import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const Modals = (props) => {
  const {
    title,
    closeModal,
    children,
    clickButton,
    labelButton,
    labelCancel,
    isOpenModal,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenModal)
  }, [isOpenModal])


  const onCloseModal = () => {
    setIsOpen(false)
    closeModal(false)
  }

  const onClickButton = () => {
    clickButton()
  }

  return (
    <Modal className='container-modal' isOpen={isOpen}>
      <ModalBody>
        <div className='icon-close-box'>
          <FontAwesomeIcon
            className='icon-close'
            icon={faTimes}
            onClick={onCloseModal}
          />
        </div>
        <div className='container-content'>
          <h4>
            <strong>{title}</strong>
          </h4>
          <div className='content-box'>
            <div className='content'>{children}</div>
            {labelCancel && (
              <button className='btn-box cancel' onClick={closeModal}>
                {labelCancel}
              </button>
            )}
            <button className='btn-box' onClick={onClickButton}>
              {labelButton}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

Modals.defaultProps = {
  title: '',
  closeModal: () => {},
  clickButton: () => {},
  labelButton: '',
  labelCancel: '',
  isOpenModal: false,
}

export default Modals
