import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Player, ControlBar, VolumeMenuButton } from 'video-react';
import VideoPlayer from 'components/VideoPlayer2'
import Hls from 'hls.js'
import HLSSource from 'components/HlsPlayer';


import picLive from 'images/icons/pic-live.png'
import lip from 'images/icons/lip.svg'
import eyeView from 'images/icons/eye-view.svg'
import iconLive from 'images/icons/icon-live.gif'
import iconCopy from 'images/icons/copy-2.svg'
import iconClose from 'images/icons/close.svg'
import IconShopAvatar from 'images/icons/default-shop-logo.svg';
import emoSmile from 'images/icons/emo-smile.svg'
import 'styles/_managelive.scss'
import host from 'config/host'
import Checkbox from 'components/Checkbox'
import Chat from 'components/Chat'

import { delay } from 'helper/utils'

import ModalLiveSetting from 'components/modalLiveSetting'
import ModalLiveSettingConfirm from 'components/modalLiveSettingConfirm'
import ModalLiveFinishConfirm from 'components/modalLiveFinishConfirm'
import ManageLiveProducts from 'components/ManageLiveProducts'
import VideoPlayer3 from 'components/VideoPlayer3'

import { dispatchPost,
         GET_POST, 
         GET_GENERATE_KEY, 
         PUBLISH_POST, 
         STOP_POST,
         TOGGLE_SHOW_ON_ITEM, 
         TOGGLE_OPEN_LIVE_SETTING_MODAL,
         SET_MODAL_LIVE_FINISH_CONFIRM,
         GET_POST_MEDIA,
         SET_POST_MEDIA
        } from '../redux/actions/post';
import { dispatchLive,
         GET_LIVE_STATUS, 
         PROVIDE_STREAM } from '../redux/actions/live';

import { join, leave } from '../redux/actions/socket';

const showOnOptions =  {live: {type: 'live', name:'showonLiveAllItem'}, 
                        shelf: {type: 'shelf', name: 'showonShelfAllItem'}};

class ManageLive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: [],
            showonShelfAllItem: false,
            showonLiveAllItem: false,
            modalLiveSetting: {
                isOpen: false
            },
            roomId: null,
            prepareLive: false
            
        }
        this.postId = this.props.match.params.postId;
        this.toggle = null
    }

    componentDidMount(){
        this.props.dispatch(dispatchLive({ type: PROVIDE_STREAM }))
        this.getPost();
        this.getPostMedia();
        this.getLiveStatus();
        this.prepareLiveState();
    }

    componentWillUnmount(){
        try {
            this.clearPostMedia();
            clearInterval(this.toggle)
            this.props.dispatch(leave)
        } catch (ex) {
        }
    }

    componentWillReceiveProps = nextProps => {

        if(nextProps.post != this.state.post && nextProps.post?.items) {
            const items = nextProps.post.items.map(item => {
                item.showOnValue = {shelf: false, live: false}
                /* NOTE - init empty array when API retun null */
                if(!item.showOn) item.showOn = [];
                
                return item;
            });
            let posts = nextProps.post;
            posts.items = items
            
            Object.keys(showOnOptions)
                  .map(key => 
                  this.checkShowListAllShelf(nextProps.post, 
                                             showOnOptions[key].type));
            this.setState({post: posts});
        }

        if(nextProps?.postMediae && nextProps.postMediae.roomId !== this.state.roomId){
            this.setState({ roomId: nextProps.postMediae.roomId })

            const payload = { 'roomId': nextProps.postMediae.roomId }
            this.props.dispatch(join(payload))
        }

        if(nextProps.liveStatus === 'publish') this.setState({prepareLive: false});

        if(nextProps.liveStatus !== this.props.liveStatus) {
            if(nextProps.liveStatus === 'finish') 
                this.finishPost();
        }

    }

    checkShowListAllShelf = (post, showOntype) => {
        const res = post.items.some((item) => {
            if(item.showOn) {
                return item.showOn.indexOf(showOntype) == -1
            }
            else {
                return true;
            }
        })
        this.setState({[showOnOptions[showOntype].name]: !res})
    }

    /* SECTION Function  */
    prepareLiveState = async () => {

        this.toggle = setInterval(() => {
            if(this.props.liveStatus == "wait" ||
               this.props.liveStatus == "disconnect" ||
               this.props.liveStatus === undefined)
                this.getLiveStatus();
            else clearInterval(this.toggle)
            
        }, 1000);

    }

    /* !SECTION Function */

    /* SECTION Event Function */
    onSetShowonShelfAll = (event, showOntype) => {
        this.setState({ [event.target.name]: !this.state[event.target.name] }, () => {
            this.setState(prevState => {

                let posts = prevState.post;
                
                const selectAll = posts.items.some(item => {
                    if (!item.showOn) return false;
                    return item.showOn && item.showOn.indexOf(showOntype) < 0
                })
                if(selectAll){
                    posts.items.map((product) => {
                        if (product.showOn && product.showOn.indexOf(showOntype) < 0) {
                            product.showOn.push(showOntype);
                        }
                    })
                } else {
                    posts.items.map((product) => {
                        if (product.showOn && product.showOn.indexOf(showOntype) != -1) {
                        const showOn = product.showOn.filter((show) => show !== showOntype)
                        product.showOn = showOn
                        }
                    })
                }
                
                return {post:posts}
            }, () => {
                const items = this.state.post.items;
                items.map((item,itemIndex) => this.onToggleShowOnItem(itemIndex, item, showOntype));
            })
        })
    }

    onShowOnChange = (event,index,item) => {
        const attrName = event.target.name;
        let showOn = [];
        this.setState( prevState => {
            let posts = prevState.post;
            // NOTE - toggle value
            if (posts.items[index].showOn && posts.items[index].showOn.indexOf(attrName) > -1) {
                posts.items[index].showOn = posts.items[index].showOn.filter(item => item !== attrName);
            } else {
                if (!posts.items[index].showOn) posts.items[index].showOn = [];
                posts.items[index].showOn.push(attrName);
            }

            showOn = posts.items[index].showOn;

            return {post:posts}
        },() => this.onToggleShowOnItem(index, item, attrName));
    }

    onOpenLiveSettingModal = () => {
        this.props.dispatch(dispatchPost({ type: TOGGLE_OPEN_LIVE_SETTING_MODAL, payload:{ isOpen: true } }))
    }

    onSubmitForm = () => {
        const liveStatus = this.props.liveStatus;
        
        switch (liveStatus) {
            case "ready": 
                    this.publishPost(); 
                    this.setState({prepareLive:true})
                    break;
            case "publish": 
                    this.stopPost(); 
                    this.setState({prepareLive:false})
                    break;
            case "disconnect": 
                    this.stopPost(); 
                    this.setState({prepareLive:false})
                    break;
        }
    }
    /* !SECTION  */

    /* SECTION API Function */
    getLiveStatus = () => this.props.dispatch(dispatchLive({ type: GET_LIVE_STATUS }))
    getPost       = () => this.props.dispatch(dispatchPost({ type: GET_POST, payload:{} }))
    getPostMedia  = () => this.props.dispatch(dispatchPost({ type: GET_POST_MEDIA, payload:{} }))
    clearPostMedia  = () => this.props.dispatch(dispatchPost({ type: SET_POST_MEDIA, payload:{postMediae: {}} }))
    generateKey   = () => this.props.dispatch(dispatchPost({ type: GET_GENERATE_KEY, payload:{} }))

    
    publishPost = () => {
        const {upstreamKey, postId} = this.props.liveDetail
        const payload = {upstreamKey, postId};
        this.props.dispatch(dispatchPost({ type: PUBLISH_POST, payload }))
    }

    stopPost = () => {
        const {upstreamKey, postId} = this.props.liveDetail
        const payload = {upstreamKey, postId};
        this.props.dispatch(dispatchPost({ type: STOP_POST, payload }))
    }

    onToggleShowOnItem = (index, item, showOnType) => {

        this.checkShowListAllShelf(this.state.post, showOnType)

        const payload = {
                            item: { index: (index+1),
                                    skuId: item.skuId , 
                                    showOn: item.showOn },
                            itemId: item.id
                        };
        this.props.dispatch(dispatchPost({ type: TOGGLE_SHOW_ON_ITEM, payload }))
    }

    finishPost = () => this.props.dispatch(dispatchPost({ type: SET_MODAL_LIVE_FINISH_CONFIRM, payload: {isOpen: true} }))
    

    /* !SECTION  */

    render() {
        const { post, showonShelfAllItem, showonLiveAllItem, prepareLive } = this.state;
        const { modalLiveSetting, liveUser, postMediae, liveStatus=false } = this.props || {};
        const items = !!post && !!post.items ? post.items : [];

        return (
            <div id="manage-live">
                <div className="row">
                    <div className="col-8"><h3 style={{ width: '66%' }}>จัดการ LIVE </h3></div>
                    <div className="col-4"><h3 className="mb-2">สินค้าที่ขาย</h3></div>
                </div>

                <div className="row">
                    <div className="col-8">
                        
                    { (postMediae?.type === 'vod') && 
                        <React.Fragment>
                            { <VideoPlayer3 controls={true} 
                                          src={host.streamUrl + postMediae?.publishUrl }/> }
                        </React.Fragment>
                    }
                    { ((postMediae?.type === 'live') || ((postMediae?.type === 'rerun') && (postMediae?.upstreamStatus === 'publish')) ) && 
                        <React.Fragment>
                            <div className="manage-live-container d-flex">
                            <div className="pic-live-container d-flex flex-row justify-content-center position-relative">

                                    <div className="video00">

                                        { postMediae?.type === 'live' && postMediae?.publishUrl &&
                                            <VideoPlayer
                                                playsinline={true}
                                                width={'50%'}
                                                height={'80vh'}
                                                muted={false}
                                                fullScreen={false}
                                                display={true}
                                                source={ host.streamUrl + postMediae?.publishUrl }
                                            >
                                            </VideoPlayer>
                                        }
                                    </div>
                                    <div className="chat-container">
                                        <div className="chat-wrap">
                                            <Chat roomId={postMediae?.roomId}></Chat>
                                        </div>
                                    </div>
                                <ModalLiveSettingConfirm onSubmitForm={this.onSubmitForm}/>
                                <ModalLiveFinishConfirm />
                                {  !(post?.rePostId) && 
                                    <div className={`setting ${modalLiveSetting.isOpen ? 'back-layer' : ''}`}>
                                        <button className="btn btn-submit" onClick={this.onOpenLiveSettingModal}> ตั้งค่าไลฟ์ </button>
                                    </div>
                                }
                                <ModalLiveSetting prepareLive={prepareLive} prepareLiveState={this.prepareLiveState}/>
                                <div className="overlay-background"> </div>
                                <div className="w-100 h-100 position-absolute text-danger">
                                    <div className="d-flex flex-row align-items-center ml-3 mt-3" style={{ gap: '15px' }}>
                                        <div className="img-profile-manage">
                                            {
                                                        post?.shop?.logoPath ? <img src={`${host.avatar}${post?.shop?.logoPath?.path}`} style={{ maxWidth: '100%', height: 'auto' }}/> :
                                                        <img src={IconShopAvatar} style={{ maxWidth: '100%', height: 'auto' }}/>
                                            }
                                        </div>
                                        <div className="d-flex flex-column flex-wrap">
                                            <span className="color-white">{post?.shop?.name}</span>
                                            <div className="d-flex flex-row justify-content-start align-items-center mt-2" style={{ gap: '15px' }}>
                                                <div className="bg-live">
                                                    <img src={iconLive} alt="live" className="gif-live" />
                                                    <span className="">Live</span>
                                                </div>
                                                <span className="color-white"> <img src={eyeView} alt="eye-view" /> {liveUser}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="close position-absolute"></div>
                                </div>
                            
                            </div>
                            </div>
                        </React.Fragment>
                    }
                    </div>
                    <div className="col-4">
                        <ManageLiveProducts items={items} post={post}/>
                    </div>
                </div>
               
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    post            : state.post.post,
    postMediae      : state.post.postMediae,
    modalLiveSetting: state.post.modalLiveSetting,
    liveUser        : state.socket.liveUser,
    generateKey     : state.post.generateKey,
    liveStatus      : state.live?.liveStatus?.upstreamStatus,
    liveDetail      : state.live?.liveStatus,
    rerunStatus     : state.live?.liveStatus
})

export default connect(mapStateToProps, null)(ManageLive)
