/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import FormShopDetail from 'components/Form/ShopDetail'
import IconEllipse from '../images/icons/ellipse.svg';
import IconLive from '../images/icons/live.svg'
import IconTrash from '../images/icons/trash.svg'
import IconClock from '../images/icons/clock.svg'
import IconIncrease from '../images/icons/increse.svg'
import IconShoppingBag from '../images/icons/shoppingBag.svg'
import IconEdit from '../images/icons/edit.svg'
import IconShopAvatar from 'images/icons/default-shop-logo.svg'


import { getUserRole, setLocalStorage } from '../helper/utils'

import { SETTING_MENU_SIDEBAR, dispatchApp } from '../redux/actions/app'
import {
  GET_PRODUCTS_BY_SHOP,
  OPEN_MODAL_ADD_PRODUCT,
  dispatchProduct,
  HANDLE_SEARCH_SHOP_PRODUCT,
  HANDLE_DELETE_PRODUCT,
  OPEN_MODAL_LIMIT_PURCHASE,
} from '../redux/actions/product'
import {
  SET_CURRENT_POST,
  dispatchShop,
  SET_PAGINATION_SHOP_DETAIL,
  SET_PAGINATION_POSTS,
  SET_DATA_SHOP_DETAIL,
} from '../redux/actions/shop'
import {
  GET_POST_BY_SHOP_ID,
  dispatchPost,
  HANDLE_DELETE_POST_LIVE,
  SET_MODAL_RERUN_LIVE
} from '../redux/actions/post'


import host from '../config/host'
import { displayDateTh } from '../helper/timeUtil'
import { sortByKey } from '../helper/utils'

import '../styles/_shopDetail.scss'
import '../styles/_react-tab.scss'
import ModalRerunLive from '../components/modalRerunLive';
import ModalAddProduct from '../components/modalAddProduct'
import Pagination from '../components/pagination'

import noItem from '../images/icons/status-error.svg'
import classnames from 'classnames'
import { checkPermission } from '../helper/utils'
import {
  dispatchModalConfirm,
  OPEN_MODAL_CONFIRM,
} from 'redux/actions/modalConfirm'
import { InputSearch } from 'components/output'
import ShopTax from './ShopTax';

import numeral from 'numeral'
import { ADMIN, SUPER_ADMIN } from 'layout/Sidebar/Role'
import ShopHub from './ShopHub'
import VodList from 'components/VodList';
import LiveList from 'components/LiveList';
import SetPriceLogistic from './ListLogisticPrice'
import RerunList from 'components/RerunList'
import { Content, LinkLabel } from 'components/FontStyle';
import ModalLimitPurchase from 'components/ModalLimitPurchase';

const LiveFilterOption = {
  ALL: { label: 'ทั้งหมด', value: 'ALL' },
  NOW_LIVE: { label: 'Now LIVE', value: 'NOW_LIVE' },
  LIVE_PROGRAM: { label: 'LIVE Program', value: 'LIVE_PROGRAM' },
  FINISH_LIVE: { label: 'Finish LIVE', value: 'FINISH_LIVE' }
}
class ShopDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shopDetail: {},
      currentTab: 2,
      productsByShop: {
        product: [],
        count: 0,
      },
      postsByShopId: {
        count: 0,
        posts: [],
      },
      searchProduct: '',
      statusSearch: false,
      searchHistory: '',
      productMode: 'ADD',
      productSelected: null,
      liveFilterSelected: LiveFilterOption.ALL,
      liveFilterOption: [LiveFilterOption.ALL,
      LiveFilterOption.NOW_LIVE,
      LiveFilterOption.LIVE_PROGRAM,
      LiveFilterOption.FINISH_LIVE,
      ]
    }
  }


  componentDidMount = () => {
    // const shopId = Number(this.props.match.params.shopId);
    //
    // const keyword = "กระเป๋าเป้";
    // this.props.dispatch(dispatchProduct({ type: GET_SEARCH_PRODUCTS_BY_SHOP, payload: { shopId, keyword } }))
    this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
    // this.props.dispatch(dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }))
    // this.props.dispatch(dispatchShop({ type: GET_SHOP_DETAIL, payload: { shopId } }))
    // this.props.dispatch(dispatchPost({ type: GET_POST_BY_SHOP_ID, payload: { shopId } }))

    const currentTab = JSON.parse(localStorage.getItem('shopDetailDefaultTab')) || { tab: 0 };
    this.setState({ currentTab: currentTab.tab });
    localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 0 }));

    localStorage.setItem(
      'createProductBy',
      JSON.stringify({ createAt: 'shopDetail' }),
    )
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.shopDetail !== this.props.shopDetail) {
      this.setState({ shopDetail: nextProps.shopDetail })
    }
    if (nextProps.productsByShop !== this.props.productsByShop) {
      this.setState({ productsByShop: nextProps.productsByShop })
    }
    if (nextProps.post.postsByShopId !== this.props.post.postsByShopId) {
      this.setState({
        postsByShopId: {
          posts: nextProps.post.postsByShopId,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: 1 },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'limit', value: 10 },
      }),
    )
  }

  setSideBarGroupMenu = (groupMenu) => {
    this.props.dispatch(
      dispatchApp({
        type: SETTING_MENU_SIDEBAR,
        payload: { group: groupMenu },
      }),
    )
  }

  setCurrentPost = (post) => {
    localStorage.setItem('post', JSON.stringify(post))
    this.props.dispatch(
      dispatchShop({ type: SET_CURRENT_POST, payload: { post } }),
    )
  }

  // openModalAddProduct = () => {
  //     this.props.dispatch(dispatchProduct({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: true } }))
  // }

  onChangePageProduct = (page) => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: page },
      }),
    )
    if (this.state.statusSearch) {
      this.props.dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_SHOP_PRODUCT,
          payload: { search: this.state.searchHistory },
        }),
      )
    } else {
      this.props.dispatch(
        dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }),
      )
    }
  }

  onShowSizeChangeProduct = (page, pageSize) => {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'limit', value: pageSize },
      }),
    )
  }

  onChangePagePosts = (page) => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchPost({ type: GET_POST_BY_SHOP_ID, payload: { shopId } }),
    )
  }

  onShowSizeChangePosts = (page, pageSize) => {
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'page', value: page },
      }),
    )
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_POSTS,
        payload: { key: 'limit', value: pageSize },
      }),
    )
  }

  onSearchProduct = (e) => {
    this.setState({ searchProduct: e.target.value })
  }

  onKeypressSearchProduct = (e) => {
    const { searchProduct } = this.state
    if (searchProduct.trim().length === 0) return
    if (e.key === 'Enter') {
      this.setState({
        statusSearch: true,
        searchHistory: this.state.searchProduct,
      })
      this.props.dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_SHOP_PRODUCT,
          payload: { search: this.state.searchProduct },
        }),
      )
    }
  }

  onClickSearchProduct = () => {
    this.setState({
      statusSearch: true,
      searchHistory: this.state.searchProduct,
    })
    this.props.dispatch(
      dispatchProduct({
        type: HANDLE_SEARCH_SHOP_PRODUCT,
        payload: { search: this.state.searchProduct },
      }),
    )
  }

  handleDeleteProduct = (product) => {
    this.props.dispatch(
      dispatchProduct({
        type: HANDLE_DELETE_PRODUCT,
        payload: {
          product,
          statusSearch: this.state.statusSearch,
          historySearch: this.state.searchHistory,
        },
      }),
    )
  }

  openModalDeleteProduct = (product) => {
    this.props.dispatch(
      dispatchModalConfirm({
        type: OPEN_MODAL_CONFIRM,
        payload: {
          isOpen: true,
          message: `คุณต้องการลบสินค้า ${product.name}`,
          btnConfirm: 'ใช่',
          btnCancel: 'ไม่ใช่',
          isClose: () =>
            this.props.dispatch(
              dispatchModalConfirm({
                type: OPEN_MODAL_CONFIRM,
                payload: false,
              }),
            ),
          confirm: () => this.handleDeleteProduct(product),
        },
      }),
    )
  }

  confirmDeletePost = (post) => {
    this.props.dispatch(
      dispatchPost({ type: HANDLE_DELETE_POST_LIVE, payload: { post } }),
    )
  }

  handleDeletePostLive = (post) => {
    this.props.dispatch(
      dispatchModalConfirm({
        type: OPEN_MODAL_CONFIRM,
        payload: {
          isOpen: true,
          message: `ต้องการลบ Live ${post.title}`,
          btnConfirm: 'ใช่',
          btnCancel: 'ไม่ใช่',
          confirm: () => this.confirmDeletePost(post),
          isClose: () =>
            this.props.dispatch(
              dispatchModalConfirm({
                type: OPEN_MODAL_CONFIRM,
                payload: false,
              }),
            ),
        },
      }),
    )
    // this.props.dispatch(dispatchModalMessage({type: OPEN_MODAL_CONFIRM ,payload:{title: "iconFail",message: "ลบ live นี้"}}))
  }

  openModalAddProduct = (mode, productSelected = {}) => {
    this.setState({ productMode: mode, productSelected }, () =>
      this.props.dispatch(
        dispatchProduct({
          type: OPEN_MODAL_ADD_PRODUCT,
          payload: { isOpen: true },
        }),
      ),
    )
  }

  onClearSearch = () => {
    const shopId = Number(this.props.match.params.shopId)
    this.props.dispatch(
      dispatchShop({
        type: SET_PAGINATION_SHOP_DETAIL,
        payload: { key: 'page', value: 1 },
      }),
    )
    this.setState({ statusSearch: false, searchHistory: '' }, () =>
      this.props.dispatch(
        dispatchProduct({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId } }),
      ),
    )
  }

  openModalRerunLive = (liveSelected) => {
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'isOpen', value: true } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'currentLive', value: liveSelected } }))
  }

  onFilterSelected = item => this.setState({ liveFilterSelected: item })

  checkPublishStatus = () =>
    this.state.postsByShopId.posts.filter((post) => post.liveStatus === 'publish').length > 0

  filterLiveStatusByStatus = (posts, type) => {
    const filter = posts.filter((post) => post.liveStatus === type);
    return sortByKey(filter, 'releaseDate');
  }

  getEnableCreateRerun = (date) => {
    const current = new Date();
    const finishDate = new Date(date);
    return (current - finishDate) > 600 ? true : false;
  }

  setCurrentTab = index => {
    this.setState({ currentTab: index })
    setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: index } })
  }

  openModalLimitPurchase = (data, product) => {
    const { productSkus, ...productNosku } = product.product
    this.props.dispatch(
      dispatchProduct({
        type: OPEN_MODAL_LIMIT_PURCHASE,
        payload: { isOpen: true, data, product: productNosku },
      })
    );
  }

  render() {
    const { shopDetail,
      productsByShop,
      statusSearch,
      currentTab } = { ...this.state, }
    const {
      createdAt,
      updatedAt,
      logoPath,
      zone,
    } = shopDetail
    const { paginationShopDetail } = this.props;

    return (
      <React.Fragment>
        <div className='shop-detail'>
          <h4>รายละเอียดร้านค้า</h4>
          <div className='shop-detail-header'>
            <div className='row'>
              <div className='col-1-atl'>
                {logoPath ? (
                  <img
                    src={`${host.avatar}${logoPath && logoPath.path}`}
                    className='shop-profile-image'
                  />
                ) : (
                  <img src={IconShopAvatar} alt='IconShopAvatar' />
                )}
              </div>
              <div className='col-7'>
                <div className='row'>
                  <div className='col-12'>
                    <h5>
                      {shopDetail.name}
                      {this.checkPublishStatus() && <img className="pl-2" src={IconLive} />}
                    </h5>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 align-center'>
                    <img src={IconShoppingBag} className='mr-2' />
                    <span>สินค้าทั้งหมด {productsByShop.count} รายการ</span>
                  </div>
                  <div className='col-6 align-center'>
                    <img src={IconClock} className='mr-2' />
                    <span>
                      เข้าร่วมเมื่อ {displayDateTh(createdAt, 'D MMM YYYY')}
                    </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <span>{zone}</span>
                  </div>
                </div>
              </div>
              <div className='col-4-atl'>
                <div className='right-side-wrap'>
                  <div className='row align-center-right'>
                    {' '}
                    <span>
                      อัพเดทข้อมูลล่าสุด{' '}
                      {displayDateTh(updatedAt, 'D MMM YYYY')}
                    </span>
                  </div>
                  {checkPermission('shop', 'delete', 'rw') ? (
                    <div className='row align-center align-center-right'>
                      {' '}
                      <img src={IconTrash} /> <span>ลบร้านค้านี้</span>
                    </div>
                  ) : null}
                  {/* {user.permission?.some((item) => item.module == "shop" && item.action == "delete") ?
                                    <div className="row align-center align-center-right"> <img src={IconTrash} /> <span>ลบร้านค้านี้</span></div> : null} */}
                </div>
              </div>
            </div>
          </div>

          <div className='shop-detail-content'>
            <Tabs selectedIndex={currentTab} onSelect={index => this.setCurrentTab(index)}>
              <TabList>
                <Tab>สินค้าทั้งหมด</Tab>
                <Tab> <img src={IconEllipse} className="pr-1" /> Live </Tab>
                <Tab> VOD </Tab>
                <Tab> รีรันจบแล้ว </Tab>
                <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() !== SUPER_ADMIN })}>ข้อมูลร้านค้า</Tab>
                <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() !== SUPER_ADMIN })}>ที่อยู่ร้านค้า</Tab>
                <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() !== SUPER_ADMIN })}>ข้อมูลภาษี</Tab>
                <Tab className={classnames('react-tabs__tab', { 'd-none': getUserRole() !== SUPER_ADMIN && getUserRole() !== ADMIN })}>ตั้งค่าราคาจัดส่ง</Tab>
              </TabList>

              <TabPanel>
                <section className='product-container'>
                  <div className='product-header'>
                    <div className='row'>
                      <div className='col-10 text-left align-center'>
                        <span className='font-subtitle'>
                          {productsByShop.count} รายการ
                        </span>
                        {/* <div className="input-text-field">
                                                <input type="text" placeholder="ค้นหาสินค้า" value={this.state.search} onChange={this.onSearchProduct} onKeyPress={this.onKeypressSearchProduct} />
                                                <img src={IconSearch} className="cursor-pointer" onClick={this.onClickSearchProduct} />
                                            </div> */}
                        <div className='w-50 pl-2'>
                          <InputSearch
                            statusSearch={statusSearch}
                            value={this.state.search}
                            placeholder='ค้นหาสินค้า'
                            onChange={this.onSearchProduct}
                            onKeyPress={this.onKeypressSearchProduct}
                            handleSearch={this.onClickSearchProduct}
                            onClearSearch={this.onClearSearch}
                          />
                        </div>
                      </div>

                      <div
                        className={classnames(
                          'col-2 text-right align-middle link',
                          {
                            'd-none': !checkPermission(
                              'product',
                              'create',
                              'rw',
                            ),
                          },
                        )}
                        onClick={() => this.openModalAddProduct('ADD')}
                      >
                        <React.Fragment>
                          {' '}
                          <img src={IconIncrease} alt='' />
                          <span className='btn-text-live'>สร้างสินค้า</span>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>

                  {productsByShop &&
                    productsByShop.product.length === 0 ? null : (
                    <div className='pagination-top'>
                      <div className='float-right position-relative mt-2'>
                        <Pagination
                          defaultCurrent={1}
                          current={paginationShopDetail.page}
                          pageSize={paginationShopDetail.limit}
                          total={paginationShopDetail.total}
                          onChange={this.onChangePageProduct}
                          onShowSizeChange={this.onShowSizeChangeProduct}
                          pageSizeOptions={paginationShopDetail.pageSizeOptions}
                        />
                      </div>
                    </div>
                  )}

                  {productsByShop && productsByShop.product.length === 0 ? (
                    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
                      <img src={noItem} alt='noItem' />
                      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
                    </div>
                  ) : (
                    <div className='product-content'>
                      <div className='product-list'>
                        <div className='product-table-header'>
                          <div className='row'>
                            <div className='col-1 text-center'>ลำดับ</div>
                            <div className='col-2 text-center'>
                              ชื่อสินค้า
                            </div>
                            <div className='col-1 text-center'>ชื่อร้านค้า</div>
                            <div className="col-1 text-center"> จำกัดการซื้อ </div>
                            <div className='col-1 text-center'>รหัส SKU</div>
                            <div className='col-2 text-center'>
                              ตัวเลือกสินค้า
                            </div>
                            <div className='col-1 text-center'>ราคาขาย</div>
                            <div className='col-1 text-center'>คลัง</div>
                            <div className='col-1 text-center'>สถานะ</div>
                            <div className='col-1 text-center'>
                              จำกัดการซื้อ <br />
                              <Content primary>(สินค้าย่อย)</Content>
                            </div>
                            <div className='col-1 text-center'></div>
                          </div>
                        </div>
                        <div className='product-table-content'>
                          {productsByShop &&
                            productsByShop.product.map(
                              (product, productIndex) => {
                                const { coverPath, shop, productSkus } = product
                                return (
                                  <React.Fragment key={`productIndex-${productIndex}`}>
                                    <div className='row product-row'>
                                      <div className='col-1 text-center nopadding'>
                                        {(paginationShopDetail.page - 1) *
                                          paginationShopDetail.limit +
                                          productIndex +
                                          1}
                                      </div>
                                      <div className='col-2 text-left nopadding'>
                                        <div className='product-name'>
                                          <div className='row nopadding'>
                                            <div className='col-4 nopadding'>
                                              <div className='product-image'>
                                                <img
                                                  src={`${host.avatar}${coverPath.path}`}
                                                  alt=''
                                                />
                                              </div>
                                            </div>
                                            <div className='col-8 nopadding'>
                                              <div className='product-detail'>
                                                <ul>
                                                  <li>
                                                    {' '}
                                                    <span className='label-product-name'>
                                                      {product.name}
                                                    </span>{' '}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        {' '}
                                        <span>{shop.name}</span>{' '}
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        {' '}
                                        <LinkLabel>
                                          <span
                                            onClick={() => this.openModalLimitPurchase(productSkus, { product: product })}>
                                            {product?.limit ? product.limit : 'ไม่จำกัด'}
                                          </span>
                                        </LinkLabel>
                                      </div>
                                      <div className='col-7 text-center nopadding'>
                                        {productSkus &&
                                          productSkus.map(
                                            (productSku, skuIndex) => {
                                              const {
                                                id,
                                                sku,
                                                stockRemain,
                                                status,
                                                priceNet,
                                                limit
                                              } = productSku
                                              return (
                                                <React.Fragment key={`skuIndex-${skuIndex}`}>
                                                  <div className='row'>
                                                    <div className='col-1-atl text-center'>
                                                      {id}
                                                    </div>
                                                    <div className='col text-center'>
                                                      {sku}
                                                    </div>
                                                    <div className='col-1-atl text-center'>
                                                      ฿{' '}
                                                      {numeral(priceNet).format(
                                                        '0,0.00',
                                                      )}
                                                    </div>
                                                    <div className='col-1-atl text-center'>
                                                      {numeral(
                                                        stockRemain,
                                                      ).format('0,0')}
                                                    </div>
                                                    <div className='col-1-atl text-center pb-2'>
                                                      {status === 'show' ? (
                                                        <div className='badge badge-green'>
                                                          ขายอยู่
                                                        </div>
                                                      ) : (
                                                        <div className='badge badge-gray'>
                                                          ไม่ขาย
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className='col-1-atl text-center'>
                                                      <LinkLabel>
                                                        <span onClick={() => this.openModalLimitPurchase(productSkus, { product: product })}>
                                                          {limit ? limit : 'ไม่จำกัด'}
                                                        </span>
                                                      </LinkLabel>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              )
                                            },
                                          )}
                                      </div>
                                      <div className='col-1 text-center nopadding'>
                                        <div className='product-action'>
                                          <img src={IconEdit} className='cursor-pointer' onClick={() => this.openModalAddProduct('UPDATE', product,)} />
                                          <img src={IconTrash} className='cursor-pointer' onClick={() => this.openModalDeleteProduct(product,)} />
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              },
                            )}

                          {productsByShop &&
                            productsByShop.product.length === 0 ? null : (
                            <div className='float-right position-relative mt-2'>
                              <Pagination
                                defaultCurrent={1}
                                current={paginationShopDetail.page}
                                pageSize={paginationShopDetail.limit}
                                total={paginationShopDetail.total}
                                onChange={this.onChangePageProduct}
                                onShowSizeChange={this.onShowSizeChangeProduct}
                                pageSizeOptions={
                                  paginationShopDetail.pageSizeOptions
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </TabPanel>

              <TabPanel>
                <LiveList></LiveList>
              </TabPanel>
              {/* ANCHOR - Vod product list */}
              <TabPanel>
                <VodList></VodList>
              </TabPanel>
              <TabPanel>
                <RerunList></RerunList>
              </TabPanel>
              <TabPanel>
                <FormShopDetail />
              </TabPanel>
              <TabPanel>
                <ShopHub />
              </TabPanel>
              <TabPanel>
                <ShopTax />
              </TabPanel>
              <TabPanel>
                <SetPriceLogistic match={this.props.match} history={this.props.history} location={this.props.location} />
              </TabPanel>
            </Tabs>
          </div>
          <ModalAddProduct
            mode={this.state.productMode}
            productSelected={this.state.productSelected}
          />
          <ModalRerunLive></ModalRerunLive>
          <ModalLimitPurchase productSelected={this.state.productSelected} getListProduct={() => this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))} />


          {/* defaultCurrent={1}
                    total={total}
                    onChange={(page) => this.onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={pageSizeOptions}
                    onShowSizeChange={this.onShowSizeChange}
                    current={page}
                    showQuickJumper={true}
                    locale={{ items_per_page: '/ หน้า' }} */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  shopDetail: state.shop.shopDetail,
  productsByShop: state.product.productsByShop,
  postsByShopId: state.post.postsByShopId,
  post: state.post,
  paginationShopDetail: state.shop.paginationShopDetail,
  paginationPost: state.shop.paginationPost,
  user: state.user.userInfo,
  loading: state.app.loading,
})

export default connect(mapStateToProps)(ShopDetail)
