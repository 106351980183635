export const GET_LIST_ADD_LOGISTIC = "GET_LIST_ADD_LOGISTIC"
export const SET_KEY_ADD_LOGISTIC_PRICE = "SET_KEY_ADD_LOGISTIC_PRICE"
export const SET_PRICE_LOGISTIC_PRICE = "SET_PRICE_LOGISTIC_PRICE"
export const SET_DEFAULT_LOGISTIC_PRICE = "SET_DEFAULT_LOGISTIC_PRICE"
export const CREATE_LOGISTIC_PRICE = "CREATE_LOGISTIC_PRICE"
export const SET_MODAL_CONFIRM = "SET_MODAL_CONFIRM"
export const GET_LIST_SHOP_LOGISTIC = "GET_LIST_SHOP_LOGISTIC"
export const SET_EDIT_SHOP_LOGISTIC = "SET_EDIT_SHOP_LOGISTIC"
export const UPDATE_LOGISTIC_PRICE = "UPDATE_LOGISTIC_PRICE"
export const HANDLE_ADD_LOGISTIC_DELETED = "HANDLE_ADD_LOGISTIC_DELETED"
export const GET_SHOP_RATE_LOGISTIC_BY_ID = "GET_SHOP_RATE_LOGISTIC_BY_ID"
export const HANDLE_APPLY_ALL_SHOP_LOGISTIC = "HANDLE_APPLY_ALL_SHOP_LOGISTIC"
export const HANDLE_DELETE_SHOP_LOGISTIC = "HANDLE_DELETE_SHOP_LOGISTIC"
export const GET_LIST_BY_LOGISTIC_ID = "GET_LIST_BY_LOGISTIC_ID"
export const HANDLE_CHANGE_STATUS_LOGISTIC = "HANDLE_CHANGE_STATUS_LOGISTIC"
export const GET_LIST_ALL_SHOP_LOGISTIC_BY_SHOP = "GET_LIST_ALL_SHOP_LOGISTIC_BY_SHOP"

export function dispatchLogisticPrice(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}