import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames'
import { ButtonAdd, InputSearch } from 'components/output';
import '../styles/_promocode.scss'
import TableHeader from 'components/TableHeader';
import { Link } from 'react-router-dom';
import { dispatchPromocode, OPEN_MODAL_CONFIRM_PROMOCODE, SET_KEY_VALUE_PROMOCODE, SET_TAB_PROMOCODE, GET_LIST_PROMOCODE, HANDLE_UPDATE_PROMOCODE, HANDLE_DELETE_PROMOCODE, GET_DETAIL_UPDATE_PROMOCODE } from 'redux/actions/promocode';
import { connect } from 'react-redux'
import IconNoPromocode from 'images/icons/nopromocode.svg'
import IconEdit from 'images/icons/edit.svg';
import IconTrash from 'images/icons/trash.svg';
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
import promocode from 'redux/reducers/promocode';
import equal from 'fast-deep-equal'
import PaginationComponent from 'components/pagination';


const statusTab = {
    ALL: { label: '' },
    COMINGSOON: { label: 'comingsoon' },
    PROCESSING: { label: 'processing' },
    EXPIRED: { label: 'expired' }
}

class Promocode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promocode: null
        }
        this.getListPromocode = this.getListPromocode.bind(this)
        this.showStatusCustomCoupon = this.showStatusCustomCoupon.bind(this)

    }

    componentDidMount() {
            this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'activeTab', value: this.props.match.params.status || '' } }))
            window.history.pushState("", "", "/manage/promocode");
        this.getListPromocode()
    }

    // shouldComponentUpdate(props) {
    //     // if (this.props.promocode !== props.promocode) {
    //         return this.props.promocode !== props.promocode
    //     // }
    // }

    // componentWillReceiveProps(nextProps){
    //     if (nextProps.promocode !== this.state.promocode) {
    //         this.getListPromocode()
    //     }
    // }

    static getDerivedStateFromProps(props, state) {
        if (!equal(props.promocode, state.promocode)) {
        window.scrollTo({top: 0,behavior:'smooth'})
            return { promocode: props.promocode }
        }
        return null
    }

    componentDidUpdate(prevProps) {
        if (!equal(prevProps.promocode, this.props.promocode)) {
            // this.getListPromocode()
        }
    }

    getListPromocode = () => {
        this.props.dispatch(dispatchPromocode({ type: GET_LIST_PROMOCODE }))
    }


    setTabStatus = (tab) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchPromocode({ type: SET_TAB_PROMOCODE, payload: tab }))
        this.getListPromocode()
    }

    onSearchPromocode = (e) => {
        if (e.key !== "Enter") return
        if (e.key === "Enter") {
            this.getListPromocode()
        }
    }

    onClearSearch = () => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'kw', value: '' } }))
        this.getListPromocode()
    }

    onChangePage = (page) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'page', value: page } }))
        this.getListPromocode()
    }

    onShowSizeChange = (page, limit) => {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'limit', value: limit } }))
    }

    showCouponType = (type) => {
        switch (type) {
            case 'discount':
                return 'ลดราคา'
            case 'voucher':
                return 'คูปองแทนเงินสด'
            default: return ''
        }
    }

    showCouponAction = (action) => {
        switch (action) {
            case 'product':
                return 'ลดราคาสินค้า'
            case 'shipping':
                return 'ลดค่าจัดส่ง'
            default:
                break;
        }
    }

    showCouponRole = (role) => {
        switch (role) {
            case 'public':
                return 'สาธารณะ'
            case 'private':
                return 'ส่วนตัว'
            default:
                break;
        }
    }

    showStatusCustomCoupon = (status) => {
        switch (status) {
            case 'comingsoon':
                return <div className="badge badge-orange">เร็วๆ นี้</div>
            case 'processing':
                return <div className="badge badge-green">กำลังดำเนินการ</div>
            case 'expired':
                return <div className="badge badge-red">หมดอายุ</div>
            default:
                return <div className="badge badge-gray">ยังไม่เปิดใช้งาน</div>
        }
    }

    showStatusCoupon = (status,statusCustom) => {
        switch (status) {
            case 'show':
               return this.showStatusCustomCoupon(statusCustom)
            case 'hide':
                return <div className="badge badge-gray">ยังไม่เปิดใช้งาน</div>
            default:
                break;
        }
    }

    render() {
        const { setTabStatus, onSearchPromocode } = this
        const { promocode } = this.props
        return (
            <div id="promocode">
                <h5>โปรโมชั่นโค้ด</h5>

                <div className="search-promotion-box bg-white">
                    <div>

                        <div className="d-flex justify-content-between align-items-center">
                            <InputSearch
                                placeholder={'ค้นหาชื่อโปรโมชั่นโค้ด'}
                                onKeyPress={(e) => onSearchPromocode(e)}
                                onChange={(e) => this.props.dispatch(
                                    dispatchPromocode({
                                        type: SET_KEY_VALUE_PROMOCODE,
                                        payload: { key: 'kw', value: e.target.value }
                                    })
                                )}
                                onClearSearch={this.onClearSearch}
                                statusSearch={promocode.kw.length > 0 ? true : false}
                                handleSearch={this.getListPromocode}
                                defaultValue={promocode.kw}
                            />
                            <Link to="/manage/promocode/addpromocode">
                                <ButtonAdd
                                    label='เพิ่มโปรโมชั่นโค้ด'
                                // handleAdd={() => window.location.replace('/manage/promocode/addpromocode')}
                                />
                            </Link>

                        </div>
                    </div>

                    <ul className="d-flex flex-row tab-select-status-promotion">
                        <li
                            className={classnames({ 'active': promocode.activeTab === statusTab.ALL.label })}
                            onClick={() => setTabStatus('')}>
                            โปรโมชั่นโค้ดทั้งหมด
                             {promocode.activeTab === statusTab.ALL.label && <div className="tab-status-active" />}
                        </li>
                        <li
                            className={classnames({ 'active': promocode.activeTab === statusTab.COMINGSOON.label })}
                            onClick={() => setTabStatus('comingsoon')}>
                            เร็วๆนี้
                             {promocode.activeTab === 'comingsoon' && <div className="tab-status-active" />}
                        </li>
                        <li
                            className={classnames({ 'active': promocode.activeTab === statusTab.PROCESSING.label })}
                            onClick={() => setTabStatus('processing')}>
                            กำลังดำเนินการ
                                 {promocode.activeTab === 'processing' && <div className="tab-status-active" />}
                        </li>
                        <li
                            className={classnames({ 'active': promocode.activeTab === statusTab.EXPIRED.label })}
                            onClick={() => setTabStatus('expired')}>
                            หมดอายุแล้ว
                             {promocode.activeTab === 'expired' && <div className="tab-status-active" />}
                        </li>
                    </ul>
                </div>

                <div className="table-promocode mt-3">
                    <TableHeader
                        type={'header'}
                        dataHeader={['ลำดับ', 'รหัสอ้างอิง', 'ชื่อแคมเปญ', 'รูปแบบ', 'ประเภท', 'สิทธิ์การใช้งาน', 'สถานะการใช้งาน', '', '']}
                        colWidth={['10em', '20%', '35%', '25%', '20%', '25%', '25%', '7%', '7%']}
                        colSide={['center', 'left', 'left', 'left', 'left', 'center', 'center', 'center', 'center', 'center']}
                    />


                    {promocode.promocode?.data?.map((item, idx) => (
                        <TableHeader
                            key={`promocode${idx}`}
                            type={'content'}
                            // dataHeader={['1', '123123', 'คูปองแทนเงินสด 200 บาท', 'คูปองแทนเงินสด', 'ลดราสินค้า', 'สาธารณะ', 'กำลังดำเนินการ', <img src={IconEdit} /> , <img src={IconTrash} />]}
                            dataHeader={[(promocode.page - 1) * promocode.limit + 1 + idx,
                            item.coupon?.refCode1,
                            <div style={{paddingRight:'2em'}}>{item.name}</div>,
                            this.showCouponType(item.coupon?.couponType),
                            this.showCouponAction(item.coupon?.couponAction),
                            this.showCouponRole(item.coupon?.couponRole),
                            this.showStatusCoupon(item.coupon?.status,item.statusCustom),
                            <Link to="/manage/promocode/addpromocode">
                                <img src={IconEdit} className="cursor-pointer" onClick={() => this.props.dispatch(dispatchPromocode({ type: GET_DETAIL_UPDATE_PROMOCODE, payload: { id: item.coupon?.id } }))} />
                            </Link>,
                            <img src={IconTrash} className="cursor-pointer" onClick={() =>
                                this.props.dispatch(dispatchPromocode({
                                    type: OPEN_MODAL_CONFIRM_PROMOCODE,
                                    payload: {
                                        isOpen: true,
                                        message: 'คุณแน่ใจหรือไม่ที่จะลบโปรโมชั่นโค้ดนี้',
                                        confirm: () => this.props.dispatch(dispatchPromocode({ type: HANDLE_DELETE_PROMOCODE, payload: { id: item.coupon?.id } })),
                                        isClose: () => this.props.dispatch(dispatchPromocode({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false } }))
                                    }
                                }))} />
                            ]}
                            colWidth={['10em', '20%', '35%', '25%', '20%', '25%', '25%', '7%', '7%']}
                            colSide={['center', 'left', 'left', 'left', 'left', 'center', 'center', 'center', 'center', 'center']}
                        />
                    ))}

                    {promocode.promocode?.data?.length === 0 &&
                        <div className="no-item-promocode">
                            <img src={IconNoPromocode} />
                            <span className="title">ยังไม่มีโปรโมชั่นโค้ด</span>
                        </div>
                    }



                </div>
                    <PaginationComponent
                        pageSize={promocode.limit}
                        current={promocode.page}
                        total={promocode.total}
                        defaultCurrent={1}
                        onChange={this.onChangePage}
                        pageSizeOptions={promocode.pageSizeOptions}
                        onShowSizeChange={this.onShowSizeChange}
                        showQuickJumper={true}
                    />

                <ModalConfirm
                    isOpen={promocode.modalConfirm.isOpen}
                    message={promocode.modalConfirm.message}
                    confirm={promocode.modalConfirm.confirm}
                    isClose={promocode.modalConfirm.isClose}
                />




            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    promocode: state.promocode
})


export default connect(mapStateToProps)(Promocode)