import axios from "config/axios"
import host from "config/host"



class ribbonProvider {
    createDecorateGroup = async (params) => {
        try {
            const data = {
                type: params.type,
                name: params.name
            }
            const url = `${host.api}/decoration-group`
            const res = await axios.post(url, data)
            return res
        } catch (error) {
            throw error
        }
    }


    getListDecorateGroup = async (params) => {
        try {
            const url = `${host.api}/decoration-group?type=${params.type}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    createRibbon = async (params) => {
        let data = {
            ...params,
        }
        try {
            const url = `${host.api}/decorations`
            const res = await axios.post(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    getListRibbon = async (params) => {
        const pagination = {
            page: params.page ? `?page=${params.page}` : '',
            limit: params.limit ? `&limit=${params.limit}` : ''
        }
        try {
            const url = `${host.api}/decorations/group/${params.id}${pagination.page}${pagination.limit}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    createProductDecorate = async (params) => {
        let data = {
            decorationGroupId: params.decorationGroupId,
            decorationId: params.decorationId,
            items: params.items
        }
        try {
            const url = `${host.api}/product-decorations`
            const res = await axios.post(url, data)
            return res
        } catch (error) {
            throw error
        }
    }

    getListDecorateByDecorateId = async (params) => {
        try {
            const url = `${host.api}/product-decorations/decoration/${params.id}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    setPermissionDecorate = async (params) => {
        try {
            const url = `${host.api}/decorations/priority/${params.id}`
            const res = await axios.patch(url, { priority: params.priority })
            return res
        } catch (error) {
            throw error
        }
    }

    deleteDecorate = async (params) => {
        try {
            const url = `${host.api}/decorations/${params.id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    updateDecorateGroup = async (params) => {
        try {
            let data = {
                name: params.name ? params.name : null,
                status: params.status ? params.status : null
            }
            if (!params.name) delete data.name
            if (!params.status) delete data.status
            // if(params.isDefault) delete data.name
            // console.log(`data`, data)

            const url = `${host.api}/decoration-group/${params.id}`
            const res = await axios.patch(url, data)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    getDecorateByDecorateId = async (params) => {
        try {
            const url = `${host.api}/decorations/${params.id}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    updateDecorateById = async (params) => {
        try {
            const url = `${host.api}/decorations/${params.id}`
            const res = await axios.patch(url, params.data)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    deleteDecorateGroup = async (params) => {
        try {
            const url = `${host.api}/decoration-group/${params.id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            console.log(`error`, error)
        }
    }

    updateProductDecorate = async (params) => {
        try {
            const url = `${host.api}/product-decorations/decoration/${params.id}`
            const res = await axios.patch(url, params.data)
            return res
        } catch (error) {
            throw error
        }
    }

    getListKeyDecorate = async (params) => {
        try {
            const url = `${host.api}/decorations/group/${params.id}/key`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

}

export default new ribbonProvider()