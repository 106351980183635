
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { SET_ORDER_FIELD,SET_ORDERLIST_IN_ORDER,OPEN_MODAL_INVOICE_DETAIL,
         SET_UPDATE_SHOP_NOTE_RES,SET_UPDATE_LOGISTIC_RES,SET_MODAL_REFUND_REASON,ON_CONFIRM_REFUND, SET_CLEARING_ORDER,
         OPEN_MODAL_CONFIRM_REFUND,CLOSE_MODAL_CONFIRM_REFUND,ADD_CLEARING_ORDER,SET_DATA_CLEARING_ORDER,CLEAR_CLEARING_ORDER,SET_SELECT_ALL_CLEARING,
         CLOSE_MODAL_CONFIRM_CLEARING_ALL,OPEN_MODAL_CONFIRM_CLEARING_ALL,OPEN_MODAL_CONFIRM_CLEARING,CLOSE_MODAL_CONFIRM_CLEARING, SET_LOGISTICS_BY_SHOP, 
         SET_ORDER_DELIVERIES, OPEN_MODAL_CONFIRM_LARGE, CLOSE_MODAL_CONFIRM_LARGE, OPEN_MODAL_CHILDREN, CLOSE_MODAL_CHILDREN,
         OPEN_MODAL_COVER_SHEET_PRINT, 
         SET_DEFAULT_ORDER} from '../actions/order';

const initialState = {
    listOrder: null,
    status: null,
    limit: 10,
    page: 1,
    pageSizeOptions: ["10","20","40"],
    modalInvoiceDetail: {
        currentInvoice: null,
        isOpen: false
    },
    modalCoverSheetPrint: {
        currentInvoice: null,
        isOpen: false
    },
    modalRefundReason: {
        isOpen: false,
    },
    updateShopNoteRES: null,
    updateLogisticRES: null,
    clearingOrder: [],
    modalConfirm : {
        isOpen: false,
        message: '',
        btnConfirm: '',
        btnCancel: ''
    },
    modalConfirmClearing : {
        isOpen: false,
        message: '',
        btnConfirm: '',
        btnCancel: ''
    },
    modalConfirmClearingAll : {
        isOpen: false,
        message: '',
        btnConfirm: '',
        btnCancel: ''
    },
    modalConfirmLarge: {
        isOpen: false,
        message: '',
        btnConfirm: '',
        btnCancel: '',
    },
    modalLargeChildren: {
        isOpen: false,
        btnConfirm: '',
        btnCancel: ''
    },
    selectAllClearingOrder: false,
    searchShopSelect: null,
    filterSearchSelect: 'name',
    filterSearchValue: null,
    statusSearch: false
}

export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {
        case SET_ORDER_FIELD: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }
        case OPEN_MODAL_INVOICE_DETAIL: {
            const {isOpen,currentInvoice} = payload;
            return {
                ...state,
                modalInvoiceDetail: {
                    isOpen,
                    currentInvoice
                }
            }
        }
        case OPEN_MODAL_COVER_SHEET_PRINT: {
            const {isOpen,currentInvoice} = payload;
            return {
                ...state,
                modalCoverSheetPrint: {
                    isOpen,
                    currentInvoice
                }
            }
        }
        case SET_ORDERLIST_IN_ORDER : {
            return {
                ...state,
                listOrder: {
                    ...state.listOrder,
                    [payload.key] : payload.value
                }
            }
        }
        case SET_UPDATE_SHOP_NOTE_RES : {
            return {
                ...state,
                updateShopNoteRES: payload.RES
            }
        }
        case SET_UPDATE_LOGISTIC_RES : {
            return {
                ...state,
                updateLogisticRES: payload.RES
            }
        }
        
        case SET_MODAL_REFUND_REASON: {
            return {
                ...state,
                modalRefundReason: {
                    isOpen: payload
                }
            }
        }

        case OPEN_MODAL_CONFIRM_REFUND: {
            return {
                ...state,
                modalConfirm: {
                    ...state.modalConfirm,
                    isOpen: true,
                    message: payload.message,
                    btnConfirm: payload.btnConfirm,
                    btnCancel: payload.btnCancel
                }
            }
        }

        case CLOSE_MODAL_CONFIRM_REFUND : {
            return {
                ...state,
                modalConfirm: {
                    ...state.modalConfirm,
                    isOpen: false,
                    message: "",
                    btnConfirm: "",
                    btnCancel: ""
                }
            }
        }

        case SET_DATA_CLEARING_ORDER: {
            return {
                ...state,
                clearingOrder: payload
            }
        }

        case CLEAR_CLEARING_ORDER: {
            return {
                ...state,
                clearingOrder: []
            }
        }

        case SET_SELECT_ALL_CLEARING: {
            return {
                ...state,
                selectAllClearingOrder: payload
            }
        }

        case OPEN_MODAL_CONFIRM_CLEARING: {
            return {
                ...state,
                modalConfirmClearing: {
                    ...state.modalConfirmClearing,
                    isOpen: true,
                    message: payload.message,
                    btnConfirm: payload.btnConfirm,
                    btnCancel: payload.btnCancel
                }
            }
        }
        case CLOSE_MODAL_CONFIRM_CLEARING: {
            return {
                ...state,
                modalConfirmClearing: {
                    ...state.modalConfirmClearing,
                    isOpen: false,
                    message: '',
                    btnConfirm: '',
                    btnCancel: ''
                }
            }
        }
        case OPEN_MODAL_CONFIRM_CLEARING_ALL: {
            return {
                ...state,
                modalConfirmClearingAll: {
                    ...state.modalConfirmClearingAll,
                    isOpen: true,
                    message: payload.message,
                    btnConfirm: payload.btnConfirm,
                    btnCancel: payload.btnCancel
                }
            }
        }
        case CLOSE_MODAL_CONFIRM_CLEARING_ALL: {
            return {
                ...state,
                modalConfirmClearingAll: {
                    ...state.modalConfirmClearingAll,
                    isOpen: false,
                    message: '',
                    btnConfirm: '',
                    btnCancel: ''
                }
            }
        }
        case SET_LOGISTICS_BY_SHOP: {
            return {
                ...state,
                modalInvoiceDetail: {
                    ...state.modalInvoiceDetail,
                    logistics: payload.logistics
                }
            }
        }

        case SET_ORDER_DELIVERIES : {
            return {
                ...state,
                modalInvoiceDetail: {
                    ...state.modalInvoiceDetail,
                    deliveries: payload.deliveries
                }
            }
        }

        case OPEN_MODAL_CONFIRM_LARGE: {
            return {
                ...state,
                modalConfirmLarge: {
                    ...state.modalConfirmLarge,
                    isOpen: true,
                    message: payload.message,
                    btnConfirm: payload.btnConfirm,
                    btnCancel: payload.btnCancel,
                    headerMsg: payload.headerMsg,
                    data: payload.data
                }
            }
        }

        case CLOSE_MODAL_CONFIRM_LARGE: {
            return {
                ...state,
                modalConfirmLarge:{
                    ...initialState.modalConfirmLarge
                }
            }
        }

        case OPEN_MODAL_CHILDREN : {
            return {
                ...state,
                modalLargeChildren: {
                    ...state.modalLargeChildren,
                    isOpen: true,
                    btnConfirm: payload.btnConfirm,
                    btnCancel: payload.btnCancel,
                    headerMsg: payload.headerMsg
                }
            }
        }

        case CLOSE_MODAL_CHILDREN: {
            return {
                ...state,
                modalLargeChildren: {
                    ...initialState.modalLargeChildren
                }
            }
        }

        case SET_DEFAULT_ORDER : {
            return {
                ...initialState
            }
        }

        default: return state
    }
}
