import axios from '../config/axios';
import host from '../config/host'
import { SET_MEDIA_UPLOAD_EVENT } from '../redux/actions/gallery'

class GalleryProvider {

    getShop = async() => {
        try {
            const url = `${host.api}/shop-storages`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }

    getAlbumById = async(query) => {
        try {
            const url = `${host.api}/albums?shopId=${query.shopId}&sort=${query.sort}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }

    createAlbum = async(body) => {
        try {
            const url = `${host.api}/albums`
            const res = await axios.post(url, body)
            return res.data
        } catch (error) {
            
        }
    }

    updateAlbum = async(albumId, body) => {
        try {
            const url = `${host.api}/albums/${albumId}`
            const res = await axios.patch(url, body)
            return res.data
        } catch (error) {
            
        }
    }

    searchAlbumByName = async(payload) => {
        try {
            const url = `${host.api}/albums/search?name=${payload.name}&shopId=${payload.shopId}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }


    getMediaByAlbumId = async(query) => {
        try {
            const url = `${host.api}/medias?albumId=${query.albumId}&sort=${query.sort}&type=${query.type}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }

    createMedia = async(body, etc) => {
        try {
            const url = `${host.api}/medias`
            const res = await axios.post(url, body)
            etc.dispatch({ type: SET_MEDIA_UPLOAD_EVENT, payload: { field: etc.fieldUpload, data: res.data} })
            return res.data
        } catch (error) {
            
        }
    }

    updateMedia = async(mediaId, body) => {
        try {
            const url = `${host.api}/medias/${mediaId}`
            const res = await axios.patch(url, body)
            return res.data
        } catch (error) {
            
        }
    }

    deleteMedia = async(mediaId) => {
        try {
            const url = `${host.api}/medias/${mediaId}`
            const res = await axios.delete(url)
            return res.data
        } catch (error) {
            
        }
    }

    searchMediaByName = async(payload) => {
        try {
            const url = `${host.api}/medias/search?name=${payload.name}&albumId=${payload.albumId}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }

    onFecthAlbumDefaultByShopId = async(shopId) => {
        try {
            const url = `${host.api}/albums/getDefaultAlbum/${shopId}`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            
        }
    }

}

export default new GalleryProvider()