import { initial } from "lodash";
import { OPEN_MODAL_MESSAGE, CLOSE_MODAL_MESSAGE,OPEN_MODAL_MESSAGE_ERROR, OPEN_MODAL_MESSAGE_SUCCESS } from '../actions/modalMessage'

const initialState = {
    isShowModalMessage: false,
    title: "Failed",
    message: "please try again",
    footerMessage: "",
    btnLabel: "ตกลง",
    infoMsg: ""
};


export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case OPEN_MODAL_MESSAGE:
            return {
                ...state,
                isShowModalMessage: true,
                title: payload.title,
                message: payload.message,
                // footerMessage: payload.footerMessage,
                btnLabel: payload.btnLabel,
                btnCancel: payload.btnCancel,
                data: payload.data
                // infoMsg : payload.infomessage
            }
        case CLOSE_MODAL_MESSAGE:
            return {
                ...state,
                isShowModalMessage: false,
                title: "",
                message: "",
                // footerMessage: payload.footerMessage,
                btnLabel: "",
                // infoMsg : payload.infomessage
            }

        case OPEN_MODAL_MESSAGE_ERROR:
            return {
                ...state,
                isShowModalMessage: true,
                title: "iconFail",
                message: payload && payload.message ? payload.message : "เกิดข้อผิดพลาด",
                footerMessage: "",
                btnLabel: "ตกลง",
                infoMsg: ""
            }
        case OPEN_MODAL_MESSAGE_SUCCESS:
            return {
                ...state,
                      isShowModalMessage: true,
                      title: 'iconSuccess',
                      message: payload && payload.message || 'ทำรายการสำเร็จ',
                      btnLabel: 'ตกลง',
                    }

        default:
            return state
    }
}