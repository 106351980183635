import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "reactstrap";
import { dispatchNotification, OPEN_TOOLTIP } from '../../redux/actions/notification';
import "./index.scss";

class TooltipNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {show: false, toolTipId: ''};
  }

  componentDidMount = () => {
      this.setState({toolTipId: this.props.toolTipId});
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tooltip.show !== this.props.tooltip.show) {
        if(nextProps.tooltip.toolTipId === this.state.toolTipId)
        this.setState({show:true});
      setTimeout(() => {
          this.setState({show:false}, () => {
              this.props.dispatch(dispatchNotification({ type: OPEN_TOOLTIP, payload: {show:false, toolTipId: ''} }));
          });
      }, 1000);
    }
  };

  toggle = () => {
      this.setState({show: !this.state.show});
  }

  render() {
    const { message } = this.props.tooltip;
    const { show } = this.state;

    return (
      <React.Fragment>
        <div className="tooltip-wrap">
            <div className={`tooltip ${show ? 'tooltiptext-show':''}`}>
                {this.props.children}
                <span className="tooltiptext">{message}</span>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
    tooltip : state.notification.tooltip
});

export default connect(mapStateToProps)(TooltipNotification);
