import React, { Component } from "react";
import { connect } from "react-redux";

import PaginationComponent from 'components/pagination';

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import { dispatchPost, GET_POST_MEDIA_GROUP } from 'redux/actions/post';
import RerunItem from "components/RerunList/RerunItem";

const initialState = { pageSizeOptions:["10","20","40"] };

class RerunList extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() { this.getPostRerunList(1,10); }
  getInitialState = () => JSON.parse(JSON.stringify(initialState));

  /* SECTION - common function */
  getPostMediaes = ({type, status, page, limit, groupName, upstreamStatus}) => 
                            this.props.dispatch( 
                            dispatchPost({ type: GET_POST_MEDIA_GROUP, 
                                           payload: { shopId: this.props.currentShop.id,
                                                       type, status, page, limit, groupName,
                                                       upstreamStatus} }) );
  /* !SECTION - common function */

  /* SECTION - event function */
  getPostRerunList = (page, limit) => this.getPostMediaes({groupName: 'rerunList', type:['rerun'], page, limit, upstreamStatus: ['finish'] });
  onShowSizeChange = (page, pageSize) => this.getPostRerunList(page, pageSize)
  onChangePage = (page) => {
    const { limit=10 } = this.props?.rerunList || {};
    this.getPostRerunList(page, limit);
  }

  /* !SECTION - event function */

  render() {
    const { pageSizeOptions } = this.state;
    const { posts=[], 
            count: total=0, 
            limit=1, 
            page=1} = this.props?.rerunList || {};

    return  <React.Fragment>
            <div className="item-content">
              <div className="row">
                <div className="col-6">
                    <h5 style={{display:"inline"}}>Rerun จบแล้ว  </h5> {total} รายการ
                </div>
                <div className="col-6 text-right">
                  <div className="d-flex justify-content-end">
                     
                      
                  </div>
                </div>
              </div>
            </div>

            { posts && posts?.length > 0 &&
              <React.Fragment>
                <div className="mt-2 mb-2">
                    <PaginationComponent  defaultCurrent={1}
                                          total={total}
                                          onChange={(page) => this.onChangePage(page)}
                                          pageSize={limit}
                                          pageSizeOptions={pageSizeOptions}
                                          onShowSizeChange={this.onShowSizeChange}
                                          current={page}
                                          locale={{ items_per_page: '/ หน้า' }}/>
                </div>
            
                { posts.map( (post,index) => <RerunItem key={index} rerun={post} /> )}

                <div className="mt-2 mb-2">
                    <PaginationComponent  defaultCurrent={1}
                                          total={total}
                                          onChange={(page) => this.onChangePage(page)}
                                          pageSize={limit}
                                          pageSizeOptions={pageSizeOptions}
                                          onShowSizeChange={this.onShowSizeChange}
                                          current={page}
                                          locale={{ items_per_page: '/ หน้า' }}/>
                </div>
              </React.Fragment>
            }

        </React.Fragment>
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
  postMediaes: state.post?.postMediaes,
  rerunList: state.post?.postMediaes?.rerunList,
  currentShop: state.shop?.currentShop,
});

export default connect(mapStateToProps)(RerunList);