import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import "./index.scss";

import host from 'config/host';
import { displayDateTh } from 'helper/timeUtil';
import ToggleInput from 'components/ToggleInput';
import { checkPermission } from 'helper/utils'

import IconTrash from 'images/icons/trash.svg';
import IconEdit from 'images/icons/edit.svg';

import {
  GET_POST_BY_SHOP_ID,
  dispatchPost,
  HANDLE_DELETE_POST_LIVE,
  SET_MODAL_RERUN_LIVE
} from 'redux/actions/post'

import {
  SET_CURRENT_POST,
  dispatchShop,
} from 'redux/actions/shop'

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';

class VodItem extends Component {
  componentDidMount() {}

  openModalRerunLive = (liveSelected) => {
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'isOpen', value: true } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'currentLive', value: liveSelected } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'mode', value: 'create' } }))
  } 
  setCurrentPost = (post) => {
    localStorage.setItem('post', JSON.stringify(post))
    this.props.dispatch( dispatchShop({ type: SET_CURRENT_POST, payload: { post } }), )
  }

  render() {
    const { onVodStatusChange,
            onDeleteVod, 
            vod,
            index } = this.props;
    const { status, 
            items, 
            title, 
            cover, 
            createdAt, 
            postId, 
            mediaId,
            releaseDate, 
            upstreamStatus } = vod;
    // const products = Array.from({ length: 34 });
    const products = items;
    const {value} = {value: true};
    return (
      <React.Fragment>
        <div className="item-content">
          <div className="row">
            {/* ANCHOR - product image preview */}
            <div className="col-3">
              <div className="item-preview">
                <img src={`${host.avatar}${cover?.path}`} />
                { upstreamStatus === 'processing' &&
                  <div className="layer-overlay"><span>processing...</span></div>
                }
              </div>
            </div>
            {/* ANCHOR - product detail */}
            <div className="col-9">
              <div className="item-detail">

                {/* ANCHOR - product header */}
                { upstreamStatus !== 'processing' &&
                <div className="row">

                  <div className="col-6 pl-3">
                    <div className="row">
                      <div className="col-6 pl-0">
                        <ToggleInput value={status!=='private'} 
                                     onChange={(e) => onVodStatusChange({value: status==='private', mediaId})}
                                     name={"publish"} 
                                     labelOn={"เปิดการเผยแพร่"} 
                                     labelOff={"ปิดการเผยแพร่"}></ToggleInput>
                      </div>
                      <div className="col-6">
                        <div className="d-flex ">
                          <h6 style={{paddingTop: '0.4rem'}}> {displayDateTh(releaseDate, "D MMM YYYY HH:mm")} </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 text-right">

                      <React.Fragment>

                      <React.Fragment>
                            <Link to={`/manage/shop/shopdetail/managelive`} onClick={() => this.setCurrentPost(vod) } >
                                <button className='btn-info mr-2'> จัดการไลฟ์ </button>
                            </Link>
                        </React.Fragment>

                        <button className="btn-info" onClick={() => this.openModalRerunLive(vod)}>สร้างรีรีน</button>
                         <Link to={`/manage/shop/shopdetail/editvod?postId=${mediaId}&mode=update`}>
                                 {checkPermission('post','create','rw') ? 
                                 <React.Fragment> <img src={IconEdit} className="cursor-pointer" /> </React.Fragment> : null}
                        </Link>
                        <img src={IconTrash} className="cursor-pointer" onClick={() => onDeleteVod(vod)} />
                      </React.Fragment>

                  </div>
                </div>
                }

                {/* ANCHOR - product content */}
                <div className="row">
                  <div className="col-12">
                    <h4>{title}</h4>
                  </div>
                </div>
                
                {/* ANCHOR - product images */}
                <div className="row">
                  <div className="col-12">
                    <h5>สินค้าที่ขาย {items?.length} รายการ</h5>
                    <div className="product-list">
                      {/* NOTE - render product images */}
                      {products && products?.length > 0 ? products.map((item,index) => {
                          return (
                            <div className="product-image">
                              <img src={`${host.avatar}${item?.coverPath?.path}`} />
                            </div>
                          )
                        }
                      )
                       : (
                        <span>ยังไม่ได้ใส่สินค้า</span>
                      )}
                    </div>
                  </div>
                </div>
              
               {/* ANCHOR - post date */}
                <div className="row">
                  <div className="col">
                    <span className='date-add'>วันที่สร้างโพสต์ {displayDateTh( createdAt, 'D MMM YYYY HH:mm', )}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isMute: state.live.isMute,
});

export default connect(mapStateToProps)(VodItem);
