import { LOADING, SETTING_MENU_SIDEBAR,SETTING_MENU_SIDEBAR_PERMISSION, SET_LOADING } from '../actions/app';

const initialState = {
    statusLoading: false,
    tabMenuHeader: {
                     group: '',
                     title: '',
                     url: ''
                    },
    loading: false
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOADING:
            const  {statusLoading} = payload;
            return {
                ...state,
                statusLoading
            }
        case SETTING_MENU_SIDEBAR:
            const  {group,permission} = payload;
            return {
                ...state,
                tabMenuHeader: {group,permission}
            }
            case SETTING_MENU_SIDEBAR_PERMISSION:
                return {
                    ...state,
                    tabMenuHeader: payload.group
                }
        case SET_LOADING:
            const  {loading} = payload;
            return {
                ...state,
                loading
            }
        default:
            return state
    }
}