
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import headerMenuList from './HeaderMenuList';

import { SETTING_MENU_SIDEBAR, SETTING_MENU_SIDEBAR_PERMISSION, dispatchApp } from '../../redux/actions/app';

import './index.scss';

import Cookies from 'js-cookie';
import {ADMIN, OWNER} from '../Sidebar/Role'

class HeaderMenu extends Component {

    // componentDidMount() {
        // this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: headerMenuList.items[0] } }))
        // this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR_PERMISSION, payload: { group: headerMenuList.items[0] } }))
    constructor(props) {
        super(props)
        this.state = {userRole: null}
    }

    componentDidMount(){

        const user = Cookies.get('user')
        const userInfo = user ? JSON.parse(user != undefined ? user : null) : {};
        const userRole = userInfo.shopId ? OWNER : ADMIN;
        this.setState({userRole});

        this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: headerMenuList.items[0].group } }))
    }

    setSideBarGroupMenu = (groupMenu) => {
        this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, payload: { group: groupMenu.group } }))
    }

    checkDuplicateArray = (arr,value) => {
        return arr.some(item => item == value)
    }


    render() {
      const { tabMenuHeader } = this.props
      const { userRole } = this.state

      return (
        <React.Fragment>
          <div className='menu-list'>
            <ul>
              {headerMenuList.items.map((menu, index) => {
                  return (
                    <React.Fragment key={`${'headerMenuList_' + index}`}>
                      {menu.role.includes(userRole) && (
                        <li
                          className={`${
                            tabMenuHeader.group === menu.group ? 'active' : ''
                          }`}
                          onClick={() => this.setSideBarGroupMenu(menu)}
                        >
                          {menu.url === '/chat' ||
                          menu.url === '/chat-summary' ? (
                            <div
                              onClick={() => {
                                window.open(menu.url, '_blank')
                              }}
                            >
                              {menu.name}
                            </div>
                          ) : (
                            <Link to={menu.isIndex ? menu.linkUrl : menu.url}>
                              {menu.name}
                            </Link>
                          )}
                        </li>
                      )}
                    </React.Fragment>
                  )})}
            </ul>
          </div>
        </React.Fragment>
      )
    }
}

const mapStateToProps = state => ({
    tabMenuHeader: state.app.tabMenuHeader,
    role: state.user.role
})

export default connect(mapStateToProps)(HeaderMenu)

