import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import 'styles/chat/modal.scss'

const ModalPreview = (props) => {
  const { imagePreview, showModalPreview, closeModalPreview } = props

  return (
    <Modal
      isOpen={showModalPreview}
      toggle={closeModalPreview}
      className='modal-medium'
    >
      <ModalBody className='preview'>
        <div className='box-img'>
          <button className='btn-cloes btn' onClick={closeModalPreview}>
            {/* <i className='icon-close' /> */}x
          </button>
          <img alt='' src={imagePreview} />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalPreview
