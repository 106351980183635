import jsCookie from 'js-cookie';


const  setCookieData = (key, value) => {

    return new Promise(function(resolve, reject){
        try {
            jsCookie.set(key, value ,{ expires: 7 } )   
            resolve()
        }catch(ex){
            reject(ex)
        }
    })


}


const   getCookieData  = (key) => {

    return Promise.resolve().then(function () {
        const cart = jsCookie.get(key) || null;
      try{
         return  cart
      }catch(ex){
          return  null
      }
      
    })
 }

 const   deleteCookieData  = (key) => {

    return Promise.resolve().then(function () {
      try{
        jsCookie.remove(key) 
      }catch(ex){
          return  null
      }
      
    })
 }

 const readCookie = (key) => {
    return jsCookie.get(key) || null;
   }

 export{
    setCookieData ,
    getCookieData ,
    deleteCookieData,
    readCookie
 }