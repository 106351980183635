import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import host from 'config/host'
import { ButtonAdd, Modals, ModalLoading } from 'components/output'
import { TextInput, Checkbox } from 'components/input'
import {
  dispatchPlaylists,
  GET_PLAYLISTS,
  // CREATE_PLAYLISTS,
  UPDATE_PLAYLISTS,
  DELETE_PLAYLISTS,
} from 'redux/actions/playlists'
import {
  dispatchPlaylistFrontPage,
  GET_PLAYLIST_FRONT_PAGE,
  CREATE_PLAYLIST_FRONT_PAGE,
  UPDATE_PLAYLIST_FRONT_PAGE,
  // DELETE_PLAYLISTS,
} from 'redux/actions/playlistFrontPage'
import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import IconTrash from 'images/icons/trash.svg'
import closeImage from 'images/icons/close-image.svg'
import noItem from 'images/icons/status-error.svg'
import edit from 'images/icons/edit.svg'
import '../styles/_playlists.scss'

const PlaylistFrontPageItems = (props) => {
  const { playlists, frontpage, dispatch, history } = props

  const [activeModal, setActiveModal] = useState('none')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [base64, setBase64] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (history.location.state?.page) {
      setIsLoading(true)
      dispatch(dispatchPlaylists({ type: GET_PLAYLISTS }))
      dispatch(
        dispatchPlaylistFrontPage({
          type: GET_PLAYLIST_FRONT_PAGE,
          payload: history.location.state?.page,
        }),
      )
    } else {
      history.push('/manage/playlist-front-page')
    }
  }, [])

  useEffect(() => {
    if (frontpage) {
      setIsLoading(false)
    }
  }, [frontpage])

  const toggleModal = (modal) => {
    if (modal === 'none') {
      clearData()
    }
    setActiveModal(modal)
  }

  const handleChangeSection = (text) => {
    setData({
      ...data,
      section: text,
    })
  }

  const handleChangeName = (text) => {
    setData({
      ...data,
      name: text,
    })
  }

  const handleFileRead = async (event) => {
    const tmpFile = event.target.files[0]
    const tmpBase64 = await convertBase64(tmpFile)

    setData({
      ...data,
      icon: tmpFile,
    })
    setBase64(tmpBase64)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const uploadImage = async (data) => {
    const path = `${host.apiMedia}/upload`

    const formData = new FormData()
    formData.append('file', data)
    formData.append('serviceKey', host.serviceKey)
    formData.append('secretKey', host.secretKey)

    const response = await axios.post(path, formData)

    if (response.status === 200) {
      return response.data
    } else {
      // handle error

      return null
    }
  }

  const validator = () => {
    let tmpError = {}
    let hasError = true

    if (!data?.id) {
      tmpError.playlist = 'กรุณาเลือกเพลย์ลิส'
      hasError = false
    }

    if (!data?.icon) {
      tmpError.icon = 'กรุณาใส่รูปภาพ'
      hasError = false
    }

    if (!data?.name) {
      tmpError.name = 'กรุณาใส่ชื่อเพลย์ลิส'
      hasError = false
    }

    setError(tmpError)
    return hasError
  }

  const clickSubmit = async () => {
    const isValid = validator()

    if (isValid) {
      setIsLoading(true)

      switch (activeModal) {
        case 'create-playlist':
          const typeDataIcon = typeof data.icon

          if (typeDataIcon === 'string') {
            dispatch(
              dispatchPlaylistFrontPage({
                type: UPDATE_PLAYLIST_FRONT_PAGE,
                payload: {
                  page: history.location.state.page,
                  data: [
                    {
                      playlistId: data.id,
                      page: history.location.state.page,
                      section: data.section,
                      title: data.name,
                    },
                  ],
                },
              }),
            )
          } else {
            const image = await uploadImage(data.icon)

            if (image) {
              const path = `image/${image.fileName}`

              dispatch(
                dispatchPlaylistFrontPage({
                  type: UPDATE_PLAYLIST_FRONT_PAGE,
                  payload: {
                    page: history.location.state.page,
                    data: [
                      {
                        playlistId: data.id,
                        page: history.location.state.page,
                        section: data.section,
                        title: data.name,
                        iconActivePath: path,
                        iconInactivePath: path,
                      },
                    ],
                  },
                }),
              )
            }
          }

          break

        case 'edit-playlist':
          const typeEditDataIcon = typeof data.icon

          if (typeEditDataIcon === 'string') {
            dispatch(
              dispatchPlaylistFrontPage({
                type: UPDATE_PLAYLIST_FRONT_PAGE,
                payload: {
                  page: history.location.state.page,
                  data: [
                    {
                      frontPageId: data.id,
                      title: data.name,
                    },
                  ],
                },
              }),
            )
          } else {
            const image = await uploadImage(data.icon)

            if (image) {
              const path = `image/${image.fileName}`

              dispatch(
                dispatchPlaylistFrontPage({
                  type: UPDATE_PLAYLIST_FRONT_PAGE,
                  payload: {
                    page: history.location.state.page,
                    data: [
                      {
                        frontPageId: data.id,
                        title: data.name,
                        iconActivePath: path,
                        iconInactivePath: path,
                      },
                    ],
                  },
                }),
              )
            }
          }

          break

        default:
          break
      }

      toggleModal('none')
    } else {
    }
  }

  const validatorFormSection = () => {
    let tmpError = {}
    let hasError = true

    if (!data?.section) {
      tmpError.section = 'กรุณาใส่ section'
      hasError = false
    }

    if (!data?.playlists) {
      tmpError.playlists = 'กรุณาเลือกเพลย์ลิส'
      hasError = false
    }

    setError(tmpError)
    return hasError
  }

  const createSection = async () => {
    const isValid = validatorFormSection()

    if (isValid) {
      setIsLoading(true)

      switch (activeModal) {
        case 'create-section':
          dispatch(
            dispatchPlaylistFrontPage({
              type: CREATE_PLAYLIST_FRONT_PAGE,
              payload: {
                data: { ...data, page: history.location.state.page },
                type: GET_PLAYLIST_FRONT_PAGE,
              },
            }),
          )

          break

        default:
          break
      }

      toggleModal('none')
    } else {
    }
  }

  const clearImage = () => {
    setBase64(null)
    setData({
      ...data,
      icon: null,
    })
  }

  const clearData = () => {
    setData(null)
    setError(null)
    setBase64(null)
  }

  const onSelectedPlaylist = (value, e) => {
    let tmpPrevPlaylist = data?.playlists ? data?.playlists : []

    if (e.target.checked) {
      tmpPrevPlaylist.push({ playlistId: value })
    } else {
      tmpPrevPlaylist = data?.playlists.filter(
        (item) => item.playlistId !== value,
      )
    }

    setData({
      ...data,
      playlists: tmpPrevPlaylist,
    })
  }

  const onSelectedData = (item) => {
    setData({
      ...data,
      ...item,
      icon: item.iconActivePath,
    })

    setError(null)
  }

  const onDeletePlaylist = (id) => {
    setIsLoading(true)
    dispatch(
      dispatchPlaylistFrontPage({
        type: UPDATE_PLAYLIST_FRONT_PAGE,
        payload: {
          page: history.location.state.page,
          data: [
            {
              frontPageId: id,
              deleted: true,
            },
          ],
        },
      }),
    )
    // dispatch(
    //   dispatchPlaylists({
    //     type: DELETE_PLAYLISTS,
    //     payload: playlistsId,
    //   }),
    // )
  }

  const clearSelectPlaylist = () => {
    const tmpSection = data.section
    setData({
      section: tmpSection,
    })
  }

  return history.location.state?.page ? (
    <div className='playlists'>
      <div className='playlists-content'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='m-0'>{history.location.state?.page}</h3>
          <div className='d-flex'>
            <ButtonAdd
              label='เพิ่มเซคชั่น'
              handleAdd={() => toggleModal('create-section')}
            />
          </div>
        </div>

        <Table
          data={frontpage}
          toggleModal={toggleModal}
          history={history}
          setSelectedData={onSelectedData}
          setSection={(section) =>
            setData({
              section,
            })
          }
          onDelete={onDeletePlaylist}
          isImage
        />
        <Modals
          title={`${
            activeModal === 'create-section' ? 'เพิ่ม' : 'แก้ไข'
          }เพลย์ลิสต์`}
          closeModal={() => toggleModal('none')}
          clickButton={createSection}
          labelButton='ตกลง'
          isOpenModal={
            activeModal === 'create-section' || activeModal === 'edit-section'
          }
        >
          <div className='m-3'>
            <TextInput
              label='ชื่อเซคชั่น'
              placeholder='ชื่อเซคชั่น'
              handleChangeText={handleChangeSection}
              defaultValue={data?.section}
              error={error && error.section}
              require
            />
          </div>
          <div className='m-3'>
            <TablePlaylists
              data={playlists}
              dataSelected={data?.playlists ? data?.playlists : []}
              toggleModal={toggleModal}
              history={history}
              setSelectedData={onSelectedPlaylist}
              clearDataSelected={() => {}}
              // onDelete={onDeletePlaylist}
              itemSize='small'
              isScroll
            />
            {error && error.playlists ? (
              <div className='text-left'>
                <span style={{ color: 'red' }}>{error.playlists}</span>
              </div>
            ) : null}
          </div>
        </Modals>
        <Modals
          title={`${
            activeModal === 'create-playlist' ? 'เพิ่ม' : 'แก้ไข'
          }เพลย์ลิสต์`}
          closeModal={() => toggleModal('none')}
          clickButton={clickSubmit}
          labelButton='ตกลง'
          isOpenModal={
            activeModal === 'create-playlist' || activeModal === 'edit-playlist'
          }
        >
          {data?.id ? (
            <>
              <div className='clear-btn' onClick={clearSelectPlaylist}>
                เปลี่ยนเพลย์ลิสต์
              </div>
              <div className='upload-playlist-box mb-3'>
                <div className='upload-playlist'>
                  {base64 || data?.icon ? (
                    <div className='d-flex'>
                      <span className='image-playlist-container'>
                        <img
                          src={base64 ? base64 : host.image + data.icon}
                          alt=''
                          className='image-playlist front-page'
                          style={{ width: '100%' }}
                        />
                        <div className='middle' onClick={clearImage}>
                          <img
                            alt=''
                            src={closeImage}
                            className='clear-image'
                          />
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div
                      className='button-upload-playlist-box'
                      style={{ width: '9em', height: '9em' }}
                    >
                      <input
                        type='file'
                        className='file-upload'
                        onChange={handleFileRead}
                      />
                      <div className='icon-camera'>
                        <IconCamera />
                      </div>
                      <div className='title'>Add Icon Playlist</div>
                    </div>
                  )}
                  {error && error.icon ? (
                    <div className='text-left'>
                      <span style={{ color: 'red' }}>{error.icon}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <TextInput
                label='ชื่อเพลย์ลิสต์'
                placeholder='ชื่อเพลย์ลิสต์'
                handleChangeText={handleChangeName}
                defaultValue={data?.name}
                error={error && error.name}
                require
              />
            </>
          ) : (
            <>
              <TableAddPlaylist
                data={playlists}
                dataSelected={data}
                toggleModal={toggleModal}
                history={history}
                setSelectedData={onSelectedData}
                clearDataSelected={clearData}
                // onDelete={onDeletePlaylist}
                itemSize='small'
                isScroll
              />
              {error && error.playlist ? (
                <div className='text-left'>
                  <span style={{ color: 'red' }}>{error.playlist}</span>
                </div>
              ) : null}
            </>
          )}
        </Modals>
        {isLoading && <ModalLoading isOpenModal={isLoading} />}
      </div>
    </div>
  ) : null
}

const Table = (props) => {
  const {
    data,
    toggleModal,
    history,
    setSelectedData,
    setSection,
    onDelete,
    isScroll,
    itemSize = '',
  } = props

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className={`table-header ${itemSize}`}>
        <div className='row'>
          <div className='col-2 text-center'>ลำดับ</div>
          <div className='col'>ชื่อเพลย์ลิสต์</div>
        </div>
      </div>
      <div className={`table-body ${isScroll && 'scroll'}`}>
        {data &&
          data.map((items, index) => {
            return (
              <>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                  <h5 className='m-0'>{items.section}</h5>
                  <div className='d-flex'>
                    <ButtonAdd
                      label='เพิ่มเพลย์ลิสต์'
                      handleAdd={() => {
                        toggleModal('create-playlist')
                        setSection(items.section)
                      }}
                    />
                  </div>
                </div>
                {items.playlists.map((item, index) => {
                  return (
                    <div className={`row ${itemSize}`} key={index}>
                      <div className='col-2 d-flex align-items-center justify-content-center'>
                        {index + 1}
                      </div>

                      <div className='col-1 d-flex align-items-center'>
                        <span className='image-playlist-container'>
                          <img
                            src={host.image + item.iconActivePath}
                            alt=''
                            className='item-playlist'
                            style={{ width: '100%' }}
                          />
                        </span>
                      </div>
                      <div className='col-6 d-flex align-items-center'>
                        {item.title}
                      </div>
                      {/* <div
                        className='d-flex'
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedData({
                            ...item,
                            name: item.title,
                          })
                          toggleModal('edit-playlist')
                        }}
                      >
                        <img src={edit} alt='edit' className='icon-link' />
                      </div> */}
                      <div
                        className='d-flex'
                        onClick={(e) => {
                          e.stopPropagation()
                          onDelete(item.id)
                        }}
                      >
                        <img
                          src={IconTrash}
                          alt='delete'
                          className='icon-link'
                        />
                      </div>
                    </div>
                  )
                })}
              </>
            )
          })}
      </div>
    </div>
  )
}

const TablePlaylists = (props) => {
  const {
    data,
    dataSelected,
    toggleModal,
    setSelectedData,
    clearDataSelected,
    isScroll,
    itemSize = '',
  } = props

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className={`table-header ${itemSize}`}>
        <div className='row'>
          {dataSelected ? null : <div className='col-2 text-center'>ลำดับ</div>}
          <div className='col-10'>ชื่อเพลย์ลิสต์</div>
        </div>
      </div>
      <div className={`table-body ${isScroll && 'scroll'}`}>
        {data &&
          data.map((item, index) => {
            return (
              <div key={index}>
                <div className={`row ${itemSize}`}>
                  <div className='col-2 d-flex align-items-center justify-content-center'>
                    {index + 1}
                  </div>

                  {/* <div className='col-1 d-flex align-items-center'>
                    <span className='image-playlist-container'>
                      <img
                        src={host.image + item.iconActivePath}
                        alt=''
                        className='item-playlist'
                        style={{ width: '100%' }}
                      />
                    </span>
                  </div> */}

                  <div className='col-8 d-flex align-items-center'>
                    {item.name}
                  </div>

                  {/* <ButtonAdd
                  label=''
                  handleAdd={(e) => {
                    e.stopPropagation()
                    setSelectedData(item)
                    toggleModal('edit')
                  }}
                /> */}
                  <Checkbox
                    label=''
                    // defaultChecked
                    checked={dataSelected.some((d) => d.playlistId === item.id)}
                    onSelectMedia={(d, e) => {
                      e.stopPropagation()
                      setSelectedData(d.id, e)
                      // toggleModal('create')
                    }}
                    media={item}

                    // title=''
                    // checked={dataSelected.some((d) => d.id === item.id)}
                    // value={item.id}
                    // onChange={(e) => {
                    //   // console.log(d, 'd')
                    //   e.stopPropagation()
                    //   setSelectedData(item)
                    //   // toggleModal('edit')
                    // }}
                    // name={item.id}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const TableAddPlaylist = (props) => {
  const {
    data,
    dataSelected,
    toggleModal,
    history,
    setSelectedData,
    clearDataSelected,
    isScroll,
    itemSize = '',
  } = props

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className={`table-header ${itemSize}`}>
        <div className='row'>
          {dataSelected?.id ? null : (
            <div className='col-2 text-center'>ลำดับ</div>
          )}
          <div className='col-10'>ชื่อเพลย์ลิสต์</div>
        </div>
      </div>
      <div className={`table-body ${isScroll && 'scroll'}`}>
        {dataSelected?.id ? (
          <div>
            <div className={`row ${itemSize}`}>
              <div className='col-10 d-flex align-items-center'>
                {dataSelected.name}
              </div>
              <div className='btn-close' onClick={clearDataSelected}>
                <img alt='' src={closeImage} className='icon-close' />
              </div>
            </div>
          </div>
        ) : (
          data &&
          data.map((item, index) => (
            <div key={index}>
              <div className={`row ${itemSize}`}>
                <div className='col-2 d-flex align-items-center justify-content-center'>
                  {index + 1}
                </div>

                <div className='col-8 d-flex align-items-center'>
                  {item.name}
                </div>

                <ButtonAdd
                  label=''
                  handleAdd={(e) => {
                    e.stopPropagation()
                    setSelectedData(item)
                  }}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  playlists: state.playlists.playlists,
  frontpage: state.frontPage.frontPage,
})

export default connect(mapStateToProps, null)(PlaylistFrontPageItems)
