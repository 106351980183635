import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { InputSearch } from 'components/output';
import { connect } from 'react-redux';
import '../styles/_withdraw.scss';
import TableHeader from 'components/TableHeader';
import {
    SET_TAB_ACTIVE, disapatchWithdraw, GET_LIST_WITHDRAW, ON_ADD_SELECT_WITHDRAW, ON_SELECT_ALL_WITHDRAW, ON_CONFITM_WITHDRAW, HANDLE_SET_TAB_ACTIVE, SET_KEY_WITHDRAW, ON_SEARCH_WITHDRAW,
    SET_DEFAULT_WITHDRAW, OPEN_MODAL_WITHDRAW_PREVIEW, CLOSE_MODAL_WITHDRAW_PREVIEW, FETCH_ORDER_SELLER_WITHDRAW
} from '../redux/actions/withdraw'
import Checkbox from 'components/Checkbox'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import PaginationComponent from 'components/pagination';
import numeral from 'numeral'
import { displayDateTh, displayDateThShortYear } from 'helper/timeUtil';
import noItem from 'images/icons/status-error.svg'
import ModalWithdrawPreview from 'components/ModalWithdrawPreview';
import SelectInput from 'components/SelectInput';
import { getUserRole } from 'helper/utils';
import { OWNER, SUPER_ADMIN } from 'layout/Sidebar/Role';
import { getUser } from 'redux/sagas/selectors';
import moment from 'moment';

const headerTab = [
    { value: 'never', label: 'รายการรอถอน' },
    { value: 'processing', label: 'กำลังดำเนินการ' },
    { value: 'transferred', label: 'ระบบโอนเงินแล้ว' }
]

const withdrawStatus = {
    NEVER: 'never',
    PROCESSING: 'processing',
    TRANSFERRED: 'transferred'
}

const statusWithdraw = [
    'requested', 'settled', 'approved', 'transferred'
]

class WithDraw extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statusSearch: false,
            valueSearch: '',
            shopSelected: null
        }
    }

    componentDidMount() {
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.limit != nextProps.limit || this.props.page != nextProps.page) {
            // this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
            this.handleSearch()
        }
    }

    componentWillUnmount() {
        this.props.dispatch(disapatchWithdraw({ type: SET_DEFAULT_WITHDRAW }))
    }

    toggle = (status) => {
        this.props.dispatch(disapatchWithdraw({ type: HANDLE_SET_TAB_ACTIVE, payload: { status, shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex]?.value?.id } }))
    }

    onAddWithdrawId = (order) => {
        this.props.dispatch(disapatchWithdraw({ type: ON_ADD_SELECT_WITHDRAW, payload: { id: order.id, priceTotal: order.priceTotal } }))
    }

    checkDuplicateArr = (arr, value) => {
        return arr.some(item => item == value)
    }

    handleSelectAllWithdraw = () => {
        this.props.dispatch(disapatchWithdraw({ type: ON_SELECT_ALL_WITHDRAW }))
    }

    handleConfirm = () => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: 'ยืนยันการถอนเงิน',
                message2: `จำนวนเงิน : ${numeral(this.props.priceTransfer).format('0,0.00')} บาท`,
                type: 'success',
                btnConfirm: 'ตกลง',
                btnCancel: 'ยกเลิก',
                confirm: () => this.confirmWithdraw()
            }
        }))
    }

    confirmWithdraw = () => {
        this.props.dispatch(disapatchWithdraw({ type: ON_CONFITM_WITHDRAW }))
    }

    onChangePage = (page) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: page } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'selectWithdraw', value: [] } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: 0 } }))
        this.handleSearch()
        // this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW }))
    }
    onShowSizeChange = (page, limit) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'limit', value: limit } }))
    }

    onChangeSearch = (e) => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'valueSearch', value: e.target.value } }))
    }

    onKeyPressSearch = (e) => {
        if (e.key == "Enter") {
            this.handleSearch()
        }
    }

    handleSearch = () => {
        const { valueSearch } = this.props
        if (valueSearch.trim().length == 0) {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: false } }))
        } else {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: true } }))
        }
        this.props.dispatch(disapatchWithdraw({ type: ON_SEARCH_WITHDRAW, payload: { shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex].value?.id } }))
    }

    onClearSearch = () => {
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'valueSearch', value: '' } }))
        this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW, payload: { shopId: this.state.shopSelectedIndex && this.props.listShop[this.state.shopSelectedIndex].value?.id } }))
    }

    openModalWithdrawSeller = (list) => {
        this.props.dispatch(disapatchWithdraw({ type: OPEN_MODAL_WITHDRAW_PREVIEW, payload: { data: [], headerData: list } }))
        this.props.dispatch(disapatchWithdraw({ type: FETCH_ORDER_SELLER_WITHDRAW, payload: { id: list.id } }))
    }

    checkTab = (status) => {
        if (this.props.activeTab === status) {
            return true
        }
        return false
    }

    onChangeShop = (value) => {
        this.setState({
            shopSelected: this.props.listShop[value].value,
            shopSelectedIndex: value
        }, () => {
            this.props.dispatch(disapatchWithdraw({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(disapatchWithdraw({ type: GET_LIST_WITHDRAW, payload: { shopId: this.props.listShop[value].value?.id } }))
        })
    }

    render() {
        const { activeTab, withdrawList, selectBox, selectWithdraw, page, limit, total, statusSearch, priceTransfer, modalWithdrawPreview, listShop } = this.props
        return (
            <React.Fragment>
                <div id="withdraw-container">

                    <h3>รายการที่ถอนได้</h3>

                    {getUserRole() === SUPER_ADMIN &&
                        <div className="w-50 mb-4">
                            {/* className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name  */}
                            <SelectInput
                                options={listShop}
                                iconClearSearch={false}
                                onChange={this.onChangeShop}
                                value={this.state.shopSelected && listShop.findIndex((item) => item.label === this.state.shopSelected.name)}
                            />
                        </div>
                    }



                    {/* <div className="input-container">
                        <InputSearch
                            placeholder={'ค้นหาจากหมายเลขออเดอร์'}
                            onChange={(e) => this.onChangeSearch(e)}
                            onKeyPress={this.onKeyPressSearch}
                            onClearSearch={() => this.onClearSearch()}
                            handleSearch={() => this.handleSearch()}
                            statusSearch={statusSearch}
                        />
                    </div> */}

                    {/* {withdrawList.data?.length == 0 ? null : <PaginationComponent
                        defaultCurrent={1}
                        total={total}
                        onChange={(page) => this.onChangePage(page)}
                        pageSize={limit}
                        pageSizeOptions={[10, 20, 40]}
                        onShowSizeChange={this.onShowSizeChange}
                        current={page}
                    />} */}

                    <div>
                        <Nav tabs>
                            {headerTab.map((data, idx) => (
                                <NavItem onClick={() => this.toggle(data.value)} key={idx}>
                                    <NavLink className={classnames({ 'active': activeTab === data.value })} >
                                        {data.label}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>

                    <div className="detail-withdraw">
                        {activeTab === withdrawStatus.TRANSFERRED ?
                            <h6 className="head-content head-content-primary">รายการที่โอนเงินแล้ว</h6> :
                            <div className="text-detail-withdraw">
                                <span className="head-content head-content-primary">
                                    {activeTab === 'processing' ? 'กำลังดำเนินการขอถอนเงิน' : 'กรุณาเลือกรายการที่ต้องการถอนเงิน'}
                                </span>
                                <span className="content content-primary text-danger">รอบการโอนเงินของระบบคือทุกวันศุกร์ (วันหยุดนักขัตฤกษ์จะทำการโอนให้ในวันทำการถัดไป)</span>
                            </div>
                        }
                        {/* <div className="text-detail-withdraw">
                            <span className="head-content head-content-primary">กรุณาเลือกรายการที่ต้องการถอนเงิน</span>
                            <span className="content content-primary text-danger">รอบการโอนเงินของระบบคือทุกวันศุกร์</span>
                        </div> */}
                        {activeTab === withdrawStatus.PROCESSING ?
                            <div>
                                <div className="text-detail-withdraw align-items-end">
                                    <span className="head-content head-content-primary color-success">จำนวนเงิน :  {numeral(priceTransfer).format('0,0.00')} บาท</span>
                                    <span className="content content-primary">รายการทั้งหมด {withdrawList.count || 0} รายการ</span>
                                </div>
                            </div> : null
                        }

                        {
                            activeTab === withdrawStatus.NEVER ?
                                <div className="d-flex">
                                    {
                                        withdrawList.data?.length === 0 ? null :
                                            <div className="text-detail-withdraw align-items-end">
                                                <span className="head-content head-content-primary color-success">จำนวนเงิน :  {numeral(priceTransfer).format('0,0.00')} บาท</span>
                                                <span className="content content-primary">รายการทั้งหมด {withdrawList.count || 0} รายการ</span>
                                            </div>
                                    }
                                    {getUserRole() === OWNER &&
                                        <button className={classnames("btn", { 'btn-submit': withdrawList.data?.length > 0, "btn-disabled": withdrawList.data?.length == 0 })}
                                            onClick={() => this.handleConfirm()}
                                            disabled={withdrawList.data?.length == 0}
                                        // disabled={true}
                                        >
                                            Withdraw
                                </button>
                                    }

                                </div>
                                : null
                        }


                    </div>

                    <div>
                        <TableHeader
                            type={'header'}
                            dataHeader={
                                [
                                    // <Checkbox onChange={() => this.handleSelectAllWithdraw()} checked={selectWithdraw.length == withdrawList.data?.length && withdrawList.data?.length != 0} disabled={withdrawList.data?.length == 0} />,
                                    'ลำดับ',
                                    this.checkTab(withdrawStatus.NEVER) ? 'หมายเลขออเดอร์' : 'หมายเลขอ้างอิง',
                                    getUserRole() === SUPER_ADMIN ? 'ชื่อร้านค้า' : null,
                                    // this.checkTab(withdrawStatus.NEVER) ? 'ชื่อร้านค้า' : null,
                                    this.checkTab(withdrawStatus.NEVER) ? 'ค่าสินค้า' : 'วันที่กดถอน',
                                    this.checkTab(withdrawStatus.NEVER) ? 'ค่าขนส่ง' : <span className="text-right" style={{paddingLeft:'10px'}}>ค่าสินค้าทั้งหมด</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? <span className="text-right" style={{padding:'10px'}}>ส่วนลดโปรโมชั่น</span> : <span className="text-right pl-1 pr-1">ค่าขนส่งทั้งหมด</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? <span className="text-right">ยอดที่ต้องชำระ</span> : <span className="text-right pl-1 pr-1">ส่วนลดโปรโมชั่นทั้งหมด</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการชำระเงิน</span> : <span className="text-right pl-1 pr-1">ยอดที่ต้องชำระทั้งหมด</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการขายสินค้า</span> : <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการชำระเงิน</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? 'รายได้สุทธิ' : <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการขายสินค้า</span>,
                                    this.checkTab(withdrawStatus.NEVER) ? <span className="text-center pl-2">วันที่ลูกค้าได้รับสินค้า</span> : 'รายได้สุทธิ',
                                    // activeTab === withdrawStatus.PROCESSING ? 'วันที่โอนเงิน' : null,
                                    this.checkTab(withdrawStatus.NEVER) ? 'วันที่กดถอนได้' : this.checkTab(withdrawStatus.PROCESSING) ? 'สถานะการอนุมัติ' : activeTab === withdrawStatus.TRANSFERRED ? 'วันที่โอนเงิน' : null,
                                    // this.checkTab(withdrawStatus.PROCESSING) ? 'สถานะการอนุมัติ' : activeTab === withdrawStatus.TRANSFERRED ? 'วันที่โอนเงิน' : null,
                                    // activeTab === withdrawStatus.NEVER || activeTab === withdrawStatus.TRANSFERRED ? null : 'สถานะการอนุมัติ'
                                ]
                            }
                            selectBox={selectBox}
                            colWidth={
                                this.checkTab(withdrawStatus.NEVER) ?
                                ['6%','11%', getUserRole() === SUPER_ADMIN ? '11%' : '0','8%','8%','13%','10%','10%','10%','8%','9%','11%'] :
                                    ['4%', '14%', getUserRole() === SUPER_ADMIN ? '10%' : '0', '9%', '10%', '7%', '10%', '10%', '10%', '10%', '9%', '11%']
                            }
                            colSide={['center', 'center', 'center','right', 'right', 'right', 'right', 'right', 'right', 'right', this.checkTab(withdrawStatus.NEVER) ? 'right' : 'right', 'center']}
                        />

                        {withdrawList.data?.length == 0 ?
                            <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                                <img src={noItem} alt="noItem" />
                                <span className="mt-2 font-secondary">ไม่มีรายการ</span>
                            </div> :
                            withdrawList.data && withdrawList.data.map((list, idx) => (
                                <TableHeader key={idx}
                                    handleClick={activeTab === withdrawStatus.NEVER ? null : () => this.openModalWithdrawSeller(list)}
                                    dataHeader={
                                        [
                                            // <Checkbox checked={this.checkDuplicateArr(selectWithdraw, list.id)} onChange={() => this.onAddWithdrawId(list)} disabled={false} key={list.id} />,
                                            `${(page - 1) * limit + idx + 1}`,
                                            `${activeTab === withdrawStatus.NEVER ? list.orderNo || '' : list.refNumber || ''}`,
                                            getUserRole() === SUPER_ADMIN ? list.shop.name : null,
                                            // this.checkTab(withdrawStatus.NEVER) ? list.shop.name : null,
                                            `${activeTab === withdrawStatus.NEVER ? `฿ ${numeral(list.priceNet).format('0,0.00')}` : activeTab === withdrawStatus.PROCESSING ? displayDateThShortYear(list.requestedDate) : activeTab === withdrawStatus.TRANSFERRED ? displayDateThShortYear(list.requestedDate) : ''}`,
                                            activeTab === withdrawStatus.NEVER ? <span>฿ {numeral(list.priceShippingNet).format('0,0.00')}</span> : <span className="text-right">฿ {numeral(list.totalItemPrice).format('0,0.00') || '0'}</span>,
                                            activeTab === withdrawStatus.NEVER ? <span style={{padding:'10px'}}> ฿ {numeral(list.withdraw?.totalDiscount).format('0,0.00')}</span> : <span className="text-right" style={{paddingLeft:'5px'}}>฿ {numeral(list.totalShippingFee).format('0,0.00')}</span>,
                                            `฿ ${activeTab === withdrawStatus.NEVER ? numeral(list.withdraw?.totalAmount).format('0,0.00') : numeral(list.totalDiscount).format('0,0.00')}`,
                                            `฿ ${activeTab === withdrawStatus.NEVER ? numeral(list.withdraw?.totalPaymentFee).format('0,0.00') : numeral(list.totalAmount).format('0,0.00')}`,
                                            `฿ ${activeTab === withdrawStatus.NEVER ? numeral(list.withdraw?.totalCommission).format('0,0.00') : numeral(list.totalPaymentFee).format('0,0.00')}`,
                                            activeTab === withdrawStatus.NEVER ? <span className="color-success"> ฿ {numeral(list.withdraw?.totalRevenue).format('0,0.00')}</span> : <span> ฿ {numeral(list.totalCommission).format('0,0.00')}</span>,
                                            activeTab === withdrawStatus.NEVER ? <span className="pl-1">{list.deliveredAt && displayDateThShortYear(list.deliveredAt)}</span> : <span className="color-success"> ฿ {numeral(list.totalRevenue).format('0,0.00')}</span>,

                                            // activeTab === withdrawStatus.TRANSFERRED ? list.transferredDate && displayDateThShortYear(list.transferredDate) : 
                                            activeTab === withdrawStatus.NEVER ? <span className={classnames({'color-primary': !moment(list.withdraw?.withdrawDate).isAfter(moment().startOf('day'))})}>{list.withdraw?.withdrawDate && displayDateThShortYear(list.withdraw?.withdrawDate)}</span> : activeTab === withdrawStatus.PROCESSING && (list.status === statusWithdraw[2]) ?
                                                <span className="color-success">อนุมัติแล้ว</span> : activeTab === withdrawStatus.PROCESSING && (list.status === statusWithdraw[1] || list.status === statusWithdraw[0]) ? <span className="color-primary text-bold">รออนุมัติ</span> : activeTab === withdrawStatus.TRANSFERRED ? list.transferredDate && displayDateThShortYear(list.transferredDate) : null,
                                            // activeTab === withdrawStatus.PROCESSING && (list.status === statusWithdraw[2]) ? 
                                            // <span className="color-success">อนุมัติแล้ว</span> : activeTab === withdrawStatus.PROCESSING && (list.status === statusWithdraw[1] || list.status === statusWithdraw[0]) ? <span className="color-primary text-bold">รออนุมัติ</span> : null
                                        ]
                                    }
                                    type={'content'}
                                    selectBox={activeTab == withdrawStatus.NEVER ? true : false}
                                    colWidth={
                                        this.checkTab(withdrawStatus.NEVER) ?
                                        ['6%','11%', getUserRole() === SUPER_ADMIN ? '11%' : '0','8%','8%','13%','10%','10%','10%','8%','9%','11%'] :
                                            ['4%', '14%', getUserRole() === SUPER_ADMIN ? '10%' : '0', '9%', '10%', '7%', '10%', '10%', '10%', '10%', '9%', '11%']
                                    }
                                    hover={activeTab === withdrawStatus.NEVER ? false : true}
                                    colSide={['center', 'center', 'center', 'right', 'right', 'right', 'right', 'right', 'right', 'right', this.checkTab(withdrawStatus.NEVER) ? 'center' : 'right', 'center']}
                                />
                            ))}



                    </div>

                    {withdrawList.data?.length == 0 ? null : <PaginationComponent
                        defaultCurrent={1}
                        total={total}
                        onChange={(page) => this.onChangePage(page)}
                        pageSize={limit}
                        pageSizeOptions={[10, 30, 50]}
                        onShowSizeChange={this.onShowSizeChange}
                        current={page}
                    />}

                </div>

                <ModalWithdrawPreview
                    isOpen={modalWithdrawPreview.isOpen}
                    data={modalWithdrawPreview.data}
                    handleClose={() => this.props.dispatch(disapatchWithdraw({ type: CLOSE_MODAL_WITHDRAW_PREVIEW }))}
                    headerData={modalWithdrawPreview.headerData}
                // fetchData={() => this.props.dispatch(disapatchWithdraw({type: FETCH_ORDER_SELLER_WITHDRAW}))}
                >
                    <div className="body-content-box">
                        <TableHeader
                            dataHeader={[
                                'ลำดับ',
                                'หมายเลขออเดอร์',
                                'ค่าสินค้า',
                                'ค่าขนส่ง',
                                'ส่วนลดโปรโมชั่น',
                                'ยอดที่ต้องชำระ',
                                <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการชำระเงิน</span>,
                                <span className="text-right pl-1 pr-1">ค่าธรรมเนียมการขายสินค้า</span>,
                                'รายได้สุทธิ',
                                'วันที่ลูกค้าได้รับสินค้า',
                                (activeTab === withdrawStatus.PROCESSING ? 'วันที่กดถอน' : 'วันที่โอนเงิน')
                            ]}
                            colWidth={['5%', '15%', '8%', '8%', '13%', '12%', '10%', '10%', '9%', '17%', '8%']}
                            type={'header'}
                            colSide={['center', 'center', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'center', 'center']}
                        />
                        <div className="detail-body">
                            {modalWithdrawPreview && modalWithdrawPreview?.data?.map((order, idx) => (
                                <TableHeader
                                    dataHeader={[
                                        idx + 1,
                                        order.order.orderNo,
                                        `฿ ${numeral(order.totalItemPrice).format('0,0.00')}`,
                                        `฿ ${numeral(order.totalShippingFee).format('0,0.00')}`,
                                        `฿ ${numeral(order.totalDiscount).format('0,0.00')}`,
                                        `฿ ${numeral(order.totalAmount).format('0,0.00')}`,
                                        `฿ ${numeral(order.totalPaymentFee).format('0,0.00')}`,
                                        `฿ ${numeral(order.totalCommission).format('0,0.00')}`,
                                        <span className="color-success justify-content-end">฿ {numeral(order.totalRevenue).format('0,0.00')}</span>,
                                        order.order.deliveredAt && displayDateThShortYear(order.order.deliveredAt),
                                        activeTab === withdrawStatus.PROCESSING ? order.requestedDate && displayDateThShortYear(order.requestedDate) : order.transferredDate && displayDateThShortYear(order.transferredDate)
                                    ]}
                                    colWidth={['5%', '15%', '8%', '8%', '13%', '12%', '10%', '10%', '9%', '17%', '8%']}
                                    type={'content'}
                                    colSide={['center', 'center', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'center', 'center']}
                                />
                            ))}
                        </div>
                    </div>
                </ModalWithdrawPreview>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    withdrawList: state.withdraw.withdrawList,
    activeTab: state.withdraw.activeTab,
    selectBox: state.withdraw.selectBox,
    selectWithdraw: state.withdraw.selectWithdraw,
    priceTransfer: state.withdraw.priceTransfer,
    page: state.withdraw.page,
    limit: state.withdraw.limit,
    total: state.withdraw.total,
    valueSearch: state.withdraw.valueSearch,
    statusSearch: state.withdraw.statusSearch,
    modalWithdrawPreview: state.withdraw.modalWithdrawPreview,
    listShop: state.withdraw.listAllShop

})
export default connect(mapStateToProps)(WithDraw);