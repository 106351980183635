import React, { Component } from 'react'
import navLogo from '../images/icons/outlet-live-logo.svg'
import logoLogin from '../images/icons/logo-login.svg'
import { FETCH_USER_LOGIN, dispatchUser, SET_KEY_USER } from '../redux/actions/user'
import '../styles/_login.scss'
import { connect } from 'react-redux'
import { isAuth } from '../index'


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        if (isAuth) {
            window.location = "/manage/shop"
        }
    }

    onSetPassword = (e) => {
        this.props.dispatch(dispatchUser(({ type: SET_KEY_USER, payload: { key: 'errLogin', value: false } })))
        const x = document.getElementById('password')
        const icon = document.getElementById('eye')
        if (x.value.trim().length > 0) {
            if (x.type === "password") {
                icon.className = "show-password-ready"
            } else if (x.type === "text") {
                icon.className = "show-password-already"
            }

            // x.type = x.type
        }
        if (x.value.trim().length == 0) {
            icon.className = "icon-show-password"
            x.type = "password"
        }

        this.setState({
            password: e.target.value
        })
    }

    onShowPassword = () => {
        const x = document.getElementById("password");
        const icon = document.getElementById('eye')
        if (x.value.trim().length === 0) return
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (icon.className === "show-password-ready") {
            icon.className = "show-password-already"
        } else {
            icon.className = "show-password-ready"
        }
    }

    handleLogin = async () => {
        const { history } = this.props

        const email = document.getElementById('email');
        const password = document.getElementById('password')
        const data = {
            email: email.value,
            password: password.value
        }
        try {
            await this.props.dispatch(dispatchUser({ type: FETCH_USER_LOGIN, payload: { user: data, history } }))
            // history.push({ pathname: '/manage/addproduct', state: null })
        } catch (error) {

            throw error
        }
    }

    onSetEmail = (e) => {
        this.props.dispatch(dispatchUser(({ type: SET_KEY_USER, payload: { key: 'errLogin', value: false } })))
        this.setState({
            email: e.target.value
        })
    }



    render() {
        return (
            <div className="overflow-page" id="login">
                <div className="circle"></div>
                <div className="circle-2"></div>
                <div className="nav-container position-relative">
                    <nav className="nav-login">
                        <img src={navLogo} alt="logo" className="text-center" style={{ width: '100px', height: '45px' }} />
                    </nav>
                </div>
                <div className="login-container">
                    <div className="form-login">
                        <form className="m-auto login-form position-relative" onSubmit={e => { e.preventDefault(); this.handleLogin() }}>
                            <div className="border-bottom"></div>
                            <div>
                                <img src={logoLogin} alt="godang-cms" />
                            </div>
                            <div className="login-data">
                                <h2 className="font-weight-light">เข้าสู่ระบบ</h2>
                                <div className="form-group mt-4">
                                    <label htmlFor="email">ชื่อผู้ใช้ <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control-1" id="email" aria-describedby="emailHelp" placeholder="กรอกชื่อผู้ใช้" onChange={(e) => this.onSetEmail(e)} />
                                </div>
                                <React.Fragment>
                                    <label htmlFor="password">รหัสผ่าน <span style={{ color: 'red' }}>*</span> </label>
                                    <div className="form-group position-relative">
                                        <input type="password" className="password-input" id="password" value={this.state.password} placeholder="กรอกรหัสผ่าน" onChange={(e) => this.onSetPassword(e)} />
                                        <div className='icon-show-password' id="eye" onClick={() => this.onShowPassword()}> </div>
                                    </div>
                                </React.Fragment>
                            </div>
                            {this.props.user.errLogin ? <span className="text-danger">ชื่อหรือรหัสผ่านผิดพลาด</span> : null}
                            <button className="btn-login" type="submit">เข้าสู่ระบบ</button>
                        </form>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, null)(Login)