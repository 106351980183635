import host from "config/host"
import axios from '../config/axios'

class promocodeProvider {

    getListPromocode = async (params) => {
        try {
            let data = {
                limit: params.limit ? `&limit=${params.limit}` : '',
                page: params.page ? `&page=${params.page}` : '',
                status: params.status ? `&status=${params.status}` : '',
            }
            let filter = {
                kw: params.filter ? params.filter : ''
            }
            !params.filter && delete data.filter
            params.status === undefined && delete data.status

            const url = `${host.api}/coupons?${data.status}${data.page}${data.limit}${params.filter ? `&filter=${JSON.stringify(filter)}` : ''}`

            const res = await axios.get(url)
            return res
        } catch (error) {
            console.log('error', error)
            throw error
        }
    }

    verifyRefCode = async (params) => {
        try {
            const url = `${host.api}/coupons/verify?refcode1=${params.code}`
            const res = await axios.get(url)
            return res


            // return {
            //     data: {
            //         benefit: "product",
            //         benefitType: "coupon",
            //         campaignId: 52,
            //         coupon: {
            //             couponAction: "product",
            //             couponRole: "public",
            //             couponType: "discount",
            //             id: 17,
            //             promotionId: 18,
            //             refCode1: "OLL-1-EWL495",
            //             refCode2: null,
            //             shopId: 14,
            //             status: "hide",
            //         },
            //         id: 18,
            //         limitUser: null,
            //         mediaCover: "image/eed49ff8-95ec-4fb8-b21c-35e573949293.jpg",
            //         name: "Sabbat - True Wireless Sabbat X12 หูฟังบลูทูธ แบบเอียบัดไอโฟน",
            //         shopId: 14,
            //         startDate: "2021-02-02T05:00:00.000Z",
            //         status: "show",
            //         statusCustom: "processing",
            //         stopDate: "2021-02-28T16:59:00.000Z",
            //         productCode :'OLL-1-EWL495'
            //     }
            // }
        } catch (error) {
            console.log('error', error)
            throw error
        }
    }

    addPromocode = async (params) => {
        try {
            const url = `${host.api}/coupons`
            const res = await axios.post(url, params)
            return res
        } catch (error) {
            throw error
        }
    }

    updatePromocode = async (params) => {
        try {
            const url = `${host.api}/coupons/${params.id}`
            const res = await axios.patch(url, params.body)
            return res
        } catch (error) {
            throw error
        }
    }

    deletePromocode = async (params) => {
        try {
            const url = `${host.api}/coupons/${params.id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getDetailCoupon = async (params) => {
        try {
            const url = `${host.api}/coupons/${params.id}`
            const res = await axios.get(url)
            return res

            // return {
            //     data: {
            //         benefit: "product",
            //         benefitType: "coupon",
            //         campaignId: 52,
            //         coupon: {
            //             couponAction: "product",
            //             couponRole: "public",
            //             couponType: "discount",
            //             id: 17,
            //             promotionId: 18,
            //             refCode1: "OLL-1-EWL495",
            //             refCode2: null,
            //             shopId: 14,
            //             status: "hide",
            //         },
            //         id: 18,
            //         limitUser: null,
            //         mediaCover: "image/eed49ff8-95ec-4fb8-b21c-35e573949293.jpg",
            //         name: "Sabbat - True Wireless Sabbat X12 หูฟังบลูทูธ แบบเอียบัดไอโฟน",
            //         shopId: 14,
            //         startDate: "2021-02-02T05:00:00.000Z",
            //         status: "show",
            //         statusCustom: "processing",
            //         stopDate: "2021-02-28T16:59:00.000Z",
            //         productCode :'OLL-1-EWL495'
            //     }
            // }

        } catch (error) {
            throw error
        }
    }

}

export default new promocodeProvider()