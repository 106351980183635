import axios from 'axios';
import { setLocalStorage } from 'helper/utils';
import Cookies from 'js-cookie';
import host from './host';

const axiosRefreshToken = axios.create();
let from;
let data;
let getConfig

axiosRefreshToken.interceptors.request.use(function (config) {
    getConfig = config
    from = config.url?.split('/api/')[1];
    data = config.data

    const access_token = Cookies.get('token');
    config.headers['secret-key'] = host.tokenSecretKey
    // config.headers['channel'] = "cms";
    // config.headers['platform'] = navigator.platform;
    // config.headers['userAgent'] = navigator.userAgent;
    // if (access_token != null) 
    //     config.headers.Authorization = "Bearer " + access_token;
    // else 
    //     
       
    return config;
}
, function (err) {
    return Promise.reject(err);
});

axiosRefreshToken.interceptors.response.use(function (response) {

    // 
    // 
    // 
    // 
    // 
    

    return response;
}, async function (error) {
    const err = error.response

    if(err?.status == 401) {
        Cookies.remove('refreshToken')
        Cookies.remove('token')
        // Cookies.remove('user')
        setLocalStorage({key:'tempUrl',value: window.location.pathname})
        window.location.replace('/login')
    }
    return Promise.reject(error);
});


export default axiosRefreshToken;