import axios from '../config/axios';
import host from '../config/host'
import Cookies from 'js-cookie'

class shopProvider {
  getAllListShops = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // const header = {
        //     headers : {
        //         Authorization : "Bearer " + JSON.parse(Cookies.get('token')).accessToken
        //     }
        // }
        const result = await axios.get(`${host.api}/shops/listShopName`)
        resolve(result.data)
      } catch (error) {
        throw error
      }
    })
  }

  getShops = async (params) => {
    try {
      const url = `${host.api}/shops?limit=${params.limit}&page=${params.page}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      // throw error
      // console.log(error)
    }
  }

  getAllShops = async (params) => {
    try {
      const url = `${host.api}/shops`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      // throw error
    }
  }

  getShopsSearch = async (payload) => {
    try {
      const header = {
        headers: {
          Authorization:
            'Bearer ' + JSON.parse(sessionStorage.getItem('token')).accessToken,
        },
      }
      const { keyword } = payload
      const url = `${host.api}/shops/search?kw=${keyword}`
      const res = await axios.get(url, header)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  getShopDetail = async (payload) => {
    try {
      const { shopId } = payload
      const url = `${host.api}/shops/${shopId}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  updateShopDetail = async (payload) => {
    try {
      const { body, shopId } = payload
      const url = `${host.api}/shops/${shopId}`
      const res = await axios.patch(url, body)
      return res.data ? res.data : null
    } catch (error) {
      throw error
    }
  }

  onSearchShop = async (params) => {
    try {
      const url = `${host.api}/shops/search?kw=${params.search}&limit=${params.limit}&page=${params.page}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  createShop = async (payload) => {
    const { body, token } = payload

    try {
      const url = `${host.api}/Shops`
      const res = await axios.post(url, body, {
        headers: { Authorization: 'Bearer ' + token },
      })
      return res.data
    } catch (error) {
      return error
    }
  }

  getShopsHubs = async (payload) => {
    try {
      const { shopId } = payload

      const url = `${host.api}/shop-hubs/shop/${shopId}`
      const res = await axios.get(url)
      return res.data ? res.data : []
    } catch (error) {
      throw error
    }
  }

  createShopsHubs = async (payload) => {
    try {
      const url = `${host.api}/shop-hubs`
      const res = await axios.post(url, payload)
      return res.data
    } catch (error) {
      return error
    }
  }

  updateShopHub = async (params) => {
    try {
      const url = `${host.api}/shop-hubs/${params.id}`
      const res = await axios.patch(url, params.data)
      return res
    } catch (error) {
      throw error
    }
  }

  getListProvince = async (params) => {
    try {
      const url = `${host.api}/provinces`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  getListDistrict = async (idProvince) => {
    try {
      const url = `${host.api}/districts/province/${idProvince}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  getListSubDistrict = async (idDistrict) => {
    try {
      const url = `${host.api}/sub-districts/district/${idDistrict}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  craeteShopTax = async (params) => {
    try {
      const url = `${host.api}/shop-tax`
      const res = await axios.post(url, params)
      return res
    } catch (error) {
      throw error
    }
  }

  getShopTax = async (id) => {
    try {
      const url = `${host.api}/shop-tax/shop/${id}`
      const res = await axios.get(url)
      return res
    } catch (error) {
      throw error
    }
  }

  editShopTax = async ({ id, data }) => {
    try {
      const url = `${host.api}/shop-tax/${id}`
      const res = await axios.patch(url, data)
      return res
    } catch (error) {
      throw error
    }
  }
}

export default shopProvider