import {
  SET_LIST_SHOP_NAME,
  ADD_KEY_VALUE_SHOP,
  SET_SHOPS,
  SET_SHOPS_SEARCH,
  SET_SHOP_DETAIL,
  SET_CURRENT_SHOP,
  SET_CURRENT_POST,
  SET_PAGINATION_SHOP_DETAIL,
  SET_PAGINATION_POSTS,
  SET_ALL_SHOPS,
  SET_SHOPS_HUBS,
  SET_SHOP_TAX,
  CREATE_SHOP_FIELD,
} from '../actions/shop'

const initialState = {
  listShopName: [],
  shops: [],
  shopsSearch: [],
  shopDetail: null,
  shopsHubs: null,
  currentShop: JSON.parse(localStorage.getItem('shop')),
  currentPost: JSON.parse(localStorage.getItem('post')),
  page: 1,
  limit: 10,
  total: 0,
  allShops: [],
  pageSizeOptions: ['10', '20', '40'],
  paginationShopDetail: {
    page: 1,
    limit: 10,
    total: 0,
    pageSizeOptions: ['10', '20', '40'],
  },
  paginationPost: {
    page: 1,
    limit: 999,
    total: 0,
    pageSizeOptions: ['10', '20', '40'],
  },
  shopTax: {},
  errorShop: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
      case SET_LIST_SHOP_NAME:
        return {
          ...state,
          listShopName: payload.listShopName,
        }

      case ADD_KEY_VALUE_SHOP:
        return {
          ...state,
          [payload.key]: payload.value,
        }

      case SET_SHOPS:
        const { shops } = payload
        return {
          ...state,
          shops,
        }
      case SET_ALL_SHOPS:
        return {
          ...state,
          allShops: payload.allShops,
        }

      case SET_SHOPS_SEARCH:
        const { shopsSearch } = payload
        return {
          ...state,
          shopsSearch,
        }

      case SET_SHOP_DETAIL:
        const { shopDetail } = payload
        return {
          ...state,
          shopDetail,
        }

      case SET_SHOPS_HUBS:
        const { shopsHubs } = payload
        return {
          ...state,
          shopsHubs,
        }

      case SET_CURRENT_SHOP:
        const { shop } = payload
        localStorage.setItem('shop', JSON.stringify(shop))
        return {
          ...state,
          currentShop: shop,
        }

      case SET_CURRENT_POST:
        const { post } = payload
        localStorage.setItem('post', JSON.stringify(post))
        return {
          ...state,
          currentPost: post,
        }

      case SET_PAGINATION_SHOP_DETAIL:
        return {
          ...state,
          paginationShopDetail: {
            ...state.paginationShopDetail,
            [payload.key]: payload.value,
          },
        }

      case SET_PAGINATION_POSTS:
        return {
          ...state,
          paginationPost: {
            ...state.paginationPost,
            [payload.key]: payload.value,
          },
        }

      case SET_SHOP_TAX:
        return {
          ...state,
          shopTax: {
            ...payload,
          },
        }

      case CREATE_SHOP_FIELD:
        return {
          ...state,
          errorShop: payload,
        }

      default:
        return state
    }
}