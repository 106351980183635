export const postRerun = {
    "posts": [
        {
            "id": 43,
            "shopId": 6,
            "title": "new live",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614160776038"
            },
            "items": [],
            "postId": 975,
            "type": "vod",
            "upstreamKey": "ymRyYSkvbv7wt6Xn",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-24T10:04:10.016Z",
            "updatedAt": "2021-02-24T10:24:44.355Z"
        },
        {
            "id": 46,
            "shopId": 6,
            "title": "new live",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614163523197"
            },
            "items": [],
            "postId": 976,
            "type": "vod",
            "upstreamKey": "uaUJMR8ikVAGrknq",
            "upstreamStatus": "ready",
            "publishUrl": "/godang/hls.m3u8?v=uaUJMR8ikVAGrknq",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "readyAt": "2021-02-24T10:54:29.216Z",
            "deleted": 0,
            "createdAt": "2021-02-24T10:54:03.489Z",
            "updatedAt": "2021-02-24T10:54:29.216Z"
        },
        {
            "id": 51,
            "shopId": 6,
            "title": "new live",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614165520296"
            },
            "items": [],
            "postId": 977,
            "type": "vod",
            "upstreamKey": "KBUGf3G4wkzqeW3e",
            "upstreamStatus": "ready",
            "publishUrl": "/godang/vod/hls.m3u8?v=KBUGf3G4wkzqeW3e",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "readyAt": "2021-02-24T11:23:30.796Z",
            "deleted": 0,
            "createdAt": "2021-02-24T11:23:06.286Z",
            "updatedAt": "2021-02-24T11:23:30.797Z"
        },
        {
            "id": 55,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614237443374"
            },
            "items": [],
            "postId": 979,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "72gucw8gN9wtwCWs",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:17:23.773Z",
            "updatedAt": "2021-02-25T07:17:23.773Z"
        },
        {
            "id": 56,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614238722571"
            },
            "items": [],
            "postId": 980,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "Gb2x7vY7eeJ7VBGT",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:38:42.998Z",
            "updatedAt": "2021-02-25T07:38:42.998Z"
        },
        {
            "id": 57,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614238740707"
            },
            "items": [],
            "postId": 981,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "6zkU5acbpdz4sA3F",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:39:01.148Z",
            "updatedAt": "2021-02-25T07:39:01.148Z"
        },
        {
            "id": 58,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614238901292"
            },
            "items": [],
            "postId": 982,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "MGaa3jP8RLDUdg7t",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:41:41.756Z",
            "updatedAt": "2021-02-25T07:41:41.756Z"
        },
        {
            "id": 59,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614238974266"
            },
            "items": [],
            "postId": 983,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "VvoesyJ6xTpJNZyK",
            "upstreamStatus": "processing",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:42:54.698Z",
            "updatedAt": "2021-02-25T07:42:54.698Z"
        },
        {
            "id": 60,
            "shopId": 6,
            "title": "new vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614239001896"
            },
            "items": [],
            "postId": 984,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "oVERB39GGdKi4Ty3",
            "upstreamStatus": "processing",
            "publishUrl": "/godang/vod/hls.m3u8?v=oVERB39GGdKi4Ty3",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T07:43:22.340Z",
            "updatedAt": "2021-02-25T07:43:22.340Z"
        },
        {
            "id": 61,
            "shopId": 6,
            "title": "new poker vod",
            "cover": {
                "type": "image",
                "path": "image/049d1c09-dc96-43ef-8618-63205cee8c75.jpg",
                "id": 179
            },
            "seo": {
                "permalink": "1614240820699"
            },
            "items": [],
            "postId": 985,
            "type": "vod",
            "upstreamProtocol": "http",
            "upstreamKey": "V7SeCHCiSed3LThu",
            "upstreamStatus": "processing",
            "publishUrl": "/godang/vod/live.m3u8?v=V7SeCHCiSed3LThu",
            "lastViews": 0,
            "totalViews": 0,
            "duration": 0,
            "status": "private",
            "rotateSupported": false,
            "deleted": 0,
            "createdAt": "2021-02-25T08:13:41.119Z",
            "updatedAt": "2021-02-25T08:13:41.286Z"
        }
    ],
    "count": 15
}

export const postsAll = {
    "posts": [
        {
            "descriptions": [],
            "coverPathId": 1117,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "vod",
            "hasRotated": false,
            "status": "hide",
            "deleted": 0,
            "releaseDate": "2020-12-24T10:47:06.598Z",
            "readyDate": "2020-12-24T10:46:54.217Z",
            "publishDate": "2020-12-24T10:47:06.599Z",
            "finishDate": "2020-12-24T10:49:26.768Z",
            "expireDate": "2020-12-31T10:47:06.599Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2020-12-24T10:50:00.053Z",
            "shopId": 7,
            "staffId": 50,
            "title": "0_o",
            "seo": {
                "permalink": "1608806181869"
            },
            "id": 262,
            "createdAt": "2020-12-24T10:36:21.941Z",
            "updatedAt": "2020-12-24T10:56:15.907Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe46f258bba97029c3850f9",
            "streamKey": "NwoRwemX2cPc4eWx",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=NwoRwemX2cPc4eWx",
            "remoteAddress": "171.96.75.24",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=NwoRwemX2cPc4eWx",
            "publishUrl": "/720/live/NwoRwemX2cPc4eWx.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/90df6f48-dc2e-4138-9d72-dedc2c21999d.jpg",
                "id": 1117
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 262,
                    "productId": 37,
                    "skuId": 191,
                    "updatedAt": "2020-12-24T10:36:22.550Z",
                    "id": 617,
                    "coverPath": {
                        "type": "image",
                        "path": "image/965ac02a-c5ef-43fd-a1fd-e1cc8be6cbbb.jpg",
                        "id": 732
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/f60bd6a8-73ee-462d-865e-f74ca22ae2d4.jpg",
                        "id": 736
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 411,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2020-12-24T13:33:31.582Z",
            "readyDate": "2020-12-24T13:33:18.395Z",
            "publishDate": "2020-12-24T13:33:31.582Z",
            "finishDate": "2020-12-24T13:38:35.856Z",
            "expireDate": "2020-12-31T13:38:35.856Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2020-12-24T13:39:00.061Z",
            "shopId": 7,
            "staffId": 50,
            "title": "itnw",
            "seo": {
                "permalink": "1608816758311"
            },
            "id": 281,
            "createdAt": "2020-12-24T13:32:38.388Z",
            "updatedAt": "2020-12-24T13:43:59.521Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe4987655315203cfb665ba",
            "streamKey": "XK68bsrJdP7y3Qxo",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=XK68bsrJdP7y3Qxo",
            "remoteAddress": "61.90.19.178",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=XK68bsrJdP7y3Qxo",
            "publishUrl": "/720/live/XK68bsrJdP7y3Qxo.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e61559c9-9e12-4b29-9c95-d3d797b64512.jpg",
                "id": 411
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 281,
                    "productId": 32,
                    "skuId": 91,
                    "updatedAt": "2020-12-24T13:32:39.011Z",
                    "id": 644,
                    "coverPath": {
                        "type": "image",
                        "path": "image/a9b96935-0723-485d-8847-6b2753db6609.jpg",
                        "id": 684
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/103462cc-ca44-4bf6-80bc-cb7e88177582.jpg",
                        "id": 685
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 411,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2020-12-24T14:06:56.544Z",
            "readyDate": "2020-12-24T14:06:43.416Z",
            "publishDate": "2020-12-24T14:06:56.544Z",
            "finishDate": "2020-12-24T14:10:00.558Z",
            "expireDate": "2020-12-31T14:10:00.558Z",
            "paymentExpireUnit": 111,
            "chatLogCreatedAt": "2020-12-24T14:11:00.049Z",
            "shopId": 7,
            "staffId": 50,
            "title": "mv",
            "seo": {
                "permalink": "1608818685957"
            },
            "id": 283,
            "createdAt": "2020-12-24T14:04:46.033Z",
            "updatedAt": "2020-12-24T14:11:00.051Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe49ffdfe918603c7484e00",
            "streamKey": "GhvDSq8ePGDDFHLY",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=GhvDSq8ePGDDFHLY",
            "remoteAddress": "61.90.19.178",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=GhvDSq8ePGDDFHLY",
            "publishUrl": "/720/live/GhvDSq8ePGDDFHLY.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e61559c9-9e12-4b29-9c95-d3d797b64512.jpg",
                "id": 411
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 283,
                    "productId": 32,
                    "skuId": 91,
                    "updatedAt": "2020-12-24T14:04:46.677Z",
                    "id": 646,
                    "coverPath": {
                        "type": "image",
                        "path": "image/a9b96935-0723-485d-8847-6b2753db6609.jpg",
                        "id": 684
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/103462cc-ca44-4bf6-80bc-cb7e88177582.jpg",
                        "id": 685
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 1167,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2020-12-25T04:33:51.349Z",
            "readyDate": "2020-12-25T04:33:37.838Z",
            "publishDate": "2020-12-25T04:33:51.349Z",
            "finishDate": "2020-12-25T04:34:45.311Z",
            "expireDate": "2021-01-01T04:34:45.311Z",
            "paymentExpireUnit": 20,
            "chatLogCreatedAt": "2020-12-25T04:37:00.058Z",
            "shopId": 7,
            "staffId": 50,
            "title": "1080p",
            "seo": {
                "permalink": "1608870777799"
            },
            "id": 296,
            "createdAt": "2020-12-25T04:32:57.896Z",
            "updatedAt": "2020-12-25T04:37:17.075Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe56b7921d7f204ab2930de",
            "streamKey": "XyN2nKSrWfh5zATu",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=XyN2nKSrWfh5zATu",
            "remoteAddress": "61.90.19.178",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=XyN2nKSrWfh5zATu",
            "publishUrl": "/720/live/XyN2nKSrWfh5zATu.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/10f19b0f-8ed3-420e-a58e-358baafd1ea9.jpg",
                "id": 1167
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 296,
                    "productId": 35,
                    "skuId": 94,
                    "updatedAt": "2020-12-25T04:32:58.566Z",
                    "id": 676,
                    "coverPath": {
                        "type": "image",
                        "path": "image/1555a330-d39f-4001-9acf-f2f949679c0f.jpg",
                        "id": 717
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/81357635-7455-43af-a8a0-8983ff579068.jpg",
                        "id": 719
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 411,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2020-12-25T04:37:58.455Z",
            "readyDate": "2020-12-25T04:37:44.972Z",
            "publishDate": "2020-12-25T04:37:58.455Z",
            "finishDate": "2020-12-25T04:43:48.626Z",
            "expireDate": "2021-01-01T04:43:48.626Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2020-12-25T04:44:00.059Z",
            "shopId": 7,
            "staffId": 50,
            "title": "outlet-profile",
            "seo": {
                "permalink": "1608871034675"
            },
            "id": 297,
            "createdAt": "2020-12-25T04:37:14.772Z",
            "updatedAt": "2020-12-25T04:45:24.249Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe56c7a289b0b04a3d81177",
            "streamKey": "k6c7HRciaYdHpDtw",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=k6c7HRciaYdHpDtw",
            "remoteAddress": "61.90.19.178",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=k6c7HRciaYdHpDtw",
            "publishUrl": "/720/live/k6c7HRciaYdHpDtw.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e61559c9-9e12-4b29-9c95-d3d797b64512.jpg",
                "id": 411
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 297,
                    "productId": 41,
                    "skuId": 193,
                    "updatedAt": "2020-12-25T04:37:15.425Z",
                    "id": 677,
                    "coverPath": {
                        "type": "image",
                        "path": "image/f5e4607c-6d33-4406-849c-aee448e993ab.jpg",
                        "id": 761
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/1617848d-d29f-4b8b-a36a-11203326c00a.jpg",
                        "id": 767
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 411,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2020-12-25T05:01:12.524Z",
            "readyDate": "2020-12-25T05:00:59.357Z",
            "publishDate": "2020-12-25T05:01:12.524Z",
            "finishDate": "2020-12-25T05:03:02.663Z",
            "expireDate": "2021-01-01T05:03:02.663Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2020-12-25T05:04:00.061Z",
            "shopId": 7,
            "staffId": 50,
            "title": "ืทดสอบๆ",
            "seo": {
                "permalink": "1608872419678"
            },
            "id": 300,
            "createdAt": "2020-12-25T05:00:19.748Z",
            "updatedAt": "2020-12-25T05:09:23.329Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5fe571e3289b0b04a3d8119f",
            "streamKey": "2cAFNdzu6EKven2t",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=2cAFNdzu6EKven2t",
            "remoteAddress": "61.90.19.178",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=2cAFNdzu6EKven2t",
            "publishUrl": "/720/live/2cAFNdzu6EKven2t.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e61559c9-9e12-4b29-9c95-d3d797b64512.jpg",
                "id": 411
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 300,
                    "productId": 34,
                    "skuId": 93,
                    "updatedAt": "2020-12-25T05:00:20.369Z",
                    "id": 681,
                    "coverPath": {
                        "type": "image",
                        "path": "image/661da8f0-ace1-49fc-814b-816994ffae1a.jpg",
                        "id": 702
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/003a8f72-281e-40cc-899f-bcaa8ffa3e4e.jpg",
                        "id": 709
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 411,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "live",
            "hasRotated": false,
            "status": "show",
            "deleted": 0,
            "releaseDate": "2021-01-05T06:56:40.145Z",
            "readyDate": "2021-01-05T06:56:25.914Z",
            "publishDate": "2021-01-05T06:56:40.145Z",
            "finishDate": "2021-01-05T07:02:04.469Z",
            "expireDate": "2021-01-12T07:02:04.469Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2021-01-05T07:03:00.047Z",
            "shopId": 7,
            "staffId": 50,
            "title": "2021A",
            "seo": {
                "permalink": "1609829743408"
            },
            "id": 347,
            "createdAt": "2021-01-05T06:55:43.529Z",
            "updatedAt": "2021-01-05T07:03:16.327Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5ff40d6f02a58a00e87a4de5",
            "streamKey": "sbeZhYwH33oiUywv",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=sbeZhYwH33oiUywv",
            "remoteAddress": "171.96.73.94",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=sbeZhYwH33oiUywv",
            "publishUrl": "/720/live/sbeZhYwH33oiUywv.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e61559c9-9e12-4b29-9c95-d3d797b64512.jpg",
                "id": 411
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 347,
                    "productId": 39,
                    "skuId": 98,
                    "updatedAt": "2021-01-05T06:56:28.017Z",
                    "id": 766,
                    "coverPath": {
                        "type": "image",
                        "path": "image/e625b5d9-4f6b-424e-b637-a59275aad996.jpg",
                        "id": 746
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/0e7bd6a3-9861-48ec-b822-7d816edac017.jpg",
                        "id": 749
                    }
                },
                {
                    "order": 2,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 347,
                    "productId": 42,
                    "skuId": 266,
                    "updatedAt": "2021-01-05T06:56:29.097Z",
                    "id": 767,
                    "coverPath": {
                        "type": "image",
                        "path": "image/ac0170f9-57ad-4fe5-b765-30af58d06614.jpg",
                        "id": 774
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/ac0170f9-57ad-4fe5-b765-30af58d06614.jpg",
                        "id": 774
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 1110,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "vod",
            "hasRotated": false,
            "status": "hide",
            "deleted": 0,
            "releaseDate": "2021-01-05T07:34:27.911Z",
            "readyDate": "2021-01-05T07:34:14.739Z",
            "publishDate": "2021-01-05T07:34:27.911Z",
            "finishDate": "2021-01-05T07:37:42.285Z",
            "expireDate": "2021-01-12T07:34:27.911Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2021-01-05T07:38:00.052Z",
            "shopId": 7,
            "staffId": 50,
            "title": "202101A",
            "seo": {
                "permalink": "1609831944158"
            },
            "id": 353,
            "createdAt": "2021-01-05T07:32:24.290Z",
            "updatedAt": "2021-01-05T07:38:32.832Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5ff41608476c5b0183d8e79c",
            "streamKey": "5p337GoJzAwC2EnY",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=5p337GoJzAwC2EnY",
            "remoteAddress": "171.96.73.94",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=5p337GoJzAwC2EnY",
            "publishUrl": "/720/live/5p337GoJzAwC2EnY.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/04566d0e-0a60-4e7d-bc82-6b3df9ac7756.jpg",
                "id": 1110
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 353,
                    "productId": 40,
                    "skuId": 192,
                    "updatedAt": "2021-01-05T07:32:24.991Z",
                    "id": 780,
                    "coverPath": {
                        "type": "image",
                        "path": "image/221268ed-2027-4b29-901c-ee84dc0ef60e.jpg",
                        "id": 754
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/ca5e00a2-74fa-4642-a6f7-df10822c3a77.jpg",
                        "id": 757
                    }
                },
                {
                    "order": 2,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 353,
                    "productId": 42,
                    "skuId": 266,
                    "updatedAt": "2021-01-05T07:32:24.993Z",
                    "id": 781,
                    "coverPath": {
                        "type": "image",
                        "path": "image/ac0170f9-57ad-4fe5-b765-30af58d06614.jpg",
                        "id": 774
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/ac0170f9-57ad-4fe5-b765-30af58d06614.jpg",
                        "id": 774
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 1110,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "vod",
            "hasRotated": false,
            "status": "hide",
            "deleted": 0,
            "releaseDate": "2021-01-05T07:47:52.657Z",
            "readyDate": "2021-01-05T07:47:39.289Z",
            "publishDate": "2021-01-05T07:47:52.657Z",
            "finishDate": "2021-01-05T07:49:27.666Z",
            "expireDate": "2021-01-12T07:47:52.658Z",
            "paymentExpireUnit": 60,
            "chatLogCreatedAt": "2021-01-05T07:50:00.048Z",
            "shopId": 7,
            "staffId": 50,
            "title": "2564A",
            "seo": {
                "permalink": "1609832794273"
            },
            "id": 356,
            "createdAt": "2021-01-05T07:46:34.360Z",
            "updatedAt": "2021-01-05T07:50:53.883Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5ff4195a476c5b0183d8e7a7",
            "streamKey": "dMYgguADe9XPQP82",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=dMYgguADe9XPQP82",
            "remoteAddress": "171.96.73.94",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=dMYgguADe9XPQP82",
            "publishUrl": "/720/live/dMYgguADe9XPQP82.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/04566d0e-0a60-4e7d-bc82-6b3df9ac7756.jpg",
                "id": 1110
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 356,
                    "productId": 35,
                    "skuId": 94,
                    "updatedAt": "2021-01-05T07:46:35.024Z",
                    "id": 787,
                    "coverPath": {
                        "type": "image",
                        "path": "image/1555a330-d39f-4001-9acf-f2f949679c0f.jpg",
                        "id": 717
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/81357635-7455-43af-a8a0-8983ff579068.jpg",
                        "id": 719
                    }
                },
                {
                    "order": 2,
                    "showOn": [
                        "shelf"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 356,
                    "productId": 37,
                    "skuId": 191,
                    "updatedAt": "2021-01-05T07:46:35.026Z",
                    "id": 788,
                    "coverPath": {
                        "type": "image",
                        "path": "image/965ac02a-c5ef-43fd-a1fd-e1cc8be6cbbb.jpg",
                        "id": 732
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/f60bd6a8-73ee-462d-865e-f74ca22ae2d4.jpg",
                        "id": 736
                    }
                }
            ]
        },
        {
            "descriptions": [],
            "coverPathId": 1163,
            "ribbon": [],
            "liveStatus": "finish",
            "liveViews": 0,
            "type": "vod",
            "hasRotated": false,
            "status": "hide",
            "deleted": 0,
            "releaseDate": "2021-01-05T11:06:35.311Z",
            "readyDate": "2021-01-05T11:06:21.884Z",
            "publishDate": "2021-01-05T11:06:35.311Z",
            "finishDate": "2021-01-05T11:11:06.776Z",
            "expireDate": "2021-01-12T11:06:35.311Z",
            "paymentExpireUnit": 1,
            "chatLogCreatedAt": "2021-01-05T11:12:00.048Z",
            "shopId": 7,
            "staffId": 50,
            "title": "มาจ้าาาาา",
            "seo": {
                "permalink": "1609844688918"
            },
            "id": 367,
            "createdAt": "2021-01-05T11:04:48.987Z",
            "updatedAt": "2021-01-05T11:12:13.354Z",
            "__v": 0,
            "createdBy": 50,
            "roomId": "5ff447d0476c5b0183d8e849",
            "streamKey": "Mp4uzAXLVhHNjzwD",
            "streamUrl": "rtmp://obs-rtmp.godang.live:1935/live?streamKey=Mp4uzAXLVhHNjzwD",
            "remoteAddress": "171.96.73.94",
            "streamPlatform": "obs",
            "hlsUrl": "/godang/hls.m3u8?v=Mp4uzAXLVhHNjzwD",
            "publishUrl": "/720/live/Mp4uzAXLVhHNjzwD.flv",
            "updatedBy": 50,
            "playlist": null,
            "cover": {
                "type": "image",
                "path": "image/e2eab05b-619c-4cef-9780-9f31582f3a0f.jpg",
                "id": 1163
            },
            "items": [
                {
                    "order": 1,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 367,
                    "productId": 32,
                    "skuId": 91,
                    "updatedAt": "2021-01-05T11:06:31.409Z",
                    "id": 803,
                    "coverPath": {
                        "type": "image",
                        "path": "image/a9b96935-0723-485d-8847-6b2753db6609.jpg",
                        "id": 684
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/103462cc-ca44-4bf6-80bc-cb7e88177582.jpg",
                        "id": 685
                    }
                },
                {
                    "order": 2,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 367,
                    "productId": 33,
                    "skuId": 92,
                    "updatedAt": "2021-01-05T11:06:31.980Z",
                    "id": 802,
                    "coverPath": {
                        "type": "image",
                        "path": "image/3ba27607-199f-4b5e-a683-ab5f2615a8cc.jpg",
                        "id": 213
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/69f84092-ad20-472f-98f7-68a5e1ad555a.jpg",
                        "id": 215
                    }
                },
                {
                    "order": 3,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 367,
                    "productId": 34,
                    "skuId": 93,
                    "updatedAt": "2021-01-05T11:06:33.306Z",
                    "id": 804,
                    "coverPath": {
                        "type": "image",
                        "path": "image/661da8f0-ace1-49fc-814b-816994ffae1a.jpg",
                        "id": 702
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/003a8f72-281e-40cc-899f-bcaa8ffa3e4e.jpg",
                        "id": 709
                    }
                },
                {
                    "order": 4,
                    "showOn": [
                        "shelf",
                        "live"
                    ],
                    "status": "show",
                    "shopId": 7,
                    "postId": 367,
                    "productId": 35,
                    "skuId": 94,
                    "updatedAt": "2021-01-05T11:06:34.418Z",
                    "id": 805,
                    "coverPath": {
                        "type": "image",
                        "path": "image/1555a330-d39f-4001-9acf-f2f949679c0f.jpg",
                        "id": 717
                    },
                    "imagePath": {
                        "type": "image",
                        "path": "image/81357635-7455-43af-a8a0-8983ff579068.jpg",
                        "id": 719
                    }
                }
            ]
        }
    ],
    "count": 25
}