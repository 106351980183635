import { Select } from 'antd';
import { ButtonIcon } from 'components/output';
import React, { Component } from 'react'
import IconAdd from 'images/icons/increse.svg'
// import {ReactComponent as IconAdd} from 'images/icons/increse.svg'
import 'styles/_listlogisticprice.scss';
import TableHeader from 'components/TableHeader';
import { Link } from 'react-router-dom';
import { ReactComponent as IconTrash } from 'images/icons/trash.svg'
import { ReactComponent as IconEdit } from 'images/icons/edit.svg'
import { ReactComponent as IconSuccess } from 'images/icons/success-circle.svg'
import { dispatchLogisticPrice, GET_LIST_BY_LOGISTIC_ID, GET_LIST_SHOP_LOGISTIC, HANDLE_APPLY_ALL_SHOP_LOGISTIC, HANDLE_CHANGE_STATUS_LOGISTIC, HANDLE_DELETE_SHOP_LOGISTIC, SET_KEY_ADD_LOGISTIC_PRICE } from 'redux/actions/logisticPrice';
// import IconEdit from 'images/icons/edit.svg'
import { connect } from 'react-redux'
import host from 'config/host';
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import { dispatchLogistic } from 'redux/actions/logistic';
import SelectInput from 'components/SelectInput';
import noItem from 'images/icons/status-error.svg'
import PaginationComponent from 'components/pagination';
import { dispatchApp, SET_LOADING } from 'redux/actions/app';
import classNames from 'classnames'
import { setLocalStorage } from 'helper/utils';


class SetPriceLogistic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterLogisitics: [],
            filterSelected: 0,
            setFilterList: (list) => this.setFilterList(list)
        }
        this.setFilterList = this.setFilterList.bind(this)
    }


    componentDidMount() {
        setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: 5 } })
        this.props.dispatch(dispatchLogisticPrice({ type: GET_LIST_SHOP_LOGISTIC }))
        const listFilter = this.setFilterList(this.props.logisticList)
        this.setState({ filterLogisitics: listFilter })
    }

    static getDerivedStateFromProps(props, state) {
        if (state.filterLogisitics.length !== props.logisticList?.length) {
            const listFilter = state.setFilterList(props.logisticList)
            return { filterLogisitics: listFilter }
        }
    }

    setFilterList = (list) => {
        const arr = [{ label: 'ทั้งหมด', value: '' }]
        list && list.map((item) => {
            arr.push({
                label: item.code,
                value: item
            })
        })
        return arr
        // this.setState({ filterLogisitics: arr })
    }

    handleApplyAll = (id, status) => {
        if (status === 'hide') return
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: <div className="text-center">คุณแน่ใจหรือไม่<br /> ที่จะใช้การจัดส่งนี้กับสินค้าทั้งร้าน</div>,
                btnCancel: 'ยกเลิก',
                btnConfirm: 'ยืนยัน',
                confirm: () => this.props.dispatch(dispatchLogisticPrice({ type: HANDLE_APPLY_ALL_SHOP_LOGISTIC, payload: { id } }))
            }
        }))
    }

    deleteShopLogistic = (id) => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: <div className="text-center">คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้</div>,
                btnCancel: 'ยกเลิก',
                btnConfirm: 'ยืนยัน',
                confirm: () => this.props.dispatch(dispatchLogistic({ type: HANDLE_DELETE_SHOP_LOGISTIC, payload: { id } }))
            }
        }))
    }

    onChangeSelectFilter = (value) => {
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'filterSelected', value: value } }))
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'page', value: 1 } }))
        this.setState({
            filterSelected: value
        }, () => this.getListShopLogisticByLogisticId(this.state.filterLogisitics[value].value.id))
    }

    getListShopLogisticByLogisticId = (logisticId) => {
        this.props.dispatch(dispatchLogisticPrice({ type: GET_LIST_BY_LOGISTIC_ID, payload: { logisticId: logisticId } }))
    }

    onActiveLogisticChange = (status, logisticId, shopLogisticId, index) => {
        if (status === 'hide') {
            this.props.dispatch(dispatchModalConfirm({
                type: OPEN_MODAL_CONFIRM, payload: {
                    isOpen: true,
                    message: <div className="text-center">สินค้าทั้งหมดที่มีการใช้ราคาค่าจัดส่งนี้อยู่ <br /> จะถูกเปลี่ยนเป็นค่ามาตรฐาน</div>,
                    btnCancel: 'ยกเลิก',
                    btnConfirm: 'ยืนยัน',
                    confirm: () => this.props.dispatch(dispatchLogisticPrice({
                        type: HANDLE_CHANGE_STATUS_LOGISTIC, payload: {
                            status: status,
                            id: this.state.filterLogisitics[this.state.filterSelected].value.id,
                            logisticId: shopLogisticId
                        }
                    }))
                }
            }))
        } else {
            this.props.dispatch(dispatchLogisticPrice({
                type: HANDLE_CHANGE_STATUS_LOGISTIC, payload: {
                    status: status,
                    id: this.state.filterLogisitics[this.state.filterSelected].value.id,
                    logisticId: shopLogisticId
                }
            }))
        }
    }

    onChangePage = (page) => {
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'page', value: page } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: GET_LIST_SHOP_LOGISTIC }))
        this.getListShopLogisticByLogisticId(this.state.filterLogisitics[this.state.filterSelected].value.id)
    }

    onShowSizeChange = (current, pageSize) => {
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'limit', value: pageSize } }))
    }


    render() {
        const { logisticPrice, shopLogistic } = this.props
        const { filterLogisitics, filterSelected } = this.state
        return (
            <div className="set-price-logistic">
                <section className="header">
                    <div className=" d-flex flex-row align-items-center">
                        <h5>การจัดส่ง</h5> <span className="ml-3 mt-1">{shopLogistic?.count} รายการ</span>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row align-items-center"><span className="mr-2">แสดง​ : </span>
                            <SelectInput
                                // clearsearch={false}
                                options={filterLogisitics}
                                value={filterSelected}
                                onChange={(e) => this.onChangeSelectFilter(e)}
                                width={'10em'}
                            /> </div>
                        {/* className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name */}
                        <Link to="/manage/shop/shopdetail/addlogisticprice">
                            <div>  <ButtonIcon><img src={IconAdd} /> เพิ่มราคาจัดส่ง </ButtonIcon></div>
                        </Link>
                    </div>
                </section>
                <div className="body">
                    <TableHeader
                        dataHeader={['ลำดับ', 'ผู้ให้บริการขนส่ง', 'ชื่อการจัดส่ง', 'สถานะการใช้งาน', <div className="w-100"></div>, <div className="w-100"></div>, <div className="w-100"></div>]}
                        type={'header'}
                        colWidth={['10%', '20%', '50%', '20%', '6%', '6%', '6%']}
                        colSide={['center', 'center', 'center', 'left', 'center', 'center', 'center']}
                    />

                    {shopLogistic && shopLogistic.data.length === 0 &&
                        <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                            <img src={noItem} alt="noItem" />
                            <span className="mt-2 font-secondary">ไม่มีรายการ</span>
                        </div>
                    }
                    {shopLogistic && shopLogistic.data.map((item, idx) => (
                        <TableHeader key={`shopLogistic-${idx}`}
                            dataHeader={[
                                (logisticPrice.page - 1) * logisticPrice.limit + idx + 1,
                                <img src={`${host.image}/${item.logistic[0].logo}`} style={{ maxWidth: '90%' }} />,
                                item.name,
                                <div
                                    className='toggle-green d-flex align-items-center'
                                    style={{ gap: '10px' }}
                                >
                                    <label className='switch'>
                                        <input
                                            type='checkbox'
                                            checked={item.status === "show" ? true : false}
                                            onChange={(e) =>
                                                this.onActiveLogisticChange(
                                                    item.status === "show" ? 'hide' : 'show',
                                                    item.logisticId,
                                                    item.id,
                                                    idx
                                                )
                                            }
                                        />
                                        <span className='slider round'></span>
                                    </label>
                                    <span>
                                        {true ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                                    </span>
                                </div>,
                                <div style={{ width: '100%' }}><IconSuccess className={classNames('mt-2 cursor-pointer', { 'icon-apply-all-disabled': item.status === 'hide' })} style={{ width: '52px' }} onClick={() => this.handleApplyAll(item.id, item.status)} /></div>,
                                <div style={{ width: '100%' }}><IconEdit onClick={() => this.props.history.push({ pathname: '/manage/shop/shopdetail/addlogisticprice', state: item })} className="cursor-pointer" /></div>,
                                <div style={{ width: '100%' }}><IconTrash onClick={() => this.deleteShopLogistic(item.id)} className="cursor-pointer" /></div>,
                            ]}
                            type={'content'}
                            colWidth={['10%', '20%', '50%', '20%', '6%', '6%', '6%']}
                            colSide={['center', 'center', 'center', 'left', 'center', 'center', 'center']}
                        />
                    ))}

                    <div className="float-right mt-4 position-relative">
                        <PaginationComponent
                            defaultCurrent={1}
                            total={logisticPrice.total}
                            onChange={(page) => this.onChangePage(page)}
                            pageSize={logisticPrice.limit}
                            pageSizeOptions={logisticPrice.pageSizeOptions}
                            onShowSizeChange={this.onShowSizeChange}
                            current={logisticPrice.page}
                        />
                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    logisticPrice: state.logisticPrice,
    shopLogistic: state.logisticPrice.shopLogistic,
    logisticList: state.product.logisticList
})


export default connect(mapStateToProps, null)(SetPriceLogistic)