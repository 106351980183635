export const SET_USER_INFO = "SET_USER_INFO"
export const FETCH_USER_LOGIN = "FETCH_USER_LOGIN"
export const ADD_USER_INFO = "ADD_USER_INFO"
export const AUTH_USER = "AUTH_USER"
export const SET_AUTH_USER = "SET_AUTH_USER"
export const SET_KEY_USER = "SET_KEY_USER"
export const CREATE_USER_SHOP = 'CREATE_USER_SHOP'
export const CREATE_USER_SHOP_FIELD = 'CREATE_USER_SHOP_FIELD'
export const SET_USER_SHOP = 'SET_USER_SHOP'
export const SKIP_CREATE_USER = 'SKIP_CREATE_USER'


export function dispatchUser(params) {
  return {
    type: params.type,
    payload: params.payload,
  };
}