import React from 'react'
import './styles.scss'

const Checkbox = (props) => {
  const {
    label,
    disabled,
    defaultChecked,
    checking,
    onSelectMedia,
    media,
  } = props

  return (
    <div className='input-checkbox'>
      <label className='container'>
        <input
          type='checkbox'
          defaultChecked={defaultChecked}
          checked={checking}
          disabled={disabled}
          onChange={(e) => onSelectMedia(media, e)}
        />
        <span className={`checkmark ${disabled ? 'disable' : ''}`}></span>
        <span className={`${disabled ? 'disable' : ''}`}>{label}</span>
      </label>
    </div>
  )
}

export default Checkbox
