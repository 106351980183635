
import { OPEN_TOAST, CLOSE_TOAST, OPEN_TOOLTIP }  from '../actions/notification';

const initialState = {
    toast: {
        show: false,
        message: 'ทำรายการสำเร็จ',
        type: 'success'
    },
    tooltip: { // close within 3 second
        show: false,
        message: '',
        toolTipId: ''
    }
}

export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {
        case  OPEN_TOOLTIP : {
            const  {message=state.tooltip.message, show, toolTipId} = payload || {};

            return{
                ...state,
                tooltip: {
                        show,
                        message,
                        toolTipId
                }
            }
         }
        case  OPEN_TOAST : {
            const  {message ,type} = payload
            return{
                ...state,
                toast: {
                    ...state.toast,
                    message  : message,
                    type : type,
                    show : true
                }
            }
         }

         case  CLOSE_TOAST : {
            return{
                ...state,
                toast: {
                    ...state.toast,
                    show : false
                }
            }
         }

        default: return state
     } //  end swicth
}