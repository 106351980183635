export const OPEN_MODAL_MESSAGE = "OPEN_MODAL_MESSAGE"
export const CLOSE_MODAL_MESSAGE = "CLOSE_MODAL_MESSAGE"
export const OPEN_MODAL_MESSAGE_ERROR = "OPEN_MODAL_MESSAGE_ERROR"
export const OPEN_MODAL_MESSAGE_SUCCESS = "OPEN_MODAL_MESSAGE_SUCCESS"

export function dispatchModalMessage (params) {
    return {
        type: params.type,
        payload: params.payload
    }
}