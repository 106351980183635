import axios from '../config/axios';
import host from '../config/host';

class PostProvider {

    getPostByShopId = async(payload) => {
        try {
            
            const {shopId, limit, page} = payload;
            const  url  =  `${host.api}/posts/shop/${shopId}?limit=${limit}&page=${page}`;
            const  res  =  await axios.get(url)
            return res.data ? res.data : [];

        }catch(error){
            
            throw error
        }
    }

    createPost = async(payload) => {
        try {
            const  url  =  `${host.api}/posts`;
            const  res  =  await axios.post(url, payload);
            return res.data;
        } catch(error) {
            console.error("[createPost][provider]:",error.response.data)
            throw error.response.data
        }
    }
    updatePost = async(payload) => {
        try {
            
            const {mediaId,post} = payload;
            const  url  =  `${host.api}/posts/update-live/${mediaId}`;
            const  res  =  await axios.patch(url, post);
            return res.data;

        } catch(error) {
            console.error("[updatePost][provider]:",error.response.data)
            throw error.response.data
        }
    }
    
    createLiveRerun = async(payload) => {
        try {
            const  url  =  `${host.api}/posts`;
            const  res  =  await axios.post(url, payload);
            return res.data;
        } catch(error) {
            console.error("[createLiveRerun][provider]:",error.response.data)
            throw error.response.data
        }
    }

    updateLiveRerun = async(payload) => {
        try {
            const  url  =  `${host.api}/posts`;
            const  res  =  await axios.patch(url, payload);
            return res.data;
        } catch(error) {
            console.error("[updateLiveRerun][provider]:",error.response.data)
            throw error.response.data
        }
    }

    createPostItem = async(payload) => {
        try {
            const  url  =  `${host.api}/post-items`;
            const  res  =  await axios.post(url, payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }
    upadatePostItem = async(payload) => {
        try {
            const  url  =  `${host.api}/post-items`;
            const  res  =  await axios.patch(url, payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }
    
    getPost = async(payload) => {
        try {
            const { postId } = payload;
            const  url  =  `${host.api}/posts/${postId}`;
            const  res  =  await axios.get(url);
            return res.data ? res.data : [];
        } catch(error) {
            throw error
        }
    }

    toggleShowProductItem = async(payload) => {
        try {
            const  url  =  `${host.api}/post-items/live-show/${payload.itemId}`;
            const  res  =  await axios.patch(url,payload.item);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }

    getGenerateKey = async(payload) => {
        try {
            const { postId } = payload;
            const  url  =  `${host.api}/posts/${postId}/generate-key`; 
            const  res  =  await axios.get(url);
            return res.data ? res.data : null;
        } catch(error) {
            throw error
        }
    }

    publishPost = async(payload) => {
        try {
            const  url  =  `${host.api}/posts/publish`;
            const  res  =  await axios.post(url,payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }

    stopPost = async(payload) => {
        try {
            const  url  =  `${host.api}/posts/stop`;
            const  res  =  await axios.post(url,payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }

    getLiveStatus = async(payload) => {
        try {
            const  { postId } = payload;
            const  url  =  `${host.api}/posts/${postId}/live-status`;
            const  res  =  await axios.get(url);
            return res.data ? res.data : null;
        } catch(error) {
            throw error
        }
    }
    deletePostById = async (id) => {
        try {
            const url = `${host.api}/posts/${id}`
            const res = await axios.delete(url)
            return {data: res.data, status: res.status};
        } catch (error) {
            throw error
        }
    }
    getPostMedia = async(payload) => {
        try {
            const  { shopId, 
                     type          : _type  =null,
                     upstreamStatus: _upstreamStatus=null,
                     limit         : _limit,
                     page          : _page,
                     status        : _status} = payload;


            const page           = _page   ? `page=${_page}&` : '';
            const limit          = _limit  ? `limit=${_limit}`: '';
            const type           = _type   ? _type.reduce((total, _postType, ) => (total+=`type=${_postType}&`), '') : '';
            const upstreamStatus = _upstreamStatus ? _upstreamStatus.reduce((total, __upstreamStatus, ) => (total+=`upstreamStatus=${__upstreamStatus}&`), '' ): '' ;
            const status         = _status ? `status=${_status}&` : '';
            
            const url  =  `${host.api}/post-medias/shop/${shopId}?${type}${upstreamStatus}${status}${page}${limit}`;
            const res  =  await axios.get(url);
            return {data: res.data, status: res.status};
        } catch(error) {
            throw error
        }
    }

    getPostMediaLive  = async(payload) => {
        try {
            
            const  { shopId } = payload;
            const url  =  `${host.api}/post-medias/shop/live/${shopId}`;
            const res  =  await axios.get(url);
            return {data: res.data, status: res.status};

        } catch(error) {
            throw error
        }
    }

    createPostRerun = async(payload) => {
        try {
            const  url  =  `${host.api}/posts/create-rerun`;
            const  res  =  await axios.post(url, payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[createPostRerun][provider]:",error.response.data)
            throw error
        }
    }

    createPostVod = async(payload) => {
        try {
            const  url  =  `${host.api}/posts/create-vod`;
            const  res  =  await axios.post(url, payload);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[createPostVod][provider]:",error.response.data)
            throw error
        }
    }

    updatePostVod = async(payload) => {
        try {
            const {mediaID,post} = payload;
            const  url  =  `${host.api}/posts/update-vod/${mediaID}`;
            const  res  =  await axios.patch(url, post);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[updatePost][provider]:",error.response.data)
            throw error.response.data
        }
    }

    updatePostVodStatus = async(payload) => {
        try {
            const {postID,status} = payload;
            const  url  =  `${host.api}/post-medias/${postID}`;
            const  res  =  await axios.patch(url, {status});
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[updatePostVodStatus][provider]:",error.response.data)
            throw error.response.data
        }
    }
    updateRerun = async(payload) => {
        try {
            const {postID,param} = payload;
            const  url  =  `${host.api}/post-medias/${postID}`;
            const  res  =  await axios.patch(url, param);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[updateRerun][provider]:",error.response.data)
            throw error.response.data
        }
    }

    

    deletePostVodById = async (payload) => {
        /* TODO - deletePostVodById */
        try {
            const url = `${host.api}/post-medias/${payload.postID}`
            const res = await axios.delete(url)
            return {data: res.data, status: res.status};
        } catch (error) {
            throw error
        }
    }

    getPostVodById = async(payload) => {
        try {
            const  url  =  `${host.api}/post-medias/${payload.postId}`;
            const  res  =  await axios.get(url);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[getPostVodById][provider]:",error.response.data)
            throw error
        }
    }

    getPostMediaById = async(payload) => {
        try {
            const  url  =  `${host.api}/post-medias/${payload.mediaId}`;
            const  res  =  await axios.get(url);
            return {data: res.data, status: res.status};
        } catch(error) {
            console.error("[getPostVodById][provider]:",error.response.data)
            throw error
        }
    }
    
}

export default new PostProvider()