export const GET_PLAYLISTS = 'GET_PLAYLISTS'
export const SET_PLAYLISTS = 'SET_PLAYLISTS'
export const CREATE_PLAYLISTS = 'CREATE_PLAYLISTS'
export const UPDATE_PLAYLISTS = 'UPDATE_PLAYLISTS'
export const GET_PLAYLISTS_ITEMS = 'GET_PLAYLISTS_ITEMS'
export const SET_PLAYLISTS_ITEMS = 'SET_PLAYLISTS_ITEMS'
export const CREATE_PLAYLISTS_ITEM = 'CREATE_PLAYLISTS_ITEM'
export const SHUFFLE_PLAYLISTS_ITEMS = "SHUFFLE_PLAYLISTS_ITEMS"
export const PIN_PLAYLISTS_ITEMS = "PIN_PLAYLISTS_ITEMS"
export const DELETE_PLAYLISTS = 'DELETE_PLAYLISTS'
export const DELETE_PLAYLISTS_ITEM = 'DELETE_PLAYLISTS_ITEM'
export const UPDATE_ORDER_PLAYLISTS = 'UPDATE_ORDER_PLAYLISTS'
export const UPDATE_ORDER_PLAYLISTS_ITEMS = 'UPDATE_ORDER_PLAYLISTS_ITEMS'

export function dispatchPlaylists(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
