import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import '../styles/_order.scss'
import Search from '../images/icons/search.svg'
import ListOrder from '../components/ListOrder'
import { connect } from 'react-redux'
import { dispatchOrder, GET_LIST_ORDER, ON_SEARCH_ORDER, SET_DEFAULT_ORDER, SET_ORDER_FIELD, SET_SELECT_ALL_CLEARING } from '../redux/actions/order';
import Pagination from '../components/pagination'
import DropDown from '../components/DropDownList'

import ModalInvoiceDetail from '../components/modalInvoiceDetail';
import FilterSearch from 'components/FilterSearch';
import SelectInput from 'components/SelectInput';
import { GET_LIST_SHOP } from 'redux/actions/shop';

import { getUserRole } from '../helper/utils';
import { ADMIN, OWNER } from '../layout/Sidebar/Role';
import ModalCoverSheetPrint from 'components/ModalCoverSheetPrint';
import ModalAlert from 'components/modalAlert/ModalAlert';

// const Example = (props) => {
//     const [activeTab, setActiveTab] = useState('1'); 


//     const toggle = tab => {
//       if(activeTab !== tab) setActiveTab(tab);
//     }

const typeFilterData = [{ label: 'ทั้งหมด', value: '' }, { label: 'โอนแล้ว', value: 'clr' }, { label: 'ยังไม่ได้โอน', value: 'nclr' }]
const filterSearch = [{ label: 'ชื่อลูกค้า', value: 'name' }, { label: 'หมายเลขออเดอร์', value: 'orderNo' }]
const initialState = {
    activeTab: 'waitShipping',
    limit: 10,
    pageSizeOptions: [10, 20, 40],
    current: 1,
    search: "",
    statusSearch: false,
    historySearch: '',
    filter: { label: 'ทั้งหมด', value: '' },
    listShop: [],
    searchShopSelectedIndex: 0,
    typeFilterSelectedIndex: 0
}

class Order extends Component {

    static getDerivedStateFromProps(props, state) {
        if (props.shop !== state.shop) {
            const listShop = [{ label: 'ร้านค้าทั้งหมด', value: '', }]
            props.shop.map((item) => {
                listShop.push({ label: item.name, value: item })
            })
            return {
                listShop: listShop,
            };
        }
        return null;
    }

    constructor(props) {
        super(props)
        this.state = {
            ...initialState
        }
    }

    componentDidMount() {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: this.props.match.params.slug || 'waitShipping' } }))
        this.setState({ activeTab: this.props.match.params.slug || 'waitShipping' })
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
        window.history.pushState("", "", "/manage/order");
    }

    componentWillUnmount() {
        this.setState({...initialState})
        this.props.dispatch(dispatchOrder({type: SET_DEFAULT_ORDER }))
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.tabSelected !== prevProps.tabSelected){
    //         this.toggle(this.props.tabSelected)
    //     }
    // }

    toggle = (tab) => {

        const { filterSearchSelect, filterSearchValue, searchShopSelect } = this.props.order
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'status', value: tab } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.setState({ activeTab: tab })
        if (this.state.statusSearch) {
            this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)
        } else {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { filter: this.state.filter.value } }))
        }

    }

    onChangePage = (page) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: page } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'clearingOrder', value: [] } }))
        // this.props.dispatch(dispatchOrder({ type: SET_SELECT_ALL_CLEARING, payload: false }))
        if (!this.state.statusSearch) {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { filter: this.state.filter.value } }))
        }
        else {
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { value: this.state.search, filter: this.state.filter.value } }))
        }
    }


    onShowSizeChange = (current, pageSize) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'limit', value: pageSize } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: current } }))
        // this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
    }

    // onSearchOrder = (e) => {
    //     this.setState({ search: e.target.value })
    // }

    handleEnterSearchOrder = (e) => {
        if (e.key != 'Enter') { return }
        if (e.key == 'Enter') {
            if (this.state.search.trim().length == 0) {
                this.setState({
                    statusSearch: false,
                    historySearch: this.state.search
                })
                this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
            } else {
                this.setState({
                    statusSearch: true,
                    historySearch: this.state.search
                })
                this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
            }
            this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
        }
    }

    onClickSearchOrder = () => {
        this.setState({
            statusSearch: true,
            historySearch: this.state.search
        })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } }))
    }

    selectTypeFilter = (value) => {
        this.setState({
            filter: value
        }, () =>
            this.props.dispatch(dispatchOrder({ type: ON_SEARCH_ORDER, payload: { search: this.state.search, filter: this.state.filter.value } })),
            this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        )
    }

    onChangeInputSearch = (data) => {
        const { filterSearchSelect, filterSearchValue, searchShopSelect } = this.props
        this.setState({ searchShopSelectedIndex: data })
        const value = this.state.listShop[data]
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchShopSelect', value: value } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        // this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)
        if (this.state.statusSearch) {
            this.props.dispatch(dispatchOrder({
                type: ON_SEARCH_ORDER, payload: {
                    filter: filterSearchSelect,
                    value: filterSearchValue ? filterSearchValue : null,
                    shopId: value ? value.value.id : null
                }
            }))
        } else {
            this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER, payload: { shopId: value.value.id } }))
        }
    }

    onChangeFilter = (index) => {
        this.setState({ typeFilterSelectedIndex: index })
        const value = filterSearch[index].value
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: value } }))
    }

    onChangeFilterValue = (value) => {
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: value } }))
    }

    onClearSearchShop = () => {
        this.setState({ searchShopSelectedIndex: 0 })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'searchShopSelect', value: { label: 'ร้านทั้งหมด', value: '' } } }))
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
    }

    onClearSearchFilterType = () => {
        this.setState({ typeFilterSelectedIndex: 0, statusSearch: false })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchSelect', value: 'name' } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'filterSearchValue', value: null } }))
        this.props.dispatch(dispatchOrder({ type: GET_LIST_ORDER }))
    }

    onSearchOrder = (filter, value, shopId) => {
        this.setState({ statusSearch: true })
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'statusSearch', value: true } }))
        this.props.dispatch(dispatchOrder({ type: SET_ORDER_FIELD, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchOrder({
            type: ON_SEARCH_ORDER, payload: {
                filter: filter,
                value: value ? value : null,
                shopId: shopId ? shopId.value.id : null
            }
        }))
    }

    onEnterSearch = (e, filter, value, shopId) => {
        if (e.key == 'Enter') {
            this.onSearchOrder(filter, value, shopId)
        }
    }

    render() {
        const { activeTab, listShop } = this.state
        const { listOrder, loading, shop, order } = this.props
        const { pageSizeOptions, limit, page, total, status, filterSearchValue, filterSearchSelect, searchShopSelect } = this.props.order

        return (
            <div className="order-history">
                <div className="header-order">
                    <span className="content-header">รายการสั่งซื้อ </span>
                </div>

                <div className="search-container">
                    <div style={{ width: '100%', display: 'flex' }}>
                        {getUserRole() !== OWNER &&
                            <React.Fragment>
                                <SelectInput
                                    width={'400px'}
                                    options={listShop}
                                    onChange={(data) => this.onChangeInputSearch(data)}
                                    value={this.state.searchShopSelectedIndex}
                                    onClearSearchShop={() => this.onClearSearchShop()}
                                />
                                &nbsp; &nbsp;
                            </React.Fragment>
                        }
                        <FilterSearch
                            width={'50%'}
                            onChange={(data) => this.onChangeFilter(data)}
                            options={filterSearch}
                            value={filterSearchValue}
                            onChangeValue={(e) => this.onChangeFilterValue(e)}
                            onClearSearchFilterType={this.onClearSearchFilterType}
                            valueTypeFilter={this.state.typeFilterSelectedIndex}
                            onSearchOrder={() => this.onSearchOrder(filterSearchSelect, filterSearchValue, searchShopSelect)}
                            onKeyPress={(e) => this.onEnterSearch(e, filterSearchSelect, filterSearchValue, searchShopSelect)}
                            statusSearch={this.state.statusSearch}
                        />
                    </div>
                    <div>
                        {/* <button className='btn btn-yellow' style={{width:'185px'}}> ดาวน์โหลดรายการจัดส่ง </button>  */}
                    </div>
                </div>

                <div className="body-order-content">
                    <div className="tab-order">
                        <div>
                            <Nav tabs>
                            {/* <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                         ออเดอร์ใหม่ ({listOrder?.waitShipping})
                                </NavLink>
                                </NavItem> */}
                                <NavItem onClick={() => this.toggle('waitShipping')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitShipping' })} >
                                        รอจัดส่ง ({listOrder?.waitShipping})
                                </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('onTheWay')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'onTheWay' })} >
                                        อยู่ระหว่างขนส่ง ({listOrder?.onTheWay})
                                </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('success')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'success' })} >
                                        ลูกค้าได้รับแล้ว
                                </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('waitRefund')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'waitRefund' })} >
                                        รอคืนเงิน ({listOrder?.waitRefund})
                                </NavLink>
                                </NavItem>

                                <NavItem onClick={() => this.toggle('refunded')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'refunded' })} >
                                        คืนเงินแล้ว
                                </NavLink>
                                </NavItem>
                                <NavItem onClick={() => this.toggle('deliveryDelayed')}>
                                    <NavLink className={classnames({ 'active': activeTab === 'deliveryDelayed' })} >
                                        เกินเวลาจัดส่ง ({listOrder?.deliveryDelayed})
                                </NavLink>
                                </NavItem>

                            </Nav>
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {/* <div className="input-text-field">
                            <input type="text" placeholder="ค้นหาออเดอร์" value={this.state.search} onChange={(e) => this.onSearchOrder(e)} onKeyPress={(e) => this.handleEnterSearchOrder(e)} />
                            <img src={Search} alt="Search" onClick={this.onClickSearchOrder} className="cursor-pointer" />
                        </div> */}

                        {/* {this.props.order.status == "success" ?
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <div className="mr-2">สถานะการโอนเงินให้ร้านค้า :
                                </div>
                                <DropDown
                                    onChange={this.selectTypeFilter}
                                    options={typeFilterData}
                                    // placeholder={'ทั้งหมด'}
                                    value={this.state.filter}
                                    className={''}
                                    style={{ width: '10em' }}
                                ></DropDown>
                            </div> : null} */}
                    </div>
                    {
                        listOrder && listOrder.data?.length == 0 ? null :
                            <div className="pagination-top-order">
                                <div className="float-right mt-3 position-relative">
                                    <Pagination
                                        defaultCurrent={1}
                                        total={total}
                                        onChange={(page) => this.onChangePage(page)}
                                        pageSize={limit}
                                        pageSizeOptions={pageSizeOptions}
                                        onShowSizeChange={this.onShowSizeChange}
                                        current={page}
                                    />
                                </div>
                            </div>
                    }



                    <div className="list-order-item">
                        <ListOrder
                            item={listOrder}
                            status={this.state.activeTab}
                            search={this.state.search}
                            filter={this.state.filter.value}
                        />
                    </div>
                </div>
                {listOrder && listOrder.data?.length > 0 && 
                    <div className="float-right mt-4 position-relative">
                        <Pagination
                            defaultCurrent={1}
                            total={total}
                            onChange={(page) => this.onChangePage(page)}
                            pageSize={limit}
                            pageSizeOptions={pageSizeOptions}
                            onShowSizeChange={this.onShowSizeChange}
                            current={page}
                        />
                    </div>
                }

                <ModalInvoiceDetail
                    order={this.props.order}
                    search={this.state.search}
                    filter={this.state.filter.value}
                />

                <ModalCoverSheetPrint />

                <ModalAlert 
                onSubmit={() => this.toggle('deliveryDelayed')}
                />

            </div>
            

        )
    }
}


const mapStateToProps = (state) => ({
    listOrder: state.order.listOrder,
    order: state.order,
    tabSelected: state.order.status,
    shop: state.shop.listShopName
})

export default connect(mapStateToProps, null)(Order)
