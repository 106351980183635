import React, { Component } from 'react'
import { Pagination } from 'antd'
import './index.scss'
import ArrowLeft from '../../images/icons/arrow-left.svg'
import ArrowRight from '../../images/icons/arrow-right.svg'


class PaginationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  static defaultProps = {
    pageSize: 10,
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a><img src={ArrowLeft} alt="prev" className="icon-arrow" /></a>;
    }
    if (type === 'next') {
      return <a><img src={ArrowRight} alt="next" className="icon-arrow" /></a>;
    }
    return originalElement;
  }


  onChange = page => {
    this.setState({
      current: page,
    });
  };


  render() {
    const { total, pageSize, current, pageSizeOptions, showQuickJumper, onShowSizeChange, onChange, size = 'default' } = this.props
    return (
      <div className="pagination-div">
        <div className="pagination-box">
          <span className="text-show-page">แสดงจำนวน</span>
          <Pagination
            showQuickJumper={true, { goButton: <button className="btn-jump-to">ตกลง</button> }}
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSizeOptions={pageSizeOptions}
            pageSize={pageSize}
            total={total}
            locale={{ items_per_page: '/ หน้า', jump_to: 'ไปที่หน้า' }}
            showSizeChanger={true}
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
            itemRender={this.itemRender}
            size={size}
          />
        </div>
      </div>
    )
  }
}


export default PaginationComponent
