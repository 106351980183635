import React, { Component } from "react";
import { connect } from "react-redux";

import host from "config/host";
import Checkbox from "components/Checkbox";
import LiveProducItem from "./LiveProducItem";

import { dispatchPost, TOGGLE_SHOW_ON_ITEM, } from 'redux/actions/post';

const showOnOptions = {
  live: { type: "live", name: "showonLiveAllItem" },
  shelf: { type: "shelf", name: "showonShelfAllItem" },
};

const initialState = {
  post: [],
  showonShelfAllItem: false,
  showonLiveAllItem: false,
  optionOrder: []
};

class ManageLiveProducts extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  componentDidUpdate = prevState => {
      if(prevState.post !== this.props.post) {
          const optionOrder = this.props.post.items.map((item) => ({name: item.order, value: item.order}));
          /* NOTE - check show option all */
          Object.keys(showOnOptions)
                  .map(key => 
                  this.checkShowListAllShelf(this.props.post, 
                                             showOnOptions[key].type));
          this.setState({post: this.props.post, optionOrder});
      }
  }

  onSetShowonShelfAll = (event, showOntype) => {

    let posts = JSON.parse(JSON.stringify(this.state.post));

    const selectAll = posts.items.some((item) => {
      if (!item.showOn) return false;
      return item.showOn && item.showOn.indexOf(showOntype) < 0;
    });

    if (selectAll) {
        posts.items = posts.items.map((product) => {
        if (product.showOn && product.showOn.indexOf(showOntype) < 0) {
          product.showOn.push(showOntype);
        }
        return product;
      });
    } else {
        posts.items = posts.items.map((product) => {
        if (product.showOn && product.showOn.indexOf(showOntype) != -1) {
          const showOn = product.showOn.filter((show) => show !== showOntype);
          product.showOn = showOn;
        }
        return product;
      });
    }

    this.setState(
      { [event.target.name]: !this.state[event.target.name] },
      () => {
        this.setState({post: posts}, () => {
          
          /* NOTE -  */
          let items = JSON.parse(JSON.stringify(this.state.post.items));
          items.map((item, itemIndex) =>
          this.onToggleShowOnItem(itemIndex, item, showOntype)
          )

        });
      }
    );
  };

  onShowOnChange = (event, index, item) => {
    const attrName = event.target.name;
    let posts = JSON.parse(JSON.stringify(this.state.post));
    /* NOTE - toggle value */
    /* NOTE - case show on type is exist */
    if (posts.items[index].showOn && posts.items[index].showOn.indexOf(attrName) > -1) {
        posts.items[index].showOn = posts.items[index].showOn.filter(item => item !== attrName);
    } else {
        if (!posts.items[index].showOn) 
            posts.items[index].showOn = [];

        posts.items[index].showOn.push(attrName);
    }
    this.setState( {post:posts},() => this.onToggleShowOnItem(index,  posts.items[index], attrName));
  };

  onToggleShowOnItem = (index, item, showOnType) => {

    this.checkShowListAllShelf(JSON.parse(JSON.stringify(this.state.post)), showOnType)

    const payload = {
                        item: { index: (index+1),
                                skuId: item.skuId , 
                                order: item.order , 
                                showOn: item.showOn },
                        itemId: item.id
                    };
    this.props.dispatch(dispatchPost({ type: TOGGLE_SHOW_ON_ITEM, payload }))
  }

  onOrderChange = (newValue, item, index) => {
    const payload = {
        item: { index: (index+1),
                skuId: item.skuId , 
                order: newValue , 
                showOn: item.showOn },
        itemId: item.id
    };
    this.props.dispatch(dispatchPost({ type: TOGGLE_SHOW_ON_ITEM, payload }))
  }

//   onOrderChange = (newValue, item, index) => {

//     /* NOTE - swap value */
//     const { items } = JSON.parse(JSON.stringify(this.state.post)) || {};
//     const targetIndex = items.findIndex(item => item.order === 4);
//     if(targetIndex > -1){
//         items[targetIndex].order = item.order;
//         items[index].order = newValue;
//     }

//     items.forEach((item, itemIndex) => {
//         const payload = {
//             item: { index: (itemIndex+1),
//                     skuId: item.skuId , 
//                     order: item.order , 
//                     showOn: item.showOn },
//             itemId: item.id
//         };
//         this.props.dispatch(dispatchPost({ type: TOGGLE_SHOW_ON_ITEM, payload }))
//     })
//   }

  checkShowListAllShelf = (post, showOntype) => {
    const res = post.items.some((item) => {
        if(item.showOn) {
            console.log("exist")
            return item.showOn.indexOf(showOntype) == -1
        }
        else {
            console.log("does not exist")
            return true;
        }
    })
    this.setState({[showOnOptions[showOntype].name]: !res})
  }

  render() {
      const {showonShelfAllItem, 
             optionOrder,
             showonLiveAllItem, 
             post} = this.state;
      const items = !!post && !!post.items ? post.items : [];
    return (
      <React.Fragment>
        <div className="item-detail-container">
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div className="select-all-item">
              <Checkbox
                title={"แสดงในเชล์ฟทั้งหมด"}
                checked={showonShelfAllItem}
                value="showonShelfAllItem"
                onChange={(e) => this.onSetShowonShelfAll(e, "shelf")}
                name="showonShelfAllItem"
              />
            </div>
            <div className="select-all-item">
              <Checkbox
                title={"แสดงในไลฟ์ทั้งหมด"}
                checked={showonLiveAllItem}
                value="showonLiveAllItem"
                onChange={(e) => this.onSetShowonShelfAll(e, "live")}
                name="showonLiveAllItem"
              />
            </div>
          </div>

          {items.map((item, itemIndex) => {
            return ( <LiveProducItem 
                        item={item}
                        onOrderChange={this.onOrderChange} 
                        itemIndex={itemIndex} 
                        optionOrder={optionOrder}
                        onShowOnChange={this.onShowOnChange}/> );
          })}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(ManageLiveProducts);
