import React, { Component } from 'react'
import { Select } from 'antd';
import IClose from 'images/icons/close-circle.svg'
import './styles.scss';

const { Option } = Select;




class SelectInput extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            value: ''
        }
    }

    // setValueIndex = (value) => {
    //     if(value) {
    //         const findIndex = this.props.options.findIndex(item => item.value.id == value.value.id)
    //     }
    // }

    // splitMsg = (value) => {
    //     if(value){
    //         const split = value?.split('-')
    //         return split[0]
    //     }
    // }

    // showValue = () => {
    //     if(this.props.name === 'subDistrict') return this.splitMsg(this.props.options[this.props.value]?.label)
    //     return this.props.value
    // }

    render() {
        const { className,width,height,options,placeholder,onChange,onClearSearchShop,value,onBlur,iconClearSearch,name } = this.props
        return (
            <div id="input-select">
                <Select
                    showSearch
                    style={{width}}
                    placeholder={placeholder}
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) => 
                        option.children.toLowerCase().substr(0,input.length).indexOf(input.toLowerCase()) >= 0
                    }
                    className={className}
                    value={value}
                    onBlur={onBlur}
                    // clearSearch={iconClearSearch}
                >
                    {options.map((item,index) => (
                        <Option key={`option${index}`} value={index}>{item.label}</Option>
                    ))}
                </Select>
                {iconClearSearch ? options[0]?.value === '' || value === null ? null : <img src={IClose} alt="" className="icon-close cursor-pointer" onClick={onClearSearchShop}/> : null}
            </div>
        )
    }
}

SelectInput.defaultProps = {
    width: '100%',
    // height: 'auto',
    options: [
        {
            label: '',
            value: ''
        }
    ],
    placeholder: "ร้านค้าทั้งหมด",
    iconClearSearch: true,
}


export default SelectInput