import { SET_LIST_WITHDRAW, SET_TAB_ACTIVE, SET_SELECT_WITHDRAW, SET_KEY_WITHDRAW, 
    SET_DEFAULT_WITHDRAW, OPEN_MODAL_WITHDRAW_PREVIEW, CLOSE_MODAL_WITHDRAW_PREVIEW,
    FETCH_ORDER_SELLER_WITHDRAW, SET_MODAL_SELLER_WITHDRAW } from '../actions/withdraw'


const initialState = {
    withdrawList: [],
    activeTab: 'never',
    selectBox: true,
    selectWithdraw: [],
    priceTransfer: 0,
    page: 1,
    limit: 30,
    total: null,
    statusSearch: false,
    valueSearch: '',
    modalWithdrawPreview: {
        isOpen: false,
        data: []
    }
}

export default function (state = initialState, action) {
    const { payload } = action
    switch (action.type) {
        case SET_LIST_WITHDRAW:
            return {
                ...state,
                withdrawList: payload
            }
        case SET_TAB_ACTIVE:
            return {
                ...state,
                activeTab: payload.status,
                selectBox: payload.status === "never" ? true : false
            }
        case SET_SELECT_WITHDRAW:
            return {
                ...state,
                selectWithdraw: payload.selectWithdraw
            }

        case SET_KEY_WITHDRAW:
            return {
                ...state,
                [payload.key]: payload.value
            }
        case SET_DEFAULT_WITHDRAW:
            return {
                ...initialState
            }
        case OPEN_MODAL_WITHDRAW_PREVIEW:
            return {
                ...state,
                modalWithdrawPreview: {
                    isOpen: true,
                    data: payload.data,
                    headerData: payload.headerData
                }
            }
        case CLOSE_MODAL_WITHDRAW_PREVIEW:
            return {
                ...state,
                modalWithdrawPreview: {
                    isOpen: false,
                    data: null,
                    headerData: null
                }
            }

            case SET_MODAL_SELLER_WITHDRAW: 
            return {
                ...state,
                modalWithdrawPreview: {
                    ...state.modalWithdrawPreview,
                    data: payload.data
                }
            }
        default: return state
    }
}
