import ligisticProvider from '../../provider/ligisticProvider';
import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_LOGISTIC_LIST, SET_LOGISTIC_LIST } from '../actions/logistic';

export function* onGetLogisticList(action) {

    const param = {}
    const logisticList = yield call(ligisticProvider.getLogisticList,param);
    yield put({ type: SET_LOGISTIC_LIST, payload: logisticList });

}



export default function* useWatcher() {
    yield takeLatest(GET_LOGISTIC_LIST, onGetLogisticList)
}