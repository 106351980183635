export const OPEN_MODAL_CREATE_LIVE = "OPEN_MODAL_CREATE_LIVE"
export const SET_LIVE_FILED = "SET_LIVE_FILED"
export const FETCH_LIVE = "FETCH_LIVE"
export const SET_LIVE = "SET_LIVE" 
export const SET_SELECT_PRODUCT_DETAIL   = "SET_SELECT_PRODUCT_DETAIL" 
export const UPDATE_QTY_SELECT_PRODUCT_DETAIL   = "UPDATE_QTY_SELECT_PRODUCT_DETAIL" 
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST"
export const SET_ITEMS_LIVE = "SET_ITEMS_LIVE"
export const FETCH_CHECK_LIVE = "FETCH_CHECK_LIVE"
export const GET_LIVE_STATUS = "GET_LIVE_STATUS"
export const SET_LIVE_STATUS = "SET_LIVE_STATUS"
export const PROVIDE_STREAM = "PROVIDE_STREAM"
export const RECONNECT_STREAM = "RECONNECT_STREAM"
export const SET_LIVE_DURATION = "SET_LIVE_DURATION"
export const GET_VIDEO_SOURCE = "GET_VIDEO_SOURCE"
export const SET_VIDEO_SOURCE = "SET_VIDEO_SOURCE"


export function dispatchLive(param){
    return {
      type: param.type,
      payload: param.payload,
    };
  }