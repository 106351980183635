
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Navbar,
    Nav,
    NavItem,
    NavLink
  } from 'reactstrap';
  import  { Link } from 'react-router-dom';

import MenuList from './MenuList';
import HeaderMenuList from '../Header/HeaderMenuList';
import { SETTING_MENU_SIDEBAR, dispatchApp } from '../../redux/actions/app';
import Cookies from 'js-cookie';
import {ADMIN, OWNER, SUPER_ADMIN} from './Role'

import './index.scss';
import { groupBy } from 'lodash';

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {userRole: null}
      }

    componentDidMount = () => {
        const user = Cookies.get('user')
        const userInfo = user ? JSON.parse(user != undefined ? user : null) : {};
        const userRole = userInfo.shopId ? OWNER : Cookies.get('role') === 'admin' ? ADMIN : SUPER_ADMIN;
        this.setState({userRole});
        const currentPath = this.props.location.pathname;
        const group = HeaderMenuList.items.find(menu => menu.url === '/'+currentPath.split('/')[1]);
        if(group) {
            group.title = group.name;
            this.setSideBarGroupMenu(group);
        }
    }

    setSideBarGroupMenu = (groupMenu) => {
        this.props.dispatch(dispatchApp({ type: SETTING_MENU_SIDEBAR, 
                                          payload: {group: groupMenu.group,
                                                    title: groupMenu.name,
                                                    permission: groupMenu.permission}, url: groupMenu.url}))
    }

    getActiveSubmenuClassName = (currentPath,menu) => {

        let classNameElm = '';
            if(currentPath === menu.url) classNameElm = 'active';
            if(menu.subMenu !== undefined) {
               menu.subMenu.map(menu => {
                    if(currentPath === menu.url) classNameElm = 'active';
                    if(menu.subMenu !== undefined && menu.subMenu.find(menu => menu.url === currentPath)) {
                        classNameElm = 'active';
                    }
                })
            }
        return classNameElm;
    }

    render() {
      const { tabMenuHeader } = this.props
      const currentPath = this.props.location.pathname
      const { userRole } = this.state

      return  (
        <React.Fragment>
          <section id='sidebar'>
            <Navbar color='faded' expand='md'>
              <Nav vertical navbar>
                {MenuList.items
                  .filter((menu) => menu.group.findIndex(name => name === tabMenuHeader.group ) > -1)
                  .map((menu, index) => (
                    <React.Fragment key={index}>
                      {menu.show == true && menu.role.includes(userRole) && (
                        <NavItem
                          className={this.getActiveSubmenuClassName(
                            currentPath,
                            menu,
                          )}
                        >
                          <Link to={menu.url}>
                            {menu.icon} <span>{menu.name}</span>
                          </Link>
                        </NavItem>
                      )}
                    </React.Fragment>
                  ))}
              </Nav>
            </Navbar>
          </section>
        </React.Fragment>
      )
    }
}

const mapStateToProps = state => ({
    tabMenuHeader: state.app.tabMenuHeader,
})

export default connect(mapStateToProps)(Sidebar)

