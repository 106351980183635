import TableHeader from 'components/TableHeader'
import React, { Component } from 'react'
import { ReactComponent as IconNotSelect } from 'images/icons/not-select-circle.svg'
import { ReactComponent as IconSelect } from 'images/icons/icon-select.svg'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg'
import { ReactComponent as IconRemove } from 'images/icons/icon-remove-dash.svg'
import { ReactComponent as IconClose } from 'images/icons/close.svg'
import { getUserRole } from 'helper/utils'
import { OWNER } from 'layout/Sidebar/Role'
import classnames from 'classnames'
import host from 'config/host'
import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import { displayDateThShortYear } from 'helper/timeUtil'
import { dispatchRibbon, HANDLE_SET_PERMISSION_DECORATE, SET_MODAL_ADD_PRODUCT_RIBBON, SET_MODAL_CONFIRM_RIBBON, SET_MODAL_CREATE_CATEGORIES, SET_MODAL_CREATE_RIBBON } from 'redux/actions/ribbon'
import { ButtonAdd } from 'components/output'
import { ReactComponent as IconPencil } from 'images/icons/pencil.svg'
import exampleProduct from 'images/icons/example-product.svg'
import './styles.scss'
import modalPreview from 'components/modalPreview'
import { ReactComponent as IconHint } from 'images/icons/hint.svg'

const labelData = {
    ribbon: {
        superSelect: 'ริบบิ้นที่เลือกใช้',
        buttonAdd: 'เพิ่มริบบิ้น'
    },
    frame: {
        superSelect: 'กรอบรูปที่เลือกใช้',
        buttonAdd: 'เพิ่มกรอบรูป'
    }
}

const statusDecorate = {
    show: {
        showStatus: 'ใช้งานอยู่'
    },
    hide: {
        showStatus: 'ปิดใช้งาน'
    },
    expired: {
        showStatus: 'หมดอายุ'
    }
}

export default class ListRibbonTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalPreview: {
                activeIndex: null,
                data: null
            }
        }
    }

    openModalAddProduct = (e, ribbon) => {
        if (ribbon.status === "expired") return
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: SET_MODAL_ADD_PRODUCT_RIBBON, payload: { isOpen: true, data: ribbon } }))
        e.stopPropagation()
    }

    handleDeleteDecorate = (e, ribbon) => {
        const { dispatch } = this.props
        dispatch(
            dispatchRibbon({
                type: SET_MODAL_CONFIRM_RIBBON,
                payload: { isOpen: true, message: `ยืนยันการลบ${this.props.ribbon.activeTab === "ribbon" ? 'ริบบิ้น' : 'กรอบรูป'}`, btnConfirm: 'ยืนยัน', btnCancel: 'ยกเลิก', data: ribbon, type: 'delete' }
            }))
        e.stopPropagation()
    }

    handleRemoveSuper = (e, ribbon) => {
        this.handleSetPermissionNormalDecorate(ribbon)
        e.stopPropagation()
    }

    handleSetPermissionNormalDecorate = (ribbon) => {
        const { dispatch } = this.props
        dispatch(dispatchRibbon({ type: HANDLE_SET_PERMISSION_DECORATE, payload: { id: ribbon.id, priority: 2 } }))
    }

    handleApplyAllRibbon = (e, item) => {
        const { dispatch, ribbon } = this.props
        dispatch(dispatchRibbon({
            type: SET_MODAL_CONFIRM_RIBBON, payload: {
                isOpen: true,
                data: item,
                message: <div className="text-center">'สินค้าทั้งหมดที่ใช้ Tag เดียวกัน <br /> จะถูกเปลี่ยนมาใช้{ribbon.activeTab === "ribbon" ? "ริบบิ้น" : 'กรอบรูป'}นี้'</div>,
                btnConfirm: 'ยืนยัน',
                btnCancel: 'ยกเลิก',
                type: 'applyall'
            }
        }))
        e.stopPropagation()
    }

    showStatusTab = (status) => {
        if (this.props.ribbon.decorateTab.status === 'show') {
            switch (status) {
                case status:
                    return statusDecorate[status].showStatus
                default:
                    break;
            }
        } else {
            return <span style={{ color: 'red' }}>{statusDecorate['hide'].showStatus}</span>
        }
    }

    setModalPreviewStatus = (e, data, index) => {
        this.setState(prevState => {
            return {
                modalPreview: {
                    activeIndex: index,
                    data: data
                }
            }
        })
        e.stopPropagation()
    }

    closeModalPreview = (e) => {
        this.setState({
            modalPreview: {
                activeIndex: null,
                data: null
            }
        })
        e.stopPropagation()
    }

    showModalPreviewProductRibbon = (data, index) => {
        const { modalPreview } = this.state
        const { type } = this.props
        return (
            modalPreview.activeIndex === index && modalPreview.data !== null &&
            <div className={`modal-preview-product`}>
                <div className="d-flex justify-content-between flex-row align-items-center">
                    <span>ตัวอย่างการแสดงผล</span> <IconClose onClick={(e) => this.closeModalPreview(e)}
                        className="cursor-pointer" />
                </div>
                <div className="top-product-cover mt-2">
                    <div className="img-product-cover">
                        <div className="d-inline-block position-relative w-100">
                            <img src={exampleProduct} alt="" className="w-100" />
                        </div>

                        <div className="img-tag-cover" style={type === "frame" ? { top: '0' } : null}>
                            <div className="box-img-cover">
                                <div className="d-inline-block position-relative">
                                    <img src={`${host.image}/${data.imagePath?.path}`} alt=""
                                        style={type === "frame" ? { width: '100%', height: '100%' } : null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-product-item">
                    <div className="product-item-name">
                        <label class="product-item-name">แฟชั่นสมาร์ทวอชแบรนด์ดังจากสวิตเซอร์...</label>
                    </div>
                    <div className="box-product-price-old">
                        <div className="product-price-old"> ฿ 400</div>
                    </div>
                    <div className="product-price-item">
                        <div className="product-price">฿ 199</div>
                    </div>
                </div>

                <div className="stock-product-item">
                    <div className="box-stock-product">
                        <div className="limited-stock">
                            <label className="text-amount-product">ขายแล้ว 100 ชิ้น</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        const { ribbon, dispatch, pagination, type } = this.props
        const { modalPreview } = this.state
        return (
            <div className="table-ribbon" id="list-ribbon-table">
                {ribbon.listRibbon?.filter((itemRibbon) => itemRibbon.status === "show").length !== 0 && ribbon.listSuperRibbon?.length > 0 &&
                    <div className="super-ribbon-container">
                        <span className="ml-3 font-weight-bold">{labelData[type].superSelect}</span>
                        <div className="mt-3 detail-ribbon-table">
                            <React.Fragment>
                                <TableHeader
                                    dataHeader={['เอาออก', 'ลำดับ', 'ชื่อ', 'Tag', 'รูป', 'เวลาใช้งาน', 'สินค้าที่ใช้', 'สถานะ', <div style={{ width: '100%' }}></div>, <div style={{ width: '2em' }}></div>]}
                                    type={'header'}
                                    colWidth={['6em', '5em', '20%', '15%', '25%', '38%', '15%', '20%', '16%', '5em']}
                                    colSide={['center', 'center', 'left', 'left', 'left', 'left', 'right', 'center', 'center', 'center']}
                                />
                            </React.Fragment>
                            {ribbon.listSuperRibbon?.map((rib, ribidx) => (
                                rib.priority === 1 &&
                                <div style={{ cursor: 'default' }} key={`superRibbon-${ribidx}`}>
                                    <TableHeader
                                        dataHeader={[
                                            rib.priority === 1 ? <IconRemove onClick={(e) => getUserRole() !== OWNER && this.handleRemoveSuper(e, rib)} className={classnames({ "cursor-pointer": getUserRole() !== OWNER })} /> : null,
                                            <span style={{ color: rib.status === "expired" ? '#9D9D9D' : '#383633' }}>{ribidx + 1}</span>,
                                            rib.name,
                                            rib.key,
                                            rib.imagePath?.path ?
                                                <div className="d-flex align-items-center" style={{ maxHeight: '100%' }}>
                                                    <img src={`${host.image}${rib.imagePath?.path}`} alt="" style={{ maxWidth: ribbon.activeTab === "ribbon" ? '90%' : '4em' }} /></div> :
                                                <div className="add-image">
                                                    <IconCamera /> เพิ่มรูป
                                                </div>,
                                            rib.startDate && rib.endDate ? `${displayDateThShortYear(rib.startDate, "DD/MM/YY")} - ${displayDateThShortYear(rib.endDate, "DD/MM/YY")}` : 'ไม่จำกัด',
                                            <span className="color-primary"> {rib.productCount} </span>,
                                            <span
                                                className={classnames({ 'color-success': rib.status === "show", 'text-danger': rib.status === "hide" })}>
                                                {this.showStatusTab(rib.status)}
                                            </span>,
                                            <div style={{ width: '100%' }}>
                                                <span className="font-weight-bold color-primary">ตัวอย่าง <IconHint /></span>
                                                {/* {this.showModalPreviewProductRibbon(modalPreview.data, ribidx)} */}
                                            </div>,
                                            <div style={{ width: '2em' }}></div>
                                        ]}
                                        type={'content'}
                                        colWidth={['6em', '5em', '20%', '15%', '25%', '38%', '15%', '20%', '16%', '5em']}
                                        colSide={['center', 'center', 'left', 'left', 'left', 'left', 'right', 'center', 'center', 'center']}
                                        padding={'1em 0'}
                                        backgroundColor={rib.status === "expired" ? '#E1E1E1' : null}
                                        color={rib.status === "expired" ? '#9D9D9D' : null}
                                        height={'70px'}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                }

                <div className="list-ribbon-container">
                    <div className={classnames("d-flex flex-row align-items-center justify-content-between mt-1", { 'border-disabled': ribbon.listRibbon?.filter((ribbon) => ribbon.status === "show").length === 0 })}>
                        {ribbon.decorateTab?.name &&
                            <div className="d-flex flex-row align-items-center font-weight-bold">
                                <span className="ml-3">{ribbon.decorateTab?.name} ({ribbon.listAllRibbon?.length})</span>
                                {getUserRole() !== OWNER && <IconPencil className="ml-2 cursor-pointer" onClick={() => dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_CATEGORIES, payload: { isOpen: true, type: 'edit' } }))} />}
                            </div>
                        }
                        {ribbon.listAllRibbon && ribbon.listAllRibbon[ribbon.listAllRibbon.length - 1]?.imagePath && getUserRole() !== OWNER && ribbon.listRibbon?.filter((ribbon) => ribbon.status === "show").length !== 0 &&
                            <ButtonAdd
                                label={labelData[type].buttonAdd}
                                handleAdd={() => dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_RIBBON, payload: { isOpen: true, type: 'create' } }))}
                            />
                        }
                        {ribbon.listRibbon?.filter((ribbon) => ribbon.status === "show").length === 0 && <span className="text-danger mr-3">ปิดใช้งาน</span>}
                    </div>
                    <div className="detail-ribbon-table mt-3" style={{ padding: '1em', backgroundColor: '#f9f9f9' }}>
                        <TableHeader
                            dataHeader={['เลือกใช้', 'ลำดับ', 'ชื่อ', 'Tag', 'รูป', 'เวลาใช้งาน', 'สินค้าที่ใช้', 'สถานะ', <div style={{ width: '100%' }}></div>, <div style={{ width: '2em' }}></div>]}
                            type={'header'}
                            colWidth={['6em', '5em', '20%', '15%', '25%', '38%', '15%', '20%', '16%', '5em']}
                            colSide={['center', 'center', 'left', 'left', 'left', 'left', 'right', 'center', 'center', 'center']}
                        />
                        {ribbon.listRibbon?.map((ribbonItem, ribbonidx) => (
                            <div className={classnames({ "cursor-pointer": getUserRole() !== OWNER })}
                                onClick={() => getUserRole() !== OWNER && dispatch(dispatchRibbon({ type: SET_MODAL_CREATE_RIBBON, payload: { isOpen: true, data: ribbonItem, type: 'edit' } }))} key={`ribbonidx-${ribbonidx}`}>
                                <TableHeader
                                    dataHeader={[
                                        ribbonItem.status === "hide" || ribbonItem.status === "expired" || ribbonItem.imageId == null ? null : ribbonItem.priority === 1 ? <IconSelect onClick={(e) => e.stopPropagation()} style={{ cursor: 'default' }} /> : <IconNotSelect onClick={(e) => getUserRole() !== OWNER && this.handleApplyAllRibbon(e, ribbonItem)} />,
                                        <span style={{ color: ribbonItem.status === "expired" ? '#9D9D9D' : '#383633' }}>{(pagination.ribbon.page - 1) * pagination.ribbon.limit + ribbonidx + 1}</span>,
                                        <div className="linebreak-table-label"> {ribbonItem.name}</div>,
                                        <div className="linebreak-table-label">{ribbonItem.key}</div>,
                                        ribbonItem.imagePath?.path ?
                                            <div className="d-flex align-items-center" style={{ maxHeight: '100%' }}>
                                                <img src={`${host.image}${ribbonItem.imagePath?.path}`} alt="" style={{ maxWidth: ribbon.activeTab === "ribbon" ? '90%' : '4em' }} /></div> :
                                            <div className="add-image">
                                                <IconCamera /> เพิ่มรูป
                                                </div>,
                                        ribbonItem.startDate && ribbonItem.endDate ? `${displayDateThShortYear(ribbonItem.startDate, "DD/MM/YY")} - ${displayDateThShortYear(ribbonItem.endDate, "DD/MM/YY")}` : 'ไม่จำกัด',
                                        <span className="cursor-add color-primary" onClick={(e) => this.openModalAddProduct(e, ribbonItem)}> {ribbonItem.productCount} </span>,
                                        <span
                                            className={classnames({ 'color-success': ribbonItem.status === "show", 'text-danger': ribbonItem.status === "hide" })}>
                                            {/* {ribbonItem.status === "show" ? "ใช้งานอยู่" : ribbonItem.status === "hide" ? "ปิดใช้งาน" : ribbonItem.status === "expired" ? "หมดอายุ" : ribbonItem.status} */}
                                            {this.showStatusTab(ribbonItem?.status)}
                                        </span>,
                                        <div style={{ width: '100%' }} onClick={(e) => this.setModalPreviewStatus(e, ribbonItem, ribbonidx)}>
                                            <span className="font-weight-bold color-primary cursor-pointer">ตัวอย่าง <IconHint /></span>
                                            {this.showModalPreviewProductRibbon(modalPreview.data, ribbonidx)}
                                        </div>,
                                        <div style={{ width: '2em', marginRight: '1em' }}> {ribbonItem.default || getUserRole() === OWNER ? null : <IconTrash onClick={(e) => this.handleDeleteDecorate(e, ribbonItem)} className="mr-2" />} </div>
                                    ]}
                                    type={'content'}
                                    colWidth={['6em', '5em', '20%', '15%', '25%', '38%', '15%', '20%', '16%', '5em']}
                                    colSide={['center', 'center', 'left', 'left', 'left', 'left', 'right', 'center', 'center', 'center']}
                                    padding={'1em 0'}
                                    backgroundColor={ribbonItem.status === "expired" ? '#E1E1E1' : null}
                                    color={ribbonItem.status === "expired" ? '#9D9D9D' : null}
                                    height={'70px'}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
