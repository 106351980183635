import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const ModalPreview = (props) => {
  const { closeModal, children, isOpenModal } = props

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenModal)
  }, [isOpenModal])

  const onCloseModal = () => {
    setIsOpen(false)
    closeModal()
  }

  return (
    <Modal className='modal-preview-media' isOpen={isOpen} centered>
      <ModalHeader>
        <div className='icon-close-box'>
          <FontAwesomeIcon
            className='icon-close'
            icon={faTimes}
            onClick={onCloseModal}
          />
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

ModalPreview.defaultProps = {
  closeModal: () => {},
  isOpenModal: false,
}

export default ModalPreview
