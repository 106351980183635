import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import classnames from 'classnames'
import "./index.scss";

import host from 'config/host';
import { displayDateTh } from 'helper/timeUtil';
import ToggleInput from 'components/ToggleInput';
import { checkPermission } from 'helper/utils'

import IconRerun from 'images/icons/rerun.svg'
import IconTrash from 'images/icons/trash.svg';
import IconEdit from 'images/icons/edit.svg';
import IconStartingLive from 'images/icons/staringLive.svg';

import {
  GET_POST_BY_SHOP_ID,
  dispatchPost,
  SET_MODAL_RERUN_LIVE,
  HANDLE_DELETE_POST_LIVE
} from 'redux/actions/post'

import {
    SET_CURRENT_POST,
    dispatchShop,
} from 'redux/actions/shop'
  

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';

class LiveItem extends Component {
  componentDidMount() {}

  openModalRerunLive = (liveSelected) => {
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'isOpen', value: true } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'currentLive', value: liveSelected } }))
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key: 'mode', value: 'update' } }))
  } 
  setCurrentPost = (post) => {
    localStorage.setItem('post', JSON.stringify(post))
    this.props.dispatch( dispatchShop({ type: SET_CURRENT_POST, payload: { post } }), )
  }

  render() {
    const { onVodStatusChange,
            onDeletePost, 
            post,
            groupName,
            index } = this.props;
    const { status, 
            items, 
            title, 
            cover, 
            releaseDate, 
            postId, 
            upstreamStatus, 
            type, 
            mediaId, 
            totalViews,
            _id=''  } = post;
    // const products = Array.from({ length: 34 });
    const products = items;
    const {value} = {value: true};
    return (
      <React.Fragment>
        <div className="item-content">
          <div className="row">
            {/* ANCHOR - product image preview */}
            <div className="col-3">
              <div className="item-preview">
                <img className={classnames({"image-bw" : (type === 'live' &&  upstreamStatus === 'finish')})} 
                     src={`${host.avatar}${cover?.path}`} />
                 <p>ID : {post?._id}</p>
                {
                    type === 'rerun' &&
                    <div className="image-ribbin">
                        <img src={IconRerun} />
                    </div>
                }
              </div>
            </div>
            {/* ANCHOR - product detail */}
            <div className="col-9">
              <div className="item-detail">

                {/* ANCHOR - product header */}
                <div className="row">
                  <div className="col-8 pl-3">
                            {upstreamStatus === 'publish' && ( <img src={IconStartingLive} /> )}
                            {upstreamStatus === 'ready' && ( <div className='badge badge-red mr-2'> ยังไม่กดเริ่มไลฟ์ </div> )}
                            {upstreamStatus === 'finish' && ( <div className='badge badge-gray mr-2'> ไลฟ์จบแล้ว </div> )}
                            <span className='date-add ml-2'>วันที่สร้างโพสต์ {displayDateTh( releaseDate, 'D MMM YYYY HH:mm', )}</span>
                  </div>
                  <div className="col-4 text-right">

                    { (upstreamStatus === 'ready' || upstreamStatus === 'disconnect' || upstreamStatus === 'publish') &&
                        <React.Fragment>
                            <Link to={`/manage/shop/shopdetail/managelive`} onClick={() => this.setCurrentPost(post) } >
                                <button className='btn-info'> จัดการไลฟ์ </button>
                            </Link>
                        </React.Fragment>
                    }

                    {/* ANCHOR - manage live container */}
                    { (upstreamStatus === 'wait') &&
                        <React.Fragment>
                            { (type === 'live' &&  upstreamStatus === 'wait') &&  
                                <Link to={`/manage/shop/shopdetail/managelive`} onClick={() => this.setCurrentPost(post) } >
                                    <button className='btn-info'> เริ่มไลฟ์ </button>
                                </Link> 
                            }
                            {/* { (type === 'live' &&  upstreamStatus === 'finish') && <button className="btn-info" onClick={() => this.openModalRerunLive(post)}>สร้างรีรัน</button> }*/}
                            {type === 'live' && 
                                <Link to={`/manage/shop/shopdetail/editlive?postId=${postId}&mediaId=${mediaId}&mode=update`}>
                                        {checkPermission('post','create','rw') ? 
                                        <React.Fragment> <img src={IconEdit} className="cursor-pointer" /> </React.Fragment> : null}
                                </Link> 
                            }
                            {type === 'rerun' && 
                                <React.Fragment> 
                                  <img src={IconEdit} className="cursor-pointer" 
                                       onClick={() => this.openModalRerunLive(post)}/> 
                                </React.Fragment> 
                            }
                            <img src={IconTrash} className="cursor-pointer" onClick={() => onDeletePost({post, groupName})} />
                        </React.Fragment>
                    }
                    { (upstreamStatus === 'finish') &&
                      <React.Fragment>
                          <div>
                            <span>ยอดการรับชมทั้งหมด {totalViews} ครั้ง</span>
                          </div>
                      </React.Fragment>
                    }

                  </div>
                </div>

                {/* ANCHOR - product content */}
                <div className="row">
                  <div className="col-12">
                    <h4>{title}</h4>
                  </div>
                </div>
                
                {/* ANCHOR - product images */}
                <div className="row">
                  <div className="col-12">
                    <h5>สินค้าที่ขาย {items?.length} รายการ</h5>
                    <div className="product-list">
                      {true ? products.map((item,index) => {
                          if(index < 6)
                          return (
                            <div className="product-image">
                              <img src={`${host.avatar}${item?.imagePath?.path}`} />
                            </div>
                          )
                        }
                      )
                       : (
                        <span>ยังไม่ได้ใส่สินค้า</span>
                      )}
                      {
                        products.length > 6 && 
                        <div className="product-image product-image-more">
                              <div className="bg-cover"></div>
                              <img src={`${host.avatar}image/a9cd824d-9b15-454b-b79e-9f6dff085450.jpeg`} />
                              <span>{`+${products.length-6}`}</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isMute: state.live.isMute,
});

export default connect(mapStateToProps)(LiveItem);
