import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop';
import { dispatchProduct, ADD_KEY_VALUE_PRODUCT, ADD_IMAGE_PATH, ADD_IMAGE_SEO } from '../../redux/actions/product';

class ModalUploadImage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            crop: {
                unit: 'px',
                width: 168,
                height: 168,
                aspect: 1 / 1,
            },
            cropSuccess: false,
            croppedImageUrl: null,
            coverImage: [],
            pathImage: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.isOpen != nextProps.isOpen) {
            return {
                isOpen: nextProps.isOpen,
            }
        }
        return null
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);

    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image

        // return new Promise((resolve, reject) => {
        //     canvas.toBlob(blob => {
        //         if (!blob) {
        //             //reject(new Error('Canvas is empty'));
        //             console.error('Canvas is empty');
        //             return;
        //         }
        //         blob.name = fileName;
        //         window.URL.revokeObjectURL(this.fileUrl);
        //         this.fileUrl = window.URL.createObjectURL(blob);
        //         resolve(this.fileUrl);
        //     }, 'image/jpeg');
        // });
    }

    // submitCropImage = () => {
    //     
    // }

    onAddImage = () => {
        let coverImg = this.state.coverImage ///send data array
        let pathImg = this.state.pathImage ///send data array
        const currentImg = this.state.croppedImageUrl
        const fileName = this.props.modalUploadImage.fileName
        // if (coverImg.length == 0) {
        //     coverImg.push(this.state.croppedImageUrl)
        // } else {
        pathImg.push(this.state.croppedImageUrl)
        // }

        const fileImage = { coverImg, pathImg, currentImg, fileName }
        this.props.onChange(fileImage);

        this.setState({
            coverImage: coverImg,
            pathImage: pathImg,
            crop: {
                unit: 'px',
                width: 168,
                height: 168,
                aspect: 1 / 1,
            },
            cropSuccess: false,
            croppedImageUrl: null,
        }, () => this.checkTypeImage(coverImg, pathImg, currentImg, fileName)
            // this.props.dispatch(dispatchProduct({ type: ADD_IMAGE_PATH, payload: { coverImage: coverImg, pathImage: pathImg } }))
        )
        this.props.isClose()
    }

    checkTypeImage = (coverImg, pathImg, currentImg, fileName) => {
        if (this.props.name === "productImage") {
            this.props.dispatch(dispatchProduct({
                type: ADD_IMAGE_PATH, payload: {
                    key: 'pathImage', value: {
                        fileName: fileName,
                        file: currentImg
                    }
                }
            }))
            if (this.props.product.seoImage.length == 0) {
                this.props.dispatch(dispatchProduct({
                    type: ADD_IMAGE_PATH, payload: {
                        key: 'seoImage', value: {
                            fileName: fileName,
                            file: currentImg
                        }
                    }
                }))
            }
        } else if (this.props.name === "seoImage") {
            this.props.dispatch(dispatchProduct({
                type: ADD_IMAGE_SEO, payload: {
                    key: 'seoImage', value: {
                        fileName: fileName,
                        file: currentImg
                    }
                }
            }))
        }

    }

    render() {
        const { isClose, isOpen, modalUploadImage, product, imgBase64, } = this.props
        return (
            <div>
                <Modal isOpen={this.state.isOpen} toggle={() => isClose()}>
                    <ModalHeader toggle={() => isClose()}>อัปโหลดรูปภาพ</ModalHeader>
                    <ModalBody>
                        {/* <img src={product.baseImg64} /> */}
                        <ReactCrop src={imgBase64} crop={this.state.crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            minWidth={168}
                            minHeight={168}
                            maxWidth={640}
                            maxHeight={640}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn-submit" id="crop-image" onClick={this.onAddImage}>ตกลง</button>
                        <button className="btn-cancel ml-2" onClick={() => isClose()}>ยกเลิก</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.modalUploadImage.isOpen,
    name: state.modalUploadImage.name,
    modalUploadImage: state.modalUploadImage,
    product: state.product,
    imgBase64: state.modalUploadImage.imgBase64
})

export default connect(mapStateToProps, null)(ModalUploadImage)