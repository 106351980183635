import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import close from '../../images/icons/close.svg';

import DatePicker from '../DatePicker';
import moment from 'moment';

class ModalCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: moment(new Date()).format("MM/DD/YYYY")
        }
    }

    // static getDerivedStateFromProps(props){
    //     const dateSelected = moment(props.value,"DD/MM/YYYY").format("MM/DD/YYYY")
    //     return {
    //         date: dateSelected
    //     }
    // }

    componentDidUpdate = prevProps => {
        if(this.props.value !== prevProps.value) {
            this.setState({date: this.props.value})
        }
    }

    onDatePickerChange = date => {
        let dateParts = date.split("/");
        let newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        this.setState({date: newDate});
        this.props.onChange(date);
    }

    onSubmitDateSelected = () => {
        const {date} = this.state;
        this.props.onSubmitDateSelected(date);
        this.props.toogleDisplay();
    }

    render() {

        const { date } = this.state;
        const { isOpen, toogleDisplay, allowpreviousDate, checkEndDate, checkStartDate } = this.props; /// this allowpreviousDate control previousDate on/off

        return (
            // <div style={{ width: '40em' , top: '37%', height: '1em', left:'26%'}} id="modalPreview">
            <div style={{ width: '40em' , top: '45px'}} id="modalPreview">
                
                <div className={`modal-preview ${isOpen ? "" : "modal-preview-hide"}`}>

                    <div className="bg-white" style={{ padding: '1.5em' }} >

                        <div className="d-flex justify-content-between mb-3">
                            <h5 className="align-items-end mb-0">ปฏิทิน</h5> 
                            <img src={close} alt="" onClick={toogleDisplay} className="cursor-pointer" />
                        </div>
                        <div className="modal-preview-container">
                            <DatePicker selectedDate={date}  onDayClick={this.onDatePickerChange} allowpreviousDate={allowpreviousDate} checkEndDate={checkEndDate} checkStartDate={checkStartDate}/>  {/* this allowpreviousDate control previousDate on/off*/}
                            <div className="d-flex mt-2 justify-content-end">
                                <button className="btn-submit" onClick={this.onSubmitDateSelected}>ตกลง</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

ModalCalendar.defaultProps = {
    checkEndDate: null,
    checkStartDate: null
}

const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps, null)(ModalCalendar)
