
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Footer extends Component {
 
    render() {
       return( <div>
            Footer page
        </div>
       )
    }
}

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(Footer)

