import moment from "moment";
import { SET_KEY_DATA_RIBBON, SET_KEY_RIBBON, SET_MODAL_CREATE_RIBBON,SET_MODAL_CREATE_CATEGORIES, SET_DATA_RIBBON_DEFAULT, SET_MODAL_ADD_PRODUCT_RIBBON, SET_MODAL_MESSAGE_RIBBON, SET_MODAL_CONFIRM_RIBBON, SET_PAGINATION_PRODUCT, SET_PAGINATION_RIBBON } from "redux/actions/ribbon";

const initialState = {
    activeTab: 'ribbon',
    decorateTab: 'สินค้ามาใหม่ (ค่าเริ่มต้น)',
    count: 0,
    ribbonActive: null,
    dataRibbon: {
        name: '',
        key:'',
        imageId:  null,
        dateLimit: true,
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        startTime: moment().format('HH:mm'),
        endTime: moment().format('HH:mm'),
        status: 'show'
    },
    dataCategories: {
        categoryName: ''
    },
    modalCreateRibbon: {
        isOpen: false,
        data: null,
        type: null
    },
    modalCreateCategories: {
        isOpen: false,
        data: null,
        type: null
    },
    decorateName: '',
    listDecorate: [],
    modalAddProduct: {
        isOpen: false,
        data: null
    },
    listProduct: [],
    listProductSelected: [],
    listShops: [],
    shopSelectFilter: {},
    searchProduct:'',
    modalMessage: {
        isOpen: false,
        data: null
    },
    modalConfirm: {
        isOpen: false,
        message: '',
        btnConfirm: '',
        btnCancel: ''
    },
    errDecorateName: false,
    pagination: {
        product: {
            page: 1,
            limit: 10,
            total: null
        },
        ribbon: {
            page: 1,
            limit: 10,
            total: null
        }
    },
    listProductShop:[],
    executingPriority: false
}

export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case SET_KEY_RIBBON:
            return {
                ...state,
                [payload.key]: payload.value
            }
        case SET_MODAL_CREATE_RIBBON:
            return {
                ...state,
                modalCreateRibbon: {
                    ...state.modalCreateRibbon,
                    isOpen: payload.isOpen !== null ? payload.isOpen : state.modalCreateRibbon.isOpen,
                    data: payload.data ? payload.data : state.modalCreateRibbon.data,
                    type: payload.type
                }
            }

        case SET_KEY_DATA_RIBBON: 
        return {
            ...state,
            dataRibbon: {
                ...state.dataRibbon,
                [payload.key] : payload.value
            }
        }

        case SET_MODAL_CREATE_CATEGORIES:
            return {
                ...state,
                modalCreateCategories: {
                    ...state.modalCreateCategories,
                    isOpen: payload.isOpen !== null ? payload.isOpen : state.modalCreateCategories.isOpen,
                    data: payload.data ? payload.data : state.modalCreateCategories.data,
                    type: payload.type
                }
            }

        case SET_DATA_RIBBON_DEFAULT:
            return {
                ...state,
                dataRibbon: {
                    ...initialState.dataRibbon
                }
            }

        case SET_MODAL_ADD_PRODUCT_RIBBON: 
        return {
            ...state,
            modalAddProduct: {
                ...state.modalAddProduct,
                isOpen : payload.isOpen,
                data: payload.data
            }
        }
        case SET_MODAL_MESSAGE_RIBBON:
            return {
                ...state,
                modalMessage: {
                    ...state.modalMessage,
                    isOpen: payload.isOpen,
                    data: payload.data
                }
            }

        case SET_MODAL_CONFIRM_RIBBON: 
        return {
            ...state,
            modalConfirm: {
                ...state.modalConfirm,
                isOpen: payload.isOpen,
                message: payload.message,
                btnConfirm: payload.btnConfirm,
                btnCancel: payload.btnCancel,
                data: payload.data,
                type: payload.type
            }
        }

        case SET_PAGINATION_PRODUCT:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    product: {
                        page: payload.page ? payload.page : state.pagination.product.page,
                        limit: payload.limit ? payload.limit : state.pagination.product.limit,
                        total: payload.total ? payload.total : state.pagination.product.total
                    }
                }
            }
            case SET_PAGINATION_RIBBON:
                return {
                    ...state,
                    pagination: {
                        ...state.pagination,
                        ribbon: {
                            page: payload.page ? payload.page : state.pagination.ribbon.page,
                            limit: payload.limit ? payload.limit : state.pagination.ribbon.limit,
                            total: payload.total ? payload.total : state.pagination.ribbon.total
                        }
                    }
                }
        default: return state
    }
}