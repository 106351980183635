import { Checkbox } from 'antd'
import host from 'config/host'
import React, { useState, useEffect } from 'react'
import './styles.scss'
import { ReactComponent as IconCloseCircle } from 'images/icons/close-circle.svg'
import { isEqual } from 'lodash'



const CardItemModal = (props) => {
    const { product, onSelectProduct, handleCheck, type, listProduct, ribbon, productidx } = props

    const onChange = (e) => {
        onSelectProduct(e.target.value)
    }



    return (
        <React.Fragment>
            <div id="card-item-ribbon" className="card-container" style={{ height: props.type === "select" ? '160px' : '190px' }} key={productidx}>
                {type === "select" && <IconCloseCircle onClick={() => onSelectProduct(product)} className="cursor-pointer icon-close" />}
                <div style={{ maxWidth: '100%', height: 'auto', maxHeight: '100px', overflow: 'hidden' }} className="w-100 h-100 position-relative">
                    <div className="container-image-product">


                        {ribbon.activeTab === "ribbon" && (product.ribbon && Object.keys(product.ribbon).length > 0) && product.ribbon?.decorationGroupId !== ribbon.decorateTab?.id ?
                            <React.Fragment>
                                <div className="item-already-use">  </div>
                                <span className="text-img">สินค้านี้ใช้กับริบบิ้น <br /> {product.ribbon?.decorationGroup?.name} </span>
                            </React.Fragment> :
                            ribbon.activeTab === "frame" && (product.frame && Object.keys(product.frame).length > 0) && product.frame?.decorationGroupId !== ribbon.decorateTab?.id ?
                            <React.Fragment>
                                <div className="item-already-use">  </div>
                                <span className="text-img">สินค้านี้ใช้กับกรอบรูป <br /> {product.ribbon?.decorationGroup?.name} </span>
                            </React.Fragment> : null
                        }

                        <img src={host.image + product?.imagePath[0]?.path} alt="" className="w-100" />
                    </div>
                </div>

                <div className="position-relative">
                    <div className="item-already-use">  </div>
                </div>

                <div className="detail-product">
                    <span className="product-name">{product?.name}</span>
                    <div className="d-flex justify-content-end mt-2 price-detail">
                        {type === 'list' &&
                            <Checkbox
                                checked={handleCheck(product)}
                                disabled={false}
                                onChange={(product) => onChange(product)}
                                value={product}
                            />
                        }
                    </div>
                </div>
            </div>


        </React.Fragment >

    )
}

CardItemModal.defaultProps = {
    handleCheck: () => { },
    type: 'list' //select
}

export default CardItemModal

