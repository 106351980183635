
import {
  SET_USER_FIELD_INPUT,
  SET_USER_INFO,
  FETCH_USER_LOGIN,
  ADD_USER_INFO,
  SET_AUTH_USER,
  SET_KEY_USER,
  SET_USER_SHOP,
  CREATE_USER_SHOP_FIELD,
} from '../actions/user'  
import Cookies from 'js-cookie'


const MOCK_LINE_PROFILE = {
//    displayName  : "vivo v9 [ Test ]",
//    userId :  "U4cba7830902f7e4139bee2000313295b"
   displayName  : "samsung j7 [ Test ]",
   userId :  "Uca30998c88ff8c48bf4dd897c2ab6d31"
}

const ERR_OUT_OF_SERVICE = {
    title : "ไม่สามารถสั่งได้",
    message: "คุณอยู่นอกพื้นที่บริการ",
    footerMessage: "ขออภัยในความไม่สะดวก"  
}

const INVALID_OTP  = "รหัส OTP ไม่ถูกต้อง"
const OVER_REQ_OTP  =  "ขอรหัส OTP เกิน"

const OTP_ERROR_MSG  = {
    "INVALID_OTP" : INVALID_OTP,
    "OVER_REQ_OTP" : OVER_REQ_OTP   
}

const user = Cookies.get('user')

const initialState = {
  userInfo: user ? JSON.parse(user != undefined ? user : null) : {},
  errLogin: null,
  role: user && JSON.parse(user).shopId != null ? 'brand' : 'superadmin',
  userShop: null,
  errUserShop: null,
}


    // "mobile": "0845403346",
    // "displayName": "yoyo",
    // "isVerify": true,
    // "createAt": "2018-10-25T09:24:47.822Z",
    // "userType": "customer",
    // "isActive": true,
    // "isSetPassword": false,
    // "verifyEmailTime": "2018-10-25T09:24:47.822Z",
    // "emailVerified": true,
    // "notiShop": true,
    // "notiCustomer": true,
    // "platform": "cms",
    // "contactMobile": "0845403346",
    // "email": "0845403346@saranros.tmp",
    // "id": "5bd18bdf1994a42adb0206a1"
    

export default function (state = initialState, action) {


    const { type, payload } = action
    switch (type) {
      // case  SET_USER_FIELD_INPUT : {
      //     const  {key ,value , vaildateKey} = payload
      //     return{
      //         ...state,
      //        [key]  : value,
      //        [vaildateKey] : true
      //     }
      //  }

      //  case FETCH_USER_LOGIN : {
      //
      //      return {
      //          ...state,
      //      }
      //  }

      case ADD_USER_INFO: {
        return {
          ...state,
          userInfo: payload,
        }
      }

      case SET_AUTH_USER: {
        return {
          ...state,
          userAuth: payload,
        }
      }

      case SET_KEY_USER: {
        return {
          ...state,
          [payload.key]: payload.value,
        }
      }

      case SET_USER_SHOP: {
        return {
          ...state,
          userShop: payload,
        }
      }

      case CREATE_USER_SHOP_FIELD: {
        return {
          ...state,
          errUserShop: payload,
        }
      }

      default:
        return state
    } //  end swicth

}