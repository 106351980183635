import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ButtonAdd, ModalLarge } from 'components/output'
import { TextInput, Textarea, NumberInput, FloatInput } from 'components/input'
import {
  dispatchUser,
  CREATE_USER_SHOP,
  SKIP_CREATE_USER,
  CREATE_USER_SHOP_FIELD,
} from 'redux/actions/user'
import { dispatchShop, CREATE_SHOP } from 'redux/actions/shop'
import SelectInput from 'components/SelectInput'
import shopProvider from 'provider/shopProvider'
import { isEmpty } from 'lodash'
const shopProvider_api = new shopProvider()

const title = {
  createUserShop: 'สร้างผู้ใช้',
  createShop: 'สร้างร้านค้า',
}

const ModalAddShop = (props) => {
  const { userShop, dispatch, errUserShop, errorShop, listShop } = props

  const [statusModal, setStatusModal] = useState('none')
  const [data, setData] = useState({})
  const [seo, setSeo] = useState({})
  const [error, setError] = useState(null)
  const [listProvince, setListProvince] = useState([])

  useEffect(() => {
      setData({ province: listProvince && listProvince[0]?.label })
  }, [listProvince])

  useEffect(() => {
    if (userShop) {
        getListProvince()
        // setData({ province: listProvince && listProvince[0]?.label })
        toggleModal('createShop')
    }
  }, [userShop])

  useEffect(() => {
    if (error && Object.keys(error).length === 0) {
      submit()
    }
  }, [error])

  useEffect(() => {
    if (errorShop === 'success') {
      onClose()
    } else if (errorShop) {
      setError(errorShop)
    }
  }, [errorShop])

  useEffect(() => {
    if (errUserShop === 'validation') {
      validator()
    } else {
      setError(errUserShop)
    }
  }, [errUserShop])

  const toggleModal = (modal) => {
    setStatusModal(modal)
  }

  const handleChangeText = (text, key) => {
    if ((key === 'mobile' && text.length < 11) || key !== 'mobile') {
      setData({
        ...data,
        [key]: text,
      })
    }
  }

  const handleChangeSeo = (text, key) => {
    setSeo({
      ...seo,
      [key]: text,
    })
  }

  const onClickSkipCreateUser = () => {
    dispatch(
      dispatchUser({
        type: SKIP_CREATE_USER,
        payload: data,
      }),
    )
  }

  const onChangeFloatInput = (value, key) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const getListProvince = async () => {
    if (listShop.length != 0) {
      const listProvince = await shopProvider_api.getListProvince()
      const provinces = []
      listProvince.data.map((province) => {
        provinces.push({ label: province.provinceName, value: province })
      })
      if (statusModal === 'createShop') {
        await setData({ ...data, province: provinces && provinces[0]?.value?.provinceName })
      }
      await setListProvince(provinces)
    }
  }

  const renderContent = () => {
    switch (statusModal) {
      case 'createUserShop':
        return (
          <div className='bg-white p-5 m-5'>
            <h5 className='mb-3'>ข้อมูลผู้ใช้</h5>
            <div className='mb-5'>
              <TextInput
                label='อีเมลล์'
                handleChangeText={(text) => handleChangeText(text, 'email')}
                error={error && error.email}
                require
              />
              {error?.skipBtn && (
                <button
                  className='btn-box'
                  onClick={() => onClickSkipCreateUser(error.user)}
                >
                  สร้างร้านโดยใช้ user นี้
                </button>
              )}
            </div>
            <div className='mb-5'>
              <NumberInput
                label='เบอร์โทรศัพท์'
                defaultValue={data?.mobile}
                handleChangeText={(text) => handleChangeText(text, 'mobile')}
                error={error && error.mobile}
                require
              />
            </div>
            <div className='mb-5'>
              <TextInput
                label='รหัสผ่าน'
                handleChangeText={(text) => handleChangeText(text, 'password')}
                error={error && error.password}
                require
              />
            </div>
          </div>
        )

      case 'createShop':
        return (
          <div className='m-3'>
            <div className='bg-white p-5 mb-3'>
              <h5 className='mb-3'>ข้อมูลร้านค้า</h5>
              <div className='mb-3'>
                <TextInput
                  label='ชื่อ'
                  handleChangeText={(text) => handleChangeText(text, 'name')}
                  error={error && error.name}
                  require
                />
              </div>
              <div className='mb-3'>
                <Textarea
                  label='รายละเอียด'
                  handleChangeText={(text) =>
                    handleChangeText(text, 'description')
                  }
                />
              </div>
              <div className='mb-3'>
                <TextInput
                  label='อำเภอ/เขต'
                  handleChangeText={(text) => handleChangeText(text, 'zone')}
                  error={error && error.zone}
                  require
                />
              </div>

              <div className='mb-3'>
                <span className="require">จังหวัด</span>
                <SelectInput
                  placeholder={''}
                  options={listProvince}
                  value={listProvince.length !== 0 && !isEmpty(data.province) ? listProvince?.findIndex((province) => province.label === data.province) : null}
                  onChange={(value) => setData({ ...data, province: listProvince[value].label })}
                  iconClearSearch={false}
                />
              </div>

              <div className='mb-3'>
                <NumberInput
                  label='ส่วนแบ่งร้านค้า'
                  defaultValue={data?.sharingShop}
                  handleChangeText={(text) =>
                    handleChangeText(text, 'sharingShop')
                  }
                  error={error && error.sharingShop}
                  require
                />
              </div>
              <div className='mb-3'>
                <NumberInput
                  label='ส่วนแบ่งระบบ'
                  defaultValue={data?.sharingPlatform}
                  handleChangeText={(text) =>
                    handleChangeText(text, 'sharingPlatform')
                  }
                  error={error && error.sharingPlatform}
                  require
                />
              </div>
              <div className=''>
                <FloatInput
                  name='paymentFee'
                  label='ค่าธรรมเนียมการชำระเงิน'
                  type='CURRENCY'
                  placeholder='0.00'
                  value={data?.paymentFee}
                  onChangeFloatInput={(value) =>
                    onChangeFloatInput(value, 'paymentFee')
                  }
                  require
                />
              </div>
            </div>
            <div className='bg-white p-5'>
              <h5 className='mb-3'>จัดการ SEO</h5>

              <div className='mb-3'>
                <TextInput
                  label='Title'
                  handleChangeText={(text) => handleChangeSeo(text, 'title')}
                  error={error && error.title}
                  require
                />
              </div>
              <div className='mb-3'>
                <Textarea
                  label='Description'
                  handleChangeText={(text) =>
                    handleChangeSeo(text, 'description')
                  }
                  error={error && error.description}
                  require
                />
              </div>
              <div className='mb-3'>
                <TextInput
                  label='Keyword'
                  handleChangeText={(text) =>
                    handleChangeSeo([text], 'keyword')
                  }
                  error={error && error.keyword}
                  require
                />
              </div>
              <div className='mb-3'>
                <TextInput
                  label='Permalink'
                  handleChangeText={(text) =>
                    handleChangeSeo(text, 'permalink')
                  }
                  error={error && error.permalink}
                  require
                />
              </div>
            </div>
          </div>
        )

      default:
        break
    }
  }

  const validator = () => {
    let tmpError = error === null ? {} : { ...error }

    switch (statusModal) {
      case 'createUserShop':
        if (!data.email) {
          tmpError = {
            ...tmpError,
            email: 'กรุณากรอกอีเมล',
          }
        } else {
          const regEmail = new RegExp(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          )

          if (!regEmail.test(data.email)) {
            tmpError = {
              ...tmpError,
              email: 'รูปแบบอีเมลไม่ถูกต้อง',
            }
          } else {
            delete tmpError.email
          }
        }

        if (!data.mobile) {
          tmpError = {
            ...tmpError,
            mobile: 'กรุณากรอกเบอร์โทรศัพท์',
          }
        } else if (
          data.mobile.toString().length !== 10 &&
          data.mobile.toString().length !== 9
        ) {
          tmpError = {
            ...tmpError,
            mobile: 'กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง',
          }
        } else {
          delete tmpError.mobile
        }

        if (!data.password) {
          tmpError = {
            ...tmpError,
            password: 'กรุณากรอกรหัสผ่าน',
          }
        } else {
          const regPass = new RegExp(/^[a-zA-Z0-9]{4,16}$/)

          if (!regPass.test(data.password)) {
            tmpError = {
              ...tmpError,
              password: 'รหัสผ่านต้องมีจำนวนอยู่ระหว่าง 4-16 ตัว',
            }
          } else {
            delete tmpError.password
          }
        }

        setError(tmpError)
        break

      case 'createShop':
        if (!data.name) {
          tmpError = {
            ...tmpError,
            name: 'กรุณากรอกชื่อร้านค้า',
          }
        } else {
          delete tmpError.name
        }

        if (!data.zone) {
          tmpError = {
            ...tmpError,
            zone: 'กรุณากรอกโซนร้านค้า',
          }
        } else {
          delete tmpError.zone
        }

        if (!data.sharingShop) {
          tmpError = {
            ...tmpError,
            sharingShop: 'กรุณากรอกส่วนแบ่งร้านค้า',
          }
        } else {
          delete tmpError.sharingShop
        }

        if (!data.sharingPlatform) {
          tmpError = {
            ...tmpError,
            sharingPlatform: 'กรุณากรอกส่วนแบ่งระบบ',
          }
        } else {
          delete tmpError.sharingPlatform
        }

        if (!data.paymentFee) {
          tmpError = {
            ...tmpError,
            paymentFee: 'กรุณากรอกค่าธรรมเนียมการชำระเงิน',
          }
        } else {
          delete tmpError.paymentFee
        }

        if (!seo.title) {
          tmpError = {
            ...tmpError,
            title: 'กรุณากรอก Title',
          }
        } else {
          delete tmpError.title
        }

        if (!seo.description) {
          tmpError = {
            ...tmpError,
            description: 'กรุณากรอกรายละเอียด',
          }
        } else {
          delete tmpError.description
        }

        if (!seo.keyword) {
          tmpError = {
            ...tmpError,
            keyword: 'กรุณากรอก Keyword',
          }
        } else {
          delete tmpError.keyword
        }

        if (!seo.permalink) {
          tmpError = {
            ...tmpError,
            permalink: 'กรุณากรอก Permalink',
          }
        } else {
          delete tmpError.permalink
        }

        setError(tmpError)
        break

      default:
        break
    }
  }

  const submit = () => {
    switch (statusModal) {
      case 'createUserShop':
        dispatch(
          dispatchUser({
            type: CREATE_USER_SHOP,
            payload: data,
          }),
        )

        break

      case 'createShop':
        dispatch(
          dispatchShop({
            type: CREATE_SHOP,
            payload: {
              token: userShop.token.accessToken,
              body: {
                ...data,
                userId: userShop.user.id,
                sharingShop: parseInt(data.sharingShop, 10),
                sharingPlatform: parseInt(data.sharingPlatform, 10),
                paymentFee: parseFloat(data.paymentFee),
                seo: {
                  ...seo,
                  seoLength: {
                    title: 'bad',
                    description: 'bad',
                    keyword: 'bad',
                  },
                },
              },
            },
          }),
        )

        break

      default:
        break
    }
  }

  const onClose = () => {
    toggleModal('none')
    setData({})
    setError(null)
    setSeo({})
  }

  return (
    <>
      <ButtonAdd
        label='เพิ่มร้านค้า'
        handleAdd={() => toggleModal('createUserShop')}
      />
      <ModalLarge
        title={title[statusModal]}
        closeModal={onClose}
        clickCancel={onClose}
        clickSubmit={() => {
          if (statusModal === 'createUserShop') {
            dispatch(
              dispatchUser({
                type: CREATE_USER_SHOP_FIELD,
                payload: 'validation',
              }),
            )
            setError(null)
          } else {
            validator()
          }
        }}
        labelButton='สร้าง'
        labelCancel='ยกเลิก'
        isOpenModal={statusModal !== 'none'}
        isShowFooter
      >
        {renderContent()}
      </ModalLarge>
    </>
  )
}

const mapStateToProps = (state) => ({
  userShop: state.user.userShop,
  errUserShop: state.user.errUserShop,
  errorShop: state.shop.errorShop,
  listShop: state.shop.shops
})

export default connect(mapStateToProps, null)(ModalAddShop)
