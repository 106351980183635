import apiClass from '../helper/api'
import host from '../config/host'
import axios from '../config/axios';


class liveProvider extends apiClass {
  constructor() {
    super({
      baseUrl: host.chat,
      restPath: "/auth/loopback",
    })
  }

  getVideoSources = async(query) => {
    try {
        const url = `${host.api}/stream/upload/mp4`
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        
    }
  }
  
}

export default new liveProvider()
