
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconSearch from '../images/icons/search.svg';
import IconNoShop from '../images/icons/no-shop.svg';
import IconLive from '../images/icons/live.svg';
import IconShopAvatar from '../images/icons/default-shop-logo.svg';
import { formatNumber, setLocalStorage } from '../helper/utils';
import host from '../config/host';
import noItem from '../images/icons/status-error.svg'


import { SETTING_MENU_SIDEBAR, dispatchApp } from '../redux/actions/app';
import { GET_SHOPS, GET_SHOPS_SEARCH, SET_CURRENT_SHOP, dispatchShop, ADD_KEY_VALUE_SHOP, ON_SEARCH_SHOP } from '../redux/actions/shop';

import '../styles/_shop.scss';
import PaginationComponent from '../components/pagination'
import { InputSearch } from 'components/output';
import ModalAddShop from 'components/ModalAddShop'
// import {Pagination} from 'antd'


class Shop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            shops: [],
            statusSearch: false,
            historySearch: '',
            search: ""
        }

    }

    componentDidMount = () => {
      setLocalStorage({key:'shopDetailDefaultTab',value:{tab:0}})
        localStorage.setItem("createProductBy", JSON.stringify({ createAt: "product" }));
        this.getShopList();
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.shops != this.props.shops) {
            this.setState({ shops: nextProps.shops });
        }
    }

    componentWillUnmount(){
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: 10 } }))
    }

    /* SECTION - Event */
    /* !SECTION */

    /* SECTION - Function */
    /* !SECTION */

    /* SECTION - Api */
    getShopList = () => {
        this.props.dispatch(dispatchShop({ type: GET_SHOPS }))
    }
    setCurrentShop = (shop) => {
        localStorage.setItem("shop", JSON.stringify(shop));
        this.props.dispatch(dispatchShop({ type: SET_CURRENT_SHOP, payload: { shop } }));
    }
    searchShop = (keyword) => {
        this.props.dispatch(dispatchShop({ type: GET_SHOPS_SEARCH, payload: { keyword } }))
    }
    /* !SECTION */

    getBadeStatusClass = (status) => {
        switch (status) {
            case "good": return "badge badge-green mr-2";
            case "normal": return "badge badge-orange mr-2";
            case "bad": return "badge badge-red mr-2";
        }
    }

    onChangePage = (page) => {
        if(this.state.statusSearch){
            this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.historySearch } }))
        } else {
            this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchShop({ type: GET_SHOPS }))
        }

    }

    onShowSizeChange = (page, pageSize) => {
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: page } }))
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'limit', value: pageSize } }))
    }

    onChangeSearchShop = (e) => {
        this.setState({ search: e.target.value })
    }

    onKeyPressSearchShop = (e) => {
        if(this.state.search.trim().length == 0){
            this.setState({historySearch: '',statusSearch: false})
        }
        if (e.key == "Enter") {
           this.onClickSearchShop()
        }
    }

    onClickSearchShop = () => {
        if(this.state.search.trim().length == 0){
            this.setState({historySearch: '',statusSearch: false})
            this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.search } }))
        } else {
            this.setState({historySearch: this.state.search,statusSearch: true})
            this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(dispatchShop({ type: ON_SEARCH_SHOP, payload: { search: this.state.search } }))
        }
    }

    onClearSearch = () => {
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'page', value: 1 } }))
        this.setState({search: '',statusSearch:false})
        this.getShopList()
    }

    
    render() {
        const { shops } = this.state;
        const { limit, page, total, pageSizeOptions, loading } = this.props
        return (
          <React.Fragment>
            <div className='shop'>
              <div className='shop-content'>
                <div className='d-flex justify-content-between'>
                  <h4>
                    ร้านค้าทั้งหมด{' '}
                    <span className='total-shop'> {total} รายการ</span>
                  </h4>
                  <ModalAddShop />
                </div>
                <div className='search-shop'>
                  <div style={{ width: '50%' }}>
                    <InputSearch
                      placeholder={'ค้นหาร้านค้า'}
                      onChange={this.onChangeSearchShop}
                      onKeyPress={this.onKeyPressSearchShop}
                      handleSearch={this.onClickSearchShop}
                      onClearSearch={this.onClearSearch}
                      statusSearch={this.state.statusSearch}
                      // defaultValue={this.state.search}
                    />
                  </div>

                  {/* <div className="input-text-field" style={{width:'50%'}} >
                            <input type="text" placeholder="ค้นหาร้านค้า" value={this.state.search} onChange={this.onChangeSearchShop} onKeyPress={this.onKeyPressSearchShop} />
                            <img src={IconSearch} alt="search" className="cursor-pointer" onClick={this.onClickSearchShop} />
                        </div> */}
                </div>
                {shops && shops.length == 0 ? null : (
                  <div className='mt-2'>
                    <PaginationComponent
                      defaultCurrent={1}
                      total={total}
                      onChange={(page) => this.onChangePage(page)}
                      pageSize={limit}
                      pageSizeOptions={pageSizeOptions}
                      onShowSizeChange={this.onShowSizeChange}
                      current={page}
                      locale={{ items_per_page: '/ หน้า' }}
                    />
                  </div>
                )}
                {/* <div className="no-shop">
                    <img src={IconNoShop}/>
                  </div> */}
                {shops && shops.length == 0 && !loading ? (
                  <div className='d-flex flex-column align-items-center justify-content-center h-50vh'>
                    <img src={noItem} alt='noItem' />
                    <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
                  </div>
                ) : (
                  <div className='shop-list'>
                    <div className='table-header'>
                      <div className='row'>
                        <div className='col-1 text-center'>ลำดับ</div>
                        <div className='col-3-atl text-center'>ชื่อร้านค้า</div>
                        <div className='col-1-atl text-center'>กำลัง LIVE</div>
                        <div className='col-2 text-center'>รายการสินค้า</div>
                        <div className='col-1-atl text-center'>
                          สถานะร้านค้า
                        </div>
                        <div className='col-3 text-center'>สถานะ SEO</div>
                      </div>
                    </div>
                    <div className='table-body'>
                      {shops &&
                        shops.map((shop, index) => (
                          <Link to={`/manage/shop/shopdetail`} key={index}>
                            <div
                              className='row'
                              onClick={() => this.setCurrentShop(shop)}
                            >
                              <div className='col-1 align-center'>
                                {(page - 1) * limit + index + 1}{' '}
                              </div>
                              <div className='col-3-atl align-left shop-logo d-flex align-items-center'>
                                {shop.logoPath ? (
                                  <img
                                    src={`${host.avatar}${shop.logoPath.path}`}
                                    className='mr-1'
                                  />
                                ) : (
                                  <img
                                    src={IconShopAvatar}
                                    alt='IconShopAvatar'
                                  />
                                )}
                                <span className='ml-4'>{shop.name}</span>
                              </div>
                              <div className='col-1-atl align-center'>
                                {' '}
                                {shop.isLive === true ? (
                                  <img src={IconLive} />
                                ) : (
                                  '-'
                                )}{' '}
                              </div>
                              <div className='col-2 align-center'>
                                {formatNumber(shop.amount)}
                              </div>
                              <div className='col-1-atl align-center'>
                                {
                                  ///status request mock open
                                  shop.status === 'show' ||
                                  shop.status === 'request' ? (
                                    <span className='badge badge-green'>
                                      {' '}
                                      เปิด{' '}
                                    </span>
                                  ) : (
                                    <span className='badge badge-gray'>
                                      {' '}
                                      ปิด{' '}
                                    </span>
                                  )
                                }
                              </div>
                              <div className='col-3 align-center'>
                                {/* TODO - wait for api */}
                                {/* <span className={this.getBadeStatusClass(shop.seoStatus.title)}> Title </span> 
                                      <span className={this.getBadeStatusClass(shop.seoStatus.description)}> Description </span> 
                                      <span className={this.getBadeStatusClass(shop.seoStatus.keyword)}> Keyword </span>  */}
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              {shops && shops.length == 0 ? null : (
                <div className='float-right position-relative'>
                  <PaginationComponent
                    defaultCurrent={1}
                    total={total}
                    onChange={(page) => this.onChangePage(page)}
                    pageSize={limit}
                    pageSizeOptions={pageSizeOptions}
                    onShowSizeChange={this.onShowSizeChange}
                    current={page}
                    locale={{ items_per_page: '/ หน้า' }}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    shops: state.shop.shops,
    limit: state.shop.limit,
    page: state.shop.page,
    total: state.shop.total,
    pageSizeOptions: state.shop.pageSizeOptions,
    loading: state.app.loading
})

export default connect(mapStateToProps)(Shop)


