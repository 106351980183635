
export const getShop = state => state.shop
export const getProduct = state => state.product
export const getPost = state => state.post
export const getLiveState = state => state.live
export const getOrder = state => state.order
export const getGallery = state => state.gallery
export const getWithdraw = state => state.withdraw
export const getPlaylists = (state) => state.playlists
export const getPlaylistFrontPage = (state) => state.playlistFrontPage
export const getUser = state => state.user
export const getPromocode = state => state.promocode
export const getLogisticPrice = state => state.logisticPrice
export const getRibbon = state => state.ribbon
