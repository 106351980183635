import React from 'react'
import { ListShop, BreadcrumbGallery } from 'components/Gallery'

const Gallery = (props) => {

  const handleClickShop = ({ shopDetail }) => {
    props.history.push(`/gallery/albums`, {
      shopDetail,
    })
  }

  return (
    <>
      <BreadcrumbGallery page='gallery' {...props} />
      <ListShop onClickShop={handleClickShop} />
    </>
  )
}

export default Gallery
