/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import HeaderMenuList from '../Header/HeaderMenuList'
import SideMenuList from '../Sidebar/MenuList'
import { Link } from 'react-router-dom'
import './index.scss'

class Breadcrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbUrl: [],
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getBreadcrumb()
    }
  }

  componentDidMount = () => {
    this.getBreadcrumb()
  }

  getBreadcrumb = () => {
    const menuList = []
    HeaderMenuList.items.map((menu) => 
      menuList.push({ name: menu.name, url: menu.url, editHeadMenu: menu.editHeadMenu ? menu.editHeadMenu : null, nameEdit: menu.nameEdit ? menu.nameEdit : null,isIndex: menu.isIndex,linkUrl: menu.linkUrl }),
    )
    SideMenuList.items.forEach((menu) => {
      if (menu.subMenu)
        menu.subMenu.forEach((submenu) => {
          if (submenu.subMenu) {
            submenu.subMenu.forEach((submenuItem) => {
              menuList.push({ name: submenuItem.name, url: submenuItem.url, editHeadMenu: submenuItem.editHeadMenu ? submenuItem.editHeadMenu : null, nameEdit: submenuItem.nameEdit ? submenuItem.nameEdit : null })
            })
          }
          menuList.push({ name: submenu.name, url: submenu.url, editHeadMenu: submenu.editHeadMenu ? submenu.editHeadMenu : null, nameEdit: submenu.nameEdit ? submenu.nameEdit : null })
        })
      menuList.push({ name: menu.name, url: menu.url, editHeadMenu: menu.editHeadMenu ? menu.editHeadMenu : null, nameEdit: menu.nameEdit ? menu.nameEdit : null })
    })

    const currentPath = this.props.location.pathname
    const splitUrl = currentPath.split('/')

    let breadcrumbUrl = []
    let totalUrl = ''

    splitUrl.map((menu, index) => {
      if (menu) {
        totalUrl += `${'/' + menu}`

        const url = menuList.find((menu) => {
          return menu.url === totalUrl
        })
        if (url) {
          breadcrumbUrl.push(url)
        }
      }
    })

    let newBreadcrumEdit = []
    const getEditText = breadcrumbUrl.map((item, idx) => {
      if(item.name){
        newBreadcrumEdit.push({
          name: item.name,
          url: item.url,
          isIndex: item.isIndex,
          linkUrl: item.linkUrl,
        })
      }
      if (item.editHeadMenu) {
        newBreadcrumEdit[idx - 1] = {
          name: breadcrumbUrl[idx - 1].nameEdit,
          url: breadcrumbUrl[idx - 1].url,
        }
      }
    })

    breadcrumbUrl = newBreadcrumEdit
    this.setState({ breadcrumbUrl })
  }

  render() {
    const { breadcrumbUrl } = this.state
    return (
      <React.Fragment>
        <div className='breadcrumb-wrap'>
          {breadcrumbUrl.map((menu, index) => {
            if (menu.url !== '/gallery') {
              return (
                <Link
                  to={menu.isIndex ? menu.linkUrl : menu.url}
                  key={`${'breadcrumbUrl' + index}`}
                  className={`${'breadcrumb-url' +
                    (index === breadcrumbUrl.length - 1 ? ' active' : '')
                    }`}
                >
                  <span>{`${menu.name +
                    (index !== breadcrumbUrl.length - 1 ? ' / ' : '')
                    }`}</span>
                </Link>
              )
            }
          })}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Breadcrumb)
