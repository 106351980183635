import React, { Component } from "react";
import { connect } from "react-redux";

import './index.scss';

import host from 'config/host';
import numeral from 'numeral';
import InputNumber from 'components/InputNumber'
import IconTrash from 'images/icons/trash.svg'

const initialState = {
};

class ProductSkuItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  
  render() {
    
    const { productSku, params } = this.props;
    const { imagePath, sku, priceNet, extraPrice, errors, stockRemain} = productSku;
    const { discountPrice,
            discountPercent,
            buyLimit,
            privileges,} = extraPrice;
    return <React.Fragment>
            { productSku.select === true &&
            <div className="item-body">
                <div className="row p-2" style={{textAlign:'left', justifyContent: 'center'}}>
                    {/* ANCHOR - image */}
                    <div style={{width:"6%",  alignSelf: "center"}}> 
                        <img className="product-preview" src={`${host.avatar}${imagePath?.path}`} /> 
                    </div>
                    {/* ANCHOR - name */}
                    <div style={{width:"19%", alignSelf: "center"}}> 
                        <span className="title-sm">{sku}</span> 
                    </div>
                    {/* ANCHOR - priceNet */}
                    <div style={{width:"15%", alignSelf: "center"}}>
                        <span className="title-sm">฿ {priceNet}</span>
                    </div>
                    {/* ANCHOR - discountPrice */}
                    <div style={{width:"10%", alignSelf: "center"}}>
                        <div className='input-group'>
                            <InputNumber value={discountPrice}
                                         name={'discountPrice'}
                                         type={'COMMON'}
                                         className={`form-control ${ errors?.discountPrice ? 'error-field' : '' }`}
                                         placeholder={'0'}
                                         params={params}
                                         onFocus={this.props.onElementFocus}
                                         onChange={this.props.onChange} />
                            <div className='input-group-append'>
                                <span className='input-group-text text-number '>
                                    ฿
                                </span>
                            </div>
                        </div>
                        <span className='error-message'> {errors?.discountPrice ? '* ข้อมูลไม่ถูกต้อง' : ''} </span>
                    </div>
                    <div style={{width:"5%",  alignSelf: "center", textAlign:'center'}}>{'  '}หรือ</div>
                    {/* ANCHOR - discountPercent */}
                    <div style={{width:"10%", alignSelf: "center", padding:'0 5px'}}>
                        <div className='input-group'>
                            <InputNumber value={discountPercent}
                                        name={'discountPercent'}
                                        type={'COMMON'}
                                        className={`form-control ${ errors?.discountPercent ? 'error-field' : '' }`}
                                        placeholder={'0'}
                                        params={params}
                                        onFocus={this.props.onElementFocus}
                                        onChange={this.props.onChange} />
                            <div className='input-group-append'>
                                <span className='input-group-text text-number '>
                                    %
                                </span>
                            </div>
                        </div>
                        <span className='error-message'> {errors?.discountPercent ? '* ข้อมูลไม่ถูกต้อง' : ''} </span>
                    </div>
                    {/* ANCHOR - privileges */}
                    <div style={{width:"20%", alignSelf: "center", padding:'0 5px', maxWidth: '150px'}}>
                        <div className='input-group'>
                            <InputNumber value={privileges}
                                        name={'privileges'}
                                        type={'COMMON'}
                                        className={`form-control sm ${ errors?.privileges ? 'error-field' : '' }`}
                                        placeholder={'0'}
                                        params={params}
                                        onFocus={this.props.onElementFocus}
                                        onChange={this.props.onChange} />
                            <div className='input-group-append'>
                                <span className='input-group-text text-number '>
                                    /{numeral(stockRemain).format('0,0')}
                                </span>
                            </div>
                        </div>
                        <span className='error-message'> {errors?.privileges ? '* ข้อมูลไม่ถูกต้อง' : ''} </span>

                    </div>
                    {/* ANCHOR - buyLimit */}
                    <div style={{width:"10%", alignSelf: "center", padding:'0 0 0 5px'}}>
                        <div className='input-group '>
                            <InputNumber value={buyLimit}
                                        name={'buyLimit'}
                                        type={'COMMON'}
                                        className={`form-control ${ errors?.buyLimit ? 'error-field' : '' }`}
                                        placeholder={'0'}
                                        params={params}
                                        onFocus={this.props.onElementFocus}
                                        onChange={this.props.onChange} />
                        </div>
                        <span className='error-message'> {errors?.buyLimit ? '* ข้อมูลไม่ถูกต้อง' : ''} </span>
                    </div>
                    <div style={{width:"5%",  alignSelf: "center", padding:'0 0 0 5px'}}>
                        <img src={IconTrash} onClick={() => this.props.onDelete(params)} />
                    </div>
                </div>
            </div>
            }
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(ProductSkuItem);


  
