import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.scss";

import host from 'config/host';
import numeral from 'numeral';
import { displayDateTh,durationFormat } from 'helper/timeUtil';
import IconRerun from 'images/icons/rerun.svg'

const initialState = {};

class RerunItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => JSON.parse(JSON.stringify(initialState));

  render() {
    const { onVodStatusChange,
            onDeleteVod, 
            rerun,
            index } = this.props || {};
    const { status, 
            items, 
            title, 
            cover,
            duration, 
            releaseDate, 
            postId, 
            mediaId, 
            upstreamStatus,
            totalViews } = rerun || {};

    const products     = items;

    return <React.Fragment>
    <div className="item-content">
      <div className="row">
        {/* ANCHOR - product image preview */}
        <div className="col-3">
          <div className="item-preview">
            <img src={`${host.avatar}${cover?.path}`} />
            <div className="image-ribbin"> <img src={IconRerun} /> </div>
            <div className="time-duration">{durationFormat(duration)}</div>
          </div>
        </div>
        {/* ANCHOR - product detail */}
        <div className="col-9">
          <div className="item-detail">

            {/* ANCHOR - product header */}
            <div className="row">

              <div className="col-7 pl-3">
                <div className="row">
                  <div className="col-2 pl-0">
                    <div className='badge badge-gray mr-2'> รีรันจบแล้ว </div>
                  </div>
                  <div className="col-10">
                    <div className="d-flex ">
                      <h5>รีรันวันที่ {displayDateTh( releaseDate, 'D MMM YYYY HH:mm', )}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-5 text-right">
                  <h5>ยอดการรับชมทั้งหมด {numeral(totalViews).format('0,0')} ครั้ง</h5>
              </div>
            </div>

            {/* ANCHOR - product content */}
            <div className="row">
              <div className="col-12">
                <h4>{title}</h4>
              </div>
            </div>
            
            {/* ANCHOR - product images */}
            <div className="row">
              <div className="col-12">
                <h5>สินค้าที่ขาย {items?.length} รายการ</h5>
                <div className="product-list">
                  {products && products?.length > 0 ? products.map((item,index) => {
                      return (
                        <div className="product-image">
                          <img src={`${host.avatar}${item?.coverPath?.path}`} />
                        </div>
                      )
                    }
                  )
                   : (
                    <span>ยังไม่ได้ใส่สินค้า</span>
                  )}
                  
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(RerunItem);