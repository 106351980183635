import React from 'react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const ButtonAdd = (props) => {
  const { label, handleAdd } = props

  return (
    <div className='btn-add' onClick={handleAdd}>
      <FontAwesomeIcon icon={faPlusCircle} className='icon-plus' />
      <span>{label}</span>
    </div>
  )
}

ButtonAdd.defaulProps = {
  label: 'เพิ่ม',
  handleAdd: () => {},
}

export default ButtonAdd
