
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './index.scss';
import { postLiving, postLive, postFinish } from './mock'

import IconIncrease from 'images/icons/increse.svg'
import IconNowLive from 'images/icons/nextLive.svg'
import IconRerun from 'images/icons/rerun.svg'
import IconProgramLive from 'images/icons/programLive.svg'
import IconFinishLive from 'images/icons/finishLive.svg'

import { checkPermission } from 'helper/utils';

import LiveItem from 'components/LiveList/LiveItem';
import LiveGroupItem from 'components/LiveList/LiveGroupItem';
import DropDown from 'components/DropDownList';
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
import PaginationComponent from 'components/pagination';

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import { dispatchPost, 
         GET_POST_MEDIA_GROUP, 
         GET_POST_MEDIA_LIVE,
         HANDLE_DELETE_POST_LIVE } from 'redux/actions/post';

const _postFilterOption = [{label:"แสดงทั้งหมด", value:"ALL"},
                          {label:"Now LIVE", value:"NOW_LIVE"},
                          {label:"LIVE Program", value:"LIVE_PROGRAM"},
                          {label:"Finish LIVE", value:"FINISH_LIVE"}];

const initialState = {postFilterOption: _postFilterOption,
                      postFilterSelected: _postFilterOption[0],
                      postSelected: null,
                      pageSizeOptions:["10","20","40"]
                     };
class LiveList extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    this.getNowLiveList();
    this.getNextLiveList({page:1,limit:10});
    this.getFinishLiveList({page:1,limit:10});
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  /* SECTION - common function */
  getPostMediaes = ({type,upstreamStatus,page,limit,groupName,pageSize=10}) => 
                  this.props.dispatch( 
                  dispatchPost({ type: GET_POST_MEDIA_GROUP, 
                                payload: { shopId: this.props.currentShop.id,
                                             type, upstreamStatus, page, limit, groupName, pageSize} }) );

  getNowLiveList = () => this.props.dispatch({ type: GET_POST_MEDIA_LIVE, 
                                               payload: { groupName: 'nowLiveList', 
                                                          shopId: this.props.currentShop.id }} );
                                            
  getFinishLiveList = ({page=1,limit=10}) => this.getPostMediaes({groupName: 'finishLiveList',
                                               type:['live'],
                                               upstreamStatus: ['finish'],
                                               page,
                                               limit });

  getNextLiveList = ({page=1,limit=10}) => this.getPostMediaes({groupName: 'nextLiveList',
                                                 type:['live','rerun'],
                                                 upstreamStatus: ['wait'],
                                                 page,
                                                 limit });
 /* !SECTION - common function */


  /* SECTION - event function */
  onFilterSelected = filterItem => this.setState({postFilterSelected: filterItem}) 

  confirmDeletePost = (post,groupName) => 
    this.props.dispatch( dispatchPost({ type: HANDLE_DELETE_POST_LIVE, payload: { post, groupName } }), )
    
  

  onDeletePost = ({post,groupName}) => {
    this.props.dispatch(
      dispatchModalConfirm({
        type: OPEN_MODAL_CONFIRM,
        payload: {
          isOpen: true,
          message: `ต้องการลบ Live ${post.title}`,
          btnConfirm: 'ใช่',
          btnCancel: 'ไม่ใช่',
          confirm: () => this.confirmDeletePost(post,groupName),
          isClose: () => this.props.dispatch( dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false, }), ),
        },
      }),
    )
  }

  onChangePage = ({page, groupName}) => {
    const { limit=10 } = this.props[groupName] || {};
    if(groupName === 'finishLiveList') this.getFinishLiveList({page,limit})
    else if(groupName === 'nextLiveList') this.getNextLiveList({page,limit})
    else this.getNextLiveList({page,limit})
   }
 
   onShowSizeChange = ({page, limit, groupName}) => {
    if(groupName === 'finishLiveList') this.getFinishLiveList({page,limit})
    else if(groupName === 'nextLiveList') this.getNextLiveList({page,limit})
    else this.getNextLiveList({page,limit})
   }
  /* !SECTION - event function */

  render() {
    const { postFilterOption, postFilterSelected, postSelected } = this.state;
    // const posts = Array.from({ length: 12 });
    // const postsLiving = postLiving.posts || [];
    // const postsLive = postLive.posts || [];
    // const postsFinish = postFinish.posts || [];
    const { nowLiveList, nextLiveList, finishLiveList } = this.props || {};
    const postsAmount = (nowLiveList?.count + nextLiveList?.count + finishLiveList?.count) || 0;
    return (
      <React.Fragment>
        <div className="item-content">
          <div className="row">
            <div className="col-6">
                <h5 style={{display:"inline"}}>Live ทั้งหมด  </h5> {postsAmount} รายการ
            </div>
            <div className="col-6 text-right">
              <div className="d-flex justify-content-end">
                  {/* ANCHOR - filter list */}
                  <div className="col-6 pr-0">
                      <div className="d-flex justify-content-between align-center pr-3">
                          <label className="pr-2">แสดง</label>
                          <DropDown
                              onChange={this.onFilterSelected}
                              options={postFilterOption}
                              placeholder={'ทั้งหมด'}
                              value={postFilterSelected}
                              className={'live-filter'}
                          ></DropDown>
                      </div>
                  </div>
                  {/* ANCHOR - buttn create VOD */}
                  <div className="col-4 pl-0">
                      <Link to={`/manage/shop/shopdetail/createlive?mode=create`}>
                          {checkPermission('post','create','rw') ? <React.Fragment>
                              <img src={IconIncrease} alt="" />
                              <span className="btn-text-live">สร้าง โพสต์</span>
                          </React.Fragment> : null}
                      </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANCHOR - Now Live */}
        { ((postFilterSelected.value === 'ALL' || 
            postFilterSelected.value === 'NOW_LIVE') &&
            nowLiveList?.count > 0) &&
            <React.Fragment>
                <p className='mt-4'> <img src={IconNowLive} /> </p>
                <LiveGroupItem posts={nowLiveList}
                               onDeletePost={this.onDeletePost}
                               onChangePage={this.onChangePage}  
                               onShowSizeChange={this.onShowSizeChange}
                               enablePagination={false} 
                               groupName={'nowLiveList'} />
            </React.Fragment>
        }

         {/* ANCHOR - Next Live */}
         { ((postFilterSelected.value === 'ALL' || 
             postFilterSelected.value === 'LIVE_PROGRAM') &&
             nextLiveList?.count > 0) &&
            <React.Fragment>
                <p className='mt-4'> <img src={IconProgramLive} /> </p>
                <LiveGroupItem posts={nextLiveList}
                               onDeletePost={this.onDeletePost}
                               onChangePage={this.onChangePage}  
                               onShowSizeChange={this.onShowSizeChange}
                               enablePagination={true} 
                               groupName={'nextLiveList'} />
            </React.Fragment>
        }

        {/* ANCHOR - Finish Live */}
        { ((postFilterSelected.value === 'ALL' || 
            postFilterSelected.value === 'FINISH_LIVE') &&
            finishLiveList?.count > 0) &&
            <React.Fragment>
                <p className='mt-4'> <img src={IconFinishLive} /> </p>
                <LiveGroupItem posts={finishLiveList}
                               onDeletePost={this.onDeletePost}
                               onChangePage={this.onChangePage}  
                               onShowSizeChange={this.onShowSizeChange}
                               enablePagination={true}  
                               groupName={'finishLiveList'} />
            </React.Fragment>
        }

        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
    modalConfirm: state.modalConfirm.modalConfirm,
    postMediaes: state.post?.postMediaes,
    nowLiveList: state.post?.postMediaes?.nowLiveList,
    nextLiveList: state.post?.postMediaes?.nextLiveList,
    finishLiveList: state.post?.postMediaes?.finishLiveList,
    currentShop: state.shop?.currentShop
  })
  
  export default connect(mapStateToProps)(LiveList)