import React, {Component} from 'react';
import { connect } from 'react-redux'
import { OPEN_MODAL_UPLOAD_IMAGE, ADD_IMAGE_BASE64,
         dispatchModalUploadImage } from '../../redux/actions/modalUploadImage';

import ModalUploadImage from '../modalUploadImage';

const FileInputComponent = (props) => {

    const {onGetImage,onFileInputClick} = props;
    let fileInput = null;

    const onCloseModalUploadImage = () => {
        document.getElementsByClassName('productImage').value = "";
        props.dispatchModalUploadImage({ type: OPEN_MODAL_UPLOAD_IMAGE, payload: { isOpen: false } })
    }

    const onSelectImage = e => {
        if(e.target.files.length > 0) {
            const fileName = e.target.files[0].name;
            if (e.target.files && e.target.files.length > 0) {
                const reader = new FileReader();
                reader.onloadend = () => {
    
                    props.dispatchModalUploadImage({
                        type: ADD_IMAGE_BASE64, payload: {
                            imgBase64: reader.result,
                            fileName: fileName
                        }
                    })
    
                }
                reader.readAsDataURL(e.target.files[0]);
                props.dispatchModalUploadImage({ type: OPEN_MODAL_UPLOAD_IMAGE, payload: { isOpen: true, name: fileName } })
            }
        }
    }

    const onModalUploadImageGetImage = (fileImage) => {
        onGetImage(fileImage);
    }

    return <React.Fragment>
                <input type="file" 
                id="file-upload"
                className="productImage"
                onChange={onSelectImage} 
                onClick={onFileInputClick}
                ref={fileinput => fileInput = fileinput} 
                style={{ display: 'none' }} />

                <div className="input-btn-wrap" onClick={ () => fileInput.click()}>
                    {props.children}
                </div>
                <ModalUploadImage isClose={onCloseModalUploadImage} onChange={onModalUploadImageGetImage} />
            </React.Fragment>

};

export default connect(null, { dispatchModalUploadImage })(FileInputComponent);

