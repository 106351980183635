/* eslint-disable no-useless-constructor */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap'
import 'styles/chat/modal.scss'

const ModalCustomer = (props) => {
  const { showModalCreate, title, dataUser, closeModalCreate } = props

  const [data, setData] = useState({
    name: '',
    mobile: '',
  })

  const [error, setError] = useState({
    name: true,
    mobile: true,
  })

  const [createUserMobile, setCreateUserMobile] = useState(false)

  const onClickSubmit = () => {}

  return (
    <Modal isOpen={showModalCreate} className='modal-medium'>
      <div className='modal-header'>
        <h3>{title}</h3>
        <button className='btn-cloes btn' onClick={closeModalCreate}>
          <i className='icon-close' />
        </button>
      </div>
      <ModalBody>
        <FormGroup>
          <label>
            ชื่อ<span>*</span>
          </label>
          <Input
            type='text'
            name='name'
            autoComplete='nope'
            className={error.name ? 'valid' : 'invalid'}
            onChange={(e) => {
              setData({ ...data, name: e.target.value })
              setError({ ...error, name: true })
            }}
            defaultValue={dataUser ? dataUser.nickName : ''}
          />
        </FormGroup>

        <FormGroup>
          <label>
            เบอร์โทร<span>*</span>
          </label>
          <Input
            type='number'
            name='phone'
            autoComplete='nope'
            className={error.mobile ? 'valid' : 'invalid'}
            onChange={(e) => {
              setData({ ...data, mobile: e.target.value })
              setError({ ...error, mobile: true })
            }}
            defaultValue={dataUser ? dataUser.mobile : ''}
          />
        </FormGroup>
        {error.name && error.mobile ? null : (
          <small className='invalid-text'>กรุณากรอกข้อมูลให้ครบ</small>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-primary-sr btn-map'
          onClick={onClickSubmit}
          // disabled={this.state.createUserMobile}
        >
          {createUserMobile ? 'กำลังบันทึก...' : 'บันทึก'}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalCustomer
