export const SET_KEY_RIBBON = "SET_KEY_RIBBON"
export const SET_MODAL_CREATE_RIBBON = "SET_MODAL_CREATE_RIBBON"
export const SET_KEY_DATA_RIBBON = "SET_KEY_DATA_RIBBON"
export const SET_MODAL_CREATE_CATEGORIES = "SET_MODAL_CREATE_CATEGORIES"
export const HANDLE_CREATE_DECORATE_GROUP = "HANDLE_CREATE_DECORATE_GROUP"
export const GET_LIST_DECORATE_GROUP = "GET_LIST_DECORATE_GROUP"
export const HANDLE_CREATE_RIBBON = "HANDLE_CREATE_RIBBON"
export const GET_LIST_RIBBON = "GET_LIST_RIBBON"
export const SET_DATA_RIBBON_DEFAULT = "SET_DATA_RIBBON_DEFAULT"
export const SET_MODAL_ADD_PRODUCT_RIBBON = "SET_MODAL_ADD_PRODUCT_RIBBON"
export const GET_DATA_ADD_PRODUCT = "GET_DATA_ADD_PRODUCT"
export const ADD_PRODUCT_SELECT_RIBBON = "ADD_PRODUCT_SELECT_RIBBON"
export const HANDLE_CONFIRM_ADD_PRODUCT = "HANDLE_CONFIRM_ADD_PRODUCT"
export const HANDLE_CHANGE_SHOP_SELECT_FILTER = "HANDLE_CHANGE_SHOP_SELECT_FILTER"
export const HANDLE_GET_LIST_PRODUCT_DECORATE = "HANDLE_GET_LIST_PRODUCT_DECORATE"
export const SEARCH_PRODUCT_ADD_RIBBON = "SEARCH_PRODUCT_ADD_RIBBON"
export const HANDLE_SELECT_ALL_PRODUCT_DECORATE = "HANDLE_SELECT_ALL_PRODUCT_DECORATE"
export const HANDLE_RANDOM_PRODUCT_DECORATE = "HANDLE_RANDOM_PRODUCT_DECORATE"
export const SET_MODAL_MESSAGE_RIBBON = "SET_MODAL_MESSAGE_RIBBON"
export const SET_MODAL_CONFIRM_RIBBON = "SET_MODAL_CONFIRM_RIBBON"
export const HANDLE_SET_PERMISSION_DECORATE = "HANDLE_SET_PERMISSION_DECORATE"
export const HANDLE_DELETE_DECORATE = "HANDLE_DELETE_DECORATE"
export const HANDLE_UPDATE_STATUS_DECORATE_GROUP = "HANDLE_UPDATE_STATUS_DECORATE_GROUP"
export const HANDLE_GET_DECORATE_BY_ID = "HANDLE_GET_DECORATE_BY_ID" 
export const HANDLE_UPDATE_DECORATE_BY_ID = "HANDLE_UPDATE_DECORATE_BY_ID" 
export const HANDLE_DELETE_DECORATE_GROUP = "HANDLE_DELETE_DECORATE_GROUP"
export const SET_PAGINATION_RIBBON = "SET_PAGINATION_RIBBON"
export const SET_PAGINATION_PRODUCT = "SET_PAGINATION_PRODUCT"
export const HANDLE_CHANGE_PAGE_PRODUCT = "HANDLE_CHANGE_PAGE_PRODUCT"
export const HANDLE_CHANGE_PAGE_RIBBON = "HANDLE_CHANGE_PAGE_RIBBON"
export const HANDLE_GET_SUPER_RIBBON = "HANDLE_GET_SUPER_RIBBON"
export const GET_ALBUM_RIBBON = "GET_ALBUM_RIBBON"

export function dispatchRibbon(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
