export const GET_PAGE = 'GET_PAGE'
export const SET_PAGE = 'SET_PAGE'
export const GET_PLAYLIST_FRONT_PAGE = 'GET_PLAYLIST_FRONT_PAGE'
export const SET_PLAYLIST_FRONT_PAGE = 'SET_PLAYLIST_FRONT_PAGE'
export const CREATE_PLAYLIST_FRONT_PAGE = 'CREATE_PLAYLIST_FRONT_PAGE'
export const UPDATE_PLAYLIST_FRONT_PAGE = 'UPDATE_PLAYLIST_FRONT_PAGE'
// export const GET_PLAYLIST_FRONT_PAGE_ITEMS = 'GET_PLAYLIST_FRONT_PAGE_ITEMS'
// export const SET_PLAYLIST_FRONT_PAGE_ITEMS = 'SET_PLAYLIST_FRONT_PAGE_ITEMS'
// export const CREATE_PLAYLIST_FRONT_PAGE_ITEM = 'CREATE_PLAYLIST_FRONT_PAGE_ITEM'
// export const SHUFFLE_PLAYLIST_FRONT_PAGE_ITEMS = "SHUFFLE_PLAYLIST_FRONT_PAGE_ITEMS"
// export const PIN_PLAYLIST_FRONT_PAGE_ITEMS = "PIN_PLAYLIST_FRONT_PAGE_ITEMS"
// export const DELETE_PLAYLIST_FRONT_PAGE = 'DELETE_PLAYLIST_FRONT_PAGE'
// export const DELETE_PLAYLIST_FRONT_PAGE_ITEM = 'DELETE_PLAYLIST_FRONT_PAGE_ITEM'

export function dispatchPlaylistFrontPage(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
