import {
  SET_POST_BY_SHOP_ID,
  SET_POST,
  SET_GENERATE_KEY,
  TOGGLE_OPEN_LIVE_SETTING_MODAL,
  TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL,
  SET_MODAL_RERUN_LIVE,
  SET_MODAL_LIVE_FINISH_CONFIRM,
  SET_POST_MEDIAS,
  SET_POST_MEDIA,
  SET_POST_VOD_BY_ID

} from "../actions/post";

const initialState = {
  postsByShopId: [],
  post: null,
  postMediae: {},
  postMediaes: {},
  generateKey: null,
  modalLiveSetting: {
    isOpen: false,
  },
  modalLiveSettingConfirm: {
    isOpen: false,
    title: "",
    type: "inform",
    btnText: "",
  },
  modalRerunLive: {
    isOpen: false,
    currentLive: null,
    mode: ''
  },
  modalLiveFinishConfirm: {
    isOpen: false
  },
  postVod: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case SET_MODAL_LIVE_FINISH_CONFIRM : 
          const {isOpen} = payload;
          return { ...state, modalLiveFinishConfirm : {...state.modalLiveFinishConfirm, isOpen}}

    case SET_POST_MEDIAS : 
          const {postMediaes, groupName} = payload;
          return { ...state, postMediaes: {...state.postMediaes, [groupName]:postMediaes}}
    
    case SET_POST_MEDIA : 
          const {postMediae} = payload;
          return { ...state, postMediae }

    case SET_POST_VOD_BY_ID : 
          const {postVod} = payload;
          return { ...state, postVod}
    
    case SET_MODAL_RERUN_LIVE: {
      return { ...state,  modalRerunLive: { ...state.modalRerunLive, [payload.key]: payload.value }}
    }

    case SET_POST_BY_SHOP_ID:
      const { postsByShopId } = payload;
      return {
        ...state,
        postsByShopId,
      };

    case SET_POST:
      const { post } = payload;
      return {
        ...state,
        post,
      };

    case SET_GENERATE_KEY:
      const { generateKey } = payload;
      return {
        ...state,
        generateKey,
      };

    case TOGGLE_OPEN_LIVE_SETTING_MODAL:
      return {
        ...state,
        modalLiveSetting: { isOpen: payload.isOpen },
      };

    case TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL:
      const title   = payload.title || "";
      const type    = payload.type || "inform";
      const btnText = payload.btnText || "ตกลง";
      
      return {
        ...state,
        modalLiveSettingConfirm: { isOpen: payload.isOpen, title, type, btnText},
      };

    default:
      return state;
  }
}
