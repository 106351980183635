import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd';

const InputBox = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
`

const InputSelect = () => {
    return (
        <Select
        showSearch
        showArrow={false}
        style={{ width: '30em' }}
        placeholder="ค้นหาสินค้า"
        optionFilterProp="children"
        onChange={this.onProductFilterChange}
        onClick={this.onClickSelect} 
        onFocus={this.onFocus}
        onBlur={this.onLeaveSelect}
        onSearch={this.onSearch}
        id="productSearch" 
        filterOption={(input, option) => option.value.indexOf(input) >= 0}
    />
    )
}

InputSelect.defaultProps = {
    // onProductFilterChange: () => {}
}

export default InputSelect
