import { SET_PLAYLISTS, SET_PLAYLISTS_ITEMS } from '../actions/playlists'

const initialState = {
  playlists: null,
  playlistsItems: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {

    case SET_PLAYLISTS: {
      return {
        ...state,
        playlists: payload,
      }
    }

    case SET_PLAYLISTS_ITEMS: {
      return {
        ...state,
        playlistsItems: payload,
      }
    }

    default:
      return state
  }
}
