import React, { Component } from "react";
import { connect } from "react-redux";

import moment from 'moment';

import DatePickerInput from 'components/DatePickerInput';
import TimePicker from 'components/Timepicker';

const initialState = {};

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  onChange = (key,value) => this.props.onChange(key,value)

  render() {

    const { date, time } = this.props;
            
    return <React.Fragment>
         {/* ANCHOR [date time] */}
         <div className='date-container'>
            <div className='row'>
              <div className='col-6'>
                <div> วันที่ <span style={{ color: 'red' }}>*</span> </div>
                <DatePickerInput
                  date={date}
                  onSubmitDateSelected={value => this.onChange('date',value)}
                />
              </div>
              <div className='col-6'>
                <div> เวลา <span style={{ color: 'red' }}>*</span> </div>
                <TimePicker
                  onTimePickerChange={value => this.onChange('time',value)}
                  value={time}
                />
              </div>
            </div>
        </div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(DateTimePicker);


  
