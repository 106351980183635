import { all } from 'redux-saga/effects'
import user from './user'
import shop from './shop'
import product from './product'
import post from './post'
import logistic from './logistic'
import order from './order'
import socket from './socket'
import gallery from './gallery'
import playlists from './playlists'
import playlistFrontPage from './playlistFrontPage'
import live from './live'
import withdraw from './withdraw'
import promocode from './promocode'
import logisticProvider from './logisticPrice'
import ribbon from './ribbon'

export default function* rootSaga() {
  yield all([
    user(),
    shop(),
    product(),
    post(),
    logistic(),
    order(),
    socket(),
    gallery(),
    playlists(),
    playlistFrontPage(),
    live(),
    withdraw(),
    promocode(),
    logisticProvider(),
    ribbon()
  ])
}
