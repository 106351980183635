import React, { Component } from "react";
import { connect } from "react-redux";
import PaginationComponent from 'components/pagination';
import LiveItem from 'components/LiveList/LiveItem';

const initialState = {pageSizeOptions:["10","20","40"]};
class LiveGroupItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  render() {
    const { posts=[], 
            count=0, 
            limit=1, 
            page=1 } = this.props?.posts || {};
    const { onDeletePost, 
            onChangePage,  
            onShowSizeChange, 
            groupName, 
            enablePagination} = this.props;
    const { pageSizeOptions } = this.state;
    return <React.Fragment>
              <div className="mt-2 mb-2">
                 { enablePagination === true && 
                  <PaginationComponent  defaultCurrent={1}
                                        total={count}
                                        onChange={(page) => onChangePage({page, groupName})}
                                        pageSize={limit}
                                        pageSizeOptions={pageSizeOptions}
                                        onShowSizeChange={(page, pageSize) => onShowSizeChange({page, pageSize, groupName})}
                                        current={page}
                                        locale={{ items_per_page: '/ หน้า' }}/>
                 }
              </div>
              { posts.map( (post,index) => 
                  <LiveItem   key={index} 
                              post={post}
                              index={index}
                              groupName={groupName}
                              onDeletePost={onDeletePost} />
              )}
          </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(LiveGroupItem);