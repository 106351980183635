import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss';
import host from 'config/host';
import classnames from 'classnames'

const LogoSelect = props => {
    const { value, className, onClick } = props
    return (
        <div className={classnames('logo-select',{'active' : className})} onClick={onClick}>
            <img src={host.banner + value} alt="" style={{    width: '100%', maxHeight: '95%' }}/>
            {/* style={{    width: '-webkit-fill-available',height: '-webkit-fill-available' }} */}
        </div>
    )
}

LogoSelect.propTypes = {

}

export default LogoSelect
