import React from 'react'
import iconupload from '../../../images/icons/iconupload.svg'
import './styles.scss'

const BrowseVideo = (props) => {
  const { VDO, onClickBrowseVideo } = props

  return (
    <div className='container-browse-video'>
      {VDO ? (
        <video controls id='video-preview'>
          <source src={VDO.preview} type='video/mp4' />
        </video>
      ) : (
        <>
          <img className='icon-upload' alt='' src={iconupload} />
          <div className='btn-browse-video-box'>
            <button className='btn-browse-video'>
              เลือกรูปภาพ/วิดีโอจากเครื่องของคุณ
            </button>
            <input
              type='file'
              onChange={onClickBrowseVideo}
              className='input-file'
              accept='video/mp4,video/x-m4v,video/*'
            />
          </div>
        </>
      )}
    </div>
  )
}

BrowseVideo.defaultProps = {
  onClickBrowseVideo: () => {},
}

export default BrowseVideo
