import React, { Component } from "react";
import { Input, AutoComplete, Select } from 'antd';
import "./index.scss";
const { Option } = Select;

const handleSelectChange = (name, value, onSelectChange) => {
    const e = {target:{value,name}};
    console.log(e);
    onSelectChange(e);
}

const SelectAndTextInput = (props) => {
  const { inputValue, onInputChange, inputName, inputType, inputPlaceholder, options } = props;
  const { selectValue, onSelectChange, selectName, disabled } = props;
  return (
    <div id="filter-search">
        <Input.Group compact>
            <div style={{display:'flex'}}>
                <Select defaultValue={selectValue} 
                        name={selectName}
                        width={'100%'} 
                        disabled={disabled}
                        onChange={(value) => handleSelectChange(selectName, value, onSelectChange)}>
                    { options.map((item,idx) => <Option value={item} key={`select-option${idx}`}>{item}</Option>)}
                </Select>
                <Input className='text-input-style' 
                       type={inputType}
                       disabled={disabled}
                       onChange={onInputChange}
                       placeholder={inputPlaceholder}
                       name={inputName}
                       value={inputValue}
                       style={{ width: '200px' , height: "42px"}} 
                       defaultValue={inputValue} />
            </div>
        </Input.Group>
    </div>
  );
};

export default SelectAndTextInput;
