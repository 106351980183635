import axios from 'axios';
import host from '../config/host'
import Cookies from 'js-cookie'
import newAxios from '../config/axiosRefreshToken'

const header = {
    headers: { 'secret-key': host.tokenSecretKey }
    // headers: { Authorization: "Bearer " + host.tokenSecretKey }
}

class userProvider {
  handleLogin = async (params) => {
    try {
      const data = {
        email: params.email,
        password: params.password,
      }

      const result = await axios.post(`${host.api}/auth/login`, data, header)
      return result
    } catch (error) {
      Cookies.remove('user')
      Cookies.remove('token')
      Cookies.remove('refreshToken')
      throw error
    }
  }

  authenticateUser = async (params) => {
    if (params.accessToken == undefined) return false
    const data = {
      access_token: params.accessToken,
    }
    try {
      const result = await axios.post(`${host.api}/auth/authenticate`, data)
      return result
    } catch (error) {}
  }

  refreshToken = async (params) => {
    // let data = params.config
    try {
      //
      const result = await newAxios.post(
        `${host.api}/auth/refresh-token`,
        { refreshToken: params.refreshToken },
        header,
      )
      if (result) {
        Cookies.set('token', result.data.accessToken)
        Cookies.set('refreshToken', result.data.refreshToken)
      }
      return result.data
      // data.headers.Authorization = "Bearer " + result.data.accessToken
      // let newAxios = axios.create(data)
      //
      // //
      // const res = await newAxios(params.url,params.data)
      //
    } catch (error) {
      // window.location.replace('/login')
      throw error
    }
  }

  createUserShop = async (body) => {
    try {
      const url = `${host.api}/auth/register`
      const res = await axios.post(url, body, header)

      return res.data
    } catch (error) {
      return error.response
    }
  }

  getUser = async (params) => {
    try {
      const data = {
        email: params.email,
        password: params.password,
      }

      const res = await axios.post(`${host.api}/auth/login`, data, header)
      return res
    } catch (error) {
      throw error
    }
  }

}

export default new userProvider()
