export const ADD_KEY_VALUE_PRODUCT = "ADD_KEY_VALUE_PRODUCT"
export const ADD_IMAGE_PATH = "ADD_IMAGE_PATH"
export const ADD_VALUE_IN_PRODUCT = "ADD_VALUE_IN_PRODUCT"
export const ADD_SEO_IN_PRODUCT = "ADD_SEO_IN_PRODUCT"
export const ADD_KEYWORD_IN_SEO = "ADD_KEYWORD_IN_SEO"
export const REMOVE_KEYWORD_IN_SEO = "REMOVE_KEYWORD_IN_SEO"
export const GET_LIST_PRODUCT = "GET_LIST_PRODUCT"
export const GET_LIST_ALL_PRODUCT = "GET_LIST_ALL_PRODUCT"
export const ON_CREATE_PRODUCT = "ON_CREATE_PRODUCT"
export const HANDLE_CRAETE_PRODUCT = "HANDLE_CRAETE_PRODUCT"
export const CREATE_PRODUCT_SKU = "CREATE_PRODUCT_SKU"
export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES"
export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES"
export const SELECT_PRODUCT_CATEGORIES = "SELECT_PRODUCT_CATEGORIES"
export const ADD_IMAGE_SEO = "ADD_IMAGE_SEO"
export const REMOVE_IMAGE_PRODUCT = "REMOVE_IMAGE_PRODUCT"
export const REMOVE_SEO_IMAGE = "REMOVE_SEO_IMAGE"
export const SET_SEO_LENGTH = "SET_SEO_LENGTH"
export const ON_GET_BRANDS = "ON_GET_BRANDS"
export const GET_PRODUCTS_BY_SHOP = "GET_PRODUCTS_BY_SHOP"
export const SET_PRODUCTS_BY_SHOP = "SET_PRODUCTS_BY_SHOP"
export const GET_SEARCH_PRODUCTS_BY_SHOP = "GET_SEARCH_PRODUCTS_BY_SHOP"
export const SET_SEARCH_PRODUCTS_BY_SHOP = "SET_SEARCH_PRODUCTS_BY_SHOP"
export const CREATE_PRODUCT_LOGISTIC = "CREATE_PRODUCT_LOGISTIC"
export const OPEN_MODAL_ADD_PRODUCT = "OPEN_MODAL_ADD_PRODUCT"
export const REMOVE_ALL_IMAGE = "REMOVE_ALL_IMAGE"
export const HANDLE_SEARCH_PRODUCT = "HANDLE_SEARCH_PRODUCT"
export const HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST = "HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST"
export const HANDLE_SEARCH_SHOP_PRODUCT = "HANDLE_SEARCH_SHOP_PRODUCT"
export const GET_ALL_PRODUCTS_BY_SHOP = "GET_ALL_PRODUCTS_BY_SHOP"
export const GET_PRODUCT_ATTRIBUTE = "GET_PRODUCT_ATTRIBUTE"
export const GET_PRODUCT = "GET_PRODUCT"
export const UPDATE_PRODUCT_LOGISTICS = "UPDATE_PRODUCT_LOGISTICS"
export const UPDATE_PRODUCT_BY_ID = "UPDATE_PRODUCT_BY_ID"
export const UPDATE_PRODUCT_SKU = "UPDATE_PRODUCT_SKU"
export const GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID = "GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID"
export const GET_DATA_PRODUCT_PAGE = "GET_DATA_PRODUCT_PAGE"
export const VALIDATE_STOCK_REMAIN = "VALIDATE_STOCK_REMAIN"
export const HANDLE_DELETE_PRODUCT = "HANDLE_DELETE_PRODUCT"
export const GET_LIST_LOGISTICS_BY_SHOP = "GET_LIST_LOGISTICS_BY_SHOP"
export const GET_LIST_PROVIDER_SHOP_LOGISTIC = "GET_LIST_PROVIDER_SHOP_LOGISTIC"
export const SET_KEY_PRODUCT = "SET_KEY_PRODUCT"
export const GET_INITIAL_EDIT_VOD = "GET_INITIAL_EDIT_VOD"
export const OPEN_MODAL_LIMIT_PURCHASE = "OPEN_MODAL_LIMIT_PURCHASE"


export function dispatchProduct(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}
