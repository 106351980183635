import React from 'react'
import numeral from 'numeral'
import host from 'config/host'
import bgProduct from 'images/bg-product-card.png'
import 'styles/chat/productCard.scss'

const ProductCard = ({ product, hasCloseCard }) => {
  return (
    <div className='product-card-chat-box'>
      <div className='bg-image-product-chat'>
        <img alt='' className='bg-product' src={bgProduct} />
      </div>
      <div className='content-product-card-chat flex-row'>
        <div className='img-box-product-card-chat'>
          <img
            alt=''
            className='img-product-card'
            src={host.img + product?.imagePath[0].path}
          />
        </div>

        <div className='product-price-item-chat'>
          <label className='product-name-chat'>{product?.name}</label>
          {product?.cheapPriceList &&
          product?.cheapPriceList != product?.cheapPrice ? (
            <div className='flex-row'>
              <label className='product-price-old-chat'>฿&nbsp;</label>
              {product?.cheapPriceList != null &&
              product?.cheapPriceList != product?.expensivePriceList ? (
                <label className='product-price-old-chat '>
                  {numeral(product?.cheapPriceList).format('0,0')} -&nbsp;
                </label>
              ) : null}
              <label className='product-price-old-chat'>
                {numeral(product?.expensivePriceList).format('0,0')}
              </label>
            </div>
          ) : null}
          <div className='footer-card-chat'>
            <div className='flex-row'>
              <label className='product-price-chat'>
                ฿ {numeral(product?.cheapPrice).format('0,0')}
              </label>
              {product?.expensivePrice ? (
                <label className='product-price-chat'>
                  - {numeral(product?.expensivePrice).format('0,0')}
                </label>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
