import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import host from 'config/host'
import {
  ButtonAdd,
  ModalLarge,
  InputSearch,
  ButtonIcon,
  ModalLoading,
} from 'components/output'
import Pagination from 'components/pagination'
import DropdownButton from "components/DropdownButton"
import {
  dispatchPlaylists,
  GET_PLAYLISTS_ITEMS,
  // CREATE_PLAYLISTS,
  CREATE_PLAYLISTS_ITEM,
  DELETE_PLAYLISTS_ITEM,
  SHUFFLE_PLAYLISTS_ITEMS,
  PIN_PLAYLISTS_ITEMS,
  UPDATE_ORDER_PLAYLISTS_ITEMS,
} from 'redux/actions/playlists'
import {
  dispatchProduct,
  GET_LIST_PRODUCT,
  HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
  ADD_KEY_VALUE_PRODUCT,
  GET_DATA_PRODUCT_PAGE,
} from '../redux/actions/product'
import noItem from '../images/icons/status-error.svg'
import IconTrash from '../images/icons/trash.svg'
import '../styles/_playlists.scss'

import numeral from 'numeral';
import DropDownPlaylist from 'components/DropdownPlaylist'

const PlaylistsItems = (props) => {
  const {
    dispatch,
    location: {
      state: { playlistsId, playlistsName },
    },
    playlistsItems,
    product,
  } = props

  const [activeModal, setActiveModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [statusSearch, setStatusSearch] = useState(false)
  const [historySearch, setHistorySearch] = useState()

  useEffect(() => {
    dispatch(
      dispatchPlaylists({
        type: GET_PLAYLISTS_ITEMS,
        payload: { playlistsId, limit: 1000, page: 1 },
      }),
    )
  }, [])

  useEffect(() => {
    if (activeModal) {
      // dispatch(dispatchProduct({ type: GET_LIST_PRODUCT }))
      dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
          payload: { playlistsId, search: '' },
        }),
      )
    }
  }, [activeModal])

  const onChangePage = (page) => {
    if (statusSearch) {
      dispatch(
        dispatchProduct({
          type: ADD_KEY_VALUE_PRODUCT,
          payload: { key: 'page', value: page },
        }),
      )
      dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
          payload: { playlistsId, search: historySearch },
        }),
      )
    } else {
      dispatch(
        dispatchProduct({
          type: ADD_KEY_VALUE_PRODUCT,
          payload: { key: 'page', value: page },
        }),
      )
      // dispatch(dispatchProduct({ type: GET_LIST_PRODUCT }))
      dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
          payload: { playlistsId, search: '' },
        }),
      )
    }
  }

  const toggleModal = (data) => {
    setActiveModal(data)
  }

  const onChangeSearch = (e) => {
    setSearch(e.target.value)
  }

  const onSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      dispatch(
        dispatchProduct({
          type: ADD_KEY_VALUE_PRODUCT,
          payload: { key: 'page', value: 1 },
        }),
      )
      setStatusSearch(true)
      setHistorySearch(search)

      dispatch(
        dispatchProduct({
          type: HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
          payload: { playlistsId, search: search },
        }),
      )
    }
  }

  const onClickSeackProduct = () => {
    setStatusSearch(true)
    setHistorySearch(search)

    dispatch(
      dispatchProduct({
        type: HANDLE_SEARCH_PRODUCT_FILTER_PLAYLIST,
        payload: { playlistsId, search: search },
      }),
    )
  }

  const onClearSearch = () => {
    dispatch(
      dispatchProduct({
        type: ADD_KEY_VALUE_PRODUCT,
        payload: { key: 'page', value: 1 },
      }),
    )
    setSearch('')
    dispatch(dispatchProduct({ type: GET_DATA_PRODUCT_PAGE }))
  }

  const onShowSizeChange = (current, pageSize) => {
    dispatch(
      dispatchProduct({
        type: ADD_KEY_VALUE_PRODUCT,
        payload: { key: 'limit', value: pageSize },
      }),
    )
    dispatch(
      dispatchProduct({
        type: ADD_KEY_VALUE_PRODUCT,
        payload: { key: 'page', value: current },
      }),
    )
  }

  const deleteItem = (data) => {
    dispatch(
      dispatchPlaylists({
        type: DELETE_PLAYLISTS_ITEM,
        payload: { playlistsId, playlistsItemId: data.id },
      }),
    )
  }

  const shufflePlaylistItems = () => {
    dispatch(
      dispatchPlaylists({
        type: SHUFFLE_PLAYLISTS_ITEMS,
        payload: { playlistsId },
      }),
    )
  }

  const handleChangePosition = (playlistItem, order) => {
    dispatch(
      dispatchPlaylists({
        type: UPDATE_ORDER_PLAYLISTS_ITEMS,
        payload: {
          order: order,
          playlistItemId: playlistItem.id,
          playlistsId: playlistItem.playlistId
        },
      }),
    )

    setIsLoading(true)
  }

  return (
    <div className='playlists'>
      <div className='playlists-content'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='m-0'>{playlistsName}</h3>
          <div className='d-flex'>
            <ButtonIcon label='สุ่ม' onClickButton={shufflePlaylistItems} />
            <ButtonAdd
              label='เพิ่มสินค้า'
              handleAdd={() => toggleModal(true)}
            />
          </div>
        </div>
        <Table data={playlistsItems} deleteItem={deleteItem} dispatch={dispatch} handleChangePosition={handleChangePosition} isLoading={isLoading} setIsLoading={setIsLoading}/>
        <ModalLarge
          title='เพิ่มสินค้า'
          closeModal={() => toggleModal(false)}
          isOpenModal={activeModal}
          disabled={false}
          isShowFooter={false}
        >
          <div className='playlists'>
            <div className='playlists-content'>
              <div className='content-header'>
                <div style={{ width: '50%' }}>
                  <InputSearch
                    placeholder='ค้นหา'
                    onChange={onChangeSearch}
                    onKeyPress={onSearchKeyPress}
                    handleSearch={onClickSeackProduct}
                    onClearSearch={onClearSearch}
                  />
                </div>
              </div>
              <TableProducts
                data={product.listAllProduct}
                page={product.page}
                limit={product.limit}
                playlistsId={playlistsId}
                dispatch={dispatch}
                toggleModal={toggleModal}
              />
              <div className='float-right position-relative mt-2'>
                <Pagination
                  total={product.total}
                  defaultCurrent={1}
                  onChange={onChangePage}
                  pageSize={product.limit}
                  pageSizeOptions={product.pageSizeOptions}
                  onShowSizeChange={onShowSizeChange}
                  current={product.page}
                  showQuickJumper={true}
                />
              </div>
            </div>
          </div>
        </ModalLarge>
      </div>
    </div>
  )
}

const Table = (props) => {
  const { data, deleteItem, dispatch, handleChangePosition, isLoading, setIsLoading } = props


  const optionPos = [...Array(data?.length).keys()].map(pos => data ? ({ value: data[pos]?.order, name: `${pos+1}` }) : null )

  useEffect(() => {
    setIsLoading(false)
  },[data])

  const onPinItem = (item) => {
    let pinDate = null
    if(!item.pinDate){
      pinDate = Date.now()
    }
    
    setIsLoading(true)

    dispatch(
      dispatchPlaylists({
        type: PIN_PLAYLISTS_ITEMS,
        payload: { playlistsItemId: item.id, pinDate: pinDate, playlistsId: item.playlistId },
      })
    )

  }

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className='table-header'>
        <div className='row'>
          <div className='col-1'>ลำดับ</div>
          <div className='col-6'>ชื่อสินค้า</div>
          <div className='col-2 text-right pr-5'>ราคาขาย</div>
          <div className='col-1 text-right pr-5'>คลัง</div>
          {/* <div className='col-1'>ปักหมุด</div> */}
        </div>
      </div>
      <div className='table-body'>
        {data &&
          data.map((item, index) => (
            <div className='row' key={index}>
              <div className='col-1'>
                <DropDownPlaylist 
                  options={optionPos} 
                  defaultValue={optionPos[index].value || ""}
                  handleSelected={(orderNumber) => handleChangePosition(item, orderNumber)}
                />
              </div>
              <div className='col-6'>
                <div className='d-flex flex-row product-name align-items-start'>
                  <img
                    src={host.avatar + item.product?.coverPath?.path}
                    alt='stock'
                    style={{ width: '5em', marginRight: '1em' }}
                  />
                  <div className='d-flex flex-column justify-content-start align-items-start'>
                    <span className='font-weight-bold'>
                      {item.product.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-2 text-right pr-5'>฿ {numeral(item.product.priceNet).format('0,0.00')}</div>
              <div className='col-1 text-right pr-5'>{numeral(item.product.remains).format('0,0')}</div>
              {/* <div className='col-1'>
                <div className='toggle-green d-flex align-items-center' style={{ gap: '10px' }} >
                  <label className='switch'>
                    <input
                      type='checkbox'
                      name='enableProductSkuOption'
                      checked={item.pinDate ? true : false}
                      onChange={() => onPinItem(item)}
                    />
                    <span className='slider round'></span>
                  </label>
                </div>
              </div> */}
              <div className='col-1'>
                <span>
                  <img
                    src={IconTrash}
                    alt='iconTrash'
                    className='icon-link'
                    onClick={() => deleteItem(item)}
                  />
                </span>
              </div>
            </div>
          ))}
      </div>
      {isLoading && <ModalLoading isOpenModal={isLoading} />}
    </div>
  )
}

const TableProducts = (props) => {
  const {
    data,
    deleteItem,
    page,
    limit,
    playlistsId,
    dispatch,
    toggleModal,
  } = props

  const onClickAddProduct = (item) => {
    dispatch(
      dispatchPlaylists({
        type: CREATE_PLAYLISTS_ITEM,
        payload: {
          productId: item.id,
          playlistId: playlistsId,
        },
      }),
    )
    toggleModal(false)
  }

  return data && data.length === 0 ? (
    <div className='d-flex flex-column align-items-center justify-content-center mt-4 h-50vh'>
      <img src={noItem} alt='noItem' />
      <span className='mt-2 font-secondary'>ไม่มีรายการ</span>
    </div>
  ) : (
    <div className='playlists-list'>
      <div className='table-header'>
        <div className='row'>
          <div className='col-1'>ลำดับ</div>
          <div className='col-7'>ชื่อสินค้า</div>
          <div className='col-1'>ราคาขาย</div>
          <div className='col-1'>คลัง</div>
          <div className='col-2'></div>
        </div>
      </div>
      <div className='table-body'>
        {data &&
          data.map((item, index) => (
            <div className='row' key={index}>
              <div className='col-1'>{(page - 1) * limit + index + 1}</div>
              <div className='col-7'>
                <div className='d-flex flex-row product-name align-items-start'>
                  <img
                    src={host.avatar + item?.coverPath?.path}
                    alt='stock'
                    style={{ width: '5em', marginRight: '1em' }}
                  />
                  <div className='d-flex flex-column justify-content-start align-items-start'>
                    <span className='font-weight-bold'>{item.name}</span>
                  </div>
                </div>
              </div>
              <div className='col-1'>{item.priceNet}</div>
              <div className='col-1'>{item.remains}</div>
              <div className='col-2'>
                <ButtonAdd
                  label='เพิ่มสินค้า'
                  handleAdd={() => onClickAddProduct(item)}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  playlistsItems: state.playlists.playlistsItems,
  product: state.product,
})

export default connect(mapStateToProps, null)(PlaylistsItems)
