import promocodeProvider from "provider/promocodeProvider";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SET_LOADING } from "redux/actions/app";
import { OPEN_MODAL_MESSAGE_ERROR, OPEN_MODAL_MESSAGE_SUCCESS } from "redux/actions/modalMessage";
import { CREATE_PROMOCODE, GET_LIST_PROMOCODE, OPEN_MODAL_ADD_PROMOCODE, OPEN_MODAL_MESSAGE_PROMOCODE, SET_KEY_VALUE_PROMOCODE, VERIFY_REF_CODE, HANDLE_UPDATE_PROMOCODE, HANDLE_DELETE_PROMOCODE, OPEN_MODAL_CONFIRM_PROMOCODE, GET_DETAIL_UPDATE_PROMOCODE } from "redux/actions/promocode";
import { getPromocode, getShop } from "./selectors";
import moment from 'moment'

export function* onGetListPromocode(action) {
    try {
        const getPromocodeState = yield select(getPromocode)
        const res = yield call(promocodeProvider.getListPromocode, { filter: getPromocodeState.kw, status: getPromocodeState.activeTab, page: getPromocodeState.page, limit: getPromocodeState.limit })
        if (res) {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'promocode', value: res.data } })
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'total', value: res.data.count } })

        }
    } catch (error) {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}

export function* handleVerifyRefCode(action) {
    const { payload } = action

    try {
        const res = yield call(promocodeProvider.verifyRefCode, { code: payload.promotionCode })
        let getPromocodeState = yield select(getPromocode)
        if (res.data.id) {
            // if (getPromocodeState.managePromocode === "create") {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'verifyCode', value: res.data } })
            yield put({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: true, data: res.data } })
            // }
            getPromocodeState = yield select(getPromocode)

        } else if (res.status === 422 || res.status === 500) {
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว  ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้' } })
        }
    } catch (error) {
        console.log('error', error)
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
}

export function* handleCreatePromocode(action) {
    const { payload } = action
    try {
        const getPromocodeState = yield select(getPromocode)
        const getShopState = yield select(getShop)
        const { verifyCode } = getPromocodeState
        let data = {
            campaignId: verifyCode.id,
            // shopId: getShopState.currentShop.id,
            name: verifyCode.name,
            mediaCover: `image/${verifyCode?.mediaCover?.path}`,
            description: verifyCode.description,
            stopDate: moment(payload.coupon.dateEnd + ' ' + moment(payload.coupon.timeEnd,"HH:mm").format("HH:mm"),"DD/MM/YYYY HH:mm"),
            startDate: moment(payload.coupon.dateStart + ' ' + moment(payload.coupon.timeStart,"HH:mm").format("HH:mm"),"DD/MM/YYYY HH:mm"),
            couponType: payload.coupon.pattern,
            couponRole: payload.coupon.License,
            couponAction: payload.coupon.type,
            refCode1: verifyCode.productCode,
            costSharingPlatform: payload.coupon.companySharing,
            costSharingPartner: payload.coupon.shopSharing,
            status: payload.coupon.usageStatus ? 'show' : 'hide',
            limitUser: {
                value: parseInt(payload.coupon.promotionCodePerUserOption.promotionCodeValue),
                unit: payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อวัน" ? 'day' :
                    payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อสัปดาห์" ? 'week' :
                        payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อเดือน" ? 'month' :
                            payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อโปรโมชั่น" ? 'promotion' : null
            }
        }

        payload.coupon?.promotionCodePerUser === "unlimit" && delete data.limitUser


        const res = yield call(promocodeProvider.addPromocode, data)
        if(res.status === 422 || res.status === 500){
            yield put({ type: OPEN_MODAL_MESSAGE_ERROR,payload: { message:'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title:'iconFail',} })
        } else {
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        }

    } catch (error) {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
}

export function* getDetailUpdatePromocode(action) {
    const { payload } = action
    yield put({type: SET_LOADING,payload:{loading : true}})
    try {
        yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'edit' } })
        const getDetail = yield call(promocodeProvider.getDetailCoupon, payload)
        const verifyCode = yield call(promocodeProvider.verifyRefCode, { code: getDetail.data.productCode })
        if(verifyCode.status === 422 || verifyCode.status === 500){
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ตรวจสอบรหัสอ้างอิงเรียบร้อยแล้ว ไม่สามารถใช้งานรหัสอ้างอิงนี้ได้', title: 'iconFail' } })
            // yield put({type: OPEN_MODAL_MESSAGE_ERROR})
        } else {
            yield put({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'verifyCode', value: getDetail.data } })
            yield put({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: true, data: getDetail.data } })
        }
        yield put({type: SET_LOADING,payload:{loading : false}})
    } catch (error) {
        yield put({type: SET_LOADING,payload:{loading : false}})
        console.log('error', error)
    }
}

export function* handleUpdatePromocode(action) {
    try {
        const { payload } = action
        const getPromocodeState = yield select(getPromocode)
        const getShopState = yield select(getShop)
        const { verifyCode } = getPromocodeState

        let data = {
            // shopId: getShopState.currentShop.id,
            name: verifyCode.name,
            mediaCover: `image/${verifyCode?.mediaCover?.path}`,
            description: verifyCode.description,
            stopDate:  moment(payload.coupon.dateEnd + ' ' + moment(payload.coupon.timeEnd,"HH:mm").format("HH:mm"),"DD/MM/YYYY HH:mm"),
            startDate: moment(payload.coupon.dateStart + ' ' + moment(payload.coupon.timeStart,"HH:mm").format("HH:mm"),"DD/MM/YYYY HH:mm"),
            couponType: payload.coupon.pattern,
            couponRole: payload.coupon.License,
            couponAction: payload.coupon.type,
            costSharingPlatform: payload.coupon.companySharing,
            costSharingPartner: payload.coupon.shopSharing,
            status: payload.coupon.usageStatus ? 'show' : 'hide',
            limitUser: {
                value: parseInt(payload.coupon.promotionCodePerUserOption.promotionCodeValue),
                unit: payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อวัน" ? 'day' :
                    payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อสัปดาห์" ? 'week' :
                        payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อเดือน" ? 'month' :
                            payload.coupon.promotionCodePerUserOption.promotionCodeOption === "ต่อโปรโมชั่น" ? 'promotion' : null
            },
        }
        if (payload.coupon?.promotionCodePerUser === "unlimit") {
            data.limitUser = null
        }
        const res = yield call(promocodeProvider.updatePromocode, { body: data, id: payload.id })
        if (res.data) {
            yield put({ type: OPEN_MODAL_MESSAGE_PROMOCODE, payload: { isOpen: true, message: 'ทำรายการสำเร็จ', title: 'iconSuccess' } })
        }
    } catch (error) {
        console.log('error', error)
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }

}



export function* handleDeletePromocode(action) {
    const { payload } = action
    const res = yield call(promocodeProvider.deletePromocode, payload)
    if (res.data.status === "success") {
        yield put({ type: OPEN_MODAL_CONFIRM_PROMOCODE, payload: { isOpen: false } })
        yield onGetListPromocode()
    }
}


export default function* useWatcher() {
    yield takeLatest(GET_LIST_PROMOCODE, onGetListPromocode)
    yield takeLatest(VERIFY_REF_CODE, handleVerifyRefCode)
    yield takeLatest(CREATE_PROMOCODE, handleCreatePromocode)
    yield takeLatest(GET_DETAIL_UPDATE_PROMOCODE, getDetailUpdatePromocode)
    yield takeLatest(HANDLE_DELETE_PROMOCODE, handleDeletePromocode)
    yield takeLatest(HANDLE_UPDATE_PROMOCODE, handleUpdatePromocode)
}