import React, { Component } from 'react'
import '../styles/_dashboard.scss'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames'
import D2 from '../images/dashboard/by_day/d2.png'
import D3 from '../images/dashboard/by_day/d3.png'
import D4 from '../images/dashboard/by_day/d4.png'
import W2 from '../images/dashboard/by_week/w2.png'
import W3 from '../images/dashboard/by_week/w3.png'
import W4 from '../images/dashboard/by_week/w4.png'
import W5 from '../images/dashboard/by_week/w5.png'
import Y2 from '../images/dashboard/by_year/y2.png'
import Y3 from '../images/dashboard/by_year/y3.png'
import Y4 from '../images/dashboard/by_year/y4.png'
import Y5 from '../images/dashboard/by_year/y5.png'
import DropDown from '../components/DropDownList'

const Date = [{label: '25 ตุลาคม 2563 - 31 ตุลาคม 2563',value: '25 ตุลาคม 2563 - 31 ตุลาคม 2563',label:'25 ตุลาคม 2563 - 31 ตุลาคม 2563',value:'25 ตุลาคม 2563 - 31 ตุลาคม 2563'}]
const month = [{label: 'พฤศจิกายน 2563',value: 'พฤศจิกายน 2563'}]


class Dashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            activeTab: 'today',
            dateSelected: {
                label: '25 ตุลาคม 2563 - 31 ตุลาคม 2563',
                value: '25 ตุลาคม 2563 - 31 ตุลาคม 2563'
            },
            monthSelected: {
                label: 'ตุลาคม 2563',
                value: 'ตุลาคม 2563'
            }

        }
    }

    toggle = (tab) => {
        this.setState({ activeTab: tab })
    }


    render() {
        const {activeTab} = this.state
        return (
            <React.Fragment>
                <div className="dashboard">

                <div>
                    <h3>แดชบอร์ด</h3>
                </div>


                <div className="tab-order">
                    <div>
                        <Nav tabs>
                            <NavItem onClick={() => this.toggle('today')}>
                                <NavLink className={classnames({ 'active': activeTab === 'today' })} >
                                วันนี้
                                </NavLink>
                            </NavItem>
                            <NavItem onClick={() => this.toggle('week')}>
                                <NavLink className={classnames({ 'active': activeTab === 'week' })} >
                                รายสัปดาห์
                                </NavLink>
                            </NavItem>

                            <NavItem onClick={() => this.toggle('year')}>
                                <NavLink className={classnames({ 'active': activeTab === 'year' })} >
                                รายเดือน
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <div className="date">
                    {activeTab == "today" ? <span>วันที่ 30 ตุลาคม 2563</span> :  
                    activeTab == "week" ?
                    <div className="d-flex flex-row">
                    <div style={{width:'10em'}} className="pick-week">เลือกสัปดาห์</div> 
                    <DropDown
                      onChange={this.onSelectShop}
                      options={Date}
                      placeholder={'เลือกสัปดาห์'}
                      value={this.state.dateSelected}
                      className={'add-product-select'}
                      style={{width:'10em'}}
                    ></DropDown> 
                    </div> : activeTab == "year" ? <div className="d-flex flex-row">
                    <div style={{width:'10em'}} className="pick-week">เลือกเดือน</div> 
                    <DropDown
                      onChange={this.onSelectShop}
                      options={Date}
                      placeholder={'เลือกเดือน'}
                      value={this.state.monthSelected}
                      className={'add-product-select'}
                      style={{width:'10em'}}
                    ></DropDown> 
                    </div> : null}
                    

                   
                </div>

                {activeTab == "today" ? 
                <div>
                    <img src={D2} alt="D2"/>
                    <img src={D3} alt="D3"/>
                    <img src={D4} alt="D4"/>
                </div> 
                : activeTab == "week" ?  
                <div>
                    <img src={W2} alt="W2"/>
                    <img src={W3} alt="W3"/>
                    <img src={W4} alt="W4"/>
                    <img src={W5} alt="W5"/>
                </div> 
                : activeTab == "year" ?   
                <div>
                    <img src={Y2} alt="W2"/>
                    <img src={Y3} alt="W3"/>
                    <img src={Y4} alt="W4"/>
                    <img src={Y5} alt="W5"/>
                </div> : null}

                

                

               
                </div>


            </React.Fragment>
        )
    }
}

export default Dashboard