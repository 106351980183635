import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'

import { cartesian } from '../../helper/utils'
import IconArrowUp from '../../images/icons/arrow-up.svg'
import IconArrowDown from '../../images/icons/arrow-down.svg'
import IconCameraIMG from '../../images/icons/camera.svg'
import IconQuestion from '../../images/icons/question.svg'
import IconKerry from '../../images/icons/kerry-logo.svg'
import IconClose from '../../images/icons/close.svg'
import IconPlus from '../../images/icons/plus.svg'
import IconTrash from '../../images/icons/trash.svg'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import Editor from '../Editor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTags from 'react-tag-autocomplete'
import DropDown from '../DropDownList'

import IconShopProfile from '../../images/icons/shop-profile-default.svg'
import { ReactComponent as IconCamera } from '../../images/icons/camera.svg'
import closeImage from '../../images/icons/close-image.svg'
// import { Editor } from 'react-draft-wysiwyg';
import classnames from 'classnames'
import { ButtonUpload, ModalLoading, ImageSortable } from 'components/output'
import {
  dispatchGallery,
  SET_MEDIA_UPLOAD_EVENT,
  SET_GALLERY_FIELD,
} from 'redux/actions/gallery'

import productProvider from '../../provider/productProvider'

import {
  dispatchProduct,
  CREATE_PRODUCT_SKU,
  ADD_KEY_VALUE_PRODUCT,
  ADD_SEO_IN_PRODUCT,
  ADD_KEYWORD_IN_SEO,
  REMOVE_KEYWORD_IN_SEO,
  GET_LIST_PRODUCT,
  ADD_VALUE_IN_PRODUCT,
  ON_CREATE_PRODUCT,
  HANDLE_CRAETE_PRODUCT,
  GET_PRODUCT_CATEGORIES,
  SELECT_PRODUCT_CATEGORIES,
  REMOVE_IMAGE_PRODUCT,
  REMOVE_SEO_IMAGE,
  SET_SEO_LENGTH,
  ON_GET_BRANDS,
  CREATE_PRODUCT_LOGISTIC,
  OPEN_MODAL_ADD_PRODUCT,
  ADD_IMAGE_SEO,
  ADD_IMAGE_PATH,
  REMOVE_ALL_IMAGE,
  GET_PRODUCT_ATTRIBUTE,
  GET_PRODUCT,
  GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID,
  UPDATE_PRODUCT_LOGISTICS,
  UPDATE_PRODUCT_SKU,
  UPDATE_PRODUCT_BY_ID,
  VALIDATE_STOCK_REMAIN,
  GET_LIST_PROVIDER_SHOP_LOGISTIC,
  SET_KEY_PRODUCT,
} from '../../redux/actions/product'
import ModalUploadImage from '../modalUploadImage'

import host from '../../config/host'

import {
  OPEN_MODAL_UPLOAD_IMAGE,
  ADD_IMAGE_BASE64,
  dispatchModalUploadImage,
} from '../../redux/actions/modalUploadImage'
import {
  dispatchShop,
  GET_LIST_SHOP,
  ADD_KEY_VALUE_SHOP,
  SET_CURRENT_SHOP,
  SET_DATA_SHOP_DETAIL,
  GET_SHOPS,
  GET_ALL_SHOPS,
  GET_CURRENT_SHOP_DETAIL,
} from '../../redux/actions/shop'
import {
  dispatchLogistic,
  GET_LOGISTIC_LIST,
} from '../../redux/actions/logistic'
import RadioButtonGroup from '../RadioButtonGroup'
import FileInput from '../FileInput'
import ModalPreview from '../modalPreview'
import Checkbox from '../Checkbox'
import '../../styles/_addproduct.scss'
import './index.scss'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ToastNotification from '../ToastNotification'
import { initial } from 'lodash'

import { dispatchApp, SET_LOADING } from '../../redux/actions/app'
import Loading from '../Loading'
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider'

import ModalAlert from '../modalAlert/ModalAlert'
import { OPEN_TOAST, dispatchNotification } from 'redux/actions/notification'

import {
  dispatchModalAlert,
  OPEN_MODAL_ALERT,
} from '../../redux/actions/modalAlert'

import { getUserRole } from '../../helper/utils'

import InputNumber from '../InputNumber'
import Cookies from 'js-cookie'
import SelectInput from 'components/SelectInput'

const brands = [
  { label: 'Volvo', value: { a: '111', b: '11' } },
  { label: 'Benz', value: { a: '222', b: '22' } },
  { label: 'Honda', value: { a: '333', b: '33' } },
]

const reactTagBaseClass = {
  rootFocused: 'is-focused',
  selected: 'react-tags__selected',
  selectedTag: 'react-tags__selected-tag',
  selectedTagName: 'react-tags__selected-tag-name',
  search: 'react-tags__search',
  searchWrapper: 'react-tags__search-wrapper',
  searchInput: 'react-tags__search-input',
  suggestions: 'react-tags__suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled',
}
const reactTagClass = {
  root: 'react-tags',
  ...reactTagBaseClass,
}

const reactTagClassTextField = {
  root: 'react-tags-text-field',
  ...reactTagBaseClass,
}

const sampleTag = [
  {
    tag: [
      { id: 0, name: 'red' },
      { id: 1, name: 'black' },
    ],
    name: 'color',
  },
  { tag: [{ id: 1, name: 'small' }], name: 'size' },
]

const _productStatus = [
  { label: 'ขายอยู่', value: 'show' },
  { label: 'ไม่ขายแล้ว', value: 'hide' },
]

const initialState = {
  isOpen: false,
  listTest: null,
  editorState: '',
  tags: [],
  suggestions: [
    { id: 3, name: 'Bananas' },
    { id: 4, name: 'Mangos' },
    { id: 5, name: 'Lemons' },
    { id: 6, name: 'Apricots' },
  ],
  listShop: {
    listShopName: [],
  },
  listCategories: [],
  listAllBrands: [],
  skus: [],
  // optionDetail: [...sampleTag],
  optionDetail: [{ tag: [], name: '' }],
  // optionDetail: [{name:"",tag:[]}]
  optionProductDetail: {
    priceNet: '',
    priceList: '',
    priceCost: '',
    // priceGP: '',
    width: '',
    height: '',
    length: '',
    stockRemain: '',
    weight: '',
  },
  skusSelectedIndex: -1,
  shopSelected: {
    value: '',
    id: '',
  },
  modalProductSkuPreviews: [],
  productSkuFileImages: [],
  logisticList: [],
  enableProductSkuOption: false,
  enableLogisticOption: false,
  sku: {
    attribute: [
      {
        key: 'สินค้า',
        value: '',
      },
    ],
    imagePath: '',
    priceNet: '',
    priceList: '',
    priceCost: '',
    // priceGP: 10,
    sharingShop: '',
    sharingPlatform: '',
    stockRemain: '',
    weight: '',
    size: {
      width: '',
      length: '',
      height: '',
    },
    sku: '',
    showOn: [],
    status: '',
    createdBy: '',
  },
  shopSelectedIndex: 0,
  indexAddImage: null,
  productAttribute: null,
  currentProduct: null,
  categorySelected: '',
  productLogisticList: null,
  loading: false,
  validateStockRemain: null,
  sharingPlatform: '',
  allShops: [],
  errShopLogistic: [],
}

class ModalAddProduct extends Component {
  constructor(props) {
    super(props)
    this.state = JSON.parse(JSON.stringify(initialState))

    // this.reactTags = React.createRef()
  }

  componentDidMount() {
    this.setState(JSON.parse(JSON.stringify(initialState)))
    // this.props.dispatch(dispatchLogistic({ type: GET_LOGISTIC_LIST }))


    // this.props.currentShop.some((shop) => shop.id == )
    // this.props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
    // this.props.dispatch(dispatchProduct({ type: GET_PRODUCT_CATEGORIES }))
    // this.props.dispatch(dispatchProduct({ type: ON_GET_BRANDS }))
    // this.props.dispatch(dispatchLogistic({ type: GET_LOGISTIC_LIST }))

    // const shopSelected = {
    //   label: this.props.currentShop?.name,
    //   value: {
    //     id: this.props.currentShop?.id,
    //     name: this.props.currentShop?.name,
    //   },
    // }

    // this.onSelectShop(shopSelected)
  }

  componentWillUnmount() {
    // ModalAddProduct.initialProductData(this.props,this.state)
  }

  static getDerivedStateFromProps(props, state) {
    const isOpen = props.modalAddProduct.isOpen

    // if (state.logisticList != props.logisticList) {
    //   return {
    //     logisticList: props.logisticList,
    //   }
    // }
    // if (state.providerShopLogistic != props.providerShopLogistic) {
    //   return {
    //     providerShopLogistic: props.providerShopLogistic
    //   }
    // }
    if (state?.isOpen !== props.modalAddProduct.isOpen) {
      if (props.modalAddProduct.isOpen === true) {
        props.dispatch(dispatchShop({ type: GET_CURRENT_SHOP_DETAIL }))
        if (props.mode === 'UPDATE' || props.mode === 'VIEW') {
          props.dispatch(
            dispatchProduct({
              type: GET_LIST_PROVIDER_SHOP_LOGISTIC,
              payload: {
                logisticList: props.logisticList,
                shopId: props.productSelected?.shopId,
              },
            }),
          )
          // props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
          props.dispatch(
            dispatchProduct({
              type: GET_PRODUCT,
              payload: { productId: props.productSelected.id },
            }),
          )
          props.dispatch(
            dispatchProduct({
              type: GET_LIST_PRODUCT_LOGISTICS_BY_PRODUCT_ID,
              payload: {
                productId: props.productSelected.id,
                logisticList: state.logisticList,
                shopId: props.productSelected?.shopId,
              },
            }),
          )
          props.dispatch(dispatchShop({ type: GET_ALL_SHOPS, payload: {} }))
          return { isOpen: props.modalAddProduct.isOpen }
        }
        if (props.mode === 'ADD') {
          const shopId = JSON.parse(Cookies.get('user')).shopId
          props.dispatch(dispatchShop({ type: GET_ALL_SHOPS, payload: {} }))


          props.dispatch(
            dispatchProduct({
              type: GET_LIST_PROVIDER_SHOP_LOGISTIC,
              payload: {
                logisticList: props.logisticList,
                shopId: props.productSelected?.shopId,
              },
            }),
          )


          // props.dispatch(dispatchShop({ type: SET_DATA_SHOP_DETAIL }))
          return { isOpen: props.modalAddProduct.isOpen }
        }

        // else
        // return ModalAddProduct.clearProductData(props,state);
      } else {
        return ModalAddProduct.clearProductData(props, state)
      }

      return { isOpen: props.modalAddProduct.isOpen }
    }
    if (state.allShops !== props.allShops) {
      if (props.modalAddProduct.isOpen) {
        let shopSelected = { value: '', id: '' }
        let shopId = null

        if (getUserRole() === 'OWNER') {
          shopId = JSON.parse(Cookies.get('user')).shopId
          const shop = props.allShops.find((item) => item.id === shopId)
          shopSelected = {
            label: shop.name,
            value: { id: shop.id, name: shop.name },
          }
        }

        const createBy = JSON.parse(localStorage.getItem('createProductBy'))

        if (createBy.createAt === 'shopDetail') {
          shopId = props.currentShop.id
          shopSelected = {
            label: props.currentShop.name,
            value: { id: props.currentShop.id, name: props.currentShop.name },
          }
        } else if (
          createBy.createAt === 'product' &&
          props.mode === 'ADD' &&
          getUserRole() !== 'OWNER'
        ) {
          return { sharingPlatform: 0, allShops: props.allShops, shopSelected }
        } else {
          if (getUserRole() !== 'OWNER')
            shopId = props?.product?.currentProduct?.shopId
        }

        props.dispatch(
          dispatchProduct({
            type: ADD_VALUE_IN_PRODUCT,
            payload: { key: 'shopId', value: shopId },
          }),
        )

        /* NOTE - assign initial sharing platform from shop for create product */
        if (
          shopId &&
          props.allShops &&
          props.allShops.length > 0 &&
          props.mode !== 'UPDATE'
        ) {
          const _shopSelected = props.allShops.find(
            (current) => current.id === shopId,
          )
          const { sharingPlatform } = _shopSelected || {}

          if (sharingPlatform) {
            return { sharingPlatform, allShops: props.allShops, shopSelected }
          }
        }
      }
      return { allShops: props.allShops }
    }

    if (state.currentProduct !== props.product.currentProduct) {
      if (props.modalAddProduct.isOpen) {
        if (props.mode === 'UPDATE' || props.mode === 'VIEW')
          return ModalAddProduct.initialProductData(props, state)
      }
    }
    if (
      props.modalAddProduct.isOpen &&
      state.productAttribute !== props.product.productAttribute
    ) {
      let sharingShop = 0
      if (props.mode === 'UPDATE' || props.mode === 'VIEW') {
        /* NOTE - map productAttribute to optionDetail */
        const productAttribute = props.product.productAttribute
        let optionDetail = [{ tag: [], name: '' }]

        if (Object.keys(productAttribute).length > 0) {
          optionDetail = Object.keys(productAttribute).map((key) => {
            const _optionDetail = { tag: [], name: key }
            if (productAttribute[key].length > 0) {
              for (const index in productAttribute[key]) {
                const item = {
                  id: index,
                  name: productAttribute[key][index].value,
                }
                _optionDetail.tag.push(item)
              }
            }
            return _optionDetail
          })
        }
        const enableProductSkuOption = Object.keys(productAttribute).length > 0

        /* NOTE - assign sharing shop */
        // if(props.product?.currentProduct?.productSkusCMS.length &&
        //    props.product?.currentProduct?.productSkusCMS.length > 0) {
        //    sharingShop = props.product?.currentProduct?.productSkusCMS[0].sharingShop || 0;
        // }

        /* NOTE - generate ProductSkus For Update */
        const skus = enableProductSkuOption
          ? ModalAddProduct.generateProductSkusForUpdate(props)
          : []

        /* NOTE - ctreate ProductSkus stock remain error */
        const validateStockRemain = skus.map((current) => false)

        /* NOTE - assign layer 1st product image */
        let productSkuFileImages = []
        if (enableProductSkuOption) {
          /* NOTE - filter only layer 1 sku */
          let skuLayer1 = null
          const productAttribute = props.product.productAttribute
          for (const key of Object.keys(productAttribute)) {
            if (productAttribute[key][0].layer) {
              if (productAttribute[key][0].layer == 1)
                skuLayer1 = productAttribute[key]
            } else skuLayer1 = productAttribute[key]
          }

          const imageSkuList = skuLayer1.reduce((imageList, sku) => {
            if (sku.imagePath) {
              productSkuFileImages.push({
                fileImage: sku.imagePath,
                key: sku.value,
              })
              return [...imageList, sku.imagePath]
            } else {
              productSkuFileImages.push({ fileImage: null, key: sku.value })
              return imageList
            }
          }, [])

          // if (imageSkuList) {
          //   props.dispatch(
          //     dispatchGallery({
          //       type: 'SET_GALLERY_FIELD',
          //       payload: { key: 'skuUploadEvent', value: imageSkuList },
          //     }),
          //   )
          // }
        }

        return {
          productAttribute,
          optionDetail,
          enableProductSkuOption,
          skus,
          productSkuFileImages,
          validateStockRemain,
        }
      }
    }
    if (
      state.logisticList.length < 1 &&
      state.logisticList !== props.logisticList
    ) {
      let logisticList = props.logisticList
      if (logisticList.length > 0) {
        const kerryIndex = logisticList.findIndex(
          (item) => item.code === 'KERRY',
        )
        let temp = logisticList[0]
        logisticList[0] = logisticList[kerryIndex]
        logisticList[kerryIndex] = temp
        logisticList[0].enable = true
        logisticList[0].rateType = 'standard'
      }
      if (props.modalAddProduct.isOpen) {
        props.dispatch(
          dispatchProduct({
            type: GET_LIST_PROVIDER_SHOP_LOGISTIC,
            payload: {
              logisticList: props.logisticList,
              shopId: props.productSelected?.shopId,
            },
          }),
        )
      }

      // props.dispatch({dispatch})
      // let logistics = props.logisticList
      //   ? props.logisticList.map((element) => Object.assign({}, element))
      //   : []

      // let logisticList =
      //   logistics !== []
      //     ? logistics.map((logistic, idx) => {
      //       logistic.rateType = 'custom'
      //       logistic.shippingCost = 0
      //       logistic.price = null
      //       logistic.enable = logistic.code === 'KERRY' ? true : false
      //       logistic.shopLogisticSelected = []
      //       logistic.providerShopLogistic = []
      //       return logistic
      //     })
      //     : []

      // // switch kerry to first position
      // let logisticList = props.logisticList
      // if (logisticList.length > 0) {
      //   const kerryIndex = logisticList.findIndex(
      //     (item) => item.code === 'KERRY',
      //   )
      //   let temp = logisticList[0]
      //   logisticList[0] = logisticList[kerryIndex]
      //   logisticList[kerryIndex] = temp
      //   logisticList[0].enable = true
      //   logisticList[0].rateType = 'custom'
      // }

      // if (props.modalAddProduct.isOpen === true)
      return { logisticList: logisticList }
      // else return { logisticList: [] }
    }

    if (
      (props.modalAddProduct.isOpen &&
        props.product.productLogisticList &&
        state.productLogisticList !== props.product.productLogisticList) ||
      state.providerShopLogistic !== props.providerShopLogistic
    ) {
      if (
        props.modalAddProduct.isOpen &&
        (props.mode === 'UPDATE' || props.mode === 'VIEW')
      ) {
        let logisticList = state.logisticList
        let productLogisticList = props.product.productLogisticList
        let providerShopLogistic = props.providerShopLogistic

        if (logisticList) {
          logisticList = logisticList.reduce((prev, logistic) => {
            const logisticMatch =
              productLogisticList &&
              productLogisticList.find(
                (productLogistic) => productLogistic.logisticId === logistic.id,
              )
            // console.log('logisticMatch', logisticMatch)
            // console.log('providerShopLogistic', providerShopLogistic)
            // const shopLogisticMatch = providerShopLogistic && providerShopLogistic.find((shopLogistic) => shopLogistic?.logisticId === logistic.id)
            //   console.log('shopLogisticMatch', shopLogisticMatch)
            // let filterShopLogistic = providerShopLogistic && providerShopLogistic.find((item) => item?.id === logisticMatch?.shopLogisticId)
            // if(filterShopLogistic){
            //   logistic.shopLogisticSelected = filterShopLogistic
            // }
            const shopLogisticMatch =
              providerShopLogistic &&
              providerShopLogistic.find(
                (shopLogistic) => shopLogistic[0]?.logisticId === logistic.id,
              )

            providerShopLogistic &&
              providerShopLogistic.map((item) => {
                // item.filter((e) => e.logisticId == logisticMatch?.shopLogisticId)
                // let dataShopSelected = item.find((e) => console.log('e', e))
                // if (dataShopSelected) logistic.shopLogisticSelected = dataShopSelected
              })

            if (logisticMatch) {
              logistic.enable = true
              logistic.productId = logisticMatch.productId
              logistic.rateType = logisticMatch.rateType
              // logistic.providerShopSelected =
              if (shopLogisticMatch) {
                logistic.shopProviderLogistic = shopLogisticMatch
              }

              if (logisticMatch.rateType === 'custom')
                logistic.price = logisticMatch.price
            } else {
              logistic.enable = false
              logistic.price = null
              logistic.rateType = 'standard'
              logistic.shopProviderLogistic = []
            }

            /* NOTE - view mode show only exist logistic */
            if (props.mode === 'VIEW')
              return logisticMatch ? prev.concat(logistic) : prev
            else return prev.concat(logistic)
          }, [])
        }
        return {
          productLogisticList: props.product.productLogisticList,
          logisticList,
          providerShopLogistic: props.providerShopLogistic,
        }
      }

      return {
        productLogisticList: props.product.productLogisticList,
        providerShopLogistic: props.providerShopLogistic,
      }
    }
    if (props.app.loading !== state.loading) {
      if (!state.loading) return { loading: props.app.loading }

      // if (props.app.loading === false) {
      //   props.dispatch(dispatchProduct({ type: REMOVE_ALL_IMAGE, payload: {}}));
      //   const isOpen = props.product.modalAddProduct.isOpen;
      //   // props.dispatch(dispatchProduct({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: false} }))
      //   let initState = ModalAddProduct.clearProductData(props,state);
      //   return initState;
      // }
    }
    if (props.shop.listShopName !== state.listShop.listShopName) {
      const listShopName = []
      const listCategories = []
      const listAllBrands = []
      props.shop.listShopName.map((item, idx) => {
        listShopName.push({ label: item.name, value: item })
      })
      props.product.listAllCategories.map((item, idx) => {
        listCategories.push({
          label: item.title,
          value: item,
        })
      })
      props.product.listAllBrands.map((item, idx) => {
        listAllBrands.push({
          label: item.name,
          value: item,
        })
      })

      // NOTE - set default shop in dropdown
      const shopList = props.shop.listShopName
      let shopSelectedIndex = ''
      const createBy = JSON.parse(localStorage.getItem('createProductBy'))

      if (
        shopList &&
        shopList.length > 0 &&
        createBy.createAt === 'shopDetail' &&
        props.currentShop
      ) {
        shopSelectedIndex = props.shop.listShopName.findIndex(
          (item) => item.id === props.currentShop.id,
        )

        return {
          listShop: {
            ...state.listShop,
            listShopName,
          },
          shopSelected: listShopName[shopSelectedIndex],
          listCategories: listCategories,
          listAllBrands: listAllBrands,
          shopSelectedIndex,
        }
      }

      return {
        listShop: {
          ...state.listShop,
          listShopName,
        },
        // shopSelected: listShopName[0],
        listCategories: listCategories,
        listAllBrands: listAllBrands,
        shopSelectedIndex,
      }
    }

    return state
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mode === 'UPDATE') this.setAllFieldReadOnly(false)
    if (this.props.mode === 'VIEW') this.setAllFieldReadOnly(true)

    if (
      this.props.modalAddProduct.isOpen !== prevProps.modalAddProduct.isOpen &&
      this.props.modalAddProduct.isOpen === false
    )
      this.setState(ModalAddProduct.clearProductData(this.props, this.state))

    if (
      this.props.listImageSKU !== prevProps.listImageSKU &&
      this.props.listImageSKU.length &&
      this.state.indexAddImage !== null
    ) {
      const { productSkuFileImages, indexAddImage } = this.state
      const { listImageSKU } = this.props

      let tmpState = [...productSkuFileImages]
      // const tmpProps = [...listImageSKU]

      // const lastIndex = tmpProps.length - 1

      tmpState[indexAddImage].fileImage = listImageSKU[0]

      this.setState(
        {
          productSkuFileImages: tmpState,
        },
        () =>
          this.props.dispatch(
            dispatchGallery({
              type: SET_GALLERY_FIELD,
              payload: { key: 'skuUploadEvent', value: [] },
            }),
          ),
      )
    }
  }

  /* SECTION - product state update */
  static initialProductData = (props, state) => {
    const {
      name,
      brand,
      status,
      shopId,
      shop,
      seo,
      imagePath,
      videoPath,
      id,
      productSkusCMS,
      categories,
      description,
      prepareDay,
    } = props.product.currentProduct || {}
    const { sharingPlatform } = productSkusCMS[0] || {}

    props.dispatch(
      dispatchProduct({
        type: GET_PRODUCT_ATTRIBUTE,
        payload: { productId: id },
      }),
    )

    // NOTE - assign keyword tags
    let tags = []
    if (seo.keyword && seo.keyword.length > 0) {
      tags = seo.keyword.reduce((prev, tag, tagIndex) => {
        const tagName = typeof tag === 'string' ? tag : tag.name
        const _tag = { id: tagIndex, name: tagName }
        return typeof tag === 'string' ? prev.concat(_tag) : prev
      }, [])
    }

    const _description =
      typeof description[0].content === 'string'
        ? description[0].content
        : description[0].content[0].content

    // NOTE - assign shopSelectedIndex
    const shopList = props.shop.listShopName
    let shopSelectedIndex = ''
    if (shopList && shopList.length > 0)
      shopSelectedIndex = shopList.findIndex((item) => item.id === shop.id)

    // NOTE - assign shop seleted
    const shopSelected = {
      label: shop.name,
      value: { id: shop.id, name: shop.name },
    }

    // NOTE - assign product image path
    // listImageProduct, seoUploadEvent, skuUploadEvent, productUploadEvent
    const imagePathList = imagePath.reduce(
      (prev, current) => [...prev, current],
      [],
    )
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'productUploadEvent', value: imagePathList },
      }),
    )

    // NOTE - assign product video path
    // productUploadEventVideo
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'productUploadEventVideo', value: videoPath },
      }),
    )

    // NOTE - assign form value
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: 'seoUploadEvent',
          value: seo.seoLength.seoImage
            ? [seo.seoLength.seoImage]
            : [seo.seoImage],
        },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'status', value: status },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'prepareDay', value: prepareDay },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'description', value: _description },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopSelected', value: shop },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopId', value: shopId },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'name', value: name },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'brand', value: brand },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: SELECT_PRODUCT_CATEGORIES,
        payload: { name: categories[0] },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'title', value: seo?.title },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'keyword', value: tags },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'description', value: seo?.description },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'permalink', value: seo?.permalink },
      }),
    )

    const filterCategory = props.listAllCategories.filter(
      (cat) => cat.name === categories[0],
    )

    return {
      tags,
      description,
      isOpen: props.modalAddProduct.isOpen,
      shopSelected,
      currentProduct: props.product.currentProduct,
      categorySelected: filterCategory && filterCategory[0]?.title,
      sharingPlatform,
    }
  }
  static clearProductData = (props, state) => {
    /* clear image */
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'productUploadEvent', value: [] },
      }),
    )
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'productUploadEventVideo', value: [] },
      }),
    )
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'seoUploadEvent', value: [] },
      }),
    )
    props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: { key: 'skuUploadEvent', value: [] },
      }),
    )

    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopSelected', value: '' },
      }),
    )
    props.dispatch(dispatchProduct({ type: REMOVE_ALL_IMAGE, payload: {} }))
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'description', value: '' },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopId', value: '' },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'name', value: '' },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'brand', value: '' },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'title', value: '' },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'keyword', value: '' },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'description', value: '' },
      }),
    )
    props.dispatch(
      dispatchShop({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'permalink', value: '' },
      }),
    )
    props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'prepareDay', value: 24 },
      }),
    )
    // props.dispatch(dispatchProduct({type: ADD_VALUE_IN_PRODUCT,payload:{key:'logisiticList',value:[]}}))

    let initState = JSON.parse(JSON.stringify(initialState))
    initState.isOpen = props.modalAddProduct.isOpen
    initState.listAllBrands = state.listAllBrands
    initState.listCategories = state.listCategories
    initState.listShop = state.listShop
    // initState.logisiticList =

    // props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'currentProduct', value: null } }))
    // props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'productAttribute', value: null } }))
    return initState
  }
  /* !SECTION - product state update */

  setAllFieldReadOnly = (status) => {
    const disableTag = ['input', 'button', 'textarea']
    for (const fieldType of disableTag) {
      const fieldTypes = document.getElementsByTagName(fieldType)
      for (const input of Array.from(fieldTypes)) {
        /* NOTE - void for unDisabled item */
        let voidItem = undefined
        if (
          Array.from(input.classList).length > 0 &&
          this.props.mode === 'UPDATE'
        )
          voidItem = Array.from(input.classList).find((item) => item === 'void')

        if (voidItem === undefined) input.disabled = status
      }
    }

    if (this.props.mode === 'UPDATE') {
      const disableClass = ['sku-code', 'stock-remain']
      for (const fieldType of disableClass) {
        const fieldTypes = document.getElementsByClassName(fieldType)
        for (const input of Array.from(fieldTypes)) {
          input.disabled = true
        }
      }
    }
  }

  onSelectShop = (item) => {
    const shopId = item.value.id || null

    if (shopId) {
      const shopSelected = this.props.allShops.find(
        (current) => current.id === shopId,
      )
      const { sharingPlatform } = shopSelected || {}

      if (sharingPlatform) this.setState({ sharingPlatform })
    }

    this.setState({
      shopSelected: item,
    })

    this.props.dispatch(
      dispatchShop({
        type: SET_CURRENT_SHOP,
        payload: { shop: { id: item.value.id, name: item.value.name } },
      }),
    )
    this.props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopSelected', value: item.value },
      }),
    )
    this.props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'shopId', value: item.value.id },
      }),
    )
    this.props.dispatch(
      dispatchProduct({
        type: GET_LIST_PROVIDER_SHOP_LOGISTIC,
        payload: {
          logisticList: this.props.logisticList,
          shopId: shopId,
        },
      }),
    )
  }

  onDelete(i) {
    if (this.props.mode !== 'VIEW') {
      const tags = this.state.tags.slice(0)
      tags.splice(i, 1)
      this.setState({ tags })
      this.props.dispatch(
        dispatchProduct({ type: REMOVE_KEYWORD_IN_SEO, payload: i }),
      )
    }
  }

  onAddition(tag) {
    if (tag.name.trim().length == 0) return
    const tags = [].concat(this.state.tags, tag)
    this.setState({
      tags: [
        ...this.state.tags,
        {
          id: this.state.tags.length,
          name: tag.name,
        },
      ],
    })
    this.props.dispatch(
      dispatchProduct({
        type: ADD_KEYWORD_IN_SEO,
        payload: {
          seo: {
            // id: this.state.tags.length,
            // name: tag.name
            name: tag.name,
          },
        },
      }),
    )
  }

  onOptionProductDetailChange = (values, name) => {
    const { formattedValue, value } = values
    const attrValueNumber = value ? Number(value) : ''
    this.setState({
      optionProductDetail: {
        ...this.state.optionProductDetail,
        [name]: attrValueNumber,
      },
    })
  }

  isEmpty = (str) => {
    return !str || 0 === str.length
  }

  updateOptionProductDetailWithAllProduct = () => {
    let {
      priceNet,
      priceList,
      // priceCost,
      stockRemain,
      weight,
      width,
      length,
      height,
    } = this.state.optionProductDetail

    // priceNet = 2000;
    // priceList = 1800;
    // priceCost = 1900;
    // stockRemain = 12;
    // weight = 0.2;
    // width = 55;
    // length = 55;
    // height =44;

    this.setState((prevState) => {
      const newSkus = [...prevState.skus].map((sku) => {
        let newSku = {
          ...sku,
          priceNet: !this.isEmpty(priceNet) ? priceNet : sku.priceNet,
          priceList: !this.isEmpty(priceList) ? priceList : sku.priceList,
          // priceCost: !this.isEmpty(priceCost) ? priceCost : sku.priceCost,
          stockRemain: !this.isEmpty(stockRemain)
            ? stockRemain
            : sku.stockRemain,
          weight: !this.isEmpty(weight) ? weight : sku.weight,
        }

        /* NOTE - if old sku do not update */
        if (sku.skuId) newSku.stockRemain = sku.stockRemain

        newSku.size = {
          width: !this.isEmpty(width) ? width : sku.size.width,
          length: !this.isEmpty(length) ? length : sku.size.length,
          height: !this.isEmpty(height) ? height : sku.size.height,
        }
        return newSku
      })
      return { skus: newSkus }
    })
  }

  onSkusChange = (value, name, params) => {
    try {
      const { index } = params
      const stringType = ['sku', 'barcode']

      if (stringType.indexOf(name) > -1) value = String(value)
      else value = value.floatValue

      let skus = [...this.state.skus]
      let sku = skus[index]
      sku[name] = value
      skus[index] = sku

      this.setState({ skus })
    } catch (error) { }
  }

  onSkusSizeChange = (value, name, params) => {
    const key = name
    value = value.floatValue

    const { index } = params

    this.setState((prevState) => {
      let skus = [...prevState.skus]
      let sku = { ...skus[index] }
      sku.size = { ...sku.size, [key]: value }

      skus[index] = sku
      return { skus }
    })
  }

  onAddOptionDetail = () => {
    this.setState(
      {
        optionDetail: [...this.state.optionDetail, { tag: [], name: '' }],
      },
      () => this.CreateProductSKU(),
    )
  }

  onDeleteOptionDetail = (OptionDetailIndex) => {
    this.setState(
      {
        optionDetail: [...this.state.optionDetail].filter(
          (item, index) => index != OptionDetailIndex,
        ),
      },
      () => this.CreateProductSKU(),
    )

    if (OptionDetailIndex == 0)
      this.setState({
        productSkuFileImages: [],
      })
  }

  onAddOptionDetailTag = (tag, index) => {
    if (tag.name.trim().length == 0) return
    this.setState(
      (prevState) => {
        let newOptionDetail = [...prevState.optionDetail]
        const newTag = [
          ...newOptionDetail[index].tag,
          { id: newOptionDetail[index].tag.length, name: tag.name },
        ]
        newOptionDetail[index].tag = newTag

        const productSkuFileImages = [
          ...this.state.productSkuFileImages,
          { key: tag.name, fileImage: null },
        ]

        if (index === 0)
          return { optionDetail: newOptionDetail, productSkuFileImages }
        else return { optionDetail: newOptionDetail }
      },
      () => this.CreateProductSKU(),
    )
  }

  onDeleteOptionDetailTag = (tagIndex, optionDetailIndex) => {
    if (this.props.mode !== 'VIEW') {
      optionDetailIndex === 0 &&
        this.onRemoveTagSKU(
          tagIndex,
          'listImageSKU',
          'skuUploadEvent',
          this.state.productSkuFileImages[tagIndex],
        )

      this.setState(
        (prevState) => {
          let newOptionDetail = [...prevState.optionDetail]

          newOptionDetail[optionDetailIndex].tag = newOptionDetail[
            optionDetailIndex
          ].tag.filter((item, index) => index != tagIndex)

          if (optionDetailIndex === 0) return { optionDetail: newOptionDetail }
          else return { optionDetail: newOptionDetail }
        },
        () => this.CreateProductSKU(),
      )
    }
  }

  onRemoveTagSKU = (index, name, key, data) => {
    const tmpState = [...this.state.productSkuFileImages]
    tmpState.splice(index, 1)

    this.setState({
      productSkuFileImages: tmpState,
      indexAddImage: null,
    })

    // if (data?.fileImage) {
    //   const tmpProps = [...this.props[name]]

    // const idxDelete = tmpProps.findIndex((t) => t.id === data.fileImage.id)

    // tmpProps.splice(idxDelete, 1)

    // this.props.dispatch(
    //   dispatchGallery({
    //     type: 'SET_GALLERY_FIELD',
    //     payload: {
    //       key: key,
    //       value: tmpProps,
    //     },
    //   }),
    // )
    // }
  }

  onClickRemoveSKU = (index, name, key, data) => {
    // const tmpProps = [...this.props[name]]
    const tmpState = [...this.state.productSkuFileImages]

    // const idxDelete = tmpProps.findIndex((t) => t.id === data.fileImage.id)

    // tmpProps.splice(idxDelete, 1)
    tmpState[index].fileImage = null

    // this.props.dispatch(
    //   dispatchGallery({
    //     type: 'SET_GALLERY_FIELD',
    //     payload: {
    //       key: key,
    //       value: tmpProps,
    //     },
    //   }),
    // )

    this.setState({
      productSkuFileImages: tmpState,
      indexAddImage: null,
    })
  }

  onChangeOptionDetailName = (index, e) => {
    const name = e.target.value
    this.setState(
      (prevState) => {
        let newOptionDetail = [...prevState.optionDetail]
        newOptionDetail[index].name = name
        return { optionDetail: newOptionDetail }
      },
      () => this.CreateProductSKU(),
    )
  }

  onChangeProductStatus = (event, index) => {
    const status = event.target.value
    this.setState((prevState) => {
      let skus = [...prevState.skus]
      skus[index].status = status
      return { skus: skus }
    })
  }

  CreateProductSKU = () => {
    const optionDetailTemp = [...this.state.optionDetail]
    const optionDetail = optionDetailTemp.filter(
      (item) => item.tag.length > 0 && item.name.length > 0,
    )

    const template = {
      createdBy: '',
      priceNet: '',
      priceList: '',
      // priceCost: '',
      // priceGP: 2000,
      sharingShop: '',
      sharingPlatform: '',
      stockRemain: '',
      sku: '',
      weight: '',
      status: 'show',
      size: {
        width: '',
        length: '',
        height: '',
      },
      attribute: [],
      showOn: ['normal', 'shelf', 'live'],
      imagePath: '',
      fileImage: '',
      updateStock: { mode: 'ADD', value: '' }, //add || remove
    }

    if (optionDetail.length > 0) {
      const tagArr = optionDetail.reduce((total, item) => {
        const arr = item.tag.map((tag) => tag.name)
        total.push(arr)
        return total
      }, [])

      const keyArr = optionDetail.reduce((total, item) => {
        total.push(item.name)
        return total
      }, [])

      const skuArr = [...cartesian(...tagArr)]

      const productSku = skuArr.map((sku) => {
        const attribute = keyArr.map((key, index) => {
          return index == 0
            ? { key, value: sku[index], imagePathId: '', layer: index + 1 }
            : { key, value: sku[index], layer: index + 1 }
        })
        // const newSkuWithId = [productId, ...sku];
        const newSku = sku.join('-')

        let productSku = { ...template }
        productSku.sku = newSku
        productSku.attribute = attribute
        productSku.imagePathId = ''

        return productSku
      })

      /* NOTE - keep old value do not create all new */
      const _SKUS = JSON.parse(JSON.stringify(this.state.skus))
      /* NOTE - case: update the Sku have product key within
                case: create the Sku does not have product key within  */
      const productId = this.state.currentProduct
        ? `${this.state?.currentProduct?.id}-`
        : ''

      /* NOTE - assign old value that match with new sku that just create */
      const newProductSkus = productSku.map((current) => {
        const matchSKU = _SKUS.find(
          (_current) => `${productId}${current.sku}` === _current.sku,
        )

        current.sku = `${productId}${current.sku}`
        return matchSKU ? matchSKU : current
      }, [])

      this.setState({ skus: newProductSkus })
    } else {
      this.setState({ skus: [] })
    }
  }

  static generateProductSkusForUpdate = (props) => {
    try {
      const productSku = props.product.currentProduct.productSkusCMS.map(
        (productSkus) => {
          const {
            attributeSKU,
            priceList,
            priceNet,
            // priceCost,
            showOn,
            imagePath,
            stockRemain,
            status,
            size,
            weight,
            sku,
            id,
            sharingShop,
            sharingPlatform,
          } = productSkus
          const productOption = {
            skuId: id,
            createdBy: 0,
            priceNet: priceNet,
            priceList: priceList,
            // priceCost: priceCost,
            // priceGP: 2000,
            sharingShop,
            sharingPlatform,
            stockRemain: stockRemain,
            sku: sku,
            weight: weight,
            status: status,
            size: size,
            attribute: attributeSKU,
            showOn: showOn,
            imagePath: imagePath,
            fileImage: null,
            updateStock: { mode: '', value: '' }, //add || remove
          }
          return productOption
        },
      )

      return productSku
    } catch (error) { }
  }

  setHandleProductSkuPreview = (index, modalClick) => {
    this.setState((prevState) => {
      let modalProductSkuPreviews = prevState.modalProductSkuPreviews
      modalProductSkuPreviews[index] = modalClick
      return { modalProductSkuPreviews: modalProductSkuPreviews }
    })
  }

  handleAddition = (s) => {
    const tags = [].concat(this.state.value.tags, s)
    this.setState({
      ...this.state.value,
      tags: tags,
    })
  }

  onChangeStatusShop = (value) => {
    this.props.dispatch(
      dispatchShop({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'status', value: value.target.value },
      }),
    )
  }

  onSelectFile = (e) => {
    // if (e.target.name == "seoImage" && this.props.product.seoImage.length == 2) return
    if (
      e.target.name == 'productImage' &&
      this.props.product.pathImage.length == 10
    )
      return
    const fileName = e.target.files[0].name
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.props.dispatch(
          dispatchModalUploadImage({
            type: ADD_IMAGE_BASE64,
            payload: {
              imgBase64: reader.result,
              fileName: fileName,
            },
          }),
        )
        this.setState({
          image64: reader.result,
        })
      }
      reader.readAsDataURL(e.target.files[0])

      this.props.dispatch(
        dispatchModalUploadImage({
          type: OPEN_MODAL_UPLOAD_IMAGE,
          payload: { isOpen: true, name: e.target.name },
        }),
      )
    }
  }

  onFileInputClick = (index) => {
    //
    this.setState({ skusSelectedIndex: index })
  }

  onCloseModalUploadImage = () => {
    // document.getElementById('productImage').value = '';
    this.props.dispatch(
      dispatchModalUploadImage({
        type: OPEN_MODAL_UPLOAD_IMAGE,
        payload: { isOpen: false },
      }),
    )
  }

  handleShowModal = (idx) => {
    const x = document.getElementById('modalPreview' + idx)
    if (x.className == 'modal-preview-hide') {
      x.className = ''
    } else {
      x.className = 'modal-preview-hide'
    }
  }

  setSEOLength = () => {
    const title = this.props.product.value.seo.seoLength.title
    const description = this.props.product.value.seo.seoLength.description
    const keyword = this.props.product.value.seo.seoLength.keyword
    if (title.length <= 24) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'title', value: 'bad' },
        }),
      )
    } else if (title.length <= 34) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'title', value: 'poor' },
        }),
      )
    } else if (title.length <= 65) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'title', value: 'good' },
        }),
      )
    } else if (title.length <= 75) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'title', value: 'poor' },
        }),
      )
    } else if (title.length <= 76) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'title', value: 'bad' },
        }),
      )
    }
    if (description.length <= 44) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'description', value: 'bad' },
        }),
      )
    } else if (description.length <= 62) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'description', value: 'poor' },
        }),
      )
    } else if (description.length <= 250) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'description', value: 'good' },
        }),
      )
    } else if (description.length <= 320) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'description', value: 'poor' },
        }),
      )
    } else if (description.length <= 321) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'description', value: 'bad' },
        }),
      )
    }
    if (keyword.length == 0) {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'keyword', value: 'bad' },
        }),
      )
    } else {
      this.props.dispatch(
        dispatchProduct({
          type: SET_SEO_LENGTH,
          payload: { key: 'keyword', value: 'good' },
        }),
      )
    }
  }

  isNullOrWhitespace = (input) => {
    if (!input) return true
    if (typeof input === 'undefined' || input == null) return true
    return input.toString().replace(/\s/g, '').length < 1
  }

  validateProduct = () => {
    const imageProduct = this.props.listImageProduct
    const imageSEO = this.props.listImageSEO
    const product = this.props.product.value
    let err = {}
    if (this.isNullOrWhitespace(product.name)) err.name = true
    if (this.isNullOrWhitespace(product.description)) err.description = true
    if (product?.categories[0] == undefined) err.categories = true
    if ((imageProduct && imageProduct.length == 0) || !imageProduct)
      err.pathImage = true
    if (this.isNullOrWhitespace(product.seo.title)) err.seoTitle = true
    if (this.isNullOrWhitespace(product.seo.description))
      err.seoDescription = true
    if ((imageSEO && imageSEO.length == 0) || !imageSEO) err.seoSeoImage = true
    if (this.isNullOrWhitespace(product.prepareDay)) err.prepareDay = true
    if (this.isNullOrWhitespace(product.seo.permalink)) err.seoPermalink = true

    const regEXP = /^[ก-๙-A-Za-z0-9_\-\+\(\)\[\].]+$/
    if (!regEXP.test(product.seo.permalink)) err.seoPermalinkFormat = true

    this.setState({ err })
    return Object.keys(err).length == 0 ? true : false
  }

  getProduct = () =>
    new Promise((resolve, reject) => {
      this.props.dispatch(
        dispatchProduct({
          type: GET_PRODUCT,
          payload: { productId: this.props.productSelected.id },
        }),
      )
      resolve()
    })

  /* SECTION - validateProductSkus */
  validateProductSkus = () => {
    const { optionDetail, skus, productSkuFileImages } = this.state
    let validate = true

    // NOTE - validate product sku option key
    const _optionDetail = [...optionDetail].map((item) => {
      item.errors = {}
      if (!item.name.length > 0) {
        item.errors.name = '* กรุณากรอกชื่อตัวเลือกสินค้า'
      } else {
        if (item.errors.name) delete item.errors.name
      }

      if (!item.tag.length > 0) {
        item.errors.tag = '* กรุณากรอกรายละเอียดตัวเลือก'
      } else {
        if (item.errors.name) delete item.errors.name
      }

      if (!(Object.keys(item.errors).length > 0)) {
        delete item.errors
      } else {
        validate = false
      }

      return item
    })
    this.setState({ optionDetail: _optionDetail })

    // NOTE - validate product sku option image
    if (productSkuFileImages.length > 0) {
      const _productSkuFileImages = productSkuFileImages.map((item) => {
        item.errors = {}
        if (!item.fileImage) item.errors.fileName = '* กรุณาอัพโหลดรูปภาพ'
        if (!(Object.keys(item.errors).length > 0)) {
          delete item.errors
        } else {
          validate = false
        }
        return item
      })
      this.setState({ productSkuFileImages: _productSkuFileImages })
    }
    // NOTE - validate product sku detail
    if (skus.length > 0) {
      const _skus = skus.map((item) => {
        item.errors = {}
        if (!item.priceCost) {
          // item.errors.priceCost = '* กรุณากรอกราคาส่ง'
        } else {
          if (item.errors.priceCost) delete item.errors.priceCost
        }
        if (!item.priceList) {
          item.errors.priceList = '* กรุณากรอกราคาปกติ'
        } else {
          if (item.errors.priceList) delete item.errors.priceList
        }
        if (!item.priceNet) {
          item.errors.priceNet = '* กรุณากรอกราคาขาย'
        } else {
          if (item.errors.priceNet) delete item.errors.priceNet
        }

        if (!item.skuId) {
          if (!item.stockRemain) {
            item.errors.stockRemain = '* กรุณากรอกจำนวนสต็อก'
          } else {
            if (item.errors.stockRemain) delete item.errors.stockRemain
          }
        }

        if (!item.weight) {
          item.errors.weight = '* กรุณากรอกน้ำหนักของสินค้า'
        } else {
          if (item.errors.weight) delete item.errors.weight
        }
        if (!item.size.height) {
          item.errors.height = '* กรุณากรอกความสูงของกล่อง'
        } else {
          if (item.errors.height) delete item.errors.height
        }
        if (!item.size.width) {
          item.errors.width = '* กรุณากรอกความกว้างของกล่อง'
        } else {
          if (item.errors.width) delete item.errors.width
        }
        if (!item.size.length) {
          item.errors.length = '* กรุณากรอกความยาวของกล่อง'
        } else {
          if (item.errors.length) delete item.errors.length
        }
        if (!item.sku) {
          item.errors.sku = '* กรุณากรอก Sku'
        } else {
          if (item.errors.sku) delete item.errors.sku
        }

        if (!(Object.keys(item.errors).length > 0)) {
          delete item.errors
        } else {
          validate = false
        }
        return item
      })
      this.setState({ skus: _skus })
    }
    return validate
  }
  /* !SECTION */

  /* SECTION - validateProductSku */
  validateProductSku = () => {
    //
    let _sku = { ...this.state.sku }
    _sku.errors = {}
    let validate = true

    if (!_sku.priceCost) {
      // _sku.errors.priceCost = '* กรุณากรอกราคาส่ง'
    } else {
      if (_sku.errors.priceCost) delete _sku.errors.priceCost
    }
    if (!_sku.priceList) {
      _sku.errors.priceList = '* กรุณากรอกราคาปกติ'
    } else {
      if (_sku.errors.priceList) delete _sku.errors.priceList
    }
    if (!_sku.priceNet) {
      _sku.errors.priceNet = '* กรุณากรอกราคาขาย'
    } else {
      if (_sku.errors.priceNet) delete _sku.errors.priceNet
    }
    if (!_sku.stockRemain) {
      _sku.errors.stockRemain = '* กรุณากรอกจำนวนสต็อก'
    } else {
      if (_sku.errors.stockRemain) delete _sku.errors.stockRemain
    }
    if (!_sku.weight) {
      _sku.errors.weight = '* กรุณากรอกน้ำหนัก'
    } else {
      if (_sku.errors.weight) delete _sku.errors.weight
    }
    if (!_sku.size.height) {
      _sku.errors.height = '* กรุณากรอกความสูงของกล่อง'
    } else {
      if (_sku.errors.height) delete _sku.errors.height
    }
    if (!_sku.size.length) {
      _sku.errors.length = '* กรุณากรอกความยาวของกล่อง'
    } else {
      if (_sku.errors.length) delete _sku.errors.length
    }
    if (!_sku.size.width) {
      _sku.errors.width = '* กรุณากรอกความกว้างของก่อง'
    } else {
      if (_sku.errors.width) delete _sku.errors.width
    }
    if (!_sku.sku) {
      _sku.errors.sku = '* กรุณากรอกรหัส SKU สินค้า'
    } else {
      if (_sku.errors.sku) delete _sku.errors.sku
    }

    if (!(Object.keys(_sku.errors).length > 0)) {
      delete _sku.errors
    } else {
      validate = false
    }

    this.setState({ sku: _sku })

    return validate
  }
  /* !SECTION */

  onCreateProduct = async () => {
    const validateProduct = await this.validateUpdateProduct('ADD')
    if (validateProduct) this.createProduct()
  }

  createProduct = async () => {
    // const validate = await this.validateProduct()
    // if (this.state.enableProductSkuOption) {
    //   const validate = await this.validateProductSkus()
    //   if (!validate) return
    // } else {
    //   const validate = await this.validateProductSku()
    //   if (!validate) return
    // }
    // if (!validate) return
    // await this.setSEOLength()

    // Product skus option Off (1 sku),
    let { sku } = this.state
    const productSkuParam = { sku }

    // Product skus option Off ( > 2 skus)
    let skus = this.state.skus.map((element) => Object.assign({}, element))

    /* NOTE - assign sharing shop */
    skus.map((current) => {
      current.sharingPlatform = Number(this.state.sharingPlatform)
      current.sharingShop = 100 - Number(this.state.sharingPlatform)
      return current
    })
    sku.sharingPlatform = Number(this.state.sharingPlatform)
    sku.sharingShop = 100 - Number(this.state.sharingPlatform)

    /* NOTE - remove priceCost */
    if (sku.hasOwnProperty('priceCost')) delete sku.priceCost;

    const skusImageFile = this.state.productSkuFileImages.map((element) =>
      Object.assign({}, element),
    )
    const productSkus = { skus, skusImageFile }
    const productSkusParam = productSkus

    const logisticParam = this.state.logisticList
    const payload = !this.state.enableProductSkuOption
      ? { productSkusParam, logisticParam, productSkuParam }
      : { productSkusParam, logisticParam }

    await this.props.dispatch(
      dispatchProduct({ type: HANDLE_CRAETE_PRODUCT, payload }),
    )
  }

  onUploadImage = () => {
    const skus = this.state.skus.map((element) => Object.assign({}, element))
    const skusImageFile = this.state.productSkuFileImages.map((element) =>
      Object.assign({}, element),
    )
    const payload = { skus, skusImageFile }
    this.props.dispatch(dispatchProduct({ type: CREATE_PRODUCT_SKU, payload }))
  }

  onModalUploadImageGetImage = (fileImage) => {
    if (this.state.skusSelectedIndex != -1) {
      const { currentImg, fileName } = fileImage

      this.setState((prevState) => {
        let productSkuFileImages = [...prevState.productSkuFileImages]

        productSkuFileImages[this.state.skusSelectedIndex].fileImage = {
          file: currentImg,
          fileName: fileName,
        }

        return { productSkuFileImages }
      })
    }
  }

  onChangeCategory = (value) => {
    //
    this.setState({ categorySelected: value })
    this.props.dispatch(
      dispatchProduct({
        type: SELECT_PRODUCT_CATEGORIES,
        payload: { name: value.value.name },
      }),
    )
  }
  onEditorChange = (htmlString) => {
    //
    this.props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'description', value: htmlString },
      }),
    )
  }

  onRemoveImage = (idx) => {
    this.props.dispatch(
      dispatchProduct({
        type: REMOVE_IMAGE_PRODUCT,
        payload: {
          idx: idx,
        },
      }),
    )
  }

  checkSeoDescription = () => {
    let textShow = ''
    if (document.querySelector('.progressDes')) {
      // document.querySelector('.progressDes').style.width = (this.props.product.value.seo.description.length / 4) + 'px'
      // document.querySelector('.progressDes').style.maxWidth = '160px'
      // document.querySelector('.progressDes').style.height = '0px'
      // document.querySelector('.seoDescription').style.color = 'grey'

      if (this.props.product.value.seo.description.length <= 44) {
        textShow = 'สั้นเกินไป'
        document.querySelector('.progressDes').style.background = 'red'
        document.querySelector('.seoDescription').style.color = 'red'
      } else if (this.props.product.value.seo.description.length <= 62) {
        textShow = 'สั้นไป'
        document.querySelector('.progressDes').style.background = 'yellow'
        document.querySelector('.seoDescription').style.color = 'yellow'
      } else if (this.props.product.value.seo.description.length <= 250) {
        textShow = 'ดี'
        document.querySelector('.progressDes').style.background = 'green'
        document.querySelector('.seoDescription').style.color = 'green'
      } else if (this.props.product.value.seo.description.length <= 320) {
        textShow = 'ยาวไป'
        document.querySelector('.progressDes').style.background = 'yellow'
        document.querySelector('.seoDescription').style.color = 'yellow'
      } else if (this.props.product.value.seo.description.length >= 321) {
        textShow = 'ยาวเกินไป'
        document.querySelector('.progressDes').style.background = 'red'
        document.querySelector('.seoDescription').style.color = 'red'
      }
    }

    return (
      <div>
        <div className='seoDescription'>
          <span style={{ color: '#9d9d9d' }}>
            {this.props.product.value.seo.description.length} ตัวอักษร
          </span>
          ({textShow})
        </div>
        <div className='containerDes'>
          <div className='progressDes'></div>
        </div>
      </div>
    )
  }

  checkSeoTitle = () => {
    let textShow = ''

    if (document.querySelector('.progressTitle')) {
      if (this.props.product.value.seo.title.length <= 24) {
        textShow = 'สั้นเกินไป'
        document.querySelector('.progressTitle').style.background = 'red'
        document.querySelector('.seoTitle').style.color = 'red'
        if (document.querySelector('.progressTitle').style.color != 'red') {
        }
      } else if (this.props.product.value.seo.title.length <= 34) {
        textShow = 'สั้นไป'
        document.querySelector('.progressTitle').style.background = 'yellow'
        document.querySelector('.seoTitle').style.color = 'yellow'
      } else if (this.props.product.value.seo.title.length <= 65) {
        textShow = 'ดี'
        document.querySelector('.progressTitle').style.background = 'green'
        document.querySelector('.seoTitle').style.color = 'green'
      } else if (this.props.product.value.seo.title.length <= 75) {
        textShow = 'ยาวไป'
        document.querySelector('.progressTitle').style.background = 'yellow'
        document.querySelector('.seoTitle').style.color = 'yellow'
      } else if (this.props.product.value.seo.title.length >= 76) {
        textShow = 'ยาวเกินไป'
        document.querySelector('.progressTitle').style.background = 'red'
        document.querySelector('.seoTitle').style.color = 'red'
      }
    }

    return (
      <div>
        <div className='seoTitle'>
          <span style={{ color: '#9d9d9d' }}>
            {this.props.product.value.seo.title.length} ตัวอักษร
          </span>
          ({textShow})
        </div>
        <div className='containerDes'>
          <div className='progressTitle'></div>
        </div>
      </div>
    )
  }

  onChangeBrand = (e) => {
    //
    this.props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'brand', value: e.target.value },
      }),
    )
  }

  onChangePrepareDay = (e) => {
    const value = e.target.value.length > 0 ? Number(e.target.value) : ''
    this.props.dispatch(
      dispatchProduct({
        type: ADD_VALUE_IN_PRODUCT,
        payload: { key: 'prepareDay', value },
      }),
    )
  }

  onChangeSharingShop = (values, name) => {
    const { formattedValue, value: _value } = values;
    const value = _value ? Number(_value) : '';

    this.setState({ sharingPlatform: value })

    if (typeof value === 'number') {
      if ([...this.state.skus].length > 0) {
        const skus = [...this.state.skus].map((current) => {
          current.sharingPlatform = Number(value)
          current.sharingShop = 100 - Number(value)
          return current
        })
        this.setState({ skus })
      }

      const sku = { ...this.state.sku }
      sku.sharingPlatform = Number(value)
      sku.sharingShop = 100 - Number(value)

      this.setState({ sku })
    }
  }

  onShowOnChange = (e, index) => {
    const skuIndex = index
    const value = e.target.value
    this.setState((prevState) => {
      const skus = [...prevState.skus]
      skus[skuIndex].showOn =
        skus[skuIndex].showOn.indexOf(value) > -1
          ? [...skus[skuIndex].showOn].filter((element) => element !== value)
          : [...skus[skuIndex].showOn, value]
      return { skus }
    })
  }

  onUpdateStockValueChange = (value, name, params) => {
    value = value.floatValue
    const { index } = params

    const { skus } = this.state

    let { validateStockRemain } = this.state
    if (
      skus[index].updateStock.mode === 'REDUCE' &&
      skus[index].stockRemain < value
    ) {
      validateStockRemain[index] = true
    } else {
      validateStockRemain[index] = false
    }
    this.setState((prevState) => {
      const skus = [...prevState.skus]
      skus[index].updateStock = { ...skus[index].updateStock, value }
      return { skus, validateStockRemain }
    })
  }

  switchUpdateStockMode = (index, action) => {
    const mode = action

    const { skus } = this.state
    let { validateStockRemain } = this.state
    if (
      mode === 'REDUCE' &&
      skus[index].stockRemain < skus[index].updateStock.value
    ) {
      validateStockRemain[index] = true
    } else {
      validateStockRemain[index] = false
    }

    this.setState((prevState) => {
      const skus = [...prevState.skus]
      skus[index].updateStock = { ...skus[index].updateStock, mode }
      return { skus, validateStockRemain }
    })
  }

  onInputNumberKeyUp = (event) => {
    event.target.value = event.target.value.replace(/[^\d]+|^0+(?!$)/g, '')
  }

  onActiveLogisticChange = (event, index, code) => {
    /* NOTE - at least one item is active */
    let logisticList = [...this.state.logisticList]
    const logisticEnable = logisticList.filter((item) => item.enable == true)

    /* NOTE - only one button is active force can not update */
    if (logisticEnable.length == 1 && logisticList[index].enable) return

    /* NOTE - disable price setting checkbox */
    document.getElementById(code).disabled = !logisticList[index].enable

    logisticList[index].enable = !logisticList[index].enable
    if (logisticList[index].rateType == undefined) {
      logisticList[index].rateType = 'standard'
    }
    // logisticList[index].rateType = 'standard'

    // if(logisticList[index].enable === false)
    //   logisticList[index].rateType = 'standard'
    // else
    //   logisticList[index].rateType = 'free'

    this.setState({ logisticList })
  }

  onRateTypeChange = (event, index) => {
    this.setState((prevState) => {
      let logisticList = [...this.state.logisticList]
      let errShopLogistic = [...this.state.errShopLogistic]
      logisticList[index].rateType =
        logisticList[index].rateType === 'standard' ? 'custom' : 'standard'
      const code = logisticList[index].code
      const indexErr = this.state.errShopLogistic.indexOf(code)
      errShopLogistic.splice(indexErr, 1)

      return { logisticList, errShopLogistic }
    })
  }

  // onCreateProductLogistic = () => {
  //   const payload = this.state.logisticList
  //     .filter((logistic) => logistic.enable === true)
  //     .map((logistic) => {
  //       const param = {
  //         logisticId: logistic.id,
  //         productId: 132,
  //         shopId: 9,
  //         rateType: logistic.rateType,
  //       }
  //       return param
  //     })
  //   this.props.dispatch(
  //     dispatchProduct({ type: CREATE_PRODUCT_LOGISTIC, payload }),
  //   )
  // }

  onOptionStateChange = (event) => {
    const attrName = event.target.name
    const attrValue = event.target.value
    this.setState({ [attrName]: !this.state[attrName] })
  }

  onSkuChange = (value, name, params) => {
    value = name === 'sku' || name === 'barcode' ? value : value.floatValue
    this.setState({ sku: { ...this.state.sku, [name]: value } })
  }

  onSkuSizeChange = (value, name, params) => {
    this.setState({
      sku: {
        ...this.state.sku,
        size: { ...this.state.sku.size, [name]: value.floatValue },
      },
    })
  }

  onSkuShowOnChange = (e, index) => {
    const { sku } = this.state
    const value = e.target.value
    this.setState({
      sku: {
        ...sku,
        showOn:
          sku.showOn.indexOf(value) > -1
            ? [...sku.showOn].filter((element) => element !== value)
            : [...sku.showOn, value],
      },
    })
  }

  logisticPriceChange = (event, index) => {
    const name = event.target.name
    const value = event.target.value
    const attrNumber =
      event.target.value.length > 0 ? Number(event.target.value) : ''
    this.setState((prevState) => {
      const { logisticList } = prevState
      logisticList[index].price = attrNumber
      return { logisticList }
    })
  }

  getShopSelectedIndex = () => {
    const getShopSelectedIndex =
      this.state.listShop.listShopName.length > 0
        ? this.state.listShop.listShopName.findIndex(
          (item) => item.value.id === this.state.shopSelected.value.id,
        )
        : 0
    return getShopSelectedIndex
  }

  toggleModal = () => {
    //
    this.props.dispatch(
      dispatchProduct({ type: REMOVE_ALL_IMAGE, payload: {} }),
    )

    this.setState(initialState)
    const isOpen = this.props.product.modalAddProduct.isOpen
    this.props.dispatch(
      dispatchProduct({
        type: OPEN_MODAL_ADD_PRODUCT,
        payload: { isOpen: !isOpen },
      }),
    )
  }

  onClickRemoveImage = (index, name, key) => {
    const tmp = [...this.props[name]]
    tmp.splice(index, 1)

    this.props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: key,
          value: tmp,
        },
      }),
    )
  }

  onSortLists = (lists, key) => {
    this.props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: key,
          value: lists,
        },
      }),
    )
  }

  updateLogisticProduct = () => {
    const shopId = this.state.currentProduct.shopId
    const productId = this.state.currentProduct.id
    const productLogistic = this.state.logisticList.reduce((prev, current) => {
      const matchProductLogisticUpdate = this.state.productLogisticList.find(
        (item) => item.logisticId === current.id,
      )
      if (matchProductLogisticUpdate) {
        const logistic = {
          rateType: current.rateType,
          status: current.enable ? 'show' : 'hide',
          logisticId: current.id,
          shopId: shopId,
          productId: productId,
          productLogisticId: matchProductLogisticUpdate.id,
        }
        return prev.concat(logistic)
      } else {
        if (current.enable) {
          const logistic = {
            rateType: current.rateType,
            status: 'show',
            logisticId: current.id,
            shopId: shopId,
            productId: productId,
          }
          return prev.concat(logistic)
        }
      }
      return prev
    }, [])
    this.props.dispatch(
      dispatchProduct({
        type: UPDATE_PRODUCT_LOGISTICS,
        payload: { productLogistic },
      }),
    )
  }

  updateProductSku = () => {
    const skusImageFile = this.state.productSkuFileImages.map((element) =>
      Object.assign({}, element),
    )
    const { skus, currentProduct } = this.state
    this.props.dispatch(
      dispatchProduct({
        type: UPDATE_PRODUCT_SKU,
        payload: { skus, productId: currentProduct.id, skusImageFile },
      }),
    )
  }

  onUpdateProduct = async () => {
    const validateProduct = await this.validateUpdateProduct('UPDATE')
    if (validateProduct) this.updateProduct()
  }

  validateUpdateProduct = async (mode) => {
    // return new Promise(async (resolve, reject) => {
    //   try {
    let validateAll = true
    const validateProduct = await this.validateProduct()

    if (!validateProduct) {
      this.openAlertModal('กรุณาข้อมูลที่จำเป็นให้ครบ')
      validateAll = false
    }
    if (validateAll === true) await this.setSEOLength()

    if (validateAll === true) {
      if (this.state.enableProductSkuOption) {
        const validate = await this.validateProductSkus()
        if (!validate) {
          this.openAlertModal('กรุณาข้อมูลที่จำเป็นให้ครบ')
          validateAll = false
        }
      } else {
        const validate = await this.validateProductSku()
        if (!validate) {
          this.openAlertModal('กรุณาข้อมูลที่จำเป็นให้ครบ')
          validateAll = false
        }
      }
    }

    if (validateAll === true) {
      const validateShopLogistic = await this.validateShopLogistic()
      if (!validateShopLogistic) validateAll = false
    }

    if (validateAll === true && mode === 'UPDATE') {
      const validateStock = await this.validateStock()
      if (!validateStock) {
        this.openAlertModal('ไม่สามารถอัพเดทสต็อกได้')
        validateAll = false
      }
    }
    return validateAll
    //     resolve(validateAll)
    //   } catch (error) {
    //     reject(false)
    //   }
    // })
  }

  validateShopLogistic = () => {
    const logistic = this.state.logisticList.filter(
      (item) => item.enable === true && item.rateType === 'custom',
    )
    let validate = true
    const errCode = []
    logistic.map((item, idx) => {
      if (
        Object.keys(item.shopLogisticSelected).length === 0 &&
        item.rateType === 'custom'
      ) {
        errCode.push(item.code)
        validate = false
      }
    })
    this.setState({ errShopLogistic: errCode })
    return validate
  }

  updateProduct = () => {
    const skusImageFile = this.state.productSkuFileImages.map((element) =>
      Object.assign({}, element),
    )
    const { skus, currentProduct } = this.state
    const shopId = currentProduct.shopId
    const productId = currentProduct.id
    let skuParam = [...skus]

    /* NOTE - check product sku is update (create new tag or delete tag) skuId is exist = does not create new tag or delete tag */
    const checkProductSkuUpdate = skus.filter((_sku) => _sku.skuId)

    /* NOTE - provide product skus param for update */
    if (checkProductSkuUpdate.length > 0) {
      skuParam = [...skus].reduce((prev, current) => {
        /* NOTE - check product status change for update product sku status */
        if (this.state.currentProduct.status !== this.props.product.value.status)
          current.status = this.props.product.value.status

        /* NOTE - remove priceCost */
        if (current.hasOwnProperty('priceCost')) delete current.priceCost;

        /* NOTE - assign sharingshop */
        current.sharingPlatform = Number(this.state.sharingPlatform)
        current.sharingShop = 100 - Number(this.state.sharingPlatform)

        /* NOTE - only old product that have skuId is allow to update stock */
        if (current.skuId) {
          if (current.updateStock.value) {
            const stockRemain = Number(current?.updateStock?.value)
            current.stockRemain =
              current?.updateStock?.mode === 'ADD'
                ? stockRemain
                : stockRemain * -1
          } else delete current.stockRemain
        }

        return [...prev, current]
      }, [])
    }

    skuParam = skuParam.map((current) => {
      /* NOTE - assign sharingshop */
      current.sharingPlatform = Number(this.state.sharingPlatform)
      current.sharingShop = 100 - Number(this.state.sharingPlatform)
      return current
    })

    /* NOTE - provide product logistic param for update */
    const productLogistic = [...this.state.logisticList].reduce(
      (prev, current) => {
        const matchProductLogisticUpdate = this.state.productLogisticList.find(
          (item) => item.logisticId === current.id,
        )

        let logistic = {}
        /* NOTE - exist item update (item get from api get product logistic */
        if (matchProductLogisticUpdate) {
          logistic = {
            rateType: current.rateType,
            status: current.enable ? 'show' : 'hide',
            logisticId: current.id,
            shopId: shopId,
            productId: productId,
            productLogisticId: matchProductLogisticUpdate.id, // old item have productLogisticId
            shopLogisticId:
              current.rateType == 'custom'
                ? current.shopLogisticSelected?.id
                : null,
          }
        } else {
          /* NOTE - new item update */
          if (current.enable) {
            logistic = {
              rateType: current.rateType,
              status: 'show',
              logisticId: current.id,
              shopId: shopId,
              productId: productId,
              shopLogisticId:
                current.rateType == 'custom'
                  ? current.shopLogisticSelected?.id
                  : null,
            }
          }
        }

        // if (current.rateType === 'custom') logistic.price = current.price
        if (current.rateType === 'standard') delete logistic.shopLogisticId

        return Object.keys(logistic).length > 0 ? [logistic].concat(prev) : prev
      },
      [],
    )

    /* NOTE - get product sku for delete */
    const deleteSkus = this.getProductSkuForDelete()

    this.props.dispatch(
      dispatchProduct({
        type: UPDATE_PRODUCT_BY_ID,
        payload: {
          productId,
          productLogistic,
          skus: skuParam,
          skusImageFile,
          deleteSkus,
        },
      }),
    )
  }

  getProductSkuForDelete = () => {
    try {
      const oldSkus = JSON.parse(
        JSON.stringify(this.props.product.currentProduct.productSkusCMS),
      )
      const newSkus = JSON.parse(JSON.stringify(this.state.skus)).filter(
        (sku) => sku.skuId !== undefined,
      )

      const deleteSkus = oldSkus.filter(
        ({ id: sku1 }) => !newSkus.some(({ skuId: sku2 }) => sku1 === sku2),
      )
      return deleteSkus
    } catch (error) {
      throw error
    }
  }

  checkWhiteSpacePermalink = (e) => {
    const permalink = e.target.value
    permalink.replace(/\s/g, '-')
    this.props.dispatch(
      dispatchProduct({
        type: ADD_SEO_IN_PRODUCT,
        payload: { key: 'permalink', value: permalink.replace(/\s/g, '-') },
      }),
    )
  }

  validateStock = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { productSelected } = this.props
        let skus = JSON.parse(JSON.stringify(this.state.skus))

        const _productProvider = new productProvider()
        const product = await _productProvider.getProductByProductId({
          productId: productSelected.id,
        })

        /* NOTE - update stock remain in skus */
        skus = skus.map((current, _index) => {
          if (current.skuId) {
            const productSku = product.data.productSkusCMS.find(
              ({ id: skuId }) => skuId === current.skuId,
            )
            if (productSku) current.stockRemain = productSku.stockRemain
          }
          return current
        })

        /* NOTE - validate stock remain from current database */
        const validateStockRemain = skus.reduce((prev, current, index) => {
          let validateStockRemain = false
          if (
            current.updateStock.mode === 'REDUCE' &&
            current.updateStock.value
          )
            validateStockRemain =
              current.stockRemain < current.updateStock.value

          return [...prev, validateStockRemain]
        }, [])
        this.setState({ validateStockRemain, skus })
        resolve(
          !validateStockRemain.filter((current) => current === true).length > 0,
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  openAlertModal = (message) => {
    this.props.dispatch(
      dispatchModalAlert({
        type: OPEN_MODAL_ALERT,
        payload: {
          isOpen: true,
          message: message,
          type: 'alert',
        },
      }),
    )
  }

  findValueShopLogistic = (code) => {
    const { providerShopLogistic } = this.props
    const findCode =
      providerShopLogistic &&
      providerShopLogistic.find((item, idx) => item[0]?.logisticCode === code)
    const newList = []
    findCode &&
      findCode.map((elem, idx) => {
        newList.push({
          label: elem.name,
          value: elem,
        })
      })
    return newList
  }

  onChangeShopLogistic = (logistic, idx, code) => {
    const data = this.findValueShopLogistic(code)
    let listLogistic = this.state.logisticList
    listLogistic[idx].shopLogisticSelected = data[logistic]?.value
    this.setState({
      logisticList: listLogistic,
    })
  }

  findIndexShopLogistic = (code, logistic) => {
    const data = this.findValueShopLogistic(code)
    const res = data.findIndex(
      (item) => item.value?.id == logistic.shopLogisticSelected?.id,
    )
    if (res !== -1) return res
  }

  render() {
    /* ANCHOR Render */
    const { Option } = Select
    const {
      shopId,
      userId,
      status,
      name,
      nameEn,
      saleType,
      unitType,
      seo,
      categories,
      brand,
      description,
      prepareDay,
    } = this.props.product.value
    const { isOpen } = this.props.product.modalAddProduct
    const {
      editorState,
      skus,
      optionDetail,
      optionProductDetail,
      listCategories,
      err,
      productSkuFileImages,
      logisticList,
      enableProductSkuOption,
      enableLogisticOption,
      sku,
      categorySelected,
      validateStockRemain,
      sharingPlatform,
      errShopLogistic,
      providerShopLogistic,
    } = this.state
    const { mode } = this.props

    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={this.toggleModal}
          backdrop='static'
          style={{ width: '80%', maxWidth: '80%' }}
        >
          <ModalBody>
            <Loading />
            <div className='close-btn-wrap'>
              <img src={IconClose} onClick={this.toggleModal} />
            </div>
            <div className='add-product-container m-auto'>
              {mode === 'UPDATE' && <h3>แก้ไขสินค้า</h3>}
              {mode === 'ADD' && <h3>เพิ่มสินค้าใหม่</h3>}
              {mode === 'VIEW' && <h3>ดูรายละเอียด</h3>}

              {/* <div className="col-12 bg-white"> */}
              <div className='bg-white'>
                <div className='seach-shop col-9 ml-10per'>
                  <h5 className='mr-3 m-0'>เลือกร้านค้า</h5>
                  <label style={{ width: '60%' }}>
                    <DropDown
                      onChange={this.onSelectShop}
                      options={this.state.listShop?.listShopName}
                      placeholder={'เลือกร้านค้า'}
                      value={this.state.shopSelected?.value?.name}
                      selectedIndex={this.state.shopSelectedIndex}
                      className={'add-product-select'}
                      disabled={
                        mode === 'UPDATE' || mode === 'VIEW' ? true : false
                      }
                    ></DropDown>
                  </label>
                </div>

                {/* </div> */}
              </div>

              {/* ANCHOR - product status */}
              <div className='bg-white'>
                <div className='seach-shop ml-10per d-flex align-items-end'>
                  <h5 className='mr-3 p-0 m-0'>สถานะการขาย</h5>
                  <div className='radio-btn'>
                    {mode === 'VIEW' && (
                      <React.Fragment>
                        {status === 'show' && (
                          <label htmlFor='opt1' className='radio'>
                            <input
                              type='radio'
                              name='rdo'
                              id='opt1'
                              className='hidden'
                              value={'show'}
                              onChange={this.onChangeStatusShop}
                              checked={status === 'show'}
                            />
                            <span className='label'></span>ขายอยู่
                          </label>
                        )}

                        {status !== 'show' && (
                          <label htmlFor='opt2' className='radio'>
                            <input
                              type='radio'
                              name='rdo'
                              id='opt2'
                              className='hidden'
                              value={'hide'}
                              onChange={this.onChangeStatusShop}
                              checked={status !== 'show'}
                            />
                            <span className='label'></span>ไม่ขายแล้ว
                          </label>
                        )}
                      </React.Fragment>
                    )}

                    {mode !== 'VIEW' && (
                      <React.Fragment>
                        <label htmlFor='opt1' className='radio'>
                          <input
                            type='radio'
                            name='rdo'
                            id='opt1'
                            className='hidden'
                            value={'show'}
                            onChange={this.onChangeStatusShop}
                            checked={status === 'show'}
                          />
                          <span className='label'></span>ขายอยู่
                        </label>

                        <label htmlFor='opt2' className='radio'>
                          <input
                            type='radio'
                            name='rdo'
                            id='opt2'
                            className='hidden'
                            value={'hide'}
                            onChange={this.onChangeStatusShop}
                            checked={status !== 'show'}
                          />
                          <span className='label'></span>ไม่ขายแล้ว
                        </label>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>

              {/* ANCHOR - product detail */}
              <div className='bg-white'>
                <div className='ml-10per col-10 pt-4 pb-4'>
                  <h5>ข้อมูลสินค้า</h5>

                  <div className='mt-2'>
                    <span>
                      ชื่อสินค้า <span className='text-danger'>*</span> (จำกัด
                      200 ตัวอักษร)
                    </span>
                    <span
                      className={classnames(`text-danger d-none`, {
                        'd-inline': err?.name,
                      })}
                    >
                      กรุณากรอกชื่อสินค้า
                    </span>
                    <input
                      type='text'
                      id='product-name'
                      name='productName'
                      className={`form-control ${err?.name ? 'error-field' : ''
                        }`}
                      value={name}
                      onChange={(e) =>
                        this.props.dispatch(
                          dispatchShop({
                            type: ADD_VALUE_IN_PRODUCT,
                            payload: { key: 'name', value: e.target.value },
                          }),
                        )
                      }
                    />
                  </div>

                  <div className='detail-product mt-4'>
                    <span>
                      รายละเอียดของสินค้า <span className='text-danger'>*</span>
                    </span>
                    <span
                      className={classnames(`text-danger d-none`, {
                        'd-inline': err?.description,
                      })}
                    >
                      กรุณากรอกรายละเอียดของสินค้า
                    </span>
                    {/* <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onEditorStateChange}
                                    /> */}
                    <Editor
                      html={description}
                      onChange={this.onEditorChange}
                      readOnly={mode === 'VIEW'}
                    />
                  </div>

                  <div className=''>
                    <div className='d-flex flex-row justify-content-between mt-4'>
                      <div className='col-5 p-0'>
                        <span>ยี่ห้อ</span>
                        <input
                          type='text'
                          list='brands'
                          name='list_brands'
                          className='form-control'
                          id='list-brands'
                          onChange={(e) => this.onChangeBrand(e)}
                          value={brand}
                        />
                        <datalist id='brands' className='asd'>
                          {this.state.listAllBrands.map((item, idx) => (
                            <React.Fragment key={'bandIndex' + idx}>
                              {item.label
                                .toLowerCase()
                                .substring(
                                  0,
                                  this.props.product.value.brand.length,
                                ) ===
                                this.props.product.value.brand.toLowerCase() && (
                                  <option>{item.label}</option>
                                )}
                            </React.Fragment>
                          ))}
                        </datalist>
                        {/* <input type="text" className="form-control" onChange={(e) => this.props.dispatch(dispatchProduct({ type: ADD_VALUE_IN_PRODUCT, payload: { key: 'brand', value: e.target.value } }))} /> */}
                      </div>

                      <div className='col-5 p-0 align-items-end'>
                        <span>
                          หมวดหมู่ <span className='text-danger'>*</span>
                        </span>
                        <span
                          className={classnames(`text-danger d-none`, {
                            'd-inline': err?.categories,
                          })}
                        >
                          กรุณาเลือกหมวดหมู่
                        </span>
                        <label htmlFor='หมวดหมู่' style={{ width: '100%' }}>
                          <DropDown
                            onChange={this.onChange}
                            options={this.state.listCategories}
                            placeholder={'เลือกหมวดหมู่'}
                            // selectedIndex={this.state.listShop.SelectedShop.index}
                            className={'add-product-select'}
                            onChange={this.onChangeCategory}
                            value={categorySelected}
                            disabled={mode === 'VIEW' ? true : false}
                          ></DropDown>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-5 p-0 mt-2">
                                        <span>รหัสสินค้า (SKU)  <span className="text-danger">*</span></span>
                                        <input type="text" className="form-control" />
                                    </div> */}
                    {(getUserRole() === 'SUPER_ADMIN' ||
                      getUserRole() === 'OWNER') && (
                        <div className='d-flex flex-row justify-content-between mt-4'>
                          <div className='col-5 p-0'>
                            <span>GP </span>
                            <div className='input-group'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text'> % </span>
                              </div>
                              {/* <input
                              type='number'
                              className={`form-control void ${ sku?.errors?.height ? 'error-field' : '' }`}
                              name='sharingPlatform'
                              placeholder='0'
                              value={sharingPlatform}
                              disabled={
                                getUserRole() === 'SUPER_ADMIN' ? false : true
                              }
                              onChange={(e) => this.onChangeSharingShop(e)}
                            /> */}
                              <InputNumber
                                value={sharingPlatform}
                                name={'sharingPlatform'}
                                className={`form-control`}
                                type={'CURRENCY'}
                                placeholder={'0.00'}
                                onChange={this.onChangeSharingShop}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              {/* ANCHOR - product picture */}
              <div className='image-live-container p-0 pb-4 pt-4'>
                <div className='upload ml-10per col-10'>
                  <h5>
                    ภาพสินค้า <span className='text-danger'>*</span>
                  </h5>
                  <span
                    className={classnames(`text-danger d-none`, {
                      'd-inline': err?.pathImage,
                    })}
                  >
                    กรุณาใส่ภาพสินค้า
                  </span>
                  <p className='upload-detail'>
                    รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส)
                    ขนาดรูปแนะนำคือ 640 x 640 พิกเซล ขนาดไฟล์สูงสุด 10 MB
                  </p>

                  <div className='d-flex flex-wrap'>
                    {mode !== 'VIEW' && (
                      <React.Fragment>
                        <ButtonUpload
                          width='12em'
                          height='12em'
                          type='image'
                          shopDetail={{
                            shopId: this.state.shopSelected?.value?.id,
                          }}
                          disabled={!this.state.shopSelected?.value?.id}
                          fieldUpload='productUploadEvent'
                          limitImageUpload={10}
                        />
                      </React.Fragment>
                    )}
                    <ImageSortable
                      mode={mode}
                      data={this.props.listImageProduct}
                      onSortLists={(value) =>
                        this.onSortLists(value, 'productUploadEvent')
                      }
                      onRemoveImage={(value, index) => {
                        this.onClickRemoveImage(
                          index,
                          'listImageProduct',
                          'productUploadEvent',
                        )
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* ANCHOR - product video */}
              <div className='image-live-container p-0 pb-4 pt-4'>
                <div className='upload ml-10per col-10'>
                  <h5>
                    วิดีโอสินค้า <span className='text-danger'>*</span>
                  </h5>
                  <span
                    className={classnames(`text-danger d-none`, {
                      'd-inline': err?.pathImage,
                    })}
                  >
                    กรุณาใส่ภาพสินค้า
                  </span>
                  <p className='upload-detail'>
                    รองรับสัดส่วน วิดีโอ 1280x720 หรือ 1920x1080
                    ขนาดไฟล์สูงสุด 2 GB
                  </p>

                  <div className='d-flex flex-wrap'>
                    {mode !== 'VIEW' && (
                      <React.Fragment>
                        <ButtonUpload
                          width='12em'
                          height='12em'
                          type='video'
                          shopDetail={{
                            shopId: this.state.shopSelected?.value?.id,
                          }}
                          disabled={!this.state.shopSelected?.value?.id}
                          fieldUpload='productUploadEventVideo'
                          limitImageUpload={10}
                        />
                      </React.Fragment>
                    )}
                    <ImageSortable
                      mode={mode}
                      data={this.props.listVideoProduct}
                      onSortLists={(value) =>
                        this.onSortLists(value, 'productUploadEventVideo')
                      }
                      onRemoveImage={(value, index) => {
                        this.onClickRemoveImage(
                          index,
                          'listVideoProduct',
                          'productUploadEventVideo',
                        )
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* ANCHOR - manage seo */}
              <div className='mt-4 bg-white'>
                <div className='ml-10per pt-4 pb-4 col-10 seo-manage'>
                  <h5>จัดการ SEO</h5>
                  <div className='d-flex justify-content-between'>
                    {/* <span>Meta Title <span className="text-danger">*</span> (20 ตัวอักษรขึ้นไป) </span>
                                    <input type="text" className="form-control" onChange={(e) => this.props.dispatch(dispatchShop({ type: ADD_SEO_IN_PRODUCT, payload: { key: 'title', value: e.target.value } }))} /> */}
                    <div>
                      <span>
                        Meta Title <span className='text-danger'>*</span>
                      </span>
                      <span
                        className={classnames(`text-danger d-none`, {
                          'd-inline': err?.seoTitle,
                        })}
                      >
                        กรุณากรอก Title SEO
                      </span>
                    </div>
                    <span>{this.checkSeoTitle()}</span>
                  </div>
                  <input
                    type='text'
                    className={`form-control ${err?.seoTitle ? 'error-field' : ''
                      }`}
                    value={seo.title}
                    name='title_description'
                    onChange={(e) =>
                      this.props.dispatch(
                        dispatchShop({
                          type: ADD_SEO_IN_PRODUCT,
                          payload: { key: 'title', value: e.target.value },
                        }),
                      )
                    }
                  />
                  <div>
                    <div className='d-flex justify-content-between'>
                      <span>
                        Meta Description <span className='text-danger'>*</span>
                        <span
                          className={classnames(`text-danger d-none`, {
                            'd-inline': err?.seoDescription,
                          })}
                        >
                          กรุณากรอกรายละเอียด SEO
                        </span>
                      </span>
                      <span> {this.checkSeoDescription()}</span>
                    </div>
                    {/* <span>Meta Description <span className="text-danger">*</span> </span> */}
                    {/* <textarea type="text" className="form-control" style={{ height: "10em", resize: 'none' }} onChange={(e) => this.props.dispatch(dispatchShop({ type: ADD_SEO_IN_PRODUCT, payload: { key: 'description', value: e.target.value } }))} /> */}
                    <textarea
                      type='text'
                      name='meta-description'
                      className={`form-control ${err?.seoDescription ? 'error-field' : ''
                        }`}
                      value={seo.description}
                      style={{ height: '10em', resize: 'none' }}
                      onChange={(e) =>
                        this.props.dispatch(
                          dispatchShop({
                            type: ADD_SEO_IN_PRODUCT,
                            payload: {
                              key: 'description',
                              value: e.target.value,
                            },
                          }),
                        )
                      }
                    />
                  </div>

                  <div>
                    <span>Keyword </span>
                    <span className='color-grey'>
                      พิมพ์และกดปุ่ม Enter เพื่อสร้างตัวเลือก
                    </span>
                    <ReactTags
                      classNames={reactTagClass}
                      autofocus={false}
                      autoresize={false}
                      allowNew={true}
                      minQueryLength={0}
                      maxSuggestionsLength={20}
                      tags={this.state.tags}
                      placeholderText={''}
                      onDelete={(e) => this.onDelete(e)}
                      onAddition={(e) => this.onAddition(e)}
                    />
                  </div>

                  <div
                    className='image-live-container d-flex flex-column'
                    style={{ alignItems: 'start' }}
                  >
                    <span>รูปภาพสำหรับแชร์</span>
                    <span
                      className={classnames(`text-danger d-none`, {
                        'd-inline': err?.seoSeoImage,
                      })}
                    >
                      กรุณาใส่รูปภาพสำหรับแชร์
                    </span>
                    <div className='d-flex flex-wrap'>
                      {this.props.listImageSEO?.length ? (
                        this.props.listImageSEO.map(
                          (item, listImageSEOIndex) => (
                            <span
                              className='item-product-container'
                              key={`listImageSEOIndex-${listImageSEOIndex}`}
                            >
                              <img
                                src={host.image + item?.path}
                                alt=''
                                className='image'
                                style={{ width: '100%' }}
                              />
                              <div
                                className='middle'
                                onClick={() =>
                                  this.onClickRemoveImage(
                                    listImageSEOIndex,
                                    'listImageSEO',
                                    'seoUploadEvent',
                                  )
                                }
                              >
                                {mode !== 'VIEW' && (
                                  <img
                                    alt=''
                                    src={closeImage}
                                    className='position-absolute remove-image-product'
                                  />
                                )}
                              </div>
                            </span>
                          ),
                        )
                      ) : (
                        <ButtonUpload
                          width='12em'
                          height='12em'
                          type='image'
                          shopDetail={{
                            shopId: this.state.shopSelected?.value?.id,
                          }}
                          disabled={!this.state.shopSelected?.value?.id}
                          fieldUpload='seoUploadEvent'
                          limitImageUpload={1}
                        />
                      )}

                      {/* <input
                        type='file'
                        name='fileinput'
                        name='seoImage'
                        onChange={this.onSelectFile}
                        ref={(fileinput) => (this.seofileinput = fileinput)}
                        style={{ display: 'none' }}
                      /> */}
                      {/* <div style={{ maxWidth: '12em', maxHeight: '12em' }}>
                        {this.props.product.seoImage.length > 0 ? (
                          <div className='position-relative'>
                            
                            <div className='item-product-container'>
                              <img
                                src={
                                  this.props.product.seoImage[0]?.fileImage
                                    ?.file
                                }
                                alt='item'
                                className='image'
                              />
                              <div className='middle'>
                                <img
                                alt=''
                                  src={closeImage}
                                  className='position-absolute remove-image-product cursor-pointer'
                                  onClick={() =>
                                    this.props.dispatch(
                                      dispatchProduct({
                                        type: REMOVE_SEO_IMAGE,
                                      }),
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <IconCamera
                              className='edit-profile-seo position-absolute cursor-pointer'
                              onClick={() => this.seofileinput.click()}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div> */}
                      {/* {this.props.product.seoImage.length == 0 ? (
                        <button
                          className='btn-upload-image'
                          onClick={() => this.seofileinput.click()}
                        >
                          <FontAwesomeIcon
                            icon={faCamera}
                            className='icon-camera position-relative'
                          />
                          <br />
                          เพิ่มรูปภาพ
                        </button>
                      ) : (
                        ''
                      )} */}
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                      <span>
                        Permarlink <span className='text-danger'>*</span>
                        <span
                          className={classnames(`text-danger d-none`, {
                            'd-inline': err?.seoPermalink,
                          })}
                        >
                          กรุณากรอก Permarlink
                        </span>
                        <span
                          className={classnames(`text-danger d-none`, {
                            'd-inline': err?.seoPermalinkFormat,
                          })}
                        >
                          รูปแบบของ Permarlink ไม่ถูกต้อง
                        </span>
                      </span>
                      <span>
                        แสดงเป็น :
                        <span style={{ color: 'orange' }}>
                          https://www.outletlive.com/product/{seo.permalink}
                        </span>
                      </span>
                    </div>
                    <input
                      type='text'
                      className={`form-control ${err?.seoPermalink || err?.seoPermalinkFormat
                        ? 'error-field'
                        : ''
                        }`}
                      value={seo.permalink}
                      onChange={this.checkWhiteSpacePermalink}
                    />
                  </div>
                </div>
              </div>

              {/* ANCHOR - SKU product option on */}
              <div className='bg-white'>
                <div className='ml-10per pb-4 pt-4 col-10'>
                  <div className='d-flex justify-content-between'>
                    <h5>เพิ่มตัวเลือกสินค้า</h5>
                    <div
                      className='toggle-green d-flex align-items-center'
                      style={{ gap: '10px' }}
                    >
                      <label className='switch'>
                        <input
                          type='checkbox'
                          name='enableProductSkuOption'
                          checked={enableProductSkuOption}
                          defaultChecked={false}
                          onChange={this.onOptionStateChange}
                        />
                        <span className='slider round'></span>
                      </label>
                      <span>เพิ่มตัวเลือกสินค้า</span>
                    </div>
                  </div>
                  <div className='mb-4'>
                    เพิ่มตัวเลือกสินค้าได้ หากสินค้านี้มีหลายตัวเลือกให้ระบุ
                    เช่น สี ขนาด วัสดุ
                  </div>

                  {enableProductSkuOption && (
                    <React.Fragment>
                      {optionDetail.map((item, index) => (
                        <div
                          className='d-flex flex-column align-items-end justify-content-between'
                          key={`optionDetail_${index}`}
                        >
                          <div className='row w-100 option-detail mb-4'>
                            <div className='col-6'>
                              {index < 1 && (
                                <span>
                                  ชื่อตัวเลือกสินค้า
                                  <span className='text-danger'>*</span>
                                  <span className='title-detail'>
                                    (เช่น สี ขนาด)
                                  </span>
                                </span>
                              )}
                              <input
                                type='text'
                                id='product-option-name'
                                name='product-option-name'
                                className={`form-control ${item?.errors?.name ? 'error-field' : ''
                                  }`}
                                value={item.name}
                                onChange={(e) =>
                                  this.onChangeOptionDetailName(index, e)
                                }
                              />
                              <p>
                                <span className='text-danger'>
                                  {item?.errors?.name && item?.errors?.name}
                                </span>
                              </p>
                            </div>
                            <div className='col-6'>
                              {index < 1 && (
                                <span>
                                  รายละเอียดตัวเลือก
                                  <span className='text-danger'>*</span>
                                  <span className='title-detail'>
                                    พิมพ์และกดปุ่ม Enter เพื่อสร้างตัวเลือก
                                  </span>
                                </span>
                              )}
                              <div className='row w-100'>
                                <div className='col-11'>
                                  <ReactTags
                                    classNames={reactTagClassTextField}
                                    autofocus={false}
                                    autoresize={false}
                                    allowNew={true}
                                    minQueryLength={0}
                                    maxSuggestionsLength={20}
                                    tags={item.tag}
                                    placeholderText={''}
                                    onDelete={(e) =>
                                      this.onDeleteOptionDetailTag(e, index)
                                    }
                                    onAddition={(e) =>
                                      this.onAddOptionDetailTag(e, index)
                                    }
                                  />
                                </div>
                                {mode !== 'VIEW' && (
                                  <div className='col-1'>
                                    {optionDetail.length < 2 ? (
                                      <a onClick={this.onAddOptionDetail}>
                                        <img
                                          src={IconPlus}
                                          alt='plus'
                                          style={{ width: '45px' }}
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() =>
                                          this.onDeleteOptionDetail(index)
                                        }
                                      >
                                        <img
                                          src={IconTrash}
                                          alt='plus'
                                          style={{ width: '45px' }}
                                        />
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                              <p>
                                <span className='text-danger'>
                                  {item?.errors?.tag && item?.errors?.tag}
                                </span>
                              </p>
                            </div>
                          </div>

                          {/* ANCHOR Sku product image */}
                          {index < 1 && (
                            <div className='row w-100 option-detail mb-4'>
                              <div className='image-live-container d-flex flex-column'>
                                <span className='title text-left'>
                                  อัพโหลดรูปภาพ
                                </span>
                                {/* <input type="file" name="fileinput" name="productImage" id="productImage" 
                                                                    onChange={(e) => this.onSelectSkuImage(e,idx)} ref={fileinputSku => this.fileinputSku = fileinputSku} 
                                                                    style={{ display: 'none' }} /> */}

                                <div className='d-flex flex-row flex-wrap'>
                                  {productSkuFileImages.map(
                                    (item, productSkuFileImagesIndex) => (
                                      <React.Fragment
                                        key={`productSkuFileImagesIndex-${productSkuFileImagesIndex}`}
                                      >
                                        <div className='product-sku-image-wrap '>
                                          <p>
                                            <span className='text-danger mt-2'>
                                              {item?.errors?.fileName &&
                                                item?.errors?.fileName}
                                            </span>
                                          </p>
                                          {/* <FileInput
                                            onGetImage={
                                              this.onModalUploadImageGetImage
                                            }
                                            onFileInputClick={() =>
                                              this.onFileInputClick(
                                                productSkuFileImagesIndex,
                                              )
                                            }
                                          > */}
                                          {item.fileImage ? (
                                            <div className='d-flex'>
                                              <span className='item-product-container'>
                                                <img
                                                  src={
                                                    host.image +
                                                    item.fileImage.path
                                                  }
                                                  alt=''
                                                  className='image'
                                                  style={{ width: '100%' }}
                                                />
                                                <div
                                                  className='middle'
                                                  onClick={() =>
                                                    this.onClickRemoveSKU(
                                                      productSkuFileImagesIndex,
                                                      'listImageSKU',
                                                      'skuUploadEvent',
                                                      item,
                                                    )
                                                  }
                                                >
                                                  {mode !== 'VIEW' && (
                                                    <img
                                                      alt=''
                                                      src={closeImage}
                                                      className='position-absolute remove-image-product'
                                                    />
                                                  )}
                                                </div>
                                              </span>
                                            </div>
                                          ) : (
                                            <ButtonUpload
                                              width='12em'
                                              height='12em'
                                              type='image'
                                              shopDetail={{
                                                shopId: this.state.shopSelected
                                                  .value.id,
                                              }}
                                              disabled={
                                                !this.state.shopSelected.value
                                                  .id
                                              }
                                              fieldUpload='skuUploadEvent'
                                              onBrowse={() =>
                                                this.setState({
                                                  indexAddImage: productSkuFileImagesIndex,
                                                })
                                              }
                                              limitImageUpload={1}
                                            // limitSelect={1}
                                            />
                                          )}

                                          {/* </FileInput> */}
                                          <p>
                                            {typeof optionDetail[0] !==
                                              'undefined' &&
                                              typeof optionDetail[0].tag !==
                                              'undefined' &&
                                              typeof optionDetail[0].tag[
                                              productSkuFileImagesIndex
                                              ] !== 'undefined'
                                              ? optionDetail[0].tag[
                                                productSkuFileImagesIndex
                                              ].name
                                              : ''}
                                          </p>
                                        </div>
                                      </React.Fragment>
                                    ),
                                  )}
                                </div>

                                {/* <div className='d-flex flex-row flex-wrap'>
                                  {this.props.listImageSKU?.map(
                                    (item, index) => (
                                      <MediaCard
                                        statusAction='close'
                                        data={item}
                                        index={index}
                                        key={item.path}
                                        onClickRemoveImage={() => {}}
                                      />
                                    ),
                                  )}
                                  <ButtonUpload
                                    width='8em'
                                    height='8em'
                                    type='image'
                                    shopDetail={{
                                      shopId: this.state.shopSelected?.value.id,
                                    }}
                                    disabled={
                                      !this.state.shopSelected?.value.id
                                    }
                                    fieldUpload='productUploadEvent'
                                  />
                                </div> */}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </div>

                {enableProductSkuOption && (
                  <React.Fragment>
                    <div className='d-block' style={{ paddingBottom: '2em' }}>
                      <div
                        className='pb-4'
                        style={{
                          marginLeft: '4em',
                          marginRight: '4em',
                          backgroundColor: '#F9F9F9',
                        }}
                      >
                        {mode !== 'VIEW' && (
                          <div style={{ padding: '2em 5.5em' }}>
                            <h5>รายละเอียดตัวเลือกสินค้า</h5>

                            <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>

                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>ราคาปกติ</span>
                                    <span className='title-detail'>
                                      ราคาตั้งต้นก่อนลดราคา
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>฿</span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.priceList}
                                        name={'priceList'}
                                        className={`form-control`}
                                        type={'CURRENCY'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <span className='title-'>ราคาที่ประกาศขาย</span>
                                    <span className='title-detail'>
                                      ราคาที่ลูกค้าจะซื้อจริง
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>฿</span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.priceNet}
                                        name={'priceNet'}
                                        className={`form-control`}
                                        type={'CURRENCY'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  {/* <div className='d-flex flex-column'>
                                  <span className='title'>ราคาส่ง</span>
                                  <span className='title-detail'>
                                    ราคาต้นทุนของสินค้า
                                  </span>
                                  <div className='input-group'>
                                    <div className='input-group-prepend'>
                                      <span className='input-group-text'>฿</span>
                                    </div>
                                    <InputNumber
                                      value={optionProductDetail.priceCost}
                                      name={'priceCost'}
                                      className={`form-control`}
                                      type={'CURRENCY'}
                                      placeholder={'0.00'}
                                      onChange={this.onOptionProductDetailChange}
                                    />
                                  </div>
                                </div> */}
                                  <div className='d-flex flex-column w-100'>
                                    <span className='title'>สต็อก</span>
                                    <span className='title-detail'>
                                      จำนวนสินค้าพร้อมขาย
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'></span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.stockRemain}
                                        name={'stockRemain'}
                                        className={`form-control`}
                                        type={'COMMON'}
                                        placeholder={'0'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>

                            <div className='d-flex flex-row align-items-end row-input' style={{ gap: '50px' }}>

                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>น้ำหนักสินค้า</span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          กก.
                                        </span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.weight}
                                        name={'weight'}
                                        className={`form-control`}
                                        type={'DECIMAL'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>
                                      กว้าง (กล่องพัสดุ)
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.width}
                                        name={'width'}
                                        className={`form-control`}
                                        type={'CURRENCY'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>ยาว (กล่องพัสดุ)</span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.length}
                                        name={'length'}
                                        className={`form-control`}
                                        type={'CURRENCY'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>สูง (กล่องพัสดุ)</span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      <InputNumber
                                        value={optionProductDetail.height}
                                        name={'height'}
                                        className={`form-control`}
                                        type={'CURRENCY'}
                                        placeholder={'0.00'}
                                        onChange={this.onOptionProductDetailChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <br />
                            <div className='row d-flex justify-content-end'>
                              <button
                                className='btn-cancel align-self-end float-right mt-1'
                                style={{ width: '12em' }}
                                onClick={
                                  this.updateOptionProductDetailWithAllProduct
                                }
                              >
                                อัปเดตกับสินค้าทั้งหมด
                              </button>
                            </div>
                          </div>
                        )}

                        <br></br>

                        {skus.length > 0 &&
                          skus.map((item, idx) => (
                            <div
                              className=''
                              style={{ margin: '0 4em' }}
                              key={idx}
                            >
                              <div
                                className='mt-4 d-block bg-white'
                                key={idx}
                                style={{ padding: '1em 1.5em' }}
                              >
                                <h5>ตัวเลือกสินค้า {idx + 1} :</h5>
                                <div className='d-flex flex-row options-product'>
                                  <div className='d-flex flex-column justify-content-between'>

                                    <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>

                                      <div className='d-flex flex-column w-100'>
                                        <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              ราคาปกติ
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <span className='title-detail'>
                                              ราคาตั้งต้นก่อนลดราคา
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  ฿
                                                </span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.priceList ? 'error-field' : ''}`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='priceList'
                                            placeholder='0'
                                            value={item.priceList}
                                            onChange={(e) =>
                                              this.onSkusChange(e, idx)
                                            }
                                          /> */}

                                              <InputNumber
                                                value={item.priceList}
                                                name={'priceList'}
                                                type={'CURRENCY'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusChange}
                                              />
                                            </div>
                                            {item?.errors?.priceList && (
                                              <span className='text-danger'>
                                                {item?.errors?.priceList}
                                              </span>
                                            )}
                                          </div>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              ราคาที่ประกาศขาย
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <span className='title-detail'>
                                              ราคาที่ลูกค้าจะซื้อจริง
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  ฿
                                                </span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.priceNet ? 'error-field' : ''}`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='priceNet'
                                            placeholder='0'
                                            value={item.priceNet}
                                            onChange={(e) =>
                                              this.onSkusChange(e, idx)
                                            }
                                          /> */}
                                              <InputNumber
                                                value={item.priceNet}
                                                name={'priceNet'}
                                                type={'CURRENCY'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusChange}
                                              />
                                            </div>
                                            {item?.errors?.priceNet && (
                                              <span className='text-danger'>
                                                {item?.errors?.priceNet}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='d-flex flex-column w-100'>
                                        <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                          {/* <div className='d-flex flex-column'>
                                          <span className='title'>
                                            ราคาส่ง
                                            <span className='text-danger'>*</span>
                                          </span>
                                          <span className='title-detail'>
                                            ราคาต้นทุนของสินค้า
                                          </span>
                                          <div className='input-group'>
                                            <div className='input-group-prepend'>
                                              <span className='input-group-text'>
                                                ฿
                                              </span>
                                            </div>
                                            <InputNumber
                                              value={item.priceCost}
                                              name={'priceCost'}
                                              type={'CURRENCY'}
                                              className={`form-control ${
                                                item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                              }`}
                                              placeholder={'0.00'}
                                              params={{ index: idx }}
                                              onChange={this.onSkusChange}
                                            />
                                          </div>
                                          {item?.errors?.priceCost && (
                                            <span className='text-danger'>
                                              {item?.errors?.priceCost}
                                            </span>
                                          )}
                                        </div> */}
                                          <div className='d-flex flex-column w-100'>
                                            <span className='title'>
                                              สต็อก
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <span className='title-detail'>
                                              จำนวนสินค้าพร้อมขาย
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'></span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.stockRemain ? 'error-field' : ''} stock-remain`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='stockRemain'
                                            placeholder='0'
                                            value={item.stockRemain}
                                            disabled={item.skuId !== undefined}
                                            onChange={(e) =>
                                              this.onSkusChange(e, idx)
                                            }
                                          /> */}
                                              <InputNumber
                                                value={item.stockRemain}
                                                name={'stockRemain'}
                                                className={`form-control 
                                                          ${item?.errors
                                                    ?.stockRemain
                                                    ? 'error-field'
                                                    : ''
                                                  } 
                                                          ${item.skuId !==
                                                    undefined
                                                    ? 'stock-remain'
                                                    : ''
                                                  }
                                                        `}
                                                type={'COMMON'}
                                                placeholder={'0'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusChange}
                                              />
                                            </div>
                                            {item?.errors?.stockRemain && (
                                              <span className='text-danger'>
                                                {item?.errors?.stockRemain}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                    <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>
                                      <div className='d-flex flex-column w-100'>
                                        <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              น้ำหนักสินค้า
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  กก.
                                                </span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.weight ? 'error-field' : ''}`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='weight'
                                            placeholder='0.00'
                                            value={item.weight}
                                            onChange={(e) =>
                                              this.onSkusChange(e, idx)
                                            }
                                          /> */}
                                              <InputNumber
                                                value={item.weight}
                                                name={'weight'}
                                                type={'DECIMAL'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusChange}
                                              />
                                            </div>
                                            {item?.errors?.weight && (
                                              <span className='text-danger'>
                                                {item?.errors?.weight}
                                              </span>
                                            )}
                                          </div>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              กว้าง (กล่องพัสดุ)
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  ซม.
                                                </span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.width ? 'error-field' : ''}`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='width'
                                            placeholder='0'
                                            value={item.size.width}
                                            onChange={(e) =>
                                              this.onSkusSizeChange(e, idx)
                                            }
                                          /> */}
                                              <InputNumber
                                                value={item.size.width}
                                                name={'width'}
                                                type={'CURRENCY'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusSizeChange}
                                              />
                                            </div>
                                            {item?.errors?.width && (
                                              <span className='text-danger'>
                                                {item?.errors?.width}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='d-flex flex-column w-100'>
                                        <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              ยาว (กล่องพัสดุ)
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  ซม.
                                                </span>
                                              </div>
                                              {/* <input
                                            type='number'
                                            className={`form-control ${item?.errors?.length ? 'error-field' : ''}`}
                                            aria-label='Amount (to the nearest dollar)'
                                            name='length'
                                            placeholder='0'
                                            value={item.size.length}
                                            onChange={(e) =>
                                              this.onSkusSizeChange(e, idx)
                                            }
                                          /> */}
                                              <InputNumber
                                                value={item.size.length}
                                                name={'length'}
                                                type={'CURRENCY'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusSizeChange}
                                              />
                                            </div>
                                            {item?.errors?.length && (
                                              <span className='text-danger'>
                                                {item?.errors?.length}
                                              </span>
                                            )}
                                          </div>
                                          <div className='d-flex flex-column'>
                                            <span className='title'>
                                              สูง (กล่องพัสดุ)
                                              <span className='text-danger'>*</span>
                                            </span>
                                            <div className='input-group'>
                                              <div className='input-group-prepend'>
                                                <span className='input-group-text'>
                                                  ซม.
                                                </span>
                                              </div>
                                              {/* <input
                                          type='number'
                                          className={`form-control ${item?.errors?.height ? 'error-field' : ''}`}
                                          aria-label='Amount (to the nearest dollar)'
                                          name='height'
                                          placeholder='0'
                                          value={item.size.height}
                                          onChange={(e) =>
                                            this.onSkusSizeChange(e, idx)
                                          }
                                        /> */}
                                              <InputNumber
                                                value={item.size.height}
                                                name={'height'}
                                                type={'CURRENCY'}
                                                className={`form-control ${item?.errors?.priceNet
                                                  ? 'error-field'
                                                  : ''
                                                  }`}
                                                placeholder={'0.00'}
                                                params={{ index: idx }}
                                                onChange={this.onSkusSizeChange}
                                              />
                                            </div>
                                            {item?.errors?.height && (
                                              <span className='text-danger'>
                                                {item?.errors?.height}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>

                                      <div className='d-flex flex-column' style={{ width: '50%' }} >
                                        <span className='title'>
                                          รหัสสินค้า (SKU)
                                          <span className='text-danger'>*</span>
                                        </span>

                                        <input
                                          type='text'
                                          className={`form-control ${item?.errors?.sku
                                            ? 'error-field'
                                            : ''
                                            } sku-code`}
                                          aria-label='Amount (to the nearest dollar)'
                                          name='sku'
                                          disabled={item.skuId !== undefined}
                                          value={item.sku}
                                          onChange={(e) =>
                                            this.onSkusChange(
                                              e.target.value,
                                              'sku',
                                              { index: idx },
                                            )
                                          }
                                        />
                                        {item?.errors?.sku && (
                                          <span className='text-danger'>
                                            {item?.errors?.sku}
                                          </span>
                                        )}
                                      </div>
                                      <div className='d-flex flex-column' style={{ width: '50%' }} >
                                        <span className='title'>
                                          รหัสบาร์โค้ด
                                          {/* <span className='text-danger'>*</span> */}
                                        </span>
                                        <input
                                          type='text'
                                          className='form-control'
                                        />
                                      </div>

                                    </div>

                                    {/* ANCHOR - stock update */}
                                    {/* NOTE - skuId exist = product SKU does not create new or delete */}

                                    {mode !== 'VIEW' &&
                                      item.skuId !== undefined &&
                                      skus.length > 0 &&
                                      skus.filter((_sku) => _sku.skuId).length >
                                      0 && (
                                        <div className='row'>
                                          <div className='col-7'>
                                            <span className='title'>
                                              อัปเดทสต็อก
                                            </span>
                                            <div className='d-flex flex-row mt-4'>
                                              <div
                                                className='d-flex flex-column mr-4'
                                                style={{ width: '25%' }}
                                              >
                                                <div className='update-stock'>
                                                  <div
                                                    className={`update-stock-item text-center ${item?.updateStock
                                                      ?.mode === 'ADD'
                                                      ? 'active'
                                                      : ''
                                                      }`}
                                                    onClick={() =>
                                                      this.switchUpdateStockMode(
                                                        idx,
                                                        'ADD',
                                                      )
                                                    }
                                                  >
                                                    เพิ่ม
                                                  </div>
                                                  <div
                                                    className={`update-stock-item text-center ${item?.updateStock
                                                      ?.mode === 'REDUCE'
                                                      ? 'active'
                                                      : ''
                                                      }`}
                                                    onClick={() =>
                                                      this.switchUpdateStockMode(
                                                        idx,
                                                        'REDUCE',
                                                      )
                                                    }
                                                    style={{
                                                      borderLeft: 'none',
                                                    }}
                                                  >
                                                    ลด
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className='d-flex flex-column'
                                                style={{
                                                  width: '50%',
                                                  marginLeft: '1rem',
                                                }}
                                              >
                                                {validateStockRemain &&
                                                  validateStockRemain[idx] ===
                                                  true && (
                                                    <div className='stock-remain-error-msg'>
                                                      สต็อคมีค่าน้อยกว่าค่าที่ต้องการลด!
                                                    </div>
                                                  )}
                                                <div className='input-group'>
                                                  <div className='input-group-prepend'>
                                                    <span className='input-group-text'></span>
                                                  </div>
                                                  {/* <input
                                                  type='number'
                                                  className={`form-control ${validateStockRemain && validateStockRemain[idx] === true ? 'error-field' : ''}`}
                                                  aria-label='Amount (to the nearest dollar)'
                                                  name='value'
                                                  placeholder='0'
                                                  value={item.updateStock.value}
                                                  onChange={(e) => this.onUpdateStockValueChange( e, idx, ) } /> */}
                                                  <InputNumber
                                                    value={
                                                      item.updateStock.value
                                                    }
                                                    name={'value'}
                                                    className={`form-control`}
                                                    type={'COMMON'}
                                                    placeholder={'0.00'}
                                                    params={{ index: idx }}
                                                    onChange={
                                                      this
                                                        .onUpdateStockValueChange
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className='col-5'
                                            style={{
                                              borderLeft: 'solid 3px #eaeaea',
                                            }}
                                          >
                                            <div className='remaining-stock'>
                                              <span className='title'>{`จำนวนสต็อกปัจจุบัน -> จำนวนสต็อกที่อัปเดท`}</span>
                                              <div className='d-flex flex-row mt-4 '>
                                                {(() => {
                                                  const mode =
                                                    item.updateStock.mode
                                                  const stock = item.stockRemain

                                                  let stockRemain =
                                                    Number(item.stockRemain) +
                                                    (mode === 'REDUCE'
                                                      ? Number(
                                                        item.updateStock
                                                          .value,
                                                      ) * -1
                                                      : Number(
                                                        item.updateStock
                                                          .value,
                                                      ))

                                                  if (isNaN(stockRemain))
                                                    stockRemain =
                                                      item.stockRemain

                                                  let remainClassName = ''

                                                  if (mode === '') {
                                                    remainClassName = ''
                                                  } else {
                                                    if (stock > stockRemain)
                                                      remainClassName = 'reduce'
                                                    if (stock < stockRemain)
                                                      remainClassName = 'add'
                                                  }
                                                  return (
                                                    <span className='label'>
                                                      {item.stockRemain
                                                        ? item.stockRemain
                                                        : '0'}
                                                      {' -> '}
                                                      <span
                                                        className={
                                                          remainClassName
                                                        }
                                                      >
                                                        {mode !== ''
                                                          ? stockRemain
                                                          : stock}
                                                      </span>
                                                    </span>
                                                  )
                                                })()}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div className='d-flex flex-row align-items-center pt-3 pb-3 justify-content-start position-relative'>
                                  <div className='mr-4 first-column'>
                                    <span className='title'>การแสดงสินค้า</span>
                                  </div>
                                  <div>
                                    <div className='checkbox-group'>
                                      <Checkbox
                                        title={'แสดงในเว็บ'}
                                        checked={
                                          item.showOn.indexOf('normal') > -1
                                        }
                                        value='normal'
                                        onChange={(e) =>
                                          this.onShowOnChange(e, idx)
                                        }
                                        name='normal'
                                      />
                                      <Checkbox
                                        title={'แสดงในเชล์ฟ'}
                                        checked={
                                          item.showOn.indexOf('shelf') > -1
                                        }
                                        value='shelf'
                                        onChange={(e) =>
                                          this.onShowOnChange(e, idx)
                                        }
                                        name='shelf'
                                      />
                                      <Checkbox
                                        title={'แสดงในไลฟ์หรือวิดีโอ'}
                                        checked={
                                          item.showOn.indexOf('live') > -1
                                        }
                                        value='live'
                                        onChange={(e) =>
                                          this.onShowOnChange(e, idx)
                                        }
                                        name='live'
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-row align-items-center pt-3 pb-3 justify-content-between position-relative'>
                                  <div className='d-flex'>
                                    {/* ANCHOR Modal preview */}
                                    <ModalPreview
                                      idx={idx}
                                      sku={item}
                                      optionDetail={optionDetail}
                                      productSkuFileImages={
                                        productSkuFileImages
                                      }
                                      setClick={(click) =>
                                        this.setHandleProductSkuPreview(
                                          idx,
                                          click,
                                        )
                                      }
                                    />

                                    <div className='mr-4 first-column'>
                                      <span className='title'>สถานะการขาย</span>
                                    </div>

                                    {mode === 'VIEW' && (
                                      <React.Fragment>
                                        {item.status === 'show' && (
                                          <label
                                            htmlFor='opt1'
                                            className='radio'
                                          >
                                            <input
                                              type='radio'
                                              name='rdo'
                                              id='opt1'
                                              className='hidden'
                                              value={'show'}
                                              checked={status === 'show'}
                                            />
                                            <span className='label'></span>
                                            ขายอยู่
                                          </label>
                                        )}

                                        {item.status !== 'show' && (
                                          <label
                                            htmlFor='opt2'
                                            className='radio'
                                          >
                                            <input
                                              type='radio'
                                              name='rdo'
                                              id='opt2'
                                              className='hidden'
                                              value={'hide'}
                                              checked={status !== 'show'}
                                            />
                                            <span className='label'></span>
                                            ไม่ขายแล้ว
                                          </label>
                                        )}
                                      </React.Fragment>
                                    )}

                                    {mode !== 'VIEW' && (
                                      <React.Fragment>
                                        <RadioButtonGroup
                                          onChange={(event) =>
                                            this.onChangeProductStatus(
                                              event,
                                              idx,
                                            )
                                          }
                                          value={item.status}
                                          disabled={false}
                                          name={'product-sku-status'}
                                          plainOptions={_productStatus}
                                        />
                                      </React.Fragment>
                                    )}
                                  </div>
                                  {/* <div className="cursor-pointer" onClick={() => this.handleShowModal(idx)}> */}
                                  <div
                                    className='cursor-pointer'
                                    onClick={
                                      this.state.modalProductSkuPreviews[idx]
                                    }
                                  >
                                    <span
                                      style={{
                                        color: 'orange',
                                        marginRight: '5px',
                                      }}
                                    >
                                      ตัวอย่างการแสดงผล
                                    </span>
                                    <img
                                      src={IconQuestion}
                                      alt='IconQuestion'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              {/* ANCHOR - SKU product option off */}
              {!enableProductSkuOption && (
                <div className='bg-white'>
                  <div className='ml-10per pb-4 pt-4 col-10'>
                    <div className=''>
                      <div
                        className='mt-4 d-block bg-white'
                        style={{ padding: '1em 1.5em' }}
                      >
                        <h5>รายละเอียด</h5>
                        <div className='d-flex flex-row options-product'>
                          <div className='d-flex flex-column justify-content-between'>

                            <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>
                                      ราคาปกติ<span className='text-danger'>*</span>
                                    </span>
                                    <span className='title-detail'>
                                      ราคาตั้งต้นก่อนลดราคา
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>฿</span>
                                      </div>

                                      {/* <input
                                              type='number'
                                              className={`form-control ${sku?.errors?.priceList ? 'error-field' : ''}`}
                                              aria-label='Amount (to the nearest dollar)'
                                              name='priceList'
                                              placeholder='0'
                                              value={sku.priceList}
                                              onChange={this.onSkuChange}
                                            /> */}

                                      <InputNumber
                                        value={sku.priceList}
                                        name={'priceList'}
                                        type={'CURRENCY'}
                                        className={`form-control ${sku?.errors?.priceNet ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuChange}
                                      />
                                    </div>
                                    {sku?.errors?.priceList && (
                                      <span className='text-danger'>
                                        {sku?.errors?.priceList}
                                      </span>
                                    )}
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <span className='title'>
                                      ราคาที่ประกาศขาย
                                      <span className='text-danger'>*</span>
                                    </span>
                                    <span className='title-detail'>
                                      ราคาที่ลูกค้าจะซื้อจริง
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>฿</span>
                                      </div>
                                      {/* <input
                                              type='number'
                                              className={`form-control ${sku?.errors?.priceNet ? 'error-field' : ''}`}
                                              aria-label='Amount (to the nearest dollar)'
                                              name='priceNet'
                                              placeholder='0'
                                              value={sku.priceNet}
                                              onChange={this.onSkuChange}
                                            /> */}
                                      <InputNumber
                                        value={sku.priceNet}
                                        name={'priceNet'}
                                        type={'CURRENCY'}
                                        className={`form-control ${sku?.errors?.priceNet ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuChange}
                                      />
                                    </div>
                                    {sku?.errors?.priceNet && (
                                      <span className='text-danger'>
                                        {sku?.errors?.priceNet}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  {/* <div className='d-flex flex-column'>
                                          <span className='title'>
                                            ราคาส่ง <span className='text-danger'>*</span>
                                          </span>
                                          <span className='title-detail'>
                                            ราคาต้นทุนของสินค้า
                                          </span>
                                          <div className='input-group'>
                                            <div className='input-group-prepend'>
                                              <span className='input-group-text'>฿</span>
                                            </div>
                                            <InputNumber
                                              value={sku.priceCost}
                                              name={'priceCost'}
                                              type={'CURRENCY'}
                                              className={`form-control ${
                                                sku?.errors?.priceCost
                                                  ? 'error-field'
                                                  : ''
                                              }`}
                                              placeholder={'0.00'}
                                              onChange={this.onSkuChange}
                                            />
                                          </div>
                                          {sku?.errors?.priceCost && (
                                            <span className='text-danger'>
                                              {sku?.errors?.priceCost}
                                            </span>
                                          )}
                                        </div> */}
                                  <div className='d-flex flex-column w-100'>
                                    <span className='title'>
                                      สต็อก <span className='text-danger'>*</span>
                                    </span>
                                    <span className='title-detail'>
                                      จำนวนสินค้าพร้อมขาย
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'></span>
                                      </div>
                                      {/* <input
                                    type='number'
                                    className={`form-control ${sku?.errors?.stockRemain ? 'error-field' : ''} stock-remain`}
                                    aria-label='Amount (to the nearest dollar)'
                                    name='stockRemain'
                                    placeholder='0'
                                    value={sku.stockRemain}
                                    onChange={this.onSkuChange}
                                  /> */}
                                      <InputNumber
                                        value={sku.stockRemain}
                                        name={'stockRemain'}
                                        type={'COMMON'}
                                        className={`form-control 
                                               ${sku?.errors?.stockRemain
                                            ? 'error-field'
                                            : ''
                                          } 
                                               ${sku.skuId !== undefined
                                            ? 'stock-remain'
                                            : ''
                                          }
                                               `}
                                        placeholder={'0'}
                                        onChange={this.onSkuChange}
                                      />
                                    </div>
                                    {sku?.errors?.stockRemain && (
                                      <span className='text-danger'>
                                        {sku?.errors?.stockRemain}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='d-flex flex-row align-items-end row-input mb-4' style={{ gap: '50px' }}>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column' >
                                    <span className='title'>
                                      น้ำหนักสินค้า
                                      <span className='text-danger'>*</span>
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          กก.
                                        </span>
                                      </div>
                                      {/* <input
                                        type='number'
                                        className={`form-control ${sku?.errors?.weight ? 'error-field' : ''}`}
                                        aria-label='Amount (to the nearest dollar)'
                                        name='weight'
                                        placeholder='0.00'
                                        value={sku.weight}
                                        onChange={this.onSkuChange} 
                                      /> */}
                                      <InputNumber
                                        value={sku.weight}
                                        name={'weight'}
                                        type={'DECIMAL'}
                                        className={`form-control ${sku?.errors?.weight ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuChange}
                                      />
                                    </div>
                                    {sku?.errors?.weight && (
                                      <span className='text-danger'>
                                        {sku?.errors?.weight}
                                      </span>
                                    )}
                                  </div>
                                  <div className='d-flex flex-column' >
                                    <span className='title'>
                                      กว้าง (กล่องพัสดุ)
                                      <span className='text-danger'>*</span>
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      {/* <input
                                      type='number'
                                      className={`form-control ${sku?.errors?.width ? 'error-field' : ''}`}
                                      aria-label='Amount (to the nearest dollar)'
                                      name='width'
                                      placeholder='0'
                                      value={sku.size.width}
                                      onChange={this.onSkuSizeChange}
                                    /> */}
                                      <InputNumber
                                        value={sku.size.width}
                                        name={'width'}
                                        type={'CURRENCY'}
                                        className={`form-control ${sku?.errors?.width ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuSizeChange}
                                      />
                                    </div>
                                    {sku?.errors?.width && (
                                      <span className='text-danger'>
                                        {sku?.errors?.width}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column w-100'>
                                <div className='d-flex flex-row align-items-end row-input mb-4 w-100'>
                                  <div className='d-flex flex-column' >
                                    <span className='title'>
                                      ยาว (กล่องพัสดุ)
                                      <span className='text-danger'>*</span>
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      {/* <input
                                        type='number'
                                        className='form-control'
                                        aria-label='Amount (to the nearest dollar)'
                                        className={`form-control ${sku?.errors?.length ? 'error-field' : ''}`}
                                        name='length'
                                        placeholder='0'
                                        value={sku.size.length}
                                        onChange={this.onSkuSizeChange}
                                      /> */}
                                      <InputNumber
                                        value={sku.size.length}
                                        name={'length'}
                                        type={'CURRENCY'}
                                        className={`form-control ${sku?.errors?.length ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuSizeChange}
                                      />
                                    </div>

                                    {sku?.errors?.length && (
                                      <span className='text-danger'>
                                        {sku?.errors?.length}
                                      </span>
                                    )}
                                  </div>
                                  <div className='d-flex flex-column' >
                                    <span className='title'>
                                      สูง (กล่องพัสดุ)
                                      <span className='text-danger'>*</span>
                                    </span>
                                    <div className='input-group'>
                                      <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                          ซม.
                                        </span>
                                      </div>
                                      {/* <input
                                    type='number'
                                    className={`form-control ${sku?.errors?.height ? 'error-field' : ''}`}
                                    aria-label='Amount (to the nearest dollar)'
                                    name='height'
                                    placeholder='0'
                                    value={sku.size.height}
                                    onChange={this.onSkuSizeChange}
                                  /> */}
                                      <InputNumber
                                        value={sku.size.height}
                                        name={'height'}
                                        type={'CURRENCY'}
                                        className={`form-control ${sku?.errors?.height ? 'error-field' : ''
                                          }`}
                                        placeholder={'0.00'}
                                        onChange={this.onSkuSizeChange}
                                      />
                                    </div>
                                    {sku?.errors?.height && (
                                      <span className='text-danger'>
                                        {sku?.errors?.height}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='d-flex flex-row align-items-start row-input mb-4' style={{ gap: '50px' }} >
                              <div className='d-flex flex-column w-100' >

                                <span className='title'>
                                  รหัสสินค้า (SKU)
                                  <span className='text-danger'>*</span>
                                </span>
                                <div className='input-group'>
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text'></span>
                                  </div>
                                  <input
                                    type='text'
                                    className={`form-control ${sku?.errors?.sku ? 'error-field' : ''
                                      } sku-code`}
                                    aria-label='Amount (to the nearest dollar)'
                                    name='sku'
                                    disabled={sku.skuId !== undefined}
                                    value={sku.sku}
                                    onChange={(e) =>
                                      this.onSkuChange(
                                        e.target.value,
                                        e.target.name,
                                      )
                                    }
                                  />
                                </div>
                                {sku?.errors?.sku && (
                                  <span className='text-danger'>
                                    {sku?.errors?.sku}
                                  </span>
                                )}
                              </div>
                              <div className='d-flex flex-column w-100' >
                                <span className='title'>
                                  รหัสบาร์โค้ด
                                  {/* <span className='text-danger'>*</span> */}
                                </span>
                                <div className='input-group'>
                                  <div className='input-group-prepend'>
                                    <span className='input-group-text'></span>
                                  </div>
                                  <input
                                    type='text'
                                    className='form-control'
                                    aria-label='Amount (to the nearest dollar)'
                                    name='barcode'
                                    onChange={(e) =>
                                      this.onSkuChange(
                                        e.target.value,
                                        e.target.name,
                                      )
                                    }
                                    value=''
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='d-flex flex-row align-items-center pt-3 pb-3 justify-content-start position-relative'>
                          <div className='mr-4 first-column'>
                            <span className='title'>การแสดงสินค้า</span>
                          </div>
                          <div>
                            <div className='checkbox-group'>
                              <Checkbox
                                title={'แสดงในเว็บ'}
                                checked={sku.showOn.indexOf('normal') > -1}
                                value='normal'
                                onChange={this.onSkuShowOnChange}
                                name='normal'
                              />
                              <Checkbox
                                title={'แสดงในเชล์ฟ'}
                                checked={sku.showOn.indexOf('shelf') > -1}
                                value='shelf'
                                onChange={this.onSkuShowOnChange}
                                name='shelf'
                              />
                              <Checkbox
                                title={'แสดงในไลฟ์หรือวิดีโอ'}
                                checked={sku.showOn.indexOf('live') > -1}
                                value='live'
                                onChange={this.onSkuShowOnChange}
                                name='live'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ANCHOR - delivery date */}
              <div className='mt-4 bg-white'>
                <div className='ml-10per pt-4 pb-4 col-10 seo-manage'>
                  <h5>เตรียมการจัดส่ง</h5>
                  <span>
                    ระบุระยะเวลาเตรียมการจัดส่งโดยประมาณโดยไม่รวมกับเวลาที่ขนส่ง
                  </span>
                  <br></br>
                  <p>
                    <span> ระยะเวลา </span>
                    <span className='text-danger'>*</span>
                    <span
                      className={classnames(`text-danger d-none`, {
                        'd-inline': err?.prepareDay,
                      })}
                    >
                      กรุณากรอกระบุระยะเวลาเตรียมการจัดส่ง
                    </span>
                  </p>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='input-group'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'> ชั่วโมง </span>
                        </div>
                        <input
                          type='number'
                          className={`form-control ${err?.prepareDay ? 'error-field' : ''
                            }`}
                          name='prepareDay'
                          placeholder='0'
                          value={prepareDay}
                          onChange={(e) => this.onChangePrepareDay(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                </div>
              </div>

              {/* ANCHOR - Logistic option */}
              <div className='bg-white pb-4 pt-4'>
                <div className='ml-10per col-10'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h5>ตั้งค่าการจัดส่ง</h5>
                      <span>
                        เลือกเปิดการใช้งานการจัดส่งที่ต้องการใช้
                        หรือตั้งราคาค่าจัดส่งด้วยตัวเอง
                      </span>
                      <br />
                    </div>
                    <div></div>
                  </div>
                  <span>ค่าเริ่มต้น</span>
                  {[...logisticList].map((logistic, logisticIndex) => (
                    <div className='mt-4' key={logisticIndex}>
                      <div className='row align-items-center'>
                        <div className='col-1'>
                          <span>
                            <img
                              src={host.image + logistic?.logo}
                              alt='stock'
                              style={{ width: '5em' }}
                            />
                          </span>
                        </div>

                        <div className='col-1'>
                          <span style={{ width: '5%' }}>{logistic?.name}</span>
                        </div>

                        <div className='col-3'>
                          <div
                            className='toggle-green d-flex align-items-center'
                            style={{ gap: '10px' }}
                          >
                            {mode !== 'VIEW' && (
                              <label className='switch'>
                                <input
                                  type='checkbox'
                                  checked={logistic?.enable}
                                  onChange={(e) =>
                                    this.onActiveLogisticChange(
                                      e,
                                      logisticIndex,
                                      logistic?.code,
                                    )
                                  }
                                />
                                <span className='slider round'></span>
                              </label>
                            )}
                            <span>
                              {logistic?.enable ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                            </span>
                          </div>
                        </div>

                        {/* <div className='col-3'>
                          <div className='d-flex flex-column'>
                            {logistic?.rateType !== 'standard' ? (
                              <React.Fragment>
                                <span className='label-title'>
                                  ค่าจัดส่งแบบเหมาจ่าย
                                  <span className='text-danger'>*</span>
                                </span>
                                <span className='label-detail'>
                                  ราคาค่าจัดส่งสุทธิโดยไม่คิดน้ำหนักและจำนวน
                                </span>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <span className='label-title'>
                                  ค่าจัดส่งคำนวณตามน้ำหนัก
                                </span>
                                <span className='label-detail'>
                                  ค่าจัดส่งขั้นต่ำ x น้ำหนักตาม SKU
                                </span>
                              </React.Fragment>
                            )}

                            {logistic?.rateType !== 'standard' &&
                            logistic?.enable === true ? (
                              <div className='input-group'>
                                <div className='input-group-prepend'>
                                  <span className='input-group-text'>฿</span>
                                </div>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={logistic?.price}
                                  onChange={(e) =>
                                    this.logisticPriceChange(e, logisticIndex)
                                  }
                                />
                              </div>
                            ) : (
                              <div className='input-group'>
                                <div className='input-group-prepend'>
                                  <span className='input-group-text'>฿</span>
                                </div>
                                <div className='rate-type'>
                                  {logistic?.priceRange}
                                </div>
                              </div>
                            )}
                          </div>
                        </div> */}

                        {/* {mode === 'VIEW' && logistic?.rateType === 'free' && (
                          <div className='col-3'>
                            <div className='font-primary font-weight-light font-size-14px'>
                              <div className='select-all-item'>
                                <label className='container'>
                                  <input
                                    type='checkbox'
                                    className='void'
                                    id={logistic?.code}
                                    checked={
                                      logistic?.rateType === 'free' &&
                                      logistic?.enable === true
                                    }
                                    // defaultChecked={ logistic?.rateType !== 'standard' }
                                    disabled={!logistic?.enable}
                                    onChange={(e) =>
                                      this.onRateTypeChange(e, logisticIndex)
                                    }
                                  />
                                  <span
                                    className={`checkmark ${
                                      !logistic?.enable ? 'disable' : ''
                                    }`}
                                  ></span>
                                  <span
                                    className={`${
                                      !logistic?.enable ? 'disable' : ''
                                    }`}
                                  >
                                    ตั้งราคาแบบเหมาจ่าย {mode} -
                                    {logistic?.rateType}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        )} */}

                        {/* {mode !== 'VIEW' && (
                          <div className='col-3'>
                            <div className='font-primary font-weight-light font-size-14px'>
                              <div className='select-all-item'>
                                <label className='container'>
                                  <input
                                    type='checkbox'
                                    className='void'
                                    id={logistic?.code}
                                    checked={
                                      logistic?.rateType === 'free' &&
                                      logistic?.enable === true
                                    }
                                    // defaultChecked={ logistic?.rateType !== 'standard' }
                                    disabled={!logistic?.enable}
                                    onChange={(e) =>
                                      this.onRateTypeChange(e, logisticIndex)
                                    }
                                  />
                                  <span
                                    className={`checkmark ${
                                      !logistic?.enable ? 'disable' : ''
                                    }`}
                                  ></span>
                                  <span
                                    className={`${
                                      !logistic?.enable ? 'disable' : ''
                                    }`}
                                  >
                                    ตั้งราคาแบบเหมาจ่าย
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        )} */}

                        {mode !== 'VIEW' && (
                          <div className='col-3'>
                            <div className='font-primary font-weight-light font-size-14px'>
                              <div className='select-all-item'>
                                <div className='d-flex flex-column'>
                                  <div>
                                    <label className='container'>
                                      <input
                                        type='checkbox'
                                        className='void'
                                        id={logistic?.code}
                                        checked={
                                          logistic?.rateType === 'standard' &&
                                          logistic?.enable === true
                                        }
                                        // defaultChecked={ logistic?.rateType !== 'standard' }
                                        disabled={!logistic?.enable}
                                        onChange={(e) =>
                                          this.onRateTypeChange(
                                            e,
                                            logisticIndex,
                                          )
                                        }
                                      />
                                      <span
                                        className={`checkmark ${!logistic?.enable ? 'disable' : ''
                                          }`}
                                      ></span>
                                      <span
                                        className={`${!logistic?.enable ? 'disable' : ''
                                          }`}
                                      >
                                        ค่ามาตราฐาน
                                      </span>
                                    </label>
                                    <span className='text-danger'>
                                      {errShopLogistic.includes(logistic.code)
                                        ? '* การจัดส่งนี้ ยังไม่มีการตั้งค่าส่งแบบกำหนดเอง'
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className='col-4'>
                          <div className='d-flex flex-row align-items-center'>
                            {/* {logistic?.rateType == 'standard' ? (
                              <React.Fragment>
                                <span className='label-title'>
                                  ค่าจัดส่งแบบเหมาจ่าย
                                  <span className='text-danger'>*</span>
                                </span>
                                <span className='label-detail'>
                                  ราคาค่าจัดส่งสุทธิโดยไม่คิดน้ำหนักและจำนวน
                                </span>
                              </React.Fragment>
                            ) : (
                                <React.Fragment>
                                  <span className='label-title'>
                                    ค่าจัดส่งคำนวณตามน้ำหนัก
                                </span>
                                  <span className='label-detail'>
                                    ค่าจัดส่งขั้นต่ำ x น้ำหนักตาม SKU
                                </span>
                                </React.Fragment>
                              )} */}

                            {logistic?.rateType === 'standard' && (
                              <React.Fragment>
                                <div className='d-flex flex-column'>
                                  <span className='label-title'>
                                    ค่าจัดส่งคำนวณตามน้ำหนัก
                                  </span>
                                  <span className='label-detail'>
                                    ค่าจัดส่งขั้นต่ำ x น้ำหนักตาม SKU
                                  </span>
                                </div>
                              </React.Fragment>
                            )}

                            {logistic?.rateType === 'custom' &&
                              logistic?.enable === true ? (
                              // <div className='input-group'>
                              //   <div className='input-group-prepend'>
                              //     <span className='input-group-text'>฿</span>
                              //   </div>
                              //   <input
                              //     type='number'
                              //     className='form-control'
                              //     value={logistic?.price}
                              //     onChange={(e) =>
                              //       this.logisticPriceChange(e, logisticIndex)
                              //     }
                              //   />
                              // </div>

                              <div className='w-100'>
                                <SelectInput
                                  iconClearSearch={false}
                                  placeholder={''}
                                  options={
                                    this.props.providerShopLogistic &&
                                    this.findValueShopLogistic(logistic?.code)
                                  }
                                  onChange={(data) =>
                                    this.onChangeShopLogistic(
                                      data,
                                      logisticIndex,
                                      logistic.code,
                                    )
                                  }
                                  value={
                                    this.props.providerShopLogistic &&
                                    this.findIndexShopLogistic(
                                      logistic?.code,
                                      logistic,
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className='input-group'
                                style={{ width: '33%' }}
                              >
                                <div className='input-group-prepend'>
                                  <span className='input-group-text'>฿</span>
                                </div>
                                <div className='rate-type'>
                                  {logistic?.priceRange}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className='d-flex flex-row justify-content-center'
                style={{ gap: '4em' }}
              >
                {mode !== 'VIEW' && (
                  <button className='btn-cancel' onClick={this.toggleModal}>
                    ยกเลิก
                  </button>
                )}

                {/* <button className='btn-submit' onClick={this.validateStock}> ตรวจสอบ </button> */}
                {mode !== 'VIEW' ? (
                  mode === 'UPDATE' ? (
                    <button
                      className='btn-submit'
                      onClick={this.onUpdateProduct}
                    >
                      แก้ไขสินค้า
                    </button>
                  ) : (
                    <button
                      className='btn-submit'
                      onClick={this.onCreateProduct}
                    >
                      สร้าง
                    </button>
                  )
                ) : (
                  ''
                )}
              </div>

              <ModalUploadImage
                isClose={() => this.onCloseModalUploadImage()}
                onChange={this.onModalUploadImageGetImage}
              />
            </div>
            <ToastNotification />
            <ModalAlert />
          </ModalBody>
        </Modal>
        {/* {this.props.isLoading && (
          <ModalLoading isOpenModal={this.props.isLoading} />
        )} */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
  shop: state.shop,
  imgBase64: state.modalUploadImage.imgBase64,
  logisticList: state.logistic.logisticList,
  currentShop: state.shop.currentShop,
  listImageProduct: state.gallery.productUploadEvent,
  listVideoProduct: state.gallery.productUploadEventVideo,
  listImageSEO: state.gallery.seoUploadEvent,
  listImageSKU: state.gallery.skuUploadEvent,
  isLoading: state.gallery.isUploading,
  modalAddProduct: state.product.modalAddProduct,
  app: state.app,
  allShops: state.shop.allShops,
  providerShopLogistic: state.product.providerShopLogistic,
  listAllCategories: state.product.listAllCategories,
})

export default connect(mapStateToProps, null)(ModalAddProduct)
