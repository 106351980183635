import { createAction } from 'redux-act'

export const connectSocket = createAction('connect socket')
export const login = createAction('login')
export const auth = createAction('auth')
export const addUser = createAction('add user')
export const removeUser = createAction('remove user')

export const newMessage = createAction('onNewMessage')
export const sendMessage = createAction('sendMessage')
export const sendLike = createAction('sendLike')
export const onUnLike = createAction('unLike')
export const onLike = createAction('onLike')

export const join = createAction('join')
export const leave = createAction('leave')
export const onLiveUser = createAction('live user')
export const onProduct = createAction('on product')

export const onEndLive = createAction('endlive')
export const onStartLive = createAction('endlive')


export const setField = createAction('socket set field')

