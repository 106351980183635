import React from 'react'
import classnames from 'classnames'
import './styles.scss'

const ButtonAdd = (props) => {
  const { label, onClickButton, children, color, disabled } = props

  return (
    <button
      className={classnames('btn-with-icon', {
        disabled: disabled,
        [color]: !disabled,
      })}
      onClick={onClickButton}
      disabled={disabled}
    >
      {children}
      <span className='ml-1'>{label}</span>
    </button>
  )
}

ButtonAdd.defaulProps = {
  label: 'ลบ',
  onClickButton: () => {},
  disabled: false
}

export default ButtonAdd
