import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconClose from "../../images/icons/close.svg";
import IconAvatar from '../../images/business-woman-profile-1.png';
import IconTrue from '../../images/icons/true.svg'
import IconShopAvatar from '../../images/icons/default-shop-logo.svg';
import IconPrint from "../../images/icons/printer-orange.svg";
import "./index.scss";
import host from '../../config/host';
import numeral from 'numeral';
import { displayDateTh } from '../../helper/timeUtil';
import {
  dispatchOrder, OPEN_MODAL_INVOICE_DETAIL,
  UPDATE_INVOICE_LOGISTIC, UPDATE_INVOICE_SHOP_NOTE, SET_COMPLETE_LOGISTIC,
  SET_UPDATE_SHOP_NOTE_RES, SET_UPDATE_LOGISTIC_RES, SET_MODAL_REFUND_REASON,
  ON_CONFIRM_REFUND, SET_CLEARING_ORDER, OPEN_MODAL_CONFIRM_REFUND, CLOSE_MODAL_CONFIRM_REFUND, CLOSE_MODAL_CONFIRM_CLEARING,
  OPEN_MODAL_CONFIRM_CLEARING,
  CLOSE_MODAL_CONFIRM_LARGE,
  OPEN_MODAL_CONFIRM_LARGE,
  HANDLE_SET_REDELIVERY,
  OPEN_MODAL_CHILDREN,
  CLOSE_MODAL_CHILDREN,
  OPEN_MODAL_COVER_SHEET_PRINT,
  CONFIRM_ORDER
} from '../../redux/actions/order';

import TooltipNotification from '../TooltipNotification';
import classNames from 'classnames'
import ModalRefundReason from '../modalRefundReason'
import ModalMessage from "../modalMessage";
import modalMessage from "../../redux/reducers/modalMessage";
import { CLOSE_MODAL_MESSAGE } from "../../redux/actions/modalMessage";
import ModalConfirm from '../modalConfirm/ModalConfirm'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from "../../redux/actions/modalConfirm";
import Loading from "../Loading";
import { message } from "antd";


import { getUserRole } from '../../helper/utils';
import { ADMIN, OWNER, SUPER_ADMIN } from '../../layout/Sidebar/Role';
import DatePickerInput from 'components/DatePickerInput'
import TimePicker from 'components/Timepicker'
import moment from 'moment'
import { differenceDate } from '../../helper/timeUtil'
import RadioComponent from "components/DropDownList";
import DropdownIcon from "components/DropdownIcon";
import productProvider from "provider/productProvider";
import DropdownButton from "components/DropdownButton";
import { getUser } from "redux/sagas/selectors";
import ModalConfirmLarge from "components/ModalConfirmLarge";
import ModalLargeChildren from "components/ModalLargeChildren";


const PaymentMethod = {
  CREDIT_CARD: { key: "creditcard", value: "บัตรเครดิต" },
  I_BANKING: { key: "ibanking", value: "อินเตอร์เน็ตแบงก์กิ้ง" },
  COD: { key: "cod", value: "เก็บเงินปลายทาง" },
  TRANSFER: { key: "transfer", value: "โอนผ่านธนาคาร" },
  QR_CODE: { key: "qrcode", value: "QR Code" },
}

const PaymentStatus = {
  WAIT: { key: "wait", value: "รอชำระเงิน" },
  TRANSFER: { key: "transfer", value: "รอตรวจสอบ" },
  APPROVED: { key: "approved", value: "ตรวจสอบแล้ว" },
  PAID: { key: "paid", value: "ชำระเงินแล้ว" },
  FAILED: { key: "failed", value: "ยกเลิก" }
}

const LogisticStatus = {
  WAIT: { key: "wait", value: "รอรับออเดอร์" },
  PREPARE: { key: 'prepare', value: "รอจัดส่ง" },
  POSTING: { key: "posting", value: "อยู่ระหว่างขนส่ง" },
  INTRANSIT: { key: "intransit", value: "อยู่ระหว่างขนส่ง" },
  REDELIVERY: { key: "redelivery", value: "จัดส่งใหม่" },
  DELIVERED: { key: "delivered", value: "สำเร็จ" },
  OUTDELIVERY: { key: "outdelivery", value: "อยู่ระหว่างขนส่ง" },
  ISSUES: { key: "issues", value: "เกิดปัญหา" },
  RETURN: { key: "return", value: "คืนของ" }
}

const OrderStatus = {
  WAIT_CONFIRM: 'waitConfirm',
  WAIT_SHIPPING: 'waitShipping',
  ON_THE_WAY: 'onTheWay',
  SUCCESS: 'success',
  WAIT_REFUND: 'waitRefund',
  REFUNDED: 'refunded',
  DELIVERY_DELAYED: 'deliveryDelayed'
}

let orderStatusChange = [
  {
    value: 'wait',
    name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
  },
  {
    value: 'success',
    name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
  },
]

class ModalInvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shopNote: '',
      trackingNo: '',
      confirmRefund: false,
      modalConfirmClearing: false,
      currentModal: '',
      releaseDate: {
        date: null,
        time: null
      },
      updateSecondTracking: false,
      deliveries: [],
      logisticRedelivery: null
    };
  }

  componentDidMount() {
    var time = moment().utcOffset(0)
    time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    this.setState({
      releaseDate: {
        date: moment().format('MM/DD/YYYY'),
        time: time
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.order.status !== this.props.order.status) {
      if (nextProps.order.status === "onTheWay") {
        orderStatusChange = [
          {
            value: 'wait',
            name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
          },
          {
            value: 'success',
            name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
          },
        ]
      }
      if (nextProps.order.status === "waitShipping") {
        orderStatusChange = [{
          value: 'success',
          name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
        }]
      }
      if (nextProps.order.status === "success") {
        orderStatusChange = [{
          value: 'wait',
          name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
        }]
      }
    }


    if (nextProps.modalInvoiceDetail !== this.props.modalInvoiceDetail) {
      let logisticDefault = nextProps.modalInvoiceDetail?.deliveries && nextProps.modalInvoiceDetail?.logistics?.filter(
        (logistic) =>
          logistic.name === nextProps.modalInvoiceDetail?.deliveries[nextProps.modalInvoiceDetail?.deliveries?.length - 1].logistic)
      if (logisticDefault == undefined || logisticDefault.length <= 0) {
        logisticDefault = nextProps.modalInvoiceDetail?.logistics && [{ ...nextProps.modalInvoiceDetail?.logistics[0] }]
      }
      this.setState({
        trackingNo: nextProps.modalInvoiceDetail?.currentInvoice?.delivery?.trackingNo || '',
        shopNote: nextProps.modalInvoiceDetail?.currentInvoice?.shopNote,
        deliveries: nextProps.modalInvoiceDetail?.deliveries,
        logisticRedelivery: logisticDefault && logisticDefault[0]?.value
      })
      // if (nextProps.order.status === "onTheWay") {
      //   if (nextProps.modalInvoiceDetail?.deliveries && nextProps.modalInvoiceDetail?.deliveries[nextProps.modalInvoiceDetail?.deliveries.length - 1].logistic === "EMS") {
      //     delete orderStatusChange[1]
      //   } 
      //   else {
      //     orderStatusChange = [
      //       {
      //         value: 'wait',
      //         name: 'เปลี่ยนสถานะเป็นรอจัดส่ง',
      //       },
      //       {
      //         value: 'success',
      //         name: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
      //       },
      //     ]
      //   }
      // }

    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeLogisticTracking = (e) => {
    let deliveries = this.state.deliveries
    deliveries[deliveries.length - 1] = {
      ...deliveries[deliveries.length - 1],
      trackingNo: e.target.value
    }
    this.setState({ deliveries })
  }

  toggleModal = () => {
    this.props.dispatch(dispatchOrder({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false } }));
    this.setState({ updateSecondTracking: false })
  }

  checkPaymentStatus = (status) => {
    switch (status) {
      case PaymentStatus.WAIT.key: return <div className="badge badge-gray">{PaymentStatus.WAIT.value}</div>
      case PaymentStatus.TRANSFER.key: return <div className="badge badge-orange">{PaymentStatus.TRANSFER.value}</div>
      case PaymentStatus.APPROVED.key: return <div className="badge badge-green"> <img src={IconTrue} className="mr-2" />{PaymentStatus.APPROVED.value}</div>
      case PaymentStatus.PAID.key: return <div className="badge badge-green"> <img src={IconTrue} className="mr-2" />{PaymentStatus.PAID.value}</div>
      case PaymentStatus.FAILED.key: return <div className="badge badge-red">{PaymentStatus.FAILED.value}</div>
    }
  }

  checkLogisticStatus = (status, shipmentStatus) => {
    if (shipmentStatus === "wait" || shipmentStatus === "prepare") {
      switch (status, shipmentStatus) {
        case (LogisticStatus.WAIT.key, 'waitconfirm'): return <div className="badge badge-gray">{LogisticStatus.WAIT.value}</div>
        case (LogisticStatus.WAIT.key, 'prepare'): return <div className="badge badge-orange">{LogisticStatus.PREPARE.value}</div>
      }
    } else {
      switch (status) {
        case (LogisticStatus.WAIT.key): return <div className="badge badge-gray">{LogisticStatus.WAIT.value}</div>
        case LogisticStatus.POSTING.key: return <div className="badge badge-orange"> {LogisticStatus.POSTING.value}</div>
        case LogisticStatus.INTRANSIT.key: return <div className="badge badge-green"> <img src={IconTrue} className="mr-2" />{LogisticStatus.INTRANSIT.value}</div>
        case LogisticStatus.REDELIVERY.key: return <div className="badge badge-orange">{LogisticStatus.REDELIVERY.value}</div>
        case LogisticStatus.DELIVERED.key: return <div className="badge badge-green"> <img src={IconTrue} className="mr-2" />{LogisticStatus.DELIVERED.value}</div>
        case LogisticStatus.OUTDELIVERY.key: return <div className="badge badge-orange">{LogisticStatus.OUTDELIVERY.value}</div>
        case LogisticStatus.ISSUES.key: return <div className="badge badge-red">{LogisticStatus.ISSUES.value}</div>
        case LogisticStatus.RETURN.key: return <div className="badge badge-red">{LogisticStatus.RETURN.value}</div>
      }
    }
  }

  getDateByPaymentStatus = () => {
    const { deliveryDate } = this.props.modalInvoiceDetail?.currentInvoice || { deliveryDate: '' };
    const { deliveredAt } = this.props.modalInvoiceDetail?.currentInvoice || { deliveredAt: '' };
    const { refundedAt } = this.props.modalInvoiceDetail?.currentInvoice?.payment || { refundedAt: '' };
    const status = this.props?.orderStatus;
    switch (status) {
      case OrderStatus.WAIT_CONFIRM: return <div className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')} </div>
      case OrderStatus.WAIT_SHIPPING: return <div className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')}</div>
      case OrderStatus.ON_THE_WAY: return <div className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')}</div>
      case OrderStatus.SUCCESS: return <div className="payment-date pr-5"> จัดส่งสำเร็จวันที่ {displayDateTh(deliveredAt, 'DD MMM yyyy')}  </div>
      case OrderStatus.WAIT_REFUND: return <div className="payment-date pr-5"> <span style={{ color: '#FF8D10' }}> สถานะ รอการคืนเงิน</span> </div>
      case OrderStatus.REFUNDED: return <div className="payment-date pr-5">คืนเงินวันที่ {displayDateTh(refundedAt, 'DD MMM yyyy')}</div>
      case OrderStatus.DELIVERY_DELAYED: return <div className="payment-date pr-5">กรุณาจัดส่งภายในวันที่ {displayDateTh(deliveryDate, 'DD MMM yyyy')} <span style={{ color: '#D10808' }}> เกินเวลาจัดส่งมา {differenceDate(new Date(), deliveryDate)} วัน</span> </div>
    }
  }

  checkPaymentMethod = (method) => {
    switch (method) {
      case PaymentMethod.CREDIT_CARD.key: return PaymentMethod.CREDIT_CARD.value;
      case PaymentMethod.I_BANKING.key: return PaymentMethod.I_BANKING.value;
      case PaymentMethod.COD.key: return PaymentMethod.COD.value;
      case PaymentMethod.TRANSFER.key: return PaymentMethod.TRANSFER.value;
      case PaymentMethod.QR_CODE.key: return PaymentMethod.QR_CODE.value;
    }
  }

  updateLogistic = () => {
    // const deliveryId = this.props.modalInvoiceDetail?.currentInvoice?.delivery?.id || 0;
    const { logisticRedelivery, deliveries } = this.state
    const { shipmentStatus } = this.props.modalInvoiceDetail.currentInvoice
    // console.log(`shipmentStatus`, shipmentStatus)
    const deliveryId = deliveries[deliveries.length - 1].id || 0;
    const trackingNo = deliveries[deliveries.length - 1].trackingNo
    const logisticId = logisticRedelivery !== null && logisticRedelivery.id || deliveries[deliveries.length - 1].logisticId
    const logistic = logisticRedelivery !== null && (logisticRedelivery.logistic || logisticRedelivery.name) || (deliveries[deliveries.length - 1].logistic || deliveries[deliveries.length - 1].name)
    if (shipmentStatus === "waitconfirm") {
      this.handleConfirmOrder()
    }
    this.props.dispatch(dispatchOrder({ type: UPDATE_INVOICE_LOGISTIC, payload: { deliveryId, trackingNo, logisticId, logistic } }));
    this.setState({ updateSecondTracking: false })
  }

  onClickCompleteLogistic = () => {
    const dateString = moment(this.state.releaseDate.date).format('YYYY-MM-DD')
    const timeString = moment(this.state.releaseDate.time).format('HH:mm')
    const releaseDate = `${dateString}T${timeString}:00.000+07:00`

    // this.props.dispatch(dispatchOrder({ type: SET_COMPLETE_LOGISTIC, payload: { detail: this.props.modalInvoiceDetail.currentInvoice, dateString, timeString, releaseDate } })); ###old delivery
    this.props.dispatch(dispatchOrder({ type: SET_COMPLETE_LOGISTIC, payload: { detail: this.props.modalInvoiceDetail.deliveries, dateString, timeString, releaseDate } }));
    this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }))
  }

  updateShopNote = () => {
    const invoiceId = this.props.modalInvoiceDetail?.currentInvoice?.id || 0;
    const shopNote = this.state.shopNote;
    this.props.dispatch(dispatchOrder({ type: UPDATE_INVOICE_SHOP_NOTE, payload: { invoiceId, shopNote } }));
  }

  onRefund = () => {
    this.props.dispatch(dispatchOrder({ type: SET_MODAL_REFUND_REASON, payload: true }))
  }

  onConfirmRefund = (id, currentInvoice) => {
    this.setState({ confirmRefund: true },
      () => this.props.dispatch(dispatchOrder({ type: ON_CONFIRM_REFUND, payload: { status: this.state.confirmRefund, id, currentInvoice: currentInvoice } }))
    )
  }

  onCancelRefund = (id, currentInvoice) => {
    this.setState({ confirmRefund: false },
      () => this.props.dispatch(dispatchOrder({ type: ON_CONFIRM_REFUND, payload: { status: this.state.confirmRefund, id, currentInvoice: currentInvoice } }))
    )
  }

  openModalConfirm = (status) => {
    this.setState({ confirmRefund: status }, () => this.props.dispatch(dispatchModalConfirm({
      type: OPEN_MODAL_CONFIRM_REFUND, payload: {
        isOpen: true,
        message: status ? 'ยืนยันการคืนเงินให้ลูกค้า' : 'ยกเลิกการขอคืนเงิน',
        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก'
      }
    })))
  }

  handleClearingOrder = (id) => {
    this.props.dispatch(dispatchOrder({ type: SET_CLEARING_ORDER, payload: { id, search: this.props.search, filter: this.props.filter } }))
    this.setState({ modalConfirmClearing: false })
  }

  openModalClearing = () => {
    this.props.dispatch(dispatchOrder({
      type: OPEN_MODAL_CONFIRM_CLEARING, payload: {
        isOpen: true,
        message: 'กรุณายืนยันว่าโอนเงินให้ร้านค้าเรียบร้อยแล้ว',
        btnConfirm: 'ยืนยัน',
        btnCancel: 'ยกเลิก'
      }
    }))
  }

  closeModalClearing = () => {
    this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CONFIRM_CLEARING }))
  }

  onDateDatePickerInputChange = (date) => {
    this.setState({ releaseDate: { ...this.state.releaseDate, date } })
  }

  onTimePickerChange = (time) => {
    const _time = moment(time)
    if (!_time.isValid()) {
      let timeTmp = moment().utcOffset(0)
      timeTmp.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      this.setState({
        releaseDate: { ...this.state.releaseDate, time: timeTmp },
      })
    } else {
      this.setState({ releaseDate: { ...this.state.releaseDate, time } })
    }
  }

  onOpenModalPrintCoverSheet = () => {
    this.toggleModal();
    this.props.dispatch(dispatchOrder({
      type: OPEN_MODAL_COVER_SHEET_PRINT,
      payload: {
        currentInvoice: this.props.modalInvoiceDetail.currentInvoice,
        isOpen: true
      }
    }));
  }

  handleSelectStatusLogistic = (value) => {
    if (value === 'wait') {
      this.props.dispatch(dispatchOrder({
        type: OPEN_MODAL_CONFIRM_LARGE, payload: {
          isOpen: true,
          message: <div>คุณแน่ใจหรือไม่ที่จะเปลี่ยนสถานะเป็น <div>“รอจัดส่ง”</div></div>,
          headerMsg: this.props.modalInvoiceDetail.currentInvoice.orderNo,
          btnConfirm: 'ยืนยัน',
          btnCancel: 'ยกเลิก',
          data: { status: value }
        }
      }))
    } else if (value === 'success') {
      this.props.dispatch(dispatchOrder({
        type: OPEN_MODAL_CHILDREN, payload: {
          isOpen: true,
          headerMsg: 'เปลี่ยนสถานะเป็นจัดส่งสำเร็จ',
          btnConfirm: 'ตกลง,',
          btnCancel: 'ยกเลิก',
          data: { status: value }
        }
      }))
    }
  }

  handleRedelivery = () => {
    this.props.dispatch(dispatchOrder({
      type: HANDLE_SET_REDELIVERY, payload: {
        orderId: this.props.modalInvoiceDetail.currentInvoice.id,
        logisticId: this.props.modalInvoiceDetail.deliveries[0].logisticId
      }
    }))
  }

  handleSelectLogisticRedelivery = (value) => {
    this.setState({
      logisticRedelivery: value
    })
  }

  handleConfirmOrder = () => {
    const orderId = this.props.modalInvoiceDetail.currentInvoice.id
    this.props.dispatch(dispatchOrder({ type: CONFIRM_ORDER, payload: { orderId } }));
  }

  render() {
    const {
      isOpen,
      currentInvoice
    } = this.props.modalInvoiceDetail;
    const {
      orderNo,
      priceNet,
      priceTotal,
      paymentStatus,
      logisticStatus,
      expirePaymentDate,
      paymentMethod,
      createdAt,
      updatedAt,
      shop,
      customerInformation,
      delivery,
      paymentedAt,
      id,
      issueNote,
      note,
      payment,
      priceShippingNet = 0,
      promotionCode = "",
      priceVoucherDiscount = 0,
      priceShippingDiscount = 0,
      priceItemDiscount = 0,
      shipmentStatus
    } = currentInvoice || {};
    const {
      logoPath,
      name
    } = shop || {};
    const {
      mobile,
      address,
      province,
      subdistrict,
      district,
      zipcode,
      email
    } = customerInformation || {};
    const {
      shopNote,
      trackingNo,
      releaseDate,
      trackingNo2,
      updateSecondTracking,
      deliveries,
      logisticRedelivery
    } = this.state;
    const {
      order,
      modalMessage,
      modalConfirmRefund,
      modalConfirmClearing,
      modalInvoiceDetail,
      modalConfirmLarge,
      modalLargeChildren
    } = this.props
    const { time } = releaseDate;
    const totalShippingPrice = priceShippingNet - priceShippingDiscount;
    const totalPromotionDiscountPrice = priceNet - priceItemDiscount;
    const totalBeforeDiscountVoucher = totalPromotionDiscountPrice + totalShippingPrice;
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          backdrop='static'
          style={{ width: '80%', maxWidth: '80%' }}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="d-flex justify-content-between w-100">
              <div>
                <span className='header-lable'> หมายเลขออร์เดอร์ </span>
                <span className='header-invoice-no'>{orderNo}</span>
              </div>
              {this.getDateByPaymentStatus(logisticStatus)}
            </div>
            <div className='close-btn-wrap'>
              <img src={IconClose} onClick={this.toggleModal} />
            </div>
          </ModalHeader>
          <ModalBody>
            <div id='modal-invoice-detail' className='container'>
              <div className='row'>
                {/* SECTION - รายการสั่งซื้อ */}
                <div className='col-8'>
                  <div className='card-container'>
                    <ul className='d-flex justify-content-between'>
                      <li>
                        <h6>รายการสั่งซื้อ</h6>
                        <div className='shop-profile'>
                          <span className='mr-2'>ร้านค้า : </span>
                          {logoPath ? (
                            <img
                              src={`${host.avatar}${logoPath?.path}`}
                              className='shop-profile-image'
                            />
                          ) : (
                            <img
                              src={IconShopAvatar}
                              className='shop-profile-image'
                            />
                          )}
                          {/* <img src={IconAvatar} className="shop-profile-image"></img> */}
                          <span className='font-size-12px'> {name}</span>
                        </div>
                      </li>
                      <li className='text-right'>
                        <div>
                          <span className='font-size-12px'>
                            ยืนยันคำสั่งซื้อเมื่อ :{' '}
                            {displayDateTh(createdAt, 'DD MMM yyyy')}
                          </span>
                        </div>
                        <div>
                          <span className='font-size-12px'>
                            อัปเดทล่าสุด :{' '}
                            {displayDateTh(updatedAt, 'DD MMM yyyy')}
                          </span>
                        </div>
                      </li>
                    </ul>

                    <div className='product-table'>
                      <div className='row header'>
                        <div className='col-1 text-center'>ลำดับ</div>
                        <div className='col-6 text-left'>สินค้า</div>
                        <div className='col-2 text-center'>จำนวน</div>
                        <div className='col-3 text-right'>ราคา</div>
                      </div>

                      {currentInvoice?.items &&
                        currentInvoice?.items.map((item, itemIndex) => {
                          const {
                            quantity,
                            product,
                            sku,
                            productSKU,
                            priceNet,
                          } = item || {}

                          return (
                            <React.Fragment key={`item-${itemIndex}`}>
                              <div className='row product'>
                                <div className='col-1 align--center'>
                                  {itemIndex + 1}
                                </div>
                                <div className='col-6'>
                                  <div className='product-detail'>
                                    <div className='product-detail-image'>
                                      <img
                                        src={`${host.avatar}${productSKU?.imagePath.path}`}
                                      ></img>
                                    </div>
                                    <div className='product-detail-name'>
                                      <ul>
                                        <li>
                                          <span className='name'>
                                            {product.name}
                                          </span>
                                        </li>
                                        <li>
                                          <span className='sku'>{sku}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-2 align--center'>
                                  {quantity}
                                </div>
                                <div className='col-3 align--center align--text-right'>
                                  ฿ {numeral(priceNet).format('0,0.00')}{' '}
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })}

                      <div className='row total'>
                        <div className='offset-6 col-6'>
                          <div className='total-detail'>

                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ราคาต่อหน่วยรวม</li>
                              <li className='text-right'>
                                ฿ {numeral(priceNet).format('0,0.00')}
                              </li>
                            </ul>

                            {/* ANCHOR -  promotion discount  */}
                            {priceItemDiscount && priceItemDiscount > 0 ?
                              <React.Fragment>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ส่วนลดโปรโมชั่น</li>
                                  <li className='text-right'> </li>
                                </ul>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'> - {promotionCode}</li>
                                  <li className='text-right'>
                                    ฿{' '} {numeral((0 - priceItemDiscount)).format('0,0.00',)}
                                  </li>
                                </ul>
                              </React.Fragment>
                              :
                              <React.Fragment>
                                <ul className='d-flex justify-content-between'>
                                  <li className='text-left'>ส่วนลดโปรโมชั่น</li>
                                  <li className='text-right'>฿{' '} {numeral(0).format('0,0.00',)} </li>
                                </ul>
                              </React.Fragment>
                            }
                            <hr />
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ทั้งหมด</li>
                              <li className='text-right'>฿{' '} {numeral(totalPromotionDiscountPrice).format('0,0.00',)}</li>
                            </ul>
                            <hr />

                            {/* ANCHOR -  shipping price  */}
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ค่าจัดส่ง</li>
                              <li className='text-right'>฿{' '} {numeral(priceShippingNet).format('0,0.00',)}</li>
                            </ul>
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>- ส่วนลดค่าจัดส่ง </li>
                              <li className='text-right'>฿{' '} {numeral(0 - priceShippingDiscount).format('0,0.00',)}</li>
                            </ul>
                            <hr />
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>รวมค่าจัดส่ง</li>
                              <li className='text-right'>฿{' '} {numeral(totalShippingPrice).format('0,0.00',)}</li>
                            </ul>
                            <hr />

                            {/* ANCHOR -  total price  */}
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>รวมทั้งสิ้น</li>
                              <li className='text-right'>฿{' '} {numeral(totalBeforeDiscountVoucher).format('0,0.00',)}</li>
                            </ul>

                            {/* { (priceShippingDiscount+priceItemDiscount) > 0 &&
                              <ul className='d-flex justify-content-between'>
                              <li className='text-left'>ส่วนลด {promotionCode}</li>
                              <li className='text-right'>
                                ฿{' '}
                                {numeral(0-(priceShippingDiscount+priceItemDiscount)).format(
                                  '0,0.00',
                                )}
                              </li>
                              </ul>
                            } */}

                            {/* { priceVoucherDiscount && priceVoucherDiscount > 0 && */}
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>คูปองแทนเงินสด {(priceVoucherDiscount) && (priceVoucherDiscount > 0) && `${promotionCode}`}</li>
                              <li className='text-right'>
                                ฿{' '} {numeral(0 - (priceVoucherDiscount)).format('0,0.00',)}
                              </li>
                            </ul>
                            <hr />
                            {/* } */}

                          </div>

                          {/* ANCHOR -  total summary  */}
                          <div className='total-summary'>
                            <ul className='d-flex justify-content-between'>
                              <li className='text-left'>
                                <h6>รวมจำนวนทั้งสิ้น</h6>
                              </li>
                              <li className='text-right total-price'>
                                <h6> ฿ {numeral(priceTotal).format('0,0.00')} </h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mt-4">
                      <div>

                        {order &&
                          order.status != 'waitRefund' &&
                          order.status != 'refunded' &&
                          getUserRole() !== OWNER ? (
                          <button className='btn-red ml-4' onClick={this.onRefund}>
                            ขอคืนเงิน
                          </button>
                        ) : null}
                        {/* {order && order.status == "success" ?
                          !currentInvoice?.clearingAt ? <button className="btn-submit ml-2" onClick={() => this.openModalClearing()}>โอนเงินให้ร้านค้าแล้ว</button> : <span className="text-green ml-2"> <img src={IconTrue} alt="" /> โอนเงินให้ร้านค้าแล้ว</span>
                          : null} */}
                        {order && order.status == 'waitRefund' ? (
                          <div className='ml-4 d-flex flex-column'>
                            <span className='text-error'>
                              หมายเหตุ : {issueNote}{' '}
                            </span>
                            {getUserRole() !== OWNER && <label className='mt-2'>
                              <button
                                className='btn-cancel'
                                onClick={() => this.openModalConfirm(false)}
                              >
                                ยกเลิกการขอคืนเงิน
                              </button>
                              <button
                                className='btn-submit ml-3'
                                onClick={() => this.openModalConfirm(true)}
                              >
                                คืนเงินให้ลูกค้าแล้ว
                              </button>
                            </label>}
                          </div>
                        ) : null}
                        {order && order.status == 'refunded' ? (
                          <span className='text-error ml-4'>
                            หมายเหตุ : {issueNote}{' '}
                          </span>
                        ) : null}
                      </div>
                      <div>
                        <button className='btn-cancel ml-3'
                          style={{ width: '12em' }}
                          onClick={this.onOpenModalPrintCoverSheet} >
                          <img src={IconPrint} className='mr-2' />
                                 พิมพ์ใบปะหน้าพัสดุ
                        </button>
                      </div>
                    </div>


                  </div>

                  {shipmentStatus === 'waitconfirm' &&
                    <div className="row">
                      <div className="col-12 text-right">
                        <button className='btn-submit ml-3 mt-5'
                          onClick={() => this.handleConfirmOrder()} >
                          ยืนยันรับออเดอร์
                          </button>
                      </div>
                    </div>
                  }

                </div>
                {/* !SECTION  */}
                {/* SECTION - การชำระเงิน */}
                <div className='col-4'>
                  <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <h6>หมายเหตุจากลูกค้า</h6>
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>
                      <div className='message-box'>
                        <span>{note ? note : '-'}</span>
                      </div>
                    </div>
                  </div>
                  <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <h6>การชำระเงิน</h6>
                        </li>
                        <li className='text-right'>
                          {this.checkPaymentStatus(paymentStatus)}
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>
                      <div className='payment-method'>
                        <span className='title'>ช่องทางการชำระเงิน</span>
                        <div className='payment-method-detail'>
                          <span className='detail'>
                            {this.checkPaymentMethod(paymentMethod)}
                          </span>

                          <div className="d-flex flex-column card-item mt-2">
                            <span className="title">เลขอ้างอิง 2C2P</span>
                            <span className="detail">{payment?.transactionId}</span>
                          </div>

                          {paymentedAt && (
                            <ul>
                              <li>
                                {displayDateTh(paymentedAt, 'DD MMM yyyy') +
                                  displayDateTh(paymentedAt, ' HH:mm')}
                              </li>
                              <li className='mt-2'>
                                <h6 className='color-orange'>
                                  ฿ {numeral(priceTotal).format('0,0.00')}
                                </h6>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-container'>
                    <div className='card-item'>
                      <ul className='d-flex justify-content-between'>
                        <li className='text-left'>
                          <h6>การจัดส่ง </h6>
                        </li>
                        <li className='text-right'>
                          {this.checkLogisticStatus(logisticStatus, shipmentStatus)}
                        </li>
                      </ul>
                    </div>
                    <div className='card-item'>
                      <span className='title'>ข้อมูลการจัดส่ง</span>
                      <ul className='font-size-12px'>
                        <li>{customerInformation?.name}</li>
                        <li>
                          {mobile}
                          {(getUserRole() == ADMIN || getUserRole() == SUPER_ADMIN) && ` / ${email && email}`}
                          {/* {`${email ? '/' + email : ''}`} */}
                        </li>
                        <li>{address} </li>
                        <li>
                          {' '}
                          {district} {subdistrict} {province} {zipcode}
                        </li>
                      </ul>
                    </div>


                    {deliveries && deliveries.length > 0 &&
                      deliveries?.map((delivery, idx) => (
                        <React.Fragment>
                          {/* ----------------------------redelivery status---------------------------------- */}
                          {(logisticStatus === "redelivery") && deliveries?.length > 1 && idx != deliveries?.length - 1 &&
                            <div className="second-logistic">
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <span className='title'>รูปแบบการจัดส่ง {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null}</span>
                                <div className='logistic-logo'>
                                  <img
                                    src={`${host.image}${delivery?.logistics?.logo}`}
                                  ></img>
                                </div>
                              </div>

                              <div className='card-item'>
                                <span className='title'>หมายเลขพัสดุ {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null}</span>
                                {logisticStatus !== LogisticStatus.WAIT.key ? (
                                  <div> {delivery.trackingNo || '-'} </div>
                                ) : (
                                  <div className='row pt-1'>
                                    <div className='col-9 pl-0'>
                                      <input
                                        type='text'
                                        className='form-control'
                                        placeholder='กรุณากรอกหมายเลขพัสดุ'
                                        name={`trackingNo${idx}`}
                                        onChange={this.onChangeLogisticTracking}
                                        value={trackingNo + idx}
                                      />
                                    </div>
                                    <div className='col-3 pl-0 pr-0'>
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'btn-disable':
                                                deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === '',
                                              'btn-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === ''
                                            }
                                          >
                                            บันทึก
                         </button>
                                        </TooltipNotification>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          }
                          {/* ----------------------------other status------------------------------------- */}
                          {logisticStatus !== "redelivery" && deliveries.length > 1 &&
                            <div className="second-logistic">
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <span className='title'>รูปแบบการจัดส่ง  {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null}</span>
                                <div className='logistic-logo'>
                                  {idx === deliveries.length - 1 && logisticStatus === LogisticStatus.POSTING.key && order.status !== 'waitRefund' && order.status !== 'refunded' ?
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                      defaultValue={logisticRedelivery ? logisticRedelivery : modalInvoiceDetail?.logistics ? modalInvoiceDetail?.logistics && modalInvoiceDetail?.logistics[0].value : null}
                                    /> :
                                    <img
                                      src={`${host.image}${deliveries[idx]?.logistics?.logo}`}
                                    ></img>
                                  }

                                </div>
                              </div>

                              <div className='card-item'>
                                <span className='title'> หมายเลขพัสดุ {idx !== 0 ? <span>ครั้งที่ {idx + 1}</span> : null} </span>
                                {idx === deliveries.length - 1 && logisticStatus === LogisticStatus.POSTING.key && order.status !== 'waitRefund' && order.status !== 'refunded' ? (
                                  <div className='row pt-1'>
                                    <div className='col-9 pl-0'>
                                      <input
                                        type='text'
                                        className='form-control'
                                        placeholder='กรุณากรอกหมายเลขพัสดุ'
                                        name={`trackingNo${idx}`}
                                        onChange={this.onChangeLogisticTracking}
                                        value={deliveries[idx].trackingNo}
                                      />
                                    </div>
                                    <div className='col-3 pl-0 pr-0'>
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'btn-disable':
                                                deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === '',
                                              'btn-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === ''
                                            }
                                          >
                                            บันทึก
                         </button>
                                        </TooltipNotification>
                                      </div>
                                    </div>
                                  </div>

                                ) : (
                                  <div> {delivery.trackingNo || '-'} </div>
                                )}
                              </div>
                            </div>
                          }

                          {/* ------------------------------1 delivery status != wait-------------------------------- */}
                          {deliveries?.length == 1 &&
                            <>
                              <div className='card-item mb-0' key={'delivery' + idx}>
                                <span className='title'>รูปแบบการจัดส่ง</span>
                                <div className='logistic-logo'>
                                  {(logisticStatus === LogisticStatus.WAIT.key || logisticStatus === LogisticStatus.POSTING.key) && order.status !== 'waitRefund' && order.status !== 'refunded' ?
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                      defaultValue={logisticRedelivery ? logisticRedelivery : modalInvoiceDetail?.logistics ? modalInvoiceDetail?.logistics && modalInvoiceDetail?.logistics[0].value : null}
                                    /> :
                                    <img
                                      src={`${host.image}${delivery?.logistics?.logo}`}
                                    ></img>
                                  }

                                </div>
                              </div>

                              { this.props?.orderStatus !== OrderStatus.WAIT_CONFIRM &&
                                <div className='card-item'>
                                  <span className='title'>หมายเลขพัสดุ</span>
                                  {logisticStatus !== LogisticStatus.WAIT.key && logisticStatus !== LogisticStatus.POSTING.key || order.status === 'waitRefund' || order.status === 'refunded' ? (
                                    <div> {delivery.trackingNo || '-'} </div>
                                  ) : (
                                    <div className='row pt-1'>
                                      <div className='col-9 pl-0'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          placeholder='กรุณากรอกหมายเลขพัสดุ'
                                          name={`trackingNo${idx}`}
                                          onChange={this.onChangeLogisticTracking}
                                          value={deliveries[idx].trackingNo}
                                        />
                                      </div>
                                      <div className='col-3 pl-0 pr-0'>
                                        <div className='tooltip-wrap'>
                                          <TooltipNotification toolTipId='tracking-no'>
                                            <button
                                              className={classNames('w-100', {
                                                'btn-disable':
                                                  deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === '',
                                                'btn-submit': deliveries && deliveries[idx].trackingNo,
                                              })}
                                              id='submit-tracking-no'
                                              onClick={this.updateLogistic}
                                              disabled={
                                                deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === ''
                                              }
                                            >
                                              บันทึก
                                 </button>
                                          </TooltipNotification>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              }
                            </>
                          }
                          {/* -------------------------------------------------------------- */}
                          {/* ------------------------------add new tracking------------------------------------ */}


                          {logisticStatus === LogisticStatus.REDELIVERY.key && idx === deliveries?.length - 1 ?
                            <div className={classNames({ 'second-logistic': updateSecondTracking })}>
                              {!updateSecondTracking && <button className="btn btn-cancel w-100 m-0 title" onClick={() => this.setState({ updateSecondTracking: true })}>อัพเดทหมายเลขพัสดุครั้งที่ {modalInvoiceDetail.deliveries?.length}</button>}
                              {updateSecondTracking && logisticStatus == LogisticStatus.REDELIVERY.key &&
                                <React.Fragment>
                                  <div className="d-flex flex-column">
                                    <span className='title'>รูปแบบการจัดส่ง ครั้งที่ {modalInvoiceDetail.deliveries?.length}</span>
                                    <DropdownIcon
                                      options={modalInvoiceDetail.logistics}
                                      handleSelected={this.handleSelectLogisticRedelivery}
                                    />
                                  </div>
                                  <div className="d-flex flex-column mt-1">
                                    <span className='title'>หมายเลขพัสดุ ครั้งที่ {modalInvoiceDetail.deliveries?.length}</span>
                                    <div className="d-flex flex-row">
                                      <div className="col-9 pl-0">
                                        <input
                                          type='text'
                                          className='form-control'
                                          placeholder='กรุณากรอกหมายเลขพัสดุ'
                                          name={`trackingNo${modalInvoiceDetail.deliveries?.length}`}
                                          onChange={this.onChangeLogisticTracking}
                                          value={deliveries[idx].trackingNo} />
                                      </div>
                                      {/* <button className="btn-disable">บันทึก</button> */}
                                      <div className='tooltip-wrap'>
                                        <TooltipNotification toolTipId='tracking-no'>
                                          <button
                                            className={classNames('w-100', {
                                              'btn-disable':
                                                deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === '',
                                              'btn-submit': deliveries && deliveries[idx].trackingNo,
                                            })}
                                            id='submit-tracking-no'
                                            onClick={this.updateLogistic}
                                            disabled={
                                              deliveries && deliveries[idx].trackingNo === null || deliveries && deliveries[idx].trackingNo === ''
                                            }
                                          >
                                            บันทึก
                             </button>
                                        </TooltipNotification>
                                      </div>


                                    </div>
                                  </div> </React.Fragment>}

                            </div> : null
                          }


                        </React.Fragment>
                      ))}
                    {logisticStatus === LogisticStatus.DELIVERED.key && currentInvoice?.deliveredAt &&
                      <div className="card-item">
                        <span className="title">วันเวลาส่งสำเร็จ</span>
                        <div>{currentInvoice?.deliveredAt && displayDateTh(currentInvoice?.deliveredAt, 'DD MMM yyyy')} {currentInvoice?.deliveredAt && displayDateTh(currentInvoice?.deliveredAt, 'HH:mm')}</div>
                      </div>
                    }


                    {
                      // getUserRole() == ADMIN || getUserRole() == SUPER_ADMIN &&
                      logisticStatus !== LogisticStatus.WAIT.key &&
                      // logisticStatus !== LogisticStatus.DELIVERED.key && 
                      logisticStatus !== LogisticStatus.REDELIVERY.key && order.status !== 'refunded' && order.status !== 'waitRefund' &&
                      <div className="w-75 mt-4">
                        <DropdownButton
                          handleSelected={this.handleSelectStatusLogistic}
                          options={orderStatusChange}
                          defaultValue={orderStatusChange[0].value || ''}
                        />
                      </div>
                    }


                  </div>
                  {/* !SECTION  */}
                  {/* SECTION - หมายเหตุ */}
                  {getUserRole() !== OWNER && (
                    <div className='card-container'>
                      <div className='card-item'>
                        <h6>หมายเหตุ</h6>
                      </div>
                      <div className='card-item'>
                        <textarea
                          name='shopNote'
                          id='shopNote'
                          cols='5'
                          className='form-control w-100'
                          placeholder='หมายเหตุ'
                          value={shopNote}
                          onChange={this.onChange}
                        ></textarea>
                        <div className='row mt-2'>
                          <div className='col-12 text-center'>
                            <div className='tooltip-wrap'>
                              <TooltipNotification toolTipId='shop-note'>
                                <button
                                  className='btn-submit'
                                  id='submit-shop-note'
                                  onClick={this.updateShopNote}
                                >
                                  บันทึกหมายเหตุ
                                </button>
                              </TooltipNotification>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* !SECTION  */}
                </div>
              </div>

            </div>
          </ModalBody>
          <ModalRefundReason id={id} />
          <ModalMessage
            title={modalMessage.title}
            message={modalMessage.message}
            isShowModalMessage={modalMessage.isShowModalMessage}
            btnLabel={modalMessage.btnLabel}
            confirmCallback={() =>
              modalMessage.title == 'iconSuccess'
                ? window.location.replace('/manage/order/waitRefund')
                : this.props.dispatch(
                  dispatchOrder({ type: CLOSE_MODAL_MESSAGE }),
                )
            }
          />

          {modalConfirmRefund.isOpen ? (
            <ModalConfirm
              isOpen={modalConfirmRefund.isOpen}
              message={modalConfirmRefund.message}
              btnConfirm={modalConfirmRefund.btnConfirm}
              btnCancel={modalConfirmRefund.btnCancel}
              confirm={() =>
                this.state.confirmRefund
                  ? this.onConfirmRefund(id, currentInvoice)
                  : this.onCancelRefund(id, currentInvoice)
              }
              isClose={() =>
                this.props.dispatch(
                  dispatchOrder({ type: CLOSE_MODAL_CONFIRM_REFUND }),
                )
              }
            />
          ) : null}

          {modalConfirmClearing.isOpen ? (
            <ModalConfirm
              isOpen={modalConfirmClearing.isOpen}
              message={modalConfirmClearing.message}
              btnConfirm={modalConfirmClearing.btnConfirm}
              btnCancel={modalConfirmClearing.btnCancel}
              confirm={() => this.handleClearingOrder(id)}
              isClose={() => this.closeModalClearing()}
            />
          ) : null}

          <ModalConfirmLarge
            isOpen={modalConfirmLarge.isOpen}
            message={modalConfirmLarge.message}
            btnConfirm={modalConfirmLarge.btnConfirm}
            btnCancel={modalConfirmLarge.btnCancel}
            headerMsg={<span>หมายเลขออเดอร์ <span className="color-primary"> {modalConfirmLarge.headerMsg} </span></span>}
            confirm={modalConfirmLarge?.data?.status === "wait" ? () => this.handleRedelivery() : () => { }}
            isClose={() => this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CONFIRM_LARGE }))}
          />

          <ModalLargeChildren
            isOpen={modalLargeChildren.isOpen}
            headerMsg={modalLargeChildren.headerMsg}
            isClose={() => this.props.dispatch(dispatchOrder({ type: CLOSE_MODAL_CHILDREN }))}
            confirm={() => this.onClickCompleteLogistic()}
          >
            <div>
              {
                // getUserRole() === 'SUPER_ADMIN' && 
                // deliveries && deliveries[deliveries?.length - 1]?.logistic !== 'EMS'
                // && currentInvoice?.logisticStatus !== "delivered" && currentInvoice?.logisticStatus !== "wait"
                // ? 
                <div>
                  <h4 className="d-flex align-items-start" style={{ margin: '1rem' }}>ระบุวันที่ส่งสำเร็จไปแล้ว</h4>
                  <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                    <span className="require font-primary">วันที่</span>
                    <DatePickerInput /// here
                      onSubmitDateSelected={this.onDateDatePickerInputChange}
                      allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                      date={new Date(this.state.releaseDate.date)}
                    />
                  </div>

                  <div className='d-flex align-items-start flex-column' style={{ margin: '1rem' }}>
                    <span className="require font-primary">เวลา</span>
                    <TimePicker
                      onTimePickerChange={this.onTimePickerChange}
                      value={time}
                    />
                  </div>

                  {/* <button
                              className={classNames({
                                'btn-disable':
                                  this.state.trackingNo.trim().length == 0,
                                'btn-submit': this.state.trackingNo,
                              })}
                              id='submit-tracking-no'
                              onClick={this.onClickCompleteLogistic}
                              disabled={this.state.trackingNo.trim().length == 0}
                            >
                              จัดส่งสำเร็จ
                            </button> */}
                </div>
                // :
                // null
              }
            </div>
          </ModalLargeChildren>

          <Loading />
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  modalInvoiceDetail: state.order.modalInvoiceDetail,
  modalMessage: state.modalMessage,
  modalConfirm: state.modalConfirm,
  modalConfirmRefund: state.order.modalConfirm,
  modalConfirmClearing: state.order.modalConfirmClearing,
  modalConfirmLarge: state.order.modalConfirmLarge,
  modalLargeChildren: state.order.modalLargeChildren,
  orderStatus: state.order.status
})

export default connect(mapStateToProps)(ModalInvoiceDetail);
