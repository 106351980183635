import { createReducer } from 'redux-act';
import {
  auth,
  newMessage,
  join,
  leave,
  onLiveUser,
  onProduct,
  onLike,
  onUnLike,
  onEndLive,
  connectSocket,
  setField,
  login,
  onStartLive,
} from '../actions/socket'

const initial = {
    isLogin: false,
    isConnected: false,
    user: null,
    id: null,
    liveUser: 0,
    like: 0,
    isLike: false,
    isLiveProduct: false,
    product: {
      index: 0,
      id: null,
      showOn: null,
    },
    listMessage: [],
    isEndLive: false,
    isStartLive: false
};

export default createReducer({
  [login]: (state,payload) => {
    return {...state,isLogin:payload.isLogin}
  },
  [connectSocket]: (state,payload) => {
    return {...state,isConnected: payload.connected}
  },
  [join]: (state,payload) => {
    return {...state,id:payload.roomId}
  },
  [auth]: (state,payload) => {
    return {...state,user: payload.user}
  },
  [onLiveUser]: (state,payload) => {
    return {...state, liveUser: payload.liveUser}
  },
  [onProduct]: (state,payload) => {
    const live = payload.index > 0
    return {...state, product: payload, isLiveProduct: live}
  },
  [newMessage]: (state,payload) => {
    const {message} = payload
    return {...state,listMessage:[...state.listMessage,message]}
  },
  [onLike]: (state,payload) => {
    const {countLike} = payload
    return {...state, isLike: true, like: countLike}
  },
  [onUnLike]: (state,payload) => {
    return {...state, isLike: false}
  },
  [onEndLive]: (state,payload) => {
    return {...state,isEndLive:payload.isEndLive }
  },
  [onStartLive]: (state,payload) => {
    return {...state,isStartLive:payload.isStartLive}
  },
  [setField]: (state,payload) => {
    return {...state,[payload.key]:payload.value}
  }
}, initial)