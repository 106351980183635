import React, { Component } from 'react'
import { Input } from 'reactstrap'
import SelectInput from 'components/SelectInput'
import TextArea from 'antd/lib/input/TextArea'
import { Radio } from 'antd'
import shopProvider from 'provider/shopProvider'
import {
  checkZipCode,
  isMobileOrTel,
  objectIsEmpty,
  textIsEmpty,
} from 'helper/utils'
import {
  dispatchShop,
  HANDLE_UPDATE_SHOP_TAX,
  GET_SHOP_TAX,
  SET_SHOP_TAX,
} from 'redux/actions/shop'
import { connect } from 'react-redux'
import ModalMessage from 'components/modalMessage'

const shopProvider_api = new shopProvider()

const initialState = {
  tax: {
    typeShop: 'company',
    companyName: '',
    typeTax: 'branch',
    taxNo: '',
    taxBranch: '',
  },
  address: {
    address: '',
    province: {},
    district: {},
    subDistrict: {},
    posCode: '',
    addressTaxNo: '',
    telCompany: '',
  },
  contact: {
    contactName: '',
    tel: '',
    lineId: '',
    email: '',
  },
  bank: {
    accountNo: '',
    bankBranch: '',
    accountName: '',
  },
  listProvince: [],
  listDistrict: [],
  listSubDistrict: [],
  provinceSelected: null,
  districtSelected: null,
  subDistrictSelected: null,
  error: {},
  shopTax: {},
}

class ShopTax extends Component {
  static defaultProps = {
    taxType: 'company',
  }

  constructor(props) {
    super(props)
    this.state = {
      ...initialState,
    }
  }

  componentDidMount() {
    this.props.dispatch(dispatchShop({ type: GET_SHOP_TAX }))
    this.getListProvince()
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.shopTax !== initialState.shopTax) {
      const { shopTax } = nextProps
      this.setState({
        tax: {
          typeShop: shopTax?.taxType || 'company',
          companyName: shopTax?.name || '',
          // typeTax: 'branch',
          taxNo: shopTax?.taxNo || '',
          taxBranch: shopTax?.branch || '',
        },
        address: {
          address: shopTax?.address1 || '',
          province: shopTax?.province || {},
          district: shopTax?.district || {},
          subDistrict: shopTax?.subdistrict || {},
          posCode: shopTax?.zipcode || '',
          telCompany: shopTax?.mobile,
        },
        provinceSelected: shopTax.provinceSelected,
        districtSelected: shopTax.districtSelected,
        subDistrictSelected: shopTax.subDistrictSelected,
        // listProvince: shopTax.listProvince ,
        listDistrict: shopTax.listDistrict || [],
        listSubDistrict: shopTax.listSubDistrict || [],
        shopTax: {
          address: shopTax?.address1 || '',
          province: shopTax?.province || {},
          district: shopTax?.district || {},
          subDistrict: shopTax?.subdistrict || {},
          posCode: shopTax?.zipcode || '',
          telCompany: shopTax?.mobile,
          typeShop: shopTax?.taxType || 'company',
          companyName: shopTax?.name || '',
          // typeTax: 'branch',
          taxNo: shopTax?.taxNo || '',
          taxBranch: shopTax?.branch || '',
        },
      })
    }
  }

  componentWillUnmount() {
    this.setState({ ...initialState })
    this.props.dispatch(dispatchShop({ type: SET_SHOP_TAX, payload: {} }))
  }

  onChangeInput = (type, name, e) => {
    if(e.target.maxLength !== -1 && e.target.value.trim().length > e.target.maxLength) return
    this.setState({
      [type]: {
        ...this.state[type],
        [name]: e.target.value,
      },
    })
  }

  getListProvince = async () => {
    const listProvice = await shopProvider_api.getListProvince()
    const listProvinces = []
    listProvice.data.map((province) =>
      listProvinces.push({ label: province.provinceName, value: province }),
    )
    this.setState({ listProvince: listProvinces })
  }

  getDistrict = async (idProvince) => {
    this.setState({
      address: {
        ...this.state.address,
        district: {},
        subDistrict: {},
        posCode: '',
      },
      districtSelected: null,
      subDistrictSelected: null,
      listSubDistrict: [],
    })
    const getDistrict = await shopProvider_api.getListDistrict(idProvince)
    const listDistrict = []
    getDistrict.data.map((district) =>
      listDistrict.push({ label: district.districtName, value: district }),
    )
    this.setState({ listDistrict })
  }

  getSubDistrict = async (idDistrict) => {
    this.setState({
      address: { ...this.state.address, subDistrict: {}, posCode: '' },
      subDistrictSelected: null,
    })
    const getSubDistrict = await shopProvider_api.getListSubDistrict(idDistrict)
    const listSubDistrict = []
    getSubDistrict.data.map((subDistrict) =>
      listSubDistrict.push({
        label: `${subDistrict.subdistrictName} - ${subDistrict.zipcode}`,
        value: subDistrict,
      }),
    )
    this.setState({ listSubDistrict })
  }

  onChangeSelectInput = async (type, name, value, option, setIndex) => {
    if (name === 'province') {
      await this.getDistrict(option[value].value.provinceId)
    }
    if (name === 'district') {
      await this.getSubDistrict(option[value].value.districtId)
    }

    if (name === 'subDistrict') {
      await this.setState({
        address: {
          ...this.state.address,
          posCode: option[value].value.zipcode,
        },
      })
    }

    this.setState({
      [type]: {
        ...this.state[type],
        [name]: option[value].value,
      },
      [setIndex]: value,
    })
  }

  onClearSearch = (type, name) => {
    this.setState({
      [type]: {
        [name]: '',
      },
    })
  }

  validate = () => {
    let err = {}
    const { tax, address, contact, bank } = this.state

    err.companyName = textIsEmpty(tax.companyName)
    err.taxNo = textIsEmpty(tax.taxNo) || tax.taxNo.trim().length < 13 || tax.taxNo.trim().length > 13
    if (tax.typeShop === 'company') err.taxBranch = textIsEmpty(tax.taxBranch)
    err.address = textIsEmpty(address.address)
    err.province = objectIsEmpty(address.province)
    err.district = objectIsEmpty(address.district)
    err.subDistrict = objectIsEmpty(address.subDistrict)
    err.telCompany =
      textIsEmpty(address.telCompany) || !isMobileOrTel(address.telCompany)
    err.posCode = !checkZipCode(address.posCode)
    // err.addressTaxNo = textIsEmpty(address.addressTaxNo)

    // err.contactName = textIsEmpty(contact.contactName)
    // err.tel = textIsEmpty(contact.tel) || !isMobileOrTel(contact.tel)
    // err.lineId = textIsEmpty(contact.lineId)
    // err.email = textIsEmpty(contact.email)

    // err.accountName = textIsEmpty(bank.accountName)
    // err.accountNo = textIsEmpty(bank.accountNo)
    // err.bankBranch = textIsEmpty(bank.bankBranch)

    Object.keys(err).filter((i) => !err[i] && delete err[i])

    this.setState({ error: err })
    return objectIsEmpty(err) ? true : false
  }

  checkMobile = (e) => {
    const { name, value } = e.target
    let err = this.state.error
    if (isMobileOrTel(value) && !this.handleCheckText(e)) {
      delete err[name]
    } else {
      err = { ...err, [name]: true }
    }
    this.setState({ error: err })
  }

  handleCheckText = (e) => {
    let err = this.state.error
    const { name, value } = e.target
    if (!textIsEmpty(value)) {
      delete err[name]
    }
    this.setState({ error: err })
  }

  handleCheckObject = (value, name) => {
    let err = this.state.error
    if (!objectIsEmpty(value)) {
      delete err[name]
    }
    this.setState({ error: err })
  }

  handleUpdateShopTax = () => {
    const validate = this.validate()
    const { tax, address } = this.state
    if (validate) {
      this.props.dispatch(
        dispatchShop({
          type: HANDLE_UPDATE_SHOP_TAX,
          payload: { tax, address },
        }),
      )
    } else {
    }
  }

  splitMsg = (value) => {
    if (value) {
      const split = value?.split('-')
      return split[0]
    }
  }

  resetEditShoptax = () => {
    this.props.dispatch(dispatchShop({ type: GET_SHOP_TAX }))
    this.setState({
      error: {},
    })
  }

  checkDataWasChanged = () => {
    const dataShopTax = {
      ...this.state.address,
      ...this.state.tax,
    }
    return JSON.stringify(dataShopTax) !== JSON.stringify(this.state.shopTax)
  }

  render() {
    const {
      listProvince,
      provinceSelected,
      listDistrict,
      districtSelected,
      listSubDistrict,
      subDistrictSelected,
      error,
    } = this.state
    const { typeTax, typeShop, taxNo, taxBranch, companyName } = this.state.tax
    const {
      posCode,
      telCompany,
      addressTaxNo,
      address,
      province,
      district,
      subDistrict,
    } = this.state.address
    const { contactName, tel, lineId, email } = this.state.contact
    const { accountNo, accountName, bankBranch } = this.state.bank
    const symbolsArr = ["e", "E", "+", "-", "."]
    return (
      <React.Fragment>
        <div className='shop-detail-general-container'>
          <section
            className='flex-column shop-detail-container'
            style={{ gap: '30px' }}
          >
            <h4>ข้อมูลทางภาษี</h4>

            <div className='flex-column' style={{ gap: '20px' }}>
              <b>ประเภทของร้านค้า</b>
              <div className='shop-tax-radio'>
                <Radio.Group
                  onChange={(e) => this.onChangeInput('tax', 'typeShop', e)}
                  value={typeShop}
                  className='font-primary'
                >
                  <Radio value={'company'}>
                    <span>นิติบุคคล</span>
                  </Radio>
                  <Radio value={'individual'}>
                    <span>บุคคลธรรมดา</span>
                  </Radio>
                </Radio.Group>
              </div>
            </div>

            <div className='flex-row justify-content-between font-primary'>
              <div className='w-45'>
                <span className='require '>
                  {typeShop !== 'individual' ? 'ชื่อบริษัท' : 'ชื่อ-นามสกุล'}
                  </span>
                {/* <SelectInput /> */}
                <Input
                  name='companyName'
                  onChange={(e) => this.onChangeInput('tax', 'companyName', e)}
                  value={companyName}
                  onBlur={(e) => this.handleCheckText(e)}
                />
                {error.companyName ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
              <div className='w-45'>
                <span className='require '>
                  {typeShop !== 'individual' ? 'เลขทะเบียนนิติบุคคลหรือทะเบียนพาณิชย์' : 'เลขประจำตัวผู้เสียภาษี'}
                </span>
                <Input
                type="number"
                  id='taxNo'
                  name='taxNo'
                  onChange={(e) => this.onChangeInput('tax', 'taxNo', e)}
                  value={taxNo}
                  onBlur={(e) => this.handleCheckText(e)}
                  onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                  maxLength='13'
                />
                {error.taxNo ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
            </div>

            <div className='d-flex justify-content-between align-items-center font-primary'>
              {/* <div className="flex-column w-45" style={{ gap: '10px' }}>
                                <span>ประเภทบริษัท</span>
                                <div className="shop-tax-radio">
                                    <Radio.Group onChange={(e) => this.onChangeInput('tax', 'typeTax', e)} value={typeTax}>
                                        <Radio value={'main'}> <span>สำนักงานใหญ่</span> </Radio>
                                        <Radio value={'branch'}> <span>สาขา</span> </Radio>
                                    </Radio.Group>
                                </div>
                            </div> */}

              {typeShop === 'company' && (
                <div className='w-45'>
                  <span className='require '>ระบุสาขา </span>
                  <Input
                    id='taxBranch'
                    name='taxBranch'
                    onChange={(e) => this.onChangeInput('tax', 'taxBranch', e)}
                    value={taxBranch}
                    onBlur={(e) => this.handleCheckText(e)}
                  />
                  {error.taxBranch ? (
                    <span className='text-danger'>
                      กรุณากรอกข้อมูลให้ถูกต้อง
                    </span>
                  ) : null}
                </div>
              )}
            </div>

            <h4>ที่อยู่บริษัท</h4>

            <div className='flex-column font-primary'>
              <span className='require '>ถนน/บ้าน/บ้านเลขที่ </span>
              <TextArea
                name='address'
                id='address'
                cols='30'
                rows='5'
                onChange={(e) => this.onChangeInput('address', 'address', e)}
                onBlur={(e) => this.handleCheckText(e)}
                value={address}
              ></TextArea>
              {error.address ? (
                <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
              ) : null}
            </div>

            <div className='flex-row justify-content-between font-primary'>
              <div className='w-45'>
                <span className='require '>จังหวัด</span>
                <SelectInput
                  name='province'
                  options={listProvince}
                  placeholder={'ค้นหาจังหวัด'}
                  onChange={(e) =>
                    this.onChangeSelectInput(
                      'address',
                      'province',
                      e,
                      listProvince,
                      'provinceSelected',
                    )
                  }
                  value={provinceSelected}
                  onBlur={() => this.handleCheckObject(province, 'province')}
                  iconClearSearch={false}
                />
                {error.province ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
              <div className='w-45'>
                <span className='require '>เขต/อำเภอ </span>
                <SelectInput
                  name='district'
                  options={listDistrict}
                  placeholder={'ค้นหาเขต/อำเภอ'}
                  onChange={(e) =>
                    this.onChangeSelectInput(
                      'address',
                      'district',
                      e,
                      listDistrict,
                      'districtSelected',
                    )
                  }
                  value={districtSelected}
                  onBlur={() => this.handleCheckObject(district, 'district')}
                  iconClearSearch={false}
                />
                {error.district ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
            </div>

            <div className='flex-row justify-content-between font-primary'>
              <div className='w-45'>
                <span className='require '>แขวง/ตำบล </span>
                <SelectInput
                  name='subDistrict'
                  options={listSubDistrict}
                  placeholder={'ค้นหาแขวง/ตำบล'}
                  onChange={(e) =>
                    this.onChangeSelectInput(
                      'address',
                      'subDistrict',
                      e,
                      listSubDistrict,
                      'subDistrictSelected',
                    )
                  }
                  value={this.splitMsg(
                    this.state.listSubDistrict[subDistrictSelected]?.label,
                  )}
                  onBlur={() =>
                    this.handleCheckObject(subDistrict, 'subDistrict')
                  }
                  iconClearSearch={false}
                />
                {error.subDistrict ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
              <div className='w-45'>
                <span className='require '>รหัสไปรษณีย์ </span>
                <Input
                  name='posCode'
                  onChange={(e) => this.onChangeInput('address', 'posCode', e)}
                  value={posCode}
                  maxLength='5'
                />
                {error.posCode ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
            </div>

            <div className='flex-row justify-content-between font-primary'>
              {/* <div className="w-45">
                                <span className="require ">เลขประจำตัวผู้เสียภาษี </span>
                                <Input name="addressTaxNo" onChange={(e) => this.onChangeInput('address', 'addressTaxNo', e)} value={addressTaxNo} onBlur={(e) => this.handleCheckText(e)} />
                                {error.addressTaxNo ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div> */}
              <div className='w-45'>
                <span className='require '>เบอร์โทรบริษัท </span>
                <Input
                type="number"
                  name='telCompany'
                  onChange={(e) =>
                    this.onChangeInput('address', 'telCompany', e)
                  }
                  value={telCompany}
                  onBlur={(e) => this.checkMobile(e)}
                  maxLength='10'
                  onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                />
                {error.telCompany ? (
                  <span className='text-danger'>กรุณากรอกข้อมูลให้ถูกต้อง</span>
                ) : null}
              </div>
            </div>
          </section>

          {/* <section className="flex-column shop-detail-container" style={{ gap: '30px' }}>
                        <h4>ข้อมูลติดต่อ</h4>
                        <div className="flex-row justify-content-between">
                            <div className="w-45">
                                <span className="require">ชื่อนามสกุล </span>
                                <Input name="contactName" onChange={(e) => this.onChangeInput('contact', 'contactName', e)} value={contactName} onBlur={(e) => this.handleCheckText(e)} />
                                {error.contactName ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                            <div className="w-45">
                                <span className="require">เบอร์โทรผู้ติดต่อ  </span>
                                <Input name="tel" onChange={(e) => this.onChangeInput('contact', 'tel', e)} value={tel} onBlur={(e) => this.checkMobile(e)} />
                                {error.tel ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>
                        <div className="flex-row justify-content-between">
                            <div className="w-45">
                                <span className="require">ID LINE </span>
                                <Input name="lineId" onChange={(e) => this.onChangeInput('contact', 'lineId', e)} value={lineId} onBlur={(e) => this.handleCheckText(e)} />
                                {error.lineId ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                            <div className="w-45">
                                <span className="require">อีเมล </span>
                                <Input name="email" onChange={(e) => this.onChangeInput('contact', 'email', e)} value={email} onBlur={(e) => this.handleCheckText(e)} />
                                {error.email ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>

                        <b>ข้อมูลบัญชีธนาคารเพื่อนำส่งรายได้</b>



                        <div className="flex-row justify-content-between">
                            <div className="w-45">
                                <span className="require" name="accountNo">เลขที่บัญชี </span>
                                <Input name="accountNo" onChange={(e) => this.onChangeInput('bank', 'accountNo', e)} value={accountNo} onBlur={(e) => this.handleCheckText(e)} />
                                {error.accountNo ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                            <div className="w-45">
                                <span className="require">สาขา </span>
                                <Input name="bankBranch" onChange={(e) => this.onChangeInput('bank', 'bankBranch', e)} value={bankBranch} onBlur={(e) => this.handleCheckText(e)} />
                                {error.bankBranch ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>

                        <div className="flex-row justify-content-between">
                            <div className="w-45">
                                <span className="require">ชื่อบัญชี(ต้องตรงกับชื่อนิติบุคคลเท่านั้น) </span>
                                <Input name="accountName" onChange={(e) => this.onChangeInput('bank', 'accountName', e)} value={accountName} onBlur={(e) => this.handleCheckText(e)} />
                                {error.accountName ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>


                    </section> */}
        </div>

        {this.checkDataWasChanged() && (
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-ignore'
              onClick={() => this.resetEditShoptax()}
            >
              ยกเลิกแก้ไข
            </button>
            <button
              className='btn btn-confirm'
              onClick={() => this.handleUpdateShopTax()}
            >
              บันทึก
            </button>
          </div>
        )}

        <ModalMessage />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  shop: state.shop,
  shopTax: state.shop.shopTax,
})

export default connect(mapStateToProps)(ShopTax)
