import React, { useEffect, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import host from 'config/host'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import closeImage from 'images/icons/close-image.svg'

const SortablePhoto = SortableElement(({ value, idx, onRemoveImage, mode, index, key }) => {
  return (
    <span className='item-product-container' key={key}>
      {value.type === 'video' ? (
        <>
          <video className='image' style={{ width: '100%' }}>
            <source src={host.video + value.path} type='video/mp4' />
          </video>
          <FontAwesomeIcon
            icon={faPlayCircle}
            style={{
              top: '-1rem',
              right: 0,
              left: 0,
              bottom: 0,
              margin: 'auto',
              position: 'absolute',
              fontSize: '2rem',
              color: '#e0e0e0',
              zIndex: 99,
            }}
          />
        </>
      ) : (
        <img
          src={host.image + value.path}
          alt=''
          className='image'
          style={{ width: '100%' }}
        />
      )}

      {mode !== 'VIEW' && (
        <div className='middle' onClick={() => onRemoveImage(value, idx)}>
          <img
            alt=''
            src={closeImage}
            className='position-absolute remove-image-product'
          />
        </div>
      )}
    </span>
  )
})

const SortableList = SortableContainer(({ items, onRemoveImage, mode }) => (
  <div className='d-flex flex-wrap'>
    {items.map((value, index) => {
      return (
        <SortablePhoto
          key={`item-${value.id}`}
          idx={index}
          value={value}
          onRemoveImage={onRemoveImage}
          mode={mode}
          index={index}
        />
      )
    })}
  </div>
))

const ImageSortable = (props) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    if (props.data) {
      setItems(props.data)
    }
  }, [props.data])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex))
    props.onSortLists(arrayMove(items, oldIndex, newIndex))
  }

  return (
    <SortableList
      items={items}
      onSortEnd={onSortEnd}
      axis='xy'
      helperClass='sortableHelper'
      onRemoveImage={props.onRemoveImage}
      mode={props.mode}
    />
  )
}

export default ImageSortable
