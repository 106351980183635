import React, { useState, useEffect } from 'react'
import { ListUser, ListChat, SideBarDetail } from 'components/outputChat'

import 'styles/chat/chatRoom.scss'

const Chat = (props) => {
  const [dataUser, setDataUser] = useState({})
  const [idSelected, setIdSelected] = useState('')
  const [isShowChat, setIsShowChat] = useState(false)
  const [pin, setPin] = useState(false)
  const [announce, setAnnounce] = useState(false)

  useEffect(() => {
    document.getElementById('content').classList.toggle('hide-sidebar')
    if (window.name) {
      setIdChat(window.name)
    }
  }, [])

  const setUser = async (data) => {
    let userArrange = {}
    await Promise.all(
      await data?.map((user, index) => {
        userArrange[user._id] = user
      }),
    )

    setDataUser(userArrange)
  }

  const setIdChat = (id) => {
    setIdSelected(id)
    setIsShowChat(true)
  }

  return (
    <div>
      <div className='div-all'>
        <div className='div-list-user'>
          <ListUser
            setDataUser={setUser}
            setIdChat={setIdChat}
            idSelected={idSelected}
          />
          {/* <ListUser
            setIdChat={this.setIdChat}
            setDataUser={this.setDataUser}
            setReadChat={this.setReadChat}
            idSelected={this.state.id}
            disableChat={this.disableChat}
            setPin={this.setPin}
            setAnnounce={this.setAnnounce}
            setAnnounceMsg={this.setAnnounceMsg}
            setPlatform={this.setPlatform}
          /> */}
        </div>

        <div className='div-list-chat'>
          <ListChat
            isShowChatWithoutUser={!!window.name}
            dataUser={dataUser}
            idSelected={idSelected}
            isShowChat={isShowChat}
          />
          {/* <ListChat
            isShowChat={this.state.isShowChat}
            id={this.state.id}
            dataUser={this.state.dataUser}
            announce={this.state.announce}
            announceMsg={this.state.announceMsg}
            platform={this.state.platform}
          /> */}
        </div>
        {/* <SideBarDetail
          dataUser={dataUser}
          idSelected={idSelected}
          isShowChat={isShowChat}
          pin={pin}
          setPin={(value) => setPin(value)}
          announce={announce}
          setAnnounce={(value) => setAnnounce(value)}
        /> */}

        {/* <SideBarDetail
          isShowChat={this.state.isShowChat}
          dataUser={this.state.dataUser}
          id={this.state.id}
          pin={this.state.pin}
          setPin={this.setPin}
          announce={this.state.announce}
          setAnnounce={this.setAnnounce}
          announceMsg={this.state.announceMsg}
          setAnnounceMsg={this.setAnnounceMsg}
          platform={this.state.platform}
          updateDataUser={this.updateDataUser}
        /> */}
      </div>
    </div>
  )
}

export default Chat
