import TextArea from 'antd/lib/input/TextArea'
import ModalMessage from 'components/modalMessage'
import SelectInput from 'components/SelectInput'
import { checkZipCode, isMobileOrTel, objectIsEmpty, textIsEmpty } from 'helper/utils'
import React, { Component } from 'react'
import { Input } from 'reactstrap'
import { connect } from 'react-redux'
import { ADD_KEY_VALUE_SHOP, CREATE_SHOPS_HUBS, dispatchShop, GET_SHOPS_HUBS } from 'redux/actions/shop'
import shopProvider from 'provider/shopProvider'

const shopProvider_api = new shopProvider()
const initialState = {
    address: {
        address1: '',
        province: {},
        district: {},
        subDistrict: {},
        zipcode: '',
        addressTaxNo: '',
        mobile: ''
    },

    listProvince: [],
    listDistrict: [],
    listSubDistrict: [],
    provinceSelected: null,
    districtSelected: null,
    subDistrictSelected: null,
    error: {},
    shopTax: {},

}

class ShopHub extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState
        }
    }

    componentDidMount() {
        const { shopDetail } = this.props
        this.props.dispatch(dispatchShop({ type: GET_SHOPS_HUBS, payload: { shopId: shopDetail?.id } }))
        this.props.dispatch(dispatchShop({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'shopsHubs', value: [] } }))
        this.getListProvince()
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.address !== nextProps.shopsHubs[0]) {
            this.setState({
                address: {
                    ...nextProps.shopsHubs[0],
                    province: nextProps.shopsHubs[0]?.province || {},
                    district: nextProps.shopsHubs[0]?.district || {},
                    subDistrict: nextProps.shopsHubs[0]?.subDistrict || {},
                },
                provinceSelected: nextProps.shopsHubs[0]?.provinceSelected,
                districtSelected: nextProps.shopsHubs[0]?.districtSelected,
                subDistrictSelected: nextProps.shopsHubs[0]?.subDistrictSelected,
                listDistrict: nextProps.shopsHubs[0]?.listDistrict || [],
                listSubDistrict: nextProps.shopsHubs[0]?.listSubDistrict || [],
            })
        }
    }

    handleCheckText = (e) => {
        let err = this.state.error
        const { name, value } = e.target
        if (!textIsEmpty(value)) {
            delete err[name]
        }
        this.setState({ error: err })
    }

    handleCheckObject = (value, name) => {
        let err = this.state.error
        if (!objectIsEmpty(value)) {
            delete err[name]
        }
        this.setState({ error: err })
    }

    splitMsg = (value) => {
        if (value) {
            const split = value?.split('-')
            return split[0]
        }
    }

    onChangeInput = (type, name, e) => {
        this.setState({
            [type]: {
                ...this.state[type],
                [name]: e.target.value
            }
        })
    }

    onChangeSelectInput = async (type, name, value, option, setIndex) => {
        if (name === 'province') {
            await this.getDistrict(option[value].value.provinceId)
        }
        if (name === 'district') {
            await this.getSubDistrict(option[value].value.districtId)
        }

        if (name === 'subDistrict') {
            await this.setState({ address: { ...this.state.address, zipcode: option[value].value.zipcode } })
        }

        this.setState({
            [type]: {
                ...this.state[type],
                [name]: option[value].value
            },
            [setIndex]: value
        })
    }
    getListProvince = async () => {
        const listProvice = await shopProvider_api.getListProvince()
        const listProvinces = []
        listProvice.data.map((province) => listProvinces.push({ label: province.provinceName, value: province }))
        this.setState({ listProvince: listProvinces })
    }

    getDistrict = async (idProvince) => {
        this.setState({ address: { ...this.state.address, district: {}, subDistrict: {}, zipcode: '' }, districtSelected: null, subDistrictSelected: null, listSubDistrict: [] })
        const getDistrict = await shopProvider_api.getListDistrict(idProvince)
        const listDistrict = []
        getDistrict.data.map((district) => listDistrict.push({ label: district.districtName, value: district }))
        this.setState({ listDistrict })
    }

    getSubDistrict = async (idDistrict) => {
        this.setState({ address: { ...this.state.address, subDistrict: {}, zipcode: '' }, subDistrictSelected: null })
        const getSubDistrict = await shopProvider_api.getListSubDistrict(idDistrict)
        const listSubDistrict = []
        getSubDistrict.data.map((subDistrict) => listSubDistrict.push({ label: `${subDistrict.subdistrictName} - ${subDistrict.zipcode}`, value: subDistrict }))
        this.setState({ listSubDistrict })
    }
    checkMobile = (e) => {
        const { name, value } = e.target
        let err = this.state.error
        if (isMobileOrTel(value) && !this.handleCheckText(e)) {
            delete err[name]
        } else {
            err = { ...err, [name]: true }
        }
        this.setState({ error: err })
    }

    updateShopHubs = () => {
        const { shopDetail, shopHub } = this.props
        const { address } = this.state

        const validate = this.validate()

        if (validate) {
            const body = {
                shopId: shopDetail.id,
                name: shopDetail.name,
                mobile: address.mobile,
                address1: address.address1,
                subdistrict: address.subDistrict.subdistrictName,
                district: address.district.districtName,
                province: address.province.provinceName,
                zipcode: address.zipcode.toString()
            }
            this.props.dispatch(dispatchShop({ type: CREATE_SHOPS_HUBS, payload: { ...body } }),
            )
        }
    }
    validate = () => {
        let err = {}
        const { address } = this.state
        err.address1 = textIsEmpty(address.address1)
        err.province = objectIsEmpty(address.province)
        err.district = objectIsEmpty(address.district)
        err.subDistrict = objectIsEmpty(address.subDistrict)
        err.mobile = textIsEmpty(address.mobile) || !isMobileOrTel(address.mobile)
        err.zipcode = !checkZipCode(address.zipcode)
        Object.keys(err).filter(i => !err[i] && delete err[i])
        this.setState({ error: err })
        return objectIsEmpty(err) ? true : false
    }

    resetEditShoptax = () => {
        this.props.dispatch(dispatchShop({ type: GET_SHOPS_HUBS, payload: { shopId: this.props.shopDetail.id } }))
        this.setState({
            error: {}
        })
    }

    checkDataWasChanged = () => {
        return JSON.stringify(this.props.shopsHubs && this.props.shopsHubs[0]) !== JSON.stringify(this.state.address)
    }


    render() {
        const { listProvince, provinceSelected, listDistrict, districtSelected, listSubDistrict, subDistrictSelected, error } = this.state
        const { zipcode, mobile, addressTaxNo, address1, province, district, subDistrict } = this.state.address
        const { shopsHubs } = this.props
        return (
            <React.Fragment>
                <div className="shop-detail-general-container">
                    <div className="shop-detail-container flex-column" style={{ gap: '30px' }}>


                        <h4>ที่อยู่ร้านค้า</h4>

                        <div className="flex-column font-primary">
                            <span className="require ">ถนน/บ้าน/บ้านเลขที่  </span>
                            <TextArea name="address1" id="address" cols="30" rows="5" onChange={(e) => this.onChangeInput('address', 'address1', e)} onBlur={(e) => this.handleCheckText(e)} value={address1}></TextArea>
                            {error.address1 ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                        </div>

                        <div className="flex-row justify-content-between font-primary">
                            <div className="w-45">
                                <span className="require ">จังหวัด</span>
                                <SelectInput
                                    name="province"
                                    options={listProvince}
                                    placeholder={'ค้นหาจังหวัด'}
                                    onChange={(e) => this.onChangeSelectInput('address', 'province', e, listProvince, 'provinceSelected')}
                                    value={provinceSelected}
                                    onBlur={() => this.handleCheckObject(province, 'province')}
                                    iconClearSearch={false}
                                />
                                {error.province ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                            <div className="w-45">
                                <span className="require ">เขต/อำเภอ  </span>
                                <SelectInput
                                    name="district"
                                    options={listDistrict}
                                    placeholder={'ค้นหาเขต/อำเภอ'}
                                    onChange={(e) => this.onChangeSelectInput('address', 'district', e, listDistrict, 'districtSelected')}
                                    value={districtSelected}
                                    onBlur={() => this.handleCheckObject(district, 'district')}
                                    iconClearSearch={false}
                                />
                                {error.district ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>

                        <div className="flex-row justify-content-between font-primary">
                            <div className="w-45">
                                <span className="require ">แขวง/ตำบล </span>
                                <SelectInput
                                    name="subDistrict"
                                    options={listSubDistrict}
                                    placeholder={'ค้นหาแขวง/ตำบล'}
                                    onChange={(e) => this.onChangeSelectInput('address', 'subDistrict', e, listSubDistrict, 'subDistrictSelected')}
                                    value={this.splitMsg(this.state.listSubDistrict[subDistrictSelected]?.label)}
                                    onBlur={() => this.handleCheckObject(subDistrict, 'subDistrict')}
                                    iconClearSearch={false}
                                />
                                {error.subDistrict ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                            <div className="w-45">
                                <span className="require ">รหัสไปรษณีย์ </span>
                                <Input name="zipcode" onChange={(e) => this.onChangeInput('address', 'zipcode', e)} value={zipcode} maxLength="5" />
                                {error.zipcode ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>

                        <div className="flex-row justify-content-between font-primary">
                            <div className="w-45">
                                <span className="require ">เบอร์โทรบริษัท </span>
                                <Input name="mobile" onChange={(e) => this.onChangeInput('address', 'mobile', e)} value={mobile} onBlur={(e) => this.checkMobile(e)} maxLength="10" />
                                {error.mobile ? <span className="text-danger">กรุณากรอกข้อมูลให้ถูกต้อง</span> : null}
                            </div>
                        </div>
                    </div>
                </div>


                {this.checkDataWasChanged() && <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-ignore" onClick={() => this.resetEditShoptax()}>ยกเลิกแก้ไข</button>
                    <button className="btn btn-confirm" onClick={() => this.updateShopHubs()}>บันทึก</button>
                </div>}

                <ModalMessage
                />

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    shopDetail: state.shop.shopDetail,
    shopsHubs: state.shop.shopsHubs,
})


export default connect(mapStateToProps)(ShopHub)