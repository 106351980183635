import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts"; 

const makePdf = (payload) => {

    const { docDifinition, fileName, option } = payload || {}
    try {
        
        pdfmake.vfs = pdfFonts.pdfMake.vfs;
        pdfmake.fonts = {
            BaiJamjuree: {
                bold:"BaiJamjuree-Bold.ttf",
                bolditalics:"BaiJamjuree-BoldItalic.ttf",
                normal:"BaiJamjuree-Regular.ttf",
                italics:"BaiJamjuree-Italic.ttf"
            },
            Roboto: {
                italics:"Roboto-Italic.ttf",
                bold:"Roboto-Medium.ttf",
                bolditalics:"Roboto-MediumItalic.ttf",
                normal:"Roboto-Regular.ttf",
            },
            Kanit: {
                bolditalics:"Kanit-BlackItalic.ttf",
                bold:"Kanit-Bold.ttf",
                italics:"Kanit-Italic.ttf",
                normal:"Kanit-Regular.ttf",
            }
        }

        if(option === 'download')
        pdfmake.createPdf(docDifinition).download(fileName);
        if(option === 'print')
        pdfmake.createPdf(docDifinition).print();

    } catch (ex) {

    }
  }

 

  export  {
    makePdf
  }