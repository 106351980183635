import React from 'react'
import styled from 'styled-components'
import Checkbox from 'components/Checkbox'
import 'styles/_variables.scss'

// const dataHeader = ['check', 'ลำดับ', 'หมายเลขออเดอร์', 'ค่าสินค้า', 'ค่าขนส่ง', 'ค่าธรรมเนียมการขายสินค้า', 'รายได้สุทธิ', 'รายได้สุทธิ', 'อัปเดตล่าสุด']

const TableHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex: row;
    flex-wrap: nowrap;
    overflow: hidden;
    border-radius: ${props => props.tableType === 'header' ? '10px 10px 0 0' : 'none'};
    border: ${props => props.tableType === 'header' ? '1px solid #383633' : 'none'};
    background-color: ${props => props.tableType === 'header' ? '#383633' : 'white'};
    margin-bottom: ${props => props.tableType === 'header' ? '0' : '1em'};
    line-break: auto;
    word-break: break-word;
    height: ${props => props.height ? props.height : 'auto'};

    &:hover {
        background-color: ${props => props.tableType === 'content' && props.hover ? '#EEEEEE' : ''}
    }

    div {
        /* padding: 1.5em; */
    }
`

const checkColSide = (side) => {
    if (side === 'left') return 'flex-start'
    if (side === 'center') return 'center'
    if (side === 'right') return 'flex-end'
}

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: ${props => props.padding ? props.padding : props.tableType === 'header' ? '1em 0' : '2em 0'};
    color: ${props => props.color ? props.color : props.tableType === 'header' ? '#FFFFFF' : 'black'};
    font-family: ${props => props.tableType === 'header' ? 'Kanit !important' : 'Bai_Jamjuree !important'};
    cursor: ${props => props.hover === true ? 'pointer' : ''};
    background-color: ${props => props.backgroundColor}; 
    overflow: hidden;
    line-break: auto;
    word-break: break-word;
    height: 100%;

    &.item-0 {
        width: ${props => props.colWidth[0]};
        justify-content: ${props => checkColSide(props.colSide[0])};
        text-align: center;
    }
    &.item-1 {
        display: flex;
        color: ${props => props.tableType === 'header' ? 'white' : '#FF8D10'};
        width: ${props => props.colWidth[1]};
        justify-content: ${props => checkColSide(props.colSide[1])};
        text-align: center;
    }
    &.item-2 {
        display:flex;
        width: ${props => props.colWidth[2]};
        justify-content: ${props => checkColSide(props.colSide[2])};
        text-align: center;
    }
    &.item-3 {
        display:flex;
        width: ${props => props.colWidth[3]};
        justify-content: ${props => checkColSide(props.colSide[3])};
        text-align: center;
    }
    &.item-4 {
        display:flex;
        width: ${props => props.colWidth[4]};
        justify-content: ${props => checkColSide(props.colSide[4])};
        text-align: center;
    }
    &.item-5 {
        display:flex;
        width: ${props => props.colWidth[5]};
        justify-content: ${props => checkColSide(props.colSide[5])};
        text-align: center;
        
    }
    &.item-6 {
        display:flex;
        box-sizing: border-box;
        width: ${props => props.colWidth[6]};
        justify-content: ${props => checkColSide(props.colSide[6])};
        text-align: center;
    }
    &.item-7 {
        display:flex;
        width: ${props => props.colWidth[7]};
        justify-content: ${props => checkColSide(props.colSide[7])};
        padding: ${props => props.dataHeader[8] === null && '0'};
        text-align: center;
    }
    &.item-8 {
        display:flex;
        width: ${props => props.colWidth[8]};
        justify-content: ${props => checkColSide(props.colSide[8])};
        padding: ${props => props.dataHeader[8] === null && '0'};
        text-align: center;
        
    }
    &.item-9 {
        display:flex;
        width: ${props => props.colWidth[9]};
        justify-content: ${props => checkColSide(props.colSide[9])};
        padding: ${props => props.dataHeader[9] === null && '0'};
        text-align: center;
    }
    &.item-10 {
        text-align: center;
        display:flex;
        width: ${props => props.colWidth[10]};
        justify-content: ${props => checkColSide(props.colSide[10])};
        padding: ${props => props.dataHeader[10] === null && '0'};
    }

    &.item-11 {
        text-align: center;
        display:flex;
        width: ${props => props.colWidth[11]};
        justify-content: ${props => checkColSide(props.colSide[11])};
        padding: ${props => props.dataHeader[11] === null && '0'};
    }

    &.item-12 {
        text-align: center;
        display:flex;
        width: ${props => props.colWidth[12]};
        justify-content: ${props => checkColSide(props.colSide[12])};
        padding: ${props => props.dataHeader[12] === null && '0'};
    }
`

const TableHeader = props => {
    const { dataHeader, type, selectBox, colWidth, hover, handleClick, colSide, padding, backgroundColor, color, height } = props
    return (
        <TableHeaderContainer tableType={type} hover={hover} onClick={handleClick} height={height}>
            {dataHeader.map((head, idx) => (
                <Content className={`item-${idx}`}
                    tableType={type}
                    selectBox={selectBox}
                    dataHeader={dataHeader}
                    colWidth={colWidth}
                    colSide={colSide}
                    hover={hover}
                    key={`col-${idx}`}
                    padding={padding}
                    backgroundColor={backgroundColor}
                    color={color}
                    >
                        {head}
                </Content>
            ))}
        </TableHeaderContainer>
    )
}

TableHeader.defaultProps = {
    dataHeader: [<Checkbox />, 'ลำดับ', 'หมายเลขออเดอร์', 'ค่าสินค้า', 'ค่าขนส่ง', 'ค่าธรรมเนียมการชำระเงิน', 'ค่าธรรมเนียมการขายสินค้า', 'รายได้สุทธิ', 'วันที่ชำระเงิน', 'อัปเดตล่าสุด'],
    colWidth: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
    tableType: 'header',
    selectBox: false,
    hover: false,
    colSide: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
    // backgroundColor: 'white'
}



export default TableHeader
