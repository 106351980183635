import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import './styles.scss'

const Textarea = (props) => {
  const {
    label,
    defaultValue,
    handleChangeText,
    placeholder,
    error,
    require,
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onChangeText = (e) => {
    setValue(e.target.value)
    handleChangeText(e.target.value)
  }

  return (
    <div className='textarea-field'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}
      <div className={classnames('container-input', {  error: !!error  })}>
        <textarea
          className='input-box'
          placeholder={placeholder}
          onChange={onChangeText}
          value={value}
          rows='3'
        />
      </div>
      {error && <div className='txt-red mt-1'>{error}</div>}
    </div>
  )
}

Textarea.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  handleChangeText: () => {},
  error: '',
  require: false,
}

export default Textarea
