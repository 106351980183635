import React from 'react'
import './styles.scss'
import host from '../../../config/host';
import IconShopAvatar from '../../../images/icons/default-shop-logo.svg';

const ProfileStorage = (props) => {
  const {
    detail: {
      used, 
      storageSizes,
      shop: { name, logoPath },
    },
  } = props

  return (
    <div className='profile-storage'>
      <div className='group-header'>
        <img alt='' className='avatar' src={logoPath ? `${host.avatar}${logoPath?.path}` : IconShopAvatar} />
        <div className='text-name ellipsis'>{name}</div>
      </div>
      <div className='group-storage'>
        <div>พื้นที่จัดเก็บ</div>
        <div>{`${used}/${storageSizes} MB`}</div>
      </div>
      <div className='progress-box'>
        <div
          className='progress'
          style={{ width: `${(used * 100) / storageSizes}%` }}
        />
      </div>
    </div>
  )
}

export default ProfileStorage
