import { initial } from "lodash";

import {
    OPEN_MODAL_UPLOAD_IMAGE,
    ADD_IMAGE_BASE64
    // ADD_IMAGE_PATH
} from '../actions/modalUploadImage'


const initialState = {
    isOpen: false,
    name: null,
    imgBase64: null
}

export default function (state = initialState, action) {
    const { payload } = action
    switch (action.type) {
        case ADD_IMAGE_BASE64:
            return {
                ...state,
                imgBase64: payload.imgBase64,
                fileName: payload.fileName
            }
        case OPEN_MODAL_UPLOAD_IMAGE:
            return {
                ...state,
                isOpen: payload.isOpen,
                name: payload.name ? payload.name : state.name
            }
        // case ADD_IMAGE_PATH :
        //     return {
        //         ...state,
        //         coverImage: payload.coverImage,
        //         pathImage: payload.pathImage
        //     }
        default:
            return state
    }

}