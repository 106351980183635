export const SET_GALLERY_FIELD = "SET_GALLERY_FIELD"
export const GET_SHOP_GALLERY = "GET_SHOP_GALLERY"
export const SET_SHOP_GALLERY = "SET_SHOP_GALLERY"
export const GET_ALBUM_GALLERY = "GET_ALBUM_GALLERY"
export const SET_ALBUM_GALLERY = "SET_ALBUM_GALLERY"
export const CREATE_ALBUM_GALLERY = "CREATE_ALBUM_GALLERY"
export const UPDATE_ALBUM_GALLERY = "UPDATE_ALBUM_GALLERY"
export const SEARCH_ALBUM_GALLERY = "SEARCH_ALBUM_GALLERY"
export const GET_MEDIA_GALLERY = "GET_MEDIA_GALLERY"
export const SET_MEDIA_GALLERY = "SET_MEDIA_GALLERY"
export const CREATE_MEDIA_GALLERY = "CREATE_MEDIA_GALLERY"
export const UPDATE_MEDIA_GALLERY = "UPDATE_MEDIA_GALLERY"
export const DELETE_MEDIA_GALLERY = "DELETE_MEDIA_GALLERY"
export const SEARCH_MEDIA_GALLERY = "SEARCH_MEDIA_GALLERY"
export const SET_MEDIA_UPLOAD_EVENT = "SET_MEDIA_UPLOAD_EVENT"
export const GET_ALBUM_DEFAULT = "GET_ALBUM_DEFAULT"


export function dispatchGallery(param){
    return {
      type: param.type,
      payload: param.payload,
      etc: param.etc
    };
  }