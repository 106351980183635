import {
  SET_PAGE,
  SET_PLAYLIST_FRONT_PAGE,
  // SET_PLAYLIST_FRONT_PAGE_ITEMS
} from '../actions/playlistFrontPage'

const initialState = {
  pages: null,
  frontPage: null,
  // playlistsItems: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_PAGE: {
      return {
        ...state,
        pages: payload,
      }
    }

    case SET_PLAYLIST_FRONT_PAGE: {
      return {
        ...state,
        frontPage: payload,
      }
    }

    // case SET_PLAYLIST_FRONT_PAGE_ITEMS: {
    //   return {
    //     ...state,
    //     playlistsItems: payload,
    //   }
    // }

    default:
      return state
  }
}
