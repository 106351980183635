import React, { Component } from "react";
import { connect } from "react-redux";

import { productByShop, post, productss } from './mock'

import { decimalToInteger } from 'helper/utils';
import  './index.scss';
import noItem from '../../images/icons/status-error.svg'

import InputNumber from 'components/InputNumber';
import ProductItem from 'components/ManageLiveProduct/ProductItem';

const initialState = {
    extraPriceTemplate: {
        discountPrice: '',
        discountPercent: '',
        buyLimit: ''
    },
    products: []
};

class ManageLiveProduct extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState));
    return state;
  };

  componentDidUpdate = prevProps => {
      if(this.props.products !== prevProps.products) 
         this.setState({products: this.props.products});
  }

  /* SECTION - event function */
  onExtraPriceTemplateChange = (value, key, params) => {
    if(this.state.elementName === key) {
      if (key === 'discountPrice') {
          this.setState({ extraPriceTemplate : 
            {...this.state.extraPriceTemplate , 
             discountPrice: value.floatValue ,
             discountPercent: ''}},() => this.forceUpdate())
      }
      else if (key === 'discountPercent') {
        this.setState({ extraPriceTemplate : 
          {...this.state.extraPriceTemplate , 
            discountPercent: value.floatValue > 100 ? 100 : value.floatValue ,
            discountPrice: ''}},() => this.forceUpdate())
      }
      else {
          this.setState({ extraPriceTemplate : 
                          {...this.state.extraPriceTemplate , 
                           [key]: value.floatValue }},() => this.forceUpdate())
      }
    }
  }

  onExtraPriceChange = (value, key, params) => { 
    if(this.state.elementName === key) {
      const { productIndex, productSkuIndex } = params;
      const attrName = key;
      let attrValue = typeof value.floatValue === 'number'? value.floatValue : '';

      let { products } = JSON.parse(JSON.stringify(this.state));

      if (attrName === 'discountPercent' || attrName === 'discountPrice') 
        products[productIndex].productSkus[productSkuIndex].errors = {}
      
      const extraPrice = products[productIndex].productSkus[productSkuIndex].extraPrice;
      const { priceNet } = products[productIndex].productSkus[productSkuIndex];

      if (attrName === 'discountPrice' && typeof attrValue === 'number' && attrValue > priceNet) 
            attrValue = priceNet;
      if (attrName === 'discountPercent' && typeof attrValue === 'number' && attrValue > 100) 
            attrValue = 100

      const { discountPercent, discountPrice, buyLimit } = extraPrice;

      if ((attrName === 'discountPercent' || attrName === 'discountPrice') 
          && attrValue !== null 
          && attrValue !== undefined 
          && attrValue >= 0) {

        if (typeof attrValue === 'number') {
                  extraPrice.discountPrice = attrName === 'discountPercent'
                                             ? decimalToInteger(priceNet * ((100 - attrValue) / 100))
                                             : decimalToInteger(attrValue);
          
                  extraPrice.discountPercent = attrName === 'discountPrice'
                                               ? decimalToInteger(((priceNet - attrValue) * 100) / priceNet)
                                               : decimalToInteger(attrValue);
          
                  if (attrName === 'discountPrice' && extraPrice.discountPrice < 0) 
                    extraPrice.discountPrice = 0
                  
                  if (attrName === 'discountPercent' && extraPrice.discountPercent < 0) 
                    extraPrice.discountPercent = 0
                  
                  if ( attrName === 'discountPercent' && extraPrice.discountPercent > 100 ) 
                    extraPrice.discountPercent = 100
        } else {
          extraPrice['discountPercent'] = '';
          extraPrice['discountPrice'] = '';
        }
        
      } else {
        extraPrice[attrName] = attrValue;
      }

      products[productIndex].productSkus[productSkuIndex].extraPrice = extraPrice;
      this.setState({products},() => this.updateProducts(products))
    }
  } 

  onExtraPriceDelete = ({productIndex, productSkuIndex}) => {

      let { products } = JSON.parse(JSON.stringify(this.state));
      products[productIndex].productSkus[productSkuIndex].select = false
      this.setState({products},() => this.updateProducts(products))
  }

  onApplyExtraPriceToProduct = () => {
    const { buyLimit, discountPercent, discountPrice, } = this.state.extraPriceTemplate;
    
      let { products } = JSON.parse(JSON.stringify(this.state));
      const _products = products.map((product) => {
        
        const productSkus = product.productSkus.map((productSku) => {
          productSku.errors = {}
          if (productSku?.select && productSku?.select == true) {
            const { priceNet } = productSku;
            
            if(typeof discountPercent === 'number'|| typeof discountPrice === 'number') {
              
              /* NOTE - provide discount Price */
                productSku.extraPrice.discountPrice =
                  typeof discountPercent === 'number' &&  discountPercent >= 0
                    ? decimalToInteger(priceNet * ((100 - discountPercent) / 100))
                    : decimalToInteger(priceNet - discountPrice);
  
              /* NOTE - provide discount percent */
                productSku.extraPrice.discountPercent =
                typeof discountPrice === 'number' && discountPrice >= 0
                    ? decimalToInteger(100 - ((priceNet - discountPrice) * 100) / priceNet)
                    : decimalToInteger(discountPercent);
              }
              
              productSku.extraPrice.buyLimit = buyLimit
  
              /* NOTE - provide error */
              if (productSku.extraPrice.discountPrice < 0) {
                productSku.errors.discountPrice = true
              }
              if ( productSku.extraPrice.discountPercent < 0 ||
                productSku.extraPrice.discountPercent > 100 ) {
                productSku.errors.discountPercent = true
              }
            }
            return productSku
          })
          product.productSkus = productSkus
          return product
        })

        this.setState({products: _products}, this.updateProducts(_products))
  }

  onElementFocus = elementName => this.setState({elementName})
  /* !SECTION - event function */

  /* SECTION - common function */
  updateProducts = products => {
    this.forceUpdate();
    this.props.onProductsChange(products);
  }
  countProductSelected = products => {
    let count = 0;
    if(products) 
    count = products.reduce((total, current) => {
      for( const sku of current.productSkus ){
           if(sku.select && sku.select === true)
               total++;
      }
       return total;
   },0)
   return count;
  }
  /* !SECTION - common function */

  render() {
    // const products = Array.from({ length: 5 });
    const {
        discountPrice,
        discountPercent,
        buyLimit
    } = this.state.extraPriceTemplate;
    const { products } = this.state;
    return <React.Fragment>
        <div className="container-products">
            <div className="d-flex justify-content-between">

                {/* ANCHOR - price template */}
                {/* ANCHOR - price form */}
                <div className="row" style={{width:'79%', maxWidth: '508px'}}>
                    {/* ANCHOR - discountPrice */}
                    <div className="col" style={{width:'32%'}}>
                                <div className="title-sm">ราคาที่ลดแล้ว (บาท) <span className="require"></span> </div> 
                                <div className="title-detail-sm mb-1">ส่วนลดแบบบาท </div> 
                                <div className='input-group'>
                                    <InputNumber
                                            value={discountPrice}
                                            name={'discountPrice'}
                                            type={'COMMON'}
                                            className={`form-control`}
                                            placeholder={'0'}
                                            onFocus={this.onElementFocus}
                                            onChange={this.onExtraPriceTemplateChange}
                                    />
                                    <div className='input-group-append'>
                                        <span className='input-group-text text-number '>
                                            ฿
                                        </span>
                                    </div>
                                </div>
                        </div>
                    <div style={{alignSelf:"flex-end", width:'4%'}} className="title-sm">หรือ</div>
                    {/* ANCHOR - discountPercent */}
                    <div className="col" style={{width:'32%'}}>
                            <div className="title-sm">ส่วนลด <span className="require"></span> </div> 
                            <div className="title-detail-sm mb-1">คิดเป็นเปอร์เซนต์</div> 
                            <div className='input-group'>
                                <InputNumber
                                        value={discountPercent}
                                        name={'discountPercent'}
                                        type={'COMMON'}
                                        className={`form-control`}
                                        placeholder={'0'}
                                        onFocus={this.onElementFocus}
                                        onChange={this.onExtraPriceTemplateChange}
                                    />
                                <div className='input-group-append'>
                                    <span className='input-group-text text-number '>
                                        %
                                    </span>
                                </div>
                            </div>
                    </div>
                    {/* ANCHOR - buyLimit */}
                    <div className="col" style={{width:'32%'}}>
                            <div className="title-sm">จำกัดการซื้อ</div> 
                            <div className="title-detail-sm mb-1">จำนวนจำกัดต่อการซื้อ 1 ครั้ง</div> 
                            <div className='input-group'>
                                <InputNumber
                                        value={buyLimit}
                                        name={'buyLimit'}
                                        type={'COMMON'}
                                        className={`form-control`}
                                        placeholder={'0'}
                                        onFocus={this.onElementFocus}
                                        onChange={this.onExtraPriceTemplateChange}
                                    />
                            </div>
                    </div>
                </div>
                
                {/* ANCHOR - apply button */}
                <div className="row text-right" style={{width:'24%', alignSelf:'flex-end'}}>
                    <button className='btn-cancel w-100' 
                            style={{marginRight:'12px'}}
                            onClick={this.onApplyExtraPriceToProduct}>นำไปใช้กับสินค้าทั้งหมด</button>
                </div>
            
            </div>
            {this.countProductSelected(products) === 0 && 
                <div className="item-contailer">
                  <img src={noItem} alt="noItem" />
                  <span className="mt-2 font-secondary">ไม่มีรายการ</span>
                </div>
            }
            { products.map((product, productIndex) => <ProductItem  key={productIndex} 
                                                                    product={product} 
                                                                    onChange={this.onExtraPriceChange}
                                                                    onDelete={this.onExtraPriceDelete}
                                                                    onElementFocus={this.onElementFocus}
                                                                    productIndex={productIndex}></ProductItem>) }
        </div>
    </React.Fragment>;
  }
}

const mapStateToProps = (state) => ({
  post: state.post.post,
});

export default connect(mapStateToProps)(ManageLiveProduct);
