import IconBag from '../../images/icons/menu/menu-bag.svg';
import IconShop from '../../images/icons/menu/menu-shop.svg';

// import { PERMISSION_HEADER_MANAGE,
//          PERMISSION_HEADER_USER,
//          PERMISSION_HEADER_REPORT } from '../../config/permission';

import {
  USER,
  MANAGE,
  REPORT,
  SETTING,
  CHAT,
  CHAT_SUMMARY,
} from '../../config/groupTabMenu'

import Cookies from 'js-cookie';
import { ADMIN, OWNER, SUPER_ADMIN } from '../Sidebar/Role'

export default {
  items: [
    {
      name: 'จัดการ',
      url: '/manage',
      isIndex: true,
      linkUrl: '/manage/shop',
      icon: IconShop,
      // permission: PERMISSION_HEADER_MANAGE,
      group: MANAGE,
      role: [ADMIN, OWNER],
    },
    {
      name: 'คลังภาพและวิดีโอ',
      url: '/gallery',
      // permission: PERMISSION_MEDIA,
      role: [ADMIN],
    },
    {
      name: 'ข้อความ',
      url: '/chat',
      // permission: PERMISSION_MEDIA,
      group: CHAT,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    // {
    //   name: 'แชทรวม',
    //   url: '/chat-summary',
    //   // permission: PERMISSION_MEDIA,
    //   group: CHAT_SUMMARY,
    //   role: [SUPER_ADMIN, ADMIN],
    // },

    // {
    //     name: 'ผู้ใช้งาน',
    //     url: '/user',
    //     icon: IconBag,
    //     permission: PERMISSION_HEADER_USER,
    //     group: USER
    // },
    // {
    //     name: 'รายงาน',
    //     url: '/report',
    //     icon: IconBag,
    //     permission: PERMISSION_HEADER_REPORT,
    //     group: REPORT
    // },
    // {
    //     name: 'ตั้งค่า',
    //     url: '/setting',
    //     icon: IconBag,
    //     permission: PERMISSION_HEADER_REPORT,
    //     group: SETTING
    // }
  ],
}