import React from 'react';
import Radio from 'antd/lib/radio';  // for js
import './index.scss';
const RadioGroup = Radio.Group;

const RadioComponent = (props) => {
    const { onChange, value, valueOption, disabled, name } = props;
    const radioStyle = {
        display: "block",
        "marginBottom": "5px",
        "borderRadius": "3px"
    };

    return (
        <RadioGroup onChange={onChange} value={valueOption} disabled={disabled} name={name}>
            <Radio
                value={value}
                name={name}
                style={radioStyle}
            />
        </RadioGroup>
    )

};

export default RadioComponent;
