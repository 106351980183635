import shopProvider from '../../provider/shopProvider';
import Cookies from 'js-cookie'
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { SET_LOADING } from '../actions/app';
import { putWait, withCallback } from 'redux-saga-callback';
import {
  GET_LIST_SHOP,
  SET_LIST_SHOP_NAME,
  GET_SHOPS,
  SET_SHOPS,
  GET_SHOPS_SEARCH,
  SET_SHOPS_SEARCH,
  GET_SHOP_DETAIL,
  SET_SHOP_DETAIL,
  ADD_KEY_VALUE_SHOP,
  ON_SEARCH_SHOP,
  SET_PAGINATION_SHOP_DETAIL,
  SET_DATA_SHOP_DETAIL,
  GET_ALL_SHOPS,
  SET_ALL_SHOPS,
  CREATE_SHOP,
  CREATE_SHOP_FIELD,
  UPDATE_SHOP_DETAIL,
  GET_SHOPS_HUBS,
  SET_SHOPS_HUBS,
  CREATE_SHOPS_HUBS,
  HANDLE_UPDATE_SHOP_TAX,
  GET_SHOP_TAX,
  SET_SHOP_TAX,
  GET_CURRENT_SHOP_DETAIL,
  SET_CURRENT_SHOP
} from '../actions/shop'
import { getShop } from './selectors';
import shop from '../reducers/shop';
import { ADD_KEY_VALUE_PRODUCT, GET_PRODUCTS_BY_SHOP, GET_PRODUCT_CATEGORIES, ON_GET_BRANDS, ADD_VALUE_IN_PRODUCT } from 'redux/actions/product';
import { GET_LOGISTIC_LIST } from 'redux/actions/logistic';
import { GET_POST_BY_SHOP_ID } from 'redux/actions/post';
import { SET_GALLERY_FIELD } from 'redux/actions/gallery'
import { OPEN_MODAL_MESSAGE_ERROR, OPEN_MODAL_MESSAGE } from 'redux/actions/modalMessage';
import { update } from 'lodash';


const api_shop = new shopProvider()

export function* onGetListShopName(action) {
  const getListShop = yield call(api_shop.getAllListShops)
  yield put({ type: SET_LIST_SHOP_NAME, payload: { listShopName: getListShop } })
}

export function* onGetShops(action) {
  yield put({ type: SET_LOADING, payload: { loading: true } })
  try {
    const getShopState = yield select(getShop)
    const shops = yield call(api_shop.getShops, { limit: getShopState.limit, page: getShopState.page })
    yield put({ type: SET_SHOPS, payload: { shops: shops?.shop } })
    yield put({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'total', value: shops?.count } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
  }
  yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onGetAllShops(action) {
  yield put({ type: SET_LOADING, payload: { loading: true } })
  try {
    const getShopState = yield select(getShop)
    const shops = yield call(api_shop.getAllShops, {})
    yield put({ type: SET_ALL_SHOPS, payload: { allShops: shops?.shop } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })

  }
  yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onGetShopsSearch(action) {
  const { payload } = action
  try {
    const shopsSearch = yield call(api_shop.getShopsSearch, payload)
    yield put({ type: SET_SHOPS_SEARCH, payload: { shopsSearch } })
  } catch (error) {

  }

}

export function* onGetShopDetail(action) {
  try {
    // const {payload} = action;
    // const param = { shopId: payload.shopId }

    const getShopState = yield select(getShop);
    const { currentShop } = getShopState;
    const param = { shopId: currentShop.id }

    const shopDetail = yield call(api_shop.getShopDetail, param);
    yield put({ type: SET_SHOP_DETAIL, payload: { shopDetail } });
  } catch (ex) {

  }
}

export function* onSearchShop(action) {
  const { payload } = action
  const getShopState = yield select(getShop)
  try {
    const shops = yield call(api_shop.onSearchShop, { search: payload.search, limit: getShopState.limit, page: getShopState.page })
    yield put({ type: SET_SHOPS, payload: { shops: shops.data.shop } })
    yield put({ type: ADD_KEY_VALUE_SHOP, payload: { key: 'total', value: shops.data.count } })
  } catch (error) {
    yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
  }
}

export function* onGetDataShopDetail(action) {
  try {
    // yield put({type: SET_LOADING,payload:{loading: true}})
    let getCurrentShop = yield select(getShop)
    const getListShop = yield call(api_shop.getAllListShops)
    yield put({ type: SET_LIST_SHOP_NAME, payload: { listShopName: getListShop } })
    getCurrentShop = yield select(getShop)
    if (getListShop.some((shop) => shop.id == getCurrentShop.currentShop.id)) {
      yield put({ type: GET_PRODUCT_CATEGORIES })
      yield put({ type: ON_GET_BRANDS })
      yield put({ type: GET_LOGISTIC_LIST })
      yield put({ type: GET_PRODUCTS_BY_SHOP, payload: { shopId: getCurrentShop.currentShop.id } })
      yield put({ type: GET_SHOP_DETAIL, payload: { shopId: getCurrentShop.currentShop.id } })
      yield put({ type: GET_POST_BY_SHOP_ID, payload: { shopId: getCurrentShop.currentShop.id } })
    }
    // yield put({type: SET_LOADING,payload:{loading: false}})
  } catch (error) {

  }
 
}

export function* handleUpdateShopTax(action) {
  const { payload } = action
  try {
    const getShopState = yield select(getShop);
    const { currentShop } = getShopState;
    let data = {
      shopId: currentShop.id,
      name: payload.tax.companyName,
      taxType: payload.tax.typeShop,
      taxNo: payload.tax.taxNo,
      branch: payload.tax.typeShop === "individual" ? null : payload.tax.taxBranch,
      mobile: payload.address.telCompany,
      address1: payload.address.address,
      subdistrict: payload.address.subDistrict.subdistrictName,
      district: payload.address.district.districtName,
      province: payload.address.province.provinceName,
      zipcode: payload.address.posCode.toString(),
    }

    if(payload.tax.typeShop === "individual") delete data.branch

    if (Object.keys(getShopState.shopTax).length === 0) {
      const res = yield call(api_shop.craeteShopTax, data)
      if (res) {
        yield put({
          type: OPEN_MODAL_MESSAGE, payload: {
            title: 'iconSuccess',
            message: "แก้ไขข้อมูลภาษีสำเร็จ",
            btnLabel: 'ตกลง',
          }
        })
        yield onGetShopTax()
      }
    } else {
      delete data.shopId
      const res = yield call(api_shop.editShopTax, { id: getShopState.shopTax.id, data })
      if (res) {
        yield put({
          type: OPEN_MODAL_MESSAGE, payload: {
            title: 'iconSuccess',
            message: "แก้ไขข้อมูลภาษีสำเร็จ",
            btnLabel: 'ตกลง',
          }
        })
      }
      yield onGetShopTax()
    }
  } catch (error) {
    console.log('error', error)
    yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
  }
}

export function* onGetShopTax(action) {
  try {
    yield put({ type: SET_LOADING, payload: { loading: true } })
    const getShopState = yield select(getShop);
    const { currentShop } = getShopState;
    const res = yield call(api_shop.getShopTax, currentShop.id)
    if (res.data !== null && res.data.province !== undefined) {
      const province = yield call(api_shop.getListProvince)
      const getProvince = province.data.filter((province) => province.provinceName === res.data.province)
      const getIndexProvince = province.data.findIndex((province) => province.provinceName === res.data.province)
      const district = yield call(api_shop.getListDistrict, getProvince[0].provinceId)
      const getDistrict = district.data.filter((district) => district.districtName === res.data.district)
      const getIndexDistrict = district.data.findIndex((district) => district.districtName === res.data.district)
      const subDistrict = yield call(api_shop.getListSubDistrict, getDistrict[0].districtId)
      const getSubDistrict = subDistrict.data.filter((subDistrict) => subDistrict.subdistrictName === res.data.subdistrict && subDistrict.zipcode == res.data.zipcode)
      const getIndexSubDistrict = subDistrict.data.findIndex((subDistrict) => subDistrict.subdistrictName === res.data.subdistrict && subDistrict.zipcode.toString() === res.data.zipcode)
      const setDataProvince = []
      province.data.map(province => setDataProvince.push({ label: province.provinceName, value: province }))
      const setDataDistrict = []
      district.data.map(district => setDataDistrict.push({ label: district.districtName, value: district }))
      const setDataSubDistrict = []
      subDistrict.data.map(subDistrict => setDataSubDistrict.push({ label: `${subDistrict.subdistrictName} - ${subDistrict.zipcode}`, value: subDistrict }))

      yield put({
        type: SET_SHOP_TAX, payload: {
          ...res.data,
          province: getProvince[0],
          district: getDistrict[0],
          subdistrict: getSubDistrict[0],
          provinceSelected: getIndexProvince,
          districtSelected: getIndexDistrict,
          subDistrictSelected: getIndexSubDistrict,
          listProvince: setDataProvince,
          listDistrict: setDataDistrict,
          listSubDistrict: setDataSubDistrict
        }
      })
    } else {
      yield put({ type: SET_SHOP_TAX, payload: { ...res.data } })
    }

    yield put({ type: SET_LOADING, payload: { loading: false } })

  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
    console.log('error', error)
  }
}

export function* onCreateShop(action) {
  const { payload } = action

  try {
    const shops = yield call(api_shop.createShop, payload)


    if (shops.code == 422) {
      switch (shops.message) {

        case "CREATE_SHOP_ERROR":

          yield put({
            type: CREATE_SHOP_FIELD,
            payload: {
              name: 'ชื่อร้านค้านี้มีการใช้แล้ว กรุณากรอกใหม่อีกครั้ง',
            },
          })
          break

        default:
          break
      }
    } else {
      yield put({
        type: CREATE_SHOP_FIELD,
        payload: 'success',
      })
      yield put({ type: GET_SHOPS })
    }
  } catch (err) {
    throw err
  }
}

export function* onUpdateShopDetail(action) {
  const { payload } = action

  try {
    const shopDetail = yield call(api_shop.updateShopDetail, payload)
    yield put({
      type: SET_GALLERY_FIELD,
      payload: { key: 'avatar', value: null },
    })
    yield put({
      type: SET_GALLERY_FIELD,
      payload: { key: 'coverShop', value: null },
    })
    yield put({ type: GET_SHOP_DETAIL })
  } catch (err) {
    throw err
  }
}


export function* onGetShopsHubs(action) {
  const { payload } = action
  yield put({ type: SET_LOADING, payload: { loading: true } })
  try {
    let shopsHubs = yield call(api_shop.getShopsHubs, payload)

    if (shopsHubs !== null && shopsHubs[0].province !== undefined) {
      const province = yield call(api_shop.getListProvince)
      const getProvince = province.data.filter((province) => province.provinceName === shopsHubs[0].province)
      const getIndexProvince = province.data.findIndex((province) => province.provinceName === shopsHubs[0].province)
      const district = yield call(api_shop.getListDistrict, getProvince[0].provinceId)
      const getDistrict = district.data.filter((district) => district.districtName === shopsHubs[0].district)
      const getIndexDistrict = district.data.findIndex((district) => district.districtName === shopsHubs[0].district)
      const subDistrict = yield call(api_shop.getListSubDistrict, getDistrict[0].districtId)
      const getSubDistrict = subDistrict.data.filter((subDistrict) => subDistrict.subdistrictName === shopsHubs[0].subdistrict && subDistrict.zipcode == shopsHubs[0].zipcode)
      const getIndexSubDistrict = subDistrict.data.findIndex((subDistrict) => subDistrict.subdistrictName === shopsHubs[0].subdistrict && subDistrict.zipcode.toString() === shopsHubs[0].zipcode)
      const setDataProvince = []
      province.data.map(province => setDataProvince.push({ label: province.provinceName, value: province }))
      const setDataDistrict = []
      district.data.map(district => setDataDistrict.push({ label: district.districtName, value: district }))
      const setDataSubDistrict = []
      subDistrict.data.map(subDistrict => setDataSubDistrict.push({ label: `${subDistrict.subdistrictName} - ${subDistrict.zipcode}`, value: subDistrict }))


      shopsHubs[0] = {
        ...shopsHubs[0],
        province: getProvince[0],
        district: getDistrict[0],
        subDistrict: getSubDistrict[0],
        provinceSelected: getIndexProvince,
        districtSelected: getIndexDistrict,
        subDistrictSelected: getIndexSubDistrict,
        listProvince: setDataProvince,
        listDistrict: setDataDistrict,
        listSubDistrict: setDataSubDistrict
      }

      yield put({
        type: SET_SHOPS_HUBS, payload: {
          shopsHubs: {
            ...shopsHubs,
          }
        }
      })
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
  } catch (error) {
    yield put({ type: SET_LOADING, payload: { loading: false } })
  }
}

export function* onCreateShopsHubs(action) {
  const { payload } = action
  try {

    const shopState = yield select(getShop)

    if (shopState.shopsHubs !== null && Object.keys(shopState.shopsHubs).length !== 0) {
      const params = {
        id: shopState.shopsHubs[0].id,
        data: payload
      }
      delete payload.shopId /// shopId is not allow
      const updateShopHub = yield call(api_shop.updateShopHub, params)
      if (updateShopHub.data) {
          yield put({
            type: OPEN_MODAL_MESSAGE, payload: {
              title: 'iconSuccess',
              message: "แก้ไขที่อยู่ร้านค้าสำเร็จ",
              btnLabel: 'ตกลง',
            }
          })
        yield onGetShopsHubs({ payload: { shopId: shopState.shopDetail.id } })
      }
    } else {
      const shopsHubs = yield call(api_shop.createShopsHubs, payload)
      if(shopsHubs){
        yield put({
          type: OPEN_MODAL_MESSAGE, payload: {
            title: 'iconSuccess',
            message: "สร้างที่อยู่ร้านค้าสำเร็จ",
            btnLabel: 'ตกลง',
          }
        })
      }
      yield onGetShopsHubs({ payload: { shopId: shopState.shopDetail.id } })
    }

  } catch (err) {
    throw err
  }
}

export function* getCurrentShopDetail(action) {
  const shopId = JSON.parse(Cookies.get('user')).shopId;
  if(shopId) {
    const shopDetail = yield call(api_shop.getShopDetail, {shopId});
    yield put({ type: SET_CURRENT_SHOP, payload: {shop: shopDetail} })
    yield put({ type: SET_DATA_SHOP_DETAIL, payload: {} })
  } else{
    yield put({ type: SET_DATA_SHOP_DETAIL, payload: {} })
  }
}


export default function* useWatcher() {
  yield takeLatest(GET_LIST_SHOP, onGetListShopName)
  yield takeLatest(GET_SHOPS, onGetShops)
  yield takeLatest(GET_ALL_SHOPS, onGetAllShops)
  yield takeLatest(GET_SHOPS_SEARCH, onGetShopsSearch)
  yield takeLatest(GET_SHOP_DETAIL, onGetShopDetail)
  yield takeLatest(ON_SEARCH_SHOP, onSearchShop)
  yield takeLatest(SET_DATA_SHOP_DETAIL, onGetDataShopDetail)
  yield takeLatest(CREATE_SHOP, onCreateShop)
  yield takeLatest(UPDATE_SHOP_DETAIL, onUpdateShopDetail)
  yield takeLatest(GET_SHOPS_HUBS, onGetShopsHubs)
  yield takeLatest(CREATE_SHOPS_HUBS, onCreateShopsHubs)
  yield takeLatest(HANDLE_UPDATE_SHOP_TAX, handleUpdateShopTax)
  yield takeLatest(GET_SHOP_TAX, onGetShopTax)
  yield takeLatest(GET_CURRENT_SHOP_DETAIL, withCallback(getCurrentShopDetail))
}