module.exports = {
    EMS: 'thaipost',
    POST: 'thaipost',
    KERRY: 'kerry_express',
    SHOPEE: 'shopee_express',
    FLASH: 'flash_express',
    DHL: 'dhl_express',
    "J&T": 'jt_express',
    ALPHA: 'alpha_fast',
    BEST: 'best_express',
    CJ: 'cj_logistics',
    SPEED: 'speed_d',
    "Ninja Van": 'ninja_van',
    SCG: 'scg_express',
    BEE: 'bee_express',
    INTER: 'inter_express',
    TNT: 'tnt_express',
    SHIPPOP: 'shippop',
    ARAMEX: 'aramex',
    TP: 'tp_logistics',
    FEDEX: 'fed_ex',
    IT: 'it_transport',
    NAKHONCHAI: 'nakhonchai_air',
    UPS: 'ups',
    NIM: 'nim_express',
    BS: 'bs_express',
    SHIPJUNG: 'shipjung',
    GLOBAL: 'global_cainiao',
    JAPAN: 'japan_post',
}