import playlistFrontPageProvider from '../../provider/playlistFrontPageProvider'
import { takeLatest, call, put, select, take } from 'redux-saga/effects'

import {
  GET_PAGE,
  SET_PAGE,
  GET_PLAYLIST_FRONT_PAGE,
  SET_PLAYLIST_FRONT_PAGE,
  CREATE_PLAYLIST_FRONT_PAGE,
  UPDATE_PLAYLIST_FRONT_PAGE,
  // DELETE_PLAYLIST_FRONT_PAGE,
  // GET_PLAYLIST_FRONT_PAGE_ITEMS,
  // SET_PLAYLIST_FRONT_PAGE_ITEMS,
  // DELETE_PLAYLIST_FRONT_PAGE_ITEM,
  // SHUFFLE_PLAYLIST_FRONT_PAGE_ITEMS,
  // CREATE_PLAYLIST_FRONT_PAGE_ITEM,
  // PIN_PLAYLIST_FRONT_PAGE_ITEMS,
} from '../actions/playlistFrontPage'

export function* onFetchPage(action) {
  try {
    const response = yield call(playlistFrontPageProvider.getPage)
    yield put({ type: SET_PAGE, payload: response })
  } catch (err) {
    throw err
  }
}


export function* onFetchPlaylistFrontPage(action) {
  const { payload } = action
  try {
    const response = yield call(playlistFrontPageProvider.getPlaylistFrontPage, payload)
    yield put({ type: SET_PLAYLIST_FRONT_PAGE, payload: response })
  } catch (err) {
    throw err
  }
}

export function* onCreatePlaylistFrontPage(action) {
  const {
    payload: { data, type },
  } = action
  try {
    const response = yield call(
      playlistFrontPageProvider.createPlaylistFrontPage,
      data,
    )
    yield put({ type, payload: data.page })
  } catch (err) {
    throw err
  }
}

export function* onUpdatePlaylistFrontPage(action) {
  const {
    payload: { data, page },
  } = action
  try {
    const response = yield call(
      playlistFrontPageProvider.updatePlaylistFrontPage,
      data,
    )
    yield put({ type: GET_PLAYLIST_FRONT_PAGE, payload: page })
  } catch (err) {
    throw err
  }
}

// export function* onDeletePlaylistFrontPage(action) {
//   const { payload: playlistsId } = action
//   try {
//     yield call(playlistFrontPageProvider.deletePlaylistFrontPage, playlistsId)
//     yield put({ type: GET_PLAYLIST_FRONT_PAGE })
//   } catch (err) {
//     throw err
//   }
// }

// export function* onFetchPlaylistFrontPageItems(action) {
//   const {
//     payload: { playlistsId, limit, page },
//   } = action
//   try {
//     const playlistItems = yield call(
//       playlistFrontPageProvider.getPlaylistItemsByPlaylistId,
//       { playlistsId, limit, page },
//     )
//     yield put({ type: SET_PLAYLIST_FRONT_PAGE_ITEMS, payload: playlistItems })
//   } catch (err) {
//     throw err
//   }
// }

// export function* onDeletePlaylistFrontPageItem(action) {
//   const {
//     payload: { playlistsId, playlistsItemId },
//   } = action
//   try {
//     yield call(playlistFrontPageProvider.deletePlaylistFrontPageItem, playlistsItemId)
//     yield put({
//       type: GET_PLAYLIST_FRONT_PAGE_ITEMS,
//       payload: { playlistsId: playlistsId, limit: 1000, page: 1 },
//     })
//   } catch (err) {
//     throw err
//   }
// }

// export function* onCreatePlaylistItem(action) {
//     const { payload: { productId, playlistId} } = action
//     try {
//       const data = yield call(playlistFrontPageProvider.createPlaylistItem, { productId, playlistId })
//       yield put({ type: GET_PLAYLIST_FRONT_PAGE_ITEMS, payload: { playlistsId: playlistId, limit:1000, page: 1 } })
//     } catch (err) {

//         throw err
//     }
// }

// export function* onShufflePlaylistItem(action) {
//   const { payload: { playlistsId } } = action
//   try {
//     yield call(playlistFrontPageProvider.shufflePlaylistFrontPageItem, playlistsId)
//     yield put({ type: GET_PLAYLIST_FRONT_PAGE_ITEMS, payload: { playlistsId: playlistsId, limit:100000000, page: 1 } })
//   } catch (err) {
//     throw err
//   }
// }

// export function* onClickPin(action) {
//   const { payload: { playlistsItemId, pinDate, playlistsId } } = action
//   try {
//     yield call(playlistFrontPageProvider.pinPlaylistFrontPageItem, { playlistsItemId, pinDate: pinDate })
//     yield put({ type: GET_PLAYLIST_FRONT_PAGE_ITEMS, payload: { playlistsId: playlistsId, limit:100000000, page: 1 } })
//   } catch (err) {
//     throw err
//   }
// }

export default function* useWatcher() {
  yield takeLatest(GET_PAGE, onFetchPage)
  yield takeLatest(GET_PLAYLIST_FRONT_PAGE, onFetchPlaylistFrontPage)
  yield takeLatest(CREATE_PLAYLIST_FRONT_PAGE, onCreatePlaylistFrontPage)
  yield takeLatest(UPDATE_PLAYLIST_FRONT_PAGE, onUpdatePlaylistFrontPage)
  // yield takeLatest(DELETE_PLAYLIST_FRONT_PAGE, onDeletePlaylistFrontPage)
  // yield takeLatest(GET_PLAYLIST_FRONT_PAGE_ITEMS, onFetchPlaylistFrontPageItems)
  // yield takeLatest(CREATE_PLAYLIST_FRONT_PAGE_ITEM, onCreatePlaylistItem)
  // yield takeLatest(DELETE_PLAYLIST_FRONT_PAGE_ITEM, onDeletePlaylistFrontPageItem)
  // yield takeLatest(SHUFFLE_PLAYLIST_FRONT_PAGE_ITEMS, onShufflePlaylistItem)
  // yield takeLatest(PIN_PLAYLIST_FRONT_PAGE_ITEMS, onClickPin)
}
