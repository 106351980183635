
import React, {Component} from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import './index.scss';

const format = 'HH:mm';

const TimePickerComponent = (props) => {

    const {value, onTimePickerChange} = props;

    const onChange = time => {
       props.onTimePickerChange(moment(time));
    };

    return (
        <TimePicker 
        defaultValue={moment('00:00', format)}
        format={format} 
        onChange={onChange}
        value={value}
        allowClear={false}
        />
    )

};

export default TimePickerComponent;

