import React, { useState } from 'react'
import classnames from 'classnames'
import './styles.scss'

const LayoutTab = (props) => {
  const { listTab, children, valueActive, handleClickTab, isMainPage } = props

  const [tabActive, setTabActive] = useState(valueActive)

  const onClickTab = (data) => {
    setTabActive(data)
    handleClickTab(data)
  }

  return (
    <div className='container-tab'>
      {isMainPage && (
        <div className='tabs'>
          {listTab.map((tab) => (
            <div
              key={tab.key}
              className={classnames('tab-box', {
                active: tabActive === tab.key,
              })}
              onClick={() => onClickTab(tab.key)}
            >
              {tab.name}
            </div>
          ))}
        </div>
      )}
      {children}
    </div>
  )
}

LayoutTab.defaultProps = {
  isMainPage: false,
  handleClickTab: () => {},
  valueActive: 'image',
  listTab: [
    { key: 'image', name: 'รูปภาพ' },
    { key: 'video', name: 'วิดีโอ' },
  ],
}

export default LayoutTab
