import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './redux/store'
import Full from './layout/Full'
import Login from './pages/Login'
import CreateLive from './pages/CreateLive'
import ManageLive from './pages/ManageLive'
import Shop from './pages/Shop'
import ShopDetail from './pages/ShopDetail'
import AddShop from './pages/AddShop'
import Product from './pages/Product'
// import addProduct from './pages/addProduct'
import Order from './pages/Order'
import Dashboard from './pages/Dashboard'
import Withdraw from './pages/Withdraw'
import Upload from './pages/Upload'
import {
  Chat,
  ChatSummary,
  Gallery,
  Albums,
  Media,
  Playlists,
  PlaylistsItems,
  PlaylistFrontPage,
  PlaylistFrontPageItems,
} from './pages'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import './styles/base.scss'
import 'antd/dist/antd.css'
import 'react-image-crop/lib/ReactCrop.scss'
import Cookies from 'js-cookie'
import { getUserRole } from 'helper/utils'
import permission from 'config/permission'
import Promocode from 'pages/Promocode';
import AddPromocode from 'pages/AddPromocode';
import AddLogisticPrice from 'pages/AddLogisticPrice'
import Ribbon from 'pages/Ribbon'

export let isAuth = false
export let RoleUser = null
export const getToken = Cookies.get('token')
//check token
if (getToken !== undefined) {
  isAuth = true
} else {
  isAuth = false
}
////check role
// const getUser = Cookies.get('user')
// if (getUser != undefined) {
//   const user = JSON.parse(getUser)
//   ////// cookies.user.shopId != null ? brand : superadmin
//   if (user['shopId']) {
//     RoleUser = 'brand'
//   } else {
//     RoleUser = 'superadmin'
//   }
//   Cookies.set('role', RoleUser)
// }

ReactDOM.render(
  <React.Fragment>
    <div className='root-container'>
      <Provider store={configureStore}>
        <Router>
          {/* <Route exact path="/" component={App} /> */}
          {/* <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/home" component={Home} permission={true} />
          <Route exact path="/create-live" component={CreateLive} />
          <Route exact path="/manage-live" component={ManageLive} />
        <Route exact path="/product" component={Product} /> */}

          <Switch>
            <Route exact path='/login' component={Login} />
            <Full permission={permission[getUserRole()]}>
              <Route
                exact
                path='/'
                component={() => window.location.replace('/manage/shop')}
              />
              <Route
                exact
                path='/manage/shop/shopdetail/managelive'
                component={ManageLive}
              />
              <Route
                exact
                path='/manage/shop'
                component={Shop}
                permission={true}
              />
              <Route
                exact
                path='/manage/shop/shopdetail'
                component={ShopDetail}
              />
              <Route
                exact
                path='/manage/shop/shopdetail/createlive'
                component={CreateLive}
              />
              <Route
                exact
                path='/manage/shop/shopdetail/addlogisticprice'
                component={AddLogisticPrice}
              />
              <Route exact path='/manage/shop/addshop' component={AddShop} />
              {/* <Route exact path='/manage/create-live' component={CreateLive} /> */}
              <Route exact path='/manage/shop/shopdetail/editlive' component={CreateLive} />
              <Route exact path='/manage/shop/shopdetail/editvod' component={Upload} />
              <Route exact path='/manage/shop/shopdetail/upload' component={Upload} />
              <Route exact path='/manage/product' component={Product} />
              {/* <Route exact path='/manage/add-product' component={addProduct} /> */}
              <Route exact path='/manage/order' component={Order} />
              <Route exact path='/manage/order/:slug' component={Order} />
              <Route exact path='/gallery' component={Gallery} />
              <Route exact path='/gallery/albums' component={Albums} />
              <Route exact path='/gallery/albums/media' component={Media} />
              <Route exact path='/manage/dashboard' component={Dashboard} />
              <Route exact path='/manage/withdraw' component={Withdraw} />
              <Route exact path='/manage/playlists' component={Playlists} />
              <Route exact path='/manage/ribbon' component={Ribbon} />

              <Switch>
                <Route exact path='/manage/promocode' component={Promocode} />
                <Route
                  path='/manage/promocode/addpromocode'
                  component={AddPromocode}
                />
                <Route path='/manage/promocode/:status' component={Promocode} />
              </Switch>

              <Route
                exact
                path='/manage/playlists/items'
                component={PlaylistsItems}
              />
              <Route
                exact
                name='listFrontPage'
                path='/manage/playlist-front-page'
                component={PlaylistFrontPage}
              />
              <Route
                exact
                name='playlistSection'
                path='/manage/playlist-front-page/page'
                component={PlaylistFrontPageItems}
              />
              <Route exact path='/chat' component={Chat} />
              <Route exact path='/chat-summary' component={ChatSummary} />

              {/* <Route exact path="/manage/order" component={ModalInvoiceDetail} /> */}
              {/* <Route path="*" component={Home} /> */}
            </Full>
          </Switch>
        </Router>
      </Provider>
    </div>
  </React.Fragment>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
