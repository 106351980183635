import React, { Component } from 'react'
import { useParams } from 'react-router'
import { faCamera, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import noItem from '../images/icons/no-item.svg'
import ModalCreateLive from '../components/live/ModalCreateLive'
import { connect } from 'react-redux'
import numeral from 'numeral';
import { decimalToInteger } from '../helper/utils';

import { dispatchLive, OPEN_MODAL_CREATE_LIVE } from '../redux/actions/live'
import {
  dispatchProduct,
  GET_ALL_PRODUCTS_BY_SHOP,
} from '../redux/actions/product'
import {
  dispatchPost,
  CREATE_POST,
  GET_POST_BY_ID,
  UPDATE_POST,
  GET_POST_MEDIA,
  GET_POST_MEDIA_BY_ID
} from '../redux/actions/post'

import { Select } from 'antd'

import lip from '../images/icons/lip.svg'
import trash from '../images/icons/trash.svg'
import IconCameraIMG from '../images/icons/camera.svg'
import closeImage from 'images/icons/close-image.svg'

import {
  dispatchModalAlert,
  OPEN_MODAL_ALERT,
} from '../redux/actions/modalAlert'

import ModalAlert from '../components/modalAlert/ModalAlert'
import ModalConfirm from '../components/modalConfirm/ModalConfirm'
import { dispatchModalConfirm } from '../redux/actions/modalConfirm'
import { OPEN_MODAL_CONFIRM } from '../redux/actions/modalConfirm'

import TimePicker from '../components/Timepicker'
import DatePickerInput from '../components/DatePickerInput'
import FileInput from '../components/FileInput'
import Radio from '../components/RadioButton'
import moment from 'moment'

import { ButtonUpload, MediaCard } from 'components/output'
import {
  dispatchGallery,
  SET_MEDIA_UPLOAD_EVENT,
  SET_GALLERY_FIELD,
} from 'redux/actions/gallery'

import host from '../config/host'
import '../styles/_createlive.scss'

import Cookies from 'js-cookie';
import InputNumber from '../components/InputNumber'

const format = 'HH:mm'

const initialState = {
  initStatus: false,
  mode: 'create',
  currentPostId: '',
  releaseDate: {
    date: null,
    time: null,
  },
  liveCoverImageFile: null,
  products: [],
  liveTitle: '',
  extraPriceTemplate: {
    discountPrice: '',
    discountPercent: '',
    buyLimit: '',
    privileges: '',
  },
  paymentPeriodTime: { paymentTime: '', unitType: 'minute' },
  checkStock: false,
  validateParam: true,
  errors: {},
  elementName: ''
}
class CreateLive extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    // this.getProductByShop()
    let state = JSON.parse(JSON.stringify(initialState))
    var time = moment().utcOffset(0)
    time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    state.releaseDate.date = moment()
    state.releaseDate.time = time
    return state
  }

  componentDidUpdate(prevProps) {
    if (this.props.mediaLive !== prevProps.mediaLive) {
      this.setState({
        liveCoverImageFile: this.props.mediaLive
          ? this.props.mediaLive[0]
          : null,
      })
    }
    if(this.state.mode === 'update') {
      if (this.props.postMediae !== prevProps.postMediae) 
         this.initialComponent();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.productsByShop !== this.props.productsByShop) {

      if (!this.state.checkStock) {
         let products = [...nextProps.productsByShop].map((element) => Object.assign({}, element), )
        // const filter = products.filter(item => item.showOn.indexOf('live') !== -1 || item.showOn.indexOf('shelf') !== -1);
      
        products = products.map((product) => {
          product.productSkus = product.productSkus.map((productSku) => {
            // NOTE - insert special property
            productSku.select = false
            const extraPriceTemplate = {
              discountPrice: null,
              discountPercent: null,
              buyLimit: null,
              privileges: null,
            }
            productSku.extraPrice = extraPriceTemplate
            productSku.errors = {}
            return productSku
          })
          return product
        });
        console.log('[products]:',products);
        // this.setState({ products })

      } else {
        if(this.validateAll() === true) {
          this.createPost(this.state.mode);
        }
      }
    }
  }

  componentDidMount = () => {

    const params = new URLSearchParams(window.location.search);
    const mode = params.get('mode');
    const postId = params.get('postId');
    const mediaId = params.get('mediaId');
    
    this.setState({mode, currentPostId: postId, currentMediaId:mediaId});

    this.getPostById()
    this.getPostMediaById(mediaId)
    // this.getProductByShop()
  }

  componentWillUnmount(){
    this.props.dispatch( dispatchGallery({ type: 'SET_GALLERY_FIELD', payload: { key: 'liveUploadEvent', value: [] } }), )
  }

  /* SECTION Event Function */
  onElementChange = (e) => {
    const attrName = e.target.name
    const attrValue = e.target.value
    this.setState({ [attrName]: attrValue })
  }

  onTimePickerChange = (time) => {
    const _time = moment(time)
    if (!_time.isValid()) {
      let timeTmp = moment().utcOffset(0)
      timeTmp.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      this.setState({
        releaseDate: { ...this.state.releaseDate, time: timeTmp },
      })
    } else {
      this.setState({ releaseDate: { ...this.state.releaseDate, time } })
    }
  }

  onDateDatePickerInputChange = (date) => {
    this.setState({ releaseDate: { ...this.state.releaseDate, date } })
  }

  onSubmitProductSelected = (productSkuSelected) => {
    this.setState({ products: productSkuSelected })
  }

  onGetLiveCoverImage = (image) => {
    
    this.setState({ liveCoverImageFile: image })
  }

  onExtraProductChange = (value, name, params) => {

    if(this.state.elementName === name) {
      const {productIndex, productSkuIndex} = params
      const attrName = name
      let attrValue = typeof value.floatValue === 'number'? value.floatValue : ''

      this.setState((prevState) => {

      const { products } = prevState;

      if (attrName === 'discountPercent' || attrName === 'discountPrice') 
        products[productIndex].productSkus[productSkuIndex].errors = {}
      
      const extraPrice = products[productIndex].productSkus[productSkuIndex].extraPrice;
      const { priceNet } = products[productIndex].productSkus[productSkuIndex];

      if (attrName === 'discountPrice' && typeof attrValue === 'number' && attrValue > priceNet) 
            attrValue = priceNet;
      if (attrName === 'discountPercent' && typeof attrValue === 'number' && attrValue > 100) 
            attrValue = 100

      const { discountPercent, discountPrice, buyLimit } = extraPrice;

      if ((attrName === 'discountPercent' || attrName === 'discountPrice') 
          && attrValue !== null 
          && attrValue !== undefined 
          && attrValue >= 0) {

        if (typeof attrValue === 'number') {
                  extraPrice.discountPrice = attrName === 'discountPercent'
                                             ? decimalToInteger(priceNet * ((100 - attrValue) / 100))
                                             : decimalToInteger(attrValue);
          
                  extraPrice.discountPercent = attrName === 'discountPrice'
                                               ? decimalToInteger(((priceNet - attrValue) * 100) / priceNet)
                                               : decimalToInteger(attrValue);
          
                  if (attrName === 'discountPrice' && extraPrice.discountPrice < 0) 
                    extraPrice.discountPrice = 0
                  
                  if (attrName === 'discountPercent' && extraPrice.discountPercent < 0) 
                    extraPrice.discountPercent = 0
                  
                  if ( attrName === 'discountPercent' && extraPrice.discountPercent > 100 ) 
                    extraPrice.discountPercent = 100
        } else {
          extraPrice['discountPercent'] = '';
          extraPrice['discountPrice'] = '';
        }
        
      } else {
        extraPrice[attrName] = attrValue;
      }

      products[productIndex].productSkus[productSkuIndex].extraPrice = extraPrice

      return { products }
    })
    }
    
  }

  onExtraProductTemplateChange = (value, name, param) => {

    if(this.state.elementName === name) {
      
        value = value.floatValue;
        if (name == 'discountPercent') {
          if (value < 0) value = 0
          if (value > 100) value = 100
        }
    
        const discountPrice   = name == 'discountPrice' && value >= 0 ? value : ''
        const discountPercent = name == 'discountPercent' && value >= 0 ? value : ''
    
        if (name == 'buyLimit' || name == 'privileges') {
          this.setState({
            extraPriceTemplate: {
              ...this.state.extraPriceTemplate,
              buyLimit: value,
            },
          })
        } else {
          this.setState(
            {
              extraPriceTemplate: {
                ...this.state.extraPriceTemplate,
                discountPrice,
                discountPercent,
              },
            },
            () => this.forceUpdate(),
          )
        }
    }

  }

  onApplyExtraPriceToProduct = () => {
    const { buyLimit, discountPercent, discountPrice, } = this.state.extraPriceTemplate;
    this.setState((prevState) => {
      let { products } = prevState;

      products = products.map((product) => {
        
        const productSkus = product.productSkus.map((productSku) => {
          productSku.errors = {}
          if (productSku?.select && productSku?.select == true) {
            const { priceNet } = productSku;
            
            if(typeof discountPercent === 'number'|| typeof discountPrice === 'number') {
              
              /* NOTE - provide discount Price */
                productSku.extraPrice.discountPrice =
                  typeof discountPercent === 'number' &&  discountPercent >= 0
                    ? decimalToInteger(priceNet * ((100 - discountPercent) / 100))
                    : decimalToInteger(priceNet - discountPrice);
  
              /* NOTE - provide discount percent */
                productSku.extraPrice.discountPercent =
                typeof discountPrice === 'number' && discountPrice >= 0
                    ? decimalToInteger(100 - ((priceNet - discountPrice) * 100) / priceNet)
                    : decimalToInteger(discountPercent);
              }
              
              productSku.extraPrice.buyLimit = buyLimit
  
              /* NOTE - provide error */
              if (productSku.extraPrice.discountPrice < 0) {
                productSku.errors.discountPrice = true
              }
              if ( productSku.extraPrice.discountPercent < 0 ||
                productSku.extraPrice.discountPercent > 100 ) {
                productSku.errors.discountPercent = true
              }
            }
            return productSku
          })
          product.productSkus = productSkus
          return product
        })

      return { products }
    })
  }

  onDeleteProduct = (skuId) => {
    
      let { products: _products } = JSON.parse(JSON.stringify(this.state));
      const products = _products.reduce( (total, product) => {
        product.productSkus = product.productSkus.filter(sku => sku.id !== skuId);
        if(product.productSkus.length > 0) 
            /* NOTE - product that remain have sku */
            return [...total, product]
        else
            return total;

      }, [])

      console.log('[_products]:',products);

      this.setState({ products })
   
  }

  onPaymentPeriodTimeChange = (value, name, params) => {
    const attrName = name
    let attrValue = null

    if (attrName === 'paymentTime' && value.floatValue >= 0)
      attrValue = value.floatValue
    else attrValue = null

    if (attrName === 'unitType') attrValue = value

    this.setState({
      paymentPeriodTime: {
        ...this.state.paymentPeriodTime,
        [attrName]: attrValue,
      },
    })
  }

  onInputNumberKeyUp = (event) => {
    event.target.value = event.target.value.replace(/[^\d]+|^0+(?!$)/g, '')
  }

  onElementFocus = elementName => this.setState({elementName})
  
  /* !SECTION */

  /* SECTION Function */
  getProductByShop = () => {
    this.props.dispatch(
      dispatchProduct({ type: GET_ALL_PRODUCTS_BY_SHOP, payload: {} }),
    )
  }

  getPostById = () => {
    const params = new URLSearchParams(window.location.search);
    const postId = params.get('postId');
    this.props.dispatch( dispatchPost({ type: GET_POST_BY_ID, payload: {postId} }), )
  }

  getPostMediaById  = (mediaId) => this.props.dispatch(dispatchPost({ type: GET_POST_MEDIA_BY_ID, payload:{mediaId: mediaId} }))


  validateAll = () => {
    try {
      // NOTE - Validate empty field
      let validateForm = true;
      const validateParam = this.validateParam();
      if (!validateParam) validateForm = false

      // NOTE - Validate stock remain
      const validateStock = this.validateStock(this.props);
      if (!validateStock) validateForm = false;

      const validatePrice = this.validatePrice(this.props);
      if (!validatePrice) validateForm = false;

      return validateForm;

    } catch (error) {
      
    }
  }

  validateStock = (nextProps) => {

    // NOTE - Validate stock remain
    let newProductList = [...nextProps.productsByShop].map((element) => Object.assign({}, element), )
    let stockList = {}
    
    newProductList.map((product) => {
      product.productSkus.map((sku) => {
        stockList[sku.id] = {}
        stockList[sku.id].stockRemain = sku.stockRemain
      })
    })

    let validateStock = true
    let validateBuyLimit = true
    const _products = [...this.state.products].map((element) => Object.assign({}, element), )

    const checkStock = _products.map((product) => {
      product.productSkus = product.productSkus.map((sku) => {
        sku.error = {}
        if (sku.select) {
            if ( sku.extraPrice.privileges === '' ||
              sku.extraPrice.privileges > stockList[sku.id].stockRemain ||
              sku.extraPrice.privileges < 0
                ) {
    
              sku.error.stockOver = true;
              validateStock = false;
    
            }  
            // if (  sku.extraPrice.buyLimit === ''
            if ( sku.extraPrice.buyLimit > stockList[sku.id].stockRemain 
                 || sku.extraPrice.buyLimit < 0) {
    
    
              sku.error.buyLimit = true;
              validateBuyLimit = false;
            } 
        }
        sku.stockRemain = stockList[sku.id].stockRemain
        return sku
      })
      return product
    })

    this.setState({
      checkStock: false,
      products: checkStock,
    })

    if (!validateStock) {
      this.props.dispatch( dispatchModalAlert({ type: OPEN_MODAL_ALERT, 
                                                payload: { isOpen: true, message: 'สิทธิ์พิเศษมีจำนวนเกินจากสต็อกสินค้า กรุณาตรวจสอบอีกครั้ง', type: 'alert', }, }) )
    }
    if (!validateBuyLimit) {
      this.props.dispatch( dispatchModalAlert({ type: OPEN_MODAL_ALERT, 
                                                payload: { isOpen: true, message: 'จำกัดจำนวนการซื้อเกินสต็อค', type: 'alert', }, }) )
    }

    return validateStock && validateBuyLimit
  }

  validateParam = () => {

    // NOTE - check empty liveTitle liveCoverImageFile product
    const {
      liveTitle,
      liveCoverImageFile,
      products,
      paymentPeriodTime,
    } = this.state;

    let errors = {}
    
    if (!(liveTitle.length > 0)) errors.liveTitle = 'กรุณากรอกชื่อไลฟ์'
    
    if (!liveCoverImageFile)
      errors.liveCoverImageFile = 'กรุณากรอกใส่รูปหน้าปกไลฟ์'
    
      // if (paymentPeriodTime.paymentTime === '' ||  paymentPeriodTime.paymentTime < 0)
      // errors.paymentPeriodTime = 'กรุณากรอกระยะเวลาชำระเงินหลังจากรับสิทธิ์'
    
    if (Object.keys(errors).length > 0) {
      this.openAlertModal('กรุณาข้อมูลที่จำเป็นให้ครบ')
      // return false
    }
    
    this.setState({ errors })

    // NOTE - check product selected
    let countSelectProduct = 0;
    [...products].map((product) => {
      product.productSkus.map((sku) => {
        if (sku.select) countSelectProduct++
      })
    })

    if (!(countSelectProduct > 0)) {
      this.openAlertModal('กรุณาเพิ่มสินค้า')
      return false
    }

    // NOTE - check product empty field
    let validateEmptyProducts = true
    const validateProducts = [...products].map((product) => {
      product.productSkus.map((sku) => {
        let errors = {}

        if (sku.select) {
          const { buyLimit, discountPercent, discountPrice, privileges, } = sku.extraPrice

          // console.table(sku.extraPrice)

          // if (buyLimit === null) 
          //    errors.buyLimit = true;

          if (discountPercent === null && discountPrice === null) {
            errors.discountPrice = true
            errors.discountPercent = true
          }
          
          if (privileges === null) 
             errors.privileges = true

          if (Object.keys(errors).length > 0) 
             validateEmptyProducts = false
        }
        sku.errors = errors
        return sku
      })
      return product
    })

    if (!validateEmptyProducts)
      this.openAlertModal('กรุณกรอกข้อมูลสินค้าให้ครบ')

    this.setState({ product: validateProducts })
    if (!validateEmptyProducts) return false

    return true
  }

  checkCondition = () => {
    if(this.validateAll()){
      this.setState({ checkStock: true }, () => {
        this.getProductByShop()
      })
    }
  }

  createPost = mode => {
        
    const { date, time } = this.state.releaseDate
    const {
      liveCoverImageFile,
      products,
      liveTitle,
      paymentPeriodTime,
      currentMediaId
    } = this.state
    const dateString = moment(date).format('MM/DD/YYYY')
    const timeString = moment(time).format('HH:mm')
    const releaseDate = new Date(`${dateString} ${timeString}`).toISOString()
    const { unitType, paymentTime } = paymentPeriodTime
    const paymentExpireUnit =
      unitType === 'minute' ? paymentTime : paymentTime * 60

    const payload = {
      post: {
        coverPathId: liveCoverImageFile.id,
        shopId: 1,
        title: liveTitle,
        releaseDate: releaseDate,
      },
      products,
      mode,
      mediaId:currentMediaId
    }
    

    if(mode === 'create') {
      this.props.dispatch(dispatchPost({ type: CREATE_POST, payload }))
    }
    if(mode === 'update') {
      payload.postID = this.state.currentPostId;
      this.props.dispatch(dispatchPost({ type: UPDATE_POST, payload }))
    }
  }

  openAlertModal = (message) => {
    this.props.dispatch(
      dispatchModalAlert({
        type: OPEN_MODAL_ALERT,
        payload: { isOpen: true, message, type: 'alert' },
      }),
    )
  }

  reset = () => {
    localStorage.setItem("shopDetailDefaultTab", JSON.stringify({ tab: 1 }));
    window.location.replace('/manage/shop/shopdetail')
    // this.setState(this.getInitialState())
  }

  validatePrice = (props) => {
    let validatePrice = true
    const { products } = this.state
    const product = products.map((item, idx) => {
      item.productSkus.map((sku, index) => {
        if (
          sku.extraPrice.discountPrice < 0 ||
          sku.extraPrice.discountPercent < 0 ||
          sku.extraPrice.discountPercent > 100
        ) {
          sku.errors.discountPrice = true
          sku.errors.discountPercent = true
          validatePrice = false
        }
      })
    })
    if (!validatePrice) {
      this.props.dispatch(
        dispatchModalAlert({
          type: OPEN_MODAL_ALERT,
          payload: {
            isOpen: true,
            message: 'กรุณาใส่ราคาให้ถูกต้อง',
            type: 'alert',
          },
        }),
      )
    }
    return validatePrice
  }

  initialComponent = () => {
    this.setState( prevState => {
      let state = JSON.parse(JSON.stringify(prevState));
      let { liveCoverImageFile,
              releaseDate,
              products,
              liveTitle,
              paymentPeriodTime
            } = state;
      const {postMediae} = this.props;

      // NOTE - set date time release
      var time = moment(postMediae.releaseDate);
      releaseDate.date = new Date(postMediae.releaseDate);
      releaseDate.time = time;
      paymentPeriodTime.paymentTime = postMediae.paymentExpireUnit > 60 ? (postMediae.paymentExpireUnit/60).toFixed(2) : postMediae.paymentExpireUnit;
      paymentPeriodTime.unitType = postMediae.paymentExpireUnit > 60 ?'hour' : 'minute';

      liveTitle = postMediae.title;
      this.props.dispatch( dispatchGallery({ type: 'SET_GALLERY_FIELD', 
                                        payload: { key: 'liveUploadEvent', 
                                                   value: [postMediae.cover] }, }), );
      return {liveTitle, releaseDate, paymentPeriodTime}
    })

    const  {initStatus} = this.state;
    if (initStatus === false) {
      const products = this.filterProductSelected();
      this.setState({initStatus:true,products});
    }
  }

  filterProductSelected = () => {
    try{
        const {postMediae} = this.props;
        const products  = postMediae.items.reduce((total, item) => {

          const {limitPerUser,discountValue,priceDiscount, quotas, id, priceNet,skuId,skuPriceNet} = item;
          let productSku  = { 
            ...item,
            select: true,
            itemId: id,
            extraPrice: {
              buyLimit: limitPerUser === 9999 ? '' : limitPerUser,
              discountPercent: discountValue,
              discountPrice : priceNet,
              privileges : quotas
            }
          }
          productSku.id = skuId;
          productSku.priceNet = skuPriceNet;


          let productItem = {}
          const existItemIndex = total.findIndex(_item => _item.id === item.id)
          /* NOTE - same product insert new sku */ 
          if (existItemIndex > -1) {
              total[existItemIndex].productSkus = [...productItem.productSkus, productSku]
              return total
          } else { /* NOTE - insert new product insert new sku */ 
              productItem = {...item, name: item.productName, id: item.productId, productSkus:[productSku]}
              return [...total, productItem];
          }
          
        },[])

               
        return products;
    } catch(error) {
        return []
    }
  }


  /* !SECTION */

  onClickRemoveImage = (index, name, key) => {
    const tmp = [...this.props[name]]
    tmp.splice(index, 1)

    this.props.dispatch(
      dispatchGallery({
        type: 'SET_GALLERY_FIELD',
        payload: {
          key: key,
          value: tmp,
        },
      }),
    )
  }

  render() {
    /* ANCHOR [Init render] */
    const {
      mode,
      releaseDate,
      liveCoverImageFile,
      products,
      extraPriceTemplate,
      liveTitle,
      paymentPeriodTime,
      errors,
    } = this.state
    const { paymentTime, unitType } = paymentPeriodTime
    const { discountPrice, discountPercent, buyLimit } = extraPriceTemplate
    const { date, time } = releaseDate
    const { productsByShop, post } = this.props
    const { img } = host



    return (
      <div className='col-12'>

        <div className='col-12'>
          <h3>สร้างไลฟ์</h3>
        </div>
        {/* ANCHOR [general live data] */}
        <div className='general-live-container'>
          <div className='upload col-xl-10 m-auto p-3 pb-4'>
            <h3>ข้อมูลทั่วไป</h3>
            <span>
              ชื่อ LIVE
              <span style={{ color: 'red' }}>* </span>
              <span className='text-detail'> (จำกัด 200 ตัวอักษร)</span>
            </span>
            <br />
            <input
              type='text'
              className={`form-control ${ errors?.liveTitle ? 'error-field' : '' }`}
              name='liveTitle'
              value={liveTitle}
              onChange={(e) => this.onElementChange(e)}
            />
            <span className='error-message'>
              {errors?.liveTitle ? '* กรุณากรอกชื่อ Live' : ''}
            </span>
          </div>
        </div>
          {/* ANCHOR [live image] */}
        <div className='image-live-container'>
          <div className='upload col-xl-10 m-auto p-3 pb-4'>
            <h3>ภาพหน้าปก LIVE</h3>
            <p className='upload-detail'>
              รองรับสัดส่วนภาพ 1:1 เท่านั้น (สี่เหลี่ยมจัตุรัส) ขนาดรูปแนะนำคือ
              640 x 640 พิกเซล ขนาดไฟล์สูงสุด 10 MB
            </p>

            {/* <div className='input-img-wrap'>
              <FileInput onGetImage={this.onGetLiveCoverImage}>
                {liveCoverImageFile?.currentImg ? (
                  <div className='live-cover-image'>
                    <img
                      src={liveCoverImageFile?.currentImg}
                      className='image-preview'
                    />
                    <img
                      src={IconCameraIMG}
                      alt='IconCamera'
                      className='upload-icon'
                    />
                  </div>
                ) : (
                  <button className='btn-upload-image'>
                    <FontAwesomeIcon icon={faCamera} className='icon-camera' />{' '}
                    <br /> เพิ่มรูปภาพ
                  </button>
                )}
              </FileInput>
            </div> */}

            {this.props.mediaLive?.length > 0 ? (
              this.props.mediaLive.map((item, index) => (
                <div className='d-flex'>
                  <span className='item-product-container'>
                    <img src={host.image + item?.path} alt='' className='image' style={{ width: '100%' }} />
                    <div className='middle' onClick={() => this.onClickRemoveImage( index, 'mediaLive', 'liveUploadEvent', ) } >
                      <img alt='' src={closeImage} className='position-absolute remove-image-product' />
                    </div>
                  </span>
                </div>
              ))
            ) : (
              <ButtonUpload
                width='12em'
                height='12em'
                type='image'
                shopDetail={{ shopId: this.props.currentShopId }}
                disabled={!this.props.currentShopId}
                fieldUpload='liveUploadEvent'
                limitImageUpload={1}
              />
            )}

            <span className='error-message'>
              {errors?.liveCoverImageFile ? '* กรุณาใส่ ภาพหน้าปก LIVE' : ''}
            </span>

            {/* <button className="btn-upload-image">
                            <FontAwesomeIcon icon={faCamera} className="icon-camera" /> <br />
                        เพิ่มรูปภาพ
                        </button> */}
          </div>
        </div>

        {/* ANCHOR [date time] */}
        <div className='date-live-container'>
          <div className='col-xl-10 m-auto p-3'>
            <h3>วันที่ เวลาที่ LIVE</h3>
            <div className='row mt-4 font-primary font-size-12px font-weight-light d-flex flex-wrap'>
              <div className='col-5 mr-4'>
                <span>
                  วันที่ <span style={{ color: 'red' }}>*</span>
                </span>{' '}
                <br />
                {/* <input className="form-control" /> */}
                {/* <DatePicker selectedDate={date}  onDayClick={this.onDatePickerChange}/> */}
                <DatePickerInput
                  date={date}
                  onSubmitDateSelected={this.onDateDatePickerInputChange}
                />
              </div>
              <div className='col-5'>
                <span>
                  เวลา <span style={{ color: 'red' }}>*</span>
                </span>{' '}
                <br />
                {/* <input className="form-control" /> */}
                <TimePicker
                  onTimePickerChange={this.onTimePickerChange}
                  value={time}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ANCHOR [product detail] */}
        <div className='setting-order'>
          <div className='col-xl-10 m-auto p-3'>
            <h3>สินค้าที่จะขายในไลฟ์</h3>
            <div className='d-flex flex-row justify-content-between mt-4 mb-2'>
              <h5>ตั้งค่าสินค้า</h5>
              <span
                className='add-order d-flex justify-content-center cursor-pointer'
                onClick={() =>
                  this.props.dispatch(
                    dispatchLive({
                      type: OPEN_MODAL_CREATE_LIVE,
                      payload: true,
                    }),
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className='icon-plus mr-1'
                />{' '}
                เพิ่มสินค้า
              </span>
            </div>

            <div className='order-list'>
              <div className='d-flex justify-content-between p-4'>
                <div className='row'>
                  <div className='col-3'>
                    <span>
                    ราคาที่ลดแล้ว (บาท) <span style={{ color: 'red' }}>*</span>
                    </span>
                    <br />
                    <span className='font-size-10px color-grey'>
                      ส่วนลดแบบบาท
                    </span>
                    <br />
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>฿</span>
                      </div>
                      <InputNumber value={discountPrice} 
                                   name={'discountPrice'} 
                                   className={`form-control`}
                                   type={'COMMON'}
                                   placeholder={'0'}
                                   onFocus={this.onElementFocus}
                                   onChange={this.onExtraProductTemplateChange}/>
                    </div>
                  </div>

                  <span className='align-self-end mb-2'>หรือ</span>

                  <div className='col-3'>
                    <span>ส่วนลด (%)</span>
                    <br />
                    <span className='font-size-10px color-grey'>
                      คิดส่วนลดเป็นเปอร์เซนต์
                    </span>
                    <br />
                    <div className='input-group'>
                      <InputNumber value={discountPercent} 
                                   name={'discountPercent'} 
                                   className={`form-control`}
                                   type={'COMMON'}
                                   placeholder={'0'}
                                   onFocus={this.onElementFocus}
                                   onChange={this.onExtraProductTemplateChange}/>
                      <div className='input-group-append'>
                        <span className='input-group-text'>%</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <span>จำกัดการซื้อ </span>
                    <br />
                    <span className='font-size-10px color-grey'>
                      จำนวนจำกัดต่อการซื้อ 1 ครั้ง
                    </span>
                    <br />
                    <div className='input-group'>
                      <InputNumber value={buyLimit} 
                                   name={'buyLimit'} 
                                   className={`form-control`}
                                   type={'COMMON'}
                                   placeholder={'0'}
                                   onFocus={this.onElementFocus}
                                   onChange={this.onExtraProductTemplateChange}/>
                    </div>
                  </div>
                </div>
                <button
                  className='btn-cancel align-self-end'
                  style={{ width: '15em' }}
                  onClick={this.onApplyExtraPriceToProduct}
                >
                  นำไปใช้กับสินค้าทั้งหมด
                </button>
              </div>

              {/* <div className="item-list" style={{heigth:"30em"}}>
                                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                                    <img src={noItem} alt="noItem" /> <br />
                                    <span className="font-secondary">ไม่มีรายการ</span>
                                </div>
                            </div> */}

              {/* ANCHOR [product list] */}
              {[...products].map((product, productIndex) => {
                return (
                  <React.Fragment key={productIndex}>
                    {product.productSkus.filter((item) => item.select === true).length > 0 && (
                      <div className='item-list mt-2'>
                        <div className='col-12 row mb-4 p-0 m-0 font-weight-bold'>
                          <div className='col-3'>{product.name}</div>
                          <div className='col-1-atl p-0'>
                            ราคาปกติ<br />
                            <span className='font-size-10px color-grey font-weight-light'>
                              ราคาตั้งต้นก่อนลดราคา
                            </span>
                          </div>
                          <div className='col-2-atl'>
                            ราคาสุทธิ <br />
                            <span className='font-size-10px color-grey font-weight-light'>
                              ราคาที่ลูกค้าเห็น
                            </span>
                          </div>
                          <div className='mr-4'></div>
                          <div className='col-2-atl'>
                            ส่วนลด (%)<br />
                            <span className='font-size-10px color-grey font-weight-light'>
                              คิดเป็นเปอร์เซนต์
                            </span>
                          </div>
                          <div className='col-2-atl'>
                            จำนวนสิทธิ์พิเศษ <br />
                            <span className='font-size-10px color-grey font-weight-light'>
                              จำนวนของสินค้าราคาพิเศษที่ต้อง การขายในไลฟ์ /
                              สต็อกทั้งหมด
                            </span>
                          </div>
                          <div className='col-2-atl'>
                            จำกัดการซื้อ <br />
                            <span className='font-size-10px color-grey font-weight-light'>
                              จำนวนจำกัดต่อการซื้อ 1 ครั้ง
                            </span>
                          </div>
                          <div className=''>{/* bin */}</div>
                        </div>
                        {/* ANCHOR [product sku list] */}
                        {[...product.productSkus].map(
                          (productSkus, productSkuIndex) => {
                            const { extraPrice, stockRemain, errors, error, id:skuId} = productSkus || {}
                            const { buyLimit, discountPercent, discountPrice, privileges, } = extraPrice || {}
                            return (
                              <React.Fragment key={productSkuIndex}>
                                {productSkus.select === true && (
                                  <div className='col-12 row item-sku m-0 p-0 mb-3'>
                                    <div className='col-3'>
                                      <div className='d-flex flex-row align-items-center'>
                                        {productSkus.imagePath && 
                                          ( <img src={`${ host.img + productSkus?.imagePath?.path }`} 
                                            style={{ width: '20%', marginRight: '10px', }} /> )}
                                        <div>
                                          <span>{productSkus.sku}</span> <br />
                                          {/* <span style={{ color: 'red' }}> ลดกระหน่ำซัมเมอร์เซลล์ </span> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-1-atl p-0'>
                                      <span className=''>
                                        ฿ {numeral(productSkus.priceNet).format('0,0')}
                                      </span>
                                    </div>
                                    
                                    <div className='col-2-atl d-flex flex-column'>
                                      <div className='input-group'>
                                        <div className='input-group-prepend'>
                                          <span className='input-group-text'>
                                            ฿
                                          </span>
                                        </div>
                                        <InputNumber  value={discountPrice} 
                                                      name={'discountPrice'} 
                                                      className={`form-control ${ errors?.discountPrice ? 'error-field' : '' }`}
                                                      type={'COMMON'}
                                                      placeholder={'0'}
                                                      params={{productIndex,productSkuIndex}}
                                                      onFocus={this.onElementFocus}
                                                      onChange={this.onExtraProductChange}/>
                                      </div>
                                      {errors?.discountPrice ? ( <span className='msg-error'> ข้อมูลไม่ถูกต้อง </span> ) : null}
                                    </div>
                                    
                                    <div>
                                      <span>หรือ</span>
                                    </div>

                                    <div className='col-2-atl d-flex flex-column'>
                                      <div className='input-group'>
                                        <InputNumber  value={discountPercent} 
                                                      name={'discountPercent'} 
                                                      className={`form-control ${ errors?.discountPercent ? 'error-field' : '' }`}
                                                      type={'COMMON'}
                                                      placeholder={'0'}
                                                      params={{productIndex,productSkuIndex}}
                                                      onFocus={this.onElementFocus}
                                                      onChange={this.onExtraProductChange}/>

                                        <div className='input-group-append'>
                                          <span className='input-group-text'>
                                            %
                                          </span>
                                        </div>
                                      </div>
                                      {errors?.discountPercent ? ( <span className='msg-error'> ข้อมูลไม่ถูกต้อง </span> ) : null}
                                    </div>
                                    
                                    {/* NOTE จำนวนสิทธิ์พิเศษ   */}
                                    <div className='col-2-atl'>
                                      <div className='input-group'>
                                          <InputNumber  value={privileges} 
                                                          name={'privileges'} 
                                                          className={`form-control price-number ${ errors?.privileges || error?.stockOver? 'error-field' : '' }`}
                                                          type={'COMMON'}
                                                          placeholder={'0'}
                                                          params={{productIndex,productSkuIndex}}
                                                          onFocus={this.onElementFocus}
                                                          onChange={this.onExtraProductChange}/>  

                                        <div className='input-group-append'>
                                          <span className='input-group-text text-number '>
                                            /{numeral(stockRemain).format('0,0')}
                                          </span>
                                        </div>
                                      </div> 
                                      {errors?.privileges ? ( <span className='msg-error'> ข้อมูลไม่ถูกต้อง </span> ) : null}
                                      {error?.stockOver ? ( <span className='msg-error'> จำนวนสิทธิ์เกินสต็อค </span> ) : null}
                                    </div>

                                    {/* NOTE จำกัดการซื้อ   */}
                                    <div className='col-2-atl'>
                                      <InputNumber  value={buyLimit} 
                                                      name={'buyLimit'} 
                                                      className={`form-control ${ error?.buyLimit ? 'error-field' : '' }`}
                                                      type={'COMMON'}
                                                      placeholder={'0'}
                                                      params={{productIndex,productSkuIndex}}
                                                      onFocus={this.onElementFocus}
                                                      onChange={this.onExtraProductChange}/>  
                                      {error?.buyLimit ? ( <span className='msg-error'> ข้อมูลไม่ถูกต้อง </span> ) : null}
                                    </div>
                                    
                                    <div className=''>
                                      <img src={trash} alt='trash' onClick={() => this.onDeleteProduct( skuId ) } />
                                    </div>
                                  
                                  </div>
                                )}
                              </React.Fragment>
                            )
                          },
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>

        {/* ANCHOR [paid time] */}
        { post?.paymentExpireUnit &&
          <div className='paid-time-container'>
          <div className='upload col-xl-10 m-auto p-3 pb-4'>
            <h3>ระยะเวลาชำระเงินหลังจากจบไลฟ์</h3>
            <div className='row'>
              <div className='col-6'>
                <span>
                  เวลา <span style={{ color: 'red' }}>* </span>{' '}
                </span>
                <br />
                <InputNumber  value={paymentTime} 
                              name={'paymentTime'} 
                              className={`form-control ${ errors?.paymentPeriodTime ? 'error-field' : '' }`}
                              type={'DECIMAL'}
                              placeholder={'0'}
                              onFocus={this.onElementFocus}
                              onChange={this.onPaymentPeriodTimeChange}/>  
                
                <span className='error-message'> {errors?.paymentPeriodTime ? '* กรุณากรอกระยะเวลาชำระเงินหลังจากรับสิทธิ์' : ''} </span>
              </div>
              <div className='col-6 d-flex justify-content-start align-items-center mt-4'>
                <div className='col-6 d-flex justify-content-center'>
                  <Radio
                    type='radio'
                    value='minute'
                    className='mr-4'
                    valueOption={unitType}
                    name='unitType'
                    onChange={(e) => this.onPaymentPeriodTimeChange(e.target.value, e.target.name)}
                  />
                  <label className='form-check-label mr-4'>นาที</label>
                </div>
                <div className='col-6 d-flex justify-content-center'>
                  <Radio
                    type='radio' 
                    value='hour'
                    className='mr-4'
                    valueOption={unitType}
                    name='unitType'
                    onChange={(e) => this.onPaymentPeriodTimeChange(e.target.value, e.target.name)}
                  />
                  <label className='form-check-label mr-4'>ชั่วโมง</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        {/* ANCHOR [confirm button] */}
        <div className='d-flex justify-content-center'>
          <span className='m-4'>
            <button
              className='btn-cancel'
              onClick={() =>
                this.props.dispatch(
                  dispatchModalConfirm({
                    type: OPEN_MODAL_CONFIRM,
                    payload: true,
                  }),
                )
              }
            >
              ยกเลิก
            </button>
          </span>
            <span className='m-4'>
              <button
                className='btn-submit'
                data-toggle='modal'
                data-target='#exampleModal'
                onClick={this.checkCondition}
              >
                { mode !== 'update' ? <span>สร้าง</span>  : <span>แก้ไข</span> }
              </button>
            </span>
        </div>

        <ModalConfirm
          message={'ยกเลิกการสร้างไลฟ์'}
          isOpen={this.props.modalConfirm}
          confirm={this.reset}
          isClose={() => this.props.dispatch( dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false, }), ) }
        />
        <ModalCreateLive
          isOpen={this.props.modalCreateLive}
          isClose={() => this.props.dispatch( dispatchLive({ type: OPEN_MODAL_CREATE_LIVE, payload: false, }), ) }
          onSubmitProductSelected={this.onSubmitProductSelected}
          products={products}
        />
        <ModalAlert />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modalCreateLive: state.live.modalCreateLive,
  modalConfirm   : state.modalConfirm.modalConfirm,
  productsByShop : state.product.productsByShop.product,
  mediaLive      : state.gallery.liveUploadEvent,
  currentShopId  : state.shop.currentShop.id,
  postMediae     : state.post.postMediae,
  post           : state.post.post
})

export default connect(mapStateToProps)(CreateLive)
