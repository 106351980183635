import DatePickerInputComponent from 'components/DatePickerInput'
import TimePickerComponent from 'components/Timepicker'
import moment from 'moment'
import React, { Component } from 'react'




export default class DateTimeLength extends Component {

    onChangeDate = (type, date) => {
        const { handleChangeDate } = this.props
        handleChangeDate(type, moment(date).format('DD/MM/YYYY'))
    }

    onChangeTime = (type, time) => {
        const { handleChangeTime } = this.props
        handleChangeTime(type,moment(time).format('HH:mm'))
    }


    render() {
        const { startDate, endDate, startTime, endTime } = this.props
        return (
            <div>
                <section className="section option">
                    {/* <div className="row">
                        <div className="col-4">
                            <h4>ระยะเวลาการใช้งาน</h4>
                        </div>
                    </div> */}

                    <div className="row d-flex align-items-center col-12">
                        <div className='d-flex align-items-start flex-column col p-0'>
                            <span className="require font-primary">วันที่เริ่ม</span>
                            <DatePickerInputComponent /// here
                                onSubmitDateSelected={(date) =>
                                    //     this.setState({
                                    //     dateStart: moment(date, "MM/DD/YYYY").format('DD-MM-YYYY')
                                    // })
                                    this.onChangeDate('startDate', date)
                                }
                                allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                value={startDate}
                                date={new Date(moment(startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))}
                                // checkStartDate={new Date(moment(endDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))} /// checkstartDate < EndDate
                            />
                        </div>
                        <div className='d-flex align-items-start flex-column col'>
                            <span className="require font-primary">เวลา</span>
                            <TimePickerComponent
                                onTimePickerChange={
                                    (time) =>
                                        // this.setState({
                                        //     timeStart: time
                                        // })
                                        this.onChangeTime('startTime', time)
                                }
                                value={moment(startTime, "HH:mm")}
                            />

                        </div>
                        <div className="mt-4 col-0"> ถึง </div>
                        <div className='d-flex align-items-start flex-column col'>
                            <span className="require font-primary font-weight-100">วันที่สิ้นสุด</span>
                            <DatePickerInputComponent /// here
                                onSubmitDateSelected={(date) =>
                                    // this.setState({
                                    // dateEnd: moment(date, "MM/DD/YYYY").format('DD-MM-YYYY')
                                    // })
                                    this.onChangeDate('endDate', date)
                                }
                                allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                value={endDate}
                                date={new Date(moment(endDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))}
                                checkEndDate={new Date(moment(startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))}
                            />
                        </div>
                        <div className='d-flex align-items-start flex-column col p-0'>
                            <span className="require font-primary">เวลา</span>
                            <TimePickerComponent
                                onTimePickerChange={(time) =>
                                    // this.setState({ timeEnd: time })
                                    this.onChangeTime('endTime', time)
                                }
                                value={moment(endTime, "HH:mm")}
                            />
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}
