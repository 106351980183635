import axios from '../config/axios'
import host from '../config/host'
import Cookies from 'js-cookie'

const tokenSecretKey = {
    headers: {
        'secret-key': host.tokenSecretKey
    }
}

class productProvider {
    getListAllProduct = (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(`${host.api}/products?limit=${params.limit}&page=${params.page}`)
                resolve(result.data)
            } catch (error) {
                // reject(error)

            }
        })
    }
    onCreateProduct = async (params) => {
        const data = {
            createdBy: JSON.parse(Cookies.get('user')).id,
            shopId: params.shopId,
            // shopId: 4,
            // createdBy: 16,
            name: params.name,
            // nameEn: "Woman Bag",
            description: [{ contentType: "text", content: params.description }],
            coverPathId: params.coverPathId,
            imagePathIds: params.imagePathIds,
            videoPathIds: params.videoPathIds,
            saleType: params.saleType,
            unitType: "ชิ้น",
            categories: params.categories,
            seo: params.seo,
            status: params.status,
            brand: params.brand,
            prepareDay: params.prepareDay
        }
        // return new Promise(async (resolve, reject) => {
        try {
            localStorage.setItem('onCreateProduct', JSON.stringify(data))
            const result = await axios.post(host.api + "/products", data)
            return (result)
        } catch (error) {

            throw error
            // reject(error)
        }
        // })
    }
    getListCategories = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(host.api + "/playlists/cms/categories", tokenSecretKey)
                resolve(result.data)
            } catch (error) {
                throw error
            }
        })
    }
    getBrands = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(`${host.api}/brands`)
                resolve(result)
            } catch (error) {
                throw error
            }
        })
    }
    createProductSku = async (param) => {
        return new Promise(async (resolve, reject) => {
            try {
                localStorage.setItem('createProductSku', JSON.stringify(param))
                const url = `${host.api}/product-sku`;
                const res = await axios.post(url, param)
                resolve(res);

            } catch (error) {

                throw error
            }
        })
    }
    getProductsByShop = async (param) => {
        try {
            const shopId = param.shopId;
            // const url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;

            let url = '';
            /* NOTE see all product all shop if shopId = 14 */
            if (process.env.REACT_APP_BY_PASS_ALL_PRODUCT === 'ALL') {
                if (shopId !== 14)
                    url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;
                else
                    url = `${host.api}/products?limit=${param.limit}&page=${param.page}`;
            } else {
                url = `${host.api}/products?shopId=${shopId}&limit=${param.limit}&page=${param.page}`;
            }

            const res = await axios.get(url)
            return res && res.data ? res.data : [];
        } catch (error) {

            throw error
        }
    }
    getSearchProductsByShop = async (param) => {
        try {
            // const header = {
            //     headers: {
            //         Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).accessToken
            //     }
            // }

            const { shopId, keyword, limit, page } = param;

            const url = `${host.api}/products/search/${shopId}?kw=${keyword}&limit=${limit}&page=${page}`;
            const res = await axios.get(url)
            return res && res.data ? res.data : [];
        } catch (error) {

            throw error
        }
    }

    createProductLogistics = async (payload) => {
        try {
            const url = `${host.api}/product-logistics`;
            localStorage.setItem('createProductLogistics', payload)
            const res = await axios.post(url, payload);
            return res
        } catch (error) {
            throw error
        }
    }

    handleSearchProduct = async (params) => {
        try {
            const url = `${host.api}/products/search-all?kw=${params.search}&limit=${params.limit}&page=${params.page}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    searchProductFilterPlaylist = async (params) => {
        try {
            const url = `${host.api}/products/search-all/${params.playlistsId}?kw=${params.search}&limit=${params.limit}&page=${params.page}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getProductAttributes = async param => {
        try {
            const url = `${host.api}/product-attributes/product/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    getProductByProductId = async param => {
        try {
            const url = `${host.api}/products/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductById = async param => {
        try {
            const url = `${host.api}/products/${param.productId}`
            const res = await axios.patch(url, param.productDetail)
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductSku = async param => {
        try {
            const url = `${host.api}/product-sku/${param.productId}`
            const res = await axios.patch(url, param.skus)
            return res
        } catch (error) {
            throw error
        }
    }

    deleteProductSku = async param => {
        try {
            const payload = { skuId: param.productSkus.skuId };
            const url = `${host.api}/product-sku/${param.productId}`;
            const res = await axios.delete(url, { data: payload });
            return res
        } catch (error) {
            throw error
        }
    }

    updateProductLogistics = async param => {
        try {
            const url = `${host.api}/product-logistics`
            const res = await axios.patch(url, param.productLogistic)
            return res
        } catch (error) {
            throw error
        }
    }

    getListProductLogisticsByProductId = async param => {
        try {
            const url = `${host.api}/product-logistics/product/${param.productId}`
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    deleteProduct = async (id) => {
        try {
            const url = `${host.api}/products/${id}`
            const res = await axios.delete(url)
            return res
        } catch (error) {
            throw error
        }
    }

    geListLogisticsNotPriceByShopId = async (params) => {
        try {
            const url = `${host.api}/product-logistics?weight=0.1&shopId=${params.shopId}` ///mock Weight
            const res = await axios.get(url)
            return res
        } catch (error) {
            throw error
        }
    }

    createLimitPurchase = async (params) => {
        try {
            const url = `${host.api}/product-metas/limit-product`
            const res = await axios.post(url, params)
            return res
        } catch (error) {
            throw error
        }
    }

    getLimitProduct = async (productId) => {
        try {
            const url = `${host.api}/product-metas/product/${productId}/limit-product`
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            throw error
        }
    }


}

export default productProvider
