import React from 'react'
import { Album, BreadcrumbGallery } from '../components/Gallery'

const Albums = (props) => {
  if (props.location.state) {
    const { shopDetail } = props.location.state

    const clickAlbum = ({ albumDetail }) => {
      props.history.push(`/gallery/albums/media`, {
        shopDetail,
        albumDetail,
      })
    }

    return (
      <>
        <BreadcrumbGallery page='albums' {...props} />
        <Album
          isMainPage={true}
          isEditName
          shopDetail={shopDetail}
          clickAlbum={clickAlbum}
        />
      </>
    )
  } else {
    return props.history.push('/gallery')
  }
}

export default Albums
