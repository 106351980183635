import { SET_LOGISTIC_LIST } from '../actions/logistic'

const initialState = {
    logisticList: []
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case SET_LOGISTIC_LIST: {
            return {
                ...state,
                logisticList: payload
            }
        }
        default: {
            return state
        }
    }
}