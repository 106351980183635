
export const LOADING              = "LOADING"
export const SET_LOADING          = "SET_LOADING"
export const SETTING_MENU_SIDEBAR = "SETTING_MENU_SIDEBAR"
export const SETTING_MENU_SIDEBAR_PERMISSION = "SETTING_MENU_SIDEBAR_PERMISSION"


export  function dispatchApp(param){

    return {
      type: param.type,
      payload: param.payload,
    };
  }