import { eventChannel } from 'redux-saga'
import { call, cancel, fork, put, take } from 'redux-saga/effects'
import {
  join,
  newMessage,
  onLiveUser,
  sendMessage,
  onProduct,
  auth,
  leave,
  sendLike, onUnLike, onLike, onEndLive, connectSocket, setField, login, onStartLive
} from '../actions/socket'
import io from 'socket.io-client'
import chatProvider from '../../provider/chatProvider'
import host from '../../config/host'
import { isLogin } from '../../helper/utils'
import { deleteCookieData } from '../../helper/cookieHelper'
import { dispatchLive, FETCH_CHECK_LIVE, SET_LIVE_DURATION } from '../actions/live'
import { dispatchPost, SET_MODAL_LIVE_FINISH_CONFIRM } from '../actions/post'

let delayUnlike = null

function* connect() {
 // yield setSocketField('isLogin', isLogin())
 try {
    const authRes = yield call(chatProvider.auth)
    const user = authRes.data.user
    yield put(auth({ user: user }))
    yield setSocketField('isLogin', true)

    const socket = io(`${host.chat}chatroom`,
        {
        transports: ['websocket'],
        cookie: 'sid', cookiePath: '/', cookieHttpOnly: true,
        },
    )
    const client = yield socket.on('connect', (msg) => {
        put(connectSocket({ connected: socket.connected }))
        

        return socket
    })
    return client
 }catch(ex){
     
 }

}

function subscribe(socket) {
  return eventChannel(emit => {
    socket.on('updateUsersList', (args) => {
      if(!args.error)
        emit(onLiveUser({ liveUser: args }))
    })

    // socket.on('onProduct', async(args) => {
    //   const json = JSON.parse(args)

    //   emit(dispatchLive({
    //     type :  SET_ITEMS_LIVE,
    //      payload : json
    //   })
    //   )

    // })

    socket.on('addMessage', (message) => {
      const thisMessage = {
        type: 'in-room',
        message: message.content,
        username: message.username
      }
      emit(newMessage({ message: thisMessage }))
    })

    socket.on('userjoin', (user) => {
      const joinMessage = {
        type: 'join',
        message: '',
        username: user.username
      }
      emit(newMessage({ message: joinMessage }))
    })

    // socket.on('isLike', (like) => {
      // if(delayUnlike){
      //   clearTimeout(delayUnlike)
      // }

      // emit(onLike({ countLike: like }))

      // delayUnlike = setTimeout(() => {
      //   emit(onUnLike())
      // }, 5000);

    // })

    socket.on('endLive', (isEndLive) => {
      
      emit(dispatchLive({
        type :  FETCH_CHECK_LIVE,
         payload : {}
      })
      )

    })

    socket.on('startLive',(isStartLive) => {
      
      emit(dispatchLive({
        type :  FETCH_CHECK_LIVE,
         payload : {}
      })
      )
    })

    socket.on('time',(time) => {
      if(time > 0) {
        emit(dispatchLive({ type :  SET_LIVE_DURATION, payload : {liveDuration: time} }) )
      }
    })

    socket.on('lastMessage', (message) => {
      
      message.map(msg=>{
        const msgg = JSON.parse(msg)
        const thisMessage = {
          type: 'in-room',
          message: msgg.content,
          username: msgg.username
        }
        emit(newMessage({ message: thisMessage }))
      })
    })

    return () => {}
  })
}

function* read(socket) {
  const channel = yield call(subscribe, socket)
  while (true) {
    let action = yield take(channel)
    yield put(action)
  }
}

function* writeMessage(socket) {
  while (true) {
    const { payload } = yield take(`${sendMessage}`)
    socket.emit('newMessage', payload.roomId, payload.message)
    const thisMessage = {
      type: 'in-room',
      message: payload.message.content,
      username: payload.message.username
    }
    yield put(newMessage({ message: thisMessage }))
  }
}

function* like(socket) {
  while (true) {
    const { payload } = yield take(`${sendLike}`)
    socket.emit('isLike', payload.roomId, null)
  }
}

function* unLike() {
  while (true) {
    const { payload } = yield take(`${onUnLike}`)
    yield put(onUnLike({ isLike: false }))
  }
}

function * handleIO (socket) {
  yield fork(read, socket)
  yield fork(writeMessage, socket)
  yield fork(like, socket)
  // yield fork(unLike)
  yield fork(userLogin)
}

function* userLogin() {
  while (true) {
    //need mo implementation
    const { payload } = yield take(`${login}`)
    // 
    // yield put(leave())
    yield deleteCookieData('connect.sid')
    // yield put(join({roomId:payload.roomId}))
  }
}

function* setSocketField(key, value) {
  yield put(setField({ key: key, value: value }))
}

function* flow() {
  while (true) {
    let { payload } = yield take(`${join}`)
    const socket = yield call(connect)
    socket.emit('join', payload.roomId)
    socket.emit('lastMessage', payload.roomId, 0)
    const task = yield fork(handleIO, socket)
    let action = yield take(`${leave}`)
    yield cancel(task)
    socket.emit('disconnect')
  }
}

export default function* useWatcher() {
  yield fork(flow)
}