
import classNames from 'classnames'
import React from 'react'
import './styles.scss'

//select font 
// primary-font = baijamjulee
// secondary-font = kanit

export const Topic = (props) => {
    return <span className={classNames(`topic`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const Title = (props) => {
    return <span className={classNames(`title`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const SubTitle = (props) => {
    return <span className={classNames(`sub-title`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const Content = (props) => {
    return <span className={classNames(`content`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const HeadContent = (props) => {
    return <span className={classNames(`head-content`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const Caption = (props) => {
    return <span className={classNames(`caption`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const Note = (props) => {
    return <span className={classNames(`note`, { 'secondary-font': props.secondary, 'primary-font': props.primary })} style={props.style}> {props.children} </span>
}

export const LinkLabel = (props) => {
    return <span className={classNames('link', props.className)} style={props.style}>{props.children}</span>;
};
