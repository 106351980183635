// import React, { useState } from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import success from '../../images/icons/success.svg'
// import './modalalert.scss'
// import {connect} from 'react-redux'

// const ModalAlert = (props) => {
//     const {
//         buttonLabel,
//     } = props;

//     const [modal, setModal] = useState(false);

//     const toggle = () => setModal(!modal);


//     return (
//         <div id="modal-alert">
//             <Button color="danger" onClick={toggle}>clickkkkkkkkkkkkkk</Button>
//             <Modal isOpen={modal} toggle={toggle} modalClassName="modal-dialog-body">
//                 {/* <ModalHeader toggle={toggle}></ModalHeader> */}
//                 <ModalBody style={{height:"25em"}}>
//                     <div className="h-100 d-flex justify-content-around flex-column align-items-center"> 
//                     <div className="d-flex align-items-center justify-content-center">
//                         <div className="d-flex flex-column text-center">
//                         <img src={success} alt="success" />
//                         <h5 className="mt-4">สร้างไลฟ์สำเร็จ</h5>
//                         </div>
//                     </div>
//                     <button className="btn-submit" onClick={toggle}>ตกลง</button>
//                     </div>
//                 </ModalBody>
//             </Modal>
//         </div>
//     );
// }

// const mapStateToProps = (state) => ({
//     isOpen: state.ModalAlert
// })

// export default connect(mapStateToProps, null)(ModalAlert)

import React, { Component, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import success from '../../images/icons/success.svg'
import './modalalert.scss'
import {connect} from 'react-redux'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from '../../redux/actions/modalAlert';
import { message } from 'antd';

class ModalAlertSuccess extends Component {
    constructor(props){
        super(props)
        this.state = {
            modal: false
        }
    }

    toggle = () => this.setState({modal: !this.state.modal})


    render() {
        
        const {isOpen, message} = this.props
        return (
            <div id="modal-alert">
                {/* <Button color="danger" onClick={() => this.props.dispatch(dispatchModalAlert({type:OPEN_MODAL_ALERT,payload:false}))}>clickkkkkkkkkkkkkk</Button> */}
                <Modal isOpen={isOpen.modalAlert} toggle={() => this.props.dispatch(dispatchModalAlert({type:OPEN_MODAL_ALERT,payload:false}))} modalClassName="modal-dialog-body">
                    {/* <ModalHeader toggle={toggle}></ModalHeader> */}
                    <ModalBody style={{ height: "25em" }}>
                        <div className="h-100 d-flex justify-content-around flex-column align-items-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex flex-column text-center">
                                    <img src={success} alt="success" />
                                    <h5 className="mt-4">{message}</h5>
                                </div>
                            </div>
                            <button className="btn-submit" onClick={() => this.props.dispatch(dispatchModalAlert({type:OPEN_MODAL_ALERT,payload:false}))}>ตกลง</button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.modalAlert
})


export default connect(mapStateToProps)(ModalAlertSuccess)

