import iconcamera from 'images/icons/iconcamera.svg'
import iconvideo from 'images/icons/iconvideo.svg'


export const tabsBrowse = [
  {
    key: 'browse',
    title: 'อัปโหลดไฟล์',
  },
  {
    key: 'albums',
    title: 'เลือกจากคลังภาพและวิดีโอ',
  },
]



export const optionsDropdownSort = [
  {
    value: 'lastUpload',
    name: 'อัปโหลดล่าสุด',
  },
  {
    value: 'name',
    name: 'เรียงตามตัวอักษร',
  },
]


export const detailButton = {
  image: {
    icon: iconcamera,
    title: 'เพิ่มรูปภาพ',
    reset: '',
  },
  video: {
    icon: iconvideo,
    title: 'เพิ่มวิดีโอ',
    reset: 'เลือกวิดีโอใหม่',
  },
}



