import axios from '../config/axios'
import host from '../config/host'

class PlaylistsProvider {
  getPlaylists = async () => {
    try {
      const url = `${host.api}/playlists/cms`
      const res = await axios.get(url)
      return res.data
    } catch (error) {}
  }

  createPlaylists = async (body) => {
    try {
      const url = `${host.api}/playlists`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  updatePlaylists = async ({ body, playlistsId }) => {
    try {
      const url = `${host.api}/playlists/${playlistsId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  deletePlaylists = async (playlistsId) => {
    try {
      const url = `${host.api}/playlists/${playlistsId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) {}
  }

  createPlaylistItem = async (body) => {
    try {
      const url = `${host.api}/playlist-items`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) {}
  }

  getPlaylistItemsByPlaylistId = async (query) => {
    try {
      const url = `${host.api}/playlist-items/playlist/${query.playlistsId}?limit=${query.limit}&page=${query.page}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {}
  }

  deletePlaylistsItem = async (playlistsItemId) => {
    try {
      const url = `${host.api}/playlist-items/${playlistsItemId}`
      const res = await axios.delete(url)
      return res.data
    } catch (error) {}
  }

  shufflePlaylistsItem = async (playlistsId) => {
    try {
      const url = `${host.api}/playlist-items/shuffle/${playlistsId}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {
      return error
    }
  }

  pinPlaylistsItem = async (params) => {
    try {
      const url = `${host.api}/playlist-items/${params.playlistsItemId}`
      const res = await axios.patch(url, { pinDate: params.pinDate })
      return res.data
    } catch (error) {
      return error
    }
  }

  // searchMediaByName = async(payload) => {
  //     try {
  //         const url = `${host.api}/medias/search?name=${payload.name}&albumId=${payload.albumId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }

  // onFecthAlbumDefaultByShopId = async(shopId) => {
  //     try {
  //         const url = `${host.api}/albums/getDefaultAlbum/${shopId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }

  updateOrderPlaylists = async (playlistsId, body) => {
    try {
      const url = `${host.api}/playlists/order/${playlistsId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }
  
  updateOrderPlaylistsItems = async (playlistItemId, body) => {
    try {
      const url = `${host.api}/playlist-items/order/${playlistItemId}`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }


}

export default new PlaylistsProvider()
