import React, { Component } from 'react'
import { connect } from 'react-redux'
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import noProduct from '../images/icons/no-product.svg'
// import stock from '../images/icons/stock.svg'
import edit from '../images/icons/edit.svg'
// import copy from '../images/icons/copy.svg'
// import IconProductEmpty from '../images/icons/product-empty.svg'
// import { Link } from 'react-router-dom'
import '../styles/_product.scss';
import { dispatchProduct, GET_LIST_PRODUCT, OPEN_MODAL_ADD_PRODUCT, ADD_KEY_VALUE_PRODUCT, HANDLE_SEARCH_PRODUCT, GET_DATA_PRODUCT_PAGE, HANDLE_DELETE_PRODUCT, OPEN_MODAL_LIMIT_PURCHASE } from '../redux/actions/product'
import Pagination from '../components/pagination'
import host from '../config/host'
// import Search from '../images/icons/search.svg'
import noItem from '../images/icons/status-error.svg'
import ModalAddProduct from '../components/modalAddProduct';
import { checkPermission } from '../helper/utils'
import IconTrash from '../images/icons/trash.svg';
import IconView from '../images/icons/view.svg';
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import Cookies from 'js-cookie';
import { ADMIN, OWNER } from '../layout/Sidebar/Role';
import { InputSearch } from 'components/output';
import ModalLimitPurchase from 'components/ModalLimitPurchase'
import { Caption, LinkLabel } from 'components/FontStyle/'
import numeral from 'numeral';
import classNames from 'classnames';

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listAllProduct: [],
            statusSearch: false,
            historySearch: "",
            search: "",
            productMode: "ADD",
            productSelected: null,
            userRole: null
        }
    }


    componentDidMount() {
        localStorage.setItem("createProductBy", JSON.stringify({ createAt: "product" }));
        this.getProduct();

        const user = Cookies.get('user')
        const userInfo = user ? JSON.parse(user !== undefined ? user : null) : {};
        const userRole = userInfo.shopId ? OWNER : ADMIN;
        this.setState({ userRole });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.product.listAllProduct !== state.listShopName) {
            return {
                listAllProduct: props.product.listAllProduct
            }
        }
        return state;
    }

    componentWillUnmount() {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: 10 } }))
    }

    getProduct = () => {
        // this.props.dispatch(dispatchProduct({
        //     type: GET_LIST_PRODUCT, payload: {
        //         limit: this.props.product.limit,
        //         page: this.props.product.page,
        //     }
        // }))
        this.props.dispatch(dispatchProduct({ type: GET_DATA_PRODUCT_PAGE }))
    }

    openModalAddProduct = (mode, productSelected = {}) => {
        this.setState({ productMode: mode, productSelected }, () =>
            this.props.dispatch(dispatchProduct({ type: OPEN_MODAL_ADD_PRODUCT, payload: { isOpen: true } }))
        );
    }

    onChangePage = (page) => {
        if (this.state.statusSearch) {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.historySearch } }))
        } else {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: page } }))
            this.props.dispatch(dispatchProduct({ type: GET_LIST_PRODUCT }))
        }
    }

    onShowSizeChange = (current, pageSize) => {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'limit', value: pageSize } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: current } }))
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value })
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: e.target.value } }))
    }

    onSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            if (this.state.search.trim().length === 0) {
                this.onClearSearch()
            }
            // this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
            // this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
            this.onClickSeachProduct()
        }

    }

    onClickSeachProduct = () => {
        if (this.state.search.trim().length === 0) {
            this.setState({ statusSearch: false, historySearch: this.state.search })
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))
        } else {
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
            this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: true } }))
            this.setState({ statusSearch: true, historySearch: this.state.search })
            this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))
        }
    }

    openModalDeleteProduct = (product) => {
        this.props.dispatch(dispatchModalConfirm({
            type: OPEN_MODAL_CONFIRM, payload: {
                isOpen: true,
                message: `คุณต้องการลบสินค้า ${product.name}`,
                btnConfirm: 'ใช่',
                btnCancel: 'ไม่ใช่',
                isClose: () => this.props.dispatch(dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: false })),
                confirm: () => this.handleDeleteProduct(product),
            }
        }))
    }

    handleDeleteProduct = (product) => {
        this.props.dispatch(dispatchProduct({ type: HANDLE_DELETE_PRODUCT, payload: { product, statusSearch: this.state.statusSearch, historySearch: this.state.historySearch } }))
    }

    onClearSearch = () => {
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'page', value: 1 } }))
        this.setState({ search: '', statusSearch: false })
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'statusSearch', value: false } }))
        this.props.dispatch(dispatchProduct({ type: ADD_KEY_VALUE_PRODUCT, payload: { key: 'valueSearch', value: '' } }))
        this.getProduct()
    }

    openModalLimitPurchase = (data, product) => {
        const { productSkus, ...productNosku } = product.product
        this.props.dispatch(
            dispatchProduct({
                type: OPEN_MODAL_LIMIT_PURCHASE,
                payload: { isOpen: true, data, product: productNosku },
            })
        );
    }


    render() {
        const { product, limit, page, loading, total } = this.props;
        const { userRole } = this.state;
        return (
            <div className="shop">

                <div className="header-product d-flex flex-row justify-content-between align-items-center m-auto p-3 col-12">
                    <span className="d-flex flex-row align-items-baseline header-gap">  <h3>รายการสินค้า</h3> <h5>สินค้าทั้งหมด {total} รายการ</h5> </span>
                    <div style={{ color: "orange" }} className="d-flex align-items-center add-product-btn" onClick={() => this.openModalAddProduct('ADD')}>
                        {checkPermission('product', 'create', 'rw') ?
                            <React.Fragment>
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-plus mr-1" />
                                <span>สร้างรายการสินค้า</span>
                            </React.Fragment> : null}

                    </div>
                </div>

                <div className="search-product col-12 m-auto">
                    <div className="input-group col-12 input-search">
                        <div className="w-50 position-relative">

                            <InputSearch
                                placeholder={"ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์"}
                                onChange={this.onChangeSearch}
                                onKeyPress={this.onSearchKeyPress}
                                handleSearch={this.onClickSeachProduct}
                                onClearSearch={this.onClearSearch}
                                statusSearch={this.state.statusSearch}
                                defaultValue={product.valueSearch}
                            />
                            {/* <input type="text" className="form-control text-left position-relative w-100 search-product-input"
                                placeholder="ค้นหาจากชื่อสินค้า, ประเภทสินค้า, แบรนด์"
                                onChange={(e) => this.onChangeSearch(e)}
                                onKeyPress={this.onSearchKeyPress}
                            /> */}
                            {/* <img src={Search} alt="search" className="icon-search-product cursor-pointer" onClick={this.onClickSeachProduct} /> */}
                        </div>
                        <div className="input-group-append">
                            <span className="input-group-text"></span>
                        </div>
                    </div>
                </div>

                {this.state.listAllProduct.length === 0 ? null :
                    <div className="pagination-top">
                        <div className="float-right position-relative">
                            <Pagination
                                total={product.total}
                                defaultCurrent={1}
                                onChange={this.onChangePage}
                                pageSize={this.props.limit}
                                pageSizeOptions={product.pageSizeOptions}
                                onShowSizeChange={this.onShowSizeChange}
                                current={product.page}
                                showQuickJumper={true}
                            />
                        </div>
                    </div>
                }



                {this.state.listAllProduct.length === 0 && !loading ? <div className="d-flex flex-column align-items-center justify-content-center mt-4 h-50vh">
                    <img src={noItem} alt="IconProductEmpty" />
                    <span className="mt-2 font-secondary">ยังไม่มีรายการสินค้า</span>
                </div> :
                    <div className="body-product col-12 m-auto list-product">
                        <div className="p-0 grid-head-col">
                            <div className="">ลำดับ</div>
                            <div className="">ชื่อสินค้า</div>
                            <div className="text-left">ชื่อร้านค้า</div>
                            <div>จำกัดการซื้อ</div>
                            <div className="">รหัส SKU</div>
                            <div className="text-left">ตัวเลือกสินค้า</div>
                            <div className="text-right">ราคาขาย</div>
                            <div className="text-right">คลัง</div>
                            <div className="">สถานะ</div>
                            <div className="">
                                จำกัดการซื้อ <br />
                                <Caption primary>(สินค้าย่อย)</Caption>
                            </div>
                            <div></div>
                        </div>

                        {this.state.listAllProduct.map((item, idx) => (
                            <div className="grid-col" key={idx}>
                                <div className="text-center">
                                    <div>{(page - 1) * limit + idx + 1}</div>
                                </div>
                                <div className="d-flex flex-row product-name align-items-start">
                                    <img src={host.avatar + item?.imagePath[0]?.path} alt="stock" style={{ width: '5em' }} />
                                    <div className="d-flex flex-column justify-content-start align-items-start">
                                        <span className="font-weight-bold">{item.name}</span>
                                        {/* <span>ประเภท : ทั่วไป</span> */}
                                        {/* <span className="message-success">แสดงในหน้าเว็ป</span> */}
                                    </div>
                                </div>
                                <div className="text-left">{item?.shop?.name}</div>
                                <div className="text-center">
                                    <LinkLabel>
                                        <span onClick={() => this.openModalLimitPurchase(item.productSkus, { product: item })}>
                                            {item?.limit ? item.limit : 'ไม่จำกัด'}
                                        </span>
                                    </LinkLabel>
                                </div>

                                <div className="text-center">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{item.id}</span>
                                    ))}

                                </div>
                                <div className="text-center">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{item.sku}</span>
                                    ))}
                                </div>
                                <div className="text-right">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>฿ {numeral(item.priceNet).format('0,0.00')}</span>
                                    ))}
                                </div>
                                <div className="text-right">
                                    {item.productSkus.map((item, idx) => (
                                        <span key={idx}>{numeral(item.stockRemain).format('0,0')}</span>
                                    ))}
                                </div>
                                <div className="text-center">
                                    {item.productSkus.map((sku, idx) => (
                                        <span key={idx}>
                                            {sku.status === "show" ?
                                                <span className="message-success">ขายอยู่</span> :
                                                <span className="message-grey">ไม่ขายแล้ว</span>}</span>
                                    ))}
                                    {/* <span className="message-success">ขายอยู่</span>
                                    <span className="message-success">ขายอยู่</span>
                                    <span className="text-danger">หมด</span> */}
                                </div>

                                <div className="text-center">
                                    {item.productSkus.map((sku, idx) => (
                                        <label style={{ paddingTop: 5 }} key={'skulist' + idx}
                                            onClick={() => this.openModalLimitPurchase(item.productSkus, { product: item })}>
                                            <LinkLabel className={classNames({ 'success': sku.limit })}>{sku.limit ? sku.limit : 'ไม่จำกัด'}</LinkLabel>
                                        </label>
                                    ))}

                                </div>

                                {userRole === OWNER ?
                                    <React.Fragment>
                                        <div className="text-center">
                                            <div className="d-flex">
                                                <span><img src={IconView} alt="view" onClick={() => this.openModalAddProduct('VIEW', item)} className='icon-link' /></span>
                                                <span><img src={edit} alt="edit" onClick={() => this.openModalAddProduct('UPDATE', item)} className='icon-link' /></span>
                                                <span> <img src={IconTrash} alt="iconTrash" className='icon-link' onClick={() => this.openModalDeleteProduct(item)} /> </span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="text-center">
                                            <div>
                                                {/* <span><img src={IconView} alt="view" onClick={() => this.openModalAddProduct('VIEW', item)} className='icon-link' /></span> */}
                                                <img src={edit} alt="edit" onClick={() => this.openModalAddProduct('UPDATE', item)} className='icon-link' />
                                                <img src={IconTrash} alt="iconTrash" className='icon-link' onClick={() => this.openModalDeleteProduct(item)} />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        ))}

                        <div className="float-right position-relative mt-2">
                            <Pagination
                                total={product.total}
                                defaultCurrent={1}
                                onChange={this.onChangePage}
                                pageSize={this.props.limit}
                                pageSizeOptions={product.pageSizeOptions}
                                onShowSizeChange={this.onShowSizeChange}
                                current={product.page}
                                showQuickJumper={true}
                            />
                        </div>



                    </div>
                }

                <ModalAddProduct mode={this.state.productMode} productSelected={this.state.productSelected} />
                <ModalLimitPurchase productSelected={this.state.productSelected} getListProduct={() => this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.state.search } }))} />
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    product: state.product,
    limit: state.product.limit,
    page: state.product.page,
    loading: state.app.loading,
    total: state.product.total,
    user: state.user.userInfo
})

export default connect(mapStateToProps, null)(Product)
