import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import './styles.scss'

const TextInput = (props) => {
  const {
    label,
    defaultValue,
    handleChangeText,
    placeholder,
    error,
    require,
    disabled,
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])




  const onChangeText = (e) => {
    let tmp = e.target.value

    if (label === 'Permalink') {
      tmp = tmp.split(' ').join('-')
    }
    
    setValue(tmp)
    handleChangeText(tmp)
  }

  return (
    <div className='text-input-field'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}
      <div className={classnames('container-input', { error: !!error })}>
        <input
          type='text'
          className='input-box'
          placeholder={placeholder}
          onChange={onChangeText}
          value={value}
          disabled={disabled}
        />
      </div>
      {error && <div className='txt-red mt-1'>{error}</div>}
    </div>
  )
}

TextInput.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  error: '',
  require: false,
  disabled: false,
}

export default TextInput
