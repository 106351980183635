import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Select } from "antd"
import React, { useEffect, useState } from "react"
import "./index.scss"

const { Option } = Select

const DropDownPlaylist = (props) => {
  const { options, defaultValue, handleSelected } = props
  const [valueDropdown, setValueDropdown] = useState()
  const [isToggle, setIsToggle] = useState(false)

  useEffect(() => {
    if (defaultValue) {
      setValueDropdown(defaultValue)
    }
  }, [defaultValue, options])

  const onSelectValue = (data) => {
    setValueDropdown(data)
    handleSelected(data)
  }

  return (
    <div className="dropdown-container">
      <Select
        className="dropdown-playlist"
        value={valueDropdown}
        onSelect={onSelectValue}
        bordered={false}
        onDropdownVisibleChange={(status) => setIsToggle(status)}
        suffixIcon={
          <FontAwesomeIcon className="icon-angle" icon={isToggle ? faAngleUp : faAngleDown} />
        }
      >
        {options?.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default DropDownPlaylist
