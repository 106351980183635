import axios from 'axios';
import { getLocalStorage, setLocalStorage } from 'helper/utils';
import Cookies from 'js-cookie';
import userProvider from '../provider/userProvider'
import host from './host';

const axiosNew = axios.create();
let from;
let data;
let getConfig
let isRefresh

axiosNew.interceptors.request.use(function (config) {
  getConfig = config
  from = config.url?.split('/api/')[1];
  data = config.data

  const access_token = Cookies.get('token');
  config.headers['channel'] = "cms";
  config.headers['platform'] = navigator.platform;
  config.headers['userAgent'] = navigator.userAgent;
  if (access_token != null)
    config.headers.Authorization = "Bearer " + access_token;
  else
    setLocalStorage({key:'tempUrl',value: window.location.pathname})
    config.headers['secret-key'] = host.tokenSecretKey
  return config;
}, function (err) {
  return Promise.reject(err);
});

axiosNew.interceptors.response.use(function (response) {

  // 
  // 
  // 
  // 
  // 


  return response;
}, async function (error) {
  const err = error.response

  if (err?.status == 401) {
    if (!isRefresh) {
      isRefresh = true
      const refreshToken = Cookies.get('refreshToken')
      err._retry = true
      setLocalStorage({key:'tempUrl',value: window.location.pathname})

      const token = await userProvider.refreshToken({
        refreshToken:
          refreshToken != undefined
            ? refreshToken
            : window.location.replace('/login'),
      })
      err.config.headers['Authorization'] = 'Bearer ' + token
      isRefresh = false
      return axiosNew(err.config)
    }
  }

  if (err?.status == 422) {
    return err
  }

  return Promise.reject(error)
});


export default axiosNew;