import orderProvider from '../../provider/orderProvider';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import {
  GET_LIST_ORDER, SET_ORDER_FIELD, ON_SEARCH_ORDER, SET_ORDERLIST_IN_ORDER,
  UPDATE_INVOICE_LOGISTIC, UPDATE_INVOICE_SHOP_NOTE,
  SET_UPDATE_SHOP_NOTE_RES, SET_UPDATE_LOGISTIC_RES, REQUEST_REFUND, SET_MODAL_REFUND_REASON,
  ON_CONFIRM_REFUND, OPEN_MODAL_CONFIRM, CLOSE_MODAL_CONFIRM, SET_CLEARING_ORDER, ADD_CLEARING_ORDER,
  SET_DATA_CLEARING_ORDER, SELECT_ALL_CLEARING_ORDER, SET_SELECT_ALL_CLEARING, OPEN_MODAL_CONFIRM_REFUND,
  CLOSE_MODAL_CONFIRM_CLEARING_ALL, OPEN_MODAL_INVOICE_DETAIL, OPEN_MODAL_CONFIRM_CLEARING, REDIRECT_TAB,
  CLOSE_MODAL_CONFIRM_CLEARING, CLOSE_MODAL_CONFIRM_REFUND,
  SET_COMPLETE_LOGISTIC, GET_ORDER_DELIVERIES, SET_ORDER_DELIVERIES,
  HANDLE_SET_REDELIVERY, CLOSE_MODAL_CONFIRM_LARGE, CONFIRM_ORDER
} from '../actions/order';
import { OPEN_TOOLTIP } from '../actions/notification';
import { getOrder } from './selectors'
import { SET_LOADING } from '../actions/app'
import { OPEN_MODAL_MESSAGE, OPEN_MODAL_MESSAGE_ERROR } from '../actions/modalMessage';
import { GET_LIST_SHOP } from 'redux/actions/shop';
import { OPEN_MODAL_ALERT } from 'redux/actions/modalAlert';

export function* onGetListOrder(action) {
  const { payload } = action
  yield put({ type: SET_LOADING, payload: { loading: true } })
  try {
    yield put({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: [] } })
    // yield put({ type: SET_ORDER_FIELD, payload: { key: 'total', value: 0 } })
    const getOrderState = yield select(getOrder)
    const res = yield call(orderProvider.getListOrder, { status: getOrderState.status, page: getOrderState.page, limit: getOrderState.limit, shopId: getOrderState.searchShopSelect?.value?.id })
    if (res.deliveryDelayed > 0) {
      const getOrderState = yield select(getOrder)
      if (getOrderState.status !== "deliveryDelayed") {
        yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'คุณมีรายการสั่งซื้อที่เกินเวลาจัดส่ง', type: 'alert', btnLabel: 'ดูรายละเอียด' } })
      }
    }
      yield put({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: res } })
      yield put({ type: SET_ORDER_FIELD, payload: { key: 'total', value: res[getOrderState.status] } })
      yield put({ type: GET_LIST_SHOP })
      yield put({ type: SET_LOADING, payload: { loading: false } })
      // if(getOrderState.status === "deliveryDelayed"){
      //   const getOrderState = yield select(getOrder)
      //   if(getOrderState.listOrder.data.length > 0){
      //     yield put({ type: OPEN_MODAL_ALERT, payload: { isOpen: true, message: 'คุณมีรายการสั่งซื้อที่เกินเวลาจัดส่ง', type: 'alert', btnLabel:'ดูรายละเอียด' } })
      //   }
      // }
    } catch (err) {
      console.log(`err`, err)
      // throw err
    }
  }

export function* onSearchOrder(action) {
    // OLD SEARCH****************
    // const { payload } = action
    // yield put({ type: SET_LOADING, payload: { loading: true } })
    // try {
    //   const getOrderState = yield select(getOrder)
    //   const res = yield call(orderProvider.onSearchOrder, { status: getOrderState.status, search: payload.search, limit: getOrderState.limit, page: getOrderState.page, filter: payload.filter })
    //   yield put({ type: SET_ORDERLIST_IN_ORDER, payload: { key: 'data', value: res.data.data } })
    //   yield put({ type: SET_ORDERLIST_IN_ORDER, payload: { key: getOrderState.status, value: res.data.count } })
    //   yield put({ type: SET_ORDER_FIELD, payload: { key: 'total', value: res.data.count } })
    //   yield put({ type: SET_LOADING, payload: { loading: false } })
    // } catch (error) {
    //   yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    // }
    // OLD SEARCH****************

    const { payload } = action
    const { filter, value, shopId } = payload
    yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
      const getOrderState = yield select(getOrder)
      const res = yield call(orderProvider.onFilterSearchOrder, { kwby: getOrderState.filterSearchSelect, kw: getOrderState.filterSearchValue, limit: getOrderState.limit, page: getOrderState.page, shopId: shopId, status: getOrderState.status })
      if (res) {
        yield put({ type: SET_ORDER_FIELD, payload: { key: 'listOrder', value: res.data } })
        yield put({ type: SET_ORDERLIST_IN_ORDER, payload: { key: getOrderState.status, value: res.data[getOrderState.status] } })
        yield put({ type: SET_ORDER_FIELD, payload: { key: 'total', value: res.data[getOrderState.status] } })
      } else {
        yield put({ type: GET_LIST_ORDER })
      }
      yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {

      yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
  }

  export function* onUpdateInvoiceLogistic(action) {
    const { payload } = action; // param : {deliveryId: number , trackingNo: string, logisticId: string, logistic: string} d
    try {
      yield put({ type: SET_LOADING, payload: { loading: true } })
      const RES = yield call(orderProvider.updateInvoiceLogistic, payload);
      if (RES.code === 422 && RES.message === "TRACKING_DOES_NOT_EXISTS") {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'ไม่พบข้อมูลหมายเลขพัสดุนี้', title: 'iconFail', } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
      } else if (RES.code === 422 && RES.message === "UPDATE_TRACKING_ERROR") {
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR, payload: { message: 'อัพเดทข้อมูลไม่สำเร็จ ข้อมูลผิดพลาด', title: 'iconFail', } })
        yield put({ type: SET_LOADING, payload: { loading: false } })
      } else {
        yield put({ type: SET_UPDATE_LOGISTIC_RES, payload: { RES } });
        yield put({ type: OPEN_TOOLTIP, payload: { show: true, message: "บันทึกแล้ว", toolTipId: 'tracking-no' } });

        const getOrderState = yield select(getOrder);
        if (getOrderState.statusSearch) {
          yield onSearchOrder({
            payload: {
              shopId: getOrderState.searchShopSelect?.value?.id
            }
          })
        } else {
          yield onGetListOrder({})
        }

        yield put({ type: SET_LOADING, payload: { loading: false } })
        // yield delay(800);
        yield put({
          type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false }
        })
      }




    } catch (error) {
      console.log(`error`, error)
      yield put({ type: SET_LOADING, payload: { loading: false } })
    }
  }

  export function* onCompleteLogistic(action) {
    const { payload } = action; // param : {deliveryId: number , trackingNo: string} d
    try {
      yield put({ type: SET_LOADING, payload: { loading: true } })
      const RES = yield call(orderProvider.updateLogisticComplete, payload);
      yield put({ type: SET_UPDATE_LOGISTIC_RES, payload: { RES } });
      yield put({ type: OPEN_TOOLTIP, payload: { show: true, message: "บันทึกแล้ว", toolTipId: 'tracking-no' } });
      const getOrderState = yield select(getOrder);
      if (getOrderState.statusSearch) {
        yield onSearchOrder({
          payload: {
            shopId: getOrderState.searchShopSelect?.value?.id
          }
        })
      } else {
        yield onGetListOrder({})
      }
      yield put({ type: SET_LOADING, payload: { loading: false } })
      // yield delay(800);
      yield put({
        type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false }
      })
      // yield put({ type: REDIRECT_TAB, payload: { status: getOrderState.status } })
      // if (getOrderState.status === 'waitShipping')
      //   yield put({ type: REDIRECT_TAB, payload: { status: 'waitShipping' } })
      // if (getOrderState.status === 'onTheWay')
      //   yield put({ type: REDIRECT_TAB, payload: { status: getOrderState.status } })
    } catch (error) {
      yield put({ type: SET_LOADING, payload: { loading: false } })
    }
  }

  export function* onUpdateInvoiceShopNote(action) {
    const { payload } = action; // param : {invoiceId: number, shopNote: string}
    try {
      yield put({ type: SET_LOADING, payload: { loading: true } })
      const RES = yield call(orderProvider.updateInvoiceShopNote, payload);
      yield put({ type: SET_UPDATE_SHOP_NOTE_RES, payload: { RES } });
      yield put({ type: OPEN_TOOLTIP, payload: { show: true, message: "บันทึกสำเร็จ", toolTipId: 'shop-note' } });
      yield put({ type: GET_LIST_ORDER, payload: {} });
      yield put({ type: SET_LOADING, payload: { loading: false } })


    } catch (error) {

    }
  }

  export function* onHandleRedirectTab(action) {
    const { payload } = action
    yield put({ type: GET_LIST_ORDER, payload: {} })
    yield put({ type: SET_MODAL_REFUND_REASON, payload: false })
    yield put({ type: SET_ORDER_FIELD, payload: { key: 'status', value: payload.status } })
    yield put({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false } })
    yield put({ type: CLOSE_MODAL_CONFIRM_CLEARING, payload: {} })
    yield put({ type: CLOSE_MODAL_CONFIRM_REFUND, payload: {} })
  }

  export function* onHandleRefund(action) {
    const { payload } = action
    yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
      const res = yield call(orderProvider.onRefundOrder, payload)
      if (res.data) {
        // yield put({
        //   type: OPEN_MODAL_MESSAGE, payload: {
        //     title: "iconSuccess",
        //     message: "ส่งคำขอคืนเงินแล้ว",
        //     btnLabel: 'ตกลง',
        //   }
        // })
        yield put({ type: REDIRECT_TAB, payload: { status: 'waitRefund' } })
        // window.location.replace('/manage/order/waitRefund')
      }

    } catch (error) {
      yield put({ type: SET_LOADING, payload: { loading: false } })
      yield put({
        type: OPEN_MODAL_MESSAGE, payload: {
          title: "iconFail",
          message: "รายการผิดพลาด",
          btnLabel: 'ตกลง',
        }
      })
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
  }

  export function* onConfirmRefund(action) {
    const { payload } = action
    const { logisticStatus, completedAt } = payload.currentInvoice

    yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
      if (payload.status) {
        const res = yield call(orderProvider.onConfirmRefund, payload.id)
        if (res.data) {
          yield put({ type: REDIRECT_TAB, payload: { status: 'refunded' } })
        }
      } else {
        const res = yield call(orderProvider.onCancelRefund, payload.id)
        if (res.data) {
          if (logisticStatus == "wait") {
            yield put({ type: REDIRECT_TAB, payload: { status: 'waitShipping' } })
          } else if (logisticStatus == "posting" || logisticStatus == "intransit" || logisticStatus == "redelivery" || logisticStatus == "delivered" && !completedAt) {
            yield put({ type: REDIRECT_TAB, payload: { status: 'onTheWay' } })
          } else if (logisticStatus == "delivered" && completedAt) {
            yield put({ type: REDIRECT_TAB, payload: { status: 'success' } })
          }

        }
      }
      yield put({ type: SET_LOADING, payload: { loading: false } })

    } catch (error) {
      yield put({ type: SET_LOADING, payload: { loading: false } })
      yield put({
        type: OPEN_MODAL_MESSAGE, payload: {
          title: "iconFail",
          message: "รายการผิดพลาด",
          btnLabel: 'ตกลง',
        }
      })

    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
  }

  export function* onClearingOrder(action) {
    const { payload } = action
    try {
      const getOrderState = yield select(getOrder)
      const data = {
        orderId: payload.id == undefined ? getOrderState.clearingOrder : [payload.id]
      }
      const res = yield call(orderProvider.onClearingOrder, data)
      if (res.data) {
        // window.location.replace('/manage/order/success')
        yield onSearchOrder({
          payload: {
            search: payload.search,
            filter: payload.filter
          }
        })
        yield put({ type: CLOSE_MODAL_CONFIRM_CLEARING_ALL })
        const orderState = yield select(getOrder)
        if (orderState.listOrder.data.length == 0) {
          yield put({ type: SET_ORDER_FIELD, payload: { key: 'page', value: orderState.page } })
          yield onSearchOrder({
            payload: {
              search: payload.search,
              filter: payload.filter
            }
          })
        }

        yield put({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { isOpen: false } })
        yield put({ type: OPEN_MODAL_CONFIRM_CLEARING, payload: { isOpen: false } })

      }
    } catch (error) {

    }
  }

  export function* onAddClearingOrder(action) {
    const { payload } = action
    const { id } = payload
    let getOrderState = yield select(getOrder)
    const idSelected = getOrderState.clearingOrder.some(item => item == id)
    if (!idSelected) {
      getOrderState.clearingOrder.push(id)
    } else {
      getOrderState.clearingOrder = getOrderState.clearingOrder.filter(item => item != id)
      // yield put({ type: SET_SELECT_ALL_CLEARING, payload: false })
    }
    yield put({ type: SET_DATA_CLEARING_ORDER, payload: getOrderState.clearingOrder })
  }

  export function* onSelectAllClearingOrder(action) {
    const getOrderState = yield select(getOrder)
    // let arr = [...getOrderState.clearingOrder]   getallstate
    let arr = []

    for (const [index, elem] of getOrderState.listOrder.data.entries()) {
      const getId = getOrderState.clearingOrder.includes(elem.id)
      if (!elem.clearingAt) {
        if (arr.includes(elem.id)) return
        arr.push(elem.id)
      }
    }
    // yield put({ type: SET_SELECT_ALL_CLEARING, payload: !getOrderState.selectAllClearingOrder })
    yield put({ type: SET_DATA_CLEARING_ORDER, payload: arr })
  }

  export function* onGetOrderDeliveries(action) {
    const { payload } = action
    const getOrderState = yield select(getOrder)
    const res = yield call(orderProvider.getOrderDeliveriesByOrderId, payload.orderId)
    yield put({ type: SET_ORDER_DELIVERIES, payload: { deliveries: res.data } })
  }

  export function* onHandleConfirmOrder(action) {
    try {
      yield put({ type: SET_LOADING, payload: { loading: false } })
      const { payload } = action
      const res = yield call(orderProvider.confirmOrder, payload)
      if (res.data) {
        yield put({ type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false } })
        yield onGetListOrder({})
      }
      yield put({ type: SET_ORDER_DELIVERIES, payload: { deliveries: res.data } })
      yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
      yield put({ type: SET_LOADING, payload: { loading: false } })
    }
  }

  export function* handleSetRedelivery(action) {
    try {
      const { payload } = action
      const res = yield call(orderProvider.reDelivery, { orderId: payload.orderId, logisticId: payload.logisticId })
      if (res.data) {
        yield put({ type: CLOSE_MODAL_CONFIRM_LARGE })
        const getOrderState = yield select(getOrder)
        // yield put({ type: REDIRECT_TAB, payload: { status: getOrderState.status } })
        if (getOrderState.statusSearch) {
          yield onSearchOrder({
            payload: {
              shopId: getOrderState.searchShopSelect?.value?.id
            }
          })
        } else {
          yield onGetListOrder({})
        }

        yield put({
          type: OPEN_MODAL_INVOICE_DETAIL, payload: { currentInvoice: null, isOpen: false }
        })
      }
    } catch (error) {
      console.log('error', error)
      yield put({
        type: OPEN_MODAL_MESSAGE, payload: {
          title: "iconFail",
          message: "รายการผิดพลาด",
          btnLabel: 'ตกลง',
        }
      })
    }


  }


  export default function* useWatcher() {
    yield takeLatest(GET_LIST_ORDER, onGetListOrder)
    yield takeLatest(ON_SEARCH_ORDER, onSearchOrder)
    yield takeLatest(UPDATE_INVOICE_LOGISTIC, onUpdateInvoiceLogistic)
    yield takeLatest(SET_COMPLETE_LOGISTIC, onCompleteLogistic)
    yield takeLatest(UPDATE_INVOICE_SHOP_NOTE, onUpdateInvoiceShopNote)
    yield takeLatest(REQUEST_REFUND, onHandleRefund)
    yield takeLatest(ON_CONFIRM_REFUND, onConfirmRefund)
    yield takeLatest(SET_CLEARING_ORDER, onClearingOrder)
    yield takeLatest(ADD_CLEARING_ORDER, onAddClearingOrder)
    yield takeLatest(SELECT_ALL_CLEARING_ORDER, onSelectAllClearingOrder)
    yield takeLatest(REDIRECT_TAB, onHandleRedirectTab)
    yield takeLatest(GET_ORDER_DELIVERIES, onGetOrderDeliveries)
    yield takeLatest(HANDLE_SET_REDELIVERY, handleSetRedelivery)
    yield takeLatest(CONFIRM_ORDER, onHandleConfirmOrder)
  }