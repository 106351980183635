import React, {Component} from 'react';
import Radio from 'antd/lib/radio';  // for js
import './index.scss';
const RadioGroup = Radio.Group;

const RadioComponent = (props) => {

    const {onChange,value,disabled,name,plainOptions} = props;
    const radioStyle = {
        display: "block",
        "marginBottom": "5px",
        "borderRadius": "3px"
      };

    return (
        <RadioGroup onChange={onChange} 
                    value={value} 
                    disabled={disabled} 
                    name={name} 
                    options={plainOptions}>
        </RadioGroup>
    )

};

export default RadioComponent;

