export const GET_LIST_PROMOCODE = 'GET_LIST_PROMOCODE'
export const OPEN_MODAL_ADD_PROMOCODE = 'OPEN_MODAL_ADD_PROMOCODE'
export const CLOSE_MODAL_ADD_PROMOCODE = 'CLOSE_MODAL_ADD_PROMOCODE'
export const SET_TAB_PROMOCODE = 'SET_TAB_PROMOCODE'
export const SET_KEY_VALUE_PROMOCODE = 'SET_KEY_VALUE_PROMOCODE'
export const OPEN_MODAL_CONFIRM_PROMOCODE = "OPEN_MODAL_CONFIRM_PROMOCODE"
export const VERIFY_REF_CODE = "VERIFY_REF_CODE"
export const CREATE_PROMOCODE = "CREATE_PROMOCODE"
export const OPEN_MODAL_MESSAGE_PROMOCODE = "OPEN_MODAL_MESSAGE_PROMOCODE"
export const HANDLE_UPDATE_PROMOCODE = "HANDLE_UPDATE_PROMOCODE"
export const HANDLE_DELETE_PROMOCODE = "HANDLE_DELETE_PROMOCODE"
export const GET_DETAIL_UPDATE_PROMOCODE = "GET_DETAIL_UPDATE_PROMOCODE"
export const SET_DEFAULT_DATA_PROMOCODE = "SET_DEFAULT_DATA_PROMOCODE"

export function dispatchPromocode(param) {
  return {
    type: param.type,
    payload: param.payload,
  }
}
