import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modalconfirm.scss'
import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from '../../redux/actions/modalConfirm';
import classnames from 'classnames'
import Loading from 'components/Loading';


class ModalConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

static defaultProps = {
    isOpen: false,
    message: '',
    message2: '',
    btnConfirm: 'ใช่',
    btnCancel: 'ไม่ใช่',
    disabled: false
}

    confirmModal = () => {
        this.props.confirm();
        this.props.dispatch(dispatchModalConfirm({type:OPEN_MODAL_CONFIRM,payload:false}))
    }

    cancelModal = () => {
        if(this.props.isClose != undefined) return this.props.isClose()
        this.props.dispatch(dispatchModalConfirm({type:OPEN_MODAL_CONFIRM,payload:false}))
    }


    render() {
        /// message show
        const {message, isOpen, isClose, btnConfirm, btnCancel, message2, type, disabled} = this.props
        return (
            <React.Fragment>
                {/* <Button color="danger" onClick={() => this.setState({ modal: true })}>{'modalconfirm'}</Button> */}
                <Modal isOpen={isOpen} toggle={() => this.setState({ modal: false })} modalClassName="modal-dialog-confirm">
                    {/* <ModalHeader toggle={() => this.setState({ modal: true })}></ModalHeader> */}
                    <ModalBody>
                            <h4>{message}</h4>
                            <h4 className={classnames({'text-success':type == "success"})}>{message2}</h4>
                            <div className="w-100 d-flex" style={{justifyContent:'space-evenly'}}>

                            <span><button className="btn-cancel" onClick={this.cancelModal}>{btnCancel}</button></span>
                            <span><button className="btn-submit" onClick={this.confirmModal} disabled={disabled}>{btnConfirm}</button></span>

                              
                            </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    // modalConfirm : state.modalConfirm.ModalConfirm
})

export default connect(mapStateToProps, null)(ModalConfirm)