import React, { Component } from "react";
import { connect } from "react-redux";

import { Toast, ToastBody, Row, Col } from "reactstrap";
import { CLOSE_TOAST, dispatchNotification }  from '../../redux/actions/notification';
import "./index.scss";

import success from '../../images/icons/toast-success.svg';
import danger from '../../images/icons/toast-fail.svg';

class ToastNotification extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps = (nextProps) => {
          if (nextProps.show) {
            setTimeout(() => { 
               this.props.dispatch(dispatchNotification({ type: CLOSE_TOAST, payload: {message: ``, type: ''} })) 
             },3000);
          }
  }

  render() {
    
    const {toastType, show, toastMessage} = this.props;

    return (
      <React.Fragment>
        <div className={`toasts fade3 ${show ? 'show' : ''}`}>
                    <div className={`toasts-container ${toastType == 'success' ? 'success' : 'danger'}`}>
                        {toastType == 'success' && <img src={success} alt=""/>}
                        {toastType == 'danger' && <img src={danger} alt=""/>}
                        <span>{toastMessage}</span>
                    </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show: state.notification.toast.show,
  toastMessage: state.notification.toast.message,
  toastType: state.notification.toast.type,
})

export default connect(mapStateToProps)(ToastNotification);