import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  dispatchProduct,
  // GET_DATA_PRODUCT_PAGE,
  OPEN_MODAL_LIMIT_PURCHASE,
} from "redux/actions/product";
import "./index.scss";

import { Modal, ModalBody } from "reactstrap";
import Loading from "components/Loading";
import Radio from "components/RadioButton";
import ToggleInput from "components/ToggleInput";

import IconClose from "images/icons/close.svg";
import LimitProductOption from "components/Form/LimitProductOption";
import productProvider from 'provider/productProvider';
import { dispatchNotification, OPEN_TOAST } from "redux/actions/notification";
import { withRouter } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as IconHint } from 'images/icons/hint.svg'

const api_product = new productProvider()

const typeOption = {
  PER_ORDER: { value: "order", label: "ต่อคำสั่งซื้อ" },
  PER_USER: { value: "user", label: "ต่อผู้ใช้งาน" },
};
const limitOption = {
  LIMIT: { value: "LIMIT", label: "จำกัด" },
  UNLIMIT: { value: "UNLIMIT", label: "ไม่จำกัด" },
};
const periodOption = {
  DAY: { value: "day", label: "วัน" },
  MONTH: { value: "month", label: "เดือน" },
};
const typePeriodOption = {
  LIMIT: { value: true, label: 'ระบุ' },
  UNLIMIT: { value: false, label: 'ไม่ระบุ' }
}

const renderOption = (params, tooltip) => {
  const { titleLabel, key, value, options, disabled, onInputValueChange } =
    params;
  var keysbyindex = Object.keys(options);
  return (
    <div className="row mt-4">
      {/* title label  */}
      <div className="col-4">
        <h6>{titleLabel}</h6>
      </div>
      {/* input radio button */}
      <div className="col-8">
        <div className="row">
          <div className="col-6">
            <Radio
              value={options[keysbyindex[0]].value}
              className="mr-4"
              valueOption={value}
              name={key}
              disabled={disabled}
              onChange={() =>
                onInputValueChange(key, options[keysbyindex[0]].value)
              }
            />
            <label className="form-check-label mr-4">
              {options[keysbyindex[0]].label}
            </label>
          </div>
          <div className="col-6">
            <Radio
              value={options[keysbyindex[1]].value}
              className="mr-4"
              valueOption={value}
              name={key}
              disabled={disabled}
              onChange={() =>
                onInputValueChange(key, options[keysbyindex[1]].value)
              }
            />
            <label className="form-check-label mr-2">
              {options[keysbyindex[1]].label}
            </label>
            {tooltip &&
              <React.Fragment>
                <IconHint id="test1" fill="red" className="icon-hint" />
                <UncontrolledTooltip placement="top" target="test1">
                  {tooltip}
                </UncontrolledTooltip>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const renderInputText = (params) => {
  const {
    titleLabel,
    key,
    value,
    show,
    disabled,
    onInputValueChange,
    isError,
    errorMsg,
  } = params;

  return (
    <>
      {show && (
        <div className="row mt-4 mb-4">
          <div className="col-4">
            <h6> {titleLabel} </h6>
          </div>
          <div className="col-8">
            <input
              className={classNames("form-control", {
                "border-error": isError,
              })}
              type="number"
              placeholder={0}
              value={value}
              onChange={(event) => onInputValueChange(key, parseInt(event.target.value))}
              disabled={disabled}
              maxLength={10}
              name={key}
            />
            {isError && <span className="text-danger">{errorMsg}</span>}
          </div>
        </div>
      )}
    </>
  );
};


class ModalLimitPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
      statusLimit: false,
      type: typeOption.PER_ORDER.value,
      limit: limitOption.LIMIT.value,
      period: periodOption.DAY.value,
      limitQuantity: 1,
      periodQuantity: 1,
      sku: [],
      isPeriod: false
    };
  }

  componentDidUpdate = async (prevProps, state) => {
    const { product } = this.props

    if (!prevProps.product.modalLimitPurchase.isOpen && product.modalLimitPurchase.isOpen) {
      const productLimit = await api_product.getLimitProduct(product.modalLimitPurchase?.product?.id)
      let data = {}
      if (productLimit['limitPreviousOrder']) {
        data.isPeriod = true
        data.period = productLimit.limitPreviousOrder.unit
        data.periodQuantity = productLimit.limitPreviousOrder.value
      }
      if (productLimit['limitType']) {
        data.type = productLimit.limitType
      }
      if (productLimit['limit']) {
        data.limit = limitOption.LIMIT.value
        data.limitQuantity = productLimit.limit
      } else {
        data.limit = limitOption.UNLIMIT.value
        data.limitQuantity = 0
      }
      let sku = []
      for (let index = 0; index < product.modalLimitPurchase?.data?.length; index++) {
        const element = product.modalLimitPurchase.data[index];
        const prodLimit = productLimit.skus?.find(item => item.productSku?.id === element.id)
        sku.push({
          typeLimit: prodLimit?.limit ? 'limit' : 'unlimit',
          limitQuantity: prodLimit?.limit ? prodLimit?.limit : 0,
          productSku: {
            ...element,
          }
        })
        if (prodLimit?.limit || productLimit['limit']) {
          data.statusLimit = true
        }
      }
      data.sku = sku
      this.setState({
        sku,
        ...data,
        productId: product.modalLimitPurchase?.product?.id
      })
    }

    if (prevProps.product.modalLimitPurchase.isOpen && !product.modalLimitPurchase.isOpen) {
      this.setState({
        statusLimit: false,
        type: typeOption.PER_ORDER.value,
        limit: limitOption.LIMIT.value,
        period: periodOption.DAY.value,
        limitQuantity: 1,
        periodQuantity: 1,
        sku: [],
        errors: {}
      })
    }
  };

  toggleModal = () => {
    const isOpen = this.props.product.modalLimitPurchase.isOpen;
    this.props.dispatch(
      dispatchProduct({
        type: OPEN_MODAL_LIMIT_PURCHASE,
        payload: { isOpen: !isOpen },
      })
    );
  };

  // onSubmit = () => { };

  onLimitChange = () => {
    const { statusLimit } = this.state;
    this.setState({ statusLimit: !statusLimit });
  };

  onInputValueChange = (key, value) => {
    let { errors } = this.state
    if (errors && errors[key] !== undefined) {
      delete errors[key]
    }
    this.setState({ [key]: value });
  };

  getInputParam = () => {
    const { statusLimit, type, limit, period, limitQuantity, periodQuantity, errors, isPeriod } =
      this.state;

    const paramsType = {
      titleLabel: "รูปแบบ",
      key: "type",
      value: type,
      options: typeOption,
      disabled: statusLimit === true ? false : true,
      onInputValueChange: this.onInputValueChange,
    };
    const paramsLimit = {
      titleLabel: "จำกัดการซื้อสินค้า",
      key: "limit",
      value: limit,
      options: limitOption,
      disabled: statusLimit === true ? false : true,
      onInputValueChange: this.onInputValueChange,
    };
    const paramsTypePeriod = {
      titleLabel: "ระยะเวลาที่ลูกค้าถูกจำกัดการซื้อ",
      key: "isPeriod",
      value: isPeriod,
      options: typePeriodOption,
      disabled: statusLimit === true ? false : true,
      onInputValueChange: this.onInputValueChange,
    };
    const paramsPeriod = {
      titleLabel: "ระยะเวลาที่ลูกค้าถูกจำกัดการซื้อ",
      key: "period",
      value: period,
      options: periodOption,
      disabled: statusLimit === true ? false : true,
      onInputValueChange: this.onInputValueChange,
    };
    const paramsLimitQty = {
      titleLabel: "ระบุจำนวน",
      key: "limitQuantity",
      value: limitQuantity,
      show: limit === limitOption.LIMIT.value ? true : false,
      disabled: statusLimit === true ? false : true,
      onInputValueChange: this.onInputValueChange,
      isError: errors?.limitQuantity,
      errorMsg: errors?.limitQuantity,
    };
    const paramsPeriodQty = {
      titleLabel: "ระบุจำนวน",
      key: "periodQuantity",
      value: periodQuantity,
      show: true,
      onInputValueChange: this.onInputValueChange,
      disabled: statusLimit === true ? false : true,
      isError: errors?.periodQuantity,
      errorMsg: errors?.periodQuantity
    };

    return {
      paramsType,
      paramsLimit,
      paramsPeriod,
      paramsLimitQty,
      paramsPeriodQty,
      paramsTypePeriod
    };
  };

  handleChangeSKUlimit = (key, value, index, sku) => {
    let { errors } = this.state
    if (errors && errors['sku' + index] !== undefined) {
      delete errors['sku' + index]
    }

    let skus = this.state.sku
    let productSku = { ...skus[index] }
    productSku[key] = value
    skus[index] = productSku
    this.setState({ sku: skus })
  }

  validate = () => {
    const { statusLimit, limitQuantity, periodQuantity, sku, limit } = this.state
    let errors = {}
    // eslint-disable-next-line no-unused-vars
    let isError = false
    if (statusLimit) {
      if (!limitQuantity && (limit === limitOption.LIMIT.value)) {
        errors['limitQuantity'] = 'กรุณากรอกข้อมูล'
        isError = true
      }
      if (!periodQuantity) {
        errors['periodQuantity'] = 'กรุณากรอกข้อมูล'
        isError = true
      }
      for (let index = 0; index < sku.length; index++) {
        const element = sku[index];
        if (!element.limitQuantity && element.typeLimit === "limit") {
          errors[`sku${index}`] = 'กรุณากรอกข้อมูล'
          isError = true
        }
      }
    }
    this.setState({ errors })
    return isError
  }

  onSubmit = async () => {
    const isError = await this.validate()
    if (!isError) {
      await this.createLimitPurchase()
    }
  }

  createLimitPurchase = async () => {
    const { product } = this.props.product.modalLimitPurchase
    const { limit, limitQuantity, period, periodQuantity, statusLimit, type, sku } = this.state
    let skus = []
    for (let index = 0; index < sku.length; index++) {
      const element = sku[index];
      if (!statusLimit) {
        skus.push({
          skuId: element.productSku.id,
          limit: 0
        })
      } else {
        if (element.typeLimit === "limit" && statusLimit) {
          skus.push({
            skuId: element.productSku.id,
            limit: element.limitQuantity
          })
        } else if (element.typeLimit === "unlimit" && statusLimit) {
          skus.push({
            skuId: element.productSku.id,
            limit: 0
          })
        }
      }
    }
    let params = {
      limitType: type, //order||user
      productId: product.id,
      limit: statusLimit && limit === limitOption.LIMIT.value ? limitQuantity : 0,
      limitPreviousOrder: statusLimit && type === typeOption.PER_USER.value && (limit === limitOption.LIMIT.value || sku.find((item) => item.typeLimit === "limit")) ? { value: periodQuantity, unit: period } : null,
      skus: skus
    }

    try {
      const res = await api_product.createLimitPurchase(params)
      if (res) {
        this.props.dispatch(dispatchProduct({ type: OPEN_MODAL_LIMIT_PURCHASE, payload: { isOpen: false, data: null, product: null } }))
        this.props.dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการสำเร็จ!`, type: 'success' } }))
        this.props.getListProduct()
        // this.props.dispatch(dispatchProduct({ type: HANDLE_SEARCH_PRODUCT, payload: { search: this.props.search } }))
        // this.props.dispatch(dispatchProduct({ type: GET_DATA_PRODUCT_PAGE }))
      }
    } catch (error) {
      console.log(error)
      this.props.dispatch(dispatchNotification({ type: OPEN_TOAST, payload: { message: `ทำรายการไม่สำเร็จ!`, type: 'danger' } }))
    }

  }

  render() {
    const { isOpen } = this.props.product.modalLimitPurchase;
    const { statusLimit, sku, errors, type, limit } = this.state;
    const {
      paramsType,
      paramsLimit,
      paramsPeriod,
      paramsLimitQty,
      paramsPeriodQty,
    } = this.getInputParam();

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggleModal}
        backdrop="static"
        style={{ width: "60%", maxWidth: "60%" }}
      >
        <ModalBody>
          <Loading />
          <div className="close-btn-wrap">
            <img src={IconClose} onClick={this.toggleModal} alt="close" />
          </div>
          <h3>จำกัดการซื้อ</h3>
          <div className="container-main">
            <div className="section-container">
              <section className="section option">
                <ToggleInput
                  value={statusLimit}
                  onChange={this.onLimitChange}
                  name={"limit"}
                  labelOn={"จำกัด"}
                  labelOff={"ไม่จำกัด"}
                />
              </section>
              <section className="section detail">
                {renderOption(paramsType)}
                <hr></hr>
                {renderOption(paramsLimit)}
                {renderInputText(paramsLimitQty)}
                {type === typeOption.PER_USER.value && <hr></hr>}
                {/* {type === typeOption.PER_USER.value && (typeLimit === limitOption.LIMIT.value || sku.find((item) => item.typeLimit === "limit")) && renderOption(paramsTypePeriod, 'เมื่อลูกค้าซื้อครบจำนวน จะไม่สามารถซื้อสินค้าได้อีก')} */}
                {type === typeOption.PER_USER.value && (limit === limitOption.LIMIT.value || sku.find((item) => item.typeLimit === "limit")) && renderOption(paramsPeriod)}
                {type === typeOption.PER_USER.value && (limit === limitOption.LIMIT.value || sku.find((item) => item.typeLimit === "limit")) && renderInputText(paramsPeriodQty)}
              </section>

              <section className="section-container">
                {sku?.map((product, idx) => (
                  <LimitProductOption key={'sku' + idx}
                    productSKU={product}
                    image={product.productSku?.imagePath?.path}
                    sku={product.productSku.sku}
                    index={idx}
                    handleChangeSKUlimit={this.handleChangeSKUlimit}
                    statusLimit={statusLimit}
                    errors={errors}
                  />
                ))}
              </section>
              <section className="section submit-form">
                <div className="d-flex justify-content-center">
                  <button className="btn-cancel mr-4" onClick={this.toggleModal}>
                    ยกเลิก
                  </button>
                  <button
                    className={classNames("btn-submit")}
                    onClick={() => this.onSubmit()}
                  >
                    บันทึก
                  </button>
                </div>
              </section>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, null)(withRouter(ModalLimitPurchase));
