/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import  { Link } from 'react-router-dom';
import './index.scss';

import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Badge
} from 'reactstrap';

import HeaderMenu from './HeaderMenu'

import IconHamburger from '../../images/icons/hamburger.svg';
import LogoOutletCms from '../../images/icons/outlet-live-logo.svg';
import IconUserProfile from '../../images/icons/user-profile.svg';
import Cookies from 'js-cookie'


class Header extends Component {

    SidebarToggle = () => {
      document.getElementById('content').classList.toggle('hide-sidebar')
    }

    logout = () => {
        Cookies.remove('user')
        Cookies.remove('token')
        window.location = '/login'
    }
 
    render() {
       return  <section id="header">
                <ul className="wrap-menu">
                    <li> 
                        <Link to="/"> 
                            <img className="godang-logo-icon" src={LogoOutletCms} />
                        </Link>
                    </li>
                    <li>
                        <button  className="btn-hamburger" onClick={this.SidebarToggle}>
                            <img src={IconHamburger} />
                        </button>
                    </li>
                    <li>
                        <HeaderMenu />
                    </li>
                </ul>

                <ul className="wrap-menu"> 
                    <li><img src={IconUserProfile} /></li>
                    <li><span className="link-menu cursor-pointer" onClick={this.logout}> ออกจากระบบ </span></li>
                </ul>
               </section>
    }
}

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(Header)

