import React, { useState, useEffect } from 'react'
import {
  MediaCard,
  DropdownSort,
  InputSearch,
  ModalLoading,
} from '../../output'
import { LayoutGallery } from '../../layout'
import { connect } from 'react-redux'
import '../../../styles/gallery.scss'

import {
  dispatchGallery,
  GET_SHOP_GALLERY,
} from '../../../redux/actions/gallery'

const optionsDropdownSort = [
  {
    value: 'hasfiles',
    name: 'ร้านที่มีไฟล์',
  },
  {
    value: 'lastUpload',
    name: 'อัปโหลดล่าสุด',
  },
  {
    value: 'name',
    name: 'เรียงตามตัวอักษร',
  },
]

const ListShop = (props) => {
  const [isOpenModal, setisOpenModal] = useState(true)

  useEffect(() => {
    props.dispatch(dispatchGallery({ type: GET_SHOP_GALLERY }))
  }, [])


  useEffect(() => {
    if(props.shopData) {
      setisOpenModal(false)
    }
  }, [props.shopData])


 
    return (
      <div className='container-gallery'>
        <div className='header'>
          <h5>คลังภาพและวิดีโอ</h5>
          <div className='content-header'>
            <div style={{ width: '50%' }}>
              {/* <InputSearch
                handleSearch={(value) => console.log('')}
                placeholder='ค้นหาจากร้านค้า'
              /> */}
            </div>
            <div style={{ width: '30%' }}>
              <DropdownSort
                label='เรียงจาก'
                defaultValue='hasfiles'
                options={optionsDropdownSort}
                handleSort={(value) => console.log('')}
              />
            </div>
          </div>
        </div>
        <LayoutGallery>
          {props.shopData?.map((shop, index) => (
            <MediaCard
              key={shop.shopId}
              statusContent='profile-shop'
              cardType={
                shop.thumbnail?.split('/')[0] === 'vdo' ? 'video' : 'image'
              }
              data={shop}
              index={index}
              onClickMediaCard={({ shopDetail }) =>
                props.onClickShop({ shopDetail, keyPage: 'album' })
              }
            />
          ))}
        </LayoutGallery>
        {isOpenModal
         && <ModalLoading isOpenModal={isOpenModal} />}
      </div>
    )
  
}

const mapStateToProps = (state) => ({
  shopData: state.gallery.shopData,
})

export default connect(mapStateToProps, null)(ListShop)
