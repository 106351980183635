import apiClass from '../helper/api'
import host from '../config/host'


class chatProvider extends apiClass {
  constructor() {
    super({
      baseUrl: host.chat,
      restPath: "/auth/loopback",
    })
  }

  auth = async () => {
    try{
      return await this.get({})
    }catch (e){
      throw new Error(e.message)
    }
  }
}

export default new chatProvider()
