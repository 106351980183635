import React, {Component} from 'react';
import Dropdown from 'react-dropdown';
import './index.scss';


// https://www.npmjs.com/package/react-dropdown

const RadioComponent = (props) => {
    const {onChange,options,placeholder,selectedIndex,className,value,disabled} = props;
    const defaultOption = options[selectedIndex];
   
    return (
        <Dropdown 
            options={options}
            disabled={disabled} 
            onChange={onChange} 
            value={selectedIndex ? defaultOption : value} 
            placeholder={placeholder}
            className={className}
            />
    )

};

export default RadioComponent;

