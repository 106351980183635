import React, { Component } from 'react';
import {ReactComponent as IconShop} from '../../images/icons/menu/menu-shop.svg';
import {ReactComponent as IconBag} from '../../images/icons/menu/menu-bag.svg';
import {ReactComponent as IconDashBoard} from '../../images/icons/menu/menu-dashboard.svg';
import {ReactComponent as IconClipBoard} from '../../images/icons/menu/menu-clip-board.svg';
import {ReactComponent as IconProfile} from '../../images/icons/menu/menu-profile.svg';
import {ReactComponent as IconPromotion} from '../../images/icons/menu/menu-promotion.svg';
import {ReactComponent as IconDashboard} from '../../images/icons/menu/dashboard.svg'
import {ReactComponent as IconWithdraw} from 'images/icons/menu/withdraw.svg'
import {ReactComponent as IconRibbon} from 'images/icons/ribbon.svg'

// import { PERMISSION_MANAGE_SHOP } from '../../config/permission';
import {ADMIN, OWNER, SUPER_ADMIN} from './Role';

import {
    USER,
    MANAGE,
    CHAT,
    ORDER,
    REPORT,
    SETTING
} from '../../config/groupTabMenu';

export default {
  items: [
    // Group User
    {
      name: 'ผู้ใช้งาน',
      url: '/user',
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [USER],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    {
      name: 'เพิ่มผู้ใช้งาน',
      url: '/user/adduser',
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [USER],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },

    // Group Manage
    // {
    //     name: 'แดชบอร์ด',
    //     url: '/manage/dashboard',
    //     icon: <React.Fragment><IconDashBoard/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    {
      name: 'แดชบอร์ด',
      url: '/manage/dashboard',
      icon: (
        <div>
          <IconDashboard />
        </div>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE,CHAT],
      show: true,
      role: [],
    },

    {
      name: 'ร้านค้าทั้งหมด',
      nameEdit: 'ร้านค้า',
      url: '/manage/shop',
      subMenu: [
        {
          name: 'รายละเอียดร้านค้า',
          url: '/manage/shop/shopdetail',
          editHeadMenu: true,
          subMenu: [
            {
              name: 'สร้างไลฟ์',
              url: '/manage/shop/shopdetail/createlive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'แก้ไขไลฟ์',
              url: '/manage/shop/shopdetail/editlive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'แก้ไข VOD',
              url: '/manage/shop/shopdetail/editvod',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'อัพโหลด',
              url: '/manage/shop/shopdetail/upload',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'จัดการ LIVE',
              url: '/manage/shop/shopdetail/managelive',
              icon: (
                <React.Fragment>
                  <IconBag />
                </React.Fragment>
              ),
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
            {
              name: 'เพิ่มราคาค่าจัดส่ง',
              url: '/manage/shop/shopdetail/addlogisticprice',
              // permission: PERMISSION_MANAGE_SHOP,
              group: [MANAGE,CHAT],
              show: true,
              role: [SUPER_ADMIN, ADMIN],
            },
          ],
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE,CHAT],
          show: true,
          role: [SUPER_ADMIN, ADMIN],
        },
        {
          name: 'สร้างร้านค้า',
          url: '/manage/shop/addshop',
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE,CHAT],
          show: false,
        },
      ],
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
    },
    {
      name: 'สินค้า',
      url: '/manage/product',
      subMenu: [
        {
          name: 'เพิ่มสินค้า',
          url: '/manage/product/addproduct',
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE,CHAT],
          show: false,
        },
      ],
      icon: (
        <React.Fragment>
          <IconBag />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    {
      name: 'รายการสั่งซื้อ',
      url: '/manage/order',
      icon: (
        <React.Fragment>
          <IconClipBoard />
        </React.Fragment>
      ),
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN, OWNER],
    },
    {
      name: 'รายการที่ถอนได้',
      url: '/manage/withdraw',
      icon: <IconWithdraw />,
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE,CHAT],
      show: true,
      role: [OWNER,SUPER_ADMIN],
    },
    {
      name: 'เพลย์ลิสต์',
      nameEdit: 'เพลย์ลิสต์',
      url: '/manage/playlists',
      subMenu: [
        {
          name: '',
          url: '/manage/playlists/items',
          editHeadMenu: true,
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          group: [MANAGE,CHAT],
          show: true,
          role: [SUPER_ADMIN, ADMIN],
        },
      ],
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
    },
    {
      name: 'โปรโมชั่นโค้ด',
      nameEdit: 'โปรโมชั่นโค้ด',
      url: '/manage/promocode',
      subMenu: [
        {
          name: 'เพิ่มโปรโมชั่นโค้ด',
          url: '/manage/promocode/addpromocode',
          icon: (
            <React.Fragment>
              <IconPromotion />
            </React.Fragment>
          ),
          // permission: PERMISSION_MANAGE_SHOP,
          group: [MANAGE,CHAT],
          show: false,
        },
        { url: '/manage/promocode/comingsoon' },
        { url: '/manage/promocode/processing' },
        { url: '/manage/promocode/expired' },
      ],
      icon: (
        <React.Fragment>
          <IconPromotion />
        </React.Fragment>
      ),
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
    },
    {
      name: 'ตัวจัดการหน้าเว็บ',
      nameEdit: 'Playlist Section',
      url: '/manage/playlist-front-page',
      subMenu: [
        {
          name: '',
          url: '/manage/playlist-front-page/page',
          editHeadMenu: true,
          icon: (
            <React.Fragment>
              <IconBag />
            </React.Fragment>
          ),
          group: [MANAGE,CHAT],
          show: true,
          role: [SUPER_ADMIN, ADMIN],
        },
      ],
      icon: (
        <React.Fragment>
          <IconShop />
        </React.Fragment>
      ),
      group: [MANAGE,CHAT],
      show: true,
      role: [SUPER_ADMIN, ADMIN],
    },
    {
      name: 'ตกแต่งรูปสินค้า',
      url: '/manage/ribbon',
      // icon: <IconWithdraw />,
      // permission: PERMISSION_MANAGE_SHOP,
      group: [MANAGE],
      show: true,
      role: [ADMIN,SUPER_ADMIN,OWNER],
      icon: <IconRibbon />
    },

    // {
    //     name: 'ถ่ายทอดสด',
    //     url: '/manage/managelive',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    // {
    //     name: 'สร้างไลฟ์',
    //     url: '/manage/create-live',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: [MANAGE,CHAT],
    //     show: true
    // },
    // {
    //     name: 'เพิ่มสินค้า',
    //     url: '/manage/add-product',
    //     icon: <React.Fragment><IconBag/></React.Fragment>,
    //     permission: PERMISSION_MANAGE_SHOP,
    //     group: ORDER
    // },

    /// Group Order
  ],
}