import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const DropdownSort = (props) => {
  const { label, options, defaultValue, handleSort } = props

  const [isOpen, setIsOpen] = useState(false)
  const [valueDropdown, setValueDropdown] = useState({ value: '', name: '' })

  useEffect(() => {
    if (defaultValue) {
      const tmpValue = options.find((option) => defaultValue === option.value)
      setValueDropdown(tmpValue)
    }
  }, [defaultValue, options])

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setIsOpen((prevState) => {
      return !prevState
    })
  }

  const onSelectDropdown = (data) => {
    setValueDropdown(data)
    handleSort(data.value)
    setIsOpen(false)
  }

  return (
    <div className='dropdown-sort'>
      <div className='label'>{label}</div>
      <div className='container-dropdown'>
        <div className='dropdown-box' onClick={toggleDropdown}>
          <span className='text-name'>{valueDropdown.name}</span>
          <div className='icon-box' onClick={toggleDropdown}>
            <FontAwesomeIcon
              icon={isOpen ? faAngleUp : faAngleDown}
              className='icon-angle'
            />
          </div>
        </div>
        {isOpen ? (
          <div className='container-options'>
            {options.map((item) => (
              <div
                key={item.value}
                className={classnames('option-box', {
                  active: valueDropdown.value === item.value,
                })}
                onClick={() => onSelectDropdown(item)}
              >
                <div className='text-option'>{item.name}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

DropdownSort.defaultProps = {
  label: 'เรียงจาก',
  handleSort: () => {},
  defaultValue: '',
  options: [
    {
      value: 'name',
      name: 'เรียงจากชื่อ',
    },
    {
      value: 'counter',
      name: 'ใช้งาน',
    },
    {
      value: 'lastused',
      name: 'อัปโหลดล่าสุด',
    },
  ],
}

export default DropdownSort
