import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { detailButton, tabsBrowse } from 'components/Gallery/constant'
import { ModalUploadMedia } from 'components/Gallery'
import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import './styles.scss'

const ButtonUploadVideo = (props) => {
  const {
    width,
    height,
    type,
    shopDetail,
    disabled,
    fieldUpload,
    limitImageUpload,
    onBrowse,
    isAvatar,
    avatar,
    children,
    videoCollection,
    onClickRemoveMedia,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  

  const onClickBrowse = () => {
    if (!disabled) {
      onBrowse && onBrowse()
      setIsOpen(true)
    }
  }

  const onChangevideoClick = () => {
    if (!disabled) {
      onBrowse && onBrowse()
      setIsOpen(true)
      onClickRemoveMedia()
    }
  }

  return (
    <>
      {videoCollection?.length > 0 ? (
        <div className='row mt-4 text-right w-100'>
          <div className='col'>
            <button className='btn-cancel mr-4' onClick={onChangevideoClick}>
              เปลี่ยนวิดีโอ
            </button>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {isAvatar ? (
            <div className='upload-container cursor-pointer'>
              {avatar}
              <div className='icon-container' onClick={onClickBrowse}>
                <IconCamera />
              </div>
            </div>
          ) : (
            <div
              className='button-upload-media-box cursor-pointer'
              style={{ width, height }}
              onClick={onClickBrowse}
            >
              <div
                className={classnames('button-upload-media', {
                  disabled: disabled,
                })}
              >
                <img className='icon' src={detailButton[type].icon} alt='' />
                <div className='title'>{detailButton[type].title}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      <ModalUploadMedia
        title={detailButton[type].title}
        labelResetMedia={detailButton[type].reset}
        isOpenModal={isOpen}
        closeModal={() => setIsOpen(false)}
        dataTabs={tabsBrowse}
        isShowTabs
        cardType={type}
        shopDetail={shopDetail}
        fieldUpload={fieldUpload}
        limitImageUpload={limitImageUpload}
      />
    </>
  )
}

ButtonUploadVideo.defaultProps = {
  onClickButton: () => {},
  onBrowse: null,
  width: '8em',
  height: '8em',
  type: 'image',
  shopDetail: {},
  disabled: false,
  isShowButton: true,
  fieldUpload: null,
  limitImageUpload: 4,
  isAvatar: false,
  icon: '',
  avatar: '',
}

export default ButtonUploadVideo
