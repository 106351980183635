import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modalcreatelive.scss'
import { Select } from 'antd';

import lip from '../../images/icons/lip.svg'
import arrowUp from '../../images/icons/arrow-up.svg'
import arrowDown from '../../images/icons/arrow-down.svg'
import close from '../../images/icons/close.svg'
import closeIcon from '../../images/icons/close-image.svg'

import { dispatchModalAlert, OPEN_MODAL_ALERT } from '../../redux/actions/modalAlert';
import { dispatchLive, OPEN_MODAL_CREATE_LIVE } from '../../redux/actions/live';
import { connect } from 'react-redux'
import ModalAlert from '../modalAlert/ModalAlertSuccess';
import Checkbox from '../Checkbox'

import host from '../../config/host';
import SelectInput from 'components/SelectInput';
import { dispatchShop, GET_LIST_SHOP } from 'redux/actions/shop';
import { dispatchProduct, GET_ALL_PRODUCTS_BY_SHOP } from 'redux/actions/product';
import { isEqual } from 'lodash';

class ModalCreateLive extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            select: false,
            productSkuSelected: [],
            productSelectTemp: {},
            productByShop: [],
            selectAllProduct: false,
            filterKey: null,
            selectedKey: null,
            shop: [],
            shopSelected: 0
        }
    }

    componentDidMount() {
        this.props.dispatch(dispatchShop({ type: GET_LIST_SHOP }))
        const payload = this.props.currentShop.id === 14 ? {} : {id: this.props.currentShop.id}
        this.props.dispatch(dispatchProduct({ type: GET_ALL_PRODUCTS_BY_SHOP, payload }))
    }

    getSelectedProduct = (productList, productSkuSelected) => {
       const currentProductSkuSelectedList = this.filterterSelectedProductSku(productSkuSelected);
       return productList.map(product => {
        product.productSkus = product.productSkus.map(sku => {
                                    if (currentProductSkuSelectedList.findIndex(item => item === sku.id) > -1) {
                                        sku.select = true
                                    } else {
                                        sku.select = false
                                    }
                                    return sku
                              })
            return product
       });
    }

    getProductSkuSelected = (_productList, skuId) => {
        let productSelectTemp = {}
        const productList =  _productList.map(product => {
            product.productSkus = product.productSkus.map(sku => {

                                        if(sku.id === skuId)  {
                                            sku.select = !sku.select;

                                                let peoductSelect = JSON.parse(JSON.stringify(product));
                                                peoductSelect.productSkus = [sku];
                                                this.setState({productSelectTemp: peoductSelect});
                                                productSelectTemp = peoductSelect;

                                        }
                                        return sku;
                                  })
                return product
           });

           return {productSelectTemp, productList}
    }

    initialProducts = (_products) => {
        let products = JSON.parse(JSON.stringify(_products));
        return products.map((product) => {
            product.productSkus = product.productSkus.map((productSku) => {
              // NOTE - insert special property
              //   productSku.select = false
              const extraPriceTemplate = {
                discountPrice: null,
                discountPercent: null,
                buyLimit: null,
                privileges: null,
              }
              productSku.extraPrice = extraPriceTemplate;
              productSku.errors = {};
              return productSku;
            })
            return product;
          });

          return products;
    }

    /* NOTE - get product sku id selected */
    filterterSelectedProductSku = (productList) => {
        return productList.reduce((total,product) => {
            const skuSelected = product.productSkus.filter(sku => sku.select === true).map(product => product.id);
            return [...total, ...skuSelected]
        },[])
    }

    componentWillReceiveProps = (nextProps) => {

        if (this.props.productsByShop !== nextProps.productsByShop) {
            if( nextProps.productsByShop &&  nextProps.productsByShop.length > 0) {
                let products = [...nextProps.productsByShop].map(element => Object.assign({}, element));
                products = this.initialProducts(products);
                let { productSkuSelected } = JSON.parse(JSON.stringify(this.state));
                    products = this.getSelectedProduct(products,productSkuSelected);
                                // NOTE - filter only show on Shelf or live
                                const _products = products.map(item => {
                                    const _item = item
                                    _item.productSkus = item.productSkus.filter(sku => sku.showOn.indexOf('live') > -1 || sku.showOn.indexOf('shelf') > -1)
                                    return _item
                                });
                this.setState({productByShop: _products })
            }
        }

        if (this.props.isOpen !== nextProps.isOpen) {
            
            /* NOTE - assign shop list */
            if (this.state.shop != nextProps.shop) {
                let listShop = [{ label: 'ร้านทั้งหมด', value: '' }]
                nextProps.shop.map((shop, idx) => {
                    listShop.push({ label: shop.name, value: shop })
                })
                this.setState({ shop: listShop })
            }
        }

        if(this.props.products !== nextProps.products) {

            if (this.state.productByShop && this.state.productByShop.length > 0) {
                let  productByShop = JSON.parse(JSON.stringify(this.state.productByShop));
                let products = this.getSelectedProduct(productByShop, nextProps.products);
                                
                // NOTE - filter only show on Shelf or live
                const _products = products.map(item => {
                    const _item = item
                    _item.productSkus = item.productSkus.filter(sku => sku.showOn.indexOf('live') > -1 || sku.showOn.indexOf('shelf') > -1)
                    return _item
                });
                this.setState({productByShop: _products, productSkuSelected: nextProps.products })
            } else {
                this.setState({productSkuSelected: nextProps.products })
            }

        }
    }

    toggle = () => this.setState({ modal: !this.state.modal })
    setSelect = (status) => this.setState({ select: status })

    onProductFilterChange = (value) => {

        // if (document.getElementsByClassName('ant-select-selection-placeholder')[0])
        //     document.getElementsByClassName('ant-select-selection-placeholder')[0].className = 'ant-select-selection-item';

        // if (document.getElementsByClassName('ant-select-selection-item')[0])
        //     document.getElementsByClassName('ant-select-selection-item')[0].textContent = value;


        const filterKey = value;
        this.setState({ filterKey });
        this.setSelect(false);
    }

    onLeaveSelect = () => {
        this.setSelect(false)
    }

    onClickSelect = () => {
        const x = document.getElementById('select')
        this.setSelect(!this.state.select)
        // if(x.ariaExpanded == true){
        //     setSelect(false)
        // }
    }

    onSelectAllProduct = () => {

        let { productByShop, productSkuSelected, selectAllProduct } = JSON.parse(JSON.stringify(this.state));
        selectAllProduct = !selectAllProduct;
        if(selectAllProduct === true) {
            productByShop   = productByShop.map(product => {
                    product.productSkus = product.productSkus.map(productSku => {
                        productSku.select = true;
                        return productSku;
                    });
                    return product;
            });
            productSkuSelected = [...productSkuSelected, ...productByShop]
        } else {
            productByShop   = productByShop.map(product => {
                product.productSkus = product.productSkus.map(productSku => {
                    productSku.select = false;
                    return productSku;
                });
                return product;
            });
            productByShop.forEach(product => {
                productSkuSelected = productSkuSelected.filter(({id: productId}) => productId !== product.id)
            })
        }

        this.setState({ productByShop, productSkuSelected, selectAllProduct: selectAllProduct })
        
    }

    onProductSelectChange = (event, skuId) => {

        let { productByShop, productSkuSelected: _productSkuSelected } = JSON.parse(JSON.stringify(this.state));

        /* NOTE - productSelectTemp = product sku that user select 
                  productList = list of product that contain only selected sku */
        const {productSelectTemp, productList} = this.getProductSkuSelected(productByShop, skuId);
        console.log('[productSelectTemp]:',productSelectTemp);
        this.setState({ productByShop: productList });

        let productSkuSelected = [];
        if(!(_productSkuSelected.length > 0)) {
                  /* NOTE - first time add product */
                  productSkuSelected = [productSelectTemp];
        } else {
                  let insertSameProduct = false; 
                  productSkuSelected = _productSkuSelected.reduce((total, product) => {

                    /* NOTE - insert new sku same product */
                    if(product.id === productSelectTemp.id) {
                        insertSameProduct = true;

                        if(productSelectTemp.productSkus[0].select === true) { 
                            /* NOTE - insert new sku same product */
                            product.productSkus = [...product.productSkus, ...productSelectTemp.productSkus]
                        } else {
                            /* NOTE - remove sku same product */
                            product.productSkus = product.productSkus.filter(sku => sku.id !== productSelectTemp.productSkus[0].id)
                        }
                    }

                    if(product.productSkus.length > 0) 
                        /* NOTE - product that insert new sku */
                        return [...total, product]
                    else
                        return total;

                    }, []);

                    /* NOTE - insert new sku new product */
                    if(!insertSameProduct) 
                       productSkuSelected = [...productSkuSelected, productSelectTemp];
                  
        }

        this.setState({productSkuSelected});
        event.preventDefault();
     
    }

    onSubmitProductSelected = () => {
        const { onSubmitProductSelected } = this.props;
        const { productSkuSelected } = this.state;

        this.props.dispatch(dispatchLive({ type: OPEN_MODAL_CREATE_LIVE, payload: false }))
        onSubmitProductSelected(productSkuSelected);
    }



    onFocus = () => {

    }
    onBlur = () => {

    }
    onSearch = () => {

    }

    clearTextField = () => {
        // if (document.getElementsByClassName('ant-select-selection-item')[0])
        //     document.getElementsByClassName('ant-select-selection-item')[0].className = 'ant-select-selection-placeholder';

        // if (document.getElementsByClassName('ant-select-selection-placeholder')[0])
        //     document.getElementsByClassName('ant-select-selection-placeholder')[0].textContent = "ค้นหาสินค้า";

        this.setState({ selectedKey: null, filterKey: '' })
    }

    selectShopFilter = value => {
        const shopSelected = this.state.shop[value].value
        const shopIdx = this.state.shop.findIndex((shop) => isEqual(shop.value,shopSelected))
        this.setState({
            shopSelected: shopIdx
        })
        this.props.dispatch(
            dispatchProduct({ type: GET_ALL_PRODUCTS_BY_SHOP, payload: { id: shopSelected.id } }),
        )
    }

    render() {
        const { buttonLabel, className, isOpen, isClose, shop, currentShop } = this.props;
        const { Option } = Select;
        const cardItems = 15;
        const { img } = host;
        const { productByShop, productSkuSelected, selectAllProduct, filterKey, selectedKey } = this.state;
        const closeBtn = <img src={close} alt="close" onClick={isClose} className="cursor-pointer" />
        return (
            <div id="modalCreateLive">
                {/* <Button color="danger" onClick={toggle}>click</Button> */}
                <Modal isOpen={isOpen} toggle={isClose} className={className} modalClassName="modal-dialog-item">
                    <ModalHeader toggle={isClose} close={closeBtn}><h5>เพิ่มสินค้าที่จะขายในไลฟ์</h5></ModalHeader>
                    <div className="search-item">
                        <div className="d-flex justify-content-between flex-wrap" >


                        {/* shop 14 name outletLive */}
                            {currentShop.id === 14 &&
                                <div className="w-50">
                                    <SelectInput
                                        options={this.state.shop}
                                        onChange={this.selectShopFilter}
                                        iconClearSearch={false}
                                        value={this.state.shopSelected}
                                        placeholder={'ค้นหาร้านค้า'}
                                    />
                                </div>
                            }


                            <div className="position-relative">
                                <img src={this.state.select ? arrowUp : arrowDown} alt="arrowUp" className="arrow-select cursor-pointer" />
                                {filterKey !== '' && filterKey !== null &&
                                    <img src={closeIcon} alt="closeIcon" className="clear-key-filter cursor-pointer" onClick={this.clearTextField} />
                                }
                                <Select
                                    showSearch
                                    showArrow={false}
                                    style={{ width: '30em' }}
                                    placeholder="ค้นหาสินค้า"
                                    optionFilterProp="children"
                                    onChange={this.onProductFilterChange}
                                    onClick={this.onClickSelect}
                                    // onFocus={this.onFocus}
                                    // onBlur={this.onLeaveSelect}
                                    // onSearch={this.onSearch}
                                    value={filterKey}
                                    id="productSearch" 
                                    filterOption={(input, option) => 
                                        // option.value.indexOf(input) >= 0
                                        // option.value.toLowerCase().substr(0,input.length).indexOf(input.toLowerCase()) >= 0
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        productByShop.map((product, productIndex) =>
                                            <Option value={product.name} key={productIndex}> {product.name} </Option>
                                        )
                                    }

                                </Select>

                            </div>
                            <div>
                            </div>

                            <div className="align-self-center font-primary font-weight-light font-size-14px mt-2">
                                <div className="select-all-item">
                                    <Checkbox
                                        title={'เลือกทั้งหมด'}
                                        checked={selectAllProduct}
                                        value="web"
                                        onChange={this.onSelectAllProduct}
                                        name="web"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalBody className="scrollbar scrollbar-warning">

                        <div className="card-items d-flex flex-row flex-wrap">

                            {/* ANCHOR - render product */}
                            {productByShop.map((product, productIndex) => {
                                const { name, nameEn, productSkus } = product;
                                const fileter = filterKey !== '' && filterKey !== null ? name.indexOf(filterKey) >= 0 : true;
                                return (<React.Fragment>
                                    { fileter && productSkus.map((productSku, productSkuIndex) => {
                                            // console.log(`productSku`, productSku)
                                        const { sku, priceNet, productDiscount, imagePath, id } = productSku;
                                        const { discountValue, priceDiscount } = productDiscount.length > 0 ? productDiscount[0] : { discountValue: 0, priceDiscount: 0 };
                                        const productPrice = priceNet - priceDiscount;
                                        return (
                                            <React.Fragment>
                                                <div className="card" style={{ width: '11rem' }} onClick={(event) => this.onProductSelectChange(event,id)}>
                                                    <div className="img-card-container">
                                                        <img className="card-img-top" src={`${img}${imagePath?.path}`} />
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="price-container">
                                                            <li>
                                                                <span>{nameEn}</span>
                                                                <p className="card-text">{sku}</p>
                                                            </li>
                                                            <li>
                                                                <div className="d-flex justify-content-between product-price">
                                                                    <span className="danger-label">฿ {productPrice}</span> <br />
                                                                    <Checkbox
                                                                        title={''}
                                                                        checked={productSku.select ? productSku.select : false}
                                                                        value="web"
                                                                        onChange={(event) => this.onProductSelectChange(event,id)}
                                                                        name="web"
                                                                    />
                                                                </div>
                                                                {
                                                                    discountValue > 0 && <span className="danger-label">โปรโมชั่น ลด {discountValue}%</span>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </React.Fragment>)
                            })}
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn-submit" onClick={this.onSubmitProductSelected}>เลือกสินค้า</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    shop: state.shop.listShopName,
    currentShop: state.shop.currentShop,
    productsByShop : state.product.productsByShop.product,
})

export default connect(mapStateToProps)(ModalCreateLive)