import React, { useEffect, useState, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactCrop from 'react-image-crop'
import { LayoutGallery } from 'components/layout'
import { MediaCard } from 'components/output'

import iconupload from 'images/icons/iconupload.svg'
import './styles.scss'
import { Checkbox } from 'antd'

const BrowseImage = (props) => {
  const { limitImageUpload, listFile, setListFile, mediaUploadEvent, validateWidthHeight } = props
  const [listImage, setListImage] = useState([])

  // const [fileImage, setFileImage] = useState({
  //   fileName: '',
  //   base64: '',
  // })

  // const [isOpenCropImage, setIsOpenCropImage] = useState(false)

  useEffect(() => {
    setListFile([])
  }, [])

  const imageInputRef = React.useRef()

  const validateDimesionWidthHeight = (e,file,reader) => {
    let image = new Image()
    let validate = false
    image.src = e.target.result
    image.onload = function (e) {
      let height = this.height
      let width = this.width
      if (validateWidthHeight.type == "lower") {
        if (validateWidthHeight.height < height || validateWidthHeight.width < width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือต่ำกว่า`)
          onRemoveImage()
        } else {
          validate = true
        }
      } else if (validateWidthHeight.type == "heigher") {
        if (validateWidthHeight.height > height || validateWidthHeight.width > width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} หรือมากกว่า`)
          onRemoveImage()
        } else { validate = true }
      } else if (validateWidthHeight.type == "equal") {
        if (height != validateWidthHeight.height || width != validateWidthHeight.width) {
          alert(`ขนาดรูปภาพต้องเป็น ${validateWidthHeight.width}x${validateWidthHeight.height} เท่านั้น`)
          onRemoveImage()
        } else { validate = true }
      } else {
        // console.log('error')
      }
      if (validate) {
        setListImage([
          ...listImage,
          { name: file.name, preview: reader.result },
        ])
      } else {
        onRemoveImage()
      }
    }
  }

  const onClickBrowse = (e) => {
    const checkType = ['image/jpeg', 'image/png', 'image/gif']

    if (e.target.files[0] && checkType.includes(e.target.files[0].type)) {
      const reader = new FileReader()
      const file = e.target.files[0]

      reader.onloadend = (e) => {
        // setIsOpenCropImage(true)
        if (validateWidthHeight) {
            validateDimesionWidthHeight(e,file,reader)
        } else {
          setListImage([
            ...listImage,
            { name: file.name, preview: reader.result },
          ])
        }
      }

      reader.readAsDataURL(file)
      setListFile([...listFile, file])

    } else {
      alert('โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง')
    }
  }

  // const onClickBrowse = (e) => {
  //   const checkType = ['image/jpeg', 'image/png', 'image/gif']

  //   if (e.target.files[0] && checkType.includes(e.target.files[0].type)) {
  //     const reader = new FileReader()
  //     const file = e.target.files[0]

  //     reader.onloadend = () => {
  //       // setIsOpenCropImage(true)
  //       setListImage([
  //         ...listImage,
  //         { name: file.name, preview: reader.result },
  //       ])

  //     }

  //     reader.readAsDataURL(file)
  //     setListFile([...listFile, file])

  //   } else {
  //     alert('โปรดเลือกไฟล์ที่จะอัพโหลดให้ถูกต้อง')
  //   }
  // }

  const onRemoveImage = async (idx) => {
    const currentImg = [...listImage]
    const currentFile = [...listFile]

    currentImg.splice(idx, 1)
    currentFile.splice(idx, 1)

    setListFile(currentFile)
    setListImage(currentImg)

    if (imageInputRef.current) {
      imageInputRef.current.value = '' ///////// Clear value ที่ค้างอยู่ทำให้ Upload ค่าเดินไม่ได้
    }
  }

  // const onClickCropImage = (imageBlob) => {
  //   var reader = new FileReader()

  //   reader.readAsDataURL(imageBlob)

  //   reader.onloadend = () => {
  //     var base64data = reader.result

  //     const FileTrans = new File([imageBlob], imageBlob.name, {
  //       type: imageBlob.type,
  //     })

  //     setListFile([...listFile, FileTrans])

  //     setListImage([
  //       ...listImage,
  //       { name: FileTrans.name, preview: base64data },
  //     ])
  //   }

  //   setIsOpenCropImage(false)
  // }

  // const onClickCloseCrop = () => {
  //   setIsOpenCropImage(false)
  //   if (imageInputRef.current) {
  //     imageInputRef.current.value = '' ///////// Clear value ที่ค้างอยู่ทำให้ Upload ค่าเดินไม่ได้
  //   }
  // }

  return (
    <div className='browse-img'>
      <LayoutGallery>
        {listImage.length < limitImageUpload ? (
          <div className='btn-upload-img'>
            <input
              type='file'
              ref={imageInputRef}
              onChange={onClickBrowse}
              className='input-file'
              accept='image/x-png, image/gif, image/jpeg'
            />
            <div className='btn-upload-box'>
              <img className='icon-upload' alt='' src={iconupload} />
              <span className='txt-upload'>อัพโหลดรูปภาพ</span>
            </div>
          </div>
        ) : null}
        {listImage.map((image, index) => (
          <MediaCard
            statusAction='close'
            data={image}
            index={index}
            preview
            key={image.preview + index}
            onClickRemoveImage={() => onRemoveImage(index)}
            mediaSelected={mediaUploadEvent}
          />
        ))}
      </LayoutGallery>
      {/* <CropImage
        isOpen={isOpenCropImage}
        fileImage={fileImage}
        onClickClose={onClickCloseCrop}
        onClickCrop={onClickCropImage}
      /> */}
    </div>
  )
}

BrowseImage.defaultProps = {
  listFile: [],
  setListFile: () => { },
}

// Modal Crop Image
// const CropImage = (props) => {
//   const { onClickClose, isOpen, onClickCrop, fileImage, aspect } = props

//   let imageRef = useRef(null)

//   const [max, setMax] = useState({ width: 168, height: 168 })
//   const [min, setMin] = useState({ width: 168, height: 168 })

//   const [imageCrop, setImageCrop] = useState({
//     x: 0,
//     y: 0,
//     unit: 'px',
//     width: 168,
//     height: 168,
//     aspect: 1,
//   })

//   const [croppedImageUrl, setCroppedImageUrl] = useState(null)
//   const [imageLoaded, setImageLoaded] = useState(null)
//   const [cropComplete, setCropComplete] = useState(null)

//   useEffect(() => {
//     if (imageLoaded) {
//       const tmp =
//         imageLoaded.width < 640 || imageLoaded.height < 640
//           ? imageLoaded.width > imageLoaded.height
//             ? imageLoaded.height
//             : imageLoaded.width
//           : 640

//       setMax({
//         width: tmp,
//         height: tmp,
//       })
//     }
//   }, [imageLoaded])

//   const onImageLoaded = (image) => {
//     setImageLoaded(image)
//   }

//   const onCropComplete = (crop) => {
//     setCropComplete(crop)
//   }

//   useEffect(() => {
//     onCropComplete(imageCrop)
//   }, [max])

//   useEffect(() => {
//     if (cropComplete) {
//       makeClientCrop(imageLoaded, cropComplete)
//     }
//   }, [cropComplete])

//   const onCropChange = (crop) => {
//     setImageCrop(crop)
//   }

//   const makeClientCrop = async (image, crop) => {
//     if (image && crop.width && crop.height) {
//       crop.x = crop.x ? crop.x : 0
//       crop.y = crop.y ? crop.y : 0

//       const tmpCroppedImageUrl = await getCroppedImg(imageLoaded, crop)

//       if (tmpCroppedImageUrl) {
//         setCroppedImageUrl(tmpCroppedImageUrl)
//       }
//     }
//   }

//   const getCroppedImg = (image, crop) => {


//     var canvas = document.createElement('canvas')

//     var scaleX = image.naturalWidth / image.width
//     var scaleY = image.naturalHeight / image.height

//     canvas.width = crop.width
//     canvas.height = crop.height

//     let ctx = canvas.getContext('2d')
//     // ctx.imageSmoothingQuality = "high"

//     ctx.drawImage(
//       image,
//       crop.x * scaleX,
//       crop.y * scaleY,
//       crop.width * scaleX,
//       crop.height * scaleY,
//       0,
//       0,
//       crop.width,
//       crop.height,
//     )

//     return new Promise((resolve, reject) => {
//       canvas.toBlob(
//         (blob) => {
//           blob.name = fileImage.fileName
//           resolve(blob)
//         },
//         'image/jpeg',
//         1,
//       )
//     })
//   }

//   const onCropCover = (e) => {
//     if (e.target.checked) {
//       const tmp = {
//         width: 970,
//         height: 326,
//       }

//       setMax(tmp)
//       setMin(tmp)

//       setImageCrop({
//         x: 0,
//         y: 0,
//         unit: 'px',
//         aspect: tmp.width / tmp.height,
//         ...tmp,
//       })
//     } else {
//       const tmp =
//         imageLoaded.width < 640 || imageLoaded.height < 640
//           ? imageLoaded.width > imageLoaded.height
//             ? imageLoaded.height
//             : imageLoaded.width
//           : 640

//       setMin({ width: 168, height: 168 })

//       setMax({
//         width: tmp,
//         height: tmp,
//       })

//       setImageCrop({
//         x: 0,
//         y: 0,
//         unit: 'px',
//         width: 168,
//         height: 168,
//         aspect: 1,
//       })
//     }
//   }

//   return (
//     <Modal isOpen={isOpen} className='modal-crop-media'>
//       <ModalHeader>
//         <div>อัปโหลดรูปภาพ</div>
//         <Checkbox onChange={onCropCover}>Cover Crop</Checkbox>
//       </ModalHeader>
//       <ModalBody>
//         <ReactCrop
//           ref={imageRef}
//           src={fileImage.base64}
//           crop={imageCrop}
//           onImageLoaded={onImageLoaded}
//           onComplete={onCropComplete}
//           onChange={onCropChange}
//           minWidth={min.width}
//           minHeight={min.height}
//           maxWidth={max.width}
//           maxHeight={max.height}
//           ruleOfThirds
//         />
//       </ModalBody>
//       <ModalFooter>
//         <button
//           className='btn-submit'
//           id='crop-image'
//           onClick={() => onClickCrop(croppedImageUrl)}
//         >
//           ตกลง
//         </button>
//         <button className='btn-cancel ml-2' onClick={onClickClose}>
//           ยกเลิก
//         </button>
//       </ModalFooter>
//     </Modal>
//   )
// }

export default BrowseImage
