
import axios from 'config/axios'
import host from 'config/host'

class withdrawProvider{

    onWithdrawById = async (params) => {
        try {
            const url = `${host.api}/seller-withdraw-orders/multi`
            const res = await axios.post(url,params)
            return res.data
        } catch (error) {
            throw error
        }
    }

    getOrderWithdrawBySellerWithdrawId = (params) => {
        try {
            const url = `${host.api}/seller-withdraw-orders/seller-withdraw/${params.id}`
            const res = axios.get(url)
            return res
        } catch (error) {
            console.log('error', error)
        }
    }

}

export default new withdrawProvider()