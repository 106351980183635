import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';
import './index.scss';
 
class VideoApp extends Component {
    player = {}
    state = {
        video: {
            src:"https://stream-app1.hyp.live/godang/vod/hls.m3u8?v=Cq5eYqy4VTPaJPHf",
            poster: ""
        }
    }

    componentDidMount = () => {
  
    }


    componentDidUpdate = prevProps => {

        if(this.props.src !== prevProps.src) {
            
            const { cache_ } = this.player;
            const { source } = cache_;
            source.src = this.props.src;

            this.player.src(source);

            this.player.load();
            this.player.autoplay('muted');
        }
    }
 
    onPlayerReady(player){
        this.player = player;
        setTimeout(() => {
            this.player.autoplay('muted');
          }, 1000);
    }
 
    onVideoPlay(duration){
        // console.log("Video played at: ", duration);
    }
 
    onVideoPause(duration){
        // console.log("Video paused at: ", duration);
    }
 
    onVideoTimeUpdate(duration){
        // console.log("Time updated: ", duration);
    }
 
    onVideoSeeking(duration){
        // console.log("Video seeking: ", duration);
    }
 
    onVideoSeeked(from, to){
        // console.log(`Video seeked from ${from} to ${to}`);
    }
 
    onVideoEnd(){
        // console.log("Video ended");
    }
 
    render() {
        const { controls,src,width,height } = this.props;
        return (
            <div>
                <VideoPlayer
                    ref={(el) => this.videoPlayer = el}
                    controls={controls}
                    src={src}
                    poster={this.state.video.poster}
                    width="100%"
                    height="auto"
                    onReady={this.onPlayerReady.bind(this)}
                    onPlay={this.onVideoPlay.bind(this)}
                    onPause={this.onVideoPause.bind(this)}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    onSeeking={this.onVideoSeeking.bind(this)}
                    onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={this.onVideoEnd.bind(this)}
                />
            </div>
        );
    }
}
export default VideoApp;