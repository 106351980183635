import {
    GET_LIST_WITHDRAW, SET_LIST_WITHDRAW, ON_ADD_SELECT_WITHDRAW, SET_SELECT_WITHDRAW, ON_SELECT_ALL_WITHDRAW,
    ON_CONFITM_WITHDRAW, SET_TAB_ACTIVE, HANDLE_SET_TAB_ACTIVE, SET_KEY_WITHDRAW, ON_SEARCH_WITHDRAW,
    FETCH_ORDER_SELLER_WITHDRAW, SET_MODAL_SELLER_WITHDRAW
} from 'redux/actions/withdraw'
import { call, takeLatest, select, put } from 'redux-saga/effects'
import orderProvider from 'provider/orderProvider'
import { getWithdraw, getUser } from '../sagas/selectors'
import withdrawProvider from 'provider/withdrawProvider'
import { OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm'
import { SET_LOADING } from 'redux/actions/app'
import { OPEN_MODAL_MESSAGE_ERROR } from 'redux/actions/modalMessage'
import { getUserRole } from 'helper/utils'
import { ADMIN, SUPER_ADMIN } from 'layout/Sidebar/Role'
import { GET_LIST_SHOP } from 'redux/actions/shop'
import shopProvider from 'provider/shopProvider'

const api_shop = new shopProvider()

export function* getListWithdraw(action) {
    const { payload } = action
    yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
        const getWithdrawState = yield select(getWithdraw)
        const getUserState = yield select(getUser)
        const role = getUserRole()
        if (role === SUPER_ADMIN) {
            const getListShop = yield call(api_shop.getAllListShops)
            yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'listAllShop', value: getListShop } })
            const listShop = [{ label: 'ร้านทั้งหมด', value: null }]
            for (const [index, e] of getListShop.entries()) {
                listShop.push({ label: e.name, value: e })
            }
            yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'listAllShop', value: listShop } })
        }
        const res = yield call(orderProvider.getListWithdraw, ({ status: getWithdrawState.activeTab, shopId: getUserState.userInfo?.shopId !== undefined ? getUserState.userInfo?.shopId : payload?.shopId, limit: getWithdrawState.limit, page: getWithdrawState.page }))
        const requestId = []
        if (res) {
            // res.data.map((order => requestId.push(order.id)))
            yield put({ type: SET_LIST_WITHDRAW, payload: res })
            yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'total', value: res.count } })
            yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: res.sumRevenue || 0 } })
            // yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'selectWithdraw', value: requestId } })
        }
    } catch (error) {
        console.log('error', error)
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onAddSelectWithdraw(action) {
    const { payload } = action
    const getWithdrawState = yield select(getWithdraw)
    let arr = [...getWithdrawState.selectWithdraw]
    let priceTransfer = getWithdrawState.priceTransfer
    if (getWithdrawState.selectWithdraw.some((value) => value == payload.id)) {
        arr = arr.filter((value) => value != payload.id)
        priceTransfer = priceTransfer - payload.priceTotal
    } else {
        arr.push(payload.id)
        priceTransfer = priceTransfer + payload.priceTotal
    }
    yield put({ type: SET_SELECT_WITHDRAW, payload: { selectWithdraw: arr } })
    yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: priceTransfer } })
}

export function* handleSelectAllWithdraw(action) {
    let getWithdrawState = yield select(getWithdraw)
    let selected = getWithdrawState.selectWithdraw
    let listAll = []
    let priceTransfer = []
    getWithdrawState.withdrawList.data.map((item) => {
        listAll.push(item.id)
        priceTransfer.push(item.priceTotal)
    })
    let sumPriceAll = priceTransfer.reduce((acc, current) => acc + current)
    if (listAll.length != selected.length) {
        selected = listAll
    } else {
        sumPriceAll = 0
        selected = []
    }
    yield put({ type: SET_SELECT_WITHDRAW, payload: { selectWithdraw: selected } })
    yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: sumPriceAll } })
}

export function* onConfirmWithdraw(action) {
    try {
        yield put({ type: SET_LOADING, payload: { loading: true } })
        const getWithdrawState = yield select(getWithdraw)
        const getUserState = yield select(getUser)
        const res = yield call(withdrawProvider.onWithdrawById, { shopId: getUserState.userInfo.shopId })
        if (res.length > 0) {
            yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'selectWithdraw', value: [] } })
            yield put({ type: OPEN_MODAL_CONFIRM, payload: { isOpen: false } })
            yield handleSetTabActive({ payload: { status: 'processing' } })
            yield getListWithdraw({})
        }
    } catch (error) {
        console.log('error', error)
        //// modal err
        yield put({ type: SET_LOADING, payload: { loading: false } })
        yield put({ type: OPEN_MODAL_MESSAGE_ERROR })
    }
    yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* handleSetTabActive(action) {
    const { payload } = action
    yield put({ type: SET_TAB_ACTIVE, payload: { status: payload.status } })
    yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'page', value: 1 } })
    yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'selectWithdraw', value: [] } })
    yield put({ type: SET_KEY_WITHDRAW, payload: { key: 'priceTransfer', value: 0 } })
    yield onSearchWithdraw({payload:{shopId: payload?.shopId}})
    yield put({ type: SET_LOADING, payload: { loading: false } })
}

export function* onSearchWithdraw(action) {
    const { payload } = action
    yield put({ type: SET_LOADING, payload: { loading: true } })
    try {
        const getWithdrawState = yield select(getWithdraw)
        const getUserState = yield select(getUser)
        if (getWithdrawState.statusSearch) {
            const res = yield call(orderProvider.onSearchWithdraw, { kw: getWithdrawState.valueSearch, shopId: getUserState.userInfo?.shopId, status: getWithdrawState.activeTab, page: getWithdrawState.page, limit: getWithdrawState.limit })
            yield put({ type: SET_LIST_WITHDRAW, payload: res })
        } else {
            yield getListWithdraw({payload:{shopId: payload?.shopId}})
        }
        yield put({ type: SET_LOADING, payload: { loading: false } })
    } catch (error) {
        console.log('error', error)
    }
}

export function* getOrderSellerWithdraw(action) {
    const { payload } = action
    try {
        const res = yield call(withdrawProvider.getOrderWithdrawBySellerWithdrawId, { id: payload.id })
        yield put({ type: SET_MODAL_SELLER_WITHDRAW, payload: { data: res.data } })
    } catch (error) {
        console.log('error', error)
    }
}

export default function* useWatcher() {
    // yield takeLatest(FETCH_REQUEST_OTP  , onFetchReqeustOtp)
    yield takeLatest(GET_LIST_WITHDRAW, getListWithdraw)
    yield takeLatest(ON_ADD_SELECT_WITHDRAW, onAddSelectWithdraw)
    yield takeLatest(ON_SELECT_ALL_WITHDRAW, handleSelectAllWithdraw)
    yield takeLatest(ON_CONFITM_WITHDRAW, onConfirmWithdraw)
    yield takeLatest(HANDLE_SET_TAB_ACTIVE, handleSetTabActive)
    yield takeLatest(ON_SEARCH_WITHDRAW, onSearchWithdraw)
    yield takeLatest(FETCH_ORDER_SELLER_WITHDRAW, getOrderSellerWithdraw)
}
