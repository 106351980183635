

import jsCookie from 'js-cookie';
import numeral from 'numeral';
import {SUPER_ADMIN, ADMIN, OWNER} from '../layout/Sidebar/Role';



const setUserFromLocal = (userData) => {
  try {
    window.localStorage.setItem('user', JSON.stringify(userData));
    //jsCookie.set('user', JSON.stringify(userData) ,{ expires: 7 })
  } catch (ex) {

  }
}

const delay = (delayInms) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

const  clearUserFromLocal = (isReload = true) =>{
    try{
         //window.localStorage.clear()
         jsCookie.remove('userInfo'); 
         jsCookie.remove('socialProfile'); 
        // 

         setTimeout(() => {
            if(isReload){
            window.location.reload()
            }
         },1000)
    
    }catch(ex){
        
   }
   //window.location.href = "/login"
}

const  clearSocialProfile  = (isReload = true) =>{
    try{
        
         jsCookie.remove('socialProfile'); 
        // 

         setTimeout(() => {
            if(isReload){
            window.location.reload()
            }
         },1000)
    
    }catch(ex){
        
   }
   //window.location.href = "/login"
}

const getUserFromLocal = () => {

  try {
    const userData = JSON.parse(jsCookie.get('userInfo')) || null;
    // const userData = JSON.parse(   window.localStorage.getItem('user')) || null;

    return userData;
  } catch (e) {
    return null;
  }
}

const isLogin = () => {
  let userData = getUserFromLocal()
  if (userData !== null) {
    return true;
  } else {
    return false;
  }
}

const getLocationString = (lat, lng) => {
  if (!lat || !lng)
    return ""
  else {
    return lat + "," + lng
  }
}



const readFile = (file) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}



function isDataURL(s) {
  isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  return !!s.match(isDataURL.regex);
}

const isNumber = (input) => {
  const exp = /^-?\d+\.?\d*$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const isMobileOrTel = (input) => {
  const exp = /^([0-9]){9,10}$/
  // /^([0-9_-]){9,10}$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const isBase64 = (str) => {
  try {
    return btoa(atob(str)) == str
  } catch (err) {
    return false;
  }
}


const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const isEmptyOrSpaces = (input) => {
  if (!input)
    return true

  return input === null || input.match(/^ *$/) !== null;
}


const login = (username, password) => {

  let role = ""
  let status = false
  let isAdmin = false
  if (isEmptyOrSpaces(username) || isEmptyOrSpaces(password)) {
    status = false
  }

  if (username == "admin" && password == "password") {
    status = true
    role = "admin"
    isAdmin = true
  }
  else if (username == "user" && password == "password") {
    status = true
    role = "user"
  } else {
    status = false
  }

  return {
    role,
    status,
    isAdmin
  }

}

const isAdmin = () => {
  let userData = getUserFromLocal()
  if (userData !== null) {
    return userData.isAdmin;
  } else {
    return false;
  }
}

const  disableGoBack = () => {
    try {
        window.history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                window.history.pushState(null, null, document.URL);
            });
   }catch(ex){
      
   }


}


const handleError = () => {


  if (process.env.NODE_ENV == "production") {
    window.location.href = "/errorNotFound"
  }

}

const setLocalStorage = (param = { key: "", value: "" }) => {

  return new Promise(function (resolve, reject) {
    try {
      const data = window.localStorage.setItem(param.key, JSON.stringify(param.value))
      resolve(data)
    } catch (ex) {
      reject(ex)
    }
  })
}

const getLocalStorage = (param = { key: "" }) => {
  return Promise.resolve().then(function () {
    const cart = window.localStorage.getItem(param.key)
    try {
      return JSON.parse(cart)
    } catch (ex) {
      return null
    }

  })

}

const detectInternalApp = () => {
  var UA = navigator.userAgent || navigator.vendor || window.opera;


  var isLINE = function (UA) {
    return (UA.indexOf("Line/") > -1);
  }

  // var isLINEBrowser = function (UA) {
  //     return (UA.indexOf("Line/") > -1 && UA.indexOf("/IAB") > -1);
  // }


  var isFacebook = function (UA) {
    return (UA.indexOf("FBAN") > -1) || (UA.indexOf("FBAV/") > -1);
  }



  if (isLINE(UA)) {
    return 'LINE';
  }
  if (isFacebook(UA)) {
    return 'Facebook'
  }

  return false;
}


function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const formatNumber = (num) => {
  return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}

const decimal2point = (number) => {
  return Number.parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const decimalToInteger = (number) => {
  const numberStr = numeral(number).format('0');
  return Number(numberStr);
}


export function* cartesian(head, ...tail) {
  const remainder = tail.length > 0 ? cartesian(...tail) : [[]];
  for (let r of remainder) for (let h of head) yield [h, ...r];
}

const isServer = () => {
  if (typeof window != 'undefined') {
    return false
  } else {
    return true
  }
}


const checkPermission = (module, action, permission) => {
  const getUser = jsCookie.get('user')
  if(getUser != undefined) return JSON.parse(getUser).permission && JSON.parse(getUser).permission.some((e) => e.module == module && e.action == action && e.permission == permission)
}

// const checkDuplicateArray = (array,keyName) => {
//   return new Set(array.map(item => item[keyName])).size !== array.length
// }

const getUserRole = () => {
  try {
    const user = jsCookie.get('user')
    const userInfo = user ? JSON.parse(user != undefined ? user : null) : {};

    if(userInfo.shopId) return OWNER;
    if(userInfo.role) {
      const {name} = userInfo.role;
      if (name === "admin") return ADMIN;
      if (name === "superadmin") return SUPER_ADMIN;
    }
    
    return OWNER;
    
  } catch (error) {
    
  }
}

const checkDuplicateArr = (arr, value) => {
    return arr.some(item => item == value)
  
}

const textIsEmpty = (text) => {
    if(typeof text !== 'string') return 
    if(text.trim().length === 0) return true
    return false
}

const objectIsEmpty = (obj) => {
  return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

const checkZipCode = (input) => {
  const exp = /^([0-9]){5}$/
  // /^([0-9_-]){9,10}$/
  if (input) {
    const str = String(input)
    return str.match(exp) !== null
  } else {
    return false
  }
}

const sortByKey = (array, key) => {
  return array.sort(function(a, b) {
      var x = a[key]; var y = b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}




 export  {
    getLocationString,
    isDataURL,
    readFile ,
    isBase64 ,
    dataURLtoFile,
    isEmptyOrSpaces,
    clearUserFromLocal,
    setUserFromLocal,
    getUserFromLocal,
    login ,
    isLogin,
    isAdmin,
    isNumber,
    isMobileOrTel,
    disableGoBack,
    handleError,
    setLocalStorage ,
    getLocalStorage ,
    detectInternalApp,
    validateEmail,
    clearSocialProfile,
    formatNumber,
    decimal2point,
    delay,
    isServer,
    checkPermission,
    decimalToInteger,
    getUserRole,
    checkDuplicateArr,
    textIsEmpty,
    sortByKey,
    checkZipCode,
    objectIsEmpty

 }
