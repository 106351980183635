import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
// import arrowLeft from '../images/icon/arrow-left.svg';
import arrowRight from '../../images/icons/arrow-right.svg';
import arrowLeft from '../../images/icons/arrow-left.svg';

import "./index.scss";

const MONTHS = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม"
];
const MONTHS_SHORT = ["ม.ค", "ก.พ.", "มี.ค", "เม.ย.", "พ.ค", "มิ.ย.", "ก.ค", "ส.ค.", "ก.ย", "ต.ค.", "พ.ย", "ธ.ค."]

const DAYS_LONG = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const DAYS_SHORT = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];


class Calendar extends Component {

  constructor(props) {
    super(props);

    const now = new Date();

    this.state = {
      date: now.getDate(),
      month: now.getMonth(),
      today: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      year: now.getFullYear(),
      selectedDate: null
    };
  }
  
  componentDidUpdate = prevState => {
    if (this.props.selectedDate !== prevState.selectedDate) {
      const { selectedDate } = this.props;
      const dateTime = new Date(selectedDate);
      this.setState({
        date: dateTime.getDate(),
        month: dateTime.getMonth(),
        today: new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()),
        year: dateTime.getFullYear(),
        selectedDate: this.props.selectedDate
      })
    }
  }

      componentDidUpdate = prevState => {
        if(this.props.selectedDate !== prevState.selectedDate) {
          const { selectedDate } = this.props;
          const dateTime = new Date(selectedDate);
          this.setState({
            date: dateTime.getDate(),
            month: dateTime.getMonth(),
            today: new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()),
            year: dateTime.getFullYear(),
            selectedDate: this.props.selectedDate 
          })
        }
      }
  static isSameDay(a, b) {
    return (
      a &&
      b &&
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  }

  get days() {
    const { month, year } = this.state;
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInMonthPrevious = new Date(year, month, 0).getDate();
    const days = [];
    const offset = new Date(year, month, 1).getDay();

    //date in previous month
    if (offset < 7) {
      for (let i = (offset - 1); i >= 0; i--) {
        //days.push(null);
        days.push(new Date(year, (month - 1), (daysInMonthPrevious - i)));
      }
    }
    // date in this month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    // date in next month
    if (days.length % 7 != 0) {
      const mergin = 7 - (days.length % 7);
      for (let i = 1; i <= mergin; i++)
        days.push(new Date(year, (month + 1), i));
    }

    return days;
  }

  get weeks() {
    const days = this.days;
    const weeks = [];
    const weekCount = Math.ceil(days.length / 7);
    for (let i = 0; i < weekCount; i++) {
      weeks.push(days.slice(i * 7, (i + 1) * 7));
    }
    return weeks;
  }

  longMonthName(month) {
    if (this.props.monthNames) {
      return this.props.monthNames[month];
    }

    return MONTHS[month];
  }

  longDayName(dayOfWeek) {
    if (this.props.longDayNames) {
      return this.props.longDayNames[dayOfWeek];
    }

    return DAYS_LONG[dayOfWeek];
  }

  shortDayName(dayOfWeek) {
    if (this.props.shortDayNames) {
      return this.props.shortDayNames[dayOfWeek];
    }

    return DAYS_SHORT[dayOfWeek];
  }



  previousMonth = () => {
    const { month, year } = this.state;

    this.setState({
      month: month !== 0 ? month - 1 : 11,
      year: month !== 0 ? year : year - 1
    }, () => {

      const { month, year } = this.state;

      const startOfMonth = new Date(year, month, 1);
      const endOfMonth = new Date(year, month + 1, 0);

    });
  };

  nextMonth = () => {
    const { month, year } = this.state;

    this.setState({
      month: month !== 11 ? month + 1 : 0,
      year: month !== 11 ? year : year + 1
    }, () => {

      const { month, year } = this.state;

      const startOfMonth = new Date(year, month, 1);
      const endOfMonth = new Date(year, month + 1, 0);


    });

  };

  onDayClick = (day) => (event) => {
    if (day && this.date_diff_indays(new Date(), new Date(day)) >= 0 || this.props.allowpreviousDate) { /// this allowpreviousDate control previousDate on/off
      this.props.onDayClick(moment(day).format("DD/MM/YYYY"));
      //   
      if (this.state.selectedDate && this.state.selectedDate.classList) {
        this.state.selectedDate.classList.remove("selected")
      }

      event.target.classList.add("selected");
      this.setState({ selectedDate: event.target });
    }
    
    if (this.props.checkEndDate && this.date_diff_startEnd(this.props.checkEndDate, new Date(day)) <= 0) {
      this.props.onDayClick(moment(this.props.checkEndDate).format("DD/MM/YYYY"));
      if (this.state.selectedDate) {
        event.target.classList.remove("selected")
      }
    }

    if (this.props.checkStartDate && this.date_diff_startEnd(this.props.checkStartDate, new Date(day)) >= 0) {
      this.props.onDayClick(moment(this.props.checkStartDate).format("DD/MM/YYYY"));
      if (this.state.selectedDate) {
        event.target.classList.remove("selected")
      }
    }

  }

  date_diff_indays = (date1, date2) => {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  date_diff_startEnd = (date1, date2) => {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }
    
      renderDay = (day, index) => {
        let { date, month, today, year } = this.state;
        let { selectedDate, allowpreviousDate } = this.props; /// this allowpreviousDate control previousDate on/off
    // const isActive = active && day && Calendar.isSameDay(active, day);
    const isToday = day && day.valueOf() === today.valueOf();
    const previousDate = this.date_diff_indays(new Date(), new Date(day)) < 0 ? ' otherMonth ' : '';
    // const week = this.date_diff_indays(new Date(), new Date(day)) < 7 && this.date_diff_indays(new Date(), new Date(day)) >= 0 ? ' week' : '';
    // const otherMonth = day.getMonth() >= new Date().getMonth()  ? '' : ' otherMonth';

    let selected;

    if (selectedDate)
      selected = moment(selectedDate).format("MM/DD/YYYY") === moment(day).format("MM/DD/YYYY") ? "selected" : "";

    return (
      <td key={`${day}-${index}`}>
        <div className="day-container">
          <div className={`day`}> &nbsp; </div>
          <div className={["container",
            !day ? "empty" : null,
            isToday ? " today" : "",
            allowpreviousDate ? "" : previousDate, /// this allowpreviousDate control previousDate on/off
            selected
          ].filter(v => v).join(" ")}
            key={`${year}.${month}.day.${index}`}
            onClick={this.onDayClick(day)}
            id={`${moment(day).format("MM_DD_YYYY")}`}>
            {day ? moment(day).format("DD") : ""}
          </div>
        </div>
      </td>
    );
  };

  renderWeek = (days, index) => {
    const { month, year } = this.state;

    return (
      <tr key={`${year}.${month}.week.${index}`}>{days.map(this.renderDay)}</tr>
    );
  };

  renderDayHeader(dayOfWeek, index) {
    return (
      <th scope="col day-header" key={`${dayOfWeek}_${index}`}>
        <span title={this.longDayName(dayOfWeek)}>
          {this.shortDayName(dayOfWeek)}
        </span>
      </th>
    );
  }

  render() {
    const { month, year } = this.state;

    return (
      <div className="react-daypicker-root">
        <div className="header">
          <div className="month-year">
            <img src={arrowLeft} onClick={this.previousMonth} />
            {this.longMonthName(month)} {(year + 543)}
            {/* ANCHOR - arrowRight */}
            <img src={arrowRight} onClick={this.nextMonth} />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {this.renderDayHeader(0)}
              {this.renderDayHeader(1)}
              {this.renderDayHeader(2)}
              {this.renderDayHeader(3)}
              {this.renderDayHeader(4)}
              {this.renderDayHeader(5)}
              {this.renderDayHeader(6)}
            </tr>
          </thead>
          <tbody>{this.weeks.map(this.renderWeek)}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(Calendar);
