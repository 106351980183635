import { takeLatest, put, call ,select ,delay} from "redux-saga/effects";
import { SET_LIVE ,
         FETCH_CHECK_LIVE, 
         GET_LIVE_STATUS, 
         SET_LIVE_STATUS, 
         PROVIDE_STREAM,
         GET_VIDEO_SOURCE,
         SET_VIDEO_SOURCE } from "../actions/live";
import { GET_POST, GET_GENERATE_KEY } from '../actions/post'
// import { FETCH_CART , FETCH_CART_COUNT } from "../actions/cart";
// import { FETCH_SHOP_DETAIL_ITEM,GET_PRODUCT_BY_SHOP,SET_LOAD_MORE_SHOP_DETAIL  } from "../actions/shop";
// import productProvider from '../../providers/productProvider'
import postProvider from '../../provider/postProvider'
import liveProvider from '../../provider/liveProvider'
import { getLiveState } from './selectors'
import { getShop } from './selectors';
import { getPost } from './selectors';
import { putWait, withCallback } from 'redux-saga-callback';
import { dispatchPost, SET_MODAL_LIVE_FINISH_CONFIRM } from '../actions/post'

// export function* onFetchLive(action){
//     const {payload} = action
//     const {slug} = payload
//     try{

//        const  livePost   = yield call(postProvider.getPostDetail , slug)

//        yield put({ 
//              type: SET_LIVE ,  
//              payload:{
//                    postDetail : livePost.data
//              }
//            })

//                //TODO : get cart count
//      yield put({
//                 type : FETCH_CART_COUNT
//             })
    

    
//     }catch(ex){
//         throw new Error(ex.message)
//     }
   

// }

// export function* onSetProductLive(action){
//     const { payload } = action
    
//     yield put({
//         type: SET_PRODUCT_LIST,
//         payload:{
//             index: payload.index-1,
//             showOn: payload.showOn,
//             quotaRemains: payload.stockRemain
//         }
//     })
// }

export function* onGetStatusLive(action){
      try{
            const getShopState = yield select(getShop);
            const { currentPost } = getShopState;
            const  liveStatus = yield call(postProvider.getLiveStatus , {postId:currentPost.postId});
            yield put({ type: SET_LIVE_STATUS, payload:{ liveStatus } });
      } catch (error){
            throw(new Error(error));
      }
}

export function* onCheckStatusLive(action){
    const stateLive  = yield select(getLiveState)
    yield put({ type: GET_POST, payload: {} })
    yield put({ type: GET_LIVE_STATUS, payload: {} })
}

export function* onProvideStream(action) {
      try {
            const getShopState = yield select(getShop);
            const { currentPost } = getShopState;
            const param = { postId: currentPost. postId };
            const res = yield call(postProvider.getLiveStatus, param);

            if(res.liveStatus === 'wait') {
                  const post = yield call(postProvider.getPost, param);
                  if(!post?.streamKey || !post?.streamUrl) 
                        yield putWait({type: GET_GENERATE_KEY, payload: {}});
            }else if(res.liveStatus === 'disconnect') {
                  const post = yield call(postProvider.getPost, param);
                  if(!post?.streamKey || !post?.streamUrl) 
                        yield putWait({type: GET_GENERATE_KEY, payload: {}});
            }

            yield put({ type: GET_POST, payload: {} });
            yield put({ type: GET_LIVE_STATUS, payload: {} });
          
      } catch (error) {
          
      }
  }

export function* onGetVideoSource(action){
      try{
            const  videoSources = yield call(liveProvider.getVideoSources , {});
            yield put({ type: SET_VIDEO_SOURCE , payload:{ videoSources } });
      } catch (error){
            throw(new Error(error));
      }
}

export default function* useWatcher() {

    // yield takeLatest(FETCH_LIVE , onFetchLive)
    // yield takeLatest(SET_ITEMS_LIVE, onSetProductLive)
    yield takeLatest(FETCH_CHECK_LIVE, onCheckStatusLive)
    yield takeLatest(GET_LIVE_STATUS, onGetStatusLive)
    yield takeLatest(PROVIDE_STREAM, onProvideStream)
    yield takeLatest(GET_VIDEO_SOURCE, onGetVideoSource)

  }