
import {OPEN_MODAL_ALERT} from '../actions/modalAlert'

const initialState = {
    modalAlert: {
        isOpen: false,
        message: "",
        type: "",
        btnLabel: "ตกลง"
    }
}


export default function (state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case OPEN_MODAL_ALERT: 
            const { isOpen, message, type, btnLabel } = payload
            return {
                ...state,
                modalAlert: { isOpen, message, type, btnLabel }
            }
        default: 
        return state
    }
}