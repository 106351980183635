import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import classnames from 'classnames';

import IconSuccess from 'images/icons/successGreen.svg'

import 'styles/_addpromocode.scss'

// import { ButtonUpload, ModalLoading } from 'components/output';
import ModalAlert from '../components/modalAlert/ModalAlert'
import Radio from '../components/RadioButton'
import ToggleInput from '../components/ToggleInput'
import SelectAndTextInput from '../components/SelectAndTextInput'

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm'
import { CREATE_PROMOCODE, dispatchPromocode, HANDLE_UPDATE_PROMOCODE, OPEN_MODAL_ADD_PROMOCODE, OPEN_MODAL_CONFIRM_PROMOCODE, SET_DEFAULT_DATA_PROMOCODE, SET_KEY_VALUE_PROMOCODE, VERIFY_REF_CODE } from 'redux/actions/promocode'
import { OPEN_MODAL_ALERT, dispatchModalAlert } from 'redux/actions/modalAlert';
import ModalDetailPromocode from 'components/ModalDetailPromocode'

import { getUserRole } from "../helper/utils";
import { ADMIN, OWNER } from "../layout/Sidebar/Role";
import ModalMessage from "components/modalMessage";
import promocode from "redux/reducers/promocode";
import ModalMessageCallback from "components/ModalMessageCallback";
import equal from 'fast-deep-equal'
import ModalConfirm from "components/modalConfirm/ModalConfirm";
import DatePickerInputComponent from "components/DatePickerInput";
import moment from 'moment'
import TimePickerComponent from "components/Timepicker";

const couponType = ["voucher", "discount"]
const couponRole = ["private", "public"]
const couponAction = ["product", "shipping"]
const status = ["show", "hide"]

const initialState = {
    promotionCode: '',
    pattern: couponType[0],
    type: couponAction[0],
    License: couponRole[1],
    promotionCodePerUser: 'unlimit',
    promotionCodePerUserOption: { promotionCodeOption: 'ต่อวัน', promotionCodeValue: '' },
    usageStatus: false,
    verifyCode: null,
    dateStart: null,
    dateEnd: moment().format('DD/MM/YYYY'),
    timeStart: null,
    timeEnd: null,
    companySharing: 0,
    shopSharing: 0,
    totalSharing: 100
}
const options = ['ต่อวัน',
    'ต่อสัปดาห์',
    'ต่อเดือน',
    'ต่อโปรโมชั่น']



class AddPromocode extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState()
    }

    componentDidMount = () => {
        this.initComponent();
    }

    // static getDelrivedStateFromProps(nextProps,state){
    //     if(nextProps.verifyCode !== state){
    //         this.handleVerifyPromocode(nextProps)
    //         return {
    //             promotionCode: nextProps.verifyCode.coupon.refCode1,
    //             verifyCode: nextProps.verifyCode
    //         }
    //     }
    // }

    static getDerivedStateFromProps(nextProps, state) {


        if (nextProps.promocode.managePromocode === "create" && !equal(nextProps.promocode?.verifyCode, state.verifyCode)) {
            const startHour = moment(nextProps.promocode?.verifyCode?.sellingStartAt).format("HH")
            const startMinute = moment(nextProps.promocode?.verifyCode?.sellingStartAt).format("mm")
            const endHour = moment(nextProps.promocode?.verifyCode?.sellingStartAt).format("HH")
            const endMinute = moment(nextProps.promocode?.verifyCode?.sellingStartAt).format("mm")
            return {
                verifyCode: nextProps.promocode.verifyCode,
                promotionCode: nextProps.promocode?.verifyCode?.productCode || '',
                pattern: nextProps.promocode?.verifyCode?.productCategory === couponType[0] ? couponType[0] : couponType[1],
                dateStart: moment(nextProps.promocode?.verifyCode?.sellingStartAt).format('DD/MM/YYYY'),
                dateEnd: moment(nextProps.promocode?.verifyCode?.sellingStopAt).format('DD/MM/YYYY'),
                timeStart: moment(nextProps.promocode?.verifyCode?.sellingStartAt, "HH:mm").set({ hour: startHour, minute: startMinute }),
                timeEnd: moment(nextProps.promocode?.verifyCode?.sellingStartAt, "HH:mm").set({ hour: endHour, minute: endMinute }),
            }
        }


        if (nextProps.promocode.managePromocode === "edit" && !equal(nextProps.promocode?.verifyCode, state.verifyCode)) {
            const startHour = moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.startDate).format("HH")
            const startMinute = moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.startDate).format("mm")
            const endHour = moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.stopDate).format("HH")
            const endMinute = moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.stopDate).format("mm")
            return {
                verifyCode: nextProps.promocode.verifyCode,
                promotionCode: nextProps.promocode?.verifyCode?.productCode,
                pattern: nextProps.promocode?.verifyCode?.coupon?.couponType,
                type: nextProps.promocode?.verifyCode?.coupon?.couponAction,
                License: nextProps.promocode?.verifyCode?.coupon?.couponRole,
                usageStatus: nextProps.promocode?.verifyCode?.coupon?.status === "show" ? true : false,
                promotionCodePerUser: nextProps.promocode?.verifyCode?.coupon?.promotion.limitUser !== null ? 'limit' : 'unlimit',
                promotionCodePerUserOption: {
                    promotionCodeOption: state.checklimitUserLimit(nextProps.promocode?.verifyCode?.coupon?.promotion?.limitUser?.unit),
                    promotionCodeValue: nextProps.promocode?.verifyCode?.coupon?.promotion?.limitUser?.value
                },
                dateStart: moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.startDate).format('DD/MM/YYYY'),
                dateEnd: moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.stopDate).format('DD/MM/YYYY'),
                timeStart: moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.startDate, "HH:mm").set({ hour: startHour, minute: startMinute }),
                timeEnd: moment(nextProps.promocode?.verifyCode?.coupon?.promotion?.startDate, "HH:mm").set({ hour: endHour, minute: endMinute }),
                companySharing: nextProps.promocode?.verifyCode?.coupon?.costSharingPlatform,
                shopSharing: nextProps.promocode?.verifyCode?.coupon?.costSharingPartner,
            }
        }
        return null
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.promocode.managePromocode === "edit" && !equal(nextProps.promocode?.verifyCode, this.state.verifyCode)) {
    //         this.setState({
    //             verifyCode: nextProps.promocode.verifyCode,
    //             promotionCode: '',
    //             pattern: nextProps.promocode.verifyCode.coupon.couponType,
    //             type: nextProps.promocode.verifyCode.coupon.couponAction,
    //             License: nextProps.promocode.verifyCode.coupon.couponRole,
    //             promotionCodePerUser: 'unlimit',
    //             promotionCodePerUserOption: { promotionCodeOption: 'ต่อวัน', promotionCodeValue: '' },
    //             usageStatus: nextProps.promocode.verifyCode.coupon.status === "show" ? true : false,
    //             promotionCodePerUser: nextProps.promocode.verifyCode.coupon.promotion.limitUser !== null ? 'limit' : 'unlimit',
    //             promotionCodePerUserOption: { 
    //                 promotionCodeOption: this.checklimitUserLimit(nextProps.promocode.verifyCode.coupon.promotion.limitUser?.unit), 
    //                 promotionCodeValue: nextProps.promocode.verifyCode.coupon.promotion.limitUser?.value
    //             },
    //         })
    //     }
    // }

    componentWillUnmount() {
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'verifyCode', value: null } }))
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'managePromocode', value: 'create' } }))
        this.props.dispatch(dispatchPromocode({ type: SET_KEY_VALUE_PROMOCODE, payload: { key: 'modalMessage', value: {isOpen: false,message: ''} } }))
        // this.props.dispatch(dispatchPromocode({ type: SET_DEFAULT_DATA_PROMOCODE }))
    }

    // componentDidUpdate = prevProps => {
    //     return true
    // }

    /* SECTION - common function */
    getInitialState = () => {
        let state = JSON.parse(JSON.stringify(initialState))
        state = { ...state, checklimitUserLimit: this.checklimitUserLimit.bind(this) }
        state.timeStart = moment('00:00', 'HH:mm')
        state.timeEnd = moment('00:00', 'HH:mm')
        state.dateStart = moment().format('DD/MM/YYYY')
        state.dateStart = moment().format('DD/MM/YYYY')
        return state
    }

    initComponent = () => {
    }

    reset = () => this.setState(this.getInitialState())

    checklimitUserLimit = (type) => {
        switch (type) {
            case 'day':
                return 'ต่อวัน'
            case 'week':
                return 'ต่อสัปดาห์'
            case 'month':
                return 'ต่อเดือน'
            case 'promotion':
                return 'ต่อโปรโมชั่น'
            default:
                return 'ต่อวัน'
                break;
        }
    }

    /* !SECTION - common function */

    /* SECTION - event function */
    onChange = e => {
        let value = e.target.value;
        let name = e.target.name;
        if (e.target.type === 'checkbox') value = !this.state[name]
        this.setState({ [name]: value });
    }

    onPromotionCodeOptionChange = (e) => {

        const name = e.target.name
        const value = e.target.value

        this.setState({ promotionCodePerUserOption: { ...this.state.promotionCodePerUserOption, [name]: value } })
    }
    /* !SECTION - event function */

    handleVerifyPromocode = (code) => {
        this.props.dispatch(dispatchPromocode({ type: VERIFY_REF_CODE, payload: { promotionCode: code } }))
    }

    handleUpdatePromocode = (id) => {
        if (this.props.promocode.managePromocode === "create") {
            this.props.dispatch(dispatchPromocode({ type: CREATE_PROMOCODE, payload: { coupon: this.state } }))
        } else {
            this.props.dispatch(dispatchPromocode({ type: HANDLE_UPDATE_PROMOCODE, payload: { coupon: this.state, id: id } }))
        }
    }

    cancelUpdatePromocode = () => {
        this.setState({ ...initialState })
        this.props.dispatch(dispatchPromocode({ type: SET_DEFAULT_DATA_PROMOCODE }))
    }

    onDateDatePickerInputChange = date => {
        // this.setState({
        //     dateStart: moment(date).format('DD/MM/YYYY')
        // })
    }

    onTimePickerChange = (time) => {
        this.setState({
            timeStart: time
        })
    }

    onChangeSharing = (value, name) => {
        this.setState({
            [name]: value,
        })
    }

    checkPercentSharing = (name) => {
        let percent = parseInt(this.state[name])
        if (percent > 100) percent = 100
        switch (name) {
            case 'companySharing':
                this.setState({
                    shopSharing: 100 - percent,
                    [name]: percent
                })
            case 'shopSharing':
                this.setState({
                    companySharing: 100 - percent,
                    [name]: percent
                })
            default:
                break;
        }
    }

    render() {
        const { promotionCode,
            pattern,
            type,
            License,
            promotionCodePerUser,
            promotionCodePerUserOption,
            usageStatus,
            timeStart,
            timeEnd,
            companySharing,
            shopSharing,
            dateStart,
            dateEnd } = this.state;
        const { promotionCodeOption,
            promotionCodeValue } = promotionCodePerUserOption;
        const { modalAlert, modalDetailPromocode, promocode, history } = this.props
        return (
            <React.Fragment>
                <div className="promotion-code" id="addpromotioncode">
                    <section className="section validate-ref-code">
                        {promocode.managePromocode === "create" ? <h4>ตรวจสอบรหัสอ้างอิง</h4> :
                            promocode.verifyCode?.productCode && <div><h4>รหัสอ้างอิง <span className="color-primary">{promocode.verifyCode?.productCode}</span> </h4></div>
                        }
                        {promocode.managePromocode === "create" &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-8">
                                        <div className='mt-2'>
                                            <span>
                                                รหัสอ้างอิง
                                    <span className='text-danger'>*</span>
                                                <span className="title-detail">(จำกัด 30 ตัวอักษร)</span>
                                            </span>
                                            <div className="row">
                                                <div className="col-8">
                                                    <input
                                                        type='text'
                                                        name='promotionCode'
                                                        className={`form-control`}
                                                        value={promotionCode}
                                                        onChange={this.onChange}
                                                        placeholder="กรอกรหัส"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <button className='btn-submit'
                                                        style={{ height: '38px' }}
                                                        className={classnames('btn-submit', { 'disabled': !(promotionCode && promotionCode.trim().length > 0) })}
                                                        disabled={!(promotionCode && promotionCode.trim().length > 0)}
                                                        onClick={() => this.handleVerifyPromocode(promotionCode)}>
                                                        ตรวจสอบ
                                        </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }




                        {(promocode.verifyCode?.productCode || promocode.verifyCode?.coupon) &&
                            <div className={classnames('row', { false: 'fade3' })}>
                                <div className="col-12">
                                    <div className="notification notification-info">
                                        <div className="message-wrap">
                                            <img src={IconSuccess} />
                                            <span className='ml-4'>ตรวจสอบรหัสอ้างอิง {promocode.verifyCode?.productCode} เรียบร้อยแล้ว <br /> รหัสอ้างอิงสามารถใช้งานได้</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </section>

                    {(promocode.verifyCode?.productCode || promocode.verifyCode?.coupon) &&
                        <React.Fragment>
                            {promocode.managePromocode === "create" ? <h3>เพิ่มข้อมูลโปรโมชั่นโค้ด</h3> : <h3>แก้ไขข้อมูลโปรโมชั่นโค้ด</h3>}

                            <section className="section option">
                                <h4>รูปแบบ</h4>
                                <div className='row mt-4'>
                                    <div className="col-4">
                                        <Radio
                                            value={couponType[0]}
                                            className='mr-4'
                                            valueOption={pattern}
                                            name='pattern'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>คูปองแทนเงินสด</label>
                                    </div>
                                    <div className="col-4">
                                        <Radio
                                            value={couponType[1]}
                                            className='mr-4'
                                            valueOption={pattern}
                                            name='pattern'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>ลดราคา</label>
                                    </div>
                                </div>
                            </section>

                            <section className="section option">
                                <h4>ประเภท</h4>
                                <div className='row mt-4'>
                                    <div className="col-4">
                                        <Radio
                                            value={couponAction[0]}
                                            className='mr-4'
                                            valueOption={type}
                                            name='type'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>ลดราคาสินค้า</label>
                                    </div>
                                    <div className="col-4">
                                        <Radio
                                            value={couponAction[1]}
                                            className='mr-4'
                                            valueOption={type}
                                            name='type'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>ลดราคาค่าจัดส่ง</label>
                                    </div>
                                </div>
                            </section>

                            <section className="section option">
                                <h4>สิทธิ์การใช้งาน</h4>
                                <div className='row mt-4'>
                                    <div className="col-4">
                                        <Radio
                                            value={couponRole[1]}
                                            className='mr-4'
                                            valueOption={License}
                                            name='License'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>โค้ดสาธารณะ</label>
                                    </div>
                                    <div className="col-4">
                                        <Radio
                                            value={couponRole[0]}
                                            className='mr-4'
                                            valueOption={License}
                                            name='License'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>โค้ดส่วนบุคคล</label>
                                    </div>
                                </div>
                            </section>

                            <section className="section option">
                                <h4>กำหนดจำนวน สิทธิ์โปรโมชั่นโค้ดต่อ "บุคคล"</h4>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <Radio
                                            value='unlimit'
                                            className='mr-4'
                                            valueOption={promotionCodePerUser}
                                            name='promotionCodePerUser'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>ไม่จำกัด</label>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-10 d-flex align-items-center">
                                        <Radio
                                            value='limit'
                                            className='mr-4'
                                            valueOption={promotionCodePerUser}
                                            name='promotionCodePerUser'
                                            onChange={this.onChange}
                                        />
                                        <label className='form-check-label mr-4'>
                                            <SelectAndTextInput
                                                inputType={'number'}
                                                inputName={'promotionCodeValue'}
                                                inputValue={promotionCodeValue}
                                                onInputChange={this.onPromotionCodeOptionChange}
                                                inputPlaceholder={'0'}
                                                options={options}
                                                selectName={'promotionCodeOption'}
                                                selectValue={promotionCodeOption}
                                                onSelectChange={this.onPromotionCodeOptionChange}
                                                disabled={promotionCodePerUser !== 'limit'}
                                            >
                                            </SelectAndTextInput>
                                        </label>
                                    </div>
                                </div>
                            </section>

                            <section className="section option">
                                <div className="row">
                                    <div className="col-4">
                                        <h4>ระยะเวลาการใช้งาน</h4>
                                    </div>
                                </div>

                                <div className="row d-flex align-items-center col-12">
                                    <div className='d-flex align-items-start flex-column col p-0'>
                                        <span className="require font-primary">วันที่</span>
                                        <DatePickerInputComponent /// here
                                            onSubmitDateSelected={(date) => this.setState({
                                                dateStart: moment(date, "MM/DD/YYYY").format('DD-MM-YYYY')
                                            })}
                                            allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                            value={dateStart}
                                            date={new Date(moment(dateStart,'DD/MM/YYYY').format('MM/DD/YYYY'))}
                                        />
                                    </div>
                                    <div className='d-flex align-items-start flex-column col'>
                                        <span className="require font-primary">เวลา</span>
                                        <TimePickerComponent
                                            onTimePickerChange={
                                                (time) => this.setState({
                                                    timeStart: time
                                                })}
                                            value={moment(timeStart, "HH:mm")}
                                        />

                                    </div>
                                    <div className="mt-4 col-0"> ถึง </div>
                                    <div className='d-flex align-items-start flex-column col'>
                                        <span className="require font-primary font-weight-100">วันที่</span>
                                        <DatePickerInputComponent /// here
                                           onSubmitDateSelected={(date) => this.setState({
                                            dateEnd: moment(date, "MM/DD/YYYY").format('DD-MM-YYYY')
                                        })}
                                            allowpreviousDate={true} /// this allowpreviousDate control previousDate on/off
                                            value={dateEnd}
                                            date={new Date(moment(dateEnd,'DD/MM/YYYY').format('MM/DD/YYYY'))}
                                        />
                                    </div>
                                    <div className='d-flex align-items-start flex-column col p-0'>
                                        <span className="require font-primary">เวลา</span>
                                        <TimePickerComponent
                                            onTimePickerChange={(time) => this.setState({ timeEnd: time })}
                                            value={timeEnd}
                                        />
                                    </div>

                                </div>
                            </section>

                            <section className="section option">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>ผู้รับผิดชอบค่าใช้จ่ายในการลดราคา</h4>
                                    </div>
                                </div>
                                <div className="row col-12 d-flex align-items-center" style={{ gap: '20px' }}>

                                    <div className="d-flex flex-column  w-25">
                                        บริษัท
                                    <div className='input-group'>
                                            <div className='input-group-prepend'>
                                                <span className='input-group-text'>%</span>
                                            </div>
                                            <input
                                                type='text'
                                                className='form-control'
                                                aria-label='Amount (to the nearest dollar)'
                                                name='companySharing'
                                                onChange={(e) => this.onChangeSharing(e.target.value, e.target.name)}
                                                onBlur={(e) => this.checkPercentSharing(e.target.name)}
                                                value={companySharing}
                                            />
                                        </div>
                                    </div>

                                    <span className="mt-4">:</span>

                                    <div className="d-flex flex-column w-25">
                                        ร้านค้า
                                    <div className='input-group'>
                                            <div className='input-group-prepend'>
                                                <span className='input-group-text'>%</span>
                                            </div>
                                            <input
                                                type='text'
                                                className='form-control'
                                                aria-label='Amount (to the nearest dollar)'
                                                name='shopSharing'
                                                onChange={(e) => this.onChangeSharing(e.target.value, e.target.name)}
                                                onBlur={(e) => this.checkPercentSharing(e.target.name)}
                                                value={shopSharing}
                                            />
                                        </div>
                                    </div>

                                </div>


                            </section>


                            <section className="section option">
                                <div className="row">
                                    <div className="col-4">
                                        <h4>สถานะการใช้งาน</h4>
                                    </div>
                                    <div className="col-8">
                                        <ToggleInput value={usageStatus}
                                            onChange={this.onChange}
                                            name="usageStatus"></ToggleInput>

                                    </div>
                                </div>
                            </section>

                            <div className='d-flex flex-row justify-content-center'
                                style={{ gap: '4em', margin: '0 1rem' }} >
                                <button className='btn-cancel'
                                    onClick={() => this.props.dispatch(dispatchPromocode(
                                        {
                                            type: OPEN_MODAL_CONFIRM_PROMOCODE,
                                            payload: {
                                                isOpen: true, message: 'ยกเลิกการเพิ่มโปรโมชั่นโค้ด',
                                                confirm: () => this.cancelUpdatePromocode(),
                                                isClose: () => this.props.dispatch(dispatchPromocode({
                                                    type: OPEN_MODAL_CONFIRM_PROMOCODE,
                                                    payload: {
                                                        isOpen: false,
                                                        message: '',
                                                        confirm: () => { }
                                                    }
                                                }))
                                            }
                                        }))}> ยกเลิก </button>
                                <button className='btn-submit' onClick={() => this.handleUpdatePromocode(promocode.verifyCode?.coupon?.id)}> บันทึก </button>
                            </div>
                        </React.Fragment>
                    }

                    <ModalDetailPromocode isOpen={modalDetailPromocode.isOpen} data={modalDetailPromocode.data} />
                    <ModalAlert isOpen={modalAlert.isOpen} />
                    <ModalMessageCallback
                        isOpen={promocode.modalMessage.isOpen}
                        message={promocode.modalMessage.message}
                        confirmCallback={() => history.push({
                            pathname: promocode.activeTab.trim().length === 0 ? '/manage/promocode' : `/manage/promocode/${promocode.activeTab}`,
                        })} // สร้าง coupon สำเร็จ
                        title={promocode.modalMessage.title}
                    />

                    <ModalConfirm
                        isOpen={promocode.modalConfirm.isOpen}
                        message={promocode.modalConfirm.message}
                        confirm={promocode.modalConfirm.confirm}
                        isClose={promocode.modalConfirm.isClose}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    modalAlert: state.modalAlert.modalAlert,
    modalDetailPromocode: state.promocode.modalDetailPromocode,
    promocode: state.promocode,
    modalMessage: state.modalMessage
})

export default connect(mapStateToProps)(AddPromocode);
