import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import host from 'config/host'
import { dispatchShop, UPDATE_SHOP_DETAIL } from 'redux/actions/shop'
import { dispatchGallery, SET_GALLERY_FIELD } from 'redux/actions/gallery'
import { TextInput, Textarea, NumberInput, FloatInput} from 'components/input'
import { ButtonUpload, ModalLoading } from 'components/output'
import IconShopAvatar from 'images/icons/default-shop-logo.svg'
import closeImage from 'images/icons/close-image.svg'
import { readCookie } from 'helper/cookieHelper'

const FormShopDetail = (props) => {
  const { shopDetail, media, dispatch } = props

  const [data, setData] = useState(null)
  const [initalData, setInitalData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [role, setRole] = useState('admin')

  useEffect(() => {
    setIsLoading(false)

    const roleUser = readCookie('role')
    setRole(roleUser)

    const tmpInitalData = {
      logoPathId: shopDetail?.logoPath?.id || null,
      logoPath: shopDetail?.logoPath?.path || '',
      coverPathId: shopDetail?.coverPath?.id || null,
      coverPath: shopDetail?.coverPath?.path || '',
      name: shopDetail?.name || '',
      description: shopDetail?.description || '',
      sharingShop: shopDetail?.sharingShop || null,
      sharingPlatform: shopDetail?.sharingPlatform || null,
      paymentFee: `${shopDetail?.shopFixedRate?.fixedRate.toFixed(2)}` || null,
    }

    setInitalData(tmpInitalData)
    setData(tmpInitalData)
  }, [shopDetail])

  useEffect(() => {
    if (media.avatar) {
      const tmp = data ? { ...data } : {}
      const listAvatar = media.avatar

      setData({
        ...tmp,
        logoPathId: listAvatar[0]?.id,
        logoPath: listAvatar[0]?.path,
      })
    }
  }, [media.avatar])

  useEffect(() => {
    if (media.coverShop) {
      const tmp = data ? { ...data } : {}

      setData({
        ...tmp,
        coverPathId: media?.coverShop[0]?.id,
        coverPath: media?.coverShop[0]?.path,
      })
    }
  }, [media.coverShop])

  const validator = () => {
    let tmpError = error ? { ...error } : {}

    if (!data.logoPath) {
      tmpError = {
        ...tmpError,
        logo: 'กรุณาใส่โปรไฟล์ร้านค้า',
      }
    } else {
      delete tmpError.logo
    }

    // if (!data.coverPath) {
    //   tmpError = {
    //     ...tmpError,
    //     cover: 'กรุณาใส่ภาพหน้าปกร้านค้า',
    //   }
    // } else {
    //   delete tmpError.cover
    // }

    if (!data.name) {
      tmpError = {
        ...tmpError,
        name: 'กรุณาใส่ชื่อร้านค้า',
      }
    } else {
      delete tmpError.name
    }

    if (!data.description) {
      tmpError = {
        ...tmpError,
        description: 'กรุณาใส่รายละเอียดร้านค้า',
      }
    } else {
      delete tmpError.description
    }

    if (!data.sharingShop) {
      tmpError = {
        ...tmpError,
        sharingShop: 'กรุณาใส่ส่วนแบ่งร้านค้า',
      }
    } else {
      delete tmpError.sharingShop
    }

    if (!data.sharingPlatform) {
      tmpError = {
        ...tmpError,
        sharingPlatform: 'กรุณาใส่ส่วนแบ่งระบบ',
      }
    } else {
      delete tmpError.sharingPlatform
    }

    setError(tmpError)
  }

  useEffect(() => {
    if (error && Object.keys(error).length === 0) {
      updateShopDetail()
    }
  }, [error])

  const updateShopDetail = () => {
    setIsLoading(true)

    dispatch(
      dispatchShop({
        type: UPDATE_SHOP_DETAIL,
        payload: {
          shopId: shopDetail.id,
          body: {
            logoPathId: data.logoPathId || null,
            name: data.name,
            description: data.description,
            coverPathId: data.coverPathId || 0,
            sharingShop: parseFloat(data.sharingShop),
            sharingPlatform: parseFloat(data.sharingPlatform),
            paymentFee: parseFloat(data.paymentFee),
          },
        },
      }),
    )
  }

  const handleChangeText = (text, key) => {
    setData({
      ...data,
      [key]: text,
    })
  }

  const onChangeFloatInput = (value, key) => {
    setData({
      ...data,
      [key]: value,
    })
  }

  const onChangeSharing = (_value, key) => {
    const value = parseFloat(_value);
    const sharingPlatform = key === 'sharingPlatform' ? value : 100 - value;
    const sharingShop = key === 'sharingShop' ? value : 100 - value;
    setData({
      ...data,
      sharingPlatform,
      sharingShop,
    })
  }

  const clearData = () => {
    setData(initalData)
    setError(null)
  }

  const isDirty = () => {
    return JSON.stringify(data) !== JSON.stringify(initalData)
  }

  const clearCover = () => {
    setData({ ...data, coverPath: null, coverPathId: null })
    dispatch(
      dispatchGallery({
        type: SET_GALLERY_FIELD,
        payload: { key: 'coverShop', value: null },
      }),
    )
  }

  return (
    <section className='detail-container mb-5'>
      <div className='detail-content'>
        <div className='col-12 text-left align-center mt-3'>
          <h4>ข้อมูลทั่วไป</h4>
        </div>
        <div className='col-12 text-left align-center mt-3'>
          <span>รูปโปรไฟล์ร้านค้า</span>
          <span style={{ color: 'red' }}>*</span>
        </div>
        <div className='col-12 text-left align-center mt-3 mb-3'>
          <ButtonUpload
            isAvatar
            shopDetail={{
              ...shopDetail,
              shopId: shopDetail?.id,
            }}
            fieldUpload='avatar'
            limitImageUpload={1}
            avatar={
              data?.logoPath ? (
                <img
                  alt=''
                  src={host.avatar + data?.logoPath}
                  className='shop-profile-image'
                />
              ) : (
                <img src={IconShopAvatar} alt='' />
              )
            }
          />
        </div>
        {error && error.logo ? (
          <div className='col-12 text-left'>
            <span style={{ color: 'red' }}>{error.logo}</span>
          </div>
        ) : null}

        <div className='col-12 text-left align-center mt-5'>
          <span>ภาพหน้าปกร้านค้า</span>
          {/* <span style={{ color: 'red' }}>*</span> */}
        </div>
        <div className='col-12 text-left align-center mt-3'>
          <span style={{ fontSize: 10 }}>
            ขนาดรูปแนะนำคือ 970 x 326 พิกเซล ขนาดไฟล์สูงสุดไม่เกิน 300 kb
          </span>
        </div>

        <div className='upload col-xl-10 p-3 pb-4'>
          {data?.coverPath ? (
            <div className='d-flex'>
              <span className='item-product-container'>
                <img
                  src={host.image + data.coverPath}
                  alt=''
                  className='image cover'
                  style={{ width: '100%' }}
                />
                <div className='middle' onClick={clearCover}>
                  <img
                    alt=''
                    src={closeImage}
                    className='position-absolute remove-image-product'
                  />
                </div>
              </span>
            </div>
          ) : (
            <ButtonUpload
              width='24em'
              height='12em'
              type='image'
              shopDetail={{
                ...shopDetail,
                shopId: shopDetail?.id,
              }}
              fieldUpload='coverShop'
              limitImageUpload={1}
            />
          )}
        </div>
        {error && error.cover ? (
          <div className='col-12 text-left'>
            <span style={{ color: 'red' }}>{error.cover}</span>
          </div>
        ) : null}

        <div className='col-12 mt-3'>
          <TextInput
            label='ชื่อร้านค้า'
            defaultValue={data?.name}
            handleChangeText={(text) => handleChangeText(text, 'name')}
            error={error && error.name}
            require
          />
        </div>
        <div className='col-12 mt-3'>
          <Textarea
            label='รายละเอียดร้านค้า'
            defaultValue={data?.description}
            handleChangeText={(text) => handleChangeText(text, 'description')}
            error={error && error.description}
            require
          />
        </div>

        {role === 'superadmin' || role === 'owner' ? (
          <div>
            <div className='col-12 mt-3'>
              
               <FloatInput
                name='sharingShop'
                label='ส่วนแบ่งร้านค้า'
                type='CURRENCY'
                placeholder='0.00'
                defaultValue={data?.sharingShop}
                onChangeFloatInput={(value) =>
                  onChangeSharing(value, 'sharingShop')
                }
                require
              />

            </div>

            <div className='col-12 mt-3'>
              <FloatInput
                name='sharingPlatform'
                label='ส่วนแบ่งระบบ'
                type='CURRENCY'
                placeholder='0.00'
                defaultValue={data?.sharingPlatform}
                onChangeFloatInput={(value) =>
                  onChangeSharing(value, 'sharingPlatform')
                }
                require
              />

            </div>
            <div className='col-12 mt-3'>
              <FloatInput
                name='paymentFee'
                label='ค่าธรรมเนียมการชำระเงิน'
                type='CURRENCY'
                placeholder='0.00'
                defaultValue={data?.paymentFee}
                onChangeFloatInput={(value) =>
                  onChangeFloatInput(value, 'paymentFee')
                }
                require
              />
            </div>
          </div>
        ) : null}

        {/* <div className='row'>
          <div className='col-6 mt-3'>
            <TextInput
              label='TAG'
              handleChangeText={(text) => handleChangeText(text, 'name')}
              error={error && error.name}
              require
            />
          </div>
          <div className='col-6 mt-3'>
            <DropdownWithAPI
              label='Category'
              // handleChangeText={(text) => handleChangeText(text, 'name')}
              error={error && error.name}
              require
            />
          </div>
        </div> */}
      </div>
      {isDirty() ? (
        <div className='detail-footer'>
          <button className='m-1 outline' onClick={clearData}>
            ยกเลิกแก้ไข
          </button>
          <button className='m-1' onClick={validator}>
            บันทึก
          </button>
        </div>
      ) : null}
      {isLoading && <ModalLoading isOpenModal={isLoading} />}
    </section>
  )
}

const mapStateToProps = (state) => ({
  shopDetail: state.shop.shopDetail,
  media: state.gallery,
})

export default connect(mapStateToProps)(FormShopDetail)
