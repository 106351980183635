import React, { useState, useEffect } from 'react'
import io from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'
import { convertTimeStampToFormant } from 'helper/timeUtil'
import {
  getMessage,
  sendMessage,
  uploadMedia,
  updateRoom,
} from 'provider/liveChatProvider'
import ModalPreview from './ModalPreview'
import ProductCard from './ProductCard'
import Cookies from 'js-cookie'
import { ReactComponent as IReply } from 'images/icons/icon-reply.svg'
import ArrowLrft from 'images/arrow-left.png'
import imgcloud from 'images/img-cloud.png'
import iconNoProfile from 'images/no-profile.png'
import host from 'config/host'
import 'styles/chat/chat.scss'
import linkifyHtml from 'linkifyjs/html'

const pathImage = 'https://thumb.izcene.com/saranros/'
let messageConnection = null

const ListChat = (props) => {
  const [dataUserSelected, setDataUserSelected] = useState(null)
  const [typeInput, setTypeInput] = useState('text')
  const [prevScrollHeight, setPrevScrollHeight] = useState(0)
  const [listChat, setListChat] = useState([])
  const [imagePreview, setImagePreview] = useState('')
  const [isShowModalPreview, setIsShowModalPreview] = useState(false)
  const [newMsg, setNewMsg] = useState('')
  const [msgSend, setMsgSend] = useState('')
  const [isLoadmore, setIsLoadmore] = useState(false)
  const [lastId, setLastId] = useState('')
  const [imageMsg, setImageMsg] = useState(null)
  const [isSendMsg, setIsSendMsg] = useState(false)
  const [first, setFirst] = useState(false)

  useEffect(() => {
    setListChat([])
    setDataUserSelected(null)
    setPrevScrollHeight(0)

    if (props.idSelected) {
      list({ roomId: props.idSelected }, true)
      setFirst(true)
      setDataUserSelected(props.dataUser[props.idSelected])
      connectSocket()
    }
  }, [props.idSelected])

  useEffect(() => {
    dataUserSelected && scrollToBottom()
  }, [dataUserSelected])

  useEffect(() => {
    /// handle when message incoming
    // const newArray = [...listChat, newMsg].filter((msg) => msg.mockUp !== true) //// filter here
    const isValidMsg = listChat.some((msg) => msg.msgRefId === newMsg.msgRefId)

    if (isValidMsg) {
      const newArray = listChat.map((msg, idx) => {
        if (msg.msgRefId === newMsg.msgRefId) {
          return newMsg
        } else {
          return msg
        }
      })
      setListChat(newArray)
    } else {
      setListChat([...listChat, newMsg])
    }
  }, [newMsg])

  useEffect(() => {
    /// handle listchat change

    if (listChat.length > 0) {
      const lastTagMsg = document.getElementsByClassName('wd-he-toscroll')
      const scrollHeight = lastTagMsg[0]?.scrollHeight
      const isScrollToBottom = scrollHeight - lastTagMsg[0]?.scrollTop

      setPrevScrollHeight(scrollHeight)

      if (isScrollToBottom - lastTagMsg[0]?.clientHeight <= 400 || isSendMsg) {
        setIsSendMsg(false)
        lastTagMsg[0].scrollTop = scrollHeight
      } else if (
        isLoadmore &&
        prevScrollHeight &&
        scrollHeight > prevScrollHeight
      ) {
        setIsLoadmore(false)
        const prevPosScroll = scrollHeight - prevScrollHeight
        lastTagMsg[0].scrollTop = prevPosScroll
      }

      if (!dataUserSelected) {
        setDataUserSelected(listChat[0].roomId)
      }
    }
  }, [listChat])

  const connectSocket = () => {
    if (messageConnection) messageConnection.disconnect()

    const socket = io(host.liveChat)
    messageConnection = socket.on(`${props.idSelected}`, (message) => {
      setNewMsg(message)
    })
  }

  const list = async (params, changeChat = false) => {
    const fetchMessage = await getMessage(params)

    const tmpMsg =
      listChat && !changeChat ? [...fetchMessage, ...listChat] : fetchMessage
    setListChat(tmpMsg)

    changeChat && scrollToBottom()
  }

  const scrollToBottom = () => {
    let lastTagMsg = document.getElementsByClassName('wd-he-toscroll')

    if (lastTagMsg.length) {
      lastTagMsg[0].scrollTop = lastTagMsg[0].scrollHeight
    }
  }

  const handleScrollChat = async (e) => {
    if (e.target.scrollTop === 0 && listChat.length > 0) {
      setIsLoadmore(true)
      if (lastId === listChat[0]._id) setIsLoadmore(false)
      setLastId(listChat[0]._id)
      list({ roomId: props.idSelected, lastId: listChat[0]._id })
    }
  }

  const showModalPreview = (img) => {
    setIsShowModalPreview(true)
    setImagePreview(img)
  }

  const closeModalPreview = () => {
    setIsShowModalPreview(false)
    setImagePreview('')
  }

  const onSendMessage = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      typeInput === 'text' && onClickAddData()
    }
  }

  const onSendImage = () => {
    typeInput === 'image' && onClickUploadToServer()
  }

  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader()
      const file = e.target.files[0]

      reader.onloadend = () => {
        setImageMsg({
          file: file,
          imgPreviewUrl: reader.result,
        })

        setTypeInput('image')
      }

      reader.readAsDataURL(file)
    }
  }

  const cancelSendImg = () => {
    setImageMsg(null)
    setTypeInput('text')
  }

  const onClickAddData = async () => {
    const randomRef = uuidv4().split('-')[0]
    setMsgSend('')
    setIsSendMsg(true)

    if (msgSend) {
      const mocKData = [...listChat]

      mocKData.push({
        mockUp: true,
        replyMsg: true,
        type: 'text',
        createdAt: null,
        msg: msgSend,
        msgRefId: randomRef,
      })

      setListChat(mocKData)

      const data = {
        msg: msgSend,
        type: 'text',
        shopId: dataUserSelected.shopId,
        userId: dataUserSelected.userId,
        shop: { name: dataUserSelected.shopId },
        user: { name: dataUserSelected.userId },
        sender: JSON.parse(Cookies.get('user')).username,
        replyMsg: true,
        msgRefId: randomRef,
      }

      sendMessage({
        message: data,
      })
    }
  }

  const onClickUploadToServer = async () => {
    const randomRef = uuidv4().split('-')[0]
    cancelSendImg()
    setIsSendMsg(true)

    const media = await uploadMedia(imageMsg.file)
    const urlImg = media.fileName

    const mocKData = [...listChat]

    mocKData.push({
      mockUp: true,
      replyMsg: true,
      type: 'image',
      createdAt: null,
      msg: urlImg,
      msgRefId: randomRef,
    })

    setListChat(mocKData)

    const data = {
      msg: urlImg,
      type: 'image',
      shopId: dataUserSelected.shopId,
      userId: dataUserSelected.userId,
      shop: { name: dataUserSelected.shopId },
      user: { name: dataUserSelected.userId },
      sender: JSON.parse(Cookies.get('user')).username,
      replyMsg: true,
      msgRefId: randomRef,
    }

    sendMessage({
      message: data,
    })
    // createUploadMediaLog({ //// ค่อยมาเพิ่มหลัง api เสร็จ
    //     path: upload.data.fileName,
    //     name: upload.data.originalFileName,
    //     userId: Cookies.get('user_id'),
    // })
  }

  const checkMsgType = (msgData, index, refId) => {
    if (msgData.replyMsg) {
      switch (msgData.type) {
        case 'text':
          return (
            <div key={index} className='div-msg-call-center' id={refId}>
              <div className='wrap-time'>
                {msgData.userRead ? <span>อ่านแล้ว</span> : null}
                <span>
                  {msgData.createdAt 
                  ? convertTimeStampToFormant(msgData.createdAt) 
                  : null}
                </span>
                <span>{msgData.sender || '...'}</span>
              </div>
              <div
                className='box-msg-call-center'
                dangerouslySetInnerHTML={{
                  __html: linkifyHtml(msgData.msg.replace(/[\r\n]/g, "<br>"), {
                    target: {
                      url: "_blank",
                    },
                  }),
                }}
              ></div>
            </div>
          )

        case 'image':
          return (
            <div key={index} className='div-msg-call-center' id={refId}>
              <div className='wrap-time'>
                {msgData.userRead ? <span>อ่านแล้ว</span> : null}
                <span>
                  {msgData.createdAt
                    ? convertTimeStampToFormant(msgData.createdAt)
                    : null}
                </span>
                <span>{msgData.sender || '...'}</span>
              </div>

              <div
                className='img-size'
                onClick={() =>
                  showModalPreview(pathImage + '0x900/image/' + msgData.msg)
                }
              >
                <img alt='' src={pathImage + '250x0/image/' + msgData.msg} />
              </div>
            </div>
          )

        case 'video':
          return (
            <div width='320' height='240' controls id={refId}>
              video
            </div>
          )

        case 'audio':
          return <div>audio</div>

        case 'location':
          return (
            <div className='div-msg-call-center' key={index} id={refId}>
              location
            </div>
          )

        default:
          break
      }
    } else {
      switch (msgData.type) {
        case 'text':
          return (
            <div
              className={`div-msg-customer-summary ${
                msgData.product ? 'isProductCard' : ''
              }`}
              key={index}
              id={refId}
            >
              <div className='img-customer'>
                <img
                  alt=''
                  src={dataUserSelected?.user?.profileImage || iconNoProfile}
                />
              </div>
              <div className='msg-customer'>
                <div className='box-name-customer'>
                  <span className='txt-name'>
                    {dataUserSelected?.user?.displayName}
                  </span>
                  <span className='txt-time'>
                    {convertTimeStampToFormant(msgData.createdAt)}
                  </span>
                </div>
                <div
                  className={`box-msg-customer-summary ${
                    msgData.product ? 'isProductCard' : ''
                  }`}
                >
                  {msgData.product ? (
                    <ProductCard product={msgData.product} />
                  ) : null}
                  <div
                    className='content'
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(msgData.msg.replace(/[\r\n\,]/g, '<br>'), {
                        target: {
                          url: "_blank",
                        },
                      }) ,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )

        case 'image':
          return (
            <div
              className={`div-msg-customer-summary ${
                msgData.product ? 'isProductCard' : ''
              }`}
              key={index}
              id={refId}
            >
              <div className='img-customer'>
                <img
                  alt=''
                  src={dataUserSelected?.user?.profileImage || iconNoProfile}
                />
              </div>
              <div className='msg-customer'>
                <div className='box-name-customer'>
                  <span className='txt-name'>
                    {dataUserSelected?.user?.displayName}
                  </span>
                  <span className='txt-time'>
                    {convertTimeStampToFormant(msgData.createdAt)}
                  </span>
                </div>
                <div
                  className={`box-msg-customer-summary ${
                    msgData.product ? 'isProductCard' : ''
                  }`}
                >
                  {msgData.product ? (
                    <ProductCard product={msgData.product} />
                  ) : null}
                </div>
                <div
                  className='img-size'
                  onClick={() =>
                    showModalPreview(pathImage + '0x900/image/' + msgData.msg)
                  }
                >
                  <img alt='' src={`${host.avatar}image/${msgData.msg}`} />
                </div>

                {/* <button
                  className='btn-reply'
                  onClick={() => setIsShowModalReply(true)}
                >
                  <IReply className='icon' />
                  ตอบกลับ
                </button> */}
              </div>
            </div>
            // <div className='div-msg-customer-summary' key={index} id={refId}>
            //   <div className='box-img-customer-summary'>
            //     <img alt='' src={dataUserSelected.urlImg} />
            //   </div>
            //   <div className='box-content'>
            //     <div className='img-size'>
            //       <img alt='' src={`${host.avatar}image/${msgData.msg}`} />
            //     </div>
            //     <span>{convertTimeStampToFormant(msgData.createdAt)}</span>
            //   </div>
            // </div>
          )

        // case 'sticker':
        //   return (
        //     <div className='div-msg-customer' key={index} id={refId}>
        //       sticker
        //     </div>
        //   )

        // case 'video':
        //   return (
        //     <div className='div-msg-customer' key={index} id={refId}>
        //       video
        //     </div>
        //   )

        // case 'audio':
        //   return (
        //     <div className='div-msg-customer' key={index} id={refId}>
        //       audio
        //     </div>
        //   )

        // case 'location':
        //   return (
        //     <div className='div-msg-customer' key={index} id={refId}>
        //       location
        //     </div>
        //   )

        default:
          break
      }
    }
  }

  return (
    <div className='wrap-div-chat'>
      {props.isShowChat ? (
        <div className='div-chat'>
          {dataUserSelected ? (
            <div className='customer-haeder'>
              <div className='profile'>
                <div className='status-box'>
                  <div className='social'>
                    <label>
                      {dataUserSelected.name || dataUserSelected.nickName}
                    </label>
                  </div>
                  {/* <div className='input-group'>
                    <div>
                      <button
                        className='btn btn-edit'
                        type='button'
                        onClick={() => {}}
                      >
                        <i className='icon-tag pr-1' />
                        Tags
                      </button>
                       {tagSelectShow.map((tag, index) => (
                        <span
                          key={tag.name}
                          className='span-tags'
                          style={{ backgroundColor: tag.colorId.code }}
                        >
                          {tag.name}
                        </span>
                      ))} 
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='haeder-right'>
                {/* <button className="btn btn-search"><i className="icon-search"/></button> */}
              </div>
            </div>
          ) : null}

          {dataUserSelected ? (
            <div onScroll={handleScrollChat}>
              <div className='line-haeder' />

              <div
                className={
                  dataUserSelected.announce
                    ? 'div-list-data pt-5'
                    : 'div-list-data'
                }
              >
                {/* {dataUserSelected.announce ? (
                  <div className='announce'>
                    <label className='text-announce'>
                      <span className='haeder'>ประกาศ :</span>
                      {this.props.announceMsg}
                    </label>
                  </div>
                ) : null} */}

                <div className='wd-he-toscroll chat'>
                  {listChat.length &&
                    listChat.map((data, index) =>
                      data.mockUp
                        ? checkMsgType(data, index)
                        : checkMsgType(data, index, data._id),
                    )}
                  {/* <div className="cover-bar" /> */}
                </div>
              </div>
            </div>
          ) : null}

          {/* msg box */}
          {dataUserSelected ? (
            <div className='chat-box'>
              <div
                className='input-group'
                onKeyPress={onSendMessage}
                onClick={async () =>
                  await updateRoom(props.idSelected, { read: true })
                }
              >
                {typeInput === 'text' ? (
                  <div>
                    {/* <button className='btn btn-attach' type='button'>
                      <img
                        className='imgcloud'
                        src={imgcloud}
                        alt='img-cloud'
                        // onClick={this.onChangeOpenMadalGallery}
                      />
                    </button> */}

                    <button className='btn btn-attach' type='button'>
                      <i className='icon-photo' />
                      <input type='file' onChange={handleImageChange} />
                    </button>
                  </div>
                ) : null}
                {typeInput === 'text' ? (
                  <textarea
                    type='textarea'
                    rows='4'
                    className='form-control mr-1'
                    onChange={(e) => setMsgSend(e.target.value)}
                    value={msgSend}
                  />
                ) : (
                  <div className='box-attach-img'>
                    <div className='wrap-attach'>
                      <div className='attach-img'>
                        <div className='wrap-detail'>
                          <div className='img-upload'>
                            <img alt='' src={imageMsg?.imgPreviewUrl} />
                          </div>

                          <label>{imageMsg?.file?.name}</label>
                        </div>
                        <button
                          className='btn-close'
                          type='button'
                          onClick={cancelSendImg}
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                    <span>
                      <button className='btn-reply' onClick={onSendImage}>
                        <IReply className='icon' />
                        ส่งรูป
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className='box-frist'>
          <img alt='' src={ArrowLrft} />
          <div className='box-text'>
            <label>เลือกลูกค้า </label>
            <label>เพื่อเริ่มการแชท</label>
          </div>
        </div>
      )}

      {/* ------------ */}
      <ModalPreview
        showModalPreview={isShowModalPreview}
        closeModalPreview={closeModalPreview}
        imagePreview={imagePreview}
      />
    </div>
  )
}

export default ListChat
