
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './index.scss';
import {postRerun, postsAll} from './mock'

import IconIncrease from 'images/icons/increse.svg'

import { checkPermission } from 'helper/utils';

import VodItem from 'components/VodList/VodItem';
import DropDown from 'components/DropDownList';
import ModalConfirm from 'components/modalConfirm/ModalConfirm';
import PaginationComponent from 'components/pagination';

import { dispatchModalConfirm, OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm';
import { dispatchPost, GET_POST_MEDIA_GROUP, 
         DELETE_POST_VOD,UPDATE_POST_VOD_STATUS } from 'redux/actions/post';

const _postFilterOption = [{label:"แสดงทั้งหมด", value:"all"},
                          {label:"กำลังเผยแพร่", value:"public"},
                          {label:"ปิดการเผยแพร่", value:"private"}];

const initialState = {postFilterOption: _postFilterOption,
                      postFilterSelected: _postFilterOption[0],
                      postSelected: null,
                      pageSizeOptions:["10","20","40"]
                     };

class VodList extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    this.getPostVodList();
  }

  getInitialState = () => JSON.parse(JSON.stringify(initialState));

  /* SECTION - common function */
  getPostMediaes = ({type, status, page, limit, groupName}) => 
                  this.props.dispatch( 
                  dispatchPost({ type: GET_POST_MEDIA_GROUP, 
                                  payload: { shopId: this.props.currentShop.id,
                                             type, status, page, limit, groupName} }) );

  openModalConfirm = () => this.props.dispatch( dispatchModalConfirm({ type: OPEN_MODAL_CONFIRM, payload: true }) )
  getPostVodList = () => this.getPostMediaes({groupName: 'vodList', type:['vod'], page: 1, limit: 10 });
  getPostVodByStatus = () => this.getPostMediaes({groupName: 'vodList', type:['vod'], page: 1, limit: 10, status: this.state.postFilterSelected.value  });
  deleteVod = () =>  this.props.dispatch( dispatchPost({ type: DELETE_POST_VOD, payload: { postID: this.state.postSelected.mediaId } }), )
  updateVodStatus = (status,mediaId) =>  this.props.dispatch( dispatchPost({ type: UPDATE_POST_VOD_STATUS, payload: { status, postID: mediaId } }), )
  /* !SECTION - common function */


  /* SECTION - event function */
  onFilterSelected = filterItem => this.setState({postFilterSelected: filterItem}, 
  () => {
    const {value: postFilterSelected} = this.state.postFilterSelected;
    if(postFilterSelected !== 'all')
      this.getPostVodByStatus();
    else
      this.getPostVodList();
  }) 

  onDeleteVod = postSelected => { 
    /* TODO - delete VOD */
    this.setState({postSelected}, () => this.openModalConfirm())
  }

  onVodStatusChange = ({value, mediaId}) => {
    /* TODO - update VOD status */
    const status = value === true ? 'public' : 'private';
    this.updateVodStatus(status,mediaId);
  }

  onChangePage = (page) => {
   const { limit=10 } = this.props?.vodList || {};
   this.getPostMediaes({groupName: 'vodList', type:['vod'], page, limit });
  }

  onShowSizeChange = (page, pageSize) => {
  this.getPostMediaes({groupName: 'vodList', type:['vod'], page, limit:pageSize });
  }

  /* !SECTION - event function */

  render() {
    const { postFilterOption, 
            postFilterSelected, 
            postSelected, 
            pageSizeOptions} = this.state;
    // const posts = Array.from({ length: 12 });
    const { posts=[], 
            count: total=0, 
            limit=1, 
            page=1} = this.props?.vodList || {};
    return (
      <React.Fragment>
        <div className="item-content">
          <div className="row">
            <div className="col-6">
                <h5 style={{display:"inline"}}>VOD </h5> {total} รายการ
            </div>
            <div className="col-6 text-right">
              <div className="d-flex justify-content-end">
                  {/* ANCHOR - filter list */}
                  <div className="col-6 pr-0">
                      <div className="d-flex justify-content-between align-center pr-3">
                          <label className="pr-2">แสดง</label>
                          <DropDown
                              onChange={this.onFilterSelected}
                              options={postFilterOption}
                              placeholder={'ทั้งหมด'}
                              value={postFilterSelected}
                              className={'live-filter'}
                          ></DropDown>
                      </div>
                  </div>
                  {/* ANCHOR - buttn create VOD */}
                  <div className="col-4 pl-0 cursor-pointer" 
                       onClick={ () => window.location.replace("/manage/shop/shopdetail/upload")} >
                          {checkPermission('post','create','rw') ? 
                          <React.Fragment>
                              <img src={IconIncrease} alt="" />
                              <span className="btn-text-live">สร้าง โพสต์</span>
                          </React.Fragment> : null}
                  </div>
              </div>
            </div>
          </div>
        </div>

        { posts && posts?.length > 0 &&
          <React.Fragment>
            <div className="mt-2 mb-2">
                <PaginationComponent  defaultCurrent={1}
                                      total={total}
                                      onChange={(page) => this.onChangePage(page)}
                                      pageSize={limit}
                                      pageSizeOptions={pageSizeOptions}
                                      onShowSizeChange={this.onShowSizeChange}
                                      current={page}
                                      locale={{ items_per_page: '/ หน้า' }}/>
            </div>

            { posts.map( (vod,index) => 
              <VodItem key={index} 
                      vod={vod}
                      index={index}
                      onDeleteVod={this.onDeleteVod}
                      onVodStatusChange={this.onVodStatusChange} />
            )}

            <div className="mt-2 mb-2">
                <PaginationComponent  defaultCurrent={1}
                                      total={total}
                                      onChange={(page) => this.onChangePage(page)}
                                      pageSize={limit}
                                      pageSizeOptions={pageSizeOptions}
                                      onShowSizeChange={this.onShowSizeChange}
                                      current={page}
                                      locale={{ items_per_page: '/ หน้า' }}/>
            </div>
          </React.Fragment>
        }



        <ModalConfirm message={`ต้องการลบ VOD ${postSelected?.title}`}
                      isOpen={this.props.modalConfirm}
                      confirm={this.deleteVod}
                      isClose={() => this.props.dispatch( dispatchModalConfirm(
                                     { type: OPEN_MODAL_CONFIRM, payload: false, }), ) } />
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
    modalConfirm: state.modalConfirm.modalConfirm,
    postMediaes: state.post?.postMediaes,
    vodList: state.post?.postMediaes?.vodList,
    currentShop: state.shop?.currentShop,
  })
  
  export default connect(mapStateToProps)(VodList)