import playlistsProvider from '../../provider/playlistsProvider'
import { takeLatest, call, put, select, take } from 'redux-saga/effects'

import {
  GET_PLAYLISTS,
  SET_PLAYLISTS,
  CREATE_PLAYLISTS,
  UPDATE_PLAYLISTS,
  DELETE_PLAYLISTS,
  GET_PLAYLISTS_ITEMS,
  SET_PLAYLISTS_ITEMS,
  DELETE_PLAYLISTS_ITEM,
  SHUFFLE_PLAYLISTS_ITEMS,
  CREATE_PLAYLISTS_ITEM,
  PIN_PLAYLISTS_ITEMS,
  UPDATE_ORDER_PLAYLISTS,
  UPDATE_ORDER_PLAYLISTS_ITEMS,
} from '../actions/playlists'

export function* onFetchPlaylists(action) {
  try {
    const playlists = yield call(playlistsProvider.getPlaylists)
    yield put({ type: SET_PLAYLISTS, payload: playlists })
  } catch (err) {
    throw err
  }
}

export function* onCreatePlaylists(action) {
  const { payload } = action
  try {
    const response = yield call(playlistsProvider.createPlaylists, payload)
    yield put({ type: GET_PLAYLISTS })
  } catch (err) {
    throw err
  }
}

export function* onUpdatePlaylists(action) {
  const { payload } = action
  try {
    const response = yield call(playlistsProvider.updatePlaylists, payload)
    yield put({ type: GET_PLAYLISTS })
  } catch (err) {
    throw err
  }
}

export function* onDeletePlaylists(action) {
  const { payload: playlistsId } = action
  try {
    yield call(playlistsProvider.deletePlaylists, playlistsId)
    yield put({ type: GET_PLAYLISTS })
  } catch (err) {
    throw err
  }
}

export function* onFetchPlaylistsItems(action) {
  const {
    payload: { playlistsId, limit, page },
  } = action
  try {
    const playlistItems = yield call(
      playlistsProvider.getPlaylistItemsByPlaylistId,
      { playlistsId, limit, page },
    )
    yield put({ type: SET_PLAYLISTS_ITEMS, payload: playlistItems })
  } catch (err) {
    throw err
  }
}

export function* onDeletePlaylistsItem(action) {
  const {
    payload: { playlistsId, playlistsItemId },
  } = action
  try {
    yield call(playlistsProvider.deletePlaylistsItem, playlistsItemId)
    yield put({
      type: GET_PLAYLISTS_ITEMS,
      payload: { playlistsId: playlistsId, limit: 1000, page: 1 },
    })
  } catch (err) {
    throw err
  }
}

export function* onCreatePlaylistItem(action) {
    const { payload: { productId, playlistId} } = action
    try {
      const data = yield call(playlistsProvider.createPlaylistItem, { productId, playlistId })
      yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistId, limit:1000, page: 1 } })
    } catch (err) {

        throw err
    }
}

export function* onShufflePlaylistItem(action) {
  const { payload: { playlistsId } } = action
  try {
    yield call(playlistsProvider.shufflePlaylistsItem, playlistsId)
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit:100000000, page: 1 } })
  } catch (err) {
    throw err
  }
}

export function* onClickPin(action) {
  const { payload: { playlistsItemId, pinDate, playlistsId } } = action
  try {
    yield call(playlistsProvider.pinPlaylistsItem, { playlistsItemId, pinDate: pinDate })
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit:100000000, page: 1 } })
  } catch (err) {
    throw err
  }
}

export function* onChangePositionOrder(action){
  try {
    const { payload } = action
    const response = yield call(playlistsProvider.updateOrderPlaylists, payload.playlistsId, { order: payload.order })
    yield put({ type: GET_PLAYLISTS })
  } catch (error) {
    throw error
  }
}

export function* onChangePositionOrderPlaylistItems(action){
  try {
    const { payload: { playlistItemId, order, playlistsId } } = action
    const response = yield call(playlistsProvider.updateOrderPlaylistsItems, playlistItemId, { order: order })
    yield put({ type: GET_PLAYLISTS_ITEMS, payload: { playlistsId: playlistsId, limit:100000000, page: 1 } })
  } catch (error) {
    throw error
  }
}

export default function* useWatcher() {
  yield takeLatest(GET_PLAYLISTS, onFetchPlaylists)
  yield takeLatest(CREATE_PLAYLISTS, onCreatePlaylists)
  yield takeLatest(UPDATE_PLAYLISTS, onUpdatePlaylists)
  yield takeLatest(DELETE_PLAYLISTS, onDeletePlaylists)
  yield takeLatest(GET_PLAYLISTS_ITEMS, onFetchPlaylistsItems)
  yield takeLatest(CREATE_PLAYLISTS_ITEM, onCreatePlaylistItem)
  yield takeLatest(DELETE_PLAYLISTS_ITEM, onDeletePlaylistsItem)
  yield takeLatest(SHUFFLE_PLAYLISTS_ITEMS, onShufflePlaylistItem)
  yield takeLatest(PIN_PLAYLISTS_ITEMS, onClickPin)
  yield takeLatest(UPDATE_ORDER_PLAYLISTS, onChangePositionOrder)
  yield takeLatest(UPDATE_ORDER_PLAYLISTS_ITEMS, onChangePositionOrderPlaylistItems)
}
