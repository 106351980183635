import { getClient, chatClient } from './client'
import host  from '../config/host'

class ApiClass {
  constructor({ baseUrl = host.api , restPath = null  } ) {
    if(baseUrl === host.chat){
      this.client = chatClient(baseUrl)
    }else{
      this.client = getClient(baseUrl);
    }
    this.restPath  = restPath
  }

  get({url = this.restPath, path="", conf = {}} = {}) {
    return this.client.get(url+path, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  delete({url =this.restPath, path="",   conf = {}} = {} ) {
    return this.client.delete(url+path, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  head({url =this.restPath, conf = {}} = {}) {
    return this.client.head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  options({url = this.restPath, conf = {}} = {}) {
    return this.client.options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post({url = this.restPath, path="", data = {}, conf = {}} = {} ) {
    return this.client.post(url+path, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  put({url = this.restPath, path="" ,  data = {}, conf = {}} = {}) {
    return this.client.put(url+path, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  patch({url =this.restPath, path="",  data = {}, conf = {}} = {}) {
    return this.client.patch(url+path, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}

export default ApiClass;
