import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconClose from "images/icons/close.svg";
import "./index.scss";
import host from "config/host";

import ModalAlert from 'components/modalAlert/ModalAlert'
import DropDown from 'components/DropDownList'
import Loading from '../Loading'

import { dispatchModalConfirm } from 'redux/actions/modalConfirm'
import { OPEN_MODAL_CONFIRM } from 'redux/actions/modalConfirm'
import { dispatchPost, 
         SET_MODAL_RERUN_LIVE,
         CREATE_RERUN_LIVE,
         CREATE_POST_RERUN,
         UPDATE_POST_RERUN } from 'redux/actions/post';
import { SETTING_MENU_SIDEBAR, SET_LOADING, dispatchApp } from 'redux/actions/app'
import { OPEN_MODAL_ALERT, dispatchModalAlert } from 'redux/actions/modalAlert';
import { GET_VIDEO_SOURCE, dispatchLive } from 'redux/actions/live';

import { getUserRole } from "helper/utils";
import { ADMIN, OWNER } from "layout/Sidebar/Role";

import TimePicker from 'components/Timepicker'
import DatePickerInput from 'components/DatePickerInput'
import moment from 'moment'

const initialState = {
  releaseDate: {
    date: null,
    time: null,
  },
  liveTitle: '',
  errors: {},
  videoSourceSelected: null
}

class ModalRerunLive extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    this.initComponent();
  }

  componentDidUpdate = prevProps => {
    if(prevProps.modalRerunLive.isOpen !== this.props.modalRerunLive.isOpen)
    {
      if(this.props.modalRerunLive.isOpen === true) {
        // this.props.dispatch(dispatchLive({ type: GET_VIDEO_SOURCE ,payload: {}}))
        this.initComponent();
        const {mode} = this.props.modalRerunLive;
        /* NOTE - init form for update */
        if(mode==='update') {
          const { title:liveTitle, releaseDate: _releaseDate } = this.props.modalRerunLive.currentLive;
          const time = moment(_releaseDate);
          const date = new Date(_releaseDate);
          const releaseDate = {date,time};
          this.setState({liveTitle,releaseDate});
        }
      } else {
        /* clear after close modal */
        this.getInitialState();
      }
    }
  }

  /* SECTION - common function */
  getInitialState = () => {
    let state = JSON.parse(JSON.stringify(initialState))
    var time = moment().utcOffset(0)
    time.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    state.releaseDate.date = moment().format('MM/DD/YYYY')
    state.releaseDate.time = time
    return state
  }

  initComponent = () => {
    this.props.dispatch(dispatchLive({ type: GET_VIDEO_SOURCE ,payload: {}}));
    const { type, title } = this.props?.modalRerunLive?.currentLive || {};
    if (type === 'vod') {
      this.setState({liveTitle: title});
    }
  }

  reset = () => this.setState(this.getInitialState())
  
  toggleModal = () => {
    this.reset();
    this.props.dispatch(dispatchPost({ type: SET_MODAL_RERUN_LIVE, payload: { key:'isOpen', value: false } }))
  }

  loading = () => this.props.dispatch(dispatchApp({ type: SET_LOADING ,payload: { loading: true }}))

  createRerunLive = () => {

    const { id, cover, shopId, postId, mediaId } = this.props.modalRerunLive.currentLive;
    const { mode } =  this.props.modalRerunLive;
    const { liveTitle, releaseDate, videoSourceSelected } = this.state;
    const { date, time } = releaseDate;

    const dateString = moment(date).format('MM/DD/YYYY')
    const timeString = moment(time).format('HH:mm')
    const _releaseDate = new Date(`${dateString} ${timeString}`).toISOString()

    // const payload = { // coverPathId: cover.id, // type:"live", // shopId, // title: liveTitle, // releaseDate: _releaseDate, // rePostId:id, // rePostSourceMP4: videoSourceSelected.value }

    const payload = {
      postId,
      postMediaRefId: mediaId,
      releaseDate:  _releaseDate
    }

    if(mode === 'create')
      this.props.dispatch(dispatchPost({ type: CREATE_POST_RERUN, payload }))
    if(mode === 'update')
      this.updateRerun();
  }

  validateForm = () => {
    const {releaseDate, liveTitle, videoSourceSelected} = this.state;
    let errors = {}
    if (liveTitle === '') errors.liveTitle = true;
    // if (!videoSourceSelected) errors.videoSourceSelected = true;

    this.setState({errors})

    return Object.keys(errors).length > 0 ? false : true;
  }

  updateRerun = () => {
    const { mediaId } = this.props.modalRerunLive.currentLive;
    const { liveTitle: title, releaseDate: _releaseDate } = this.state;
    const dateString = moment(_releaseDate.date).format('MM/DD/YYYY')
    const timeString = moment(_releaseDate.time).format('HH:mm')
    const releaseDate = new Date(`${dateString} ${timeString}`).toISOString()

    this.props.dispatch( dispatchPost({ type: UPDATE_POST_RERUN, 
                                        payload: {postID: mediaId,param:{title,releaseDate} } }), )
  }

  /* !SECTION - common function */

  /* SECTION - event function */
  onElementChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  onDateDatePickerInputChange = (date) => {
    this.setState({ releaseDate: { ...this.state.releaseDate, date } })
  }

  onVideoSourceChange = (item) =>{
    this.setState({videoSourceSelected: item})
  }

  onTimePickerChange = (time) => {
    const _time = moment(time)
    if (!_time.isValid()) {
      let timeTmp = moment().utcOffset(0)
      timeTmp.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      this.setState({
        releaseDate: { ...this.state.releaseDate, time: timeTmp },
      })
    } else {
      this.setState({ releaseDate: { ...this.state.releaseDate, time } })
    }
  }

  onCreateLiveRerun = () => {

    if(this.validateForm()) 
      this.createRerunLive();
    else
      this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, 
                                               payload: { isOpen: true, message: 'กรุณากรอกข้อมูลให้ครบ', 
                                               type: 'alert' } }))

  }
  onUpdateLiveRerun = () => {

    if(this.validateForm()) 
      this.createRerunLive();
    else
      this.props.dispatch(dispatchModalAlert({ type: OPEN_MODAL_ALERT, 
                                               payload: { isOpen: true, message: 'กรุณากรอกข้อมูลให้ครบ', 
                                               type: 'alert' } }))

  }
  /* !SECTION - event function */

  render() {

    const {modalRerunLive, videoSources} = this.props;
    const { mode } = modalRerunLive;
    const {releaseDate, liveTitle, errors, videoSourceSelected} = this.state;
    const { date, time } = releaseDate;

    return (
      <React.Fragment>
        <Modal
          isOpen={modalRerunLive.isOpen}
          toggle={this.toggle}
          backdrop="static"
          style={{ width: "80%", maxWidth: "80%" }}
        >
          <ModalHeader toggle={this.toggle}>
            { mode === 'create' && <span className="header-lable"> สร้างรีรัน </span>}
            { mode === 'update' && <span className="header-lable"> แก้ไขรีรัน </span>}
            <span className="header-lable"> สร้างรีรัน </span>
            <div className="close-btn-wrap">
              <img src={IconClose} onClick={this.toggleModal} />
            </div>
          </ModalHeader>
          <ModalBody>
          <Loading />
            <div className="container">
              {/* ANCHOR [general live data] */}
              <div className="general-live-container">
                <div className="upload col-xl-10 m-auto p-3 pb-4">
                  <h3>ข้อมูลทั่วไป</h3>
                    <span> ชื่อ LIVE 
                        <span style={{ color: "red" }}>* </span>
                        <span className="text-detail"> (จำกัด 200 ตัวอักษร)</span>
                    </span>
                  <br />
                  <input
                    type="text"
                    // className={`form-control ${ errors?.liveTitle ? "error-field" : "" }`}
                    className={`form-control`}
                    name="liveTitle"
                    value={liveTitle}
                    onChange={(e) => this.onElementChange(e)}
                  />
                  <span className="error-message">
                    {errors?.liveTitle ? "* กรุณากรอกชื่อ Live" : ""}
                  </span>
                </div>
              </div>

              {/* ANCHOR [date time] */}
              <div className="date-live-container">
                <div className="col-xl-10 m-auto p-3">
                  <h3>วันที่ เวลาที่ LIVE</h3>
                  <div className="row mt-4 font-primary font-size-12px font-weight-light d-flex flex-wrap">
                    <div className="col-5 mr-4">
                      <span>
                        วันที่ <span style={{ color: "red" }}>*</span>
                      </span>
                      <br />
                      <DatePickerInput
                        date={moment(date,"MM/DD/YYYY").format("DD/MM/YYYY")}
                        onSubmitDateSelected={this.onDateDatePickerInputChange}
                      />
                    </div>
                    <div className="col-5">
                      <span>
                        เวลา <span style={{ color: "red" }}>*</span>
                      </span>
                      <br />
                      <TimePicker
                        onTimePickerChange={this.onTimePickerChange}
                        value={time}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* ANCHOR [video source] */}
              {/* <div className="date-live-container">
                <div className="col-xl-10 m-auto p-3">
                <h3>ข้อมูล Video</h3>
                <div className="row mt-4 font-primary font-size-12px font-weight-light d-flex flex-wrap">
                    <div className="col-5 mr-4">
                      <DropDown
                        onChange={this.onVideoSourceChange}
                        options={videoSources}
                        placeholder={'เลือกวิดีโอ'}
                        value={videoSourceSelected}
                        className={'add-product-select'}
                      ></DropDown>
                      <span className="error-message mt-2">
                        {errors?.videoSourceSelected ? "* กรุณาเลือกวิดีโอ" : ""}
                      </span>
                    </div>
                </div>
                </div>
              </div> */}


                {/* ANCHOR submit form */}
                <div className='d-flex justify-content-center'>
                  <span className='m-4'>
                    <button
                      className='btn-cancel'
                      onClick={this.toggleModal} 
                    >
                      ยกเลิก
                    </button>
                  </span>
                  <span className='m-4'>
                    <button
                      className='btn-submit'
                      data-toggle='modal'
                      data-target='#exampleModal'
                      onClick={this.onCreateLiveRerun}
                    >
                      { mode === 'create' && <span className="header-lable"> สร้าง </span>}
                      { mode === 'update' && <span className="header-lable"> แก้ไข </span>}

                    </button>
                  </span>
                </div>

            </div>
          </ModalBody>
        </Modal>

        <ModalAlert />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  modalRerunLive: state.post.modalRerunLive,
  modalConfirm: state.modalConfirm.modalConfirm,
  videoSources: state.live.videoSources
});

export default connect(mapStateToProps)(ModalRerunLive);
