import { 
    SET_GALLERY_FIELD,
    SET_SHOP_GALLERY,
    SET_ALBUM_GALLERY,
    SET_MEDIA_GALLERY,
    SET_MEDIA_UPLOAD_EVENT,
} from '../actions/gallery'


const initialState = {
  shopData: null,
  albumData: null,
  mediaData: null,
  albumDefault: null,
  isUploading: false
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_GALLERY_FIELD: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case SET_SHOP_GALLERY: {
            return {
                ...state,
                shopData: payload
            }
        }

        case SET_ALBUM_GALLERY: {
            return {
                ...state,
                albumData: payload
            }
        }

        case SET_MEDIA_GALLERY: {
            return {
                ...state,
                mediaData: payload,
                isUploading: false
            }
        }
        case SET_MEDIA_UPLOAD_EVENT: {
            if (payload.field === 'avatar') {
                return {
                  ...state,
                  [payload.field]: [payload.data],
                }
            } else {
              return {
                ...state,
                [payload.field]: state[payload.field]
                  ? [...state[payload.field], payload.data]
                  : [payload.data],
              }
            }
            
        }


        default: return state
    }

}