// // User Type

// export const SUPERADMIN = 'superadmin';
// export const ADMIN = 'admin';
// export const CALLCENTER = 'callcenter';
// export const ACCOUNT = 'account';

// // MANAGE
// export const PERMISSION_MANAGE_SHOP = [SUPERADMIN, ADMIN];

// // HEADER BAR
// export const PERMISSION_HEADER_MANAGE = [SUPERADMIN, ADMIN];
// export const PERMISSION_HEADER_USER = [SUPERADMIN, ADMIN];
// export const PERMISSION_HEADER_REPORT = [SUPERADMIN, ADMIN];

const ADMIN = [
  '/manage/shop',
  '/manage/product',
  '/manage/add-product',
  '/manage/shop/shopdetail/managelive',
  '/manage/shop/shopdetail',
  '/manage/shop/shopdetail/createlive',
  '/manage/shop/shopdetail/editlive',
  '/manage/shop/shopdetail/editvod',
  '/manage/shop/shopdetail/upload',
  '/manage/shop/addshop',
  '/manage/create-live',
  '/manage/editlive',
  '/manage/product',
  '/manage/add-product',
  '/manage/order',
  '/manage/order/:slug',
  '/gallery',
  '/gallery/albums',
  '/gallery/albums/media',
  '/manage/dashboard',
  '/manage/playlists',
  '/manage/playlists/items',
  '/manage/playlist-front-page',
  '/manage/playlist-front-page/page',
  '/manage/order/waitShipping',
  '/manage/order/onTheWay',
  '/manage/order/success',
  '/manage/order/waitRefund',
  '/manage/order/refunded',
  '/manage/order/deliveryDelayed',
  '/manage/promocode',
  '/manage/promocode/addpromocode',
  '/manage/promocode/comingsoon',
  '/manage/promocode/processing',
  '/manage/promocode/expired',
  '/manage/shop/shopdetail/addlogisticprice',
  '/chat',
  // '/chat',
  '/manage/ribbon'
]
const SUPER_ADMIN = [
  '/manage/shop',
  '/manage/product',
  '/manage/add-product',
  '/manage/shop/shopdetail/managelive',
  '/manage/shop/shopdetail',
  '/manage/shop/shopdetail/createlive',
  '/manage/shop/shopdetail/editlive',
  '/manage/shop/shopdetail/editvod',
  '/manage/shop/shopdetail/upload',
  '/manage/shop/addshop',
  '/manage/create-live',
  '/manage/product',
  '/manage/add-product',
  '/manage/order',
  '/manage/order/:slug',
  '/gallery',
  '/gallery/albums',
  '/gallery/albums/media',
  '/manage/dashboard',
  '/manage/playlists',
  '/manage/playlists/items',
  '/manage/playlist-front-page',
  '/manage/playlist-front-page/page',
  '/manage/order/waitShipping',
  '/manage/order/onTheWay',
  '/manage/order/success',
  '/manage/order/waitRefund',
  '/manage/order/refunded',
  '/manage/order/deliveryDelayed',
  '/manage/promocode',
  '/manage/promocode/addpromocode',
  '/manage/promocode/comingsoon',
  '/manage/promocode/processing',
  '/manage/promocode/expired',
  '/manage/shop/shopdetail/addlogisticprice',
  '/chat',
  '/chat-summary',
  // '/chat',
  // '/chat-summary',
  '/manage/ribbon',
  '/manage/withdraw',
]

const OWNER = [
  '/manage/product',
  '/manage/add-product',
  '/manage/order',
  '/manage/withdraw',
  '/manage/order/waitShipping',
  '/manage/order/onTheWay',
  '/manage/order/success',
  '/manage/order/waitRefund',
  '/manage/order/refunded',
  '/manage/order/deliveryDelayed',
  '/manage/promocode',
  '/manage/promocode/addpromocode',
  '/manage/promocode/comingsoon',
  '/manage/promocode/processing',
  '/manage/promocode/expired',
  '/chat',
  '/manage/ribbon'
  // '/chat',
]

export default {
  ADMIN,
  OWNER,
  SUPER_ADMIN,
}
