export const OPEN_MODAL_ALERT = "OPEN_MODAL_ALERT"


export function dispatchModalAlert(param){
    return {
        type: param.type,
        payload: param.payload
    }
}

