import React, { useState, useEffect } from 'react'
import Switch from 'react-switch'
import { Input, FormGroup } from 'reactstrap'
import ModalCustomer from './ModalCustomer'
import 'styles/chat/sideBarDetail.scss'

let scrollY = 0

const SideBarDetail = (props) => {
  const {
    dataUser,
    idSelected,
    isShowChat,
    pin,
    setPin,
    announce,
    setAnnounce,
  } = props

  const [dataUserSelected, setDataUserSelected] = useState(null)

  const [detailUser, setDetailUser] = useState(false)
  const [isShowModalCreate, setIsShowModalCreate] = useState(false)
  const [isShowModalInvoice, setIsShowModalInvoice] = useState(false)
  const [isShowModalListAddress, setIsShowModalListAddress] = useState(false)
  const [note, setNote] = useState('')

  useEffect(() => {
    if (dataUser[idSelected]) {
      setDataUserSelected(dataUser[idSelected])
    }
  }, [dataUser, idSelected])

  const handleScroll = (e) => {
    if (document.getElementById('btn-arrow')) {
      if (e.target.scrollTop < 6000 && e.target.scrollTop > scrollY) {
        document.getElementById('btn-arrow').style.display = 'none'
      } else {
        document.getElementById('btn-arrow').style.display = 'inline'
      }

      scrollY = e.target.scrollTop
    }
  }

  const setDefault = () => {}

  const onClickDetailUser = () => {
    setDetailUser(!detailUser)
  }

  const showModalCreate = () => {
    setIsShowModalCreate(true)
  }

  const closeModalCreate = () => {
    setIsShowModalCreate(false)
  }

  const onSaveNote = () => {}

  const updateDataUser = () => {}

  const showModalListAddress = async (edit) => {
    // let user

    // if (dataUserSelected.platform === 'mobile') {
    //   const userData = await api_usersCtrl.autoUserByMobile({
    //     displayName: dataUserSelected.name || 'mockName',
    //     mobile: dataUserSelected.mobile,
    //   })

    //   user = userData.data
    // } else {
    //   user = await api_usersCtrl.autoUserByLineIdOnly({
    //     displayName: dataUserSelected.name,
    //     lineId: this.props.id,
    //   })
    // }

    // this.setState({
    //   isShowModalListAddress: true,
    //   userId: user.id,
    //   displayName: user.displayName,
    //   editAddress: edit,
    // })
    setIsShowModalListAddress(true)
  }

  const handleChangePin = () => {
    // axios({
    //   method: 'PATCH',
    //   url: `${api.SOCKET_API}/user`,
    //   data: {
    //     userId: this.props.id,
    //     pin: !this.props.pin,
    //   },
    // })
    setPin(!pin)
  }

  const handleChangeAnnounce = () => {
    // axios({
    //   method: 'PATCH',
    //   url: `${api.SOCKET_API}/user`,
    //   data: {
    //     userId: this.props.id,
    //     announce: !this.props.announce,
    //   },
    // })
    setAnnounce(!announce)
  }

  return (
    <div
      // onMouseEnter={() => window.addEventListener('scroll', handleScroll, true)}
      // onMouseLeave={setDefault}
    >
      {dataUserSelected && isShowChat ? (
        <div
          className={detailUser ? 'div-side-bar-open' : 'div-side-bar-close'}
        >
          <button
            id='btn-arrow'
            className={detailUser ? 'btn btn-open' : 'btn btn-close'}
            onClick={onClickDetailUser}
          >
            <i className='icon-back' />
          </button>
          {detailUser ? (
            <div className='wrap-side-bar'>
              <div className='wrap-header'>
                <div className='row-center'>
                  <div className='box-avatar'>
                    <img
                      alt=''
                      src={
                        dataUserSelected.urlImg ||
                        'https://thumb.izcene.com/saranros/image/ab8fd127-0b30-43f7-b8e3-0af4aee56f56.png'
                      }
                    />
                  </div>
                </div>
                <label className='name'>{dataUserSelected.name}</label>
                <div className='row-center'>
                  {/* <Link to={"/order-history/" + this.state.userId} className="btn btn-primary-sr view-history">ดูประวัติการสั่งซื้อ</Link> */}
                  {/* <button className="btn btn-primary-sr view-history ml-2" onClick={this.showModalInvoice}>ยืนยันการโอนเงิน</button> */}
                </div>

                <div className='wrap-info'>
                  <div className='wrap-content'>
                    <div className='haeder'>
                      <label className='info-haeder'>ข้อมูลลูกค้า</label>
                      <button
                        className='btn btn-edit'
                        onClick={showModalCreate}
                      >
                        <i className='icon-edit' />
                      </button>
                    </div>
                    {dataUserSelected.address ||
                    dataUserSelected.nickName ||
                    dataUserSelected.mobile ? (
                      <div>
                        <p className='info'>{dataUserSelected.nickName}</p>
                        <p className='info'>{dataUserSelected.mobile}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className='wrap-setting'>
                <div className='row-setting'>
                  <label className='text-setting'>ปักหมุดแชท</label>
                  <label htmlFor='small-radius-switch-pin' className='mb-0'>
                    <Switch
                      checked={pin}
                      onChange={handleChangePin}
                      handleDiameter={18}
                      offColor='#828282'
                      onColor='#004EC2'
                      offHandleColor='#ffffff'
                      onHandleColor='#ffffff'
                      height={22}
                      width={42}
                      className='react-switch'
                      id='small-radius-switch-pin'
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </label>
                </div>
              </div>

              <div className='wrap-setting'>
                <div className='row-setting'>
                  <label className='text-setting'>ประกาศข้อความ</label>
                  <label htmlFor='small-radius-switch-annouce' className='mb-0'>
                    <Switch
                      checked={announce}
                      onChange={handleChangeAnnounce}
                      handleDiameter={18}
                      offColor='#828282'
                      onColor='#004EC2'
                      offHandleColor='#ffffff'
                      onHandleColor='#ffffff'
                      height={22}
                      width={42}
                      className='react-switch'
                      id='small-radius-switch-annouce'
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </label>
                </div>
                {/* <FormGroup>
                  <Input
                    type='textarea'
                    onChange={(e) =>
                      this.setState({ announceMsg: e.target.value })
                    }
                    onBlur={(e) => this.onSaveAnnounce(e.target.value)}
                    value={this.state.announceMsg}
                  />
                </FormGroup> */}
              </div>

              <div className='wrap-setting'>
                <div className='row-setting'>
                  <label className='text-setting'>เพิ่มโน้ต</label>
                  <button
                    className='btn-sm btn-primary-sr'
                    onClick={onSaveNote}
                  >
                    บันทึก
                  </button>
                </div>
                <FormGroup>
                  <Input
                    type='textarea'
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                  />
                </FormGroup>
              </div>
              <div className='cover-bar' />
            </div>
          ) : null}
        </div>
      ) : null}

      <ModalCustomer
        showModalCreate={isShowModalCreate}
        closeModalCreate={closeModalCreate}
        title='ข้อมูลลูกค้า'
        id={idSelected}
        dataUser={dataUserSelected}
        updateDataUser={updateDataUser}
      />

      {/* <ModalManageInvoice
        showModalInvoice={this.state.isShowModalInvoice}
        closeModalInvoice={this.closeModalInvoice}
        id={
          this.props.platform == 'mobile'
            ? dataUserSelected.mobile
            : this.props.id
        }
        name={
          dataUserSelected
            ? dataUserSelected.name
            : ''
        }
        platform={
          dataUserSelected
            ? dataUserSelected.platform
            : ''
        }
      /> */}

      {/* <ModalListAddress
        isOpen={this.state.isShowModalListAddress}
        closeModal={this.closeModalListAddress}
        userId={this.state.userId}
        userLineId={this.state.lineId}
        editAddress={this.state.editAddress}
        valueAddress={this.state.valueAddress}
        listDistricts={this.state.listDistricts}
        listSubDistricts={this.state.listSubDistricts}
        refresh={this.getAddress}
        platform={this.props.platform}
      /> */}
    </div>
  )
}

export default SideBarDetail
