import React from 'react'
import './styles.scss'
import host from '../../../config/host'

const ShopStorage = (props) => {
  const {
    detail: {
      logoPath,
      storage: { used, max },
      name, 
    },
  } = props

  return (
    <div className='shop-storage'>
      <div className='group-header'>
        <img alt='' className='avatar' src={`${host.avatar}${logoPath}`} />
        <div className='text-name'>{name}</div>
      </div>
      <div className='group-storage'>
        <div className='storage'>
          <div className='label'>พื้นที่จัดเก็บ</div>
          <div className='text-storage'>{`${used}/${max} MB`}</div>
        </div>
        <div className='progress-box'>
          <div
            className='progress'
            style={{ width: `${(used * 100) / max}%` }}
          />
        </div>
      </div>
    </div>
  )
}

export default ShopStorage
