import { Input, Radio } from 'antd'
import LogoSelect from 'components/LogoSelect'
import { divide, isEmpty } from 'lodash'
import React, { Component } from 'react'
import 'styles/_addlogisticprice.scss'
import { ReactComponent as IconTrash } from 'images/icons/trash.svg'
import ModalConfirm from 'components/modalConfirm/ModalConfirm'
import {
    CREATE_LOGISTIC_PRICE, dispatchLogisticPrice, GET_LIST_ADD_LOGISTIC, SET_DEFAULT_LOGISTIC_PRICE, SET_EDIT_SHOP_LOGISTIC, SET_KEY_ADD_LOGISTIC_PRICE,
    SET_PRICE_LOGISTIC_PRICE, UPDATE_LOGISTIC_PRICE, HANDLE_ADD_LOGISTIC_DELETED, GET_SHOP_RATE_LOGISTIC_BY_ID, SET_MODAL_CONFIRM
} from 'redux/actions/logisticPrice'
import { connect } from 'react-redux';
import classnames from 'classnames'
import { isEmptyOrSpaces, setLocalStorage, textIsEmpty } from 'helper/utils'
import logistic from 'redux/reducers/logistic'
import ModalConfirmChildren from 'components/ModalConfirmChildren'
import { ReactComponent as IconSuccess } from 'images/icons/success.svg'
import { dispatchLogistic } from 'redux/actions/logistic'
import logisticPrice from 'redux/reducers/logisticPrice'


class AddLogisticPrice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            err: {
                start: [],
                price: [],
                end: [],
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(dispatchLogisticPrice({ type: GET_LIST_ADD_LOGISTIC }))
        if (this.props.location.state != undefined) {
            this.props.dispatch(dispatchLogisticPrice({ type: GET_SHOP_RATE_LOGISTIC_BY_ID, payload: { id: this.props.location.state.id } }))
            // this.props.dispatch(dispatchLogisticPrice({ type: SET_EDIT_SHOP_LOGISTIC, payload: this.props.location.state }))
        }
    }

    componentWillUnmount() {
        this.props.dispatch(dispatchLogisticPrice({ type: SET_DEFAULT_LOGISTIC_PRICE }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_MODAL_CONFIRM, payload: { isOpen: false } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'name', value: '' } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { 
        //     key: 'logisticSelected', 
        //     value: this.props.logisticPrice.listLogistics.find(item => item.code === 'DHL') 
        // } }))
        // this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'price', value: [ {
        //     start: 1,
        //     end: null,
        //     price: null,
        //     rateType: 'flat',
        //     status: 'active',
        // }] } }))
    }

    static getDelivedFromProps(props) {
        if (this.props.logisticPrice !== props.logisticPrice) {
            return {
                logisticPrice: props.logisticPrice
            }
        }
    }


    onChangeLogisticSelect = (logistic) => {
        //undefined === 'create'  ||  !undefined === "edit"
        if (this.props.location.state == undefined) {
            this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'logisticSelected', value: logistic } }))
        }

    }

    onChangeName = (name) => {
        if(name.trim().length === 60) return
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'name', value: name } }))
        this.setState({
            err: {
                ...this.state.err,
                name: false
            }
        })
    }

    handleAddPriceLogistic = () => {
        let price = this.props.logisticPrice.price
        const data = {
            start: price[price.length - 1].end + 1,
            end: null,
            price: null,
            status: 'active',
            rateType: 'flat'
        }
        price.push(data)
        this.props.dispatch(dispatchLogisticPrice({ type: SET_KEY_ADD_LOGISTIC_PRICE, payload: { key: 'price', value: price } }))
    }

    handleDeletePrice = (idx) => {
        let price = this.props.logisticPrice.price
        const priceRemove = this.props.logisticPrice.price[idx]
        this.props.dispatch(dispatchLogisticPrice({ type: HANDLE_ADD_LOGISTIC_DELETED, payload: priceRemove }))
        price.splice(idx, 1)
        // price[idx].start = priceRemove.start
        if (price[idx]?.start) {
            price[idx].start = price[idx - 1].end + 1
        }
        this.props.dispatch(dispatchLogisticPrice({
            type: SET_KEY_ADD_LOGISTIC_PRICE, payload: {
                key: 'price',
                value: price
            }
        }))
    }

    onChangePrice = (name, value, idx) => {
        this.props.dispatch(dispatchLogisticPrice({
            type: SET_PRICE_LOGISTIC_PRICE, payload: {
                name: name,
                value: value || '',
                idx: idx
            }
        }))
    }

    onChangeLogisticRateType = (e, idx) => {
        this.props.dispatch(dispatchLogisticPrice({
            type: SET_PRICE_LOGISTIC_PRICE, payload: {
                name: e.target.name,
                value: e.target.value,
                idx: idx
            }
        }))
    }

    onBlurChangePrice = (name, value, idx) => {
        let logisticPrice = this.props.logisticPrice.price
        for (let index = 0; index < logisticPrice.length; index++) {
            const element = logisticPrice[index];
            for (let indx = 0; indx < index; indx++) {
                const elem = logisticPrice[indx];
                if (!isNaN(logisticPrice[index - 1].end)) {
                    this.props.dispatch(dispatchLogisticPrice({
                        type: SET_PRICE_LOGISTIC_PRICE, payload: {
                            name: 'start',
                            value: logisticPrice[index - 1].end + 1,
                            idx: index
                        }
                    }))
                    if (logisticPrice[index - 1].end + 1 > element.end) {
                        this.props.dispatch(dispatchLogisticPrice({
                            type: SET_PRICE_LOGISTIC_PRICE, payload: {
                                name: 'end',
                                value: null,
                                idx: index
                            }
                        }))
                    }
                }
                if (isNaN(elem.end)) {
                    this.props.dispatch(dispatchLogisticPrice({
                        type: SET_PRICE_LOGISTIC_PRICE, payload: {
                            name: 'status',
                            value: 'inactive',
                            idx: index
                        }
                    }))
                }
            }
        }


        const setInactive = logisticPrice.findIndex((item, index) => isNaN(item.end))
        logisticPrice.map((e, index) => {
            if (index > setInactive && setInactive !== -1) {
                this.props.dispatch(dispatchLogisticPrice({
                    type: SET_PRICE_LOGISTIC_PRICE, payload: {
                        name: 'status',
                        value: 'inactive',
                        idx: index
                    }
                }))
            } else {
                this.props.dispatch(dispatchLogisticPrice({
                    type: SET_PRICE_LOGISTIC_PRICE, payload: {
                        name: 'status',
                        value: 'active',
                        idx: index
                    }
                }))
            }
        })
        if (!isNaN(logisticPrice[logisticPrice.length - 1].end) && logisticPrice[logisticPrice.length - 1].end != null && logisticPrice[logisticPrice.length - 1].status != 'inactive') {
            this.handleAddPriceLogistic()
        }
    }

    handleSubmit = async () => {
        const {logisticPrice} = this.props
        if (!isNaN(logisticPrice.price[logisticPrice.price.length - 1].end) && logisticPrice.price[logisticPrice.price.length - 1].end != null && logisticPrice.price[logisticPrice.price.length - 1].status != 'inactive') {
            this.handleAddPriceLogistic()
            return
        }
        
        const verify = await this.verify(logisticPrice)
        if (verify) {
            if (this.props.location.state == undefined) {
                this.props.dispatch(dispatchLogisticPrice({ type: CREATE_LOGISTIC_PRICE }))
            } else {
                this.props.dispatch(dispatchLogisticPrice({ type: UPDATE_LOGISTIC_PRICE, payload: this.props.location.state }))
            }
        }

    }

    verify = (data) => {
        let err = {
            name: false,
            start: [],
            end: [],
            price: []
        }
        let verify = true
        if (data.name?.trim().length === 0) err.name = true
        const lastIndexStatusActive = data.price?.filter((item) => item.status == 'active').length - 1
        for (let index = 0; index < data.price.length; index++) {
            const element = data.price[index];
            if (element.status == 'active') {
                if (isNaN(element.start) || element.start == null) err.start = [...err.start, index]
                if (index != data.price.length - 1) {
                    if (lastIndexStatusActive != index) {
                        if (isNaN(element.end) || element.end == null) {
                            err.end = [...err.end, index]
                        }
                    }
                }
                if (element.price == undefined || isNaN(element.price)) err.price = [...err.price, index]
            }

        }
        this.setState({ err })
        if (err.name) return verify = false
        if (err.end.length > 0) return verify = false
        if (err.price.length > 0) return verify = false
        return verify
    }

    handleCreateSuccess = async () => {
        setLocalStorage({ key: 'shopDetailDefaultTab', value: { tab: 7 } })
        await this.props.history.push({ pathname: "/manage/shop/shopdetail" })
    }

    addMoreShopLogistic = () => {
        window.location.replace('/manage/shop/shopdetail/addlogisticprice')
    }

    render() {
        const { logisticPrice, modalConfirm } = this.props
        const { listLogistics, price, logisticSelected, name } = logisticPrice
        const { err } = this.state
        return (
            <div className="add-logistic-price">

                <h5>เพิ่มราคาค่าจัดส่ง</h5>
                <div className="container-box">
                    <h4>เลือกผู้ให้บริการขนส่ง</h4>
                    <div className="d-flex flex-row flex-wrap mt-3" style={{ gap: '30px' }}>
                        {listLogistics && listLogistics.map((item) => (
                            <LogoSelect
                                value={item.logo}
                                className={item.code === logisticSelected?.code ? 'active' : ''}
                                onClick={() => this.onChangeLogisticSelect(item)}
                            />
                        ))}
                    </div>
                </div>

                <div className="container-box">
                    <h5>ตั้งชื่อการจัดส่ง</h5>

                    <div className="d-flex flex-column mt-4">
                        <div><span className="require">ชื่อการจัดส่ง</span> <span>(จำกัด 60 ตัวอักษร)</span></div>
                        <Input type="text" className="form-control w-50" placeholder="ตั้งชื่อการจัดส่งของคุณ" onChange={(e) => this.onChangeName(e.target.value)} value={name} />
                        {err?.name && <span className="require text-danger"> กรุณากรอกชื่อการจัดส่ง </span>}
                    </div>
                </div>

                <div className="container-box">
                    <h5>กำหนดราคาค่าจัดส่งตามจำนวนชิ้น</h5>

                    {price.map((item, idx) => (
                        <>
                            <div className={classnames('d-flex flex-row justify-content-between align-items-center flex-wrap set-price-value', { "opacity-disable-price": item.status === "inactive" })}>
                                <span>สินค้าชิ้นที่</span>
                                <span>{item.start}</span>
                                <span>-</span>
                                <div className="d-flex flex-column">
                                    <Input type="number" className="form-control set-price" placeholder="ไม่จำกัด" name="end" 
                                    onChange={(e) => this.onChangePrice(e.target.name, e.target.value, idx)} value={item.end} 
                                    onBlur={(e) => this.onBlurChangePrice(e.target.name, e.target.value, idx)} />
                                    {err?.end.includes(idx) && <span className="text-danger require">กรุณากรอกจำนวนสินค้า</span>}
                                </div>
                                <div>
                                    <Radio
                                        type='radio'
                                        value='flat'
                                        className='mr-2'
                                        valueOption={'rateType'}
                                        name='rateType'
                                        onChange={(e) =>
                                            this.onChangeLogisticRateType(e, idx)
                                        }
                                        checked={item.rateType == 'flat'}
                                    />
                                    <label className='form-check-label'>
                                        ราคาเหมาจ่าย
                                </label>
                                </div>
                                <div>
                                    <Radio
                                        type='radio'
                                        value='item'
                                        valueOption={'rateType'}
                                        name='rateType'
                                        onChange={(e) =>
                                            this.onChangeLogisticRateType(e, idx)
                                        }
                                        checked={item.rateType == 'item'}
                                    />
                                    <label className='form-check-label'>
                                        ราคาต่อชิ้น (ชิ้นละ)
                                </label>
                                </div>
                                <div className="d-flex flex-column ">
                                    <Input type="number" className="form-control set-price" placeholder="ระบุจำนวนเงิน" name="price" 
                                    onChange={(e) => this.onChangePrice(e.target.name, e.target.value, idx)} value={item.price} 
                                    onBlur={(e) => this.onBlurChangePrice(e.target.name, e.target.value, idx)}
                                    />
                                    {err?.price.includes(idx) && <span className="text-danger require">กรุณากรอกราคาสินค้า</span>}
                                </div>
                                <div style={{width:'4.5%'}}>
                                {idx !== 0 ? <IconTrash className="cursor-pointer" onClick={() => this.handleDeletePrice(idx)}/> : null }
                                </div>
                            </div>

                        </>
                    ))}

                    {/* <div className="d-flex justify-content-end mt-2">
                        <button className={classnames({
                            'btn btn-disabled': textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) || isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end),
                            'btn-cancel btn': !textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) && !isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end)
                        }
                        )} onClick={() => this.handleAddPriceLogistic()}
                            disabled={textIsEmpty(logisticPrice.price[logisticPrice.price.length - 1]?.end?.toString()) || isNaN(logisticPrice.price[logisticPrice.price.length - 1]?.end)}
                        >
                            เพิ่มอัตราค่าจัดส่ง
                        </button>
                    </div> */}
                </div>

                <div className="d-flex flex-row justify-content-center" style={{ gap: '30px' }}>
                    <button className="btn-cancel">ยกเลิก</button>
                    <button className="btn-submit" onClick={() => this.handleSubmit()}>บันทึก</button>
                </div>

                <ModalConfirm
                    isOpen={false}
                    message={'ทำรายการสำเร็จ'}
                    type={'success'}
                    btnConfirm={'เสร็จสิ้น'}
                    btnCancel={'สร้างรายการใหม่เพิ่ม'}
                    confirm={() => { }}
                    isClose={() => { }}
                />

                <ModalConfirmChildren
                    isOpen={modalConfirm.isOpen}
                    btnCancel={'สร้างรายการใหม่เพิ่ม'}
                    btnConfirm={'เสร็จสิ้น'}
                    handleConfirm={() => this.handleCreateSuccess()}
                    handleCancel={() => this.addMoreShopLogistic()}
                >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <IconSuccess />
                        <h4 className="mt-3">ทำรายการสำเร็จ</h4>
                    </div>
                </ModalConfirmChildren>

            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    logisticPrice: state.logisticPrice,
    modalConfirm: state.logisticPrice.modalConfirm
})


export default connect(mapStateToProps, null)(AddLogisticPrice)