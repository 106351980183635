import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { detailButton } from 'components/Gallery/constant'
import { ModalUploadMedia } from 'components/Gallery'
import { tabsBrowse } from 'components/Gallery/constant'
import { ReactComponent as IconCamera } from 'images/icons/iconcamera.svg'
import './styles.scss'

const ButtonUpload = (props) => {
  const {
    width,
    height,
    type,
    shopDetail,
    disabled,
    fieldUpload,
    limitImageUpload,
    onBrowse,
    isAvatar,
    avatar,
    targetAlbum, /// if u want to upload file to other album (not default album)
    albumDetail,
    validateWidthHeight
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onClickBrowse = () => {
    if (!disabled) {
      onBrowse && onBrowse()
      setIsOpen(true)
    }
  }

  return (
    <>
      {isAvatar ? (
        <div className='upload-container cursor-pointer'>
          {avatar}
          <div className='icon-container' onClick={onClickBrowse}>
            <IconCamera />
          </div>
        </div>
      ) : (
        <div
          className='button-upload-media-box cursor-pointer'
          style={{ width, height }}
          onClick={onClickBrowse}
        >
          <div
            className={classnames('button-upload-media', {
              disabled: disabled,
            })}
          >
            <img className='icon' src={detailButton[type].icon} alt='' />
            <div className='title'>{detailButton[type].title}</div>
          </div>
        </div>
      )}

      <ModalUploadMedia
        title={detailButton[type].title}
        labelResetMedia={detailButton[type].reset}
        isOpenModal={isOpen}
        closeModal={() => setIsOpen(false)}
        dataTabs={tabsBrowse}
        isShowTabs
        cardType={type}
        shopDetail={shopDetail}
        fieldUpload={fieldUpload}
        limitImageUpload={limitImageUpload}
        setLoading={(data) => {
          setIsLoading(data)
        }}
        targetAlbum={targetAlbum}
        albumDetail={albumDetail}
        validateWidthHeight={validateWidthHeight}
      />
    </>
  )
}

ButtonUpload.defaultProps = {
  onClickButton: () => {},
  onBrowse: null,
  width: '8em',
  height: '8em',
  type: 'image',
  shopDetail: {},
  disabled: false,
  isShowButton: true,
  fieldUpload: null,
  limitImageUpload: 4,
  isAvatar: false,
  icon: '',
  avatar: '',
  validateWidthHeight:null /// {width: xx,height: xx,type: 'lower' || 'heigher' || 'equal'}
}

export default ButtonUpload
