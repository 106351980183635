import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IconClose from '../../images/icons/close.svg'
import './styles.scss'
import IconSuccess from 'images/icons/success.svg'
import { dispatchModalAlert, OPEN_MODAL_ALERT } from 'redux/actions/modalAlert'
import { dispatchPromocode, OPEN_MODAL_ADD_PROMOCODE } from 'redux/actions/promocode'
import { connect } from 'react-redux'
import { displayDateTh } from 'helper/timeUtil';
import host from 'config/host';
import IconCalendar from 'images/icons/calendar.svg'
import IconNoPromocode from 'images/icons/nopromocode.svg'
import { ReactComponent as IconCalender } from 'images/icons/icon-calendar.svg'



const ModalDetailPromocode = (props) => {
    const {
        buttonLabel,
        className,
        isOpen,
        data
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const showTypeCoupon = (type) => {
        switch (type) {
            case 'discount':
                    return 'ลดราคา'
            case 'voucher':
                    return 'คูปองส่วนลด'
            default:
                break;
        }
    }
    return (
        <div id="modaldetailpromocode">
            <Modal isOpen={isOpen} toggle={toggle} modalClassName={'modal-detail-promocode'}>
                <ModalHeader toggle={toggle}>
                    <div className="w-100 d-flex justify-content-between">
                        <h5>ตรวจสอบรหัสอ้างอิง</h5>
                        <img src={IconClose} className="cursor-pointer" onClick={() => props.dispatch(dispatchPromocode({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: false, data: '' } }))} />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="badge badge-green d-flex flex-row align-items-start text-left">
                        <img src={IconSuccess} alt="" style={{ maxWidth: '1em', marginRight: '10px', paddingTop: '6px' }} />
                        <span>ตรวจสอบรหัสอ้างอิง {data?.productCode} เรียบร้อยแล้ว <br /> รหัสอ้างอิงสามารถใช้งานได้</span>
                    </div>

                    <div className="detail-promocode">
                        <div className="img-box">
                        {data?.mediaCover?.path ? 
                        <img src={`${host.img}image/${data?.mediaCover?.path}`} alt="" style={{ width:'240px',height:'240px' }} />
                        :
                            <img src={IconNoPromocode} alt="" style={{width:'10em'}} />
                    }
                    </div>
                        


                        <div className="d-flex flex-column p-2">
                            <h5> {data?.name} </h5>
                            <div className="mt-3">
                                <span className="font-weight-bold">ประเภท </span>
                                <span>{showTypeCoupon(data?.productCategory)}</span>
                            </div>
                            <div className="mt-3">
                                <span className="font-weight-bold">รายละเอียด <br /> </span>
                                <span className="text-justify">{data?.description}</span>
                            </div>

                            <div className="mt-3 text-danger">
                                <IconCalender className="calendar" />


                                <span className="ml-2">ระยะเวลาการใช้งาน {displayDateTh(data?.coupon ? data?.coupon?.promotion?.startDate : data?.sellingStartAt, 'DD MMM YYYY')} -  {displayDateTh(data?.coupon ? data?.coupon?.promotion?.stopDate : data?.sellingStopAt, 'DD MMM YYYY')}</span>
                            </div>

                        </div>
                    </div>


                    <div className="d-flex flex-row justify-content-between w-100 p-2">
                        <div className="info-promocode">
                            <div>
                                <span className="font-weight-bold mt-4">ร้านค้าที่เข้าร่วม</span> <br />
                                <span>{data?.branch?.join(',')}</span><br />
                            </div>
                            <div className="mt-3">
                                <span className="font-weight-bold">สิ่งที่จะได้รับ</span><br />
                                <span>{data?.textBenefit} </span> <br />
                            </div>
                            <div className="mt-3">
                                <span className="font-weight-bold">วิธีการใช้งาน</span><br />
                                <span>{data?.textHowto}</span></div>

                        </div>
                        <div className="condition-promocode">
                            <span className="font-weight-bold">เงื่อนไข</span> <br />
                            <span className="text-justify">{data?.textTerms}</span>
                        </div>
                    </div>

                    <div>

                    </div>

                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-confirm cursor-pointer" onClick={() => props.dispatch(dispatchPromocode({ type: OPEN_MODAL_ADD_PROMOCODE, payload: { isOpen: false, data: '' } }))}>ตกลง</button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => ({
    modalAlert: state.modalAlert.modalAlert
})


export default connect(mapStateToProps)(ModalDetailPromocode)
