import React from 'react'
import { Media, BreadcrumbGallery } from '../components/Gallery'

const Medias = (props) => {
  if (props.location.state) {
    const { shopDetail, albumDetail } = props.location.state

    return (
      <>
        <BreadcrumbGallery page='media' {...props} />
        <Media
          isMainPage={true}
          shopDetail={shopDetail}
          albumDetail={albumDetail}
          isEditName
        />
      </>
    )
  } else {
    return props.history.push('/gallery')
  }
}

export default Medias
