import axios from 'config/axios'
import host from 'config/host'

class PlaylistFrontPageProvider {
  // playlist-front-pages/list-page
  getPage = async () => {
    try {
      const url = `${host.api}/playlist-front-pages/list-page`
      const res = await axios.get(url)
      return res.data
    } catch (error) {}
  }

  getPlaylistFrontPage = async (page) => {
    try {
      const url = `${host.api}/playlist-front-pages/page?page=${page}`
      const res = await axios.get(url)
      return res.data
    } catch (error) {}
  }

  createPlaylistFrontPage = async (body) => {
    try {
      const url = `${host.api}/playlist-front-pages`
      const res = await axios.post(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  updatePlaylistFrontPage = async (body) => {
    try {
      const url = `${host.api}/playlist-front-pages`
      const res = await axios.patch(url, body)
      return res.data
    } catch (error) {
      return error
    }
  }

  // deletePlaylistFrontPage = async (playlistsId) => {
  //   try {
  //     const url = `${host.api}/playlists/${playlistsId}`
  //     const res = await axios.delete(url)
  //     return res.data
  //   } catch (error) {}
  // }

  // createPlaylistItem = async (body) => {
  //   try {
  //     const url = `${host.api}/playlist-items`
  //     const res = await axios.post(url, body)
  //     return res.data
  //   } catch (error) {}
  // }

  // getPlaylistItemsByPlaylistId = async (query) => {
  //   try {
  //     const url = `${host.api}/playlist-items/playlist/${query.playlistsId}?limit=${query.limit}&page=${query.page}`
  //     const res = await axios.get(url)
  //     return res.data
  //   } catch (error) {}
  // }

  // deletePlaylistFrontPageItem = async (playlistsItemId) => {
  //   try {
  //     const url = `${host.api}/playlist-items/${playlistsItemId}`
  //     const res = await axios.delete(url)
  //     return res.data
  //   } catch (error) {}
  // }

  // shufflePlaylistFrontPageItem = async (playlistsId) => {
  //   try {
  //     const url = `${host.api}/playlist-items/shuffle/${playlistsId}`
  //     const res = await axios.get(url)
  //     return res.data
  //   } catch (error) {
  //     return error
  //   }
  // }

  // pinPlaylistFrontPageItem = async (params) => {
  //   try {
  //     const url = `${host.api}/playlist-items/${params.playlistsItemId}`
  //     const res = await axios.patch(url, { pinDate: params.pinDate })
  //     return res.data
  //   } catch (error) {
  //     return error
  //   }
  // }

  // searchMediaByName = async(payload) => {
  //     try {
  //         const url = `${host.api}/medias/search?name=${payload.name}&albumId=${payload.albumId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }

  // onFecthAlbumDefaultByShopId = async(shopId) => {
  //     try {
  //         const url = `${host.api}/albums/getDefaultAlbum/${shopId}`
  //         const res = await axios.get(url)
  //         return res.data
  //     } catch (error) {

  //     }
  // }
}

export default new PlaylistFrontPageProvider()
