import axios from 'axios';
import host from '../config/host'
import apiKey from '../config/key'
import { getCookieData, readCookie } from './cookieHelper'
import { isServer } from './utils'
import jsCookie from 'js-cookie';



/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null) => {
  const options = {
    baseURL:  baseUrl ? baseUrl : host.api
  };

  const cookieToken =  readCookie('token')
  const token = isServer() ? apiKey.publicKeyAPI : cookieToken || apiKey.publicKeyAPI
   options.headers = {
          Authorization: `Bearer ${token}` ,
          "channel" : "web"
   };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {

      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
    //   if (error.response.status >= 500) {

    //   }


    if(error.response.status == 401){

       // 
      try {

        jsCookie.remove('token')
        jsCookie.remove('user')

        setTimeout(() => {
            window.location.href = "/authStatus"
        },2000)

        
      }catch(ex){
        
      }

    }

      return Promise.reject(error);
    },
  );

  return client;
};

const chatClient =   (baseUrl = null) => {
  const jwt = require('jsonwebtoken')
  const ac = apiKey.publicKeyAPI
  
  const signed = jwt.sign({ access_token: ac }, apiKey.jwtSecret)
  
  const options = {
    baseURL:  baseUrl ? baseUrl : host.chat
  }
  options.headers = {
    authorization : `Bearer ${signed}`
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {

      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
      //   if (error.response.status >= 500) {

      //   }

      return Promise.reject(error);
    },
  );

  return client;
};

export { getClient, chatClient }