import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const BreadcrumbGallery = (props) => {
  const {
    page,
    location: { pathname, state },
  } = props

  const [listLink, setListLink] = useState([])

  useEffect(() => {
    const tmpPath = pathname.split('/')
    var listPath = []

    tmpPath.forEach((name) => {
      switch (name) {
        case 'gallery':
          listPath.push({
            name,
            title: 'คลังภาพและวิดีโอ',
            data: {
              pathname: '/gallery',
              search: '',
              hash: '',
              state: undefined,
            },
          })
          break

        case 'albums':
          listPath.push({
            name,
            title: state.shopDetail.shop.name,
            data: {
              pathname: '/gallery/albums',
              search: '',
              hash: '',
              state: state,
            },
          })
          break

        case 'media':
          listPath.push({
            name,
            title: state.albumDetail.name,
            data: {
              pathname: '/gallery/albums/media',
              search: '',
              hash: '',
              state: state,
            },
          })
          break

        default:
          break
      }
    })

    setListLink(listPath)
  }, [])

  return (
    <div className='breadcrumb-wrap'>
      {listLink.map((item, index) => {
        return (
          <>
            {index > 0 && <span>{' / '}</span>}
            <Link
              to={item.data}
              key={pathname}
              className={classnames('breadcrumb-url', {
                active: item.name === page,
              })}
            >
              <span>{item.title}</span>
            </Link>
          </>
        )
      })}
    </div>
  )
}

export default BreadcrumbGallery
