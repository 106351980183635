import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import './styles.scss'

const FloatInput = (props) => {
  const {
    name,
    label,
    defaultValue,
    placeholder,
    error,
    require,
    onChangeFloatInput,
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onChange = (data) => {
    setValue(data.value)
    onChangeFloatInput(data.value)
  }

  return (
    <div className='text-input-field'>
      <label>{label}</label>
      {require && <span className='txt-red'>*</span>}
      <div className={classnames('container-input', { error: !!error })}>
        <NumberFormat
          value={value}
          name={name}
          defaultValue={defaultValue}
          className='input-box'
          placeholder={placeholder}
          fixedDecimalScale
          thousandSeparator
          decimalScale={2}
          onValueChange={onChange}
        />
      </div>
      {error && <div className='txt-red mt-1'>{error}</div>}
    </div>
  )
}

FloatInput.defaultProps = {
  label: 'ชื่ออัลบั้ม',
  defaultValue: '',
  placeholder: '',
  error: '',
  require: false,
}

export default FloatInput
