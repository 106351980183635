export const GET_POST = "GET_POST"
export const GET_POST_BY_ID = "GET_POST_BY_ID"
export const SET_POST = "SET_POST"
export const GET_GENERATE_KEY = "GET_GENERATE_KEY"
export const SET_GENERATE_KEY = "SET_GENERATE_KEY"
export const PUBLISH_POST = "PUBLISH_POST"
export const STOP_POST = "STOP_POST"
export const GET_POST_BY_SHOP_ID = "GET_POST_BY_SHOP_ID"
export const SET_POST_BY_SHOP_ID = "SET_POST_BY_SHOP_ID"
export const CREATE_POST = "CREATE_POST"
export const CREATE_POST_ITEM = "CREATE_POST_ITEM"
export const UPDATE_POST = "UPDATE_POST"
export const UPDATE_POST_ITEM = "UPDATE_POST_ITEM"
export const TOGGLE_SHOW_ON_ITEM = "TOGGLE_SHOW_ON_ITEM"
export const TOGGLE_OPEN_LIVE_SETTING_MODAL = "TOGGLE_OPEN_LIVE_SETTING_MODAL"
export const TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL = "TOGGLE_OPEN_LIVE_SETTING_CONFIRM_MODAL"
export const HANDLE_DELETE_POST_LIVE = "HANDLE_DELETE_POST_LIVE"
export const SET_MODAL_RERUN_LIVE = "SET_MODAL_RERUN_LIVE"
export const CREATE_RERUN_LIVE = "CREATE_RERUN_LIVE"
export const SET_MODAL_LIVE_FINISH_CONFIRM = "SET_MODAL_LIVE_FINISH_CONFIRM"
export const GET_POST_MEDIA_GROUP = "GET_POST_MEDIA_GROUP"
export const SET_POST_MEDIAS = "SET_POST_MEDIAS"
export const CREATE_POST_RERUN = "CREATE_POST_RERUN"
export const CREATE_POST_VOD = "CREATE_POST_VOD"
export const GET_POST_VOD_BY_STATUS = "GET_POST_VOD_BY_STATUS"
export const GET_POST_VOD_BY_ID = "GET_POST_VOD_BY_ID"
export const SET_POST_VOD_BY_ID = "SET_POST_VOD_BY_ID"
export const DELETE_POST_VOD = "DELETE_POST_VOD"
export const UPDATE_POST_VOD_STATUS = "UPDATE_POST_VOD_STATUS"
export const UPDATE_POST_RERUN = "UPDATE_POST_RERUN"
export const GET_POST_MEDIA = "GET_POST_MEDIA"
export const SET_POST_MEDIA = "SET_POST_MEDIA"
export const UPDATE_PRODUCT_UPDATE = "UPDATE_PRODUCT_UPDATE"
export const GET_POST_MEDIA_LIVE = "GET_POST_MEDIA_LIVE"
export const GET_POST_MEDIA_BY_ID = "GET_POST_MEDIA_BY_ID"


export function dispatchPost(params) {
    return {
        type: params.type,
        payload: params.payload
    }
}

